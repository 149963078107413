import React from 'react';
import PropTypes from 'prop-types';

import { getFormattedWeight, formatUnit } from '../../../utils/units';

import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { MyForestSummary as MyForestSummaryTexts } from '../../../constants/messages';

import CloudArrowUp from '../../UI/Icons/CloudArrowUp';

import './CarbonBalanceNew.css';
import CarbonBalanceBar from './CabronBanlaceBar';

import Information from '../../UI/Icons/Information';
import Tooltip from '../../UI/Tooltip/Tooltip';

const CarbonBalanceNew = ({ emitted = 0, vcus = 0, miterdCredits = 0, currentAfforestation = 0, futureAfforestation = 0, showBar=true, intl }) => {
  const getTotalBar = (totalCaptured, emitted) => {
    if ((totalCaptured > 2 * emitted) || emitted === 0) {
      return totalCaptured;
    } else {
      return 2 * emitted;
    }
  }

  const totalCaptured = vcus * 1000 + miterdCredits * 1000 + currentAfforestation + futureAfforestation;
  const totalBar = getTotalBar(totalCaptured, emitted);
  const emittedPercentage = parseInt((emitted * 100 / totalBar).toFixed(0));
  
  const formattedEmitted = getFormattedWeight(emitted);
  const formattedCurrentAfforestation = getFormattedWeight(currentAfforestation);
  const formattedFutureAfforestation = getFormattedWeight(futureAfforestation);
  
  let vcusPercentage = 0;
  let miterdPercentage = 0;
  let currentAfforestationPercentage = 0;
  let futureAfforestationPercentage = 0;
  
  if (totalCaptured !== 0) {
    vcusPercentage = parseInt((vcus * 1000 * 100 / totalBar).toFixed(0));
    miterdPercentage = parseInt((miterdCredits * 1000 * 100 / totalBar).toFixed(0));
    currentAfforestationPercentage = parseInt((currentAfforestation * 100 / totalBar).toFixed(0));
    futureAfforestationPercentage = parseInt((futureAfforestation * 100 / totalBar).toFixed(0));
  }
  return (
    <div className="carbon-balance-new-container">
      {
        showBar &&
        <CarbonBalanceBar
          futureAfforestationPercentage={futureAfforestationPercentage}
          currentAfforestationPercentage={currentAfforestationPercentage}
          vcusPercentage={vcusPercentage}
          miterdPercentage={miterdPercentage}
          emittedPercentage={emittedPercentage}
          emitted={emitted}
          formattedEmitted={formattedEmitted}
        />
      }
      <div className="carbon-balance-new-legend-container">
        {vcus > 0 && <div className="carbon-balance-new-legend-item-container">
          <div>
            {showBar && <div className="carbon-balance-new-legend-item-icon carbon-balance-new-legend-item-icon--vcus"/>}
            <span className="carbon-balance-new-legend-item-value">{intl.formatNumber(vcus)} {intl.formatMessage(MyForestSummaryTexts.tonsFixed)}</span>
            <Tooltip
              style={{marginLeft: '4px'}}
              size='big'
              content={
              <FormattedMessage
                id={'CarbonBalance.conservationCreditsTooltip'}
                defaultMessage={'This represents the CO₂ already offset by forestry projects on the Reforestum platform. Each ton corresponds to one Verified Carbon Unit (VCU) certified and issued by the international Verra standard'}
              />
              }
            >
              <Information/>
            </Tooltip>
          </div>
          <p className="carbon-balance-new-legend-item-label">
            <FormattedMessage
              id={'CarbonBalance.verifiedOffsets'}
              defaultMessage={'Verified Offsets'}
            />
          </p>
        </div>}
        {miterdCredits > 0 && <div className="carbon-balance-new-legend-item-container">
          <div>
            {showBar && <div className="carbon-balance-new-legend-item-icon carbon-balance-new-legend-item-icon--miterd"/>}
            <span className="carbon-balance-new-legend-item-value">{intl.formatNumber(miterdCredits)} {intl.formatMessage(MyForestSummaryTexts.tonsFixed)}</span>
            <Tooltip
              style={{marginLeft: '4px'}}
              size='big'
              content={
              <FormattedMessage
                id={'CarbonBalance.miterdCreditsTooltip'}
                defaultMessage={'This is the Miterd credits tooltip'}
              />
              }
            >
              <Information/>
            </Tooltip>
          </div>
          <p className="carbon-balance-new-legend-item-label">
            <FormattedMessage
              id={'CarbonBalance.miterdCredits'}
              defaultMessage={'MITERD Carbon Credits'}
            />
          </p>
        </div>}
        {formattedCurrentAfforestation.value > 0 && <div className="carbon-balance-new-legend-item-container">
          <div>
            {
              showBar && <div className="carbon-balance-new-legend-item-icon carbon-balance-new-legend-item-icon--current-afforestation" />
            }
            <span className="carbon-balance-new-legend-item-value">
              {`${intl.formatNumber(formattedCurrentAfforestation.value)} ${formatUnit(intl, formattedCurrentAfforestation.unit)} ${intl.formatMessage(MyForestSummaryTexts.co2UnitUpdated)}`}
            </span>
            <Tooltip
              style={{marginLeft: '4px'}}
              size='big'
              content={
              <FormattedMessage
                id={'CarbonBalance.currentAfforestationCreditsTooltip'}
                defaultMessage={'This represents the estimated amount of CO₂ captured up to date by the forests promoted by Reforestum and financed through Forest Shares'}
              />
              }
            >
              <Information/>
            </Tooltip>
          </div>
          <p className="carbon-balance-new-legend-item-label">
            <FormattedMessage
              id={'CarbonBalance.currentAfforestationCredits'}
              defaultMessage={'Estimated removal to date'}
            />
          </p>
        </div>}
        {formattedFutureAfforestation.value > 0 && <div className="carbon-balance-new-legend-item-container">
          <div>
            {
              showBar && <div className="carbon-balance-new-legend-item-icon carbon-balance-new-legend-item-icon--future-afforestation" />
            }
            <span className="carbon-balance-new-legend-item-value">
              {`${intl.formatNumber(formattedFutureAfforestation.value)} ${formatUnit(intl, formattedFutureAfforestation.unit)} ${intl.formatMessage(MyForestSummaryTexts.co2UnitUpdated)}`}
            </span>
            <Tooltip
              style={{marginLeft: '4px'}}
              size='big'
              content={
              <FormattedMessage
                id={'CarbonBalance.futureAfforestationCreditsTooltip'}
                defaultMessage={'This represents the estimated remaining CO2 that will be captured over the permanence period of the forest projects promoted by Reforestum and financed through Forest Shares'}
              />
              }
            >
              <Information/>
            </Tooltip>
          </div>
          <p className="carbon-balance-new-legend-item-label">
            <FormattedMessage
              id={'CarbonBalance.futureAfforestationCredits'}
              defaultMessage={'Estimated future removal'}
            />
          </p>
        </div>}
        {formattedEmitted.value > 0 && <div className="carbon-balance-new-legend-item-container">
          <div>
            <CloudArrowUp />
            <span className="carbon-balance-new-legend-item-value">
              {`${intl.formatNumber(formattedEmitted.value)} ${formatUnit(intl, formattedEmitted.unit)}`}
            </span>
          </div>
          <p className="carbon-balance-new-legend-item-label">
            <FormattedMessage
              id={'CarbonBalance.co2Emissions'}
              defaultMessage={'CO₂ EMISSIONS'}
            />
          </p>
        </div>}
      </div>
    </div>
  );
};

CarbonBalanceNew.propTypes = {
  emitted: PropTypes.number,
  showBar: PropTypes.bool,
  currentAfforestation: PropTypes.number,
  futureAfforestation: PropTypes.number,
  vcus: PropTypes.number,
  intl: intlShape,
};

export default injectIntl(CarbonBalanceNew);
