import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber, FormattedMessage } from 'react-intl';

import {getFormattedArea, getFormattedCredits} from '../../utils/units';

import Heading from '../UI/Text/Heading';
import Text from '../UI/Text/Text';
import FormattedUnit from '../Utils/FormattedUnit';

import { OFFSET_TYPE_SQM_SHARES, OFFSET_TYPE_CREDIT } from '../../constants/offsetTypes';

const SummaryText = ({ offsetType, quantity, className, forestName, forestLocation }) => (
  <Heading className={className}>
    { offsetType === OFFSET_TYPE_SQM_SHARES &&
        <FormattedMessage
        id={'Puchase.Sqm.purchaseInfoHeader'}
        defaultMessage={'You are now purchasing {areaString} of carbon offsetting rights at our forest {forestNameString} located at {forestLocationString}'}
        values={{
            areaString: <Text color="green" tag="span">
                          <FormattedNumber
                            value={getFormattedArea(quantity).value}
                          /> <FormattedUnit unit={getFormattedArea(quantity).unit} />
                        </Text>,
            forestNameString: <Text color="green" tag="span">
                              {forestName}
                              </Text>,
            forestLocationString: <Text color="green" tag="span">
                                  {forestLocation}
                                  </Text>
        }}
        />
     }

      { offsetType === OFFSET_TYPE_CREDIT &&
          <FormattedMessage
          id={'Puchase.VCU.purchaseInfoHeader'}
          defaultMessage={'You are now purchasing {areaString} of carbon offsetting rights at our project {forestNameString} located at {forestLocationString}'}
          values={{
              vcusString: <Text color="green" tag="span">
                  <FormattedNumber
                      value={getFormattedCredits({credits: quantity}).value}
                  /> <FormattedUnit unit={getFormattedCredits({credits: quantity}).unit} />
              </Text>,
              forestNameString: <Text color="green" tag="span">
                  {forestName}
              </Text>,
              forestLocationString: <Text color="green" tag="span">
                  {forestLocation}
              </Text>
          }}
          />
      }
  </Heading>
);

SummaryText.propTypes = {
  offsetType:  PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  className: PropTypes.string,
  forestLocation: PropTypes.string.isRequired,
  forestName: PropTypes.string.isRequired,
};

export default SummaryText;
