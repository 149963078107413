import { PROJECT_TYPES } from "../constants/projectTypes";

export function parseProjectTypeTag(projectTypeTag) {
    // parse the tag text so stuff before the first spaced dash is used for the tag name
    // while the rest is used for the tooltip

    const parts = projectTypeTag.split(" - ")
    
    return {tagText: parts[0], tagTooltip: parts[1]}
}

export function isAfforestationLikeProject(projectType) {
    // this is used to show planted vs established in the tags
    if (projectType.includes("IFM")  || projectType.includes("ARR")) {
        return true
    } else {
        return false
    }
}

export function getProjectTypeDetails(projectType, intl) {

    if (projectType in PROJECT_TYPES) {
        return parseProjectTypeTag(intl.formatMessage(PROJECT_TYPES[projectType]))
    } else {
        return parseProjectTypeTag(intl.formatMessage(PROJECT_TYPES['Other']))
    }

}