import React from 'react'
import './ProposalDetails.scss'
import ReadMore from '../UI/ReadMore'
import DownloadableFile from '../DownloadableFile/DownloadableFile'
import ProposalDetailsExtras from './ProposalDetailsExtras'

const ProposalDetailsProposalDetails = (
  {
    proposalDetails,
    extrasToRender=null
  }={}
) => {

  const renderMain = () => {

    let proposalDetailsDescription = (proposalDetails.proposal && proposalDetails.proposal.description) || null
    let attachments = ((proposalDetails.proposal && proposalDetails.proposal.attachments) || []).map(
      a => ({
        fileName: a.file_name || '',
        fileUrl: a.url || '',
        fileType: a.file_type || '',
      })
    )

    return <div className={'rfp-detail__overview'}>
      {
        proposalDetailsDescription && 
          <div className='rfp-detail__overview__proposal-details'>
            <ReadMore text={proposalDetailsDescription} isHtml cutLength={800} minLength={1000} />
          </div>
      }
      {
        extrasToRender &&
        <ProposalDetailsExtras
          extrasToRender={extrasToRender}
        />
      }
      {
        (attachments || []).length > 0 && <div className={'rfp-detail__overview__proposal-details__downloads'}>
          {
            (attachments || []).map(
              d => <DownloadableFile fileName={d.fileName} fileUrl={d.fileUrl} fileType={d.fileType} />
            )
          }
        </div>
      }
    </div>
  }

  return proposalDetails ? renderMain() : null
}


export default ProposalDetailsProposalDetails