import { facebookAppId } from "../../constants/config";

let instance

class FacebookAuth {
  constructor(callback) {
    if (instance) {
      callback(instance)
      return instance
    }

    this.injectClientLibrary(callback)
    instance = this
  }

  initializeFacebookAndCallCallback(callback) {
    window.FB.init({
      appId: facebookAppId,
      autoLogAppEvents: true,
      status: true,
      xfbml: true,
      version: 'v3.1'
    });

    window.FB.Event.subscribe('auth.authResponseChange', this.handleStatusChange)
    window.FB.Event.subscribe('auth.statusChange', this.handleStatusChange)
    callback(this)
  }

  injectClientLibrary = callback => {
    if(!window.FB) {
      window.fbAsyncInit = () => {
        this.initializeFacebookAndCallCallback(callback)
      };
    }
    else {
      this.initializeFacebookAndCallCallback(callback)
    }
  }

  login = (onSuccess, onFailure) => {
    window.FB.login(loginResponse => {
      if (loginResponse.authResponse) {
        window.FB.api('/me?fields=email', meResponse => {
          onSuccess({...loginResponse.authResponse, ...meResponse})
        })
      } else {
        onFailure && onFailure(loginResponse)
      }
    }, {
      scope: 'email',
    })
  }

  logout = onLogout => {
    window.FB.getLoginStatus(response => {
      if (response.status === 'connected') {
        window.FB.logout(onLogout)
      } else {
        onLogout()
      }
    })
  }

  handleStatusChange = (response) => {
    const check = (response) => {
      const isSignedIn = response && response.status === 'connected'
      if (!isSignedIn && this.onExternalLogout) {
        this.onExternalLogout()
      }
    }

    if (!response) {
      window.FB.getLoginStatus(check)
    } else {
      check(response)
    }
  }

  setOnExternalLogout = callback => {
    this.onExternalLogout = callback
  }
}

export default FacebookAuth
