import { axiosV2Instance } from './';
import { refreshProfile } from './userSession';

import { addAlert } from '../actions/alerts';
import { getIsUpdating } from '../selectors/userActions';

import actionTypes from '../constants/actionTypes';
import api from '../constants/api';
import { actAsOrganization } from '../utils/impersonationHelpers';
import history from '../setup/history';
import { AlertMessages } from '../constants/messages';
import { PRIVACYLEVEL } from '../constants/privacyLevels';

export const updateOrganizationAvatar = ({formData, intl, noSuccessMessage}) => ({
  shouldCallAPI: state => !getIsUpdating(state),
  callAPI: () => {
    const meta = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return axiosV2Instance.post(api.UPLOAD_ORG_AVATAR, formData, meta);
  },
  actions: [
    actionTypes.UPLOAD_ORG_START,
    {
      type: actionTypes.UPLOAD_ORG_COMPLETE,
      payload: (response = {}, dispatch) => {
        if(!noSuccessMessage) {
          dispatch(addAlert({
            type: 'success',
            message: intl ? intl.formatMessage(AlertMessages.avatarSuccessfullyUpdated) : 'Profile picture successfully updated',
            dismissAfter: 3000
          }));
        }
      }
    },
    {
      type: actionTypes.UPLOAD_ORG_ERROR,
      payload: (response = {}, dispatch) => {
        dispatch(addAlert({
          type: 'error',
          message: `Error uploading avatar: ${(response.error || response).message || 'Internal server error'}`,
          dismissAfter: 4000
        }));
        return response.error;
      }
    }

  ]
});

export const updateOrg = ({form, intl}) => ({
  shouldCallAPI: state => !getIsUpdating(state),
  callAPI: () => {
    const formToSend = {...form, privacy: form.privacy !== undefined ? Number(form.privacy) : PRIVACYLEVEL.PRIVATE};
    return axiosV2Instance.put(api.UPLOAD_ORG, formToSend);
  },
  actions: [
    actionTypes.UPLOAD_ORG_START,
    {
      type: actionTypes.UPLOAD_ORG_COMPLETE,
      payload: async (response, dispatch) => {     
        dispatch(addAlert({
          type: 'success',
          message: intl ? intl.formatMessage(AlertMessages.orgSuccessfullyUpdated) : 'Organization successfully updated',
          dismissAfter: 3000
        }));
        await dispatch(refreshProfile())
      }
    },
    {
      type: actionTypes.UPLOAD_ORG_ERROR,
      payload: (response = {}, dispatch) => {
        dispatch(addAlert({
          type: 'error',
          message: (response.error && response.error.message) || response.message || 'Internal server error.',
          dismissAfter: 4000
        }));
        return response.error;
      }
    }
  ]
})
export const createNewOrg = ({form, avatarForm, intl}) => ({
  callAPI: () => {
    const formToSend = {...form, privacy: (form.privacy !== undefined) ? Number(form.privacy) : PRIVACYLEVEL.PRIVATE};
    return axiosV2Instance.post(api.UPLOAD_ORG, formToSend);
  },
  actions: [
    actionTypes.CREATE_ORG_START,
    {
      type: actionTypes.CREATE_ORG_COMPLETE,
      payload: async (response, dispatch) => {
        dispatch(addAlert({
          type: 'success',
          message: intl ? intl.formatMessage(AlertMessages.orgSuccessfullyCreated) : 'Organization successfully created',
          dismissAfter: 4000
        }));
        dispatch(addAlert({
          type: 'info',          
          message: intl ? intl.formatMessage(AlertMessages.impersonatingNewOrg) : 'You are now impersonating the newly created org',
          dismissAfter: 6000
        }));
        await actAsOrganization({organizationId: response.data.data.id, actionAfterSwitch: () => {}})
        if(avatarForm) {
          await dispatch(updateOrganizationAvatar({formData: avatarForm, intl, noSuccessMessage: true}))
      }
        await dispatch(refreshProfile())
        history.push('/')
      }
    },
    {
      type: actionTypes.UPLOAD_ORG_ERROR,
      payload: (response = {}, dispatch) => {
        dispatch(addAlert({
          type: 'error',
          message: (response.error && response.error.message) || response.message || 'Internal server error.',
          dismissAfter: 10000
        }));
        return response.error;
      }
    }
  ]
})
