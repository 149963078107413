import React from 'react'

const TrashCancel = props => <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M15.4744 3.97502L0.524415 3.97503L0.524414 2.77503L15.4744 2.77502L15.4744 3.97502Z" fill="#1F3B21"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M6.72472 6.52502V12.725H5.52472V6.52502H6.72472Z" fill="#A5B1A6"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M10.4747 6.52502V12.725H9.27472V6.52502H10.4747Z" fill="#A5B1A6"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M2.97441 2.77502V15.25C2.97441 15.2567 2.97705 15.263 2.98174 15.2677C2.98642 15.2724 2.99278 15.275 2.99941 15.275H12.9994C13.006 15.275 13.0124 15.2724 13.0171 15.2677C13.0218 15.263 13.0244 15.2567 13.0244 15.25V2.77503H14.2244V15.25C14.2244 15.5749 14.0954 15.8865 13.8656 16.1162C13.6359 16.346 13.3243 16.475 12.9994 16.475H2.99941C2.67453 16.475 2.36294 16.346 2.13321 16.1162C1.90348 15.8865 1.77441 15.5749 1.77441 15.25V2.77502H2.97441Z" fill="#1F3B21"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M4.81657 0.816877C5.16352 0.469935 5.63407 0.275024 6.12472 0.275024H9.87472C10.3654 0.275024 10.8359 0.469935 11.1829 0.816877C11.5298 1.16382 11.7247 1.63437 11.7247 2.12502V3.97502H10.5247V2.12502C10.5247 1.95263 10.4562 1.7873 10.3343 1.66541C10.2124 1.54351 10.0471 1.47502 9.87472 1.47502H6.12472C5.95233 1.47502 5.787 1.54351 5.6651 1.66541C5.5432 1.7873 5.47472 1.95263 5.47472 2.12502V3.97502H4.27472V2.12502C4.27472 1.63437 4.46963 1.16382 4.81657 0.816877Z" fill="#1F3B21"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M13.9997 18L0.99972 1.00002L1.79408 0.392572L14.7941 17.3926L13.9997 18Z" fill="#1F3B21"/>
</svg>

export default TrashCancel
