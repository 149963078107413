import React from 'react'
import './DownloadableFile.css'
import FileText from '../UI/Icons/FileText'
import DownloadFile from '../UI/Icons/DownloadFile'
import {injectIntl} from 'react-intl'
import {fileDownload} from '../../constants/messages'

const MAX_FILE_NAME_LENGTH = 35

const DownloadableFile = (
  {
    fileName,
    fileType,
    fileUrl,
    intl
  }
) => <a href={fileUrl} target={'_blank'} className={'downloadable-file design-system-text-body-xs-medium'}>
  <FileText />
  <div className={'downloadable-file__file-name'}>
    {
      fileName.length > MAX_FILE_NAME_LENGTH
        ? `${fileName.substring(0, MAX_FILE_NAME_LENGTH)}...`
        : fileName
    }
  </div>
  <div className={'downloadable-file__download-message-and-icon'}>
    <div className={'downloadable-file__file-type'}>({fileType})</div>
    {intl.formatMessage(fileDownload.fileDownload)}
    <div className={'downloadable-file__download-message-and-icon__icon'}>
      <DownloadFile />
    </div>
  </div>
</a>

export default injectIntl(DownloadableFile)