import React from 'react';

const ArrowDown = props => (
  <svg {...props} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L5 5L9 1" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
  </svg>
)


export default ArrowDown;
