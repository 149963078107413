import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Box } from 'react-layout-components';

import Text from '../../UI/Text/Text';
import NavigationPrev from '../../UI/Icons/NavigationPrev';
import NavigationNext from '../../UI/Icons/NavigationNext';

import './Pagination.css';

class Pagination extends React.Component {
  renderPaginationItem = (value, preDots = false, postDots = false) => {
    const { current, getMore } = this.props;

    return (
      <li
        key={value}
        className={cx(
          'pagination__item',
          { 'pagination__item--active': value === current }
        )}
        onClick={() => { current !== value && getMore(value); }}>
        <Text>
          {preDots && '...'}
          {value}
          {postDots && '...'}
        </Text>
      </li>
    );
  }

  render() {
    const {total, pageLength, current, getMore, className, maxItems } = this.props;

    const totalPages = Math.ceil(total / pageLength);

    const itemsToRender = Math.min(totalPages - 2, maxItems - 2);
    const itemsOnSide = Math.floor(itemsToRender / 2);

    return (
      <Box className={cx(className, 'pagination')}>
        <ul className="pagination__nav">
          <li
            className={cx(
              'pagination__item',
              'pagination__item--prev',
              { 'pagination__item--disabled': current - 1 < 1 }
            )}
            onClick={() => { current - 1 >= 1 && getMore(current - 1); }}
          >
            <NavigationPrev />
          </li>

          {this.renderPaginationItem(1, false, current - itemsOnSide > 2 && totalPages > maxItems)}

          {itemsToRender > 0 && Array.apply(null, {length: itemsToRender}).map((_, index) => {
            if (current + itemsToRender > totalPages) {
              return this.renderPaginationItem(totalPages - itemsToRender + index);
            }
            return this.renderPaginationItem(Math.max(2, current - itemsOnSide) + index);
          })}

          {totalPages > 1 
            ? this.renderPaginationItem(totalPages, current + itemsToRender < totalPages && totalPages > maxItems)
            : null
          }

          <li
            className={cx(
              'pagination__item',
              'pagination__item--prev',
              { 'pagination__item--disabled': current + 1 > totalPages }
            )}
            onClick={() => { current + 1 <= totalPages && getMore(current + 1); }}
          >
            <NavigationNext />
          </li>
        </ul>
      </Box>
    );
  };
}

Pagination.propTypes = {
  className: PropTypes.string,
  current: PropTypes.number.isRequired,
  getMore: PropTypes.func.isRequired,
  pageLength: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  maxItems: PropTypes.number.isRequired,
};

export default Pagination;
