import React from 'react'
import { injectIntl } from 'react-intl'
import './PublicDashboardNavigation.css'
import scrollToElementId from "../Utils/scrollToElementId"

const PublicDashboardNavigation = (
  {
    sectionIdsAndTitles
  }
) => <nav
  className={'public-dashboard-navigation'}
>
  {
    sectionIdsAndTitles.map(
      (sectionIdAndTitle, i) => <button
        key={`public-dashboard-nav-item-${i}`}
        className={'public-dashboard-navigation__link'}
        onClick={
          () => scrollToElementId({id: sectionIdAndTitle.id, elementToScrollId: 'public-dashboard-container'})
        }
      >
        {sectionIdAndTitle.icon ? <div className={'public-dashboard-navigation__icon'}>{sectionIdAndTitle.icon}</div> : null}
        <span className={'public-dashboard-navigation__link__text'}>{sectionIdAndTitle.title}</span>
      </button>
    )
  }

</nav>

export default injectIntl(PublicDashboardNavigation)
