import React from 'react';

const ListIcon = props => <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3Z" fill="#1F3B21" stroke="#1F3B21" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 2H19" stroke="#1F3B21" stroke-width="1.2" stroke-linecap="square" stroke-linejoin="round"/>
<path d="M2 9C2.55228 9 3 8.55228 3 8C3 7.44772 2.55228 7 2 7C1.44772 7 1 7.44772 1 8C1 8.55228 1.44772 9 2 9Z" fill="#1F3B21" stroke="#1F3B21" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 8H19" stroke="#1F3B21" stroke-width="1.2" stroke-linecap="square" stroke-linejoin="round"/>
<path d="M2 15C2.55228 15 3 14.5523 3 14C3 13.4477 2.55228 13 2 13C1.44772 13 1 13.4477 1 14C1 14.5523 1.44772 15 2 15Z" fill="#1F3B21" stroke="#1F3B21" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 14H19" stroke="#1F3B21" stroke-width="1.2" stroke-linecap="square" stroke-linejoin="round"/>
</svg>

export default ListIcon;
