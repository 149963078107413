import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Card from "../../../UI/Card/Card";
import { formatUnit, getFormattedWeight } from "../../../../utils/units";
import TinyInfoBlock from "../../../UI/TinyInfoBlock/TinyInfoBlock";
import { ButtonLink } from "@reforestum/shared-components";
import Tag from "../../../UI/Tags/Tag";
import CarbonCloud from "../../../UI/Icons/CarbonCloud";
import Coin from "../../../UI/Icons/Coin";
import DocumentWithTextIcon from "../../../UI/Icons/DocumentWithTextIcon";
import HeartIcon from "../../../UI/Icons/HeartIcon";
import SurfaceArrowsOut from "../../../UI/Icons/SurfaceArrowsOut";
import './Widget.scss';
import getCurrencySymbol from '../../../../utils/getCurrencySymbol';
import ReadMore from '../../../UI/ReadMore';
import Tooltip from '../../../UI/Tooltip/Tooltip';

const MAX_ITEMS_TO_SHOW_IN_A_PROPERTY = 3;

const RfpWidget = ({ intl, rfpWidgetData }) => {
  if (!rfpWidgetData || !rfpWidgetData.data || !rfpWidgetData.data.request) return null;

  const {
    request: {
      title,
      description,
      targetPriceMin,
      targetPriceMax,
      targetPriceUnit,
      targetVolume,
      properties
    },
    proposals,
  } = rfpWidgetData.data;

  const getRfpPriceFormatted = () => {
    return targetPriceMin || targetPriceMax ? (
      <span>
        <FormattedNumber value={targetPriceMin}/>
        {targetPriceMin && targetPriceMax && ' - '}
        {targetPriceMax && <FormattedNumber value={targetPriceMax}/>}
      </span>
    ) : null;
  }

  const renderPropertiesBlock = () => {
    const rfpPropertiesFormatted = properties && properties.map((property) => {
      const { items } = property;
      let itemsToShow = items.slice(0, MAX_ITEMS_TO_SHOW_IN_A_PROPERTY);
      let itemsToHide = items.slice(MAX_ITEMS_TO_SHOW_IN_A_PROPERTY)
      if(itemsToHide.length === 1) {
        itemsToShow = [...itemsToShow, ...itemsToHide];
        itemsToHide = [];
      }
      const itemsToHideLength = itemsToHide.length;
      return {
        ...property,
        //items to show and remaining items number if there are more than MAX_ITEMS_TO_SHOW_IN_A_PROPERTY
        items: [
          ...itemsToShow,
          ...(itemsToHideLength > 0 ? 
            [<Tooltip
              position={'bottom'}
              content={
                itemsToHide.join(', ')
              }
            >
              <span>+{itemsToHideLength}</span>
            </Tooltip>] :
            []
          )
        ],
      };
    });
  

    return (
      <div className="organization-dashboard-widget-properties-block">
          {rfpPropertiesFormatted.map((property, index) => property.items.length > 0 ? (
              <div key={index} className="organization-dashboard-widget-property">
                  <p className="organization-dashboard-widget-property-name design-system-text-label-m">
                      {property.propertyName}
                  </p>
                  <div className="organization-dashboard-widget-property-items">
                      {property.items.map((item, index) => (
                          <Tag key={index} size="small" background="light" text={
                          <span className="organization-dashboard-widget-property-item-text design-system-text-label-s">{item}</span>
                      }/>
                      ))}
                  </div>
              </div>)
          : null )}
      </div>
  )}

  const numberOfProposals = proposals.length;
  const numberOfShortlistedProposals = proposals.filter(proposal => proposal.shortlisted).length;

  const rfpPriceFormatted = getRfpPriceFormatted();
  //We multiply by 1000 becouse the recieved value is in tons
  const formattedRfpTargetCo2 = getFormattedWeight(targetVolume * 1000, intl);
  
  const tinyInfoItemsRFP = [
    targetVolume && {
        title: 
          <p style={{marginTop: '4px'}} className="design-system-text-label-m">
            <FormattedMessage id="OrganizationGeneralDashboard.rfpWidget.targetVolume" defaultMessage="Target Volume"/>
          </p>,
        value: <p className="design-system-text-body-m-medium">
                  {`${formattedRfpTargetCo2.value} ${formatUnit(intl, formattedRfpTargetCo2.unit)}`}
              </p>,
              iconComponent: <CarbonCloud/>,
        divider: true,
          },
          rfpPriceFormatted && {
              title:  
                <p  style={{marginTop: '4px'}} className="design-system-text-label-m">
                  <FormattedMessage id="OrganizationGeneralDashboard.rfpWidget.targetPrice" defaultMessage="Target Price"/>
                </p>,
              value: <p className="design-system-text-body-m-medium">
                  {rfpPriceFormatted} {getCurrencySymbol(targetPriceUnit)}/ton
              </p>, 
              iconComponent: <Coin/>,
          },
  ]

  return (
    <Card href="/rfp/0" className="organization-dashboard-widget">
      <div className="organization-dashboard-widget-content">
        <h2 className="design-system-text-label-s">
          <FormattedMessage
            id="OrganizationGeneralDashboard.rfpWidget.title"
            defaultMessage="Request for Proposals"
          />
        </h2>
        <div className="organization-dashboard-widget-main-wrapper">
          <p className="organization-dashboard-widget-main-text design-system-text-title-l-regular">
            {title}
          </p>
          <div className="organization-dashboard-widget-main-tinyInfo">
            {tinyInfoItemsRFP.map((item, index) => (
              <TinyInfoBlock key={index} {...item} />
            ))}
          </div>
        </div>
        {description && <p className="organization-dashboard-widget-content-rfp-description design-system-text-body-m-regular">
          <ReadMore text={description} isHtml cutLength={250} notExpandable/>
        </p>}
        {renderPropertiesBlock()}
        <div className="organization-dashboard-widget-content-rfp-bottom">
          <div className="organization-dashboard-widget-content-rfp-bottom-left">
            <div className="organization-dashboard-widget-content-rfp-bottom-left-item">
              <DocumentWithTextIcon className="organization-dashboard-widget-content-rfp-bottom-left-item-icon"/>
              <span className="design-system-text-body-m-regular" >
                {numberOfProposals} {numberOfProposals === 1 ? <FormattedMessage id="OrganizationGeneralDashboard.rfpWidget.proposal" defaultMessage="proposal"/> : <FormattedMessage id="OrganizationGeneralDashboard.rfpWidget.proposals" defaultMessage="proposals"/>}
              </span>
            </div>
            {(numberOfShortlistedProposals > 0) && <div className="organization-dashboard-widget-content-rfp-bottom-left-item">
              <HeartIcon className="organization-dashboard-widget-content-rfp-bottom-left-item-icon"/>
              <span className="design-system-text-body-m-regular">
                {numberOfShortlistedProposals} <FormattedMessage id="OrganizationGeneralDashboard.rfpWidget.shortlisted" defaultMessage="shortlisted"/>
              </span>
            </div>
            }
          </div>
          <ButtonLink outlineBlack size="S" to="/rfp/0">
            <SurfaceArrowsOut/>
            <FormattedMessage
              id="OrganizationGeneralDashboard.rfpWidget.button"
              defaultMessage="See details"
            />
          </ButtonLink>
        </div>
      </div>
    </Card>
  );
};

export default RfpWidget;