import actionTypes from '../constants/actionTypes';
import get from 'lodash/get';
import { createReducer } from '../utils/reducersHelpers';

export default createReducer({
  isFetchingOffsetClaimDetails: false,
  offsetClaim: undefined,
  offsetClaimErrorMessages: undefined
}, 
{
  [actionTypes.OFFSET_CLAIM_START]: state => {
    return {
      ...state,
      isFetchingOffsetClaimDetails: true
    }
  },
  [actionTypes.OFFSET_CLAIM_COMPLETE]: (state, action) => {
    return {
      ...state,
      isFetchingOffsetClaimDetails: false,
      offsetClaim: action.payload
    }
  },
  [actionTypes.OFFSET_CLAIM_ERROR]: (state, action) => {
    return {
      ...state,
      isFetchingOffsetClaimDetails: false,
      offsetClaimErrorMessages: get(action.payload, 'errors[0]')
    }
  },
  [actionTypes.OFFSET_CLAIM_ERROR_RESET]: () => ({
    isFetchingOffsetClaimDetails: false,
    offsetClaim: undefined,
    offsetClaimErrorMessages: undefined
  })
});

export const getOffsetClaimDetails = state => state.offsetClaim;
export const getIsFetchingOffsetClaimDetails = state => state.isFetchingOffsetClaimDetails;
export const getOffsetClaimErrorMessages = state => state.offsetClaimErrorMessages;