import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Container from '../components/Layout/Container/Container';
import Content from '../components/Layout/Container/Content';
import { FormattedMessage } from 'react-intl';
import './CryptoPaymentFinished.css';

export default class CryptoPaymentsFinished extends Component {

  render() {
    const status = this.props.match.params.status

    return (
      status === 'payment-complete'
      ?
      <Container>
        <Content className="crypto-payment-finished-container">
          <div className="crypto-payment-finished-title">
            <FormattedMessage
              id={'CryptoPaymentsFinished-success-title'}
              defaultMessage={'Success!'}
            />
          </div>
          <div className="crypto-payment-finished-subtitle">
            <FormattedMessage
              id={'CryptoPaymentsFinished-success-subtitle'}
              defaultMessage={'Your crypto payment has been completed with success'}
            />
          </div>
          <div className="crypto-payment-finished-info">
            <FormattedMessage
                id={'CryptoPaymentsFinished-success-info-1'}
                defaultMessage={'You will now be able to see the results of your carbon-offsetting work in '}
              />
            <a className='styled-link' href="../../projects">
              <FormattedMessage
                id={'CryptoPaymentsFinished-success-info-2'}
                defaultMessage={'My Forests'}
              />
            </a>
            <br />
            <FormattedMessage
                id={'CryptoPaymentsFinished-success-info-3'}
                defaultMessage={'A confirmation of your purchase is available from your '}
              />
            <a className='styled-link' href="../../account/forest-extensions">
              <FormattedMessage
                id={'CryptoPaymentsFinished-success-info-4'}
                defaultMessage={'Account'}
              />
            </a>
          </div>
        </Content>
      </Container>
      :
      <Container>
        <Content className="crypto-payment-finished-container">
          <div className="crypto-payment-finished-title-error">
            <FormattedMessage
              id={'CryptoPaymentsFinished-error-title'}
              defaultMessage={'Oops'}
            />
          </div>
          <div className="crypto-payment-finished-subtitle">
            <FormattedMessage
              id={'CryptoPaymentsFinished-error-subtitle'}
              defaultMessage={'Your crypto payment was not completed'}
            />
          </div>
          <div className="crypto-payment-finished-info">
            <FormattedMessage
              id={'CryptoPaymentsFinished-error-info'}
              defaultMessage={'If you feel that you are seeing this information as a result of an error, please do get in touch with the team so that we can resolve any doubts you may have'}
            />
          </div>
        </Content>
      </Container>
    );
  }
}

CryptoPaymentsFinished.propTypes = {
  status: PropTypes.string,
};