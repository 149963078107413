import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { getFormattedWeight } from '../../../utils/units';

import HeadingSmall from '../../UI/Text/HeadingSmall';
import Text from '../../UI/Text/Text';
import FormattedUnit from '../../Utils/FormattedUnit';

import './CarbonHeader.css';

const CarbonHeader = ({className, captured, emitted, isOwnUser, isTiny}) => {
  const isEmpty = !captured && !emitted;
  const parsedBalanced = parseInt(captured - emitted)

  return (
    <div className={cx(className, 'carbon-header')}>

        { isEmpty &&
        <Text color="dark">
          <FormattedMessage
            id = {'CarbonBalance.general.title.unavailable'}
            defaultMessage = {'CO₂ Balance not yet available'}
          />
        </Text>
        }
        { (!isEmpty && isOwnUser) &&
        <FormattedMessage
            id = {'CarbonBalance.title'}
            defaultMessage = {'My CO₂ Balance'}
                />
        }
        { (!isEmpty && !isOwnUser) &&
        <FormattedMessage
            id = {'CarbonBalance.general.title'}
            defaultMessage = {'CO₂ Balance'}
                />
        }

      {
        isEmpty ?
        <Text
          className="carbon-header__main"
          color="dark"
          tag="p">

          {
            isTiny ?
              <FormattedMessage
                id={'CarbonBalance.emptyText.tiny'}
                defaultMessage={'Add your first CO₂ source before starting your fight against climate change, it´s really fast!'}
              />
            :
              <FormattedMessage
                id={'CarbonBalance.emptyText'}
                defaultMessage={'Calculate your carbon footprint to measure your contributions to global warming'}
              />
          }
        </Text>
        :
        <Text
          className="carbon-header__main"
          color="white"
          tag="p">
          <FormattedNumber
            value={getFormattedWeight(parsedBalanced).value}
          /> <FormattedUnit unit={getFormattedWeight(captured - emitted).unit} />
        </Text>
      }

      {
        !isEmpty&&
        <HeadingSmall
          className="carbon-header__subtitle"
          color="white"
          tag="p">
          { captured - emitted < 0 ?
          <FormattedMessage
            id={'CarbonBalance.negative'}
            defaultMessage={'Unfortunately your emissions are greater than your captured CO₂'}
          />
          :
          <FormattedMessage
            id={'CarbonBalance.positive'}
            defaultMessage={'Your captured CO₂ is greater than your emissions'}
          />
          }
        </HeadingSmall>
      }
    </div>
  );
};

CarbonHeader.propTypes = {
  className: PropTypes.string,
  captured: PropTypes.number,
  emitted: PropTypes.number,
  isOwnUser: PropTypes.bool,
  isTiny: PropTypes.bool,
};

export default CarbonHeader;
