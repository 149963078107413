import  React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import Box from 'react-layout-components';
import { links } from '@reforestum/shared-utils'

// import history from '../../../setup/history';

import Text from '../../UI/Text/Text';
import Logo from '../../UI/Logo/Logo';
import Toggle from '../../UI/Icons/Toggle';
import { toggleNav } from '../../../constants/customEvents';

import './MobileTopbar.css';
import { getLoadStatus} from "../../../selectors/UI";
import {connect} from "react-redux";
import Search from "../../Widgets/Search/Search"
import { getIsUserAuthenticated } from '../../../selectors/userSession';
import { getIsMobileResolution } from '../../../selectors/UI';
import SecondaryNavigation from '../SecondaryNavigation/SecondaryNavigation';

const MobileTopbar = ({
  children,
  className,
  loadStatus,
  isAuthenticated,
  isMobileResolution
}) => {

  if(!isMobileResolution) return null
  
  if(loadStatus !== 'active')
    return null;

  return (
    <div>
    <div className={cx(className, 'mobile-topbar')}>
      <Box>
        <Link to={isAuthenticated ? links.getHomeUrl() : links.getProjectUrl()}>
          <Logo className="mobile-topbar__logo" />
        </Link>
        <Search small />
        <Text className="mobile-topbar__page-tracker">
          { children }
        </Text>
      </Box>
      <div className="mobile-topbar__menu-toggle"
        onClick={() => dispatchEvent(toggleNav)}>
        <Toggle />
      </div>
    </div>
    <SecondaryNavigation/>
    </div>
  );
};

MobileTopbar.propTypes = {
  className: PropTypes.string,
  loadStatus: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
};
const mapStateToProps = state => ({
  loadStatus: getLoadStatus(state),
  isAuthenticated: getIsUserAuthenticated(state),
  isMobileResolution: getIsMobileResolution(state),
});


export default connect(
  mapStateToProps,
)(MobileTopbar);
