import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Text from '../Text/Text';
import './Tags.css';
import Tooltip from '../Tooltip/Tooltip';
import Information from "../Icons/Information"

const Tag = (props) => {
    const {
        text,
        background,
        size = 'medium',
        tooltip,
        className,
        tooltipPosition,
    } = props

    return ( 
        <div className={cx("tag__wrapper", background && `tag__wrapper--${background}`, size && `tag__wrapper--size-${size}`, className)}>
            <Text className="tag__text">
                {text}
            </Text>
            {tooltip && (
                    <Tooltip
                        content={tooltip}
                        position={tooltipPosition ? tooltipPosition : 'bottom'}
                    >   
                        <Information className="tag-information__icon"/>
                    </Tooltip>
                )}
        </div>
    )
}

Tag.propTypes = {
   text: PropTypes.string,
   background: PropTypes.string,
   className: PropTypes.string,
   size: PropTypes.oneOf(['small', 'medium']),
};

export default Tag;
