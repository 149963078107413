import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { getCurrentUrlEncoded } from '../../../utils/url';

import Text from '../Text/Text';

export default (props) => {
  const { location: { pathname, search }, hideModal } = props;

  return (
    <div>
      <Text color="light">
        <FormattedMessage 
          id="Common.hasAccount"
          defaultMessage={'Already have an account?'}
        />
      </Text>
      &nbsp;
      <Link to={`/login?next=${getCurrentUrlEncoded(pathname, search)}`} onClick={hideModal}>
        <Text color="green">
          <FormattedMessage 
            id="Common.signIn" 
            defaultMessage={'Sign in'}
          />
        </Text>
      </Link>
    </div>
  );
}
