import React from 'react';
import { Text } from 'recharts';

export const calculateValueAndUnitFormatted = (unformattedValue, intlLocale) => {
  const shouldShowInMegaTons = unformattedValue > Math.pow(10, 9);
  const value = shouldShowInMegaTons ? unformattedValue/ Math.pow(10, 9) : unformattedValue / 1000;
  const unit = shouldShowInMegaTons ? 'MT' : 'T';
  const valueFormatted = (value) => new Intl.NumberFormat(`${intlLocale}`).format(value);
  const decimals = (value < 10 && value !== 0) ? 2 : 0;
  const valueAndUnitFormatted = `${valueFormatted(value.toFixed(decimals))} ${unit}`

  return valueAndUnitFormatted
}

const CustomizedYAxis = (props) => {
  const { x, y, payload, intlLocale } = props;

  return (
    <Text
      style={{ fontSize: '11px', fill: '#79897A', fontWeight: 500 }}
      x={x}
      dx={12}
      y={y}
      textAnchor="end"
      verticalAnchor="middle"
    >{calculateValueAndUnitFormatted(payload.value, intlLocale)}
    </Text>
  )
};

export default CustomizedYAxis;