import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';


import './Billing.css';
import '../CertificateAndBilling.css'
import SettingHeader from '../../SettingHeader/SettingHeader';
import invoiceStatus from '../../../../constants/invoiceStatus';
import getCurrencySymbol from '../../../../utils/getCurrencySymbol';
import { OFFSET_TYPE_SQM_SHARES } from '../../../../constants/offsetTypes';
import { getFormattedArea } from '../../../../utils/units';
import BACKEND_LOCALES from '../../../../constants/backendLocales';
import { format } from 'date-fns';
import {Button} from '@reforestum/shared-components';
import { loadFromStorage } from '../../../../utils/localStorage';

class Billing extends Component {

    componentDidMount() {
        const { fetchInvoices } = this.props
        fetchInvoices()
    }
    render() {
        const {
            settingName,
            invoices,
            token,
            intl
        } = this.props;
        
        const { locale } = intl;
        const currentlyLoadedOrgIfAny = loadFromStorage('organizationId')
        return (
            <div>
                <SettingHeader settingName={settingName}/>
                {invoices && invoices.map((invoice) => {
                    const isCompleted = invoice.status === invoiceStatus.COMPLETED
                    const isSqmShares = invoice.offset_type === OFFSET_TYPE_SQM_SHARES
                    let formattedArea = null
                    const dateLocaleFns = BACKEND_LOCALES.find((backendLocale) => backendLocale.backendKey === locale).dateFnsLocale
                    if(isSqmShares) {
                        formattedArea = getFormattedArea(invoice.sqm)
                    }
                    return (
                        <div className='certificate-or-billing-container'>
                            <div className='certificate-or-billing-container-data'>
                            <div className='certificate-or-billing-container-data-column--left'>
                                <div className='certificate-or-billing-container-data-forest-name'>
                                    {invoice.forest_name}
                                </div>
                                <div className='certificate-or-billing-container-data-amount-money'>
                                    {isCompleted ? `Paid ${invoice.total_amount} ${getCurrencySymbol(invoice.currency)}` : 
                                        `Processing ${invoice.total_amount} ${getCurrencySymbol(invoice.currency)}`
                                    }
                                </div>
                            </div>
                            <div className='certificate-or-billing-container-data-column--right'>
                                <div className='certificate-or-billing-container-data-amount'>
                                    {isSqmShares ?
                                    `${formattedArea.value} ${formattedArea.unit}` :
                                    `${invoice.vcu_amount} VCUs`}
                                </div>
                                <div className='certificate-or-billing-container-data-date'>
                                    {format(invoice.issue_date, 'DD/MM/YYYY', { locale: dateLocaleFns })}
                                </div>
                            </div>
                            </div>
                            {isCompleted && invoice.download_url && <div className='certificate-or-billing-container-download'>
                                    <Button 
                                        onClick={() => 
                                            window.open(`${invoice.download_url}?access-token=${token}${currentlyLoadedOrgIfAny ? `&orgId=${currentlyLoadedOrgIfAny}` : ''}`)} 
                                            small
                                    >Download</Button>
                            </div>}
                        </div>
                    )}
                )}
            </div>
        )
    }
}

Billing.propTypes = {
    settingName: PropTypes.string,
};

export default injectIntl(Billing);
