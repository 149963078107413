import React from 'react';

const PortfolioLinkIcon = (props) => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="24" cy="24" r="24" transform="matrix(1 0 0 -1 0 48)" fill="#D7E8CF"/>
    <path d="M37.9974 17.6341V16.7073C37.9974 15.7805 38.1018 14.8537 36.9975 14.8537H19.9991C19.3141 14.8537 18.8056 14.4949 18.4993 13.9268C18.1929 13.3588 17.6845 13 16.9994 13H13.4998C12.3955 13 11.0001 13.8299 11 14.8537V31.3427" stroke="#1F3B21" stroke-width="1.2" stroke-linecap="round"/>
    <path d="M15 18V18C15 17.7239 15.2239 17.5 15.5 17.5L34.5 17.5C34.7761 17.5 35 17.7239 35 18V18" stroke="#8DB99D" stroke-width="1.2" stroke-linecap="square" stroke-linejoin="round"/>
    <path d="M37.8246 34.1777L39.6164 20.177C39.7235 19.3401 39.0715 18.5993 38.2277 18.5993H13.3403C12.6121 18.5993 12.0056 19.1574 11.9451 19.883L10.7784 33.8837C10.7104 34.7 11.3545 35.4 12.1736 35.4H36.436C37.1404 35.4 37.7352 34.8765 37.8246 34.1777Z" stroke="#1F3B21" stroke-width="1.2"/>
    </svg>
    
);

export default PortfolioLinkIcon;
