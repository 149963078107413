import React from 'react';

import Dropdown from '../../UI/Dropdown/Dropdown';
import PopoverWithWrapper from '../../UI/Popover/PopoverWithWrapper';
import HeaderLinkWithIcon from '../HeaderLinkWithIcon';
import DropdownItem from '../../UI/Dropdown/DropdownItem';
import './HeaderExpandableSectionDesktop.css';

const HeaderExpandableSectionDesktop = ({headerLink, headerIcon, dropdownContent}) => 
    <PopoverWithWrapper 
        mouseOutClosesPopover
        routeChangeClosesPopover
        expandOnHover
        customShadow="0px 18px 36px rgba(0, 0, 0, 0.3), 0px 30px 60px rgba(50, 50, 93, 0.25)"
        renderButton={({isPopoverOpen}) => 
            <HeaderLinkWithIcon sectionExpanded={isPopoverOpen} icon={headerIcon} expandable className="topbar-nav__item">
                {headerLink}
            </HeaderLinkWithIcon>
        } 
        content={
            <Dropdown>
                {dropdownContent.filter(dC => !dC.hideInTopLevelNav).map(({icon, title, description, link}) =>
                    <DropdownItem isLink link={link} key={JSON.stringify(title)}>
                        <div className='header-expandable-section-desktopitem-wrapper'>
                            {icon}
                            <div>
                                <p className='header-expandable-section-desktopitem-title'>
                                    {title}
                                </p>
                                <p className='header-expandable-section-desktopitem-description'>
                                    {description}
                                </p>
                            </div>
                        </div>
                    </DropdownItem>
                )}

        </Dropdown>
}/>

export default HeaderExpandableSectionDesktop;