import React from 'react';

const RoundedWhitePlus = props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <circle cx="10" cy="10" r="9.5" stroke="#FAF8F6" stroke-opacity="0.4"/>
  <path d="M6.5 10H13.5" stroke="white" stroke-width="1.2" stroke-linecap="square"/>
  <path d="M10 6.5V13.5" stroke="white" stroke-width="1.2" stroke-linecap="square"/>
  </svg>
);

export default RoundedWhitePlus;
