import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import './CarbonSearch.css'

const CarbonSearch = (
{
	className,
	filter,
	placeholder,
	setFilter,
}
) => {
	return <input
		type="text"
		placeholder={placeholder}
		className={cx(className, 'carbon-search-input')}
		onChange={setFilter}
		value={filter}
		autoFocus
	/>
}

CarbonSearch.propTypes = {
	className: PropTypes.string,
	filter: PropTypes.string,
	setFilter: PropTypes.func.isRequired,
	placeholder: PropTypes.string.isRequired,
}

export default CarbonSearch
