import React from 'react';
import { FormattedMessage } from 'react-intl';
import './Widget.scss';
import { formatUnit, getFormattedWeight } from '../../../../utils/units';
import Certification from '../../../UI/Icons/Certification';
import Plant from '../../../UI/Icons/Plant';
import Card from '../../../UI/Card/Card';
import { ButtonTertiary } from '@reforestum/shared-components';
import TinyInfoBlock from '../../../UI/TinyInfoBlock/TinyInfoBlock';
import {
  MyForestSummary as MyForestSummaryTexts,
} from '../../../../constants/messages';

const PortfolioWidget = ({ portfolioWidgetData, intl }) => {
    const emptyPortfolio = portfolioWidgetData === null;
    
    const {
        captured,
        registryNumber,
        projectTypes
    } = portfolioWidgetData || {};

    const formattedCapturedCarbon = getFormattedWeight(captured);
    const tinyInfoItemsPortfolio = [
        {
            value: 
              <p className="design-system-text-body-m-medium">
                {registryNumber}
                {' '}
                {(registryNumber === 1) ? 
                      <FormattedMessage id="OrganizationGeneralDashboard.portfolioWidget.registry" defaultMessage="registry" /> :
                      <FormattedMessage id="OrganizationGeneralDashboard.portfolioWidget.registries" defaultMessage="registries"/>
                }
              </p>,
            iconComponent: <Certification/>,
            divider: true,
        },
        {
            value: <p className="design-system-text-body-m-medium">
                {projectTypes}
                {' '}
                {(projectTypes === 1) ?
                      <FormattedMessage id="OrganizationGeneralDashboard.portfolioWidget.projectType" defaultMessage="project type" /> :
                      <FormattedMessage id="OrganizationGeneralDashboard.portfolioWidget.projectTypes" defaultMessage="project types"/>
                }
            </p>, 
            iconComponent: <Plant/>,
        },
	]

  const renderPortfolioWidget = () => {
    return (
        <Card href="/portfolio" className="organization-dashboard-widget">
          <div className="organization-dashboard-widget-content">
            <h2 className="design-system-text-label-s">
              <FormattedMessage id="OrganizationGeneralDashboard.portfolioWidget.title" defaultMessage="Current Portfolio" />
            </h2>
            <div className="organization-dashboard-widget-main-wrapper">
              <p className="organization-dashboard-widget-main-text design-system-text-title-l-regular">
                {`${intl.formatNumber(formattedCapturedCarbon.value)} ${formatUnit(intl, formattedCapturedCarbon.unit)} ${intl.formatMessage(MyForestSummaryTexts.co2UnitUpdated)}`}
              </p>
              {<div className="organization-dashboard-widget-main-tinyInfo">
                {tinyInfoItemsPortfolio.map((item, index) => (
                  <TinyInfoBlock key={index} {...item} />
                ))}
              </div>}
            </div>
            <p className="organization-dashboard-widget-content-explanatory-text design-system-text-body-m-regular">
              <FormattedMessage
                id="OrganizationGeneralDashboard.portfolioWidget.explanatoryText"
                defaultMessage="We have automatically imported this information from public registries and tentatively linked it to your portfolio based on beneficiary information from retirements. You can verify and add additional contributions as necessary."
              />
            </p>
            <div className="organization-dashboard-widget-content-action-button-wrapper">
              <ButtonTertiary>
                <FormattedMessage id="OrganizationGeneralDashboard.portfolioWidget.button" defaultMessage="See portfolio details" />
              </ButtonTertiary>
            </div>
          </div>
        </Card>
      );
  }

  const renderEmptyPortfolioWidget = () => {
    return (
        <Card href="/portfolio" className="organization-dashboard-widget">
          <div className="organization-dashboard-widget-content">
            <h2 className="design-system-text-label-s">
              <FormattedMessage id="OrganizationGeneralDashboard.portfolioWidget.title" defaultMessage="Current Portfolio" />
            </h2>
            <div className="organization-dashboard-widget-main-wrapper">
              <p className="organization-dashboard-widget-main-text design-system-text-title-l-regular">
                <FormattedMessage id="OrganizationGeneralDashboard.portfolioWidget.emptyTitle" defaultMessage="Your project’s portfolio is still empty"/>
              </p>
            </div>
            <p className="organization-dashboard-widget-content-explanatory-text design-system-text-body-m-regular">
              <FormattedMessage
                id="OrganizationGeneralDashboard.portfolioWidget.emptyExplanatoryText"
                defaultMessage="Upload and manage in one single place all the projects you already support, and keep track of projects updates and their evolution over time."
              />
            </p>
            <div className="organization-dashboard-widget-content-action-button-wrapper">
              <ButtonTertiary>
                <FormattedMessage id="OrganizationGeneralDashboard.portfolioWidget.emptyPortfolioButton" defaultMessage="Start building portfolio" />
              </ButtonTertiary>
            </div>
          </div>
        </Card>
      );
  }
  
  return emptyPortfolio ? renderEmptyPortfolioWidget() : renderPortfolioWidget()
};

export default PortfolioWidget;