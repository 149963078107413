import * as fromUserSession from '../reducers/userSession';

export const getUserDetails = state =>
  fromUserSession.getUserDetails(state.userSession);

export const getIsUserAuthenticated = state =>
  fromUserSession.getIsUserAuthenticated(state.userSession);

export const getToken = state =>
  fromUserSession.getToken(state.userSession);

export const getUserLang = state =>
  fromUserSession.getUserLang(state.userSession);

export const getUseMetricWeight = state =>
  fromUserSession.getUseMetricWeight(state.userSession);

export const getUseMetricDistance = state =>
  fromUserSession.getUseMetricDistance(state.userSession);

export const getUseCelsiusForTemp = state =>
  fromUserSession.getUseCelsiusForTemp(state.userSession);

export const getCurrency = state => {
  return fromUserSession.getCurrency(state.userSession);
}

export const getBigWeight = state => {
  return fromUserSession.getBigWeight(state.userSession);
}
export const getAuthMethod = state =>
  fromUserSession.getAuthMethod(state.userSession);
