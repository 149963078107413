import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';

import Wrapper from '../components/Layout/Wrapper/Wrapper';
import AccountActivationContainer from '../containers/AccountActivationContainer';
import MobileTopbar from '../components/Header/MobileTopbar/MobileTopbar';
import { links } from '@reforestum/shared-utils';

const UserActivation = (props) => {
  const activationToken = props.match.params.token
  const accountActivationTokenToEAPIRedirectionMap = {
    'EfW5Spm2JS6hCtpeth5b0jvVMFaTcjBE': '1f571229-f38a-47a4-a9e7-8cf45a12953f',
    'Xt3MZKjSQWNSs6d5b2yoN4IUiUC0GaP8': '374a79ed-1da6-4218-ad1a-21db6abff3d6',
    'Edij-sRT3XWgVo0l-8nlDslspmpgfvGL': '7b0f5512-37a0-4f46-b9ae-cb52e957d71c',
    'q57nC9R_ypKMEQNvJbc75UfGdamlKK-9': 'e8f247c0-2e93-41cc-9215-c7feb9c6a5fe',
    '4IhJbHWlLT_ZMYCPSoasiC4dNw472DUU': 'f236a6f9-b7fc-47af-8245-d66cadb49dd7',
    'mPCgnqLI6NF3yCbLFH0KUuLqTtyUr4pf': '3f53cf22-9dc7-498e-8542-b28f3b5d8751',
    'BGY5JYF4s-0MBFxd08JVfM1Q06sXBSQn': '0b60f79d-e304-4537-825b-bbb2b3eb7750',
    '09KiEesDcKYAmSbptjKykXeoqJEfxR_A': 'd306a9ad-55d2-437d-84a7-9ea9e5b54529',
    '8BDZOP3DQ7mHDDfwEGIPW3l2iTzWjazb': '1e219adf-ec5f-4bef-a538-c3d43d9996de',
    'ZW08Dp1jGu9ANBPABopHBli9B-lyekkJ': '20d97f04-488d-45ce-9b4a-4754dff51533',
    'v5_2jNT9pWpNPWqG83wzWnKXa8QVbwDw': 'b3e2b3cc-67dd-4096-8d5b-f3d6ac420b92',
    'xVjWEa9k_x51_7g7MHlObfKEI7A4QJIP': '1d56da6c-d3fe-4bef-8e6f-4ce7a8ec2f1a',
    '8QQZjOGMhY1qiKgFjyOZ9Bo3IvZLCEIr': '1bada8e5-0136-4782-ad5e-e8a97bc00479',
    'VBJxcKSf1Kr9rNLb5SfE1rDOgzgDj1hQ': '5059cfe7-14d7-4def-9604-fbcb2b4276b5',
    'XtRcZ2OqLCB6HwJZi4MbmTRnPM7UDpZu': 'ca3d5fb7-b0c7-4cfc-ae8a-27aacfe6171b',
    '31wyC3W5KQww9WOTrsrj8-W1yrov5iR_': 'ec5dc8d2-1ce9-4604-8e2a-0e4c1a26a538',
    'EbUW04NI5cduT1uyst2OC71zWUj-XTuA': 'c147a5a9-a2a6-4022-a32d-7035091fa3b7',
    'zWhOO3vZMNtxWG8NkTg1pqwROqUybRa0': 'a68cc224-5bbd-469c-9df6-35537302a0a5',
    '_xknJBM-xGPDAQc4P-EvKdGcrKkq5d1r': '4f714df7-3def-434d-a334-cd7a21fe7675',
    'NVZyOfqyUnDi0VlA2W83fR7iorKmuAAC': 'a8014f3d-d375-4882-9a91-ba5ddf6fcf9a',
    'mCMwRTTMsezA5LR4Z0jovs_uvInntyeN': 'b549c1b8-53d3-40f8-a26e-c64185b49ed3',
    'NqgSKWb1eNTQdvVaI7TG2AnXH6xmZ_b6': '1a63b0a5-20fc-4c5c-85e0-fbad3fa8f861',
    'Spxsah9GcshMxau0pS4rBCjClOWesRU1': '3ecc5fb7-09a5-4579-84b6-3e8dd56be271',
    'QuS8FFdjnwIlzeIrR_8-FsbuArHd7EEv': '3a8da96c-8769-4ee8-b023-ececc732ce38',
    'NEVnvbVwS5qcSVNsOMhNjLnyD6TdPGWF': '9e1d850c-989b-4e16-8c30-f9f2b1bbc518',
    '8p1kaCFCzR5VhkqzyPzH1Gp6JpZ4hXA4': '68ae5e95-2714-430b-8e8f-1dc4561a3415',
    'jGYb1qYDtloQNpe_HMIdsgEokuEFV5P-': '4f108378-ad9a-4ced-9bc6-7df7c3c6d894',
    'FKzuhv4SqFyjL38UA54yjfi0QnXb2wCN': 'b19e1aeb-ceef-4199-ac0d-1bbdd4e1961b',
    'fiMJpRqJIm8_AVdNpK0wp5-RQrrRO1oI': '4e446b6f-f08f-41b4-a833-b5b4c504b1bb',
    'WTBtOTjHLe6t0DXPM52wITV1Oi5_rWjI': '4f420acd-d395-4080-9ac5-0645ee2f945e',
    'B1SFsl8x1Dzbxusdk2f6mRIAdSGazUyQ': 'fc4f8be3-78b1-45f9-ac59-05ea85920af6',
    '3t-X6lQnIOveKUSYwSQfUvekd_LI90Oq': '4076046c-b979-45d3-bee3-eb16f8c27c7b',
    '_JsbwV1hh5oq08q8cl-QZKGXgcgeGmEj': '91911f66-76ae-4367-a1fb-3bb3c50193b5',
    'L1DuZQS25iNajKguCtEkHPf-Lf2lkH8c': 'd85d5074-317e-477f-8d9c-5d2a9e9fa5d5',
    'zaYq72bn01Y5ADdLZfk_ym32o-PvQqmm': 'd4739398-f1d3-45b4-911a-c902f0fee37c',
    'iIaDvQQHP5Y-b_cDPgJ19rL_ZOiiY9b6': '2d09b2d4-e926-42e5-b3a2-8a229ae9bc4f',
    'j3uBdgVvfgG9D0sSazsM_qd4siAsGj_u': 'e47d825d-4e6e-4e1b-9c8e-2f6755dbeed6',
    'UXgG4Q3aoy4ujQZS5T5-QulVE6v7nnPK': '2ce02a06-390a-4450-a50e-fe63febab3e9',
    'j_A6cy_pxPw4wZ3KddmItjntGZ9B--Rv': 'b453b702-4f07-4fe3-bbe4-990954b039d8',
  }

  if(accountActivationTokenToEAPIRedirectionMap[activationToken]) {
    window.location.replace(links.getEnterpriseActivationUrl(accountActivationTokenToEAPIRedirectionMap[activationToken]))
  }
  else {
    return (
      <Wrapper>
        <Helmet>
          <title>
            User activation | Reforestum
          </title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <MobileTopbar>
          <FormattedMessage
            id={'Header.userActivation'}
            defaultMessage={'Backer Registration'}
          />
        </MobileTopbar>
        <AccountActivationContainer {...props} />
      </Wrapper>
    );
  }
};

export default UserActivation;
