import actionTypes from '../constants/actionTypes';

const initialState = {
  isFetching: false,
  enterpriseOffset: undefined,
  enterpriseOffsetErrorMessages: null
};

export default (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.ENTERPRISE_ACTIVATION_START:
        return {
          ...state,
          isFetching: true,
          enterpriseOffsetErrorMessages: null
        }
      case actionTypes.ENTERPRISE_ACTIVATION_COMPLETE:
        return {
          isFetching: false,
          enterpriseOffset: action.payload,
          enterpriseOffsetErrorMessages: null
        };
      case actionTypes.ENTERPRISE_ACTIVATION_ERROR:
        return {
          ...state,
          isFetching: false,
          enterpriseOffsetErrorMessages: action.payload
        }
      default:
        return state;
    }
};

export const getEnterpriseActivationOffset = state => state.enterpriseOffset;
export const getIsFetching = state => state.isFetching;
export const getEnterpriseOffsetErrorMessages = state => state.enterpriseOffsetErrorMessages;