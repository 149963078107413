import React from 'react';
import PropTypes from 'prop-types';
import Box from 'react-layout-components';
import {
  injectIntl,
  intlShape
} from 'react-intl';

import { authMessages } from '../../constants/messages';

import { getPrivacyOptionsForIndividuals } from '../../utils/getPrivacyOptions';

import VerticalCenter from '../Layout/VerticalCenter/VerticalCenter';
import CoverImage from '../Layout/CoverImage/CoverImage';
import AuthHeader from './AuthHeader';
import AuthForm from './AuthForm';
import RecoveryForm from './RecoveryForm';
import ResetForm from './ResetForm';

import { SIGNUP_IMAGE } from '../../constants/signupImage';

const Recovery = ({
  activationParameters,
  handleRecovery,
  handleReset,
  handleRegisterRedirection,
  recoveryErrorMessages,
  resetErrorMessages,
  isLoading,
  isRecoveryRequested,
  isPasswordReset,
  recoveryToken,
  intl
}) => {

  const privacyOptions = getPrivacyOptionsForIndividuals(intl);

  const renderRecoveryOrActivationTitle = () => {
    let result
    if(activationParameters.type) {
      result = <AuthHeader
        title={intl.formatMessage(authMessages.activationTitle)}
        text={intl.formatMessage(authMessages.activationText)}
      />
    }
    else {
      result = <AuthHeader
        title={intl.formatMessage(authMessages.recoveryTitle)}
        text={intl.formatMessage(authMessages.recoveryText)}
      />
    }
    return result
  }

  return (

    <Box width="100%">
  
      <CoverImage img={SIGNUP_IMAGE} />
  
      {
        recoveryToken ?
        <VerticalCenter className="recovery-sidebar">
          {renderRecoveryOrActivationTitle()}
          <AuthForm>
            <ResetForm
              activationParameters={activationParameters}
              handleReset={handleReset}
              errorMessages={resetErrorMessages}
              isLoading={isLoading}
              isPasswordReset={isPasswordReset}
              privacyOptions={privacyOptions}
            />
          </AuthForm>
        </VerticalCenter>
        :
        <VerticalCenter className="recovery-sidebar">
          <AuthHeader
            title={intl.formatMessage(authMessages.recoveryTitle)}
            text={intl.formatMessage(authMessages.recoveryText)}
          />
          <AuthForm>
            <RecoveryForm
              handleRecovery={handleRecovery}
              errorMessages={recoveryErrorMessages}
              isLoading={isLoading}
              isRecoveryRequested={isRecoveryRequested}
              handleRegisterRedirection={handleRegisterRedirection}
            />
          </AuthForm>
        </VerticalCenter>
      }
  
    </Box>
  )
};

Recovery.propTypes = {
  className: PropTypes.string,
  activationParameters: PropTypes.object,
  handleRecovery: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  recoveryErrorMessages: PropTypes.string,
  resetErrorMessages: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isRecoveryRequested: PropTypes.bool.isRequired,
  isPasswordReset: PropTypes.bool.isRequired,
  recoveryToken: PropTypes.string,
  intl: intlShape,
};

export default injectIntl(Recovery);
