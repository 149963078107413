import React, {Component} from 'react'
import {FormattedMessage, injectIntl} from 'react-intl'
import './PartyInUseInformation.css'
import {accountSwitcher} from "../../constants/messages"
import PartySwitcher from "../Header/PartySwitcher"
import {
	getUserOrImpersonatingOrg,
	getUserOrOrgAppropriateNameToShow
} from "../../utils/usersAndOrganizations"
import AvatarPlaceholder from '@reforestum/shared-components/dist/assets/images/avatar-placeholder.png'
import {urlSpecificAfterSwitchActions} from '../../utils/partySwitcher'
import { PropTypes } from 'prop-types'

class PartyInUseInformation extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isSwitcherOpen: false
		}
	}

	render() {
		const {isActivation} = this.props
		return (
			<div className={'party-in-use-information__wrapper'}>
				<div className={'party-in-use-information'}>
					<div className={'party-in-use-information__instructions'}>
						<div className={'party-in-use-information__left-column'}>
							<div
								className="party-in-use-information__user-image"
								style={{
									backgroundImage: `url(${getUserOrImpersonatingOrg(this.props.user).avatar_url || AvatarPlaceholder})`
								}}
							/>
						</div>
						<div className={'party-in-use-information__right-column'}>
							<div>
								{isActivation ? this.props.intl.formatMessage(accountSwitcher.currentlyActingPartyActivation) : this.props.intl.formatMessage(accountSwitcher.currentlyActingParty)}
								&nbsp;
								<span className={'party-in-use-information__account-name'}>
									{getUserOrOrgAppropriateNameToShow(getUserOrImpersonatingOrg(this.props.user))}
								</span>
							</div>
							<div
								className={'party-in-use-information__clickable-text-action'}
								onClick={() => this.setState({isSwitcherOpen: !this.state.isSwitcherOpen})}
							>
								<FormattedMessage
									id={'accountSwitcher.switchAccount'}
									defaultMessage={'Switch account'}
								/>
							</div>
						</div>
					</div>
				</div>
				{
					this.state.isSwitcherOpen && <div className={'party-in-use-information__switcher_container'}>
						<PartySwitcher
							user={this.props.user}
							displayCreateNew={false}
							actionAfterSwitch={urlSpecificAfterSwitchActions}
						/>
					</div>
				}
			</div>
		)
	}
}

PartyInUseInformation.propTypes = {
	user: PropTypes.object.isRequired,
	isActivation: PropTypes,
}

export default injectIntl(PartyInUseInformation)
