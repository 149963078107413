import React from 'react'
import './ProposalDetails.scss'
import {FormattedMessage, injectIntl} from 'react-intl'
import {rfpCard} from '../../constants/messages'
import MapPinSimple from '../UI/Icons/MapPinSimple'
import Plant from '../UI/Icons/Plant'
import Certification from '../UI/Icons/Certification'
import TinyInfoBlock from '../UI/TinyInfoBlock/TinyInfoBlock'
import {renderPriceString, renderVolumeString} from './helpers'
import renderTooltipForTrimmedText from '../../utils/renderTooltipForTrimmedText'
import ProposalDetailsExtras from './ProposalDetailsExtras'
import { withRouter } from 'react-router-dom'
import './ProposalDetailsOverview.scss'
import ArrowDownNoCircle from '../UI/Icons/ArrowDownNoCircle'
import ReadMore from '../UI/ReadMore'
const DEFAULT_PROJECT_IMAGE = 'https://statics.reforestum.com/rfp/proposal_fallback.png'
const PROJECT_DETAILS_MAX_TITLE_LENGTH = 40
const PROJECT_DETAILS_MAX_TAG_LENGTH = 10

const ProposalDetailsOverview = (
  {
    proposalDetails,
    extrasToRender=null,
    intl,
    navigateToProjectDetails,
  }={}
) => {

  let vintages = (proposalDetails && proposalDetails.proposal && proposalDetails.proposal.vintages) ? proposalDetails.proposal.vintages : []
  let maxVolume = (proposalDetails && proposalDetails.proposal && proposalDetails.proposal.max_volume) || null
  let minVolume = (proposalDetails && proposalDetails.proposal && proposalDetails.proposal.min_volume) || null
  let maxPrice = (proposalDetails && proposalDetails.proposal && proposalDetails.proposal.max_price) || null
  let minPrice = (proposalDetails && proposalDetails.proposal && proposalDetails.proposal.min_price) || null
  let priceUnit = (proposalDetails && proposalDetails.proposal && proposalDetails.proposal.price_unit) || 'USD'
  let projectLocation = (proposalDetails && proposalDetails.project && proposalDetails.project.location) || null
  let projectTypology = (proposalDetails && proposalDetails.project && proposalDetails.project.typology) || null
  let projectRegistry = (proposalDetails && proposalDetails.project && proposalDetails.project.registry) || null
  let projectRating = (proposalDetails && proposalDetails.project && proposalDetails.project.rating) || null
  let projectName = (proposalDetails && proposalDetails.project && proposalDetails.project.name) || null
  let projectImage = (proposalDetails && proposalDetails.project && proposalDetails.project.image) || null
  let supplierName = (proposalDetails && proposalDetails.supplier && proposalDetails.supplier.name) || null
  let projectId = (proposalDetails && proposalDetails.project && proposalDetails.project.id) || null

  const renderProjectTinyInfoBlocks = () => {
    const blocks = [
      {
        shouldRender: projectLocation,
        props: {
          className: 'rfp-card__tiny-info-block',
          title: intl.formatMessage(rfpCard.country),
          value: <span>
            <MapPinSimple />
            {(projectLocation || '').length > PROJECT_DETAILS_MAX_TAG_LENGTH
              ? renderTooltipForTrimmedText({text: projectLocation, maxLength: PROJECT_DETAILS_MAX_TAG_LENGTH, trimmedUpperCase: true})
              : projectLocation
            }
         </span>,
          unit: 'unit'
        }
      },
      {
        shouldRender: projectTypology,
        props: {
          className: 'rfp-card__tiny-info-block',
          title: intl.formatMessage(rfpCard.projectType),
          value: <span>
            <Plant />
            {
              (projectTypology || '').length > PROJECT_DETAILS_MAX_TAG_LENGTH
                ? renderTooltipForTrimmedText({text: projectTypology, maxLength: PROJECT_DETAILS_MAX_TAG_LENGTH, trimmedUpperCase: true})
                : projectTypology
            }
          </span>
        }
      },
      {
        shouldRender: projectRegistry,
        props: {
          className: 'rfp-card__tiny-info-block',
          title: intl.formatMessage(rfpCard.registry),
          value: <span>
            <Certification />
            {
              (projectRegistry || '').length > PROJECT_DETAILS_MAX_TAG_LENGTH
                ? renderTooltipForTrimmedText({text: projectRegistry, maxLength: PROJECT_DETAILS_MAX_TAG_LENGTH, trimmedUpperCase: true})
                : projectRegistry
            }
          </span>
        }
      },
      {
        shouldRender: projectRating,
        props: {
          className: 'rfp-card__tiny-info-block',
          title: intl.formatMessage(rfpCard.beZeroRating),
          value: projectRating
        }
      },
    ]

    const blocksToRender = blocks.filter(b => b.shouldRender)

    return blocksToRender
      .filter(b => b.shouldRender)
      .map(
        (b, i) => <TinyInfoBlock
          index={`tiny-info-block-${i}`}
          divider={blocksToRender.length > i + 1}
          {...b.props}
        />
      )
  }

  const renderProjectDetails = () => <div className={'rfp-p-card__project-details'}>
    <div>
      <h1>
        {
          projectName.length > PROJECT_DETAILS_MAX_TITLE_LENGTH
            ? renderTooltipForTrimmedText({text: projectName, maxLength: PROJECT_DETAILS_MAX_TITLE_LENGTH})
            : projectName
        }
      </h1>
      <span>
          <FormattedMessage id={'RfpResults.ProposalBy'} defaultMessage={'Proposal by'} />
        &nbsp;
        <span className={'rfp-p-card__supplier-name'}>
          {supplierName.length > PROJECT_DETAILS_MAX_TITLE_LENGTH
            ? renderTooltipForTrimmedText({text: supplierName, maxLength: PROJECT_DETAILS_MAX_TITLE_LENGTH})
            : supplierName
          }
        </span>
        </span>
    </div>
    <div className={'rfp-p-card__tiny-info-blocks'}>
      {renderProjectTinyInfoBlocks()}
    </div>
  </div>

  const renderProposalHighLevelBreakdown = () => {
    const hasVintages = vintages && vintages.length > 0
    const hasPrice = minPrice || maxPrice
    const hasVolume = minVolume || maxVolume
    const isSectionEmpty = !hasVintages && !hasPrice && !hasVolume
    if(isSectionEmpty) return null
    
    return (
      <div className={'rfp-p-card__proposal-breakdown'}>
        {hasVintages && vintages.length > 0 && <div className={'rfp-p-card__proposal-breakdown__item'}>
          <div className={'rfp-p-card__proposal-breakdown__item__label'}>
            <FormattedMessage
              id={`proposalDetialsOverview.vintages`}
              defaultMessage={'Vintages Included'}
            />
          </div>
          <div className={'rfp-p-card__proposal-breakdown__item__values'}>
            {
              vintages.map(
                v => <div className={'rfp-p-card__proposal-breakdown__item__values__tag'}>
                  {v}
                </div>
              )
            }
          </div>
        </div>}
        {hasVolume && <div className={'rfp-p-card__proposal-breakdown__item'}>
          <div className={'rfp-p-card__proposal-breakdown__item__label'}>
            <FormattedMessage
              id={`proposalDetialsOverview.volume`}
              defaultMessage={'Volume'}
            />
          </div>
          <div className={'rfp-p-card__proposal-breakdown__item__values'}>
            <div className={'rfp-p-card__proposal-breakdown__item__values__text'}>
              {renderVolumeString({intl, minVolume, maxVolume})}
            </div>
          </div>
        </div>}
        {hasPrice && <div className={'rfp-p-card__proposal-breakdown__item'}>
          <div className={'rfp-p-card__proposal-breakdown__item__label'}>
            <FormattedMessage
              id={`proposalDetialsOverview.price`}
              defaultMessage={'Price'}
            />
          </div>
          <div className={'rfp-p-card__proposal-breakdown__item__values'}>
            <div className={'rfp-p-card__proposal-breakdown__item__values__text'}>
              {renderPriceString({intl, minPrice, maxPrice, priceUnit})}
            </div>
          </div>
        </div>}

      </div>)
  }

  const renderProjectInfo = () => <div className={'rfp-p-card__project-image-and-info'}>
    <div className={'rfp-p-card__project-image'}>
      <img alt={'Project depiction'} src={projectImage || DEFAULT_PROJECT_IMAGE} />
    </div>
    {renderProjectDetails()}
  </div>

  const renderMain = () => proposalDetails
    ? <div className={'rfp-detail__overview'}>
      {renderProjectInfo()}
      {
        extrasToRender &&
        <ProposalDetailsExtras
          extrasToRender={extrasToRender}
        />
      }
      {projectId && proposalDetails.project.show_details &&
        <button className={'proposal-details-overview-see-details'} onClick={navigateToProjectDetails}>
          <FormattedMessage id={'proposalDetails.overview.seeProjectDetails'} defaultMessage={'See project details'} />
          <ArrowDownNoCircle />
        </button>
      }
      {!proposalDetails.project.show_details &&
        <div>
           {proposalDetails.project.detailed_location && <p className='proposal-details-overview-location'><MapPinSimple/>{proposalDetails.project.detailed_location}</p>}
            {proposalDetails.project.description && 
              <p className='proposal-details-overview-description'>
                <ReadMore isHtml text={proposalDetails.project.description} cutLength={300} minLength={260}/> 
              </p>
            }
        </div>
      }
      {renderProposalHighLevelBreakdown()}
    </div>
    : null

  return renderMain()
}


export default withRouter(injectIntl(ProposalDetailsOverview))