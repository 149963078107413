import React from 'react';
import PropTypes from 'prop-types';
import { ScrollView } from 'react-layout-components';
import { TabList, Tab, TabPanel } from 'react-tabs';
import { FormattedMessage } from 'react-intl';
import RoutedTabbed from '../../UI/Tabs/RoutedTabbed';
import ForestHeader from '../Header/ForestHeader';
import ForestInfo from '../Info/ForestInfo';
import BreadCrumb from '../../UI/BreadCrumb/BreadCrumb';
import BetaTooltip from '../../UI/Tabs/BetaTooltip'
import PatronsContainer from '../../../containers/PatronsContainer';
import LatestActivityContainer from '../../../containers/LatestActivityContainer';
import SponsorsContainer from '../../../containers/SponsorsContainer';
import ImpactContainer from '../../../containers/ImpactContainer';
import MyCredits from "../MyCredits/MyCredits";
import {OFFSET_TYPE_CREDIT} from "../../../constants/offsetTypes";
import getIsImpactAvailable from '../../../utils/getIsImpactAvailable';
import './ForestDetails.css';
import { links } from '@reforestum/shared-utils';
import getFeatureFlags from "../../../constants/featureFlags"
import { withRouter } from 'react-router-dom';
import ContributorsContainer from '../../../containers/ContributorsContainer';
import TransactionsContainer from '../../../containers/TransactionsContainer';
import HorizontalScrollWithArrowsContainer from '../../UI/HorizontalScrollWIthArrowsContainer/HorizontalScrollWIthArrowsContainer';
import Token from '../../UI/Text/Token';
import BeZero from '../BeZero/BeZero'

const FOREST_DETAILS_ID = 'FOREST_DETAILS_ID'

const ForestDetails = ({
  forest,
  openGallery,
  gallery,
  species,
  team,
  myOwnedForests,
  latestActivity,
  topContributors,
  totalContributors,
  renderedStandalone,
  tabsWithoutRouting = false,
  hideCommunity = false,
  hideEmptyTabs = false,
  resetScrollCallback = () => {},
}) => {

  const ff = getFeatureFlags()

  const resetScroll = () => {
    document.getElementById(FOREST_DETAILS_ID).scrollTo(0,0)
    resetScrollCallback()
  }

  const showSponsorsTab = getFeatureFlags().sponsorsTab && (
    (forest.virtual_sectors && forest.virtual_sectors.length > 0) || (forest.sectors && forest.sectors.length > 0)
  )

  const numberOfSponsors = (forest.virtual_sectors && forest.virtual_sectors.length) || (forest.sectors && forest.sectors.length)

  const showTransactionsTab = getFeatureFlags().transactionsTab && forest.offset_type === OFFSET_TYPE_CREDIT
  const hasSectorsOrVirtualSectors = (forest.sectors && forest.sectors.length > 0) || (forest.virtual_sectors && forest.virtual_sectors.length > 0)
  const showPatrons = !(hasSectorsOrVirtualSectors) && (forest.available || forest.reforesters_amount > 0) 
  const showContributors = getFeatureFlags().contributors && forest.offset_type === OFFSET_TYPE_CREDIT
  const hideMySharesTab= !forest.available && !(forest.user_total_vcus > 0 || forest.user_total_surface > 0)
  const isImpactAvailable = getIsImpactAvailable(forest);
  const myFilteredOwnedForests = myOwnedForests && myOwnedForests.length > 0 ? myOwnedForests.filter(myForest => myForest.id !== forest.id) : null

  const tabsAndTabPanels = [
    {
      tab: <FormattedMessage
        id={'ForestDetails.tabInfo'}
        defaultMessage={'About'}
      />,
      tabPanel: <ForestInfo
        hideCommunity={hideCommunity}
        forestId={forest.id}
        area={forest.my_surface}
        capturedCo2={forest.captured_co2}
        shortDescription={forest.short_desc}
        longDescription={forest.long_desc}
        generatedO2={forest.generated_o2}
        descriptions={forest.descriptions}
        userSurface={forest.user_total_surface}
        forestName={forest.name}
        mainImage={forest.main_image}
        gallery={gallery}
        sectors={forest.sectors}
        virtualSectors={forest.virtual_sectors}
        openGallery={(index) => () => openGallery(gallery.images, index)}
        species={species}
        team={team}
        community={forest.community}
        topContributors={topContributors}
        totalContributors={totalContributors}
        carbonCalculation={forest.carbon_calculation_desc}
        beZeroAvailable={forest.be_zero_available}
      />,
      tabPath: 'about',
      shouldShow: true
    },
    {
      tab: (
        <div className='forest-details-tab-container'>
          <FormattedMessage
            id={'ForestDetails.communities'}
            defaultMessage={'Communities'}
          />
          <Token
            content={numberOfSponsors}
            color={(window.location.pathname.indexOf("/communities") !== -1) ? 'green' : 'inactive'}
          />
        </div>
      ),
      tabPanel: <SponsorsContainer forest={forest}/>,
      tabPath: 'communities', 
      shouldShow: !hideCommunity && showSponsorsTab
    },
    {
      tab: (
        <div className='forest-details-tab-container'>
          <FormattedMessage
            id={'ForestDetails.contributors'}
            defaultMessage={'Contributors'}
          />
          {totalContributors > 0 && <Token
            content={totalContributors}
            color={(window.location.href.indexOf("/contributors") !== -1) ? 'green' : 'inactive'}
          />}
        </div>
      ),
      tabPanel: <ContributorsContainer forestId={forest.id} registry={forest.registry}/>,
      tabPath: 'contributors',
      shouldShow: showContributors && (hideEmptyTabs ? totalContributors > 0 : true)
    },
    {
      tab: <FormattedMessage
      id={'ForestDetails.transactions'}
      defaultMessage={"Transactions"}
    />,
      tabPanel: <TransactionsContainer forest={forest}/>,
      tabPath: 'transactions',
      shouldShow: showTransactionsTab
    },
    {
      tab: <FormattedMessage
        id={'ForestDetails.tabPatrons'}
        defaultMessage={'Reforesters'}
      />,
      tabPanel: <PatronsContainer forestId={forest.id} registry={forest.registry}/>,
      tabPath: 'reforesters',
      shouldShow: showPatrons
    },
    {
      tab: <FormattedMessage
        id={'ForestDetails.tabLatestActivity'}
        defaultMessage={'Latest Activity'}
      />,
      tabPanel: <LatestActivityContainer openGallery={openGallery} latestActivity={latestActivity}/>,
      tabPath: 'latest-activity',
      shouldShow: latestActivity && latestActivity.latestActivity && latestActivity.latestActivity.length > 0
    },
    {
      tab: <div>
        <FormattedMessage
          id={'ForestDetails.Tracking'}
          defaultMessage={'Tracking'}
        />
        {isImpactAvailable && <BetaTooltip />}
      </div>,
      tabPanel: <ImpactContainer
        forestName={forest.name}
        forestId={forest.id}
        isImpactAvailable={isImpactAvailable}
        formula={forest.timeline_formula}
        years={forest.co2_years}
        surface={forest.total_surface}
        plantingDate={forest.planting_date}
        totalVcus={forest.total_vcus}
        vcuRegistryUrl={forest.vcu_registry_url}
        renderedStandalone={renderedStandalone}
      />,
      tabPath: 'tracking',
      shouldShow: hideEmptyTabs ? (forest.timeline_formula || isImpactAvailable) : true
    },
    {
      tab: <FormattedMessage
        id={'ForestDetails.tabMyCredits'}
        defaultMessage={'My carbon credits'}
      />,
      tabPanel: <MyCredits forest={forest} myFilteredOwnedForests={myFilteredOwnedForests}/>,
      tabPath: 'my-shares',
      shouldShow: !hideMySharesTab && forest.offset_type === OFFSET_TYPE_CREDIT
    },
    {
      tab: <FormattedMessage
        id={'ForestDetails.tabRating'}
        defaultMessage={'Rating'}
      />,
      tabPanel: <BeZero projectId={forest.id} shouldRenderRiskSummaries={true} showLabelValues={true} />,
      tabPath: 'rating',
      shouldShow: ff.beZeroInForestInfo && forest.be_zero_available
    },
  ]

  const getTabsAndTabPanelsToShow = () => tabsAndTabPanels
    .map(
    tabAndPanel => ({
      ...tabAndPanel,
      tab: tabAndPanel.shouldShow ? <Tab key={`tab-${tabAndPanel.tabPath}`}>{tabAndPanel.tab}</Tab> : <Tab className={'hidden_tab'} key={`tab-${tabAndPanel.tabPath}`}>{tabAndPanel.tab}</Tab>,
      tabPanel: <TabPanel key={`tab-panel-${tabAndPanel.tabPath}`}>{tabAndPanel.shouldShow ? tabAndPanel.tabPanel : <div></div>}</TabPanel>
    })
  )

  return (
    <ScrollView
      id={FOREST_DETAILS_ID}
      justifyContent="flex-start"
      alignItems="center"
      flex="1"
      className="forest-details">
      <div className="forest-details__content">
        {!renderedStandalone && <BreadCrumb
            textFrom={
              <FormattedMessage
                    id={'ForestBreadcrumb.projects'}
                    defaultMessage={'Projects'}
              />
            }
            textTo={forest.name}
            linkTo={(`${links.getProjectUrl()}/map`)}
        />}
        <ForestHeader
          name={forest.name}
          location={forest.location_desc}
          available={forest.available}
          forest={forest}
          hidePurchaseButton={renderedStandalone}
        />
        <RoutedTabbed tabsWithoutRouting={tabsWithoutRouting} resetScroll={resetScroll} paths={tabsAndTabPanels.map(tabAndPanel => tabAndPanel.tabPath)}>
          <div className="forest-details__tabs">
            <HorizontalScrollWithArrowsContainer hideScrollbar centered>
              <TabList>
                {getTabsAndTabPanelsToShow().map(tabAndPanel => tabAndPanel.tab)}
              </TabList>
            </HorizontalScrollWithArrowsContainer>
          </div>
          {getTabsAndTabPanelsToShow().map(tabAndPanel => tabAndPanel.tabPanel)}
        </RoutedTabbed>
      </div>
  
    </ScrollView>
  )
};

ForestDetails.propTypes = {
  forest: PropTypes.object,
  openGallery: PropTypes.func.isRequired,
  species: PropTypes.array,
  team: PropTypes.array.isRequired,
  gallery: PropTypes.object,
  myOwnedForests: PropTypes.array,
  latestActivity: PropTypes.array.isRequired,
  topContributors: PropTypes.array,
  totalContributors: PropTypes.number,
  renderedStandalone: PropTypes.bool,
  tabsWithoutRouting: PropTypes.bool,
  hideCommunity: PropTypes.bool,
  hideEmptyTabs: PropTypes.bool,
};

export default withRouter(ForestDetails);
