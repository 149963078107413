import React from 'react'
import './PartySwitcher.css'
import {loadFromStorage} from '../../utils/localStorage'
import {injectIntl} from "react-intl"
import {accountSwitcher} from "../../constants/messages"
import AvatarPlaceholder from '@reforestum/shared-components/dist/assets/images/avatar-placeholder.png'
import PartySwitcherCard from "./PartySwitcherCard"
import {getOrganizationRoleNameFromRoleId} from "../../utils/usersAndOrganizations"
import { Link } from 'react-router-dom'
import { actAsOrganization, stopActingAsOrganization } from '../../utils/impersonationHelpers'

const PartySwitcher = (
	{
		intl,
		user,
		displayCreateNew=true,
		displaySwitchToInstruction=true,
		actionAfterSwitch=null
	}) => {
	const currentlyActingAsOrganizationId = loadFromStorage('organizationId')

	const renderInstruction = () => {
		let result = null
		if(user.organizations && user.organizations.length > 0 && displaySwitchToInstruction) {
			result = <div className={'party-switcher__leading-instruction'}>
				{intl.formatMessage(accountSwitcher.switchTo)}
			</div>
		}
		else if(!user.organizations || user.organizations.length === 0) {
			result = <div className={'party-switcher__leading-instruction'}>
				{intl.formatMessage(accountSwitcher.yourOrganizations)}
			</div>
		}
		return result 
	}

	const renderUser = () => <PartySwitcherCard
		partyName={user.display_name || `${user.name} ${user.surname}`}
		onClick={async () => await stopActingAsOrganization({actionAfterSwitch})}
		avatar={user.avatar_url || AvatarPlaceholder}
	/>

	const renderUserOrganizations = () => {
		let userHasSpecifiedParty = localStorage.getItem('ref__auto-switched-once-to-org')
		if(!userHasSpecifiedParty && user && user.organizations && user.organizations.length > 0) {
			actAsOrganization({organizationId: user.organizations[0].organization_id})
			localStorage.setItem('ref__auto-switched-once-to-org', true)
		}
		return user.organizations && user.organizations
			.filter(org => org.organization_id !== currentlyActingAsOrganizationId)
			.map(
			organization => <PartySwitcherCard
				key={`impersonable-organization-${organization.organization_id}`}
				partyName={organization.company_name}
				partyRoleString={getOrganizationRoleNameFromRoleId(organization.role, intl)}
				onClick={async () => await actAsOrganization({organizationId: organization.organization_id, actionAfterSwitch: actionAfterSwitch})}
				avatar={organization.avatar_url || AvatarPlaceholder}
			/>
		)
	}

	const renderCreateNewAccountOption = () => {
		return <Link to='/create-new-organization' className={'party-switcher__create-new'}>
			{intl.formatMessage(accountSwitcher.createNew)}
		</Link>
	}

	const renderMain = () => <div className={'party-switcher'}>
		{renderInstruction()}
		{(currentlyActingAsOrganizationId) && renderUser()}
		{renderUserOrganizations()}
		{displayCreateNew && renderCreateNewAccountOption()}
	</div>

	return renderMain()
}

export default injectIntl(PartySwitcher)
