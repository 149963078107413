import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { links } from '@reforestum/shared-utils';

import Wrapper from '../components/Layout/Wrapper/Wrapper';
import MySectorContainer from '../containers/MySectorContainer';
import MobileTopbar from '../components/Header/MobileTopbar/MobileTopbar';
import { connect } from 'react-redux';

import {
  fetchForestDetails,
  fetchMyForests,
} from '../actions/forests';
import * as selector from '../selectors/forests';
import Loader from '../components/UI/Loader/Loader';

class MySector extends Component {

  componentWillMount() {
    const {
      match: { params: { id: forestId }},
      fetchForestDetails, fetchMyForests
    } = this.props;
    fetchForestDetails(forestId);
    fetchMyForests()
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params: { id: forestId }},
      fetchForestDetails
    } = this.props;


    if(prevProps && (prevProps.match.params.id !== forestId)) {
      console.log("fetching new forest details with forestId", forestId)
      fetchForestDetails(forestId);
    }
  }

  render() {
    const {
      match: { params: { id: forestId, sectorId, shareId }},
      selectedForest,
    } = this.props;

    if(!(selectedForest)) {
      return <Loader/>
    }

    const generateSeoData = () => {
      let title, description;
      const img = selectedForest.main_image
      const imgAlt = selectedForest.description
      const canonical = `https://app.reforestum.com${links.getProjectUrl(forestId, sectorId)}`
      
      
      // Sector
      if(selectedForest.sectors && selectedForest.sectors.filter(({sector_id}) => sector_id === sectorId).length){
        const sectorData = selectedForest.sectors.filter(({sector_id}) => sector_id === sectorId)[0]
        title = sectorData.name
        description = sectorData.title
      } else if(selectedForest.virtual_sectors && selectedForest.sectors.filter(({sector_id}) => sector_id === sectorId).length) {
      // Virtual Sector
        const virtualSectors = selectedForest.virtual_sectors || selectedForest.user_forest_virtual_sectors
        const virtualSectorData = virtualSectors.filter(({forest_virtual_sector_id}) => forest_virtual_sector_id === sectorId)[0]
        title = virtualSectorData.forest_virtual_sector_name
        description = virtualSectorData.forest_virtual_sector_title
      } else {
        title = selectedForest.name
        description = selectedForest.short_desc
      }

      return { title, description, img, imgAlt, canonical } 
    }

    const seoData = generateSeoData() 
    
    const renderContainer = () => {
      const locationProps = {
        selectedForestId: forestId,
        sectorId,
        offsetType: selectedForest.offset_type,
        isForestLevel: Boolean(selectedForest && selectedForest.navigate_to_sector)
      }

      return <MySectorContainer {...this.props} {...locationProps} shareIdToZoom={Number(shareId)} />
    }
    return (

      <Wrapper>
        <Helmet>
          {/* ---- CANONICAL ---- */}
          <link rel="canonical" href={seoData.canonical} />
          
          {/* ---- SEO ---- */}
          <title>{seoData.title} | Reforestum</title>
          <meta name="description" content={seoData.description} />
        
          {/* ---- SHARING ---- */}
          {/* SHARING: Essential META Tags */}
          <meta property="og:title" content={seoData.title} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={seoData.img} />
          <meta property="og:url" content={seoData.canonical} />
          <meta name="twitter:card" content="summary_large_image" />
          {/* SHARING: Non-Essential */}
          <meta property="og:description" content={seoData.description} />
          <meta property="og:site_name" content={seoData.title} />
          <meta name="twitter:image:alt" content={seoData.imgAlt} />
          {/* SHARING: Non-Essential (Analytics Related) */}
          <meta name="twitter:site" content="@reforestum" />        
        </Helmet>
        <MobileTopbar>
          <FormattedMessage
            id="Header.projects"
            defaultMessages={'Explore projects'}
          />
        </MobileTopbar>
        {renderContainer()}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  detailErrorMessages: selector.getDetailsErrorMessages(state),
  errorMessages: selector.getErrorMessages(state),
  isFetching: selector.getIsFetching(state),
  isFetchingTrees: selector.getIsFetchingTrees(state),
  myForests: selector.getMyForests(state),
  selectedForest: selector.getForestById(state, ownProps.match.params.id),
  trees: selector.getForestTrees(state, ownProps.match.params.id),
});

export default connect(mapStateToProps, {
  fetchMyForests,
  fetchForestDetails,
})(MySector);
