import React from 'react'
import PropTypes from 'prop-types'
import './Search.css'
import '../../UI/Tags/Tags.css'
import SearchAlt from "../../UI/Icons/SearchAlt"
import { FormattedMessage, injectIntl, intlShape } from "react-intl"
import {
	searchMessages,
} from '../../../constants/messages'
import Close from "../../UI/Icons/Close"
import { connect } from "react-redux"
import { fetchMyForests } from "../../../actions/forests"
import * as selector from "../../../selectors/forests"
import { getProjectUrl, getPublicProfileUrl } from "@reforestum/shared-utils/src/links"
import { withRouter } from "react-router"
import { Link } from 'react-router-dom';
import { getProjectTypeDetails } from "../../../utils/getProjectTypeDetails"
import Certification from "../../UI/Icons/Certification"
import Text from "../../UI/Text/Text"
import Tooltip from "../../UI/Tooltip/Tooltip"
import Information from "../../UI/Icons/Information"
import Loader from "../../UI/Loader/Loader"
import { querySearch } from "../../Utils/querySearch"
import Overlay from '../../UI/Overlay/Overlay'
import { Button } from '@reforestum/shared-components'
import { getIsUserAuthenticated } from '../../../selectors/userSession'
import { loadFromStorage } from '../../../utils/localStorage'
import getFeatureFlags from '../../../constants/featureFlags'
import OrganizationsLinkIcon from '../../UI/Icons/OrganizationsLinkIcon'
import ProjectsLinkIcon from '../../UI/Icons/ProjectsLinkIcon';

const SEARCH_RESULT_TYPE_PROJECT = 'project'
const SEARCH_RESULT_TYPE_SECTOR = 'sector'
const SIDE_RESULTS_LENGTH_COMPANIES = 3
const SIDE_RESULTS_LENGTH_PROJECTS = 3
const SIDE_RESULTS_LENGTH_COMMUNITIES = 3

const LOCALSTORAGE_SEARCH_TOOLTIP_SHOWN_KEY = 'searchOverlayShownOnNavigator'

const featureFlags = getFeatureFlags()

class Search extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			searchString: '',
			searchResultsProjects: [],
			searchResultsCompanies: [],
			hasBeenClicked: false,
			inputOpen: !this.props.small,
			performSearchTimeout: null,
			showInfoTooltip: false,
		}

		this.shouldShowTooltipWithOverlay = this.shouldShowTooltipWithOverlay.bind(this)
		this.setTooltipTimerIfAppropiate = this.setTooltipTimerIfAppropiate.bind(this)

	}

	componentDidMount() {
		this.setTooltipTimerIfAppropiate()
	}

	componentWillUnmount() {
		clearTimeout(this.showTooltipTimer)
	}

	setTooltipTimerIfAppropiate() {
		clearTimeout(this.showTooltipTimer)
		if (this.shouldShowTooltipWithOverlay()) {
			this.showTooltipTimer = setTimeout(
				() => {
					if (this.shouldShowTooltipWithOverlay()) {
						this.setState(({ showInfoTooltip: true }))
						localStorage.setItem(LOCALSTORAGE_SEARCH_TOOLTIP_SHOWN_KEY, true)
					}
				},
				4000,
			);
		}
	}

	shouldShowTooltipWithOverlay() {
		return featureFlags.showTutorialSearchBarTooltip && !loadFromStorage(LOCALSTORAGE_SEARCH_TOOLTIP_SHOWN_KEY) && (window.location.pathname.indexOf('/projects') !== -1)
	}

	handleCloseSearchTooltipWithOverlay() {
		this.setState({ showInfoTooltip: false })
	}

	async getSearchResults() {
		this.setState({ isLoading: true })
		let result = await querySearch(this.state.searchString)
		const companies = (result && result.data) ? result.data.companies : []
		const projects = (result && result.data) ? result.data.projects : []
		this.setState({ searchResultsProjects: projects, searchResultsCompanies: companies, isLoading: false })
	}

	performInDepthSearch() {
		this.setState({ hasBeenClicked: false })
		this.props.history.push(`/search?term=${this.state.searchString}`)
	}

	componentDidUpdate(prevProps, prevState) {
		//Reset Tooltip timer if location changes
		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.setTooltipTimerIfAppropiate()
		}

		if(prevState.searchString !== this.state.searchString) {
			if (this.state.performSearchTimeout) {
				clearTimeout(this.state.performSearchTimeout)
			}
			const performSearchTimeout = setTimeout(this.getSearchResults.bind(this), 400)
			this.setState({ performSearchTimeout })
		}
	}

	handleCloseTooltip() {
		this.setState({ showInfoTooltip: false })
	}

	noResultsFound() {
		return this.state.searchResultsProjects.length + this.state.searchResultsCompanies.length === 0
	}

	shouldUseFullWidthResults() {
		return this.props.small
	}

	updateSearch(e) {
		this.setState({ searchString: e.target.value, hasBeenClicked: true })
	}

	handleSearchKeydown(e) {
		if(!featureFlags.openGeneralSearchFromSearch) return

		if (e.key === "Escape") {
			this.updateSearch({ target: { value: '' } })
			this.setState({ hasBeenClicked: false, searchResultsProjects: [] })
		}
		if (e.key === "Enter") {
			this.performInDepthSearch()
		}
	}

	shouldDisplaySearchResults() {
		return (this.props.small && this.state.inputOpen && this.state.hasBeenClicked) || (this.state.hasBeenClicked)
	}

	renderCloseIcon() {
		return <div
			className={'search__close-icon-container'}
			onClick={async () => {
				if (this.props.small) {
					this.setState({ inputOpen: false })
				}
				await this.updateSearch({ target: { value: '' } })
				await this.setState({ hasBeenClicked: false, searchResultsProjects: [], searchResultsCompanies: [] })
			}}
		>
			<Close width={'8'} height={'8'} stroke={'var(--ref-greyscale-800)'} viewBox={'0 0 15 15'} />
		</div>
	}

	renderSearchResultsCard(
		{
			type = SEARCH_RESULT_TYPE_PROJECT,
			item = {},
			parentId = null,
			projectInfo = {},
			key = ''
		} = {}
	) {
		const { intl } = this.props
		const projectTypeDetails = getProjectTypeDetails(projectInfo.projectType, intl)
		return <Link
			key={key}
			to={(item.id && !parentId) ? getProjectUrl(item.id) : getProjectUrl(parentId, item.id)}
			onClick={
				async () => {
					await this.updateSearch({ target: { value: '' } })
					await this.setState({ hasBeenClicked: false, searchResultsProjects: [], searchResultsCompanies: [] })
				}
			}
			className={`search-results__card ${type !== SEARCH_RESULT_TYPE_PROJECT && 'search-results__card__sector'}`}
		>
			<div className={'search-results__card__image-and-name'}>
				<div className={'search-results__card__logo'}>
					{
						<div style={{ backgroundImage: `url('${(projectInfo.logo || projectInfo.headerImage)}')` }} className="search-results__card__logo__image" alt={item.title} />
					}
					{
						(type === SEARCH_RESULT_TYPE_SECTOR && !item.noOwner) && <div
							style={{ backgroundImage: `url('${(item.logo || item.headerImage)}')` }}
							className="search-results__card__logo__company-logo-overlay"
							alt={item.title}
						/>
					}
				</div>
				<div className={'search-results__card__text'}>
					<h3>{item.title}</h3>
					<p>{item.location && item.location}</p>
					{
						(type === SEARCH_RESULT_TYPE_SECTOR) && <Link to={getProjectUrl(projectInfo.id)}>
							{projectInfo.title}
						</Link>
					}
				</div>
			</div>
			<div className={'search-results__card__tags_container'}>
				{projectTypeDetails.tagText &&
					<div className={'tag-certification__wrapper search-results__card__tag'}>
						<Text className="tag-certification__text">{projectTypeDetails.tagText}</Text>
						{projectTypeDetails.tagTooltip && (
							<Tooltip
								content={projectTypeDetails.tagTooltip}
								position={'bottom-right'}
								key={projectInfo.projectType}
							>
								<Information className="tag-information__icon" />
							</Tooltip>
						)}
					</div>
				}
				{Array.isArray(projectInfo.certifications) && projectInfo.certifications.map(
					cert => (
						<div className={"tag-certification__wrapper"} key={cert.name}>
							<Certification className="tag-certification__icon" />
							<Text className="tag-certification__text">{cert.name}</Text>
							<Tooltip
								content={cert.tooltip}
								position={'bottom-right'}
								key={cert.name}
							>
								<Information className="tag-information__icon" />
							</Tooltip>
						</div>
					)
				)}
			</div>
		</Link>
	}

	renderSearchResultsCardCompany(
		{
			company,
			key = ''
		} = {}
	) {
		return <Link
			key={key}
			to={getPublicProfileUrl(company.slug)}
			onClick={
				async () => {
					await this.updateSearch({ target: { value: '' } })
					await this.setState({ hasBeenClicked: false, searchResultsProjects: [], searchResultsCompanies: [] })
				}
			}
			className={`search-results__card search-results__card__company`}
		>
			<div className={'search-results__card__image-and-name search-results__card__image-and-name__company'}>
				<div className={'search-results__card__logo search-results__card__logo__company'}>
					<div style={{ backgroundImage: `url('${(company.logo)}')` }} className="search-results__card__logo__image search-results__card__logo__image__company" alt={company.displayName || company.name} />
				</div>
				<div className={'search-results__card__text search-results__card__text__company'}>
					<h3>{company.displayName || company.name}</h3>
				</div>
			</div>
		</Link>
	}

	renderSearchResultsForProjects() {
		const { intl } = this.props
		const noSearchTerm = this.state.searchString.length === 0
		const projectsToRender = this.state.searchResultsProjects.map(
			(projectAndSectors, i) =>
				this.renderSearchResultsCard({
					type: SEARCH_RESULT_TYPE_PROJECT,
					item: projectAndSectors.project,
					projectInfo: projectAndSectors.project,
					key: `search-results__result-${i}`
				})
		)
		const projectsToRenderLength = projectsToRender.length
		const showAllProjectsLink = (projectsToRenderLength === 0 || noSearchTerm)
		const searchQuery = (!showAllProjectsLink) ? `?name=${this.state.searchString}` : ''
		return (
			<div className={'search-results__results-set'}>
				<h2>
					{
						noSearchTerm
							? intl.formatMessage(searchMessages.searchProjectsResultsSuggestions)
							: intl.formatMessage(searchMessages.searchProjectsResults)
					}
				</h2>
				{
					projectsToRender.splice(0, SIDE_RESULTS_LENGTH_PROJECTS)
				}
				{projectsToRenderLength === 0 && !noSearchTerm && (
					<div className='search-results__card--no-results'>No results for "{this.state.searchString}"</div>
				)}
					<Link to={`/projects/list${searchQuery}`} className='search-results__card'>
						<div className='search-results__card__image-and-name'>
							<div className='search-results__card__logo'>
								<SearchAlt/>
							</div>
							<div className='search-results__card__text search-results__card__text--all-results'>
								<h3>{showAllProjectsLink ? 'See all projects' : 'See all results for Projects'}</h3>
							</div>
						</div>
					</Link>

			</div>
		)
	}

	renderSearchResultsForCommunities() {
		const { intl } = this.props
		const sectorsToRender = this.state.searchResultsProjects.map(
			(projectAndSectors, i) =>
				(projectAndSectors.sectors.length === 0)
					? null
					: projectAndSectors.sectors.map(
						(sector, j) =>
							sector.id ? this.renderSearchResultsCard({
								type: SEARCH_RESULT_TYPE_SECTOR,
								item: sector,
								parentId: projectAndSectors.project.id,
								projectInfo: projectAndSectors.project,
								key: `search-results__result-${i}-${j}`
							}) : null
					)
		)
		return <div className={'search-results__results-set'}>
			<h2>
				{
					this.state.searchString.length > 0
						? intl.formatMessage(searchMessages.searchCommunitiesResults)
						: intl.formatMessage(searchMessages.searchCommunitiesResultsSuggestions)
				}
			</h2>
			{
				sectorsToRender.splice(0, SIDE_RESULTS_LENGTH_COMMUNITIES)
			}
		</div>
	}

	renderSearchResultsForCompanies() {
		const { intl } = this.props
		const searchResultsForCompaniesLength = this.state.searchResultsCompanies.length
		const noSearchTerm = this.state.searchString.length === 0
		const showAllCompaniesLink = (searchResultsForCompaniesLength === 0 || noSearchTerm)
		const searchQuery = (!showAllCompaniesLink) ? `?name=${this.state.searchString}` : ''
		return <div className={'search-results__results-set'}>
			<h2>
				{
					noSearchTerm
						? intl.formatMessage(searchMessages.searchCompaniesResultsSuggestions)
						: intl.formatMessage(searchMessages.searchCompaniesResults)
				}
			</h2>
			{
				this.state.searchResultsCompanies.splice(0, SIDE_RESULTS_LENGTH_COMPANIES)
					.map(
						(company, i) =>
							this.renderSearchResultsCardCompany({
								company,
								key: `search-results-company-${i}`
							})
					)
			}
			{searchResultsForCompaniesLength === 0 && !noSearchTerm && (
				<div className='search-results__card--no-results'>No results for "{this.state.searchString}"</div>
			)}
				<Link to={`/organizations${searchQuery}`} className='search-results__card'>
					<div className='search-results__card__image-and-name'>
						<div className='search-results__card__logo'>
							<SearchAlt/>
						</div>
						<div className='search-results__card__text search-results__card__text--all-results'>
							<h3>{showAllCompaniesLink ? 'See all Organizations' : 'See all results for Organizations'}</h3>
						</div>
					</div>
				</Link>
		</div>
	}

	renderSpecificPageLinks() {

		const specificPageLinks = [
			{
				icon: <ProjectsLinkIcon/>,
                title: (
                    <FormattedMessage
                        id='Header.vcmSection.projectsTitle'
                        defaultMessage='Projects'
                    />
                ),
                link: '/projects/list',
				showSearchResults: (this.state.searchResultsProjects.length) > 0 && this.state.searchString.length > 0
			},
			{
                icon: <OrganizationsLinkIcon/>,
                title: (
                    <FormattedMessage
                        id='Header.vcmSection.organizationsTitle'
                        defaultMessage='Organizations'
                    />
                ),
                link: '/organizations',
				showSearchResults: (this.state.searchResultsCompanies.length) > 0 && this.state.searchString.length > 0
            },
		]	
		return (
			<ul className={'search-results__specific-page-links-container'}>
				{specificPageLinks.map(
					({ icon, title, link, showSearchResults }, i) => {
						const searchQuery = (showSearchResults) ? `?name=${this.state.searchString}` : ''
						return (
							<li key={`search-results__specific-page-link-${i}`}>
								<Link className={'search-results__specific-page-links-link'}to={`${link}${searchQuery}`}>
									<div className={'search-results__specific-page-links-link-icon'}>{icon}</div>
									<div className={'search-results__specific-page-links-link-title'}>{title}</div>
								</Link>
							</li>
						)
					}
				)}
			</ul>
		)
	}

	shouldRenderCommunityResults() {
		if(!featureFlags.showCommunitiesInSearchResults) return false
		let result = this.state.hasBeenClicked && this.state.searchResultsProjects.some(e => e.sectors && e.sectors.length > 0)
		return result
	}

	renderSearchResults() {
		return <div
			className={'search-results__void'}
			onClick={
				async () => {
					await this.updateSearch({ target: { value: '' } });
					this.setState({ hasBeenClicked: false, searchResultsProjects: [], searchResultsCompanies: [] })
				}
			}
		>
			<div className={`search-results__container ${this.shouldUseFullWidthResults() ? 'search-results__container__results-full-width' : 'search-results__container__results-dropdown'}`}>
				{this.renderSpecificPageLinks()}
				{
					<div className={'search-results__results'}>
						{this.renderSearchResultsForCompanies()}
					</div>
				}
				{
					<div className={'search-results__results'}>
						{this.renderSearchResultsForProjects()}
					</div>
				}
				{
					this.shouldRenderCommunityResults() && <div className={'search-results__results'}>
						{this.renderSearchResultsForCommunities()}
					</div>
				}
			</div>
		</div>
	}


	renderSearchBarInputOpener() {
		const { showInfoTooltip } = this.state
		return (<SearchAlt
			className={`search-bar__icon search-bar__icon__input-opener ${showInfoTooltip && 'search-bar__icon--highlighted'}`}
			width={'29'}
			height={'29'}
			stroke={"var(--ref-greyscale-800)"}
			onClick={() => {
				this.setState({ inputOpen: true, showInfoTooltip: false })
			}
			}
		/>)
	}

	renderSearchBarWithInput() {
		const { intl, autoFocus, small } = this.props
		return (<div
			onClick={() => {
				this.setState({ hasBeenClicked: true, showInfoTooltip: false })
				if (!this.state.hasBeenClicked) this.updateSearch({ target: { value: '' } })
				this.getSearchResults()
			}}
			className={
				`search-bar 
				${this.props.medium && 'search-bar--medium'} 
				${this.shouldDisplaySearchResults() ? 'search-bar__search-results-displaying' : ''} 
				${(this.state.searchString.length > 0 || this.state.hasBeenClicked || small) ? 'search-bar__ready-for-input' : ''} 
				${this.state.showInfoTooltip && 'search-bar--highlighted'}`} 
		>
			<input
				type={'text'}
				onKeyDown={this.handleSearchKeydown.bind(this)}
				autoComplete={false}
				autoFocus={small || autoFocus || false}
				onChange={this.updateSearch.bind(this)}
				value={this.state.searchString}
				placeholder={this.props.medium ? intl.formatMessage(searchMessages.searchPlaceholderMedium) : intl.formatMessage(searchMessages.searchPlaceholder)}
			/>
			{(this.state.searchString && this.state.searchString.length > 0 && this.state.isLoading) && <Loader useNormalDiv />
			}
			{(this.state.searchString.length > 0 || this.state.hasBeenClicked || small) && this.renderCloseIcon()}
			{featureFlags.openGeneralSearchFromSearch ? (
				<SearchAlt
					className={'search-bar__icon'}
					width={'29'}
					height={'29'}
					stroke={'var(--ref-greyscale-800)'}
					onClick={() => this.performInDepthSearch()}
				/>
			) : (
				<SearchAlt
					className={'search-bar__icon--no-actions'}
					width={'29'}
					height={'29'}
					stroke={'var(--ref-greyscale-800)'}
				/>
			)}
		</div>)
	}

	renderSearchBar() {
		return this.state.inputOpen ? this.renderSearchBarWithInput() : this.renderSearchBarInputOpener()
	}

	render() {
		const { small } = this.props
		const { inputOpen, showInfoTooltip } = this.state
		return (<div className={`search ${(small) ? 'search__small' : ''} ${(inputOpen) ? 'search__small__input-open' : ''}`}>
			{showInfoTooltip && <Overlay className="overlay-over-search-results" />}
			{this.renderSearchBar()}
			{showInfoTooltip && <div className="search-info-tooltip-container">
				<Tooltip
					className={`special-tooltip ${small && 'search-info-tooltip--mobile'}`}
					position={small ? "bottom-right" : "bottom"}
					alwaysOpen
					content={
						<div>
							<p>
								<FormattedMessage id="Search.InfoTooltipText" defaultMessage="You can use this search bar to look for projects and organizations and discover..." />
							</p>
							<Button
								className="special-dimiss__btn"
								id="special-dimiss__btn"
								onClick={() => this.handleCloseTooltip()}
							>
								<FormattedMessage
									id={'Search.InfoTooltipButton'}
									defaultMessage={'Not now'}
								/>
							</Button>
						</div>
					}
				/>
			</div>}
			{this.shouldDisplaySearchResults() && this.renderSearchResults()}
		</div>)
	}

}

Search.propTypes = {
	myForests: PropTypes.array,
	intl: intlShape,
}

const mapStateToProps = (state) => ({
	myForests: selector.getMyForests(state),
	isAuthenticated: getIsUserAuthenticated(state)
})


export default injectIntl(
	connect(
		mapStateToProps, {
		fetchMyForests
	}
	)(withRouter(Search))
)
