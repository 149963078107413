import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Text from '../../UI/Text/Text';
import ForestSection from '../UI/ForestSection';
import ForestTitle from '../UI/ForestTitle';
import Loader from '../../UI/Loader/Loader';

import ForestInfoGallery from './ForestInfoGallery';

import getFeatureFlags from '../../../constants/featureFlags';

import './ForestInfo.css';
import ReadMore from '../../UI/ReadMore';
import { READ_MORE_CUT_LENGTH, READ_MORE_MIN_LENGTH } from '../../../constants/readMoreForestInfo';
import { SECTOR_TYPES } from '../../../constants/sectorTypes';
import CommunitiesWidget from '../../Widgets/ForestWidgets/CommunitiesWidget';
import { orderSectorsByContributionAmount } from '../../../utils/orderSectorsByContributionAmount';
import Community from '../Community/Community';
import ContributorsWidget from '../../Widgets/ForestWidgets/ContributorsWidget';
import WidgetsContainer from '../../Widgets/ForestWidgets/WidgetsContainer';
import BeZeroRatingOnly from "../BeZero/BeZeroRatingOnly"

const ForestInfo = ({
  mainImage,
  gallery = {},
  openGallery,
  descriptions,
  shortDescription,
  longDescription,
  welcomeDescription,
  welcomeTitle,
  sectors,
  virtualSectors,
  forestId,
  species,
  team,
  forestName,
  community,
  carbonCalculation,
  topContributors,
  totalContributors,
  beZeroAvailable,
  hideCommunity = false,
}) => {

  const ff = getFeatureFlags();


  //Collaborating companies array. Sectors or virtualSectors without community sectors
  const sectorsArrayOrNull = (sectors && sectors.length && orderSectorsByContributionAmount({
    sectors: sectors.filter((sector) => sector.type !== SECTOR_TYPES.COMMUNITY),
    isSqmSharesProject: true
  })) 
    || 
    (virtualSectors && virtualSectors.length && orderSectorsByContributionAmount({
      sectors: virtualSectors.filter((sector) => !sector.forest_virtual_sector_is_community),
      isSqmSharesProject: false
    })) 
    || 
    null
  return (
    <div className="forest-info">
      <ForestSection>
        {ff.welcomeTextForestSectorProfile && 
          welcomeTitle && 
          welcomeDescription &&
            <div className="forest-info-welcome">
              <ForestTitle>
                {welcomeTitle}
              </ForestTitle>
              <Text color="light" tag="p" weight="regular">
                <span dangerouslySetInnerHTML={{__html: welcomeDescription}} />
              </Text>
            </div>
        }
        <ForestTitle>
          {shortDescription}
        </ForestTitle>
        <Text color="light" tag="p" weight="regular">
          <ReadMore text={longDescription} isHtml cutLength={READ_MORE_MIN_LENGTH} minLength={READ_MORE_CUT_LENGTH}/>
        </Text>
      </ForestSection>
       <ForestSection additionalClassNameString={'forest-section__widgets'}>
        <WidgetsContainer>
          {sectorsArrayOrNull && (sectorsArrayOrNull.length > 0) && !hideCommunity &&
            <CommunitiesWidget sectors={sectorsArrayOrNull} forestId={forestId} renderedFromProjectDetails/>
          }
          {topContributors && topContributors.length > 0 &&
            <ContributorsWidget topContributors={topContributors} totalContributors={totalContributors} forestId={forestId} renderedFromProjectDetails/>
          }
          {
            (ff.beZeroInForestInfo && beZeroAvailable) && <div className='widget-container'><BeZeroRatingOnly projectId={forestId} renderedFromProjectDetails/></div>
          }
        </WidgetsContainer>
        </ForestSection>
      {gallery && gallery.images && (gallery.images.length > 0) && <ForestSection>
        <ForestTitle badge={gallery.images && gallery.images.length}>
          <FormattedMessage 
            id={'ForestDetails.photoGallery'}
            defaultMessage={'Photo Gallery'}
          />
        </ForestTitle>

        {gallery.isFetching ? <Loader /> : (
          <ForestInfoGallery
            images={gallery.images && gallery.images}
            openGallery={openGallery}
          />
        )}
      </ForestSection>}

      {descriptions && descriptions.filter(section => section.text).map((section, index) => (
        <ForestSection key={index}>
          <ForestTitle>
            {section.title}
          </ForestTitle>
          <Text color="light" tag="p" weight="regular">
            <ReadMore text={section.text} isHtml cutLength={READ_MORE_MIN_LENGTH} minLength={READ_MORE_CUT_LENGTH}/>
          </Text>
        </ForestSection>
      ))}
      <Community
        species={species}
        forestName={forestName}
        team={team}
        community={community}
        carbonCalculation={carbonCalculation}
        openGallery={openGallery}
      />
    </div>
  );
};

ForestInfo.propTypes = {
  area: PropTypes.number,
  capturedCo2: PropTypes.number,
  shortDescription: PropTypes.string,
  longDescription: PropTypes.string,
  forestName: PropTypes.string,
  generatedO2: PropTypes.number,
  userSurface: PropTypes.number,
  url: PropTypes.array,
  mainImage: PropTypes.string,
  gallery: PropTypes.object,
  openGallery: PropTypes.func.isRequired,
  descriptions: PropTypes.array,
  sectors: PropTypes.array,
  virtualSectors: PropTypes.array,
  forestId: PropTypes.number,
  beZeroAvailable: PropTypes.bool,
  hideCommunity: PropTypes.bool,
};

export default ForestInfo;
