import React from 'react';

const RfpLinkIcon = props => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <circle cx="24" cy="24" r="24" fill="#C4E5EA"/>
  <path d="M32.7998 36.4855H23.8926H14.9855C14.7241 36.4855 14.4735 36.3817 14.2887 36.1969C14.1038 36.012 14 35.7614 14 35.5L14.1443 24.9732L14 11.9855C14 11.7241 14.1038 11.4735 14.2887 11.2887C14.4735 11.1038 14.7241 11 14.9855 11H28L33.7113 17V26.1376L33.7113 35.5394C33.7113 35.6688 33.6859 35.7576 33.6363 35.8771C33.5868 35.9967 33.5142 36.1054 33.4227 36.1969C33.3312 36.2884 33.2225 36.361 33.103 36.4105C32.9834 36.46 32.9292 36.4855 32.7998 36.4855Z" stroke="#1F3B21" stroke-width="1.2" stroke-linecap="round"/>
  <path d="M28 11V17H33.5" stroke="#1F3B21" stroke-width="1.2" stroke-linecap="round"/>
  <path d="M19.417 23.082V22.482H18.817V23.082H19.417ZM24.0003 27.4938V28.0938H24.6003V27.4938H24.0003ZM28.5838 23.082H29.1838V22.482H28.5838V23.082ZM24.0004 27.4938H23.4004V28.0938H24.0004V27.4938ZM19.417 23.682H20.792V22.482H19.417V23.682ZM20.017 24.4056V23.082H18.817V24.4056H20.017ZM22.6253 26.8938C21.163 26.8938 20.017 25.7584 20.017 24.4056H18.817C18.817 26.4639 20.5438 28.0938 22.6253 28.0938V26.8938ZM24.0003 26.8938H22.6253V28.0938H24.0003V26.8938ZM23.4003 26.1703V27.4938H24.6003V26.1703H23.4003ZM20.792 23.682C22.2543 23.682 23.4003 24.8174 23.4003 26.1703H24.6003C24.6003 24.1119 22.8735 22.482 20.792 22.482V23.682ZM28.5838 22.482H27.2088V23.682H28.5838V22.482ZM29.1838 24.4056V23.082H27.9838V24.4056H29.1838ZM25.3754 28.0938C27.4569 28.0938 29.1838 26.4639 29.1838 24.4056H27.9838C27.9838 25.7584 26.8377 26.8938 25.3754 26.8938V28.0938ZM24.0004 28.0938H25.3754V26.8938H24.0004V28.0938ZM23.4004 26.1703V27.4938H24.6004V26.1703H23.4004ZM27.2088 22.482C25.1273 22.482 23.4004 24.1119 23.4004 26.1703H24.6004C24.6004 24.8174 25.7464 23.682 27.2088 23.682V22.482Z" fill="#79897A"/>
  <path d="M24 26V30.1667" stroke="#79897A" stroke-width="1.2" stroke-linecap="square"/>
  </svg>
);

export default RfpLinkIcon;