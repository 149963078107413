import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Box } from 'react-layout-components';
import { FormattedNumber, FormattedMessage,  injectIntl, intlShape  } from 'react-intl';
import TinyInfoBlock from '../../UI/TinyInfoBlock/TinyInfoBlock';

import { getFormattedWeight, getFormattedArea, getFormattedVCUs, formatUnit } from '../../../utils/units';

import {graphMessages} from '../../../constants/messages'


import Text from '../../UI/Text/Text';
import Uppercase from '../../UI/Text/Uppercase';
import FormattedUnit from '../../Utils/FormattedUnit';

import { OFFSET_TYPE_SQM_SHARES, OFFSET_TYPE_CREDIT } from '../../../constants/offsetTypes';

import './ForestData.css';


const ForestData = ({ className, data,  intl, currency=null }) => {
  const lastYearEstimate = (data.futureEstimation && data.futureEstimation.amount)? getFormattedWeight(data.futureEstimation.amount * data.area) : 0;
  const isPredictionBlock = lastYearEstimate && lastYearEstimate.value;

  // @NOTE: This handles the public profile view details
  if (isPredictionBlock) {
    const areaData = data.offsetType === OFFSET_TYPE_CREDIT ? getFormattedVCUs(data.carbon) : getFormattedArea(data.area)
    return (
    <Box className={cx(className, 'forest-checkout-data')}>

      <TinyInfoBlock
      className="carbon-graph__tiny-info-block"
      title={intl.formatMessage(graphMessages.forestArea)}
      value={areaData.value}
      unit={areaData.unit}
      />
 
      {data.carbon > 0 && 
        <TinyInfoBlock
          className="carbon-graph__tiny-info-block"
          title={intl.formatMessage(graphMessages.totalCo2Capt)}
          value={getFormattedWeight(data.carbon).value}
          unit={getFormattedWeight(data.carbon).unit}
        />
      }
      <TinyInfoBlock
          colored
          className="carbon-graph__tiny-info-block"
          title={intl.formatMessage(
            graphMessages.yearEstimate, 
            {year: data.futureEstimation.years})
          }
          value={lastYearEstimate.value}
          unit={formatUnit(intl, lastYearEstimate.unit)}
      />

  </Box>
  )
  }

  return (

    <Box className={cx(className, 'forest-checkout-data')}>
      {data.price !== null &&
        <Box column className="forest-checkout-data__item">
          <Uppercase color="light">
            <FormattedMessage
              id={'ForestData.price'}
              defaultMessage={'Price'}
            />
          </Uppercase>
          <Text>
            {
              data.price > 0 ?
              <FormattedNumber
                value={Number(data.price)}
                style="currency" // eslint-disable-line
                currency={currency}
                currencyDisplay="symbol"
              />
              :
              '---'
            }
          </Text>
        </Box>
      }

      {data.offsetType === OFFSET_TYPE_SQM_SHARES &&
        <Box column className="forest-checkout-data__item">
          <Uppercase color="light">
            <FormattedMessage
              id={'ForestData.area'}
              defaultMessage={'Area'}
            />
          </Uppercase>
          <Text>
            {
              (data.price > 0 || data.price === null) ?
              <span>
                <FormattedNumber
                  value={getFormattedArea(data.quantity ? data.quantity : data.area).value} //@NOTES: LEGACY
                /> <FormattedUnit unit={getFormattedArea(data.quantity ? data.quantity : data.area).unit} /> {/* @NOTES: LEGACY*/} 
              </span>
              :
              '---'
            }
          </Text>
        </Box>
      }

      {data.offsetType === OFFSET_TYPE_CREDIT &&
        <Box column className="forest-checkout-data__item">
          <Uppercase color="light">
            <FormattedMessage
                id={'ForestData.carbonCredits'}
                defaultMessage={'Carbon credits'}
            />
          </Uppercase>
          <Text>
            {
              (data.price > 0 || data.price === null) ?
                  <span>
                  <FormattedNumber
                      value={getFormattedVCUs(data.carbon).value}
                  /> <FormattedUnit unit={getFormattedVCUs(data.carbon).unit} />
                </span>
                  :
                  '---'
            }
          </Text>
        </Box>
      }

      {data.carbon > 0 && 
          <Box column className="forest-checkout-data__item">
          <Uppercase color="light">
            <FormattedMessage
              id={'ForestData.carbon'}
              defaultMessage={'Carbon'}
            />
          </Uppercase>
          <Text>
            {
              (data.price > 0 || data.price === null) ?
              <span>
                <FormattedNumber
                  value={getFormattedWeight(data.carbon).value}
                /> <FormattedUnit unit={getFormattedWeight(data.carbon).unit} />
              </span>
              :
              '---'
            }
          </Text>
        </Box>
      }

    </Box>
  );
};

ForestData.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    offsetType: PropTypes.string,
    price: PropTypes.number,
    area: PropTypes.number,
    carbon: PropTypes.number,

  }).isRequired,
  currency: PropTypes.string,
  intl: intlShape,
};

export default injectIntl(ForestData);
