import { scroller } from "react-scroll"

let lastScrollTime = 0
const scrollRecoveryTime = 1000

const moveCalculatorElementToPageTopOnMobile = (referenceToStep, delay=1200, duration=500)=> {
  if (/Mobi|Android/i.test(navigator.userAgent)) {

    if(Date.now() - lastScrollTime <= scrollRecoveryTime) return

    lastScrollTime = Date.now()

    if(document.getElementById('l-content-id')) {
      document.getElementById('l-content-id').className += ' l-content-extra-padding-for-scroll'
    }
    if(document.getElementById('l-sidebar')) document.getElementById('l-sidebar').className += ' l-sidebar-hider'

    scroller.scrollTo(referenceToStep, {
      duration: duration,
      delay: delay,
      smooth: true,
      containerId: 'l-content-id',
      offset: 0 - document.getElementsByName('step-1')[0].getBoundingClientRect().height / 4
    })
  }
}

const moveCalculatorElementBackToLastPosition = () => {
  if(document.getElementById('l-content-id')) document.getElementById('l-content-id').className = 'l-content'
  if(document.getElementById('l-sidebar')) document.getElementById('l-sidebar').className = 'l-sidebar l-container'
}

export {
  moveCalculatorElementToPageTopOnMobile,
  moveCalculatorElementBackToLastPosition
}
