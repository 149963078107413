import React from 'react';

const FolderIcon = props => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M14.6871 4.5L14.6871 2.94482C14.6871 2.45862 14.7419 1.97241 14.1625 1.97241H5.24539C4.88601 1.97241 4.6193 1.78418 4.45858 1.48621C4.29786 1.18823 4.03115 1 3.67177 1H1.83588C1.25658 1 1.00003 1.43538 1 1.97241V8" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="round"/>
<path d="M14.4198 11.2441L15.1164 5.80111C15.2235 4.9642 14.5715 4.2234 13.7277 4.2234H2.62716C1.89903 4.2234 1.29246 4.78153 1.23199 5.50714L0.778427 10.9501C0.710411 11.7664 1.35454 12.4664 2.17359 12.4664H13.0312C13.7356 12.4664 14.3304 11.9429 14.4198 11.2441Z" stroke="#1F3B21" strokeWidth="1.2"/>
</svg>


);

export default FolderIcon;
