export default {
  ALERT_ADD: 'ALERT_ADD',
  ALERT_DISMISS: 'ALERT_DISMISS',
  ALERT_CLEAR: 'ALERT_CLEAR',

  AUTH_START: 'AUTH_START',
  AUTH_COMPLETE: 'AUTH_COMPLETE',
  AUTH_ERROR: 'AUTH_ERROR',
  GUEST_START: 'GUEST_START',
  GUEST_COMPLETE: 'GUEST_COMPLETE',
  GUEST_ERROR: 'GUEST_ERROR',
  AUTH_LOGOUT: 'AUTH_LOGOUT',
  AUTH_EXPIRED: 'AUTH_EXPIRED',
  REFRESH_START: 'REFRESH_START',
  REFRESH_COMPLETE: 'REFRESH_COMPLETE',
  REFRESH_ERROR: 'REFRESH_ERROR',
  RECOVERY_START: 'RECOVERY_START',
  RECOVERY_COMPLETE: 'RECOVERY_COMPLETE',
  RECOVERY_ERROR: 'RECOVERY_ERROR',
  RESET_START: 'RESET_START',
  RESET_COMPLETE: 'RESET_COMPLETE',
  RESET_ERROR: 'RESET_ERROR',
  SIGNUP_START: 'SIGNUP_START',
  SIGNUP_COMPLETE: 'SIGNUP_COMPLETE',
  SIGNUP_ERROR: 'SIGNUP_ERROR',
  UPDATE_START: 'UPDATE_START',
  UPDATE_COMPLETE: 'UPDATE_COMPLETE',
  UPDATE_ERROR: 'UPDATE_ERROR',
  UPLOAD_START: 'UPLOAD_START',
  UPLOAD_COMPLETE: 'UPLOAD_COMPLETE',
  UPLOAD_ERROR: 'UPLOAD_ERROR',
  UPLOAD_ORG_START: 'UPLOAD_ORG_START',
  UPLOAD_ORG_COMPLETE: 'UPLOAD_ORG_COMPLETE',
  UPLOAD_ORG_ERROR: 'UPLOAD_ORG_ERROR',
  CREATE_ORG_START: 'CREATE_ORG_START',
  CREATE_ORG_COMPLETE: 'CREATE_ORG_COMPLETE',
  CREATE_ORG_ERROR: 'CREATE_ORG_ERROR',
  UPLOAD_PROGRESS: 'UPLOAD_PROGRESS',
  GET_PROFILE_START: 'GET_PROFILE_START',
  GET_PROFILE_COMPLETE: 'GET_PROFILE_COMPLETE',
  GET_PROFILE_ERROR: 'GET_PROFILE_ERROR',
  GET_ACTIVATION_PROFILE_START: 'GET_ACTIVATION_PROFILE_START',
  GET_ACTIVATION_PROFILE_COMPLETE: 'GET_ACTIVATION_PROFILE_COMPLETE',
  GET_ACTIVATION_PROFILE_ERROR: 'GET_ACTIVATION_PROFILE_ERROR',
  GET_INVOICES_START: 'GET_INVOICES_START',
  GET_INVOICES_COMPLETE: 'GET_INVOICES_COMPLETE',
  GET_INVOICES_ERROR: 'GET_INVOICES_ERROR',
  NEW_PASS_START: 'NEW_PASS_START',
  NEW_PASS_COMPLETE: 'NEW_PASS_COMPLETE',
  NEW_PASS_ERROR: 'NEW_PASS_ERROR',

  TOGGLE_DISTANCE_UNITS: 'TOGGLE_DISTANCE_UNITS',
  TOGGLE_WEIGHT_UNITS: 'TOGGLE_WEIGHT_UNITS',
  TOGGLE_TEMPERATURE_UNITS: 'TOGGLE_TEMPERATURE_UNITS',
  CHANGE_CURRENCY: 'CHANGE_CURRENCY',

  GET_CERTIFICATES_START: 'GET_CERTIFICATES_START',
  GET_CERTIFICATES_COMPLETE: 'GET_CERTIFICATES_COMPLETE',
  GET_CERTIFICATES_ERROR: 'GET_CERTIFICATES_ERROR',

  LANGUAGES_START: 'LANGUAGES_START',
  LANGUAGES_COMPLETE: 'LANGUAGES_COMPLETE',
  LANGUAGES_ERROR: 'LANGUAGES_ERROR',

  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',

  COUNTRIES_START: 'COUNTRIES_START',
  COUNTRIES_COMPLETE: 'COUNTRIES_COMPLETE',
  COUNTRIES_ERROR: 'COUNTRIES_ERROR',

  USER_POPUP_TOGGLE: 'USER_POPUP_TOGGLE',
  USER_POPUP_OPEN: 'USER_POPUP_OPEN',
  USER_POPUP_CLOSE: 'USER_POPUP_CLOSE',

  CURRENCY_AND_MEASUREMENTS_POPUP_TOGGLE: 'CURRENCY_AND_MEASUREMENTS_POPUP_TOGGLE',
  CURRENCY_AND_MEASUREMENTS_POPUP_OPEN: 'CURRENCY_AND_MEASUREMENTS_POPUP_OPEN',
  CURRENCY_AND_MEASUREMENTS_POPUP_CLOSE: 'CURRENCY_AND_MEASUREMENTS_POPUP_CLOSE',

  CALC_START: 'CALC_REQUEST',
  CALC_COMPLETE: 'CALC_COMPLETE',
  CALC_ERROR: 'CALC_ERROR',
  CALC_ADD_NEXT_POSSIBLE_STEP_VALUES: 'CALC_ADD_NEXT_POSSIBLE_STEP_VALUES',
  CALC_STEP_START: 'CALC_STEP_START',
  CALC_STEP_COMPLETE: 'CALC_STEP_COMPLETE',
  CALC_STEP_ERROR: 'CALC_STEP_ERROR',
  CALC_STEP_CLEAR: 'CALC_STEP_CLEAR',
  CALCULATOR_MANUALLY_SET_FORM_VALUES: 'CALCULATOR_MANUALLY_SET_FORM_VALUES',
  CALCULATOR_RESET_MANUALLY_SET_FORM_VALUES: 'CALCULATOR_RESET_MANUALLY_SET_FORM_VALUES',
  CALCULATOR_CONVERT_STEP_INDEX_TO_MILES: 'CALCULATOR_CONVERT_STEP_INDEX_TO_MILES',

  CARBON_FETCH_START: 'CARBON_FETCH_START',
  CARBON_FETCH_COMPLETE: 'CARBON_FETCH_COMPLETE',
  CARBON_FETCH_ERROR: 'CARBON_FETCH_ERROR',
  CARBON_ADD_START: 'CARBON_ADD_START',
  CARBON_ADD_COMPLETE: 'CARBON_ADD_COMPLETE',
  CARBON_ADD_ERROR: 'CARBON_ADD_ERROR',
  CARBON_REMOVE_START: 'CARBON_REMOVE_START',
  CARBON_REMOVE_COMPLETE: 'CARBON_REMOVE_COMPLETE',
  CARBON_REMOVE_ERROR: 'CARBON_REMOVE_ERROR',
  CARBON_UPDATE_START: 'CARBON_UPDATE_START',
  CARBON_UPDATE_COMPLETE: 'CARBON_UPDATE_COMPLETE',
  CARBON_UPDATE_ERROR: 'CARBON_UPDATE_ERROR',
  CARBON_EDIT_START: 'CARBON_EDIT_START',
  CARBON_EDIT_COMPLETE: 'CARBON_EDIT_COMPLETE',
  CARBON_EDIT_ERROR: 'CARBON_EDIT_ERROR',
  CARBON_PREPARE_EDIT: 'CARBON_PREPARE_EDIT',
  CARBON_EXIT_EDIT_MODE: 'CARBON_EXIT_EDIT_MODE',
  SET_CARBON_FILTER: 'SET_CARBON_FILTER',

  FORESTS_SUMMARY_START: 'FOREST_SUMMARY_START',
  FORESTS_SUMMARY_COMPLETE: 'FOREST_SUMMARY_COMPLETE',
  FORESTS_SUMMARY_ERROR: 'FOREST_SUMMARY_ERROR',

  FORESTS_FETCH_START: 'FORESTS_FETCH_START',
  FORESTS_FETCH_COMPLETE: 'FORESTS_FETCH_COMPLETE',
  FORESTS_FETCH_ERROR: 'FORESTS_FETCH_ERROR',

  MY_FORESTS_START: 'MY_FORESTS_START',
  MY_FORESTS_COMPLETE: 'MY_FORESTS_COMPLETE',
  MY_FORESTS_ERROR: 'MY_FORESTS_ERROR',

  FORESTS_DETAILS_START: 'FORESTS_DETAILS_START',
  FORESTS_DETAILS_COMPLETE: 'FORESTS_DETAILS_COMPLETE',
  FORESTS_DETAILS_ERROR: 'FORESTS_DETAILS_ERROR',

  REFORESTER_SHARES_START: 'REFORESTER_SHARES_START',
  REFORESTER_SHARES_COMPLETE: 'REFORESTER_SHARES_COMPLETE',
  REFORESTER_SHARES_ERROR: 'REFORESTER_SHARES_ERROR',

  FORESTS_TREES_START: 'FOREST_TREES_START',
  FORESTS_TREES_COMPLETE: 'FOREST_TREES_COMPLETE',
  FORESTS_TREES_ERROR: 'FOREST_TREES_ERROR',

  FOREST_PATRONS_RESET: 'FOREST_PATRONS_RESET',
  FOREST_PATRONS_START: 'FOREST_PATRONS_START',
  FOREST_PATRONS_COMPLETE: 'FOREST_PATRONS_COMPLETE',
  FOREST_PATRONS_ERROR: 'FOREST_PATRONS_ERROR',

  FOREST_CONTRIBUTORS_RESET: 'FOREST_CONTRIBUTORS_RESET',
  FOREST_CONTRIBUTORS_START: 'FOREST_CONTRIBUTORS_START',
  FOREST_CONTRIBUTORS_COMPLETE: 'FOREST_CONTRIBUTORS_COMPLETE',
  FOREST_CONTRIBUTORS_ERROR: 'FOREST_CONTRIBUTORS_ERROR',

  PROJECT_VINTAGES_RESET: 'PROJECT_VINTAGES_RESET',
  PROJECT_VINTAGES_START: 'PROJECT_VINTAGES_START',
  PROJECT_VINTAGES_COMPLETE: 'PROJECT_VINTAGES_COMPLETE',
  PROJECT_VINTAGES_ERROR: 'PROJECT_VINTAGES_ERROR',

  FOREST_TRANSACTIONS_RESET: 'FOREST_TRANSACTIONS_RESET',
  FOREST_TRANSACTIONS_START: 'FOREST_TRANSACTIONS_START',
  FOREST_TRANSACTIONS_COMPLETE: 'FOREST_TRANSACTIONS_COMPLETE',
  FOREST_TRANSACTIONS_ERROR: 'FOREST_TRANSACTIONS_ERROR',

  FOREST_GALLERY_START: 'FOREST_GALLERY_START',
  FOREST_GALLERY_COMPLETE: 'FOREST_GALLERY_COMPLETE',
  FOREST_GALLERY_ERROR: 'FOREST_GALLERY_ERROR',

  FOREST_GALLERIES_START: 'FOREST_GALLERIES_START',
  FOREST_GALLERIES_COMPLETE: 'FOREST_GALLERIES_COMPLETE',
  FOREST_GALLERIES_ERROR: 'FOREST_GALLERIES_ERROR',

  FOREST_LATEST_ACTIVITY_START: 'FOREST_LATEST_ACTIVITY_START',
  FOREST_LATEST_ACTIVITY_COMPLETE: 'FOREST_LATEST_ACTIVITY_COMPLETE',
  FOREST_LATEST_ACTIVITY_ERROR: 'FOREST_LATEST_ACTIVITY_ERROR',

  FOREST_MRV_GRAPH_START: 'FOREST_MRV_GRAPH_START',
  FOREST_MRV_GRAPH_COMPLETE: 'FOREST_MRV_GRAPH_COMPLETE',
  FOREST_MRV_GRAPH_ERROR: 'FOREST_MRV_GRAPH_ERROR',

  FOREST_IMPACT_TAB_OPENED: 'FOREST_IMPACT_TAB_OPENED',
  FOREST_UPDATE_MRV_RASTER_SOURCES: 'FOREST_UPDATE_MRV_RASTER_SOURCES',
  FOREST_RESET_MRV: 'FOREST_RESET_MRV',
  FORESTS_HIDE_MRV_SNACK: 'FORESTS_HIDE_MRV_SNACK',
  FOREST_UPDATE_SHOW_ONLY_GRAPH_CONTROLLED_LAYERS: 'FOREST_UPDATE_SHOW_ONLY_GRAPH_CONTROLLED_LAYERS',

  BALANCE_START: 'BALANCE_START',
  BALANCE_COMPLETE: 'BALANCE_COMPLETE',
  BALANCE_ERROR: 'BALANCE_ERROR',

  GALLERY_OPEN: 'GALLERY_OPEN',
  GALLERY_CLOSE: 'GALLERY_CLOSE',

  PRICE_START: 'PRICE_START',
  PRICE_COMPLETE: 'PRICE_COMPLETE',
  PRICE_ERROR: 'PRICE_ERROR',

  SWITCH_PAYMENT_TYPE: 'SWITCH_PAYMENT_TYPE',
  CRYPTO_CHECKOUT_PAY_START: 'CRYPTO_CHECKOUT_PAY_START',
  CHECKOUT_PAY_START: 'CHECKOUT_PAY_START',
  CHECKOUT_PAY_COMPLETE: 'CHECKOUT_PAY_COMPLETE',
  CRYPTO_CHECKOUT_PAY_COMPLETE: 'CRYPTO_CHECKOUT_PAY_COMPLETE',
  CHECKOUT_PAY_ERROR: 'CHECKOUT_PAY_ERROR',
  CRYPTO_CHECKOUT_PAY_ERROR: 'CRYPTO_CHECKOUT_PAY_ERROR',
  CHECKOUT_TOGGLE_BILLING: 'CHECKOUT_TOGGLE_BILLING',
  CHECKOUT_RESET: 'CHECKOUT_RESET',

  LAST_PURCHASE_START: 'LAST_PURCHASE_START',
  LAST_PURCHASE_COMPLETE: 'LAST_PURCHASE_COMPLETE',
  LAST_PURCHASE_ERROR: 'LAST_PURCHASE_ERROR',

  PUBLIC_PROFILE_START: 'PUBLIC_PROFILE_START',
  PUBLIC_PROFILE_COMPLETE: 'PUBLIC_PROFILE_COMPLETE',
  PUBLIC_PROFILE_ERROR: 'PUBLIC_PROFILE_ERROR',

  COMMUNITY_DATA_START: 'COMMUNITY_DATA_START',
  COMMUNITY_DATA_COMPLETE: 'COMMUNITY_DATA_COMPLETE',
  COMMUNITY_DATA_ERROR: 'COMMUNITY_DATA_ERROR',

  LOAD_STATUS: 'LOAD_STATUS',

  SET_IS_MOBILE_RESOLUTION: 'SET_IS_MOBILE_RESOLUTION',

  MODAL_SHOW: 'MODAL_SHOW',
  MODAL_HIDE: 'MODAL_HIDE',

  SET_MIXPANEL_ID: 'SET_MIXPANEL_ID',

  ENTERPRISE_ACTIVATION_START: 'ENTERPRISE_ACTIVATION_START',
  ENTERPRISE_ACTIVATION_COMPLETE: 'ENTERPRISE_ACTIVATION_COMPLETE',
  ENTERPRISE_ACTIVATION_ERROR: 'ENTERPRISE_ACTIVATION_ERROR',

  ENTERPRISE_ACTIVATION_COMPANY_DETAILS_START: 'ENTERPRISE_ACTIVATION__COMPANY_DETAILS_START',
  ENTERPRISE_ACTIVATION__COMPANY_DETAILS_COMPLETE: 'ENTERPRISE_ACTIVATION__COMPANY_DETAILS_COMPLETE',
  ENTERPRISE_ACTIVATION__COMPANY_DETAILS_ERROR: 'ENTERPRISE_ACTIVATION__COMPANY_DETAILS_ERROR',

  OFFSET_CLAIM_START: 'OFFSET_CLAIM_START',
  OFFSET_CLAIM_COMPLETE: 'OFFSET_CLAIM_COMPLETE',
  OFFSET_CLAIM_ERROR: 'OFFSET_CLAIM_ERROR',
  OFFSET_CLAIM_ERROR_RESET: 'OFFSET_CLAIM_ERROR_RESET',

  MY_CONTRIBUTIONS_START: 'MY_CONTRIBUTIONS_START',
  MY_CONTRIBUTIONS_COMPLETE: 'MY_CONTRIBUTIONS_COMPLETE',
  MY_CONTRIBUTIONS_ERROR: 'MY_CONTRIBUTIONS_ERROR'
};
