import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import store from '../setup/store';

import { connect } from 'react-redux';

// reducers
import {
  getUserDetails,
  getIsUserAuthenticated
} from '../selectors/userSession';
import { getIsUserPopupOpen, getIsPreferencesSwitcherPopupOpen, getLoadStatus } from '../selectors/UI';
import {
  getIsFetchingOffsetClaimDetails,
  getOffsetClaimErrorMessages,
  getOffsetClaimDetails
} from '../selectors/offsetClaim';

// actions
import { toggleUserDropDown, togglePreferencesSwitcherPopup } from '../actions/UI';
import { logout } from '../actions/userSession';
import { addAlert } from '../actions/alerts';
import { offsetClaiming, resetOffsetClaimErrors } from '../actions/enterpriseActivation'
import { fetchMyContributionsInPublicProfile } from '../actions/publicProfileMyContributions';

// localstorage
import { loadFromStorage, removeFromStorage } from '../utils/localStorage';

// components
import Header from '../components/Header/Header';

// constants
import { NAV_PROFILES } from '../constants/navProfiles';
import { ACTIVATION_STATUS } from '../constants/enterpriseLanding';
import { OffsetActivationAlerts } from '../constants/messages';
import { links } from '@reforestum/shared-utils';
import Modal from '../components/UI/Modal/Modal';
import PartyInUseInformation from '../components/Checkout/PartyInUseInformation';
import { Button } from '@reforestum/shared-components';
import { enviroment } from '../constants/config';
import { getUserOrImpersonatingOrg, getUserType } from '../utils/usersAndOrganizations';
import USER_TYPE from '../constants/userType';

class HeaderContainer extends Component {
  constructor(props){
    super(props)
    this.state = {
      offsetClaimErrors: undefined,
      offsetClaim: undefined,
      showConfirmSelectedAccountModalBeforeActivating: false,
    }

    this.handleOffsetClaimingClick = this.handleOffsetClaimingClick.bind(this);
    this.claimOffsets = this.claimOffsets.bind(this);
    this.renderSuccessActivationAlert = this.renderSuccessActivationAlert.bind(this);
    this.renderErrorAlertWhileClaiming = this.renderErrorAlertWhileClaiming.bind(this);
    this.renderConfirmSelectedAccountModalBeforeActivating = this.renderConfirmSelectedAccountModalBeforeActivating.bind(this)
  }

  componentWillReceiveProps(newProps){
    const enterpriseOffsetDetails = loadFromStorage('enterpriseOffset') || {};

    if(enterpriseOffsetDetails.status === ACTIVATION_STATUS.PENDING // check that avoids repetition of alerts
      && this.state.offsetClaimErrors !== newProps.offsetClaimErrorMessages
      && newProps.isAuthenticated
    ){
      this.setState({ offsetClaimErrors: newProps.offsetClaimErrorMessages });
      // if errors while claiming, render error alert
      this.renderErrorAlertWhileClaiming();
    }

    if(this.state.offsetClaim !== newProps.offsetClaim
      && newProps.isAuthenticated
    ){
      this.setState({ offsetClaim: newProps.offsetClaim });
      // if no errors, render success alert
      this.renderSuccessActivationAlert();
    }

    if(this.props.isFetchingOffsetClaimDetails
      && !newProps.isFetchingOffsetClaimDetails
      && newProps.offsetClaim){
        const companySlug = loadFromStorage("companySlug")
        this.props.fetchMyContributionsInPublicProfile(companySlug)
        removeFromStorage("companySlug")
    }
  }

  componentWillUnmount(){
    this.props.resetOffsetClaimErrors();
  }

  renderSuccessActivationAlert(){
    const { intl } = this.props;
    store.dispatch(addAlert({
      type: 'success',
      message: intl.formatMessage(OffsetActivationAlerts.activatedSuccessfully),
      dismissAfter: 5000
    }))
  }

  renderErrorAlertWhileClaiming(){
    const { intl } = this.props;
    store.dispatch(addAlert({
      type: 'error',
      message: intl.formatMessage(OffsetActivationAlerts.errosWhileClaiming),
      dismissAfter: 8000
    }))
  }

  handleOffsetClaimingClick(){
    const { user } = this.props;
    const enterpriseOffsetDetails = loadFromStorage('enterpriseOffset') || {};
    const { activationId } = enterpriseOffsetDetails;
    if(user.organizations && user.organizations.length > 0) {
      this.setState({showConfirmSelectedAccountModalBeforeActivating: true})
    } else {
      this.claimOffsets(activationId)
    }
  }

  claimOffsets(){
    const { offsetClaiming } = this.props;
    const enterpriseOffsetDetails = loadFromStorage('enterpriseOffset') || {};
    const { activationId } = enterpriseOffsetDetails;
    // instead of using the offsetClaiming callback, errors and success is handled by Redux props
    offsetClaiming(activationId)
  }

  renderConfirmSelectedAccountModalBeforeActivating() {
    const {user} = this.props
    return <Modal content={
      <div>
        <PartyInUseInformation isActivation user={user}/>
        <Button onClick={() => {
          this.claimOffsets()
          this.setState({showConfirmSelectedAccountModalBeforeActivating: false})
          }}><FormattedMessage defaultMessage="continue" id="OffsetActivation.accountSelectModalConfirmButton"/></Button>
      </div>
    } clickClose={() => this.setState({showConfirmSelectedAccountModalBeforeActivating: false})} />
  }

  renderMessageIfReforestumImpersonatingOtherOrgInProduction() {
    const {user} = this.props
    const userOrOrg = getUserOrImpersonatingOrg(user)
    const reforestumOrgId = 30419
    if (
        (enviroment === 'production') && 
        (getUserType() !== USER_TYPE.USER) && 
        (userOrOrg.organization_id  !== reforestumOrgId) &&
        (user.email.indexOf('@reforestum.com') !== -1)
    ) {
      return (
        //background medium red
        <div style={{backgroundColor: '#ffcccc', color: "black", fontSize: "18px", padding: '20px 10px', textAlign: 'center'}}>
            You are impersonating an organization that is not Reforestum in Production. Please be careful not to modify anything unwanted
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    // header container mounts once before any component of the app...
    // ...so the localstorage must be call on the render method...
    // ...and no following the current lifecycle recommendations (componentDidMount)
    const enterpriseOffsetDetails = loadFromStorage('enterpriseOffset') || {};

    const { loadStatus, logout, location, isAuthenticated } = this.props;
    const { offsetClaimErrors, showConfirmSelectedAccountModalBeforeActivating } = this.state;

    if (loadStatus === 'loading') {
      return null;
    }

    // @DECISION-TAKEN: as default, Header does not appear for Enterprise activation landings
    // @TODO: make it open to customization when necessary
    // We will also not show the headers for the forms
    if (location.pathname.startsWith('/activation')) {
      return null
    }

    let navProfile;

    if (location.pathname.startsWith('/new-supplier')) {
      navProfile = NAV_PROFILES.SUPPLIER_FORMS;
    }

    if (location.pathname.startsWith(links.getPublicProfileUrl())) {
      navProfile = NAV_PROFILES.PUBLIC_PROFILE;
    }

    if (location.pathname.startsWith(links.getUserActivationUrl())) {
      navProfile = NAV_PROFILES.USER_ACTIVATION;
    }

    if (enterpriseOffsetDetails.status === ACTIVATION_STATUS.PENDING && !isAuthenticated) {
      navProfile = NAV_PROFILES.ENTERPRISE_ACTIVATION_PENDING_AND_NO_AUTH
    }

    if (enterpriseOffsetDetails.status === ACTIVATION_STATUS.PENDING && isAuthenticated) {
      navProfile = NAV_PROFILES.ENTERPRISE_ACTIVATION_PENDING_AND_AUTH
    }

    if(enterpriseOffsetDetails.status === ACTIVATION_STATUS.ACTIVATED && !isAuthenticated){
      navProfile = NAV_PROFILES.ENTERPRISE_ACTIVATION_ACTIVATED_AND_NO_AUTH
    }

  const showInteractiveOverlay = enterpriseOffsetDetails.showActivationOverlay && location.pathname.startsWith('/public-profile');
 
    return (
      <div>
        {showConfirmSelectedAccountModalBeforeActivating && this.renderConfirmSelectedAccountModalBeforeActivating()}
        {this.renderMessageIfReforestumImpersonatingOtherOrgInProduction()}
        <Header
          {...this.props}
          activationId={enterpriseOffsetDetails.activationId}
          status={enterpriseOffsetDetails.status}
          logout={logout}
          navProfile={navProfile}
          showInteractiveOverlay={showInteractiveOverlay && window.innerWidth > 768}
          handleOffsetClaimingClick={this.handleOffsetClaimingClick}
          offsetClaimErrors={offsetClaimErrors}
          renderSuccessActivationAlert={this.renderSuccessActivationAlert}
          renderErrorAlertWhileClaiming={this.renderErrorAlertWhileClaiming}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: getUserDetails(state),
  isUserPopupOpen: getIsUserPopupOpen(state),
  isPreferencesSwitcherPopupOpen: getIsPreferencesSwitcherPopupOpen(state),
  loadStatus: getLoadStatus(state),
  isAuthenticated: getIsUserAuthenticated(state),
  offsetClaim: getOffsetClaimDetails(state),
  isFetchingOffsetClaimDetails: getIsFetchingOffsetClaimDetails(state),
  offsetClaimErrorMessages: getOffsetClaimErrorMessages(state)
})

HeaderContainer.propTypes = {
  isUserPopupOpen: PropTypes.bool.isRequired,
  toggleUserDropDown: PropTypes.func.isRequired,
  togglePreferencesSwitcherPopup: PropTypes.func.isRequired,
  loadStatus: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  offsetClaiming: PropTypes.func,
  offsetClaimErrorMessages: PropTypes.string,
  resetOffsetClaimErrors: PropTypes.func,
  fetchMyContributionsInPublicProfile: PropTypes.func,
  intl: intlShape
};

export default connect(
  mapStateToProps,
  { toggleUserDropDown,
    togglePreferencesSwitcherPopup,
    logout,
    offsetClaiming,
    resetOffsetClaimErrors,
    fetchMyContributionsInPublicProfile
  }
)(injectIntl(withRouter(HeaderContainer)));
