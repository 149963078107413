import actionTypes from '../constants/actionTypes';

const initialState = {
  isFetching: false,
  details: null,
  activationUserError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACTIVATION_PROFILE_START:
      return {
        isFetching: true,
      };
    case actionTypes.GET_ACTIVATION_PROFILE_COMPLETE:
      return {
        ...state,
        isFetching: false,
        details: action.payload
      };
    case actionTypes.GET_ACTIVATION_PROFILE_ERROR:
      return {
        isFetching: false,
        activationUserError: action.payload,
      };
    default:
      return state;
  }
};

export const getIsFetching = state => state.isFetching;
export const getUserActivationDetails = state => state.details;
export const getAccountActivationError = state => state.activationUserError;
