import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './ForestTeamMember.css';

const ForestTeamMember = ({ member, className }) => (
  <div className={cx('forest-team-member', className)}>
    <img src={member.avatar} alt={`${member.full_name} - Team Member`} className="forest-team-member__photo" />
    <div className="forest-team-member__text-col">
      <div className="forest-team-member__text-wrapper">
        <span className="forest-team-member__position">{member.title}</span>
        <h3 className="forest-team-member__name">{member.full_name}</h3>
      </div>
      <p className="forest-team-member__description">{member.bio}</p>
    </div>
  </div>
);

ForestTeamMember.propTypes = {
  member: PropTypes.shape({
    bio: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    full_name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

export default ForestTeamMember;
