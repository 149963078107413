import React from 'react';

const ForestNotificationOn = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon-notify-me">
          <g id="icon-notifications" transform="translate(5.000000, 2.000000)">
            <path
              d="M4.69999981,16.8888897 C4.69999981,20.1352665 9.37320242,20.1352665 9.37320242,16.8888897"
              id="Path-4"
              fill="#37B877"
            />
            <path
              d="M6.99666659,3.33333333 C8.44594195,3.33333333 12.0691304,3.7118437 12.0691304,10.5250303 C12.0691304,14.6886443 13.6633333,14.385836 13.6633333,15.8241754 C13.6633333,17.1111107 11.7068115,17.1111107 6.99666659,17.1111107 C2.28652166,17.1111107 0.329999924,17.1111107 0.329999924,15.8241754 C0.329999924,14.385836 1.92420282,14.6886443 1.92420282,10.5250303 C1.92420282,3.7118437 5.54739123,3.33333333 6.99666659,3.33333333 Z"
              id="Combined-Shape"
              fill="#AFE3C9"
            />
            <rect
              id="Rectangle-2"
              stroke="#AFE3C9"
              strokeWidth="0.782608696"
              fill="#AFE3C9"
              x="5.5"
              y="0.69565234"
              width="2.97385621"
              height="1.11111111"
              rx="0.555555556"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ForestNotificationOn;
