import React, { Component } from 'react';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { links } from '@reforestum/shared-utils'

import history from '../setup/history';
import { AUTH_METHOD } from '../constants/authMethods'

import { authenticate, logout } from '../actions/userSession';
import * as authSelector from '../selectors/userActions';
import * as userSelector from '../selectors/userSession';

import Login from '../components/Auth/Login';
import getIsAutheticatedFromStore from '../utils/getIsAutheticatedFromStore';

class HeaderContainer extends Component {

  componentDidMount() {
    mixpanel.track("Page view", {
      "Authenticated": getIsAutheticatedFromStore(),
      "Action": "Login",
      "Domain": "App"
    });
  }

  componentWillMount() {
    const { isAuthenticated, location } = this.props;

    if (isAuthenticated) {
      history.push(links.getHomeUrl());
    }

    this.nextUrl = new URLSearchParams(location.search).get('next');
  }

  componentWillReceiveProps(newProps) {
    const { isAuthenticated } = newProps;

    if (isAuthenticated) {
      history.push(this.nextUrl ? this.nextUrl : links.getHomeUrl());
    }
  }

  handleLogin(data) {
    this.props.authenticate(data, AUTH_METHOD.FORM, this.nextUrl ? this.nextUrl : links.getHomeUrl());
  }

  handleRegisterRedirection(){
    history.push(links.getRegisterUrl())
  }

  render() {
    const { errorMessages, isLoading, logout, lastAttemptedEmail } = this.props;
    return (
      <Login
        handleLogin={this.handleLogin.bind(this)}
        handleRegisterRedirection={this.handleRegisterRedirection.bind(this)}
        errorMessages={errorMessages}
        isLoading={isLoading}
        logout={logout}
        lastAttemptedEmail={lastAttemptedEmail}
      />
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: userSelector.getIsUserAuthenticated(state),
  isLoading: authSelector.getIsLoading(state),
  errorMessages: authSelector.getLoginErrorMessages(state),
  lastAttemptedEmail: authSelector.getLastAttemptedEmail(state)
});

HeaderContainer.propTypes = {
  authenticate: PropTypes.func.isRequired,
  errorMessages: PropTypes.string,
  lastAttemptedEmail: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  { authenticate, logout }
)(HeaderContainer);
