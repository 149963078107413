import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { Box } from 'react-layout-components';
import { FormattedMessage } from 'react-intl';

import Budget from '../../UI/Icons/Budget';
import Area from '../../UI/Icons/Area';
import Sources from '../../UI/Icons/Sources';
import IconText from '../../UI/Text/IconText';
import Text from '../../UI/Text/Text';

import mixpanel from 'mixpanel-browser';

import './Tabs.css';
import getIsAutheticatedFromStore from '../../../utils/getIsAutheticatedFromStore';
import { links } from '@reforestum/shared-utils';

const isTabActive = linkTo => (match, { pathname }) => {
  return linkTo === pathname || linkTo + '/' === pathname
}

class Tabs extends Component {

  trackClick(type){
    
    mixpanel.track("Checkout", {
      "Authenticated": getIsAutheticatedFromStore(),
      "Action": "Tab selection",
      "Tab":type,
      "Domain": "App"
    });
  }

  getLink = (url) => {
    const { location: { search } } = this.props

    return `${url}/${search}`
  }

  render() {
    return (
      <Box className="create-forest-tabs">

        <NavLink
          activeClassName="active"
          to={this.getLink(links.getCreateForestUrl('carbon'))}
          className="create-forest-tabs__item"
          onClick={() => this.trackClick('C02')}
          isActive={isTabActive(links.getCreateForestUrl('carbon'))}
          >
          <IconText icon={Sources}>
            <Text>
              <FormattedMessage
                id={'Create.byCo2TabTitle'}
                defaultMessage={'By CO₂ sources'}
              />
            </Text>
          </IconText>
        </NavLink>

        <NavLink
          activeClassName="active"
          to={this.getLink(links.getCreateForestUrl('budget'))}
          className="create-forest-tabs__item"
          onClick={() => this.trackClick('Budget')}
          isActive={isTabActive(links.getCreateForestUrl('budget'))}
          >
          <IconText icon={Budget}>
            <Text>
              <FormattedMessage
                id={'Create.byBudgetTabTitle'}
                defaultMessage={'By budget'}
              />
            </Text>
          </IconText>
        </NavLink>

        <NavLink
          activeClassName="active"
          to={this.getLink(links.getCreateForestUrl('area'))}
          className="create-forest-tabs__item"
          onClick={() => this.trackClick('Area')}
          isActive={isTabActive(links.getCreateForestUrl('area'))}
          >
          <IconText icon={Area}>
            <Text>
              <FormattedMessage
                id={'Create.byAreaTabTitle'}
                defaultMessage={'By area'}
              />
            </Text>
          </IconText>
        </NavLink>

      </Box>
    );
  }

}

Tabs.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(Tabs);
