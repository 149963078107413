const formatDate = timestamp => {
  const date = new Date(timestamp)
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  }
  return date.toLocaleDateString('en-GB', options)
}

export default formatDate