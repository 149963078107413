import React from 'react';

// used for giving custom styles to Select options
// it receives privacyOptions automatically...
// ...no need no pass them as the argument in the Select element
export const formatOptionLabel = (options) => {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div
                style={{
                color: "var(--ref-greyscale-600)",
                fontSize: 14,
                fontWeight: 500
            }}>
                {options.label}
            </div>
            <div
                style={{
                marginTop: "0.3rem",
                color: "var(--ref-greyscale-600)",
                fontSize: 14
                }}>
                {options.customDescription}
            </div>
        </div>
    )
};

