import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'react-layout-components';
import {FormattedMessage, injectIntl} from 'react-intl'

import Container from '../../Layout/Container/Container';
import Content from '../../Layout/Container/Content';
import Sidebar from '../../Layout/Sidebar/Sidebar';
import SidebarToggle from '../../UI/SidebarToggle/SidebarToggle';
import HeadingSmall from '../../UI/Text/HeadingSmall';
import { Button } from '@reforestum/shared-components';
import {OFFSET_TYPE_SQM_SHARES, OFFSET_TYPE_CREDIT} from "../../../constants/offsetTypes";
import Forest from './Forest';
import './ForestList.css';
import {forestDetailsMessages} from "../../../constants/messages"
import Tooltip from "../../UI/Tooltip/Tooltip"
import Information from "../../UI/Icons/Information"

class ForestList extends Component {
  constructor(props){
    super(props);
    this.state = {
      windowWidth: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowWidth.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth.bind(this));
  }

  updateWindowWidth() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  filterOnlyPurchasable(f) {
    return f.available
  }

  renderForest(forest) {
    const { getSelectedOffsetData, openForestDetails, isPaymentPossible, currency } = this.props
    const { windowWidth } = this.state;
    return <Forest
      available={forest.available}
      key={forest.id}
      forest={forest}
      purchasable={true}
      getSelectedOffsetData={getSelectedOffsetData.bind(
        this,
        forest
      )}
      openForestDetails={openForestDetails}
      windowWidth={windowWidth}
      isPaymentPossible={isPaymentPossible}
      currency={currency}
    />
  }

  renderForestsSplitByOffsetType() {
    const { forests, intl } = this.props
    const { windowWidth } = this.state
    const getOnlySqmShareForests = () => forests.filter(this.filterOnlyPurchasable).filter(f => f.offset_type === OFFSET_TYPE_SQM_SHARES)
    const getOnlyVCUForests = () => forests.filter(this.filterOnlyPurchasable).filter(f => f.offset_type === OFFSET_TYPE_CREDIT)
    return <div>
      {
        (getOnlySqmShareForests().length > 0 ) && <div className={'create-forest-list__offset-type-info'}>
          <div className={'create-forest-list__offset-type-info__title-group'}>
            <h2>
              <FormattedMessage
                id={'ForestList.offsetTypeFutureRemovals'}
                defaultMessage={'Afforestation projects'}
              />
            </h2>
            <div className={'create-forest-list__offset-type-info__info-group'}>
              <h3>
                <FormattedMessage
                  id={'ForestList.offsetTypeFutureRemovalsSubheading'}
                  defaultMessage={'Future CO2 removals'}
                />
              </h3>
              <div>
                <Tooltip
                  size='medium'
                  position={windowWidth < 1050 ? 'bottom' : 'bottom-right'}
                  content={intl.formatMessage(forestDetailsMessages.futureRemovalsCO2Tooltip)}
                >
                  <Information/>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      }
      {
        getOnlySqmShareForests().map(forest => this.renderForest(forest))
      }

      {
        (getOnlyVCUForests().length > 0 ) && <div className={'create-forest-list__offset-type-info'}>
          <div className={'create-forest-list__offset-type-info__title-group'}>
            <h2>
              <FormattedMessage
                id={'ForestList.offsetTypeAvoidedEmissions'}
                defaultMessage={'Projects based on carbon credits'}
              />
            </h2>

            <div className={'create-forest-list__offset-type-info__info-group'}>
              <h3>
                <FormattedMessage
                  id={'ForestList.offsetTypeAvoidedEmissionsSubheading'}
                  defaultMessage={'Verified avoided or captured emissions'}
                />
              </h3>
              <div>
                <Tooltip
                  size='medium'
                  position={windowWidth < 1050 ? 'bottom-left' : 'bottom-right'}
                  content={intl.formatMessage(forestDetailsMessages.forestryProjectsBasedOnCarbonCreditsTooltip)}
                >
                  <Information/>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      }
      {
        getOnlyVCUForests().map(forest => this.renderForest(forest))
      }
      {
        ((getOnlySqmShareForests().length === 0 ) &&  (getOnlyVCUForests().length === 0 )) &&
        <div className={'create-forest-list__offset-type-info__title-group'}>
        <h2>
          <FormattedMessage
            id={'ForestList.noProjectsAvailable'}
            defaultMessage={'No projects are available at the moment for neutralization'}
          />
        </h2>
        <div className={'create-forest-list__offset-type-info__info-group'}>
          <h3>
            <FormattedMessage
              id={'ForestList.noProjectsAvailableSubheading'}
              defaultMessage={'Feel free to contact Reforestum for more information'}
            />{' '}
            <a href="https://reforestum.com/contact-us" target="_blank" rel="noopener noreferrer">
            https://reforestum.com/contact-us
              </a>
          </h3>
        </div>
      </div>
      }
    </div>
  }

  render(){
    const { windowWidth } = this.state;

    return (
      windowWidth > 768 ?
      <Container>
        <Content className="create-forest-list">
          {this.renderForestsSplitByOffsetType()}
        </Content>
      </Container>
      :
      <Sidebar className="create-forest-list">
        <Box
          center
          justifyContent="space-between"
          className="create-forest__title">

          <HeadingSmall>
            <FormattedMessage
              id={'Forests.selectForest'}
              defaultMessage={'Select Forest'}
            />
          </HeadingSmall>

          <Button className="create-forest__sidebar-button">
            <FormattedMessage
              id={'Forest.chooseForest'}
              defaultMessage={'Choose Forest'}
            />
          </Button>

          <SidebarToggle/>
        </Box>

        {this.renderForestsSplitByOffsetType()}
      </Sidebar>
    );
  }
}

ForestList.propTypes = {
  forests: PropTypes.array.isRequired,
  getSelectedOffsetData: PropTypes.func.isRequired,
  openForestDetails: PropTypes.func.isRequired,
  isPaymentPossible: PropTypes.func.isRequired,
  currency: PropTypes.string
}

export default injectIntl(ForestList)
