import * as fromForests from '../reducers/forests';

export const getIsFetching = state =>
  fromForests.getIsFetching(state.forests);

export const getIsFetchingForestSummary = state =>
  fromForests.getIsFetchingForestSummary(state.forests);

export const getIsFetchingDetails = state =>
  fromForests.getIsFetchingDetails(state.forests);

export const getIsFetchingReforesterShares = state =>
 fromForests.getIsFetchingReforesterShares(state.forests.reforesterShares);

export const getIsFetchingMyForests = state =>
  fromForests.getIsFetchingMyForests(state.forests);

export const getIsFetchingTrees = state =>
  fromForests.getIsFetchingTrees(state.forests);

export const getErrorMessages = state =>
  fromForests.getErrorMessages(state.forests);

export const getDetailsErrorMessages = state =>
  fromForests.getDetailsErrorMessages(state.forests);

export const getForests = state =>
  fromForests.getForests(state.forests);

export const getMyForests = state =>
  fromForests.getMyForests(state.forests);

export const getMyOwnedForests = state =>
  fromForests.getMyOwnedForests(state.forests);

export const getForestById = (state, id) =>
  fromForests.getForestById(state.forests, id);

export const getForestSpecies = (state, id) =>
  fromForests.getForestSpecies(state.forests, id);

export const getForestTeam = (state, id) =>
  fromForests.getForestTeam(state.forests, id);

export const getForestTrees = (state, id) =>
  fromForests.getForestTrees(state.forests, id);

export const getReforesterShares = state => fromForests.getReforesterShares(state.forests);

export const getTotalCapturedCarbon = state =>
  fromForests.getTotalCapturedCarbon(state.forests);

export const getTotalOxygen = state =>
  fromForests.getTotalOxygen(state.forests);

export const getIsFetchingPatrons = state =>
  fromForests.getIsFetchingPatrons(state.forests);

export const getPatronsPage = state =>
  fromForests.getPatronsPage(state.forests);

export const getPatronsData = state =>
  fromForests.getPatronsData(state.forests);

export const getPatronsPodium = state =>
  fromForests.getPatronsPodium(state.forests);

export const getPatronsForestId = state => 
  fromForests.getPatronsForestId(state.forests);

export const getPatronsSectorId = state =>
  fromForests.getPatronsSectorId(state.forests);

export const getPatrons = state =>
  fromForests.getPatrons(state.forests);

export const getPatronsErrorMessages = state =>
  fromForests.getPatronsErrorMessages(state.forests);

export const getIsFetchingContributors = state =>
  fromForests.getIsFetchingContributors(state.forests);

export const getContributorsPage = state =>
  fromForests.getContributorsPage(state.forests);

export const getContributorsTotal = state =>
  fromForests.getContributorsTotal(state.forests);

export const getContributorsForestId = state => 
  fromForests.getContributorsForestId(state.forests);

export const getContributors = state =>
  fromForests.getContributors(state.forests);

export const getTopContributors = state => 
  fromForests.getTopContributors(state.forests);

export const getContributorsErrorMessages = state =>
  fromForests.getContributorsErrorMessages(state.forests);

export const getIsFetchingVintages = state => 
  fromForests.getIsFetchingVintages(state.forests);

export const getVintagesErrorMessages = state => 
  fromForests.getVintagesErrorMessages(state.forests);

export const getVintagesProjectId = state => 
  fromForests.getVintagesProjectId(state.forests);

export const getVintages = state => 
  fromForests.getVintages(state.forests);

export const getIsFetchingTransactions = state =>
  fromForests.getIsFetchingTransactions(state.forests);

export const getTransactionsPage = state =>
  fromForests.getTransactionsPage(state.forests);

export const getTransactionsTotal = state =>
  fromForests.getTransactionsTotal(state.forests);

export const getTransactionsForestId = state => 
  fromForests.getTransactionsForestId(state.forests);

export const getTransactions = state =>
  fromForests.getTransactions(state.forests);

export const getTransactionsErrorMessages = state =>
  fromForests.getTransactionsErrorMessages(state.forests);


export const getIsFetchingGallery = state =>
  fromForests.getIsFetchingGallery(state.forests);

export const getGallery = (state, forestId) =>
  fromForests.getForestGallery(state.forests, forestId);

export const getLatestActivity = state =>
  fromForests.getLatestActivity(state.forests);

  // MRV project
export const getMrvGraph = state => fromForests.getMrvGraph(state.forests);
export const isImpactTabOpened = state => fromForests.isImpactTabOpened(state.forests);
export const showOnlyGraphControlledLayers = state => fromForests.showOnlyGraphControlledLayers(state.forests)
export const getGraphControlledRasterSources = state => fromForests.getGraphControlledRasterSources(state.forests);
export const getShouldShowMrvSnack = state => fromForests.getShouldShowMrvSnack(state.forests);
