import api from "../../constants/api"
import {getUserRequestHeaders} from "./getUserRequestHeaders"

export const queryDiscoverProposalRequest = async (
	{
		projectId,
		approximateTargetVolumeTons,
		details
	}
) => {

	let result = {data: {totalProjects: [], totalMatches: [], matches: []}}
	try {
		result = await fetch(
			`${api.DISCOVER_PROPOSAL_REQUEST}`,
			{
				method: 'post',
				headers: {...getUserRequestHeaders(), 'Content-Type': 'application/json'},
				body: JSON.stringify({
					projectId,
					approximateTargetVolumeTons,
					details,
				})
			}
		)
		result = await result.json()
	}
	catch(e) {
		console.warn('Error performing discover proposal request', e)
	}

	return result
}
