import React from 'react';
import { Button } from "@reforestum/shared-components";
import './PaywallBanner.css';

function PaywallBanner({ title, description, buttonText, buttonLink, className }) {
    return (
      <div className={`paywall-banner__cta-container ${className}`}>
        <h1>{title}</h1>
        <p>{description}</p>
        <div className='widget-button'>
          <a target='_blank' rel="noopener noreferrer" href={buttonLink}>
            <Button>{buttonText}</Button>
          </a>
        </div>
      </div>
    );
  }

export default PaywallBanner;  