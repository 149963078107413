import actionTypes from '../constants/actionTypes';
import { axiosV2Instance } from './';
import api from '../constants/api';
import { getIsFetching } from '../selectors/publicProfile';

export const getCommunityMembers = (userName, projectsIds, sectorsIds, currentPage) => ({
    shouldCallAPI: state => !getIsFetching(state),
    callAPI: () => axiosV2Instance.get(`${api.GET_ORGANIZATION_PROFILE}${userName}${api.GET_ORGANIZATION_COMMUNITY}?projects=${projectsIds}&sectors=${sectorsIds}&perPage=10&currentPage=${currentPage}`),
    actions: [
        actionTypes.COMMUNITY_DATA_START,
        {
        type: actionTypes.COMMUNITY_DATA_COMPLETE,
        payload: response => response.data
        },
        {
        type: actionTypes.COMMUNITY_DATA_ERROR,
        payload: response => response
        }
    ],
});

    