import React from 'react';
import get from 'lodash/get';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { updateUser } from '../../actions/userActions';
import {refreshProfile} from "../../actions/userSession";
import Popover from '../UI/Popover/Popover';
import './PreferencesSwitcherDropdown.css';
import {getUserDetails} from "../../selectors/userSession";
import CheckboxRadio from "../UI/Forms/CheckboxRadio";
import {EUR, GBP, USD} from "../../constants/currencies";
import {EN, ES, FR} from "../../constants/languages";
import {languageIs} from "./PreferencesSwitcherHelper"

const PreferencesSwitcherDropdown = props => {
  const {className, updateUser, refreshProfile, userDetails} = props

  const switchMeasurementType = async () => {
    const {intl} = props
    await updateUser(
      {
        form: {
          preferences: {
            metric_distance: !get(userDetails, 'preferences.metric_distance', false),
            metric_weight: !get(userDetails, 'preferences.metric_distance', false),
          }
        },
        intl
      }
    )
    await refreshProfile()
    window.location.reload()
  }

  const setCurrencyTo = async value => {
    const {intl} = props
    await updateUser(
      {
        form: {
          preferences: {
            currency: value,
          }
        },
        intl
      }
    )
    await refreshProfile()
    window.location.reload()
  }

  const setLanguageTo = async value => {
    const {intl} = props
    await updateUser(
      {
        form: {
          preferences: {
            lang: value,
          }
        },
        intl
      }
    )
    await refreshProfile()
    window.location.reload()
  }

  const ClickableRadioCheckbox = props => <div onClick={e => {props.onClick(); e.stopPropagation()}}>
    <CheckboxRadio
      type={'radio'}
      meta={{touched: false}}
      className={'preferences-switcher-radio'}
      {...props}
      input={{onChange: () => {}, ...props.input}}
    />
  </div>

  return (
    <Popover
      className={cx(className, 'preferences-switcher-dropdown')}
      direction="down"
      useArrow={false}
    >
      <div className={"preferences-switcher-dropdown__section-title"}>
        <FormattedMessage
          id={'Header.preferences-switcher-dropdown.currency'}
          defaultMessage={'Currency'}
        />
      </div>

      <ClickableRadioCheckbox
        onClick={() => setCurrencyTo(EUR)}
        input={{checked: get(userDetails, 'preferences.currency') === EUR}}
        label={<FormattedMessage id={'currency.euro-eur'} defaultMessage={"Euro (EUR)"}/>}
      />
      <ClickableRadioCheckbox
        onClick={() => setCurrencyTo(GBP)}
        input={{checked: get(userDetails, 'preferences.currency') === GBP}}
        label={<FormattedMessage id={'currency.pound-sterling-gbp'} defaultMessage={"Pound Sterling (GBP)"}/>}
      />
      <ClickableRadioCheckbox
        onClick={() => setCurrencyTo(USD)}
        input={{checked: get(userDetails, 'preferences.currency') === USD}}
        label={<FormattedMessage id={'currency.us-dollar-usd'} defaultMessage={"US Dollar (USD)"}/>}
      />

      <div className={"preferences-switcher-dropdown__section-title"}>
        <FormattedMessage
          id={'Header.preferences-switcher-dropdown.measurement-system'}
          defaultMessage={'Measurement System'}
        />
      </div>

      <ClickableRadioCheckbox
        onClick={() => !get(userDetails, 'preferences.metric_distance', false) && switchMeasurementType()}
        input={{checked: get(userDetails, 'preferences.metric_distance', false)}}
        label={<FormattedMessage id={'Units.metric'} defaultMessage={"Metric"}/>}
      />
      <ClickableRadioCheckbox
        onClick={() => get(userDetails, 'preferences.metric_distance', false) && switchMeasurementType()}
        input={{checked: !get(userDetails, 'preferences.metric_distance', false)}}
        label={<FormattedMessage id={'Units.imperial'} defaultMessage={"Imperial"}/>}
      />

      <div className={"preferences-switcher-dropdown__section-title"}>
        <FormattedMessage
          id={'Header.preferences-switcher-dropdown.language'}
          defaultMessage={'Language'}
        />
      </div>

      <ClickableRadioCheckbox
        onClick={() => setLanguageTo(EN)}
        input={{checked: languageIs(EN, userDetails)}}
        label={'English'}
      />
      <ClickableRadioCheckbox
        onClick={() => setLanguageTo(ES)}
        input={{checked: languageIs(ES, userDetails)}}
        label={<FormattedMessage id={'Español'}/>}
      />
      <ClickableRadioCheckbox
        onClick={() => setLanguageTo(FR)}
        input={{checked: languageIs(FR, userDetails)}}
        label={<FormattedMessage id={'Français'}/>}
      />
    </Popover>
  )
}

const mapStateToProps = state => ({
  userDetails: getUserDetails(state)
})

PreferencesSwitcherDropdown.propTypes = {
  className: PropTypes.string
};

export default connect(
  mapStateToProps,
  { updateUser, refreshProfile }
)(injectIntl(PreferencesSwitcherDropdown));
