import { USD, GBP, EUR } from '../constants/currencies'

export default currency => {
  let result = currency

  const currencyToSymbol = {
    [USD]: '$',
    [GBP]: '£',
    [EUR]: '€',
  }

  if(currencyToSymbol[currency]) {
    result = currencyToSymbol[currency]
  }

  return result
}
