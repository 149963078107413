import { EUR, GBP, USD } from '../constants/currencies';
import { EN, ES, FR } from '../constants/languages';
import { Languages, Currencies, TempUnits, DistanceUnits, WeightUnits, TonType } from '../constants/messages';
import { LONG_TON, METRIC_TON, SHORT_TON } from '../constants/tonType';

export const getLanguageOptions = (intl) => {
    return (
        [
            {
              label: intl.formatMessage(Languages.english),
              value: EN,
            },
            {
              label: intl.formatMessage(Languages.spanish),
              value: ES,
            },
            {
              label: intl.formatMessage(Languages.french),
              value: FR,
            }
          ]
        
    )
}

export const getCurrencyOptions = (intl) => {
  return (
    [
        {
          label: intl.formatMessage(Currencies.euro),
          value: EUR,
        },
        {
          label: intl.formatMessage(Currencies.pound),
          value: GBP,
        },
        {
          label: intl.formatMessage(Currencies.dollar),
          value: USD,
        }
      ]
    
  )
}

export const getTempUnitsOptions = (intl) => {

  //The return value is TRUE if the temp is celsius, FALSE if it is fahrenheit
  return (
    [
        {
          label: intl.formatMessage(TempUnits.celsius),
          value: true,
        },
        {
          label: intl.formatMessage(TempUnits.fahrenheit),
          value: false,
        }
      ]
    
  )
}

export const getDistanceOptions = (intl) => {

  //The return value is TRUE if the distance is metric, FALSE if it is feet
  return (
    [
        {
          label: intl.formatMessage(DistanceUnits.metric),
          value: true,
        },
        {
          label: intl.formatMessage(DistanceUnits.imperial),
          value: false,
        }
      ]
    
  )
}

export const getWeightOptions = (intl) => {
  
    //The return value is TRUE if the weight is metric, FALSE if it is imperial
    return (
      [
          {
            label: intl.formatMessage(WeightUnits.metric),
            value: true,
          },
          {
            label: intl.formatMessage(WeightUnits.imperial),
            value: false,
          }
        ]
    )
  }

  export const getTonTypeOptions = (intl) => {
    return (
      [
          {
            label: intl.formatMessage(TonType.metric),
            value: METRIC_TON,
          },
          {
            label: intl.formatMessage(TonType.short),
            value: LONG_TON,
          },
          {
            label: intl.formatMessage(TonType.long),
            value: SHORT_TON,
          },
        ]
    )
  }
