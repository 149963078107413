import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import * as Sentry from "@sentry/react";

import getFeatureFlags from '../constants/featureFlags';
import HeaderContainer from '../containers/HeaderContainer';
import Login from '../views/Login';
import Register from '../views/Register';
import Recovery from '../views/Recovery';
import Calculator from '../views/Calculator';
import MySector from '../views/MySector';
import MyForests from '../views/MyForests';
import PublicProfileDashboard from '../views/PublicProfileDashboard';
import Dashboard from '../views/Dashboard';
import CreateForest from '../views/CreateForest';
import Checkout from '../views/Checkout';
import CryptoPaymentFinished from '../views/CryptoPaymentFinished';
import UserActivation from '../views/UserActivation';
import NotFound from '../views/NotFound';
import CheckoutSuccess from '../views/CheckoutSuccess';
import StripePaymentError from '../views/StripePaymentError';
import GiftAForest from '../views/GiftAForest';
import EnterpriseActivation from '../views/EnterpriseActivation'
import AccountSettings from '../views/AccountSettings'
import NewSupplier from '../views/NewSupplier';
import NewSupplierProposal from '../views/NewSupplierProposal';

import Frame from '../components/Layout/Frame/Frame';
import SearchPage from "../views/SearchPage"
import MarketInsights from '../views/MarketInsights';
import DeveloperProfileDashboard from '../views/DeveloperProfileDashboard';
import ProjectsList from '../views/ProjectsList';
import OrganizationsList from '../views/OrganizationsList';
import RequestForProposalsResultsView from '../views/RequestForProposalsResultsView'
import OrganizationPortfolioDashboard from '../views/OrganizationPortfolioDashboard';
import { getUserType } from '../utils/usersAndOrganizations';
import USER_TYPE from '../constants/userType';
import Discover from '../views/Discover'

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

const renderProposalList = (props) => getUserType() === USER_TYPE.NEW_MODEL_ORGANIZATION ? <RequestForProposalsResultsView {...props} /> : <Redirect to="/" />

const routes = () => {
  const ff = getFeatureFlags()
  return (
    <Frame>
      <SentryRoute path="/" component={HeaderContainer} />
      <Switch>
        {/* === CURRENT ROUTES === */}
        {/* --- Static Routes (no params) --- */}
        <SentryRoute exact path="/" component={Dashboard} />
        <SentryRoute exact path="/portfolio" render={
          (props) => getUserType() === USER_TYPE.NEW_MODEL_ORGANIZATION ? <OrganizationPortfolioDashboard {...props} /> : <Redirect to="/" />
        } />
        {getFeatureFlags().rfpResults && <SentryRoute exact path="/rfp/:rfpId/:tab?" render={renderProposalList} />}
        {getFeatureFlags().rfpResults && <SentryRoute path="/rfp/:rfpId/proposal/:proposalId" render={renderProposalList} />}
        <SentryRoute exact path="/search" component={SearchPage} />
        {getFeatureFlags().marketInsightsScreen && <SentryRoute exact path="/market-insights" component={MarketInsights} />}
        <SentryRoute exact path="/organizations" component={OrganizationsList}/>
        <SentryRoute exact path="/login" component={Login} />
        <SentryRoute exact path="/register" component={Register} />
        <SentryRoute exact path="/recover-password" component={Recovery} />
        <SentryRoute exact path="/carbon-footprint-calculator" component={Calculator} />
        <SentryRoute exact path="/checkout" component={Checkout} />
        <SentryRoute exact path="/checkout-success" component={CheckoutSuccess} />
        <SentryRoute exact path="/checkout-error" component={StripePaymentError} />
        <SentryRoute path="/account" component={AccountSettings} />
        <SentryRoute path="/create-new-organization" render={() => <AccountSettings createNewOrgScreen/>} />
        <SentryRoute exact path="/new-supplier/:uuid" component={NewSupplier} />
        <SentryRoute exact path="/new-supplier-proposal/:uuid" component={NewSupplierProposal} />
        {ff.giftAForest && <SentryRoute exact path="/gift-a-forest" component={GiftAForest} />}
        {/* --- Dynamic Routes (with params) --- */}
        {/* - Optional Params - */}
        <SentryRoute exact path="/create-forest/:mode?" component={CreateForest} />
        <SentryRoute exact path="/crypto-payment-finished/:status?" component={CryptoPaymentFinished} />
        {/* - Mandatory Params - */}
        <SentryRoute exact path="/user-activation/:token" component={UserActivation} />
        <SentryRoute path="/public-profile/:userName" component={PublicProfileDashboard} />
        <SentryRoute path="/developer-profile/:userName" component={DeveloperProfileDashboard} />
        {/* - Base on levels - */}
        {ff.parametrizedLandingPage && <SentryRoute exact path="/activation/:uuid/:actionName?" component={EnterpriseActivation} />}
        {ff.findMyShares && <SentryRoute path="/project/:id/sector/:sectorId/share/:shareId" component={MySector} />}
        <SentryRoute path="/project/:id/sector/:sectorId" component={MySector} />
        <SentryRoute path="/project/:id" component={MyForests} />
        <SentryRoute path="/projects/discover" render={(props) => <Discover {...props} />} />
        <SentryRoute path="/projects/list" component={ProjectsList} />
        <SentryRoute path="/projects" render={() => <Redirect to="/projects/discover" />} />
        {/* === LEGACY ROUTES === */}
        {ff.findMyShares && <SentryRoute path="/my-forests/:id/shared-forest/:sectorId/share/:shareId" component={MySector} />}
        <SentryRoute path="/my-forests/:id/shared-forest/:sectorId" component={MySector} />
        <SentryRoute path="/my-forests/:id?" component={MyForests} />
        <SentryRoute exact path="/calculator" render={() => <Redirect to="/carbon-footprint-calculator" />} />
        {/* === ERROR MANAGEMENT == */}
        {/* Canonical 404 */}
        <SentryRoute path="/404" component={NotFound} />
        {/* Any Route that didn't match */}
        <SentryRoute path="*" component={NotFound} />
      </Switch>
    </Frame>
  )
}
export default routes;
