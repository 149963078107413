import React from 'react';

const Compass = props => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#1F3B21" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="square"/>
    <path d="M9.05997 9.06069L9.29631 9.61218L9.51064 9.52033L9.60669 9.30786L9.05997 9.06069ZM10.8158 5.17676L11.3626 5.42392L10.5675 4.63055L10.8158 5.17676ZM6.93866 6.93932L6.69035 6.39311L6.47874 6.48931L6.38717 6.70297L6.93866 6.93932ZM5.34766 10.6516L4.79617 10.4153L5.584 11.2031L5.34766 10.6516ZM9.60669 9.30786L11.3626 5.42392L10.2691 4.92959L8.51324 8.81353L9.60669 9.30786ZM10.5675 4.63055L6.69035 6.39311L7.18696 7.48553L11.0642 5.72297L10.5675 4.63055ZM6.38717 6.70297L4.79617 10.4153L5.89914 10.888L7.49014 7.17567L6.38717 6.70297ZM5.584 11.2031L9.29631 9.61218L8.82362 8.5092L5.11131 10.1001L5.584 11.2031Z" fill="#79897A"/>
  </svg>
);

export default Compass;
