import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { links } from '@reforestum/shared-utils';

import Wrapper from '../components/Layout/Wrapper/Wrapper';
import LoginContainer from '../containers/LoginContainer';
import MobileTopbar from '../components/Header/MobileTopbar/MobileTopbar';

const Login = (props) => {
  return (
    <Wrapper>
      <Helmet>
        <title>
          Login | Reforestum
        </title>
        <link rel="canonical" href={`https://app.reforestum.com${links.getLoginUrl()}`} />
        <meta name="description" content="Welcome back to Reforestum.  Sign in now to check out your carbon footprint and create and follow forests." />
      </Helmet>
      <MobileTopbar>
        <FormattedMessage
          id={'Login.loginButton'}
          defaultMessage={'Login'}
        />
      </MobileTopbar>
      <LoginContainer {...props} />
    </Wrapper>
  );
};

export default Login;
