import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl'

import './OrganizationTeam.css';
import {settingsMessages} from "../../../../constants/messages"
import api from "../../../../constants/api"
import {getUserRequestHeaders} from "../../../Utils/getUserRequestHeaders"
import Modal from "../../../UI/Modal/Modal"
import {Button} from "@reforestum/shared-components"
import {getIsUserAuthenticated, getUserDetails} from "../../../../selectors/userSession"
import {connect} from "react-redux"
import {updateUser} from "../../../../actions/userActions"
import {logout, refreshProfile} from "../../../../actions/userSession"
import Loader from "../../../UI/Loader/Loader"

class ClaimOrganization extends Component {

	constructor(props) {
		super(props)

		this.state = {
			showManualInviteFlowModal: false,
			addTeamMemberFirstName: '',
			addTeamMemberLastName: '',
			addTeamMemberEmail: '',
			addingTeamMember: false,
			isLoading: false,
			orgMembers: null
		}
	}

	async queryOrgMembers() {
		const orgId = this.props.orgId
		let result = []
		try {
			result = await fetch(
				`${api.GET_ORGANIZATION_MEMBERS}${orgId}/members`,
				{headers: getUserRequestHeaders()}
			)
			result = await result.json()
			if(result.message) {
				if(result.message === 'You are unauthorized to make this request') {
					result = null
				}
			}
			else {
				result = false
			}
		}
		catch(e) {
			console.warn('Error team member query', e)
		}
		return result
	}

	async getOrgMembers() {
		this.setState({isLoading: true})
		let result = await this.queryOrgMembers()
		this.setState({orgMembers: result, isLoading: false})
	}

	componentDidMount() {
		this.getOrgMembers()
	}

	userIsAuthenticated() {
		return !!this.props.isAuthenticated
	}

	getUserEmail() {
		let result = null
		if(this.userIsAuthenticated()) {
			result = this.props.user.email
		}
		return result
	}

	async handleAddNewTeamMemberRequest() {
		const {intl, addAlert, setShowClaimOrganizationModal, logout} = this.props
		this.setState({addingTeamMember: true})
		const orgId = this.props.orgId
		let result = []
		const emailToUse = this.getUserEmail() ? this.getUserEmail() : this.state.addTeamMemberEmail
		try {
			result = await fetch(
				`${api.GET_ORGANIZATION_MEMBERS}${orgId}/members`,
				{
					method: 'put',
					headers: {...getUserRequestHeaders(), 'Content-Type': 'application/json'},
					body: JSON.stringify({
						name: this.state.addTeamMemberFirstName,
						surname: this.state.addTeamMemberLastName,
						email: emailToUse,
					})
				}
			)
			result = await result.json()

			if(!result.message) {
				addAlert({
					type: 'success',
					message: emailToUse + ' ' + intl.formatMessage(settingsMessages.orgTeamModalUserHasBeenInvited),
					dismissAfter: 3000
				})
				if(this.getUserEmail()) {
					setTimeout(() => logout(), 3000)
				}
				this.setState({addingTeamMember: false})
				setShowClaimOrganizationModal(false)
			} else {
				this.setState({showManualInviteFlowModal: true})
			}
		}
		catch(e) {
			console.warn('Error in add team member query', e)
		}

		return result
	}

	renderAddTeamMemberModal() {
		const {intl, setShowClaimOrganizationModal} = this.props
		return <Modal
			clickClose={() => setShowClaimOrganizationModal(false)}
			content={
				<div className={'organization-team__add-new-team-member-modal'}>
					<h1 className={'organization-team__add-new-team-member-modal__title'}>
						{intl.formatMessage(settingsMessages.orgTeamModalManageTitle)}
					</h1>
					<p className={'organization-team__add-new-team-member-modal__description'}>
						{intl.formatMessage(settingsMessages.orgTeamModalManageDescription)}
					</p>

					<div className={'organization-team__add-new-team-member-modal__input-heading'}>
						{intl.formatMessage(settingsMessages.orgTeamModalAddUserInputHeadingFirstName)}
					</div>
					<input
						className={'organization-team__add-new-team-member-modal__input'}
						type={'text'}
						value={this.state.addTeamMemberFirstName}
						onChange={e => this.setState({addTeamMemberFirstName: e.target.value})}
					/>

					<div className={'organization-team__add-new-team-member-modal__input-heading'}>
						{intl.formatMessage(settingsMessages.orgTeamModalAddUserInputHeadingLastName)}
					</div>
					<input
						className={'organization-team__add-new-team-member-modal__input'}
						type={'text'}
						value={this.state.addTeamMemberLastName}
						onChange={e => this.setState({addTeamMemberLastName: e.target.value})}
					/>

					<div className={'organization-team__add-new-team-member-modal__input-heading'}>
						{intl.formatMessage(settingsMessages.orgTeamModalAddUserInputHeadingEmail)}
					</div>

					{
						this.getUserEmail()
							?
							<input
								disabled={true}
								className={'organization-team__add-new-team-member-modal__input'}
								type={'email'}
								value={this.getUserEmail()}
							/>
							:
							<input
								className={'organization-team__add-new-team-member-modal__input'}
								type={'email'}
								placeholder={intl.formatMessage(settingsMessages.orgTeamModalAddUserEmailInputPlaceholder)}
								value={this.state.addTeamMemberEmail}
								onChange={e => this.setState({addTeamMemberEmail: e.target.value})}
							/>
					}

					<Button onClick={() => this.handleAddNewTeamMemberRequest()} small className="organization-team__add-new-team-member-modal__cta">
						<FormattedMessage
							id={'Setting.orgTeamModalManageCTA'}
							defaultMessage={'Activate'}
						/>
					</Button>
				</div>
			}
		/>
	}

	renderManualInviteFlowModal() {
		const {intl, setShowClaimOrganizationModal} = this.props
		return <Modal
			clickClose={() => {
				this.setState({showManualInviteFlowModal: false})
				setShowClaimOrganizationModal(false)
			}}
			content={
				<div className={'organization-team__add-new-team-member-modal'}>
					<h1 className={'organization-team__add-new-team-member-modal__title'}>
						{intl.formatMessage(settingsMessages.orgTeamManualInviteTitle)}
					</h1>
					<p className={'organization-team__add-new-team-member-modal__description'}>
						{intl.formatMessage(settingsMessages.orgTeamManualInviteDescription)}
					</p>
				</div>
			}
		/>
	}

	renderAlreadyPartOfOrgMessage() {
		const {intl, setShowClaimOrganizationModal} = this.props
		return <Modal
			clickClose={() => setShowClaimOrganizationModal(false)}
			content={
				<div className={'organization-team__add-new-team-member-modal'}>
					<h1 className={'organization-team__add-new-team-member-modal__title'}>
						{intl.formatMessage(settingsMessages.orgTeamAlreadyInOrgTitle)}
					</h1>
					<p className={'organization-team__add-new-team-member-modal__description'}>
						{intl.formatMessage(settingsMessages.orgTeamAlreadyInOrgDescription)}
					</p>

					<Button onClick={() => window.location.href = '/'} small className="organization-team__add-new-team-member-modal__cta">
						<FormattedMessage
							id={'Setting.orgTeamAlreadyInOrgCTA'}
							defaultMessage={'Continue'}
						/>
					</Button>
				</div>
			}
		/>
	}

	render() {
		return (
			<div className={'invite-new-team-member'}>
				{
					this.state.isLoading && <Loader />
				}
				{
					(this.state.orgMembers === false)
						? this.renderAlreadyPartOfOrgMessage()
						:
						<div>
							{(!this.state.isLoading && this.props.showClaimOrganizationModal && !this.state.showManualInviteFlowModal) && this.renderAddTeamMemberModal()}
							{(!this.state.isLoading && this.state.showManualInviteFlowModal) && this.renderManualInviteFlowModal()}
						</div>
				}
			</div>
		)
	}
}

ClaimOrganization.propTypes = {
	addAlert: PropTypes.func,
	showClaimOrganizationModal: PropTypes.bool,
	setShowClaimOrganizationModal: PropTypes.func,
	logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		logout,
		isAuthenticated: getIsUserAuthenticated(state),
		user: getUserDetails(state),
		refreshProfile: PropTypes.func.isRequired,
		updateUser: PropTypes.func.isRequired,
	};
};

export default connect(mapStateToProps, {
	logout,
	updateUser,
	refreshProfile,
})(injectIntl(ClaimOrganization));
