import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import { Button } from '@reforestum/shared-components';
import FeedbackMessage from '../UI/Forms/FeedbackMessage';

import Row from '../UI/Forms/Row';
import RowAligner from '../UI/Forms/RowAligner';

class CoinbaseForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coin: 'BTC'
    };
  }

  setInputError(error, key) {
    this.setState({
      [key]: error ? true : false,
    });
  }

  moveToNextInput(input) {
    document.querySelector(`${input} input`).focus();
  }

  submitForm(event) {
    const {
      quantity,
      forestId,
      makeCryptoPayment,
      price,
      coin,
      sources
    } = this.props;

    event.preventDefault();

    let selectedSources = []

    Object.keys(sources).forEach(s => selectedSources.push(sources[s]['id']))

    makeCryptoPayment(
      coin,
      // Fiat total
      price,
      quantity,
      forestId,
      selectedSources
    );
  }

  render() {
    const { isCryptoPaying, cryptoPaymentErrors } = this.props;

    return (
      <form
        id="payment-form"
        method="post"
        onSubmit={this.submitForm.bind(this)}>

        { cryptoPaymentErrors &&
          <Row>
            <RowAligner>
              <FeedbackMessage type="error">
                {cryptoPaymentErrors}
              </FeedbackMessage>
            </RowAligner>
          </Row>
        }

        <div style={{marginTop: '20px'}}></div>

        <Row>
          <RowAligner>
            <Button big loading={isCryptoPaying}>
              <FormattedMessage
                id={'CoinbaseForm.submit'}
                defaultMessage={'Pay with Coinbase Commerce'}
              />
            </Button>
          </RowAligner>
        </Row>

      </form>
    );
  }
}

CoinbaseForm.propTypes = {
  quantity: PropTypes.number.isRequired,
  forestId: PropTypes.number.isRequired,
  forestName: PropTypes.string.isRequired,
  intl: intlShape,
  isCryptoPaying: PropTypes.bool.isRequired,
  makeCryptoPayment: PropTypes.func.isRequired,
  cryptoPaymentErrors: PropTypes.string,
  price: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  coin: PropTypes.string.isRequired,
  sources: PropTypes.array.isRequired
};

export default injectIntl(CoinbaseForm);
