import React from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import Wrapper from '../components/Layout/Wrapper/Wrapper'
import DiscoverContainer from '../containers/DiscoverContainer'
import MobileTopbar from '../components/Header/MobileTopbar/MobileTopbar'

const Discover = (props) => {
  return (
    <Wrapper>
      <Helmet>
        <title>
          Discover Projects | Reforestum
        </title>
        <link rel="canonical" href={`https://app.reforestum.com/projects/discover`} />
        <meta name="description" content="Search for carbon offsetting projects and request prices and proposals from suppliers." />
      </Helmet>
      <MobileTopbar>
        <FormattedMessage
          id={'Header.discover'}
          defaultMessage={'Project Discovery'}
        />
      </MobileTopbar>
      <DiscoverContainer {...props} />
    </Wrapper>
  )
}

export default Discover
