import React from 'react';

const CloseSidebar = props => (
  <svg width="14" height="14" stroke="#304351" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g fill="none" fillRule="evenodd">
			<path d="M-3-3h20v20H-3z"/>
			<g strokeWidth="1.5">
				<path d="M12.9 1L7 6.9 1.1 1M12.9 13L7 7.1 1.1 13"/>
			</g>
		</g>
	</svg>
);

export default CloseSidebar;
