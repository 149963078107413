import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';


import './Certificates.css';
import '../CertificateAndBilling.css'
import SettingHeader from '../../SettingHeader/SettingHeader';
import { getFormattedArea } from '../../../../utils/units';
import {Button} from '@reforestum/shared-components';
import { loadFromStorage } from '../../../../utils/localStorage';

class Billing extends Component {

    componentDidMount() {
        const { fetchCertificates } = this.props
        fetchCertificates()
    }
    render() {
        const {
            settingName,
            certificates,
            token,
        } = this.props;

        const currentlyLoadedOrgIfAny = loadFromStorage('organizationId')
    
        return (
            <div>
                <SettingHeader settingName={settingName}/>
                {certificates && certificates.map((certificate) => {
                    const isSqmShares = certificate.user_total_surface !== undefined
                    let formattedArea = null
                    if(isSqmShares) {
                        formattedArea = getFormattedArea(certificate.user_total_surface)
                    }
                    return (
                        <div className='certificate-or-billing-container'>
                            <div className='certificate-or-billing-container-data'>
                            <div className='certificate-or-billing-container-data-column--left'>
                                <div className='certificate-or-billing-container-data-forest-name'>
                                    {certificate.name}
                                </div>
                            </div>
                            <div className='certificate-or-billing-container-data-column--right'>
                                <div className='certificate-or-billing-container-data-amount'>
                                    {isSqmShares ?
                                        `${formattedArea.value} ${formattedArea.unit}` :
                                        `${certificate.user_total_vcu} VCUs`
                                    }
                                </div>
                            </div>
                            </div>
                            {certificate.download_url && <div className='certificate-or-billing-container-download'>
                                    <Button onClick={() => window.open(`${certificate.download_url}?access-token=${token}${currentlyLoadedOrgIfAny ? `&orgId=${currentlyLoadedOrgIfAny}` : ''}`)} small>Download</Button>
                            </div>}
                        </div>
                    )}
                )}
            </div>
        )
    }
}

Billing.propTypes = {
    settingName: PropTypes.string,
};

export default injectIntl(Billing);
