import React from 'react'
import { FormattedMessage } from 'react-intl';

import withAuthProvider from '../../utils/social-auth/withAuthProvider'

import GoogleLogo from '../UI/Icons/GoogleLogo'

class GoogleButton extends React.PureComponent {
  render() {
    const { handleLogin, isDisabled, } = this.props

    return (
      <button
        className="social-button social-button--google"
        type="button"
        onClick={handleLogin}
        disabled={isDisabled}
        style={{ cursor: isDisabled ? "not-allowed" : null}}
      >
        <GoogleLogo className="social-button__icon social-button__icon--google" />
        <span className="social-button__text google__text">
          <FormattedMessage id="Login.withGoogle" defaultMessage="Login with Google"/>
        </span>
        {/* g-plus is class used by tarteaucitron to show/hide allow button */}
        <div className="g-plus social-button__allow" />
      </button>
    )
  }
}

export default withAuthProvider(GoogleButton, 'google')
