import React from 'react';

const Footprint = props => (
	<svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M7.1625 16.25V14.35H12.8375V16.25C12.8375 17.2993 11.9868 18.15 10.9375 18.15H9.0625C8.01316 18.15 7.1625 17.2993 7.1625 16.25Z" stroke="#1F3B21" strokeWidth="1.2"/>
		<path d="M5.9375 8.75H14.0625" stroke="#A5B1A6" strokeWidth="1.2"/>
		<path d="M6.25 5H13.75" stroke="#A5B1A6" strokeWidth="1.2"/>
		<path d="M13.4374 11.875H9.99994H6.56252C6.24998 10.9375 5.6281 8.66864 5.62498 7.1875C5.61845 4.08636 7.49991 1.5625 9.99994 1.5625C12.5 1.5625 14.3815 4.08636 14.375 7.1875C14.3719 8.66864 13.75 10.9375 13.4374 11.875Z" stroke="#1F3B21" strokeWidth="1.2"/>
	</svg>
);

export default Footprint;
