import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'react-layout-components';

import PatronItem from './PatronItem';
import PatronsMore from './PatronsMore';

const PatronsList = ({
  patrons,
  selfPosition,
  selfAmount,
  user,
  forestType,
  registry
}) => {
  const notPresentInList = patrons.length > 0 &&
    patrons.filter(patron => {
      return patron.rank === selfPosition;
    }).length <= 0;

  const presentAfterList = patrons.length > 0 &&
    patrons[patrons.length - 1].rank < selfPosition;

  return (
    <Box column className="patrons-list">
      {!!(selfAmount && selfPosition && notPresentInList
        && !presentAfterList && user.name) &&
        <div className="patrons-list__self">
          <PatronItem
            user={user}
            selfPosition={selfPosition}
            selfAmount={selfAmount}
            forestType={forestType}
            registry={registry}
          />

          <PatronsMore />
        </div>
      }
      <div className="patrons-list__patrons">
        {patrons.map(patron => (

          (selfPosition === patron.rank) ? (
            // handles case where user is in page
            <PatronItem
              key={patron.rank}
              user={user}
              selfPosition={selfPosition}
              selfAmount={selfAmount}
              forestType={forestType}
              registry={registry}
            />
          ) : (
            // other cases, different user
            <PatronItem
              key={patron.rank}
              patron={patron}
              selfPosition={selfPosition}
              forestType={forestType}
              registry={registry}
            />
          )
        ))}
      </div>
      {notPresentInList && presentAfterList && user.name &&
        <div className="patrons-list__self">
          <PatronsMore />

          <PatronItem
            user={user}
            selfPosition={selfPosition}
            selfAmount={selfAmount}
            forestType={forestType}
            registry={registry}
          />
        </div>
      }
    </Box>
  );
};

PatronsList.propTypes = {
  patrons: PropTypes.array.isRequired,
  forestType: PropTypes.string,
  registry: PropTypes.string,
  selfPosition: PropTypes.number,
  selfAmount: PropTypes.number,
  user: PropTypes.object,
};

export default PatronsList;
