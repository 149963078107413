import actionTypes from '../constants/actionTypes';
import { createReducer } from '../utils/reducersHelpers';

const initialState = {
  isFetching: false,
  errorMessages: null,
  oxygen: 0.7273,
  profile_details: { description: "", name:"", avatar_url:"", website:"" },
  profile_balance: { emitted: 0, captured: 0, timeline_formula: "", total_user_surface: 0 },
  profile_projects: []
};

export default createReducer(initialState, {
      [actionTypes.PUBLIC_PROFILE_START]: state => {
      //Reset public profile every time a new fetch is done so it doesn't show a different public profile in case of error
      return {
        ...initialState,
        isFetching: true, 
        errorMessages: null 
      };
},
[actionTypes.PUBLIC_PROFILE_COMPLETE]: (state, action) => {
  return {
      ...state,
      isFetching: false,
      profile_balance: action.payload.balance,
      profile_details: action.payload.profile,
      profile_projects: action.payload.projects,
};
},
[actionTypes.PUBLIC_PROFILE_ERROR]: (state, action) => {
  return {
      ...state,
      isFetching: false,
      errorMessages: action.payload.message
};
}
});

// fetching and error
export const getIsFetching = (state) => state.isFetching;
export const getErrorMessages = (state) => state.errorMessages;

// balance
export const getProfileBalance = (state) => state.profile_balance;

// profile details
export const getProfileDetails = (state) => state.profile_details;

// projects
export const getProfileForests = (state) => state.profile_projects;
