import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './User.css';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import UserDropdown from './UserDropdown';
import AvatarPlaceholder from '@reforestum/shared-components/dist/assets/images/avatar-placeholder.png';
import PreferencesSwitcherDropdown from "./PreferencesSwitcherDropdown";
import {getUserOrImpersonatingOrg} from "../../utils/usersAndOrganizations"

class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      windowWidth: window.innerWidth,
    };

    this.setWindowWidth = this.setWindowWidth.bind(this);
  }

  componentDidMount() {
    this.setWindowWidth();
    window.addEventListener("resize", this.setWindowWidth);
  }

  setWindowWidth() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  renderAccountImage(user) {
    return (
      <div
        className="topbar-user__photo"
        style={{
          backgroundImage: `url(${getUserOrImpersonatingOrg(user).avatar_url || AvatarPlaceholder})`
        }}
      />
    )
  }

  renderPreferencesSwitcherSettingsDropdown() {
    return (<div className="topbar-user__preferences-switcher-dropdown-handler"
      onClick={
        e => { this.props.togglePreferencesSwitcherPopup(); e.stopPropagation() }
      }
    >
      <div className="topbar-user__preferences-switcher-dropdown-holder">
        <PreferencesSwitcherDropdown />
      </div>
    </div>)
  }

  render() {
    const {
      className,
      isUserPopupOpen,
      isPreferencesSwitcherPopupOpen,
      toggleUserDropDown,
      user,
      logout
    } = this.props;

    let { windowWidth } = this.state;

    return (
      <div tabIndex="0"
        className={cx(className, 'topbar-user')}
        onClick={() => windowWidth > 768 && toggleUserDropDown()}
        onKeyDown={e => { e.keyCode === 32 && toggleUserDropDown(); }}
      >
        <div className="topbar-user__name-photo">
          {
            windowWidth > 768 && this.renderAccountImage(user)
          }
          {
            windowWidth <= 768 && <a href="/account/" className="topbar-nav__item">
              {this.renderAccountImage(user)}
            </a>
          }

        </div>
        {
          isUserPopupOpen &&
          <div className="topbar-user__dropdown-holder">
            <div className="topbar-user__dropdown-handler" />
            <UserDropdown
              className="topbar-user__dropdown"
              logout={logout}
              user={user}
            />
          </div>
        }
        {
          isPreferencesSwitcherPopupOpen && this.renderPreferencesSwitcherSettingsDropdown()
        }
        {windowWidth <= 768 &&
          <span onClick={logout} className="topbar-nav__item header-link">
            <FormattedMessage
              id={'Header.logout'}
              defaultMessage={'Logout'}
            />
          </span>
        }
      </div>
    );
  }
}

User.propTypes = {
  className: PropTypes.string,
  isUserPopupOpen: PropTypes.bool.isRequired,
  isPreferencesSwitcherPopupOpen: PropTypes.bool.isRequired,
  toggleUserDropDown: PropTypes.func.isRequired,
  togglePreferencesSwitcherPopup: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

export default User;
