import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'react-layout-components';
import {
  injectIntl,
  intlShape
} from 'react-intl';

import { authMessages } from '../../constants/messages';

import VerticalCenter from '../Layout/VerticalCenter/VerticalCenter';
import CoverImage from '../Layout/CoverImage/CoverImage';
import AuthHeader from './AuthHeader';
import AuthForm from './AuthForm';
import LoginForm from './LoginForm';
import Logo from '../UI/Logo/Logo';

import { SIGNUP_IMAGE } from '../../constants/signupImage';

import './Login.css';

const Login = ({
  handleLogin,
  handleRegisterRedirection,
  errorMessages,
  isLoading,
  intl,
  logout,
  lastAttemptedEmail
}) => {
  
  return (
    <Box width="100%">
      <CoverImage img={SIGNUP_IMAGE} />
      <VerticalCenter className="login-sidebar">
        <div className="reforestum-logo-loginView__wrapper">
          <Logo className="reforestum__logo" />
        </div>
        <AuthHeader
          title={intl.formatMessage(authMessages.loginTitle)}
          text={intl.formatMessage(authMessages.loginText)}
        />
        <AuthForm>
          <LoginForm
            handleLogin={handleLogin}
            handleRegisterRedirection={handleRegisterRedirection}
            errorMessages={errorMessages}
            isLoading={isLoading}
            logout={logout}
            lastAttemptedEmail={lastAttemptedEmail}
          />
        </AuthForm>
      </VerticalCenter>
    </Box>
  )
};

Login.propTypes = {
  handleLogin: PropTypes.func.isRequired,
  errorMessages: PropTypes.string,
  lastAttemptedEmail: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  logout: PropTypes.func.isRequired,
};

export default injectIntl(Login);
