import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';

import Wrapper from '../components/Layout/Wrapper/Wrapper';
import MobileTopbar from '../components/Header/MobileTopbar/MobileTopbar';
import OrganizationPortfolioDashboardContainer from '../containers/OrganizationPortfolioDashboardContainer';

const OrganizationPortfolioDashboard = (props) => {
  return (
    <Wrapper>
      <Helmet>
        <title>
          Dashboard | Reforestum
        </title>
        <link rel="canonical" href={`https://app.reforestum.com/portfolio`} />
        <meta name="description" content="Together, we can stop climate change through massive reforestation! Manage your carbon footprint and create & track forests from your dashboard." />
      </Helmet>
      <MobileTopbar>
        <FormattedMessage
          id={'Header.portfolioDashboard'}
          defaultMessage={'My Portfolio'}
        />
      </MobileTopbar>
      {<OrganizationPortfolioDashboardContainer/>}
    </Wrapper>
  );
};

export default OrganizationPortfolioDashboard;
