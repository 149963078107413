import React from 'react'
import PropTypes from 'prop-types'
import './SearchResults.css'
import '../../UI/Tags/Tags.css'
import {injectIntl, intlShape} from "react-intl"
import {
	searchMessages,
} from '../../../constants/messages'
import {connect} from "react-redux"
import {fetchMyForests} from "../../../actions/forests"
import * as selector from "../../../selectors/forests"
import {getProjectUrl, getPublicProfileUrl} from "@reforestum/shared-utils/src/links"
import {withRouter} from "react-router"
import { Link } from 'react-router-dom';
import {getProjectTypeDetails} from "../../../utils/getProjectTypeDetails"
import Certification from "../../UI/Icons/Certification"
import Text from "../../UI/Text/Text"
import Tooltip from "../../UI/Tooltip/Tooltip"
import Information from "../../UI/Icons/Information"
import {querySearch} from "../../Utils/querySearch"

const SEARCH_RESULT_TYPE_PROJECT = 'project'
const SEARCH_RESULT_TYPE_SECTOR = 'sector'

class SearchResults extends React.Component {
	constructor(props) {
		super(props)
		const urlParams = new URLSearchParams(window.location.search)
		const activeFilter = urlParams.get('filter')
		this.state = {
			hasDoneAtLeastOneSearch: false,
			searchResultsProjects: [],
			searchResultsCompanies: [],
			hasBeenClicked: false,
			activeSearchFilter: activeFilter || this.getSearchFilters().ALL.value,
		}
	}

	getSearchFilters (
		{
			intl={formatMessage: (m)=>m}
		}={}
	) {
		return ({
			ALL: {
				name: 'all',
				value: 'all',
				label: intl.formatMessage(searchMessages.searchFiltersAll),
				shouldRender: () => true
			},
			ORGS: {
				name: 'orgs',
				value: 'orgs',
				label: intl.formatMessage(searchMessages.searchFiltersOrganizations),
				shouldRender: () => this.state.searchResultsCompanies.length > 0
			},
			PROJECTS: {
				name: 'projects',
				value: 'projects',
				label: intl.formatMessage(searchMessages.searchFiltersProjects),
				shouldRender: () => this.getProjectsToRender().length > 0
			},
			COMMUNITIES: {
				name: 'communities',
				value: 'communities',
				label: intl.formatMessage(searchMessages.searchFiltersCommunities),
				shouldRender: () => this.getSectorsToRender().length > 0
			}
		})
	}

	async getSearchResults() {
		this.setState({isLoading: true})
		let result = await querySearch(this.props.searchTerm)
		const companies = (result && result.data) ? result.data.companies : []
		const projects = (result && result.data) ? result.data.projects : []
		this.setState({hasDoneAtLeastOneSearch: true, searchResultsProjects: projects, searchResultsCompanies: companies, isLoading: false})
	}

	componentDidMount() {
		this.getSearchResults()
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevProps.searchTerm !== this.props.searchTerm && this.props.searchTerm !== '')
			this.getSearchResults()
	}

	noResultsFound() {
		return this.state.searchResultsProjects.length + this.state.searchResultsCompanies.length === 0
	}

	shouldUseFullWidthResults() {
		return true
	}

	renderSearchResultsCard(
		{
			type=SEARCH_RESULT_TYPE_PROJECT,
			item={},
			parentId=null,
			projectInfo={},
			key=''
		}={}
	) {
		const {intl} = this.props
		const projectTypeDetails = getProjectTypeDetails(projectInfo.projectType, intl)
		return <Link
			key={key}
			to={(item.id && !parentId) ? getProjectUrl(item.id) : getProjectUrl(parentId, item.id)}
			onClick={
				async () => {
					await this.setState({hasBeenClicked: false, searchResultsProjects: [], searchResultsCompanies: []})
				}
			}
			className={`search-results__card ${type!==SEARCH_RESULT_TYPE_PROJECT && 'search-results__card__sector'}`}
		>
			<div className={'search-results__card__image-and-name'}>
				<div className={'search-results__card__logo'}>
					{
						<div style={{backgroundImage: `url('${(projectInfo.logo || projectInfo.headerImage)}')`}} className="search-results__card__logo__image" alt={item.title} />
					}
					{
						(type === SEARCH_RESULT_TYPE_SECTOR && !item.noOwner) && <div
							style={{backgroundImage: `url('${(item.logo || item.headerImage)}')`}}
							className="search-results__card__logo__company-logo-overlay"
							alt={item.title}
						/>
					}
				</div>
				<div className={'search-results__card__text'}>
					<h3>{item.title}</h3>
					<p>{item.location && item.location}</p>
					{
						(type === SEARCH_RESULT_TYPE_SECTOR) && <Link to={getProjectUrl(projectInfo.id)}>
							{projectInfo.title}
						</Link>
					}
				</div>
			</div>
				<div className={'search-results__card__tags_container'}>
					{projectTypeDetails.tagText &&
						<div className={'tag-certification__wrapper search-results__card__tag'}>
							<Text className="tag-certification__text">{projectTypeDetails.tagText}</Text>
							{projectTypeDetails.tagTooltip && (
								<Tooltip
									content={projectTypeDetails.tagTooltip}
									position={'bottom-right'}
									key={projectInfo.projectType}
								>
									<Information className="tag-information__icon"/>
								</Tooltip>
							)}
						</div>
					}
					{Array.isArray(projectInfo.certifications) && projectInfo.certifications.map(
						cert => (
							<div className={"tag-certification__wrapper"} key={cert.name}>
								<Certification className="tag-certification__icon"/>
								<Text className="tag-certification__text">{cert.name}</Text>
								<Tooltip
									content={cert.tooltip}
									position={'bottom'}
									key={cert.name}
								>
									<Information className="tag-information__icon"/>
								</Tooltip>
							</div>
						)
					)}
				</div>
		</Link>
	}

	renderSearchResultsCardCompany(
		{
			company,
			key=''
		}={}
	) {
		return <Link
			key={key}
			to={getPublicProfileUrl(company.slug)}
			onClick={
				async () => {
					await this.setState({hasBeenClicked: false, searchResultsProjects: [], searchResultsCompanies: []})
				}
			}
			className={`search-results__card search-results__card__company`}
		>
			<div className={'search-results__card__image-and-name search-results__card__image-and-name__company'}>
				<div className={'search-results__card__logo search-results__card__logo__company'}>
					<div style={{backgroundImage: `url('${(company.logo)}')`}} className="search-results__card__logo__image search-results__card__logo__image__company" alt={company.displayName || company.name} />
				</div>
				<div className={'search-results__card__text search-results__card__text__company'}>
					<h3>{company.displayName || company.name}</h3>
				</div>
			</div>
		</Link>
	}

	getProjectsToRender() {
		return this.state.searchResultsProjects.map(
			(projectAndSectors, i) =>
				this.renderSearchResultsCard({
					type: SEARCH_RESULT_TYPE_PROJECT,
					item: projectAndSectors.project,
					projectInfo: projectAndSectors.project,
					key: `search-results__result-${i}`
				})
		)
	}

	renderSearchResultsForProjects() {
		const {intl} = this.props
		return this.getProjectsToRender().length > 0
			?
				<div className={'search-results__results-set'}>
					<h2>
						{intl.formatMessage(searchMessages.searchProjectsResults)}
					</h2>
					{
						this.getProjectsToRender()
					}
				</div>
			: null
	}

	getSectorsToRender() {
		return this.state.searchResultsProjects.map(
			(projectAndSectors, i) =>
				(projectAndSectors.sectors.length === 0)
					? null
					: projectAndSectors.sectors.map(
						(sector, j) =>
							sector.id ? this.renderSearchResultsCard({
								type: SEARCH_RESULT_TYPE_SECTOR,
								item: sector,
								parentId: projectAndSectors.project.id,
								projectInfo: projectAndSectors.project,
								key: `search-results__result-${i}-${j}`
							}) : null
					)
		).filter( (sector) => sector !== null)
	}

	renderSearchResultsForCommunities() {
		const {intl} = this.props
		return <div className={'search-results__results-set'}>
			<h2>
				{intl.formatMessage(searchMessages.searchCommunitiesResults)}
			</h2>
			{
				this.getSectorsToRender()
			}
		</div>
	}

	renderSearchResultsForCompanies() {
		const {intl} = this.props
		return <div className={'search-results__results-set'}>
			<h2>
				{intl.formatMessage(searchMessages.searchCompaniesResults)}
			</h2>
			{
				this.state.searchResultsCompanies
				.map(
					(company, i) =>
						this.renderSearchResultsCardCompany({
							company,
							key: `search-results-company-${i}`
						})
				)
			}
		</div>
	}

	renderSearchFilters() {
		const {activeSearchFilter} = this.state
		const {history, searchTerm} = this.props
		return <div className={'search-filters'}>
			{
				Object.values(this.getSearchFilters({intl: this.props.intl})).filter(f => f.shouldRender()).map(
					filter => <div
						key={filter.name}
						className={`search-filter ${activeSearchFilter === filter.value ? 'search-filter__active' : ''}`}
						onClick={e => {
							history.push({
								pathname: history.pathname,
								search: (searchTerm ? `term=${searchTerm}&` : '') + `filter=${filter.value}`
							})
							e.stopPropagation()
							this.setState({activeSearchFilter: filter.value})
						}}
					>
						{filter.label}
					</div>
				)
			}
		</div>
	}

	shouldRenderCompanyResults() {
		let result = this.state.searchResultsCompanies.length > 0
		result = result && this.state.activeSearchFilter !== this.getSearchFilters().PROJECTS.value
		return result && (this.state.activeSearchFilter === this.getSearchFilters().ALL.value || this.state.activeSearchFilter === this.getSearchFilters().ORGS.value)
	}

	shouldRenderProjectResults() {
		let result = this.state.searchResultsProjects.length > 0
		result = result && (this.state.activeSearchFilter === this.getSearchFilters().ALL.value || this.state.activeSearchFilter === this.getSearchFilters().PROJECTS.value)
		return result
	}

	shouldRenderCommunityResults() {
		let result = this.getSectorsToRender().length > 0
		result = result && (this.state.activeSearchFilter === this.getSearchFilters().ALL.value || this.state.activeSearchFilter === this.getSearchFilters().COMMUNITIES.value)
		return result
	}

	renderSearchResults() {
		return <div
			className={'search-results__void'}
		>
			<div className={`search-results__container ${this.shouldUseFullWidthResults() ? 'search-results__container__results-full-width' : 'search-results__container__results-dropdown'} ${this.noResultsFound() ? 'search-results__container__no-results' : ''}`}>
				{
					this.shouldUseFullWidthResults() && this.renderSearchFilters()
				}
				{
					this.shouldRenderCompanyResults() && <div className={'search-results__results'}>
						{this.renderSearchResultsForCompanies()}
					</div>
				}
				{
					this.shouldRenderProjectResults() && <div className={'search-results__results'}>
						{this.renderSearchResultsForProjects()}
					</div>
				}
				{
					this.shouldRenderCommunityResults() && <div className={'search-results__results'}>
						{this.renderSearchResultsForCommunities()}
					</div>
				}
				{
					(this.state.hasDoneAtLeastOneSearch && this.noResultsFound()) && <div className={'search-results__results__none-found__container'}>
							<div className={'search-results__results__none-found__message'}>
								{this.props.intl.formatMessage(searchMessages.noResultsFound)}
							</div>
							<div className={'search-results__results__none-found__contact__wrapper'}>
								<div className={'search-results__results__none-found__contact'}>
									<h1>
										{this.props.intl.formatMessage(searchMessages.noResultsFoundContactHeading)}
									</h1>
									<div className={'search-results__results__none-found__contact__message'}>
										{this.props.intl.formatMessage(searchMessages.noResultsFoundContactDescription)}
									</div>
									<a href={'https://reforestum.com/contact-us'} className={'search-results__results__none-found__contact__cta'}>
										{this.props.intl.formatMessage(searchMessages.noResultsFoundContactCta)}
									</a>
								</div>
							</div>
					</div>
				}
			</div>
		</div>
	}

	render() {
			return <div className={`search-results`}>
				{this.renderSearchResults()}
		</div>
	}
}

SearchResults.propTypes = {
	myForests: PropTypes.array,
	intl: intlShape,
}

const mapStateToProps = (state) => ({
	myForests: selector.getMyForests(state)
})


export default injectIntl(
	connect(
		mapStateToProps, {
				fetchMyForests
			}
		)(withRouter(SearchResults))
)
