import React from 'react';
import { Helmet } from 'react-helmet';
import Wrapper from '../components/Layout/Wrapper/Wrapper';
import NewSupplierProposalContainer from '../containers/NewSupplierProposalContainer';

const NewSupplier = () => {
  return (
    <Wrapper style={{ paddingTop: '0' }}>
      <Helmet>
        <title>
          New Proposal | Reforestum
        </title>
        <link rel="canonical" href={`https://app.reforestum.com/new-supplier-proposal`} />
        <meta name="description" content="Register a new proposal as a supplier." />
      </Helmet>

      <NewSupplierProposalContainer/>
    </Wrapper>
  );
};

export default NewSupplier;
