import React from 'react';

const NavigationNext = props => (
  <svg transform="scale(-1 1)" {...props} width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 5L1.5 5" stroke="#1F3B21" stroke-width="1.2"/>
    <path d="M6 0.5L1.5 5L6 9.5" stroke="#1F3B21" stroke-width="1.2"/>
  </svg>

);

export default NavigationNext;
