import { defineMessages } from "react-intl";

export const loaderRedirectMessages = defineMessages({
  "Loader.suggestion.1": {
    id: "Loader.suggestion.1",
    defaultMessage: "Hang tight. We're processing your carbon emissions"
  },
  "Loader.suggestion.2": {
    id: "Loader.suggestion.2",
    defaultMessage: "Get ready to plant some forests"
  },
  "Loader.suggestion.3": {
    id: "Loader.suggestion.3",
    defaultMessage: "Time to make a difference"
  },
  "Loader.suggestion.4": {
    id: "Loader.suggestion.4",
    defaultMessage: "Loading...plant forests to offset emissions"
  },
  "Loader.suggestion.5": {
    id: "Loader.suggestion.5",
    defaultMessage: "Ready to plant some trees?"
  }
})

export const greetingMessages = defineMessages({
  neutral: {
    id: 'Dashboard.greetingEmpty',
    defaultMessage: 'Let\'s get started!'
  },
  positive: {
    id: 'Dashboard.greetingPositive',
    defaultMessage: 'You have offset all your CO₂ sources. Amazing work!'
  },
  negative: {
    id: 'Dashboard.greetingNegative',
    defaultMessage: 'It\'s time to make a difference!'
  }
});
export const greetingCTAMessages = defineMessages({
  neutral: {
    id: 'Dashboard.greetingEmptyCallToAction',
    defaultMessage: 'Calculate your carbon footprint'
  },
  positive: {
    id: 'Dashboard.greetingPositiveCallToAction',
    defaultMessage: 'Add carbon emissions'
  },
  negative: {
    id: 'Dashboard.greetingNegativeCallToAction',
    defaultMessage: 'Plant a forest'
  }
})

export const localizedLinkMessages = defineMessages({
  tos: {
    id: "localizedLink.tos",
    defaultMessage: "https://reforestum.com/terms-of-service",
  },
  blog: {
    id: "localizedLink.blog",
    defaultMessage: "https://reforestum.com/blog",
  },
  contact: {
    id: "localizedLink.contact",
    defaultMessage: "https://reforestum.com/contact",
  },
  how: {
    id: "localizedLink.how",
    defaultMessage: "https://reforestum.com/how-it-works",
  },
});

export const calculatorMessages = defineMessages({
  noLogic: {
    id: "calculatorMessages.noLogic",
    defaultMessage: "No Logic for this option yet."
  },

  noWalkingDirections: {
    id: "calculatorMessages.noWalkingDirections",
    defaultMessage: "Sorry, we could not calculate walking directions from {origin} to {destination}.",
  },

  noPathsFoundInBackend: {
    id: "calculatorMessages.noPathsFoundInBackend",
    defaultMessage: "No results found, here some alternatives:",
  }
});

export const checkoutMessages = defineMessages({
  cardNumberLabel: {
    id: "StripeForm.cardNumberLabel",
    defaultMessage: "Card Number"
  },
  expiryDateLabel: {
    id: "StripeForm.expiryDateLabel",
    defaultMessage: "Expiry Date"
  },
  cvcLabel: {
    id: "StripeForm.cvcLabel",
    defaultMessage: "Security Code (CVC)"
  },
  transaction: {
    id: "transaction",
    defaultMessage: "After we've confirmed the transaction you'll receive and email with your invoice. "
  },
  checkForest: {
    id: "checkForest",
    defaultMessage: "Meanwhile, you can already check your percentage of {forestName}!"
  },
  goToForest: {
    id: "goToForest",
    defaultMessage: "Go to {forestName}"
  },
  goToYourShares: {
    id: "goToYourShares",
    defaultMessage: "Locate your share"
  },
  coinSelectionLabel: {
    id: "coinSelectionLabel",
    defaultMessage: "Select Cryptocurrency"
  },
  payWithCardLabel: {
    id: "payWithCardLabel",
    defaultMessage: "Credit Card"
  },
  payWithCryptoLabel: {
    id: "payWithCryptoLabel",
    defaultMessage: "Cryptocurrency"
  }
});

export const transactionsInProjectSection = defineMessages({
  genericContactFormHeading: {
    id: "TransactionsInProject.genericContactFormHeading",
    defaultMessage: "Update your projects"
  },
  genericContactFormInstructions: {
    id: "TransactionsInProject.genericContactFormInstructions",
    defaultMessage: "Reforestum's full project management system will release soon. For now, please describe any changes you wish to make to your profile and our staff will be in touch with you soon."
  },
  genericContactFormFirstName: {
    id: "TransactionsInProject.genericContactFormFirstName",
    defaultMessage: "First Name"
  },
  genericContactFormLastName: {
    id: "TransactionsInProject.LastName",
    defaultMessage: "Last Name"
  },
  genericContactFormQuery: {
    id: "TransactionsInProject.genericContactFormQuery",
    defaultMessage: "Describe your query here"
  },
  genericSuccessMessage: {
    id: "TransactionsInProject.genericSuccessMessage",
    defaultMessage: "Your message has been sent to Reforestum. We will respond soon."
  },
});

export const newsletterPopup = defineMessages({
  newsletterPopupHeading: {
    id: "newsletterPopup.heading",
    defaultMessage: "Stay up-to-date on Reforestum product updates"
  },
  newsletterPopupDescription: {
    id: "newsletterPopup.description",
    defaultMessage: "We are creating the most open platform to navigate and understand voluntary carbon markets. Leave us your email address and stay tuned for when we add a big new features."
  },
  newsletterPopupCTA: {
    id: "newsletterPopup.cta",
    defaultMessage: "Subscribe"
  },
  newsletterPopupSubscriptionSuccess: {
    id: "newsletterPopup.subscriptionSuccess",
    defaultMessage: "Congratulations! You have been added to our newsletter."
  },
});

export const filterMessages = defineMessages({
  co2Label: {
    id: "Filters.co2Label",
    defaultMessage: "Select CO₂ sources:"
  },
  budgetLabel: {
    id: "Filters.budgetLabel",
    defaultMessage: "Define your budget:"
  },
  areaLabel: {
    id: "Filters.areaLabel",
    defaultMessage: "Define your area:"
  },
});

export const photoMessages = defineMessages({
  photoLabel: {
    id: "Form.photoLabel",
    defaultMessage: "Profile Picture"
  },
});

export const addressMessages = defineMessages({
  addressLabel: {
    id: "Form.addressLabel",
    defaultMessage: "Full address"
  },
  addressPlaceholder: {
    id: "Form.addressPlaceholder",
    defaultMessage: "Type your full address"
  },
  cityLabel: {
    id: "Form.cityLabel",
    defaultMessage: "City"
  },
  cityPlaceholder: {
    id: "Form.cityPlaceholder",
    defaultMessage: "Type your city"
  },
  zipLabel: {
    id: "Form.zipLabel",
    defaultMessage: "Zip code"
  },
  zipPlaceholder: {
    id: "Form.zipPlaceholder",
    defaultMessage: "0000-000"
  },
  stateLabel: {
    id: "Form.stateLabel",
    defaultMessage: "State"
  },
  statePlaceholder: {
    id: "Form.statePlaceholder",
    defaultMessage: "Type your state"
  },
  countryLabel: {
    id: "Form.countryLabel",
    defaultMessage: "Country"
  },
  countryPlaceholder: {
    id: "Form.countryPlaceholder",
    defaultMessage: "Type your country"
  },
});

export const settingsMessages = defineMessages({
  personalInformation: {
    id: "Setting.personalInformation",
    defaultMessage: 'Personal Information'
  },
  preferences: {
    id: "Setting.preferences",
    defaultMessage: 'Preferences'
  },
  billing: {
    id: "Setting.billing",
    defaultMessage: 'Billing'
  },
  certificates: {
    id: "Setting.certificates",
    defaultMessage: 'Certificates'
  },
  orgInformation: {
    id: "Setting.orgInformation",
    defaultMessage: 'Organization Information'
  },
  orgTeam: {
    id: "Setting.orgTeam",
    defaultMessage: 'Your Team'
  },
  orgTeamInviteNewMember: {
    id: "Setting.orgTeamInviteNewMember",
    defaultMessage: 'Invite New Member'
  },
  orgTeamHeadingUser: {
    id: "Setting.orgTeamHeadingUser",
    defaultMessage: 'User'
  },
  orgTeamHeadingEmail: {
    id: "Setting.orgTeamHeadingEmail",
    defaultMessage: 'Email'
  },
  orgTeamModalAddUserTitle: {
    id: "Setting.orgTeamModalAddUserTitle",
    defaultMessage: 'Add a member'
  },
  orgTeamModalAddUserDescription: {
    id: "Setting.orgTeamModalAddUserDescription",
    defaultMessage: "Reforestum will send an invite to the email address you provide below. Please be sure to use a company email address."
  },
  orgTeamModalAddUserEmailInputPlaceholder: {
    id: "Setting.orgTeamModalAddUserEmailInputPlaceholder",
    defaultMessage: "E.g. jason@mycompany.com"
  },
  orgTeamModalAddUserInputHeadingFirstName: {
    id: "Setting.orgTeamModalAddUserInputHeadingFirstName",
    defaultMessage: 'First Name'
  },
  orgTeamModalAddUserInputHeadingLastName: {
    id: "Setting.orgTeamModalAddUserInputHeadingLastName",
    defaultMessage: 'Last Name'
  },
  orgTeamModalAddUserInputHeadingEmail: {
    id: "Setting.orgTeamModalAddUserInputHeadingEmail",
    defaultMessage: 'Company Email'
  },
  orgTeamModalUserHasBeenInvited: {
    id: "Setting.orgTeamModalUserHasBeenInvited",
    defaultMessage: 'has been invited to your team'
  },
  orgTeamManualInviteTitle: {
    id: "Setting.orgTeamManualInviteTitle",
    defaultMessage: 'Further Validation Required'
  },
  orgTeamAlreadyInOrgTitle: {
    id: "Setting.orgTeamAlreadyInOrgTitle",
    defaultMessage: 'Already Activated'
  },
  orgTeamAlreadyInOrgDescription: {
    id: "Setting.orgTeamAlreadyInOrgDescription",
    defaultMessage: "You are already part of this organization. You can manage your organization's profile from your dashboard"
  },
  orgTeamAlreadyInvitedTitle: {
    id: "Setting.orgTeamAlreadyInvitedTitle",
    defaultMessage: 'Already Invited'
  },
  orgTeamAlreadyInvitedDescription: {
    id: "Setting.orgTeamAlreadyInvitedDescription",
    defaultMessage: "You have already invited this team member. If they did not receive the invite, please feel free to contact Reforestum for further assistance."
  },
  orgTeamAlreadyInOrgCTA: {
    id: "Setting.orgTeamAlreadyInOrgCTA",
    defaultMessage: "Continue"
  },
  orgTeamManualInviteDescription: {
    id: "Setting.orgTeamManualInviteDescription",
    defaultMessage: 'The Reforestum team has been alerted to your teammate addition request. In this case, your request requires further validation by our team. Thank you for your patience; we will be in touch soon.'
  },
  orgTeamManualInviteButton: {
    id: "Setting.orgTeamManualInviteButton",
    defaultMessage: 'Continue'
  },
  orgTeamModalManageTitle: {
    id: "Setting.orgTeamModalManageTitle",
    defaultMessage: 'Manage Your Organization'
  },
  orgTeamModalManageDescription: {
    id: "Setting.orgTeamModalManageDescription",
    defaultMessage: "Enter your name and work email address to manage your company's profile."
  },
  createNewOrg: {
    id: "Setting.createNewOrg",
    defaultMessage: 'Create new organization'
  },
  privacyLabel: {
    id: "Form.privacyLabel",
    defaultMessage: "Account Privacy"
  },
  nicknameLabel: {
    id: "Form.nicknameLabel",
    defaultMessage: "Nickname"
  },
  nicknamePlaceholder: {
    id: "Form.nicknamePlaceholder",
    defaultMessage: "Type your desired nickname"
  },
  displayNameLabel: {
    id: "Form.displayNameLabel",
    defaultMessage: "Display name in Reforestum"
  },
  displayNamePlaceholder: {
    id: "Form.displayNamePlaceholder",
    defaultMessage: "Type your display name"
  },
  langLabel: {
    id: "Form.langLabel",
    defaultMessage: "Preferred Language"
  },
  companyName: {
    id: "Form.companyName",
    defaultMessage: "Company Name"
  },
  vatNumber: { 
    id: "Form.vatNumber",
    defaultMessage: "VAT number"
  },
  companyBioLabel: { 
    id: "Form.companyBioLabel",
    defaultMessage: "Bio in"
  },
  companyEnvStatementLabel: { 
    id: "Form.companyEnvStatementLabel",
    defaultMessage: "Environmental statement in"
  },
});

export const privacyLevels = defineMessages({
  private: {
    id: "Form.private",
    defaultMessage: "Completely private"
  },
  privateDescription: {
    id: "Form.privateDescription",
    defaultMessage: "No personal information is shown"
  },
  public: {
    id: "Form.public",
    defaultMessage: "Public"
  },
  publicDescription: {
    id: "Form.publicDescription",
    defaultMessage: "Display my name, surname and photo"
  },
  publicDescriptionForCompany: {
    id: "Form.publicDescriptionForCompany",
    defaultMessage: "Display my company name and logo"
  },
  nickname: {
    id: "Form.nickname",
    defaultMessage: "Nickname"
  },
  nicknameDescription: {
    id: "Form.nicknameDescription",
    defaultMessage: "Display only my nickname"
  }
});

export const accountSwitcher = defineMessages({
  switchTo: {
    id: "accountSwitcher.switchTo",
    defaultMessage: "Switch to"
  },
  switchAccount: {
    id: "accountSwitcher.switchAccount",
    defaultMessage: "Switch account"
  },
  yourOrganizations: {
    id: "accountSwitcher.yourOrganizations",
    defaultMessage: "Your organizations"
  },
  createNew: {
    id: "accountSwitcher.createNew",
    defaultMessage: "+ Create new"
  },
  currentlyActingParty: {
    id: "accountSwitcher.currentlyActingParty",
    defaultMessage: "You are currently using Reforestum as"
  },
  currentlyActingPartyActivation: {
    id: "accountSwitcher.currentlyActingPartyActivation",
    defaultMessage: "You are activating your shares as"
  },
  userOrganizationRoleOwner: {
    id: "accountSwitcher.userOrganizationRoleOwner",
    defaultMessage: "Owner"
  },
  userOrganizationRoleCollaborator: {
    id: "accountSwitcher.userOrganizationRoleCollaborator",
    defaultMessage: "Collaborator"
  }
});

export const ksUserSignup = defineMessages({
  ksTitle: {
    id: "ksTitle",
    defaultMessage: "Activate your Reforestum account"
  },
  ksMessage: {
    id: "ksMessage",
    defaultMessage: "Update the form below to redeem your Reforestum account"
  },

  ksMessageCompany: {
    id: "ksMessageCompany",
    defaultMessage: "Update the form below to redeem your Reforestum account on behalf of {companyName} to be able to track the growth of your forest",
  },
});

export const authMessages = defineMessages({
  loginTitle: {
    id: "Login.loginTitle",
    defaultMessage: "Welcome back"
  },
  loginText: {
    id: "Login.loginText",
    defaultMessage: "Type your credentials below to manage your forests and carbon emissions."
  },
  recoveryTitle: {
    id: "Login.recoveryTitle",
    defaultMessage: "Don't worry"
  },
  recoveryText: {
    id: "Login.recoveryText",
    defaultMessage: "Type your email address below and we'll send you a special link for you to reset your password."
  },
  activationTitle: {
    id: "Login.activationTitle",
    defaultMessage: "Activate Your Account"
  },
  activationText: {
    id: "Login.activationText",
    defaultMessage: "Set a new password to activate your Reforestum account"
  },
  resetTitle: {
    id: "Login.resetTitle",
    defaultMessage: "Reset password"
  },
  resetText: {
    id: "Login.resetText",
    defaultMessage: "Type your new password below"
  },
  signupTitle: {
    id: "Login.signupTitle",
    defaultMessage: "Join Reforestum"
  },
  signupText: {
    id: "Login.signupText",
    defaultMessage: "Thank you for wanting to make the world a better place! Type your information below to get started."
  },
  nameLabel: {
    id: "Login.nameLabel",
    defaultMessage: "First name"
  },
  namePlaceholder: {
    id: "Login.namePlaceholder",
    defaultMessage: "Type your first name"
  },
  surnameLabel: {
    id: "Login.surnameLabel",
    defaultMessage: "Last name"
  },
  surnamePlaceholder: {
    id: "Login.surnamePlaceholder",
    defaultMessage: "Type your last name"
  },
  emailLabel: {
    id: "Login.emailLabel",
    defaultMessage: "Email address"
  },
  emailPlaceholder: {
    id: "Login.emailPlaceholder",
    defaultMessage: "Type your email address"
  },
  passwordLabel: {
    id: "Login.passwordLabel",
    defaultMessage: "Password"
  },
  passwordPlaceholder: {
    id: "Login.passwordPlaceholder",
    defaultMessage: "Type your password"
  },
  newPasswordLabel: {
    id: "Password.newPasswordLabel",
    defaultMessage: "New Password"
  },
  newPasswordPlaceholder: {
    id: "Password.newPasswordPlaceholder",
    defaultMessage: "Type desired new password"
  },
  repeatPasswordLabel: {
    id: "Login.repeatPasswordLabel",
    defaultMessage: "Confirm New Password"
  },
  repeatPasswordPlaceholder: {
    id: "Login.repeatPasswordPlaceholder",
    defaultMessage: "Confirm your new password"
  },
  forgot: {
    id: "Login.forgot",
    defaultMessage: "Forgot?"
  },
  recoverySuccess: {
    id: "Login.recoverSuccess",
    defaultMessage: "Please check your inbox now"
  },
  resetSuccess: {
    id: "Login.resetSuccess",
    defaultMessage: "Your password has been successfully reset. You can login with your new credentials."
  },
  activationSuccess: {
    id: "Login.activationSuccess",
    defaultMessage: "Your account has been successfully activated. You can login with your new credentials."
  },
  singularPersonLabel: {
    id: "Login.singularPersonLabel",
    defaultMessage: "Personal use"
  },
  companyLabel: {
    id: "Login.companyLabel",
    defaultMessage: "Business"
  },
  vatNumberLabel: {
    id: "Login.vatNumberLabel",
    defaultMessage: "VAT number"
  },
  vatNumberPlaceholder: {
    id: "Login.vatNumberPlaceholder",
    defaultMessage: "0000-0000-0000-0000"
  },
  companyNameLabel: {
    id: "Login.companyNameLabel",
    defaultMessage: "Company's name"
  },
  companyNamePlaceholder: {
    id: "Login.companyNamePlaceholder",
    defaultMessage: "Type your company name"
  },
  companyEmailLabel: {
    id: "Login.companyEmailLabel",
    defaultMessage: "Company's email"
  },
  companyEmailPlaceholder: {
    id: "Login.companyEmailPlaceholder",
    defaultMessage: "Type your company email"
  },
});

export const searchMessages = defineMessages({
  searchFiltersAll: {
    id: "Search.searchFiltersAll",
    defaultMessage: "All"
  },
  searchFiltersOrganizations: {
    id: "Search.searchFiltersOrganizations",
    defaultMessage: "Organizations"
  },
  searchFiltersProjects: {
    id: "Search.searchFiltersProjects",
    defaultMessage: "Projects"
  },
  searchFiltersCommunities: {
    id: "Search.searchFiltersCommunities",
    defaultMessage: "Communities"
  },
  searchPlaceholder: {
    id: "Search.searchPlaceholder",
    defaultMessage: "Search"
  },
  searchPlaceholderMedium: {
    id: "Search.searchPlaceholderMedium",
    defaultMessage: "Search"
  },
  searchCompaniesResultsSuggestions: {
    id: "Search.searchCompaniesResultsSuggestions",
    defaultMessage: "Organization Suggestions"
  },
  searchProjectsResultsSuggestions: {
    id: "Search.searchProjectsResultsSuggestions",
    defaultMessage: "Project Suggestions"
  },
  searchCommunitiesResultsSuggestions: {
    id: "Search.searchCommunitiesResultsSuggestions",
    defaultMessage: "Community Suggestions"
  },
  searchCompaniesResults: {
    id: "Search.searchCompaniesResults",
    defaultMessage: "Organizations"
  },
    searchProjectsResults: {
    id: "Search.searchProjectsResults",
    defaultMessage: "Projects"
  },
  searchCommunitiesResults: {
    id: "Search.searchCommunitiesResults",
    defaultMessage: "Communities"
  },
  searchProjectsAfforestation: {
    id: "Search.searchProjectsAfforestation",
    defaultMessage: "Afforestation"
  },
  searchProjectsConservation: {
    id: "Search.searchProjectsConservation",
    defaultMessage: "Conservation"
  },
  noResultsFound: {
    id: "Search.noResultsFound",
    defaultMessage: "No Results Were Found For Your Search"
  },
  noResultsFoundContactHeading: {
    id: "Search.noResultsFoundContactHeading",
    defaultMessage: "Something missing?"
  },
  noResultsFoundContactDescription: {
    id: "Search.noResultsFoundContactDescription",
    defaultMessage: "Contact us to add your project, organization or community."
  },
  noResultsFoundContactCta: {
    id: "Search.noResultsFoundContactCta",
    defaultMessage: "Contact Us"
  }
});

export const discoverMessages = defineMessages({
  searchPlaceholder: {
    id: "Discover.searchPlaceholder",
    defaultMessage: "Search Projects"
  },
  selectMultiPlaceholderDefault: {
    id: "Discover.selectMultiPlaceholderDefault",
    defaultMessage: "Filter and select items"
  },
  additionalDetailsPlaceholder: {
    id: "Discover.additionalDetailsPlaceholder",
    defaultMessage: "Specify additional request preferences (e.g. vintage) or additional context for the suppliers"
  }
});

export const searchMultiMessages = defineMessages({
  noSelectionDefaultPill: {
    id: "searchMultiMessages.noSelectionDefaultPill",
    defaultMessage: "Any"
  },
});

export const carbonMessages = defineMessages({
  captured: {
    id: "Carbon.captured",
    defaultMessage: "CO₂ Captured"
  },
  emitted: {
    id: "Carbon.emitted",
    defaultMessage: "CO₂ Emitted"
  },
  oxygen: {
    id: "Carbon.oxygen",
    defaultMessage: "O₂ Generated"
  },
  emptyImageAlt: {
    id: "EmptyCarbon.imageAlt",
    defaultMessage: "No carbon sources available"
  },
  freqFormNone: {
    id: "FrequencyForm.none",
    defaultMessage: "No"
  },
  freqFormDaily: {
    id: "FrequencyForm.daily",
    defaultMessage: "Daily"
  },
  freqFormWeekly: {
    id: "FrequencyForm.weekly",
    defaultMessage: "Weekly"
  },
  freqFormMonthly: {
    id: "FrequencyForm.monthly",
    defaultMessage: "Monthly"
  },
  freqFormYearly: {
    id: "FrequencyForm.yearly",
    defaultMessage: "Yearly"
  },
  freqFormDay: {
    id: "FrequencyForm.day",
    defaultMessage: "day"
  },
  freqFormWeek: {
    id: "FrequencyForm.week",
    defaultMessage: "week"
  },
  freqFormMonth: {
    id: "FrequencyForm.month",
    defaultMessage: "month"
  },
  freqFormTotal: {
    id: "FrequencyForm.total",
    defaultMessage: "Total"
  },
  calculatorLastStepBefore: {
    id: "Calculator.lastStepBefore",
    defaultMessage: "This source will be named"
  },
  calculatorLastStepPlaceholder: {
    id: "Calculator.lastStepPlaceholder",
    defaultMessage: "Carbon source name"
  },
  calculatorHowToReduceYourCarbonFootprint: {
    id: 'Calculator.howToReduceYourCarbonFootprint',
    defaultMessage: 'How can I reduce my carbon footprint?'
  },
  notOffset: {
    id: "Carbon.notOffset",
    defaultMessage: "To offset"
  },
  offset: {
    id: "Carbon.offset",
    defaultMessage: "Offset"
  },
  recurring: {
    id: "Carbon.recurring",
    defaultMessage: "Recurring"
  },
  failedCarbonCalculation: {
    id: "Calculator.failedCarbonCalculation",
    defaultMessage: "Apologies, we don't have available data for the current selection. Please try something else or contact us."
  },
  notAvailable: {
    id: 'CalculatorTotal.notAvailable',
    defaultMessage: 'n/a'
  }
});

export const formMessages = defineMessages({
  countryMatch: {
    id: 'FormErrors.countryDoesNotMatch',
    defaultMessage: 'Please select a valid country from the list'
  },
  required: {
    id: "FormErrors.required",
    defaultMessage: "This field is required"
  },
  email: {
    id: "FormErrors.invalidEmail",
    defaultMessage: "Invalid email address"
  },
  emailDomain: {
    id: "FormErrors.invalidEmailDomain",
    defaultMessage: "Invalid email domain. Please use your corporate email address"
  },
  phone: {
    id: "FormErrors.invalidPhone",
    defaultMessage: "Invalid phone number"
  },
  option: {
    id: "FormErrors.invalidOption",
    defaultMessage: "Invalid option"
  },
  strength: {
    id: "FormErrors.passwordStrength",
    defaultMessage: "Please use a password at least 8 characters long, with at least 1 uppercase, 1 digit and 1 special character."
  },
  passwordNotMatch: {
    id: "FormErrors.passwordNotMatch",
    defaultMessage: "Passwords must match"
  },
  selectDefaultOption: {
    id: "Forms.selectDefaultOption",
    defaultMessage: "Select option"
  },
  startTypingToSeeCommonSources: {
    id: "Forms.startTypingToSeeCommonSources",
    defaultMessage: "Search for a pre-made source or build your own!"
  },
  defaultsMatchingSearchHeading: {
    id: "Forms.defaultsMatchingSearchHeading",
    defaultMessage: "Start a calculation matching"
  },
  quickCalculationHeading: {
    id: "Forms.quickCalculationHeading",
    defaultMessage: "quick calculation"
  },
  defaultOptionsHeading: {
    id: "Forms.defaultOptionsHeading",
    defaultMessage: "Start a calculation"
  },
  noMatchesAndDefaultOptionsHeading: {
    id: "Forms.noMatchesAndDefaultOptionsHeading",
    defaultMessage: "No matches. Showing default options"
  },
  mustAgree: {
    id: "FormErrors.mustAgree",
    defaultMessage: "You must agree to Reforestum's terms of service"
  },
  maxLength: {
    id: "FormErrors.maxLength",
    defaultMessage: "This field must less than {maxLength} characters"
  },
  invalidCountry: {
    id: "FormErrors.invalidCountry",
    defaultMessage: "Please, select a valid country from the list"
  },
  invalidProfileDomain: {
    id: "FormErrors.invalidProfileDomain",
    defaultMessage: "Only alphanumeric characters and dashes are allowed"
  },
  invalidFoundationYear: {
    id: "FormErrors.invalidFoundationYear",
    defaultMessage: "Foundation year should be bigger than 1800 and lower or equal to the current year"
  },
  onlyAlphabeticCharacters: {
    id: "FormErrors.onlyAlphabeticCharacters",
    defaultMessage: "This field must contain only alphabetic characters"
  }
});

export const tosMessages = defineMessages({
  message: {
    id: "tosMessages.message",
    defaultMessage: "I agree with Reforestum's"
  },
  tos: {
    id: "tosMessages.tos",
    defaultMessage: "Terms of service"
  },
});

export const unitsMessages = defineMessages({
  "ac": {
    id: "Units.ac",
    defaultMessage: "acres"
  },
  "C": {
    id: "Units.C",
    defaultMessage: "°C"
  },
  "F": {
    id: "Units.F",
    defaultMessage: "°F"
  },
  "ft": {
    id: "Units.ft",
    defaultMessage: "ft"
  },
  "ft2": {
    id: "Units.ft2",
    defaultMessage: "ft²"
  },
  "gr": {
    id: "Units.gr",
    defaultMessage: "gr"
  },
  "ha": {
    id: "Units.ha",
    defaultMessage: "hectares"
  },
  "kg": {
    id: "Units.kg",
    defaultMessage: "kg"
  },
  "km": {
    id: "Units.km",
    defaultMessage: "km"
  },
  "km2": {
    id: "Units.km2",
    defaultMessage: "km²"
  },
  "lb": {
    id: "Units.lb",
    defaultMessage: "pounds"
  },
  "m": {
    id: "Units.m",
    defaultMessage: "m"
  },
  "M.ac": {
    id: "Units.M.ac",
    defaultMessage: "million acres"
  },
  "M.ha": {
    id: "Units.M.ha",
    defaultMessage: "million hectares"
  },
  "m2": {
    id: "Units.m2",
    defaultMessage: "m²"
  },
  "mi": {
    id: "Units.mi",
    defaultMessage: "mi"
  },
  "mi2": {
    id: "Units.mi2",
    defaultMessage: "mi²"
  },
  "oz": {
    id: "Units.oz",
    defaultMessage: "ounces"
  },
  "st": {
    id: "Units.st",
    defaultMessage: "tons"
  },
  "t": {
    id: "Units.t",
    defaultMessage: "tons"
  },
  "it": {
    id: "Units.it",
    defaultMessage: "tons"
  },
  "vcu": {
    id: "Units.vcu",
    defaultMessage: "VCUs"
  },
  "M.vcu": {
    id: "Units.M.vcu",
    defaultMessage: "million VCUs"
  },
  "tco2e": {
    id: "Units.tco2e",
    defaultMessage: "tCO2e"
  },
  "credits": {
    id: "Units.credits",
    defaultMessage: "Credits"
  },
  "M.credits": {
    id: "Units.M.credits",
    defaultMessage: "million Credits"
  },
})

export const rfpCard = defineMessages({
  "location": {
    id: "rfpCard.location",
    defaultMessage: "location"
  },
  "country": {
    id: "rfpCard.country",
    defaultMessage: "country"
  },
  "projectType": {
    id: "rfpCard.projectType",
    defaultMessage: "Project Type"
  },
  "registry": {
    id: "rfpCard.registry",
    defaultMessage: "Registry"
  },
  "beZeroRating": {
    id: "rfpCard.beZeroRating",
    defaultMessage: "BeZero Rating"
  },
  "descriptionTargetVolume": {
    id: "rfpCard.descriptionTargetVolume",
    defaultMessage: "Target Volume"
  },
  "descriptionTargetPrice": {
    id: "rfpCard.descriptionTargetPrice",
    defaultMessage: "Target Price"
  },
  "descriptionClosingDate": {
    id: "rfpCard.descriptionClosingDate",
    defaultMessage: "Closing Date"
  },
  "EditRequestFormPlaceholder": {
    id: "rfpCard.EditRequestFormPlaceholder",
    defaultMessage: "Further details about the changes or criteria."
  },
  "AddToPortfolioFormPlaceholder": {
    id: "rfpCard.AddToPortfolioFormPlaceholder",
    defaultMessage: "Further details about the contracted conditions and the delivery terms"
  },
  "SupplierEmailCopied": {
    id: "rfpCard.SupplierEmailCopied",
    defaultMessage: "The supplier's email address was copied to your clipboard"
  },
  "EditRFPFormSubmitted": {
    id: "rfpCard.EditRFPFormSubmitted",
    defaultMessage: "Your RFP edit request has been submitted"
  },
  "AddToPortfolioRFPFormSubmitted": {
    id: "rfpCard.AddToPortfolioRFPFormSubmitted",
    defaultMessage: "Your request to list this proposal in your portfolio has been submitted"
  },
  "unitTons": {
    id: "rfpCard.unitTons",
    defaultMessage: "tons"
  },
})

export const fileDownload = defineMessages({
  "fileDownload": {
    id: "fileDownload.download",
    defaultMessage: "Download"
  },
})

export const rfpProposalDetails = defineMessages({
  "supplierCountry": {
    id: "rfpProposalDetails.supplierCountry",
    defaultMessage: "country"
  },
  "supplierType": {
    id: "rfpProposalDetails.supplierType",
    defaultMessage: "Supplier Type"
  },
})

export const graphMessages = defineMessages({
  forestArea: {
    id: 'ForestDetails.forestArea',
    defaultMessage: 'Forest total area'
  },
  shareArea: {
    id: 'ForestDetails.shareArea',
    defaultMessage: 'Forest share area'
  },
  totalCo2Capt: {
    id: 'ForestDetails.totalCo2Capt',
    defaultMessage: 'CO₂ captured to date'
  },
  totalCo2CaptForestTooltip: {
    id: 'ForestDetails.totalCo2CaptForestTooltip',
    defaultMessage: 'Amount of CO2 captured by the project to date'
  },
  totalCo2CaptmySharesTooltip: {
    id: 'ForestDetails.totalCo2CaptmySharesTooltip',
    defaultMessage: 'Proportional amount of CO2 captured by your Forest Shares in the the project so far'
  },
  yearEstimateDate: {
    id: 'ForestDetails.yearEstimateDate',
    defaultMessage: 'Estimated CO2 captured by {year}'
  },
  yearEstimate: {
    id: 'ForestDetails.yearEstimate',
    defaultMessage: '{year} year estimate'
  },
  yearEstimatemySharesTooltip: {
    id: 'ForestDetails.yearEstimatemySharesTooltip',
    defaultMessage: 'Estimated amount of CO2 your Forest Shares will capture during its minimum permanence period, according to its certification.'
  },
  yearEstimateForestTooltip: {
    id: 'ForestDetails.yearEstimateForestTooltip',
    defaultMessage: 'Estimated amount of CO2 the forest will capture during its minimum permanence period, according to its certification.'
  },
  totalOwnedVCUs: {
    id: 'CarbonGraph.totalOwnedVCUs',
    defaultMessage: 'Carbon credits owned'
  },
  totalOwnedReductions: {
    id: 'CarbonGraph.totalOwnedReductions',
    defaultMessage: 'Total CO₂ Reductions'
  },
  ownedTreesTooltip: {
    id: 'ForestDetails.ownedTreesTooltip',
    defaultMessage: 'Estimated number of trees in the area corresponding to your Shares in this Forest'
  }
})

export const forestDetailsMessages = defineMessages({
  futureRemovalsCO2Tooltip: {
    id: 'ForestDetails.futureRemovalsCO2Tooltip',
    defaultMessage: 'These are afforestation offset projects funded through Forest Shares, which are portions of forests with an associated amount of future CO2 removal that is estimated based on their size and the verified absorption capacity of the project.'
  },
  forestryProjectsBasedOnCarbonCreditsTooltip: {
    id: 'ForestDetails.forestryProjectsBasedOnCarbonCreditsTooltip',
    defaultMessage: 'These are offsetting forestry projects (afforestation or conservation and restoration) funded through carbon credits that are issued once CO2 removal or avoidance has been verified by accredited bodies such as Verra.'
  },
  occupiedSurfacePercentage: {
    id: 'ForestDetails.occupiedSurfacePercentage',
    defaultMessage: '{occupiedSurfacePercentage}% claimed'
  },
  occupiedSurfacePercentageTooltip: {
    id: 'ForestDetails.occupiedSurfacePercentageTooltip',
    defaultMessage: '% of forest already funded by Reforestum’s community'
  },
  myShares: {
    id: 'ForestDetails.myShares',
    defaultMessage: 'My shares'
  },
  mySharesTooltip: {
    id: 'ForestDetails.mySharesTooltip',
    defaultMessage: 'Forest Shares correspond to the Surface area you own in this forest'
  },
  weather: {
    id: 'ForestDetails.weather',
    defaultMessage: 'Weather'
  },
  extension: {
    id: 'ForestDetails.extension',
    defaultMessage: 'Extension'
  },
  myCredits: {
    id: 'ForestDetails.myCredits',
    defaultMessage: 'My carbon credits'
  },
  myCreditsTooltip: {
    id: 'ForestDetails.myCreditsTooltip',
    defaultMessage: 'Carbon credits you have acquired in this project, each one corresponding to 1 ton of CO2 reduced or avoided'
  },
  capturedCo2: {
    id: 'ForestDetails.capturedCo2',
    defaultMessage: 'Captured CO₂'
  },
  certificationsDescription: {
    id: 'ForestDetails.certificationsDescription',
    defaultMessage: 'Génenis is certified as a carbon footprint compensation project by the Spanish Office of Climate Change (OECC). The OECC is a management center dependent on the Ministry of Environment of the Ministry of Agriculture, Food and Environment of Spain to develop policies related to climate change.'
  },
  certificationsTitle: {
    id: 'ForestDetails.certificationsTitle',
    defaultMessage: 'Forest official certifications'
  },
  claimed: {
    id: 'ForestDetails.claimed',
    defaultMessage: 'Claimed'
  },
  coordinates: {
    id: 'ForestDetails.coordinates',
    defaultMessage: 'Coordinates'
  },
  enlargeForest: {
    id: 'ForestDetails.enlargeForest',
    defaultMessage: 'Enlarge forest'
  },
  forestedArea: {
    id: 'ForestDetails.forestedArea',
    defaultMessage: 'Forested Area'
  },
  forestedPercentage: {
    id: 'ForestDetails.forestedPercentage',
    defaultMessage: 'Forested'
  },
  fundThisForest: {
    id: 'ForestDetails.fundThisForest',
    defaultMessage: 'Fund this forest'
  },
  fundThisForestSharesTooltip: {
    id: 'ForestDetails.fundThisForestSharesTooltip',
    defaultMessage: 'Fund this project by acquiring Forest Shares to offset your...'
  },
  fundThisForestConservationTooltip: {
    id: 'ForestDetails.fundThisForestConservationTooltip',
    defaultMessage: 'Fund this project by acquiring certified Carbon Credits ...'
  },
  generatedO2: {
    id: 'ForestDetails.generatedO2',
    defaultMessage: 'Generated O₂'
  },
  infoTitle: {
    id: 'ForestDetails.infoTitle',
    defaultMessage: 'About {forestName}'
  },
  monthlyAvg: {
    id: 'ForestDetails.monthlyAvg',
    defaultMessage: 'Monthly Avg.'
  },
  myForestArea: {
    id: 'ForestDetails.myForestArea',
    defaultMessage: 'My Forest Area'
  },
  reforestedArea: {
    id: 'ForestDetails.reforestedArea',
    defaultMessage: 'Reforested'
  },
  reforesters: {
    id: 'ForestDetails.reforesters',
    defaultMessage: 'reforesters'
  },
  reforestersTooltip: {
    id: 'ForestDetails.reforestersTooltip',
    defaultMessage: 'Members of Reforestum\'s global community supporting this project'
  },
  successionTitle: {
    id: 'ForestDetails.successionTitle',
    defaultMessage: 'Succession Plan'
  },
  teamTitle: {
    id: 'ForestDetails.teamTitle',
    defaultMessage: '{forestName} team'
  },
  totalArea: {
    id: 'ForestDetails.totalArea',
    defaultMessage: 'Total area'
  },
  yourArea: {
    id: 'ForestDetails.yourArea',
    defaultMessage: 'Your reforested area'
  },
  expandMyShare: {
    id: 'ForestDetails.expandMyShare',
    defaultMessage: 'Expand my share'
  },
  becomeAReforester: {
    id: 'ForestDetails.becomeAReforester',
    defaultMessage: 'Become a reforester'
  },
  growMyImpact: {
    id: 'ForestDetails.growMyImpact',
    defaultMessage: 'Grow my impact'
  }
})

export const forestMapMessages = defineMessages({
  loadingReforesters: {
    id: 'forestMapMessages.loadingReforesters',
    defaultMessage: 'Loading reforesters...'
  }
})

export const sponsorsCard = defineMessages({
  creditsOwned: {
    id: 'SponsorsCard.creditsOwned',
    defaultMessage: 'Credits Owned'
  },
  reforesters: {
    id: 'SponsorsCard.reforesters',
    defaultMessage: 'Reforesters'
  },
  carbonCapture: {
    id: 'SponsorsCard.carbonCapture',
    defaultMessage: 'Carbon Capture'
  }
})

export const forestMapLayers = defineMessages({
  satellite: {
    id: "ForestMapLayers.satellite",
    defaultMessage: "Satellite"
  },
  map: {
    id: "ForestMapLayers.map",
    defaultMessage: "Map"
  },
  carboDensity: {
    id: "ForestMapLayers.carbonDensity",
    defaultMessage: "Carbon Density"
  },
  sentinel2: {
    id: "ForestMapLayers.sentinel2",
    defaultMessage: "Sentinel 2"
  },
  sentinel2Tooltip: {
    id: "ForestMapLayers.sentinel2Tooltip",
    defaultMessage: "Near real-time images obtained by the Sentinel 2 satellite from the European Space Agency"
  },
  planetTCI: {
    id: "ForestMapLayers.planetTCI",
    defaultMessage: "Planet natural color"
  },
  planetTCITooltip: {
    id: "ForestMapLayers.planetTCITooltip",
    defaultMessage: "Cloudless composite of images showing the True Color Image (TCI) bands, as seen with a human eye"
  },
  planetNDVI: {
    id: "ForestMapLayers.planetNDVI",
    defaultMessage: "Planet vegetation index"
  },
  planetNDVITooltip: {
    id: "ForestMapLayers.planetNDVITooltip",
    defaultMessage: "Cloudless composite of images, showing the Normalized Difference Vegetation Index (NDVI). Blue pixels indicate lower level of biomass, yellow pixels higher."
  },
  craggHighResolution: {
    id: "ForestMapLayers.craggHighResolution",
    defaultMessage: "High resolution"
  }
})

export const layerSelectorDropdown = defineMessages({
  mrvOptionsTitle: {
    id: "LayerSelectorDropdown.mrvOptionsTitle",
    defaultMessage: "AI ENABLED MONITORING",
  },
  mrvOptionsTitleTooltip: {
    id: "LayerSelectorDropdown.mrvOptionsTitleTooltip",
    defaultMessage: "The options below provide additional layers of verification by analyzing relevant metrics of the project thanks to Reforestum’s proprietary machine learning algorithms.",
  },
  planetOptionsTitle: {
    id: "LayerSelectorDropdown.planetOptionsTitle",
    defaultMessage: "PLANET HIGH-RESOLUTION",
  },
  planetOptionsTitleTooltip: {
    id: "LayerSelectorDropdown.planetOptionsTitleTooltip",
    defaultMessage: "Images with a resolution of 5 meters per pixel, taken by Planet and provided by the NICFI program",
  }
})

export const ImpactMRV = defineMessages({
  totalVCUsTooltip: {
    id: "ImpactMRV.totalVCUsTooltip",
    defaultMessage: "Total amount of Carbon Credits issued in this project by the certifying body to date."
  },
})

export const notifyForestAlertMessages = defineMessages({
  errorLabel: {
    id: "NotifyForestAlert.error.label",
    defaultMessage: "Error!"
  },
  errorMessage: {
    id: "NotifyForestAlert.error.message",
    defaultMessage: "Failed to set the notification"
  },
  successLabel: {
    id: "NotifyForestAlert.success.label",
    defaultMessage: "Great!"
  },
  successMessage: {
    id: "NotifyForestAlert.success.message",
    defaultMessage: "Notification has been set"
  }
})

export const notifyForestTooltipMessages = defineMessages({
  text: {
    id: "NotifyForestTooltip.text",
    defaultMessage: "Enable notifications for this forest"
  }
})

export const modalCalcExitMessages = defineMessages({
  title: {
    id: 'ModalCalcExit.title',
    defaultMessage: 'Wait, don\'t go!'
  },
  text: {
    id: 'ModalCalcExit.text',
    defaultMessage: 'Are you sure you want to leave your carbon calculation without saving your source?'
  },
  confirmButtonText: {
    id: 'ModalCalcExit.confirmButtonText',
    defaultMessage: 'Return to save'
  },
  cancelButtonText: {
    id: 'ModalCalcExit.cancelButtonText',
    defaultMessage: 'Don\'t save'
  }
})

export const modalNotifyForestNotAuthMessages = defineMessages({
  title: {
    id: 'ModalNotifyForest.notAuthenticated.title',
    defaultMessage: 'Sign up for Notifications'
  },
  text: {
    id: 'ModalNotifyForest.notAuthenticated.text',
    defaultMessage: 'Join Reforestum to receive notifications on forest projects.'
  },
  confirmButtonText: {
    id: 'ModalNotifyForest.notAuthenticated.confirmButtonText',
    defaultMessage: 'Sign up for Free'
  },
  cancelButtonText: {
    id: 'ModalNotifyForest.notAuthenticated.cancelButtonText',
    defaultMessage: 'No thanks'
  }
})

export const MRVLinkToWebsite = defineMessages({
  mrvLink: {
    id: "mrvLinkToWeb",
    defaultMessage: "https://reforestum.com/mrv",
  }
});

export const MRVSnackMessages = defineMessages({
  welcomeTitle: {
    id: 'MRVSnack.welcomeTitle',
    defaultMessage: '{forestName} is an AI monitored forest'
  },
  welcomeDescription: {
    id: 'MRVSnack.welcomeDescription',
    defaultMessage: 'Explore this project and the different map layers'
  }
})

export const MRVGraphMessages = defineMessages({
  carbonCaptured: {
    id: 'MRVGraph.carbonCaptured',
    defaultMessage: 'Carbon Captured'
  },
  creditsIssuedTooltip: {
    id: 'MRVGraph.creditsIssuedTooltip',
    defaultMessage: 'Credits issued by VCS'
  },
  currentlyLoadedDatapointTooltip: {
    id: 'MRVGraph.currentlyLoadedDatapointTooltip',
    defaultMessage: "Date of the layer loaded on the map",
  },
  vcsIssuanceTooltip: {
    id: 'MRVGraph.vcsIssuanceTooltip',
    defaultMessage: 'Dates when Verra audits the project according to VCS standards and issues carbon credits.'
  },
  carbonCapturedTooltip: {
    id: 'MRVGraph.carbonCapturedTooltip',
    defaultMessage: 'Amount of CO2 this project has captured to date.'
  },
  planetCarbonLengend: {
    id: 'MRVGraph.planetCarbonLengend',
    defaultMessage: 'Carbon avoided'
  },
  planetCarbonLengendTooltip: {
    id: 'MRVGraph.planetCarbonLengendTooltip',
    defaultMessage: 'Total tons of CO2 emissions avoided to date'
  },
})

export const MyForestSummary = defineMessages({
  areaOwned: {
    id: 'MyForestSummary.areaOwned',
    defaultMessage: 'Area Owned'
  },
  area: {
    id: 'MyForestSummary.area',
    defaultMessage: 'Area'
  },
  co2: {
    id: 'MyForestSummary.co2',
    defaultMessage: 'Estimated removal to date'
  },
  co2UnitUpdated: {
    id: 'MyForestSummary.co2UnitUpdated',
    defaultMessage: 'of CO₂'
  },
  corporateProfileNavigationSpreadProjectsTitle: {
    id: 'MyForestSummary.corporateProfileNavigationSpreadProjectsTitle',
    defaultMessage: 'Projects'
  },
  corporateProfileNavigationSpreadProjectsDescription: {
    id: 'MyForestSummary.corporateProfileNavigationSpreadProjectsDescription',
    defaultMessage: 'Explore {companyName} neutralization projects'
  },
  corporateProfileNavigationSpreadAnalyticsTitle: {
    id: 'MyForestSummary.corporateProfileNavigationSpreadAnalyticsTitle',
    defaultMessage: 'Analytics'
  },
  corporateProfileNavigationSpreadAnalyticsDescription: {
    id: 'MyForestSummary.corporateProfileNavigationSpreadAnalyticsDescription',
    defaultMessage: 'Gather insights about {companyName} neutralization'
  },
  corporateProfileNavigationSpreadCommunityTitle: {
    id: 'MyForestSummary.corporateProfileNavigationSpreadCommunityTitle',
    defaultMessage: 'Community'
  },
  corporateProfileNavigationSpreadCommunityDescription: {
    id: 'MyForestSummary.corporateProfileNavigationSpreadCommunityDescription',
    defaultMessage: 'See how the {companyName} community is taking action'
  },
  unOnboardedCompanyModeEstimated: {
    id: 'MyForestSummary.unOnboardedCompanyModeEstimated',
    defaultMessage: 'Estimated'
  },
  unonboardedCompanyDefaultEventsDescription: {
    id: 'MyForestSummary.unonboardedCompanyDefaultEventsDescription',
    defaultMessage: 'This company has not yet listed any updates about this project. If you would like to know more about the great work being done by this company, we encourage you to let them know by requesting that they add updates here.'
  },
  unonboardedCompanyInterestFormSubmissionSuccess: {
    id: 'PublicDashboard.unOnboardedCompany.recordInterest.formSubmissionSuccess',
    defaultMessage: 'Your interest has been submitted'
  },
  unonboardedCompanyDefaultEventsTitle: {
    id: 'MyForestSummary.unonboardedCompanyDefaultEventsTitle',
    defaultMessage: 'No updates so far'
  },
  unonboardedCompanyDefaultEventsCTAContactCompany: {
    id: 'MyForestSummary.unonboardedCompanyDefaultEventsCTAContactCompany',
    defaultMessage: 'Request updates'
  },
  unonboardedCompanyDefaultEventsCTASignInToAddUpdates: {
    id: 'MyForestSummary.unonboardedCompanyDefaultEventsCTASignInToAddUpdates',
    defaultMessage: 'Update profile'
  },
  jumpToSectionProjectsAndCommunity: {
    id: 'MyForestSummary.jumpToSectionProjectsAndCommunity',
    defaultMessage: 'Projects & Community'
  },
  jumpToSectionSimilarCompanies: {
    id: 'MyForestSummary.jumpToSectionSimilarCompanies',
    defaultMessage: 'Similar Companies'
  },
  requestUpdatesEmail: {
    id: 'MyForestSummary.requestUpdatesEmail',
    defaultMessage: 'Email'
  },
  requestUpdatesFirstName: {
    id: 'MyForestSummary.requestUpdatesFirstName',
    defaultMessage: 'First Name'
  },
  requestUpdatesLastName: {
    id: 'MyForestSummary.requestUpdatesLastName',
    defaultMessage: 'Last Name'
  },
  requestUpdatesNote: {
    id: 'MyForestSummary.requestUpdatesNote',
    defaultMessage: 'Notes'
  },
  conservation: {
    id: 'MyForestSummary.conservation',
    defaultMessage: 'Verified offsets (VCUs)'
  },
  reforesters: {
    id: 'MyForestSummary.reforesters',
    defaultMessage: 'Community'
  },
  reforestersLink: {
    id: 'MyForestSummary.reforestersLink',
    defaultMessage: 'See reforesters'
  },
  tonsFixed: {
    id: 'MyForestSummary.tonsFixed',
    defaultMessage: 'tons of CO₂'
  },
  seoDataDescription: {
    id: 'MyForestSummary.seoDataDescription',
    defaultMessage: 'X is offsetting Y in Z projects. About X:'
  },
  seoDataTitle: {
    id: 'MyForestSummary.seoDataTitle',
    defaultMessage: 'A look into the Carbon Neutralization work of this company'
  },
  sharingMessage: {
    id: 'MyForestSummary.sharingMessage',
    defaultMessage: "Share this Company's profile"
  },
  sharingDescription: {
    id: 'MyForestSummary.sharingDescription',
    defaultMessage: "Spread the word about this company's carbon neutralization offsetting efforts. Celebrate their great work, and motivate others to do the same with optimum transparency and efficacy."
  },
  textCopiedToClipboard: {
    id: 'MyForestSummary.textCopiedToClipboard',
    defaultMessage: "Sharing link copied to your clipboard! You can now paste it anywhere."
  }
})

export const Tags = defineMessages({
  afforestation: {
    id: 'Tags.afforestation',
    defaultMessage: 'Afforestation'
  },
  conservation: {
    id: 'Tags.conservation',
    defaultMessage: 'Conservation'
  },
})

export const BeZero = defineMessages({
  ratingNotAssessed: {
    id: 'beZero.ratingNotAssessed',
    defaultMessage: 'Not Assessed'
  },
  ratingSignificantRisk: {
    id: 'beZero.ratingSignificantRisk',
    defaultMessage: 'Significant Risk'
  },
  ratingNotableRisk: {
    id: 'beZero.ratingNotableRisk',
    defaultMessage: 'Notable Risk'
  },
  ratingSomeRisk: {
    id: 'beZero.ratingSomeRisk',
    defaultMessage: 'Some Risk'
  },
  ratingLittleRisk: {
    id: 'beZero.ratingLitleRisk',
    defaultMessage: 'Little Risk'
  },
  ratingVeryLowRisk: {
    id: 'beZero.ratingVeryLowRisk',
    defaultMessage: 'Very Low Risk'
  },
  unassessed: {
    id: 'beZero.unassessed',
    defaultMessage: '(Unassessed)'
  },
  dataReferencesTitle: {
    id: 'beZero.dataReferencesTitle',
    defaultMessage: 'More Information about BeZero Ratings'
  },
  dataReferencesMethodology: {
    id: 'beZero.dataReferencesMethodology',
    defaultMessage: 'Methodology'
  },
  endUserDisclaimerAndUsageGuidelines: {
    id: 'beZero.endUserDisclaimerAndUsageGuidelines',
    defaultMessage: 'End User Disclaimer And Usage Guidelines'
  },
  beZeroDataTitle: {
    id: 'beZero.beZeroDataTitle',
    defaultMessage: 'Insights about project risk factors'
  },
  beZeroSummaryTitle: {
    id: 'beZero.beZeroSummaryTitle',
    defaultMessage: 'BeZero Project Summary'
  },
  beZeroDataDesc: {
    id: 'beZero.beZeroDataDesc',
    defaultMessage: 'Reforestum has partenered with BeZero to bring you the most trusted, independently appraised risk factor evaluations of Carbon Projects.'
  },
  beZeroCarbonRating: {
    id: 'beZero.beZeroCarbonRating',
    defaultMessage: 'BeZero Carbon Rating'
  },
  beZeroRatingOnlyWidgetTitle: {
    id: 'beZero.beZeroRatingOnlyWidgetTitle',
    defaultMessage: 'Rating'
  },
  additionalityPercentage: {
    id: 'beZero.additionalityPercentage',
    defaultMessage: 'Additionality'
  },
  overCreditingPercentage: {
    id: 'beZero.overCreditingPercentage',
    defaultMessage: 'Over Crediting'
  },
  leakagePercentage: {
    id: 'beZero.leakagePercentage',
    defaultMessage: 'Leakage'
  },
  permanencePercentage: {
    id: 'beZero.permanencePercentage',
    defaultMessage: 'Permanence'
  },
  policyEnvironmentPercentage: {
    id: 'beZero.policyEnvironmentPercentage',
    defaultMessage: 'Policy Environment'
  },
  perverseIncentives: {
    id: 'beZero.perverseIncentives',
    defaultMessage: 'Perverse Incentives'
  },
})

export const OffsetActivationAlerts = defineMessages({
  activatedSuccessfully: {
    id: 'OffsetActivationAlerts.activatedSuccessfully',
    defaultMessage: 'The activation of your shares has been completed successfully!'
  },
  errosWhileClaiming: {
    id: 'OffsetActivationAlerts.errosWhileClaiming',
    defaultMessage: 'There was an error during the activation of your shares. Please try again or contact support.'
  },
  loginRequest: {
    id: 'OffsetActivationAlerts.loginRequest',
    defaultMessage: 'Please login to visit your shares'
  },
  checkYourContributionsBox: {
    id: 'OffsetActivationAlerts.checkYourContributionsBox',
    defaultMessage: 'Your shares have been added to your profile. Check your contributions below!'
  },
})

export const MyTreesAlerts = defineMessages({
  sharingLinkCopied: {
    id: 'MyTrees.sharingLinkCopied',
    defaultMessage: 'Sharing link copied! Paste it wherever your would like to share this offset.'
  }
})

export const UserActivationAlerts = defineMessages({
  accountAlreadyActivated: {
    id: 'UserActivationAlerts.accountAlreadyActivated',
    defaultMessage: 'Account already activated. Please register, login or contact support'
  },
  successfullyRegistered: {
    id: 'UserActivationAlerts.successfullyRegistered',
    defaultMessage: 'Account successfully registered. Welcome to Reforestum!'
  },
  userLoggedIn: {
    id: 'UserActivationAlerts.userLoggedIn',
    defaultMessage: 'You\'re already registered in Reforestum'
  },
  getAccountActivationError: {
    id: 'UserActivationAlerts.getAccountActivationError',
    defaultMessage: 'There was an error. Please contact support.'
  }
})

export const AlertMessages = defineMessages({
  userSuccessfullyUpdated: {
    id: 'AlertMessages.userSuccessfullyUpdated',
    defaultMessage: 'User successfully updated'
  },
  orgSuccessfullyUpdated: {
    id: 'AlertMessages.orgSuccessfullyUpdated',
    defaultMessage: 'Organization successfully updated'
  },
  orgSuccessfullyCreated: {
    id: 'AlertMessages.orgSuccessfullyCreated',
    defaultMessage: 'Organization successfully created'
  },
  impersonatingNewOrg: {
    id: 'AlertMessages.impersonatingNewOrg',
    defaultMessage: 'You are now impersonating the newly created org'
  },
  avatarSuccessfullyUpdated: {
    id: 'AlertMessages.avatarSuccessfullyUpdated',
    defaultMessage: 'Profile picture successfully updated'
  },
  corporateProfileUrlCopiedToClipboard: {
    id: 'AlertMessages.corporateProfileUrlCopiedToClipboard',
    defaultMessage: 'Profile URL copied to clipboard'
  },
  corporateProfileUrlCopiedNotice: {
    id: 'AlertMessages.corporateProfileUrlCopiedNotice',
    defaultMessage: 'Notice that the Corporate Profile won\'t be available until you submit this form'
  },
  uploadValidAvatarFile: {
    id: 'AlertMessages.uploadValidAvatarFile',
    defaultMessage: 'Please upload a valid file of type: {validFileTypesExceptLast} or {lastValidFileType}.'
  },
  imageNotSquareWarning: {
    id: 'AlertMessages.imageNotSquareWarning',
    defaultMessage: 'We recommend you upload a square image for best results'
  }
})

export const ReadMoreMessage = defineMessages({
  text: {
    id: 'ReadMoreMessage.text',
    defaultMessage: 'Read more'
  }
})

export const ReadLessMessage = defineMessages({
  text: {
    id: 'ReadLessMessage.text',
    defaultMessage: 'Read less'
  }
})

export const Languages = defineMessages({
  english: {
    id: 'Languages.english',
    defaultMessage: 'English'
  },
  spanish: {
    id: 'Languages.spanish',
    defaultMessage: 'Spanish'
  },
  french: {
    id: 'Languages.french',
    defaultMessage: 'French'
  }
})

export const Currencies = defineMessages({
  euro: {
    id: 'currency.euro-eur',
    defaultMessage: 'Euro (EUR)'
  },
  pound: {
    id: 'currency.pound-sterling-gbp',
    defaultMessage: 'Pound Sterling (GBP)'
  },
  dollar: {
    id: 'currency.us-dollar-usd',
    defaultMessage: 'US Dollar (USD)'
  }
})

export const TempUnits = defineMessages({
  celsius: {
    id: 'TempUnits.celsius',
    defaultMessage: 'Celsius (°C)'
  },
  fahrenheit: {
    id: 'TempUnits.fahrenheit',
    defaultMessage: 'Fahrenheit (°F)'
  }
})

export const DistanceUnits = defineMessages({
  metric: {
    id: 'DistanceUntis.metric',
    defaultMessage: 'Square meters (m²)'
  },
  imperial: {
    id: 'DistanceUntis.imperial',
    defaultMessage: 'Square feet (ft²)'
  }
})

export const WeightUnits = defineMessages({
  metric: {
    id: 'WeightUntis.metric',
    defaultMessage: 'Kilograms (kg)'
  },
  imperial: {
    id: 'WeightUntis.imperial',
    defaultMessage: 'Pounds (lb)'
  }
})

export const TonType = defineMessages({
  metric: {
    id: 'TonType.metric',
    defaultMessage: 'Metric Ton (1000 kg)'
  },
  short: {
    id: 'TonType.short',
    defaultMessage: 'Short Ton (2000 lb)'
  },
  long: {
    id: 'TonType.long',
    defaultMessage: 'Long Ton (2240 lb)'
  }
})
