import React from "react";
import PropTypes from 'prop-types';

import { intlShape, injectIntl, FormattedMessage } from 'react-intl';

import { OFFSET_TYPE_CREDIT } from '../../constants/offsetTypes';

import HeadingSmall from '../UI/Text/HeadingSmall';
import Text from '../UI/Text/Text';
import MyContributionsForestItem from '../Specials/MyContributionsForestItem';

import './MySharesBlock.css'

const MySharesBlock = ({myContributions, name}) => {

    const sortForestsByCo2OffsetDescending = forests => forests.sort(
        (a, b) =>  {
          //@Pablo TODO: Remove temporary send VCU projects to the back of the list when new my offsets box is refactored
          if(a.offset_type === OFFSET_TYPE_CREDIT) {
            return 1;
          }
          return a.total_kg_co2_capturable_by_user_shares_in_sector < b.total_kg_co2_capturable_by_user_shares_in_sector ? 1 : -1
        }
    )
    
    return (
        <div className="public-dashboard-new-content-myShares">
          <Text className="my-shares-title">
            <FormattedMessage id={'Dashboard.mySharesTitle'} defaultMessage={'My offsets'} />
          </Text>
          <HeadingSmall>
            <FormattedMessage
              id={'Dashboard.mySharesDescription'}
              defaultMessage={"Find your contributions in the {companyName} projects below"}
              values={{ companyName: name}}
            />
          </HeadingSmall>
          <div className="public-dashboard-new-company-info-separator" />
            {
              sortForestsByCo2OffsetDescending(myContributions).map(
                forest => ( <MyContributionsForestItem forest={forest} key={forest.sector_name}/>)
              )
            }
        </div>
      )
}

MySharesBlock.propTypes = {
    intl: intlShape,
    myContributions: PropTypes.array,
    name: PropTypes.string
}

export default injectIntl(MySharesBlock)
