import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import Loader from '../components/UI/Loader/Loader';
import Eye from '../components/UI/Icons/Eye';
import { EyeLashes } from '@reforestum/shared-components';
import LatestActivityCard from '../components/UI/LatestActivityCard/LatestActivityCard';
import { Button } from '@reforestum/shared-components';

import * as userSessionSelector from '../selectors/userSession';

import '../components/UI/LatestActivityCard/LatestActivityCard.css';

class LatestActivityContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      seeFullEventList: false,
      indexCardShown: 0
    }
  }

  handleSeeFullEventList(){
    this.setState({seeFullEventList: !this.state.seeFullEventList})
  }

  render() {
    const {seeFullEventList, indexCardShown} = this.state;
    const {isCorpProfile, events, latestActivity} = this.props;

    let eventsList;

    if(isCorpProfile && events){
      eventsList = events
    }

    if(latestActivity){
      eventsList = latestActivity.latestActivity;
    }
    
    const { lang } = this.props;

    if(!eventsList){
      return <Loader />
    }

    const renderEventList = (events) => {
      return events.map((card, index) => {
        const openGallery = index => () => this.props.openGallery(card.pics.map(pic => ({URI: pic.url})), index);
        return (
            <LatestActivityCard
              key={index}
              {...card}
              lang={lang}
              openGallery={openGallery}
            />
          )
        })
    }

    return (
      <div className="latest-activity-container">
        {isCorpProfile && !seeFullEventList  && eventsList
          ? renderEventList(eventsList.filter((card, index) => index === indexCardShown))
          : renderEventList(eventsList)
        }

        {eventsList.length > 1 && isCorpProfile
          ? <Button
              onClick={() => this.handleSeeFullEventList()}
              className={cx("latest-activity-see-list__button", !seeFullEventList && "special-dashed-line")}
              >
              <span role="img" aria-label="Eye" className="latest-activity-see-list__icon">
                {!seeFullEventList
                  ? <Eye />
                  : <EyeLashes />
                }
              </span>
              <span>{!seeFullEventList 
                      ? <FormattedMessage 
                          id={'LastestActivities.Seefullstory'}
                          defaultMessage={'See full story'}
                        />
                      : <FormattedMessage 
                          id={'LastestActivities.Seeless'}
                          defaultMessage={'See less'}
                        />
                    }
              </span>
            </Button>
          : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: userSessionSelector.getUserLang(state),
});

LatestActivityContainer.propTypes = {
  latestActivity: PropTypes.object,
  events: PropTypes.array,
  isCorpProfile: PropTypes.bool,
  openGallery: PropTypes.func.isRequired,
  lang: PropTypes.string,
};

export default connect(mapStateToProps, {
})(LatestActivityContainer);
