import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { localizedLinkMessages } from '../../constants/messages';

import stripeImg from '@reforestum/shared-components/dist/assets/images/checkout/stripe.png';
import coinbaseImg from '@reforestum/shared-components/dist/assets/images/checkout/coinbase-logo.svg';
import visaImg from '@reforestum/shared-components/dist/assets/images/checkout/visa.png';
import mastercardImg from '@reforestum/shared-components/dist/assets/images/checkout/mastercard.png';
import americanexpressImg from '@reforestum/shared-components/dist/assets/images/checkout/americanexpress.png';
import googlePayImg from '@reforestum/shared-components/dist/assets/images/checkout/googlepay.png';
import { PAYMENT_GATEWAY } from '../../reducers/checkout';

import Small from '../UI/Text/Small';

import './FinePrint.css';

class FinePrint extends Component {

  render() {
    const { intl, version } = this.props;

    if(version === PAYMENT_GATEWAY.COINBASE){
      return (
        <div className="checkout-fineprint">
          <Small color="light" className="checkout-fineprint__text">

            <FormattedMessage
              id={'CoinbaseForm.aboutCoinbasePayment'}
              defaultMessage={'All crypto payments processed via'}
            />{' '}
            <a
              href="https://coinbase.com/"
              target="_blank"
              rel="noopener noreferrer">
              Coinbase.
            </a>
            <br/>

            <FormattedMessage
              id={'CoinbaseForm.aboutPaymentsAccepted'}
              defaultMessage={"We currently accept payments in Bitcoin, Bitcoin Cash, Ethereum and Litecoin"}
            />
            <br/>


            <FormattedMessage
              id={'FinePrint.moreInfo'}
              defaultMessage={"For more information read our"}
            />{' '}
            <a
              href={intl.formatMessage(localizedLinkMessages.tos)}
              target="_blank"
              rel="noopener noreferrer">
              <FormattedMessage
                id={'FinePrint.allPaymentsProcessed'}
                defaultMessage={"terms of service."}
              />
            </a>
          </Small>
          <div className="checkout-fineprint__logos">
            <object data={coinbaseImg} aria-label="coinbase logo" className="checkout-fineprint__coinbase" type="image/svg+xml"></object>
          </div>
        </div>
      );
    }
    else {
      return (
        <div className="checkout-fineprint">
          <Small color="light" className="checkout-fineprint__text">

            <FormattedMessage
              id={'FinePrint.processedVia'}
              defaultMessage={'All payments processed via'}
            />{' '}
            <a
              href="https://stripe.com/"
              target="_blank"
              rel="noopener noreferrer">
              Stripe.
            </a>
            <br/>

            <FormattedMessage
              id={'FinePrint.noCreditCardStore'}
              defaultMessage={"Reforestum never stores your credit card details."}
            />
            <br/>


            <FormattedMessage
              id={'FinePrint.moreInfo'}
              defaultMessage={"For more information read our"}
            />{' '}
            <a
              href={intl.formatMessage(localizedLinkMessages.tos)}
              target="_blank"
              rel="noopener noreferrer">
              <FormattedMessage
                id={'FinePrint.allPaymentsProcessed'}
                defaultMessage={"terms of service."}
              />
            </a>
          </Small>
          <div className="checkout-fineprint__logos">
            <img
              className="checkout-fineprint__stripe"
              src={stripeImg}
              alt="Powered by stripe"
            />
            <img
              className="checkout-fineprint__card"
              src={visaImg}
              alt="Visa"
            />
            <img
              className="checkout-fineprint__card"
              src={mastercardImg}
              alt="Mastercard"
            />
            <img
              className="checkout-fineprint__card"
              src={americanexpressImg}
              alt="American Express"
            />
            <img
                className="checkout-fineprint__card"
                src={googlePayImg}
                alt="Google Pay"
            />
          </div>
        </div>
      );
    }
  }
}

FinePrint.propTypes = {
  intl: intlShape,
};

export default injectIntl(FinePrint);
