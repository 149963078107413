import { addLocaleData } from 'react-intl';

import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import it from 'react-intl/locale-data/it';
import fr from 'react-intl/locale-data/fr';

import localeEn from '../i18n/en';
import localeEs from '../i18n/es';
import localeFr from '../i18n/fr';

export const DEFAULT_LOCALE = 'en'

const locales = {
  en: localeEn,
  es: localeEs,
  fr: localeFr,
};

addLocaleData([...en, ...es, ...it,  ...fr]);

export const isLocaleSupported = (locale) => locales[locale] != null

export const messages = (locale) => {

  return locales[locale]
};

const getLocaleWithoutRegionCode = (locale) => locale.toLowerCase().split(/[_-]+/)[0]

export const getMessagesLocale = (locale) => {

  const localeWithoutRegionCode = getLocaleWithoutRegionCode(locale)

  if (isLocaleSupported(locale))
    return locale
  else if (isLocaleSupported(localeWithoutRegionCode))
    return localeWithoutRegionCode
  else return DEFAULT_LOCALE
}
