import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Container from '../components/Layout/Container/Container';
import Content from '../components/Layout/Container/Content';
import { FormattedMessage } from 'react-intl';
import './StripePaymentError.css';

export default class StripePaymentError extends Component {

  render() {

    return (
      <Container>
        <Content className="stripe-payment-finished-container">
          <div className="stripe-payment-finished-title-error">
            <FormattedMessage
              id={'StripePaymentsFinished-error-title'}
              defaultMessage={'Oops'}
            />
          </div>
          <div className="stripe-payment-finished-subtitle">
            <FormattedMessage
              id={'StripePaymentsFinished-error-subtitle'}
              defaultMessage={'Your payment was not completed'}
            />
          </div>
          <div className="stripe-payment-finished-info">
            <FormattedMessage
              id={'StripePaymentsFinished-error-info'}
              defaultMessage={'If you feel that you are seeing this information as a result of an error, please do get in touch with the team so that we can resolve any doubts you may have'}
            />
          </div>
        </Content>
      </Container>
    );
  }
}

StripePaymentError.propTypes = {
  status: PropTypes.string,
};