import React from 'react';
import { Helmet } from 'react-helmet';

import Wrapper from '../components/Layout/Wrapper/Wrapper';
import MobileTopbar from "../components/Header/MobileTopbar/MobileTopbar"
import MarketInsightsContainer from '../containers/MarketInsightsContainer';

const MarketInsights = () => {
  return (
    <Wrapper>
        <Helmet>
            <title>
            Market Insights | Reforestum
            </title>
            <link rel="canonical" href={`https://app.reforestum.com/market-insights}`} />
            <meta name="description" content="Discover the most relevant insights of the Voluntary Carbon Markets"/>
        </Helmet>
        <MobileTopbar>
        </MobileTopbar>
        <MarketInsightsContainer/>
    </Wrapper>
  );
};

export default MarketInsights;
