import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'react-layout-components';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import getCurrencySymbol from '../../../utils/getCurrencySymbol';

import { filterMessages } from '../../../constants/messages';
import Tabs from './Tabs';
import FilterInput from './FilterInput';
import CarbonSources from './CarbonSources';
import OrangePlus from '../../UI/Icons/OrangePlus';
import IconText from '../../UI/Text/IconText';
import Text from '../../UI/Text/Text';

import { getUnit, getFormattedWeight, formatUnit } from '../../../utils/units';

import './Filters.css';
import { links } from '@reforestum/shared-utils';

const Filters = ({
  mode,
  handleChange,
  handleSelect,
  values,
  sources,
  selectedSources,
  intl,
  currency,
}) => (
  <Box column className="create-forest-filters">
    <Box column className="create-forest-filters__inner">

      <Tabs />

      { mode === 'budget' &&
        <FilterInput
          label={intl.formatMessage(filterMessages.budgetLabel)}
          value={values.budget}
          onChange={e => { handleChange(e.target.value, 'budget'); }}
          unit={getCurrencySymbol(currency)}
        />
      }
      { mode === 'area' &&
        <FilterInput
          label={intl.formatMessage(filterMessages.areaLabel)}
          value={values.area}
          onChange={e => { handleChange(e.target.value, 'area'); }}
          unit={getUnit('m', true)}
        />
      }
      { mode === 'carbon' &&
        <div>
          <FilterInput
            disabled
            label={intl.formatMessage(filterMessages.co2Label)}
            value={getFormattedWeight(values.carbon).value}
            onChange={e => { handleChange(e.target.value, 'carbon'); }}
            unit={formatUnit(intl, getFormattedWeight(values.carbon).unit)}
          />
          <CarbonSources
            sources={sources}
            handleSelect={handleSelect}
            selectedSources={selectedSources}
          />
          <div className="create-forest-sources__bottom">
            <Link to={links.getCalculatorUrl()}>
              <IconText icon={OrangePlus}>
                  <Text color="orange">
                    <FormattedMessage
                      id={'CarbonSources.addMore'}
                      defaultMessage={'Add CO₂ source'}
                    />
                  </Text>
              </IconText>
            </Link>
          </div>
        </div>
      }

    </Box>
  </Box>
);

Filters.propTypes = {
  mode: PropTypes.oneOf(['carbon', 'budget', 'area']).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  intl: intlShape,
  sources: PropTypes.array.isRequired,
  values: PropTypes.shape({
    area: PropTypes.number,
    budget: PropTypes.number,
    carbon: PropTypes.number,
  }).isRequired,
  selectedSources: PropTypes.array.isRequired,
  currency: PropTypes.string.isRequired,
};

export default injectIntl(Filters);
