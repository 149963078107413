import api from "../../constants/api"
import {getUserRequestHeaders} from "./getUserRequestHeaders"

export const queryBeZero = async projectId => {
	let result = null
	try {
		result = await fetch(
			`${api.RATING(projectId)}`,
			{headers: getUserRequestHeaders()}
		)
		result = await result.json()
	}
	catch(e) {
		console.warn('Error fetching bezero data', e)
	}
	return result
}

export const queryBeZeroRatingOnly = async projectId => {
	let result = null
	try {
		result = await fetch(
			`${api.RATING_ONLY(projectId)}`,
			{headers: getUserRequestHeaders()}
		)
		result = await result.json()
	}
	catch(e) {
		console.warn('Error fetching bezero data', e)
	}
	return result
}
