import actionTypes from '../constants/actionTypes';
import getUserPreferences from '../utils/getUserPreferences';

const initialState = {
  isAuthenticated: false,
  details: {
    preferences: getUserPreferences(),
  },
  authMethod: null,
  token: null,
  mixpanelId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGNUP_COMPLETE:
    case actionTypes.AUTH_COMPLETE:
      return {
        ...state,
        isAuthenticated: true,
        authMethod: action.payload.authMethod,
        details: {
          ...action.payload.user,
          organizations: action.payload.organizations
        },
        token: action.payload["access-token"],
      };
    case actionTypes.GUEST_COMPLETE:
      return {
        ...state,
        isAuthenticated: false,
        token: action.payload["access-token"]
      };
    case actionTypes.GET_PROFILE_COMPLETE:
      return {
        ...state,
        details: action.payload.data,
      };
    case actionTypes.UPLOAD_COMPLETE:
      return {
        ...state,
        details: action.payload.user
      };
    case actionTypes.AUTH_LOGOUT:
    case actionTypes.AUTH_EXPIRED:
      return initialState;
    case actionTypes.SET_MIXPANEL_ID:
      return {
        ...state,
        mixpanelId: action.payload,
      };
    case actionTypes.CHANGE_LANGUAGE:
      return {
        ...state,
        details: {
          ...state.details,
          preferences: {
            ...state.details.preferences,
            lang: action.payload,
          }
        }
      }
    default:
      return state;
  }
};

export const getIsUserAuthenticated = state => state.isAuthenticated;
export const getUserDetails = state => state.details;

export const getToken = state => state.token;
export const getUserLang = state => (state.details.preferences || {}).lang;

export const getUseMetricDistance = state => (state.details.preferences || {}).metric_distance;
export const getUseMetricWeight = state => (state.details.preferences || {}).metric_weight;
export const getUseCelsiusForTemp = state => (state.details.preferences || {}).temp_celsius;
export const getCurrency = state => (state.details.preferences || {}).currency;
export const getBigWeight = state => (state.details.preferences || {}).big_weight;
export const getAuthMethod = state => state.authMethod;
