import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'react-layout-components';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import FadeProps from 'fade-props';
import mixpanel from "mixpanel-browser";
import {loaderRedirectMessages } from '../../../constants/messages'

import './Loader.css';
import Cluster from '@reforestum/shared-components/dist/assets/images/cluster.png';
import getIsAutheticatedFromStore from '../../../utils/getIsAutheticatedFromStore';
import { links } from '@reforestum/shared-utils';

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: 'Loader.suggestion.1',
    };

    this.timer = this.timer.bind(this);
  }

  componentDidMount() {
    this.intervalId = setInterval(this.timer, 2500);
    this.trackReferrerVisits();
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  trackReferrerVisits() {
    if (!this.props.location || !this.props.location.pathname || !this.props.location.search) return
    if (this.props.location.pathname === links.getCreateForestUrl('redirect')) {
      const URLKeysAndValues = {}
      this.props.location.search.slice(1).split('&').forEach(
        p => URLKeysAndValues[p.split('=')[0]] = p.split('=')[1]
      )
      if(URLKeysAndValues.utm_referral_id){
        mixpanel.track("Page view", {
          "Authenticated": getIsAutheticatedFromStore(),
          "Action": "Create forest",
          "Domain": "App",
          "Redirection": "1",
          ...URLKeysAndValues
        });
      }
    }
  }

  timer() {
    let message = this.state.message;
    message = message.split('.');
    message[2] = parseInt(message[2], 10) % 4;
    message[2] = parseInt(message[2], 10) + 1;
    message = message.join('.');
    this.setState({ message: message});
  }

  render() {
    const { intl } = this.props
    return (
      <Box fit center style={{ marginTop: '-40px' }}>
        <Box column center className="loader">
          <div>
            <img className="map-tree-loader__image" alt="cluster" src={Cluster}/>
            <img className="map-tree-loader__image" alt="cluster" src={Cluster}/>
            <img className="map-tree-loader__image" alt="cluster" src={Cluster}/>
          </div>
          <h4 className="loader_header_text">We are redirecting you...</h4>
          <div className="loader_redirect_holder">
            <div className="loader_redirect_state"/>
          </div>
          <h5 className="loader_footer_text">
            <FadeProps>
              <FormattedMessage
                {...intl.formattedMessage(loaderRedirectMessages[this.state.message])}
              />
            </FadeProps>
          </h5>
        </Box>
      </Box>
    );
  }
}

Loader.propTypes = {
  location: PropTypes.string,
  intl: intlShape
}

export default injectIntl (Loader);
