import React, { Component } from "react"
import {queryBeZeroRatingOnly} from "../../Utils/queryBeZero"
import Loader from "../../UI/Loader/Loader"
import './BeZero.css'
import '../../../components/Widgets/ForestWidgets/Widget.css'
import BeZeroImage from './BeZero.png'
import {FormattedMessage, injectIntl} from "react-intl"
import {BeZero as BeZeroTexts} from "../../../constants/messages"
import {Link, withRouter} from 'react-router-dom'
import {getProjectUrl} from '@reforestum/shared-utils/src/links'
import ArrowRightActive from '../../UI/Icons/ArrowRightActive'
import ArrowRight from '../../UI/Icons/ArrowRight'
import Information from '../../UI/Icons/Information'
import Tooltip from '../../UI/Tooltip/Tooltip'

class BeZeroRatingOnly extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loadingBeZeroInfo: false,
			beZeroData: null,
			documentWidth: window.innerWidth,
			buttonHovered: false
		}
	}

	async getBeZeroInfoForProject() {
		this.setState({ loadingBeZeroInfo: true })
		const { result } = await queryBeZeroRatingOnly(this.props.projectId)
		if (result) {
			this.setState({ beZeroData: result })
		}
		this.setState({ loadingBeZeroInfo: false })
	}

	componentDidMount() {
		if (this.props.projectId) {
			window.addEventListener('resize', () => this.updateDocumentWidth())
			this.getBeZeroInfoForProject()
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.projectId && prevProps.projectId !== this.props.projectId) {
			this.getBeZeroInfoForProject()
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', () => this.updateDocumentWidth())
	}

	updateDocumentWidth() {
		this.setState({ documentWidth: window.innerWidth })
	}

	renderLoadedBeZeroData() {
		const {beZeroData, buttonHovered} = this.state
		const {intl, projectId, renderedFromProjectDetails, match} = this.props
		return <div>
			<div className={'widget-title'}>
				{intl.formatMessage(BeZeroTexts.beZeroRatingOnlyWidgetTitle)}
			</div>
			<div className={'be-zero__image-and-heading'}>
				<img alt={'BeZero logo'} className={'be-zero__image-and-heading__image'} src={BeZeroImage} />
				<h1 className={'widget-text'}>{intl.formatMessage(BeZeroTexts.beZeroCarbonRating)}</h1>
				<Tooltip
					style={{marginLeft: '4px'}}
					size='big'
					content={
						<FormattedMessage
							id={'BeZeroWidget.tooltip'}
							defaultMessage={`Reforestum has partnered with BeZero to bring you BeZero's independently evaluated rating for this project.`}
						/>
					}
				>
					<Information/>
				</Tooltip>
			</div>
			<div className={'be-zero__project__rating'}>{beZeroData.rating}{beZeroData.beZeroIsOnWatch ? <span className={'be-zero__on-watch'}>(rw)</span> : ''}</div>
			<div className={'be-zero__project__name-and-id'}>{beZeroData.projectId}</div>
			<Link
				to={renderedFromProjectDetails ?
					`${match.path.slice(0, match.path.lastIndexOf('/:'))}/rating` :
					`${getProjectUrl(projectId)}/rating`
				}
				className='widget-button'
				onMouseEnter={() => this.setState({buttonHovered: true})}
				onMouseLeave={() => this.setState({buttonHovered: false})}
			>
				<FormattedMessage id="BeZeroWidget.button" defaultMessage="See risk factors"/>{buttonHovered ? <ArrowRightActive  classname='widget-button-arrow'/> : <ArrowRight classname='widget-button-arrow'/>}
			</Link>
		</div>
	}

	renderMain() {
		const {beZeroData} = this.state
		return (
			<div className={'be-zero be-zero__rating-only'}>
				{
					(beZeroData && beZeroData.rating)
					? this.renderLoadedBeZeroData()
					: <Loader />
				}
			</div>
		)
	}

	render() {
		return this.state.loadingBeZeroInfo ? (
			<Loader />
		) : this.props.projectId ? (
			this.renderMain()
		) : null
	}
}

export default withRouter(injectIntl(BeZeroRatingOnly))
