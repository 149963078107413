// Dependencies
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { intlShape, injectIntl, FormattedMessage } from 'react-intl'
import { TabList, Tab, TabPanel } from 'react-tabs'
import { Box } from 'react-layout-components'
import {ShareButtons, generateShareIcon} from 'react-share'

// Utils
import { getFormattedWeight, formatUnit } from '../../utils/units'
import { links } from '@reforestum/shared-utils'
import store from '../../setup/store'

// Constants
import getFeatureFlags from '../../constants/featureFlags'
import {
  MyForestSummary as MyForestSummaryTexts,
  newsletterPopup,
  transactionsInProjectSection
} from '../../constants/messages'
import {PUBLIC_DASHBOARD_SECTION_IDS} from '../../constants/publicProfile'

// Components
import CompanyInfoSection from './CompanyInfoSection'
import RelatedCompanies from './RelatedCompanies'
import CarbonBalanceNew from '../Widgets/CarbonBalanceNew/CarbonBalanceNew'
import MySharesBlock from './MySharesBlock'
import MyForestSummary from '../Widgets/MyForestSummary/MyForestSummary'
import CommunityContainer from './CommunityContainer'
import PublicDashboardNavigation from './PublicDashboardNavigation'
import AvatarPlaceholder from '@reforestum/shared-components/dist/assets/images/avatar-placeholder.png'

// UI components / Icons
import Car from '../UI/Icons/Car'
import Information from '../UI/Icons/Information'
import Tooltip from '../UI/Tooltip/Tooltip'
import HeadingSmall from '../UI/Text/HeadingSmall'
import Token from '../UI/Text/Token'
import RoutedTabbed from '../UI/Tabs/RoutedTabbed'

// Styles
import './PublicDashboard.css'
import cx from 'classnames'
import {Button, ButtonLink} from "@reforestum/shared-components"
import Text from "../UI/Text/Text"
import Modal from "../UI/Modal/Modal"
import CommunityPatronsPodium from "./CommunityPatronsPodium"
import {withRouter} from "react-router"
import {addAlert} from "../../actions/alerts"
import ClaimOrganization from "../AccountSettings/OrganizationSettings/OrganizationInformation/ClaimOrganization"
import RoundedWhitePlus from '../UI/Icons/RoundedWhitePlus'
import { getPublicProfileUrl } from '@reforestum/shared-utils/src/links'
import EditProfileIcon from '../UI/Icons/EditProfileIcon'
import StandardFormModal from "../UI/Modal/StandardFormModal"
import {getUserOrImpersonatingOrg} from "../../utils/usersAndOrganizations"
import {getUserDetails} from "../../selectors/userSession"
import {connect} from "react-redux"
import Tag from '../UI/Tags/Tag'
import BetaTooltip from '../UI/Tabs/BetaTooltip'
import CopyToClipboardIcon from "../UI/Icons/CopyToClipboardIcon"
import GlobeSimple from "../UI/Icons/GlobeSimple"
import CarbonCredits from "../UI/Icons/CarbonCredits"
import { ORGANIZATION_INSIGHTS_METABASE_DASHBOARD } from '../../constants/metabaseDashboards';
import IframeResizable from '../UI/IframeResizable/IframeResizable';
import SUBSCRIPTIONS from '../../constants/subscriptions'
import PaywallBanner from '../Widgets/PaywallBanner/PaywallBanner'

const {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
} = ShareButtons

const FacebookIcon = generateShareIcon('facebook')
const TwitterIcon = generateShareIcon('twitter')
const WhatsappIcon = generateShareIcon('whatsapp')
const LinkedinIcon = generateShareIcon('linkedin')
const RedditIcon = generateShareIcon('reddit')

const ff = getFeatureFlags()

const ONE_COLUMN_UI_MAX_WIDTH = 900

const DAILY_CAR_C02 = 4598 /*KG of CO2 emitted by a car in a year*/ / 365

class PublicDashboard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showClaimOrganizationModal: false,
      showOnboardYourCompanyModal: false,
      showSharingModal: false,
      showRequestThatCompanyOnboardModal: false,
      showAddNewProjectModal: false,
      showNewsletterModal: false,
      isOneColumn: window.innerWidth <= ONE_COLUMN_UI_MAX_WIDTH
    }

    this.setIsOneColumn = this.setIsOneColumn.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.setIsOneColumn)
    if(this.props.unOnboardedCompanyMode && (this.props.user && this.props.user.guest === undefined)) {
      this.newsletterTimeout = setTimeout(() => this.handleNewsletterPopup(), 30000)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setIsOneColumn)
    clearTimeout(this.newsletterTimeout)
  }

  setIsOneColumn() {
    const isOneColumn = window.innerWidth <= ONE_COLUMN_UI_MAX_WIDTH
    if(isOneColumn !== this.state.isOneColumn) this.setState({isOneColumn: isOneColumn})
  }

  render() {
    const {
      unOnboardedCompanyMode,
      children
    } = this.props

    return (
      <div
        id='public-dashboard-container'
        className={`public-dashboard-new-container ${unOnboardedCompanyMode ? 'public-dashboard-new-container__unonboarded-company-mod' : ''}`}
      >
        {this.renderAppropriateModals()}
        <div className={`public-dashboard-new-content ${unOnboardedCompanyMode ? 'public-dashboard-new-content__unonboarded-company-mod' : ''}`}>
          {this.renderLeftSection()}
          {this.renderRightSection()}
        </div>
        {this.shouldShowSimilarCompanies() && this.renderAfterMainContentSection()}
        {unOnboardedCompanyMode && this.renderUnonboardedCompanyEndPageCTA()}
        {children}
      </div>
    )
  }

  // ** Handlers **
  handleNewsletterPopup() {
    const hasSeenNewsletterPopup = localStorage.getItem('ref__hasSeenNewsletterPopup')
    if(!hasSeenNewsletterPopup) {
      this.setState({showNewsletterModal: true})
      localStorage.setItem('ref__hasSeenNewsletterPopup', true)
    }
  }

  handleShowOnboardYourCompanyModal() {
    this.updateRoute('get-started')
    this.setState({showOnboardYourCompanyModal: !this.state.showOnboardYourCompanyModal})
  }

  handleShowRequestThatCompanyOnboardModal() {
    this.updateRoute('request-action')
    this.setState({showRequestThatCompanyOnboardModal: !this.state.showRequestThatCompanyOnboardModal})
  }

  // ** Helpers **
  shouldShowCommunity() {
    const {
      profile,
      organizationPrivateDashboardMode,
    } = this.props

    const {
      community_size,
    } = profile

    return Boolean(ff.communityInCorporateProfile && profile && community_size) && !organizationPrivateDashboardMode
  }

  shouldShowSimilarCompanies() {
    return this.props.unOnboardedCompanyMode
  }

  copyLinkTextToClipboard(link) {
    const {intl} = this.props
    this.setState({showSharingModal: false})
    navigator.clipboard.writeText(link)
    store.dispatch(addAlert({
      type: 'success',
      message: intl.formatMessage(MyForestSummaryTexts.textCopiedToClipboard),
      dismissAfter: 6000
    }))
  }

  toggleSharingModal() {
    this.setState({showSharingModal: !this.state.showSharingModal})
  }

  updateRoute(param) {
    const currentLocation = this.props.history.location.pathname
    if(currentLocation.indexOf(param) >= 0) {
      this.props.history.push(`${currentLocation.split(`/${param}`)[0]}`)
    }
    else {
      this.props.history.push(`${currentLocation}/${param}`)
    }
  }

  scrollToPageTop(timeout=200) {
    setTimeout(() => {
      const element = document.getElementById('public-dashboard-container')
      if(element) {
        element.scrollTo({top: 0, behavior: 'smooth'})
      }
    }, timeout)
  }

  // ** Getters **
  getMyContributionsArray() {
    const {
      myContributions
    } = this.props
    return myContributions && Array.isArray(myContributions) ? myContributions : null
  }

  getCompanyNickname() {
    const {profile} = this.props
    const {name} = profile
    return profile.nickname ? profile.nickname : name
  }

  getNavSections() {
    const {intl} = this.props
    let result = [
      {
        id: PUBLIC_DASHBOARD_SECTION_IDS.PROJECTS_AND_COMMUNITY,
        title: intl.formatMessage(MyForestSummaryTexts.jumpToSectionProjectsAndCommunity),
        icon: <CarbonCredits width={24} height={24}/>
      },
    ]

    if (this.shouldShowSimilarCompanies()) {
      result.push({
        id: PUBLIC_DASHBOARD_SECTION_IDS.SIMILAR_COMPANIES,
        title: intl.formatMessage(MyForestSummaryTexts.jumpToSectionSimilarCompanies),
        icon: <GlobeSimple/>
      })
    }

    return result
  }

  getCommunityProjectAndSectorIds() {
    const {projects} = this.props
    let communityProjectIds = []
    let communitySectorsIds = []
    projects && projects.forEach(project => {
      // Don't take into account in community
      // projects that are not owned by the company
      // (e.g. comunnity forests compensations)
      if (!project.org_is_owner) {
        return
      }

      communityProjectIds.push(project.forest_id)
      communitySectorsIds.push(project.sector_id)
    })
    return {communityProjectIds, communitySectorsIds}
  }

  getTabInformation() {
    const showCommunity = this.shouldShowCommunity()
    const {unOnboardedCompanyMode} = this.props

    const tabs = {
      PROJECTS: 'projects'
    }

    // PATHS FOR TABS
    const paths = [
      tabs.PROJECTS
    ]

    if (ff.corporateProfileAnalytics && unOnboardedCompanyMode) {
      tabs.ANALYTICS = 'analytics'
      paths.push(tabs.ANALYTICS)
    }

    if (showCommunity) {
      tabs.COMMUNITY = 'community'
      paths.push(tabs.COMMUNITY)
    }

    const currentTab = window.location.href.split("/").pop()

    const isCurrentTab = tab => {
      return tab === currentTab
    }

    return {tabs, paths, currentTab, isCurrentTab}
  }

  // ** Renderers **
  renderAnalyticsDashboard() {
    const {publicProfileSlug, user} = this.props
    const {subscriptions} = getUserOrImpersonatingOrg(user)
    const isSubscribedToAnalytics = subscriptions && subscriptions[SUBSCRIPTIONS.GENERIC]
    const id =`metabase-organization-analytics-${publicProfileSlug}`
    return <div className={`public-dashboard-analytics-tab-container`}>
      <IframeResizable
        id={id}
        title="Organization Analytics Dashboard"
        className='public-dashboard-analytics-iframe'
        src={`${ORGANIZATION_INSIGHTS_METABASE_DASHBOARD}?profile=${publicProfileSlug}#bordered=false&titled=false&hide_parameters=profile,retirement_period`}
      />
      {!isSubscribedToAnalytics && <div className="public-dashboard-analytics-tab-container__paywall-blur-overlay" />}
      {!isSubscribedToAnalytics && (
        <PaywallBanner 
          title={<FormattedMessage  id="publicDashboard.analytics.paywallBanner.title" defaultMessage="Analyze the behaviour of the organization in the carbon market" />}
          description={<FormattedMessage  id="publicDashboard.analytics.paywallBanner.description" defaultMessage="Get access to the organization's analytics and see how it behaves in the carbon markets" />}
          buttonText={<FormattedMessage  id="publicDashboard.analytics.paywallBanner.buttonText" defaultMessage="Get Access"/>}
          buttonLink={'https://reforestum.com/contact-us'}
          className={'public-dashboard-analytics-tab-container__paywall-banner'}
        />
      )}
    </div>
  }

  renderLeftSection() {
    const companyNickname = this.getCompanyNickname()
    const myContributionsArray = this.getMyContributionsArray()

    const {
      profile,
      unOnboardedCompanyMode,
      organizationPrivateDashboardMode,
    } = this.props

    const {
      avatar_url,
      name,
      bio,
      year_foundation,
      website,
      environmental_statement
    } = profile

    const {isOneColumn} = this.state

    return <div className="public-dashboard-new-content-left__wrapper">
      {organizationPrivateDashboardMode && isOneColumn && this.renderOrganizationDashboardWelcomeSnack()}
      <CompanyInfoSection
        className="public-dashboard-new-content-left"
        name={name}
        avatar_url={avatar_url}
        bio={bio}
        foundation_year={year_foundation}
        website={website}
        environmental_statement={environmental_statement}
        companyNickname={companyNickname}
        unOnboardedCompanyMode={unOnboardedCompanyMode}
        organizationPrivateDashboardMode={organizationPrivateDashboardMode}
        toggleSharingModal={() => this.toggleSharingModal()}
        handleShowOnboardYourCompanyModal={() => this.handleShowOnboardYourCompanyModal()}
      />
      {ff.mySharesInCorporateProfile && myContributionsArray.length > 0 &&
        <MySharesBlock myContributions={myContributionsArray} name={name}/>}
      {
        (ff.publicDashboardNavigation && this.getNavSections() && this.getNavSections().length > 1) &&
        <PublicDashboardNavigation
          sectionIdsAndTitles={this.getNavSections()}
        />
      }
    </div>
  }

  renderRightSection() {
    const {
      organizationPrivateDashboardMode,
    } = this.props

    const {isOneColumn} = this.state

    return <div className="public-dashboard-new-content-right">

      {
        organizationPrivateDashboardMode
          ? null
          : this.renderPublicProfileTabs()
      }

      {organizationPrivateDashboardMode && !isOneColumn && this.renderOrganizationDashboardWelcomeSnack()}
      {
        organizationPrivateDashboardMode
          ? this.renderOrganizationPrivateDashboardPortfolio()
          : null
      }
    </div>
  }

  renderCompensationSummaryPanel() {
    const {
      intl,
      captured,
      current_c02_captured,
      future_c02_captured,
      total_vcus,
      miterd_credits,
      profile,
      has_community,
      unOnboardedCompanyMode,
      organizationPrivateDashboardMode,
      publicProfileSlug
    } = this.props

    const {
      name,
      has_unverified_transactions,
    } = profile

    const formattedCapturedCarbon = getFormattedWeight(captured)

    return <div className="public-dashboard-new-company-compensation-container">
      <div className="public-dashboard-new-company-compensation-summaray">
        <div>
          <p className="public-dashboard-new-company-compensation-label">

            {unOnboardedCompanyMode ?
              <span>{intl.formatMessage(MyForestSummaryTexts.unOnboardedCompanyModeEstimated)}&nbsp;</span> : ''}
            {has_community
              ? <FormattedMessage id={'Dashboard.balanceTitleWithCommunity'}
                                  defaultMessage={'{value} community is offsetting'} values={{value: name}}/>
              : <FormattedMessage id={'Dashboard.balanceTitle'} defaultMessage={'{value} IS OFFSETTING'}
                                  values={{value: name}}/>
            }
          </p>
          <div className="public-dashboard-new-company-compensation-compensed-container">
            <div className="public-dashboard-new-company-compensation-compensed-value-container">
              <p className={
                `public-dashboard-new-company-compensation-compensed-value 
                          ${(formattedCapturedCarbon.value === 0) && 'public-dashboard-new-company-compensation-compensed-value-not-available'}`
              }
              >
                {formattedCapturedCarbon.value > 0 ?
                  `${intl.formatNumber(formattedCapturedCarbon.value)} ${formatUnit(intl, formattedCapturedCarbon.unit)} ${intl.formatMessage(MyForestSummaryTexts.co2UnitUpdated)}`
                  :
                  <FormattedMessage id={'Dashboard.balanceZero'} defaultMessage={'Information not available'}/>
                }
              </p>
              {formattedCapturedCarbon.value > 0 && <Tooltip
                position="bottom-left"
                content={
                  <FormattedMessage
                    id={'CarbonBalance.capturedCarbonTooltip'}
                    defaultMessage={'Total amount of CO₂ offset in Reforestum forestry projects.'}
                  />
                }
              >
                <Information/>
              </Tooltip>}
            </div>
            {has_unverified_transactions && (
              <Tag
                className="public-dashboard-new-company-captured-carbon-not-verified-tag"
                tooltipPosition="bottom-left"
                text={
                  <FormattedMessage
                    id={'CarbonBalance.notVerifiedTag'}
                    defaultMessage={'UNVERIFIED TRANSACTIONS'}
                  />

                }
                tooltip={
                  <FormattedMessage
                    id={'CarbonBalance.notVerifiedTagTooltip'}
                    defaultMessage={'Not verified tag tooltip'}
                  />

                }
              />
            )}
          </div>
        </div>
        {organizationPrivateDashboardMode &&
          (publicProfileSlug ? (
              <ButtonLink target="_blank" to={getPublicProfileUrl(publicProfileSlug)}>
                <FormattedMessage
                  id={'OrganizationPrivateDashboard.seeYourPublicProfileCTA'}
                  defaultMessage={'See your public profile'}
                />
              </ButtonLink>) : (
              <ButtonLink target="_blank" to={'/account/organization-information'}>
                <FormattedMessage
                  id={'OrganizationPrivateDashboard.activateYourPublicProfileCTA'}
                  defaultMessage={'Activate your public profile'}
                />
              </ButtonLink>)
          )
        }
      </div>
      {<CarbonBalanceNew
        showBar={true}
        vcus={total_vcus}
        miterdCredits={miterd_credits}
        currentAfforestation={current_c02_captured}
        futureAfforestation={future_c02_captured}
      />}
      {!organizationPrivateDashboardMode && <div
        className="public-dashboard-new-information-message-box public-dashboard-new-company-compensation-equivalent-container">
        <Car className="public-dashboard-new-information-message-box-icon"/>
        <p className="public-dashboard-new-information-message-box-text">
          <FormattedMessage
            id={'Dashboard.compensedEquivalent'}
            defaultMessage={'That\'s the equivalent of {value} cars taken off the road for a day!'}
            values={{value: intl.formatNumber(Number(captured / DAILY_CAR_C02).toFixed(0))}}
          />
        </p>
      </div>}
    </div>
  }

  renderAppropriateModals() {
    const {intl} = this.props
    return <span>
      {
        this.state.showClaimOrganizationModal &&
        <ClaimOrganization
          addAlert={(alertObject) => store.dispatch(addAlert(alertObject))}
          orgId={this.props.profile.org_id}
          showClaimOrganizationModal={this.state.showClaimOrganizationModal}
          setShowClaimOrganizationModal={(value) => this.setState({showClaimOrganizationModal: value})}
        />
      }
      {this.state.showOnboardYourCompanyModal && this.renderOnboardYourCompanyModal()}
      {this.state.showSharingModal && this.renderSharingModal()}
      {this.state.showRequestThatCompanyOnboardModal && this.renderRequestThatCompanyOnboardModal()}
      {
        this.state.showAddNewProjectModal && <StandardFormModal
          hideFirstName
          hideLastName
          action={'add-organization-project'}
          intl={intl}
          heading={<span>{intl.formatMessage(transactionsInProjectSection.genericContactFormHeading)}</span>}
          description={<span>{intl.formatMessage(transactionsInProjectSection.genericContactFormInstructions)}</span>}
          handleClose={() => this.setState({showAddNewProjectModal: false})}
          submitCallback={() => this.setState({showAddNewProjectModal: false})}
          additionalContext={{partyId: getUserOrImpersonatingOrg(this.props.user.id)}}
          successAlertSettings={{
            type: 'success',
            message: this.props.intl.formatMessage(transactionsInProjectSection.genericSuccessMessage),
            dismissAfter: 8000
          }}
        />
      }
      {
        this.state.showNewsletterModal && <StandardFormModal
          action={'request-newsletter-subscription'}
          displayKnownEmail
          hideFirstName
          hideLastName
          hideTextArea
          intl={intl}
          heading={<span>{intl.formatMessage(newsletterPopup.newsletterPopupHeading)}</span>}
          description={<span>{intl.formatMessage(newsletterPopup.newsletterPopupDescription)}</span>}
          cta={<span>{intl.formatMessage(newsletterPopup.newsletterPopupCTA)}</span>}
          handleClose={() => this.setState({showNewsletterModal: false})}
          submitCallback={() => this.setState({showNewsletterModal: false})}
          successAlertSettings={{
            type: 'success',
            message: this.props.intl.formatMessage(newsletterPopup.newsletterPopupSubscriptionSuccess),
            dismissAfter: 8000
          }}
        />
      }
    </span>
  }

  renderBetaBannerSnack() {
    return <div className="public-dashboard-new-information-message-box public-dashboard-new-information-message-box--beta">
      <BetaTooltip/>
      <div>
        <p className="public-dashboard-new-information-message-box-text--bold">
          <FormattedMessage
            id={'OrganizationPrivateDashboard.betaDashboardTitle'}
            defaultMessage={'This dashboard has unverified transactions'}
          />
        </p>
        <p className="public-dashboard-new-information-message-box-text">
          <FormattedMessage
            id={'OrganizationPrivateDashboard.betaDashboardText'}
            defaultMessage={'The information shown in this dashboard may not be 100% accurate as some autoimported transactions can be wrong and not belong to this organization.'}
          />
        </p>
      </div>
    </div>
  }

  renderOrganizationDashboardWelcomeSnack() {
    const companyNickname = this.getCompanyNickname()
    return <div
      className="public-dashboard-new-information-message-box public-dashboard-new-information-message-box--welcome">
      <EditProfileIcon className="public-dashboard-new-information-message-box-icon"/>
      <div>
        <p className="public-dashboard-new-information-message-box-text--bold">
          <FormattedMessage
            id={'OrganizationPrivateDashboard.welcomeToDashboardTitle'}
            defaultMessage={'Welcome to {companyName} dashboard!'}
            values={{companyName: companyNickname}}
          />
        </p>
        <p className="public-dashboard-new-information-message-box-text">
          <FormattedMessage
            id={'OrganizationPrivateDashboard.welcomeToDashboardText'}
            defaultMessage={'Here you can manage your portfolio of projects and your public profile information.'}
          />
        </p>
      </div>
    </div>
  }

  renderPublicProfileTabs() {
    const {projects} = this.props

    const showCommunity = this.shouldShowCommunity()

    const {tabs, paths, isCurrentTab} = this.getTabInformation()

    return (
      projects && projects.length > 0
        ?
        (<RoutedTabbed paths={paths} theme="public-profile">
            <TabList id={"test"} className="public-dashboard-new-forests-tab-list">
              <div className="public-dashboard-new-forests-list-title">
                <Tab>
                  <div className="public-dashboard-new-forests-list-title__wrapper">
                    <HeadingSmall className={
                      cx(
                        'public-dashboard-new-forests-list-title__heading',
                        {'public-dashboard-new-forests-list-title__heading--innactive': !isCurrentTab(tabs.PROJECTS)}
                      )}
                    >
                      <FormattedMessage
                        id={'Dashboard.projectsListPublicProfile'}
                        defaultMessage={'Projects'}
                      />
                    </HeadingSmall>
                    <Token
                      content={projects.length.toString()}
                      color={isCurrentTab(tabs.PROJECTS) ? 'green' : 'inactive'}
                    />
                  </div>
                </Tab>
                {
                  tabs.ANALYTICS
                    ? <Tab>
                        <div className="public-dashboard-new-forests-list-title__wrapper">
                          <HeadingSmall className={
                            cx(
                              'public-dashboard-new-forests-list-title__heading',
                              {'public-dashboard-new-forests-list-title__heading--innactive': !isCurrentTab(tabs.ANALYTICS)}
                            )}
                          >
                            <FormattedMessage
                              id={'Dashboard.tabsAnalyticsTitle'}
                              defaultMessage={'Analytics'}
                            />
                          </HeadingSmall>
                        </div>
                      </Tab>
                    : null
                }
                {this.renderCommunityTab()}
              </div>
            </TabList>
            {this.renderProjects()}
            {showCommunity && this.renderCommunity()}
            {this.renderAnalytics()}
          </RoutedTabbed>
        ) :
        null
    )
  }

  renderAnalytics() {
    return <TabPanel>
      <div className="public-dashboard-new-forests-container">
        {this.renderAnalyticsDashboard()}
      </div>
    </TabPanel>
  }

  renderProjects() {
    const {
      profile,
      organizationPrivateDashboardMode
    } = this.props
    const {has_unverified_transactions} = profile
    const {isOneColumn} = this.state
    return <TabPanel>
      <div className="public-dashboard-new-forests-container">
        {has_unverified_transactions && !organizationPrivateDashboardMode && !isOneColumn && this.renderBetaBannerSnack()}
        {this.renderCompensationSummaryPanel()}
      </div>
      <div className="public-dashboard-new-forests-container">
        {this.renderFoundedForests()}
      </div>
    </TabPanel>
  }

  renderCommunity() {
    const companyNickname = this.getCompanyNickname()
    const {communityProjectIds, communitySectorsIds} = this.getCommunityProjectAndSectorIds()

    const {
      profile,
      unOnboardedCompanyMode,
    } = this.props

    const {
      name,
    } = profile

    let result
    const exampleProfileLeaderboard = [
      {
        "display_name": <FormattedMessage
          id={'PublicDashboard.unOnboardedCompany.community-tab-customer'}
          defaultMessage={`John (Customer)`}
        />,
        "avatar_url": "https://default.avatars.reforestum.com/JF_yellow.svg",
        "co2_amount": 2.33,
        "vcu_amount": 2.33,
        "sqm_amount": 0,
        "num_forest": 1
      },
      {
        "display_name": <FormattedMessage
          id={'PublicDashboard.unOnboardedCompany.community-tab-partner'}
          defaultMessage={`ACME (Partner)`}
        />,
        "avatar_url": "https://default.avatars.reforestum.com/AC_green.svg",
        "co2_amount": 0.558,
        "vcu_amount": 0.558,
        "sqm_amount": 0,
        "num_forest": 1
      },
      {
        "display_name": <FormattedMessage
          id={'PublicDashboard.unOnboardedCompany.community-tab-employee'}
          defaultMessage={`Peter (Employee)`}
        />,
        "avatar_url": "https://default.avatars.reforestum.com/PL_red.svg",
        "co2_amount": 0.34,
        "vcu_amount": 0.34,
        "sqm_amount": 0,
        "num_forest": 1
      }
    ]
    if (!unOnboardedCompanyMode) {
      result = <CommunityContainer userName={name.toLowerCase()} projectsIds={communityProjectIds.join(',')}
                                   sectorsIds={communitySectorsIds.join(',')}/>
    } else {
      result = <div>
        <Box
          className="community-container public-dashboard-unonboarded-company-community-tab-explanation__community-container">
          <div className={'public-dashboard-unonboarded-company-community-tab-explanation'}>
            <h2>
              <FormattedMessage
                id={'PublicDashboard.unOnboardedCompany.community-tab-explanation-heading'}
                defaultMessage={`Explore and recognize the community contributing to these offsets`}
                values={{companyName: companyNickname}}
              />
            </h2>
            <h3>
              <FormattedMessage
                id={'PublicDashboard.unOnboardedCompany.community-tab-explanation'}
                defaultMessage={`Bring transparency to your offsetting; visualize the community whose collaborations are making a difference.`}
                values={{companyName: companyNickname}}
              />
            </h3>
          </div>
          <CommunityPatronsPodium profileLeaderboard={exampleProfileLeaderboard}/>
          <div className={'public-dashboard-unonboarded-company-community-tab-CTAs'}>
            <Button onClick={() => this.handleShowOnboardYourCompanyModal()} small
                    className="btn--blank public-dashboard-unonboarded-company-community-tab-CTAs__button">
              <Text className={'public-dashboard-unonboarded-company__page-end-CTA__button__text'}>
                <FormattedMessage
                  id={'PublicDashboard.unOnboardedCompany.finalCTA.button'}
                  defaultMessage={'Add Your Company'}
                />
              </Text>
            </Button>
            <Button onClick={() => this.handleShowRequestThatCompanyOnboardModal()} small
                    className="btn--blank public-dashboard-unonboarded-company-community-tab-CTAs__button">
              <Text className={'my-forest-summary__unonboarded-company-activity__CTA__button__text'}>
                <FormattedMessage
                  id={'MyForestSummary.unonboardedCompanyDefaultEventsCTAContactCompany'}
                  defaultMessage={'Request updates'}
                  values={{companyName: companyNickname}}
                />
              </Text>
            </Button>
          </div>
        </Box>
      </div>
    }

    return <TabPanel>
      {result}
    </TabPanel>
  }

  renderFoundedForests(companyNickname) {
    const {
      profile,
      projects,
      openGallery,
      unOnboardedCompanyMode,
      organizationPrivateDashboardMode,
    } = this.props

    const {
      avatar_url,
    } = profile

    return (
      projects.map((forest, index) => {
        const {
          transactions,
          registry,
          certifications,
          forest_id,
          sector_id,
          name,
          forest_surface,
          sector_surface,
          user_owned_surface,
          vcus,
          main_image,
          location,
          reforesters_amount,
          planting_date,
          offset_type,
          timeline_formula,
          years_co2,
          description,
          events,
          org_total_credits,
          gallery,
          project_type
        } = forest

        const exploreLink = sector_id
          ? links.getProjectUrl(forest_id, sector_id)
          : links.getProjectUrl(forest_id)

        const reforestersLink = `${exploreLink}/reforesters`
        const isForestLevel = Boolean(sector_id)

        return (<MyForestSummary
          key={index}
          type={offset_type}
          forestImage={main_image}
          forestName={name}
          location={location}
          exploreLink={exploreLink}
          reforestersLink={reforestersLink}
          certifications={certifications}
          transactions={transactions}
          registry={registry}
          area={forest_surface}
          sectorSurface={sector_surface}
          userOwnedSurface={user_owned_surface}
          companyImage={avatar_url}
          overlayCompanyImageOnForestImage={!!forest.org_is_owner}
          reforestumProjectType={forest.reforestum_project_type}
          orgIsOwner={forest.org_is_owner}
          reforesters_amount={reforesters_amount}
          plantingDate={planting_date}
          formula={timeline_formula}
          vcus={vcus}
          project_type={project_type}
          orgTotalCredits={org_total_credits}
          years={years_co2}
          description={forest.org_is_owner ? description : ''}
          openGallery={openGallery}
          events={events}
          gallery={gallery}
          companyNickname={companyNickname}
          unOnboardedCompanyMode={unOnboardedCompanyMode}
          organizationPrivateDashboardMode={organizationPrivateDashboardMode}
          handleShowOnboardYourCompanyModal={() => this.handleShowOnboardYourCompanyModal()}
          handleShowRequestThatCompanyOnboardModal={() => this.handleShowRequestThatCompanyOnboardModal()}
          isForestLevel={isForestLevel}
        />)
      })
    )
  }

  renderRequestThatCompanyOnboardModal() {
    const {profile} = this.props

    const {
      avatar_url,
      name
    } = profile

    const companyNickname = this.getCompanyNickname()

    return <StandardFormModal
      intl={this.props.intl}
      preHeading={
        <div className="standard-form-modal__avatar">
          <img className="standard-form-modal__avatar__img" src={avatar_url || AvatarPlaceholder} alt="company logo"/>
        </div>
      }
      heading={
        <FormattedMessage
          id={'PublicDashboard.unOnboardedCompany.recordInterest.title'}
          defaultMessage={`Let this company know that you'd like to know more`}
          values={{companyName: companyNickname}}
        />
      }
      description={
        <FormattedMessage
          id={'PublicDashboard.unOnboardedCompany.recordInterest.text'}
          defaultMessage={"In Reforestum's next meeting with this company, we will let them know that you and others like you are interested in their improved neutralization activities, community and transparency, taking into consideration any specific notes you may have."}
          values={{companyName: companyNickname}}
        />
      }
      handleClose={() => this.handleShowRequestThatCompanyOnboardModal()}
      submitCallback={() => this.setState({showRequestThatCompanyOnboardModal: false})}
      additionalContext={{company: name}}
      successAlertSettings={{
        type: 'success',
        message: this.props.intl.formatMessage(MyForestSummaryTexts.unonboardedCompanyInterestFormSubmissionSuccess),
        dismissAfter: 8000
      }}
    />
  }

  renderSharingButtons() {
    const shareButtonProps = {
      url: window.location.href.replace(/\/forests$/gm, ''),
      className: 'public-dashboard__sharing__button',
    }

    const shareIconProps = {
      size: 45,
      round: true,
      className: 'public-dashboard__sharing__icon',
      iconBgStyle: {fill: 'var(--ref-greyscale-500)'}
    }

    const shareButtons = [
      {button: LinkedinShareButton, icon: LinkedinIcon},
      {button: FacebookShareButton, icon: FacebookIcon},
      {button: RedditShareButton, icon: RedditIcon},
      {button: WhatsappShareButton, icon: WhatsappIcon},
      {button: TwitterShareButton, icon: TwitterIcon}
    ]

    return <div className={'public-dashboard__sharing'}>
      {shareButtons.map(
        (b, i) => <b.button key={`sharing-button-${i}`} {...shareButtonProps}>
          <b.icon {...shareIconProps} />
        </b.button>
      )}
      <div className={'public-dashboard__sharing__copy-to-clipboard-icon__container'}
           onClick={() => this.copyLinkTextToClipboard(shareButtonProps.url)}>
        <CopyToClipboardIcon item1Props={{stroke: '#fff'}} item2Props={{stroke: '#fff'}}/>
      </div>
    </div>
  }

  renderSharingModal() {
    const companyNickname = this.getCompanyNickname()
    return <Modal
      clickClose={() => this.toggleSharingModal()}
      content={
        <div className={'public-dashboard__sharing-modal'}>
          <h1>
            {this.props.intl.formatMessage(MyForestSummaryTexts.sharingMessage, {companyName: companyNickname})}
          </h1>
          <p>
            {this.props.intl.formatMessage(MyForestSummaryTexts.sharingDescription, {companyName: companyNickname})}
          </p>
          {this.renderSharingButtons()}
        </div>
      }
    />
  }

  renderOnboardYourCompanyModal() {
    const {profile} = this.props
    const {avatar_url} = profile

    return <Modal
      clickClose={() => this.handleShowOnboardYourCompanyModal()}
      content={<div className={'public-dashboard__unonboarded-company__onboarding-modal'}>
        <div className="public-dashboard__unonboarded-company__onboarding-modal__avatar">
          <img className="public-dashboard__unonboarded-company__onboarding-modal__avatar__img"
               src={avatar_url || AvatarPlaceholder} alt="company logo"/>
        </div>
        <h2>
          <FormattedMessage
            id={'PublicDashboard.unOnboardedCompany.activationCTA.title'}
            defaultMessage={`Complete your company's profile`}
          />
        </h2>
        <h3>
          <FormattedMessage
            id={'PublicDashboard.unOnboardedCompany.activationCTA.subtitle'}
            defaultMessage={'Bring transparency and community to your CO2 neutralization efforts'}
          />
        </h3>
        <Button onClick={() => this.setState({showClaimOrganizationModal: true, showOnboardYourCompanyModal: false})}
                small className="btn--blank public-dashboard__unonboarded-company__cta">
          <FormattedMessage
            id={'PublicDashboard.unOnboardedCompany.activationCTA.getStartedCTA'}
            defaultMessage={'Get Started'}
          />
        </Button>
      </div>}
    />
  }

  renderUnonboardedCompanyEndPageCTA() {
    return <div
      className={'public-dashboard-unonboarded-company__page-end-CTA'}>
      <h2 className={'public-dashboard-unonboarded-company__page-end-CTA__title'}>
        <FormattedMessage
          id={'PublicDashboard.unOnboardedCompany.finalCTA.title'}
          defaultMessage={`Join the World's Top Companies`}
        />
      </h2>
      <h3 className={'public-dashboard-unonboarded-company__page-end-CTA__subtitle'}>
        <FormattedMessage
          id={'PublicDashboard.unOnboardedCompany.finalCTA.subtitle'}
          defaultMessage={'Bring Transparency and Community to your Carbon Offsetting Efforts'}
        />
      </h3>
      <a target={'_blank'} href={'https://reforestum.com/contact-us'}>
        <Button small className="btn--blank public-dashboard-unonboarded-company__page-end-CTA__button">
          <Text className={'public-dashboard-unonboarded-company__page-end-CTA__button__text'}>
            <FormattedMessage
              id={'PublicDashboard.unOnboardedCompany.finalCTA.button'}
              defaultMessage={'Add Your Company'}
            />
          </Text>
        </Button>
      </a>
    </div>
  }

  renderAfterMainContentSection() {
    return <div className={'public-dashboard-after-main-content'}>
      <div className="public-dashboard-new-content-left__wrapper"></div>
      <div className="public-dashboard-new-content-right">
        <section id={PUBLIC_DASHBOARD_SECTION_IDS.SIMILAR_COMPANIES}
                 className={'generic-public-dashboard-content-section'}>
          <h2 id={'related-companies'}>
            <FormattedMessage
              id={'PublicDashboard.relatedCompanies.title'}
              defaultMessage={`Discover Similar Companies`}
            />
          </h2>
          <RelatedCompanies organizationId={this.props.profile.org_id}/>
        </section>
      </div>
    </div>
  }

  renderCommunityTab() {
    const {tabs, isCurrentTab} = this.getTabInformation()

    const {
      profile,
      unOnboardedCompanyMode,
    } = this.props

    const {
      community_size,
    } = profile

    const onboardedOnlyComponent = unOnboardedCompanyMode
      ? null
      : <Token
        content={community_size && community_size.toString()}
        color={isCurrentTab(tabs.COMMUNITY) ? 'green' : 'inactive'}
      />
    return (
      unOnboardedCompanyMode
        ? <div id={PUBLIC_DASHBOARD_SECTION_IDS.PROJECTS_AND_COMMUNITY}
               onClick={() => this.handleShowOnboardYourCompanyModal()}
               className="public-dashboard__unonboarded-company__pseudo-tab react-tabs__tab">
          <div className="public-dashboard-new-forests-list-title__wrapper">
            <HeadingSmall className={
              cx(
                'public-dashboard-new-forests-list-title__heading',
                {'public-dashboard-new-forests-list-title__heading--innactive': !isCurrentTab(tabs.COMMUNITY)}
              )}
            >
              <FormattedMessage
                id={'Header.community'}
                defaultMessage={'Community'}/>
            </HeadingSmall>
            {onboardedOnlyComponent}
          </div>
        </div>
        : <Tab id={PUBLIC_DASHBOARD_SECTION_IDS.PROJECTS_AND_COMMUNITY}>
          <div className="public-dashboard-new-forests-list-title__wrapper">
            <HeadingSmall className={
              cx(
                'public-dashboard-new-forests-list-title__heading',
                {'public-dashboard-new-forests-list-title__heading--innactive': !isCurrentTab(tabs.COMMUNITY)}
              )}
            >
              <FormattedMessage
                id={'Header.community'}
                defaultMessage={'Community'}/>
            </HeadingSmall>
            {onboardedOnlyComponent}
          </div>
        </Tab>
    )
  }

  renderOrganizationPrivateDashboardPortfolio() {
    const {projects} = this.props
    return <div>
      {this.renderCompensationSummaryPanel()}
      <div
        className="public-dashboard-new-forests-list-title public-dashboard-new-forests-list-title--private-dashboard">
        <div className="public-dashboard-new-forests-list-title__wrapper">
          <p className="public-dashboard-new-forests-list-title__heading--private-dashboard">
            <FormattedMessage
              id={'Dashboard.portfolioOfProjectsTitle'}
              defaultMessage={'Portfolio of projects'}
            />
          </p>
        </div>
        <Button onClick={() => this.setState({showAddNewProjectModal: true})}
                className={"public-dashboard-new-add-project-button"}>
          <RoundedWhitePlus className="public-dashboard-new-add-project-button-icon"/>
          <FormattedMessage
            id={'Dashboard.addNewProjectButton'}
            defaultMessage={'Add new project'}
          />
        </Button>
      </div>
      <div className="public-dashboard-new-forests-container">
        {(projects && projects.length > 0) ?
          this.renderFoundedForests() :
          <div
            className="public-dashboard-new-information-message-box public-dashboard-new-information-message-box--no-projects">
            <div></div>
            <div>
              <p className="public-dashboard-new-information-message-box-text--bold">
                <FormattedMessage
                  id={'OrganizationPrivateDashboard.noProjectsTitle'}
                  defaultMessage={'You don\'t have any project in your portfolio yet.'}
                />
              </p>
              <p className="public-dashboard-new-information-message-box-text" style={{marginTop: '8px'}}>
                <FormattedMessage
                  id={'OrganizationPrivateDashboard.noProjectsText'}
                  defaultMessage={'Add your first project today!'}
                />
              </p>
            </div>
          </div>
        }
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  user: getUserDetails(state)
})

PublicDashboard.propTypes = {
  captured: PropTypes.number.isRequired,
  emitted: PropTypes.number.isRequired,
  companySlug: PropTypes.string.isRequired,
  current_c02_captured: PropTypes.number,
  future_c02_captured: PropTypes.number,
  total_vcus: PropTypes.number,
  miterd_credits: PropTypes.number,
  has_community: PropTypes.bool,
  formula: PropTypes.string,
  oxygen: PropTypes.number,
  profile: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  intl: intlShape,
  myContributions: PropTypes.array,
  openGallery: PropTypes.func.isRequired,
  unOnboardedCompanyMode: PropTypes.bool,
  organizationPrivateDashboardMode: PropTypes.bool,
  publicProfileSlug: PropTypes.string,
}

export default connect(
  mapStateToProps,
)(
  injectIntl(withRouter(PublicDashboard))
)
