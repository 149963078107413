import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Box } from 'react-layout-components';
import {
  injectIntl,
  intlShape,
  FormattedNumber,
  FormattedMessage
} from 'react-intl';

import { carbonMessages } from '../../constants/messages';

import { getFormattedWeight } from '../../utils/units';

import Uppercase from '../UI/Text/Uppercase';
import Text from '../UI/Text/Text';
import { Button } from '@reforestum/shared-components';
import { DisabledButton } from '@reforestum/shared-components';
import FormattedUnit from '../Utils/FormattedUnit';

const Total = ({
  calcErrorMessages,
  calculatedCarbon,
  className,
  formSubmit,
  frequency,
  handleUpdateSubmit,
  isAdding,
  isEditing,
  isCalculating,
  isFetching,
  stepErrorMessages,
  intl,
  sourceToEdit,
  resetForm,
  stepsList,
  calculatedPath,
  calculatedPathOfSourceToEdit,
  nameOfSourceToEdit,
  currentSourceName
 }) => {
  const renderFrequencyValue = (frequency, intl) => {
    switch (frequency) {
      case '0':
        return intl.formatMessage(carbonMessages.freqFormTotal);
      case '1':
        return intl.formatMessage(carbonMessages.freqFormDaily);
      case '2':
        return intl.formatMessage(carbonMessages.freqFormWeekly);
      case '3':
        return intl.formatMessage(carbonMessages.freqFormMonthly);
      default:
        return;
    }
  };

  const useAddOrUpdateMessage = () => {
    if (sourceToEdit !== undefined && sourceToEdit > 0){
      return (
          <FormattedMessage
              id={'CalculatorTotal.addAsNewSource'}
              defaultMessage={'Add as new'}
          />
      )
    }
    return (
        <FormattedMessage
            id={'CalculatorTotal.addSource'}
            defaultMessage={'Add CO₂ emission'}
        />
    )
  }

  const renderAddButtonIfAvailable = () => {
    return (
    !stepErrorMessages &&
    !isFetching &&
    !isCalculating &&
    !calcErrorMessages &&
    currentSourceName &&
    currentSourceName.length > 0 &&
    calculatedCarbon > 0 ?
      <Button
        loading={isAdding}
        onClick={() => formSubmit()}
      >
        {useAddOrUpdateMessage()}
      </Button>
      :
      <DisabledButton>
        {useAddOrUpdateMessage()}
      </DisabledButton>
    )
  }

  const sourceHasBeenEditedByUser = () => {
    if(!calculatedPath) return false
    if(!calculatedPathOfSourceToEdit) return false
    if(JSON.stringify(calculatedPath) !== JSON.stringify(calculatedPathOfSourceToEdit)) return true
    if(!nameOfSourceToEdit) return false
    if(!currentSourceName) return false
    if(nameOfSourceToEdit.trim() !== currentSourceName.trim()) return true
    return false
  }

  const renderUpdateButtonIfAvailable = () => {
    if (sourceToEdit !== undefined && sourceToEdit > 0){
      if(
        !stepErrorMessages &&
        !isFetching &&
        !calcErrorMessages &&
        sourceHasBeenEditedByUser() &&
        calculatedCarbon > 0
      ){
        return (
          <Button loading={isEditing} onClick={() => handleUpdateSubmit()} className={'calculator-update-button'}>
            <FormattedMessage
              id={'CalculatorTotal.updateSource'}
              defaultMessage={'Update'}
            />
          </Button>
        )
      }
      else {
        return (
          <DisabledButton>
            <FormattedMessage
              id={'CalculatorTotal.updateSource'}
              defaultMessage={'Update'}
            />
          </DisabledButton>
        )
      }
    }
    return null
  }

  const renderCancelButtonIfAvailable = () => {
    if(
      !stepErrorMessages &&
      !isFetching &&
      !calcErrorMessages &&
      stepsList.length > 1
    ){
      return (
        <Button className={'carbon-calc-total__cancel'} loading={isEditing} onClick={() => resetForm()}>
          <FormattedMessage
            id={'CalculatorTotal.cancelUpdateSource'}
            defaultMessage={'Cancel'}
          />
        </Button>
      )
    }
    else {
      return (
        <DisabledButton>
          <FormattedMessage
            id={'CalculatorTotal.cancelUpdateSource'}
            defaultMessage={'Cancel'}
          />
        </DisabledButton>
      )
    }
  }

  return (
      <Box className={cx(className, 'carbon-calc-total')}>
        <Box column className={cx(className, 'carbon-calc-total-summary')} style={{ opacity: isCalculating ? 0.5 : 1 }}>
          <Uppercase
              color="light">
            <FormattedMessage
                id={'CalculatorTotal.title'}
                defaultMessage={'Total CO₂'}
            />
          </Uppercase>

          {calcErrorMessages ?
              <Box>
                <Text color="red">
                  <FormattedMessage id={'General.passValue'} defaultMessage={' {value}'} values={{value: calcErrorMessages}} />
                </Text>
              </Box>
              :
              <Box className="carbon-calc-total__button">
                <Text>
                  {calculatedCarbon ?
                      <div>
                        <FormattedNumber
                            value={getFormattedWeight(calculatedCarbon).value}
                        /> <FormattedUnit unit={getFormattedWeight(calculatedCarbon).unit} />
                      </div>
                      :
                      <FormattedMessage
                          id={'CalculatorTotal.notAvailable'}
                          defaultMessage={'n/a'}
                      />
                  }
                </Text>
                {calculatedCarbon > 0 &&
                <Text color="light">
                  &nbsp;/&nbsp;{renderFrequencyValue(frequency, intl)}
                </Text>
                }
              </Box>
          }
        </Box>

        <div className={'carbon-calc-total__action-buttons'}>
          {renderCancelButtonIfAvailable()}
          {renderUpdateButtonIfAvailable()}
          {renderAddButtonIfAvailable()}
        </div>
      </Box>
  );
};

Total.propTypes = {
  calcErrorMessages: PropTypes.string,
  calculatedCarbon: PropTypes.number,
  className: PropTypes.string,
  formSubmit: PropTypes.func.isRequired,
  frequency: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleUpdateSubmit: PropTypes.func.isRequired,
  sourceToEdit: PropTypes.number,
  isAdding: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isCalculating: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  stepErrorMessages: PropTypes.bool,
  valid: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  resetForm: PropTypes.func,
  isSmallWidth: PropTypes.bool,
  stepsList: PropTypes.array
};

export default injectIntl(Total);
