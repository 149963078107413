import actionTypes from '../constants/actionTypes';
import { createReducer } from '../utils/reducersHelpers';

const initialState = {
  isFetching: false,
  errorMessages: null,
  community: [],
  leaderboard: [],
  pagination: null
};

export default createReducer(initialState, {
      [actionTypes.COMMUNITY_DATA_START]: state => {
      return { ...state, isFetching: true, errorMessages: null };
},
[actionTypes.COMMUNITY_DATA_COMPLETE]: (state, action) => {
  return {
      ...state,
      isFetching: false,
      community: action.payload.data.community,
      leaderboard: action.payload.data.leaderboard,
      pagination: action.payload.pagination,
};
},
[actionTypes.COMMUNITY_DATA_ERROR]: (state, action) => {
  return {
      ...state,
      isFetching: false,
      errorMessages: action.payload.message
};
}
});

// fetching and error
export const getIsFetching = (state) => state.isFetching;
export const getErrorMessages = (state) => state.errorMessages;

// community
export const getProfileCommunity = (state) => state.community;

// leaderboard
export const getProfileLeaderboard = (state) => state.leaderboard;

// pagination
export const getPagination = (state) => state.pagination;