import {FormattedMessage} from 'react-intl'
import {Button} from '@reforestum/shared-components'
import React from 'react'
import './RequestAccessModal.scss'
import PartyInUseInformation from '../../Checkout/PartyInUseInformation'
import Mail from '../../UI/Icons/Mail'

const RequestAccessModal = (
  {
    user
  }={}
) => {
  const renderRequestAccessModal = () => {
    return <div className={'discover__request-proposal-modal'}>
      <PartyInUseInformation user={user}/>
      <h1>
        <FormattedMessage
          id={`discover.DiscoverRequestAccessModalTitle`}
          defaultMessage={'Request Access'}
        />
      </h1>
      <p>
        <FormattedMessage
          id={`discover.DiscoverRequestAccessModalExplanationOne`}
          defaultMessage={`Your account does not yet have access to this feature.`}
        />
      </p>
      <p>
        <FormattedMessage
          id={`discover.DiscoverRequestAccessModalExplanationTwo`}
          defaultMessage={'You\'re just steps away from your new offsets. Use the button below to send a message to our sales team and gain access.'}
        />
      </p>
      <div className={'discover__request-proposal-modal__cta-container'}>
        <Button
          onClick={()=>{window.open('https://reforestum.com/contact-sales', '_blank')}}
        >
          <Mail stroke={'#fff'} />
          <FormattedMessage
            id={`discover.DiscoverRequestAccessModalCTA`}
            defaultMessage={'Contact Sales'}
          />
        </Button>
      </div>
    </div>
  }

  return renderRequestAccessModal()
}

export default RequestAccessModal