import React from 'react';
import PropTypes from 'prop-types';

import HeadingSmall from '../../UI/Text/HeadingSmall';

import './ForestTitle.css';

const ForestTitle = ({ children, badge, className }) => (
  <HeadingSmall className={`forest-details-title ${className ? className : ''}`}>
    {children}
    {badge && <span className="forest-details-title__badge">{badge}</span>}
  </HeadingSmall>
);

ForestTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  badge: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default ForestTitle;
