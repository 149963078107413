import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'react-layout-components';

import ForestItem from './ForestItem';
import EmptyForest from './EmptyForest';

// Forest list component used in Dashboard's sidebar ONLY
const ForestList = ({ forests }) => {

  if (forests.length <= 0) {
    return <EmptyForest />;
  }

  let renderForests = [];
  forests && forests.forEach(forest => {
    forest.user_sectors_in_forest
    ? forest.user_sectors_in_forest.map(sector => renderForests.push({...sector, forest_name: forest.name}))
    : renderForests.push(forest);
  })

  return (
    <Box column fit>
      { renderForests.map(forest => {
        const isSectorShare = !!forest.share_id;
        return (
          <ForestItem
            key={forest.id}
            forest={forest}
            isSectorShare={isSectorShare}
          />
        )
        }
      )}
    </Box>
  );
};

ForestList.propTypes = {
  forests: PropTypes.array.isRequired,
};

export default ForestList;
