import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { links } from '@reforestum/shared-utils';

import Wrapper from '../components/Layout/Wrapper/Wrapper';
import CalcContainer from '../containers/CalcContainer';
import MobileTopbar from '../components/Header/MobileTopbar/MobileTopbar';

const Calculator = (props) => {
  return (
    <Wrapper>
      <Helmet>
        <title>
          Carbon Footprint Calculator | Reforestum
        </title>
        <link rel="canonical" href={`https://app.reforestum.com${links.getCalculatorUrl()}`} />
        <meta name="description" content="What’s your contribution to climate change? Use Reforestum’s free carbon footprint calculator to measure your flights, diet, travel, and more." />
      </Helmet>
      <MobileTopbar>
        <FormattedMessage
          id={'Header.sources'}
          defaultMessage={'Carbon Footprint'}
        />
      </MobileTopbar>
      <CalcContainer {...props} />
    </Wrapper>
  );
};

export default Calculator;
