import {reforestumApiUrl, reforestumApiV2Url, googleApiKey, enterpriseActivation} from './config'

export default {
  ROOT_URL: reforestumApiUrl,
  ROOT_V2_URL: reforestumApiV2Url,
  SIGNUP: 'auth/signup',
  LOGIN: 'auth/login/',
  REFRESH_TOKEN: 'auth/keep-alive',
  RECOVER: 'auth/password-recovery',
  RESET_PASSWORD: 'auth/reset-password',
  UPDATE_USER: 'users/me',
  RECORD_FORM_RESPONSE: 'user/form-response',
  RECORD_FORM_RESPONSE_v2: 'forms/support',
  UPDATE_PASSWORD: 'auth/update-password',
  USER_ACTIVATION_DETAILS: 'users/activation-token',
  UPLOAD_AVATAR: 'user/upload-avatar',
  UPLOAD_ORG_AVATAR: 'organizations/avatar',
  UPLOAD_ORG: 'organizations',
  CREATE_ORG: '/v2/organizations',
  GET_PROFILE: 'users/me',
  GET_INVOICES: 'user/invoices',
  GET_CERTIFICATES: 'user/certificates',
  LAST_PURCHASE: 'user/last-purchase-information',
  GET_COUNTRIES: 'list/countries',
  GET_LANGUAGES: 'list/languages',
  GET_STEPS: 'co2/steps',
  CALC_CO2: 'co2/calculate',
  GET_SOURCES: 'co2/sources',
  ADD_SOURCE: 'co2/source',
  REMOVE_SOURCE: 'co2/source/',
  UPDATE_SOURCE: 'co2/source/',
  GET_BALANCE: 'co2/balance',
  GET_PUBLIC_PROFILE: 'user/public-profile', // OLD REFERENCE TO V1 FOR RETRIEVING MY CONTRIBUTIONS (offsets)
  GET_ORGANIZATION_PROFILE: 'organization/', // NEW REFERENCE TO V2 ENDPOINT FOR PUBLIC PROFILE
  GET_ORGANIZATION_COMMUNITY: '/community', // NEW REFERENCE TO V2 ENDPOINT FOR PUBLIC PROFILE COMMUNITY
  GET_ORGANIZATION_DASHBOARD: 'organizations/dashboard',
  GET_PRICE: '/checkout/pre-order',
  CRYPTO_PAYMENT: '/checkout/crypto-checkout',
  FORESTS: 'forests/',
  REFORESTER_SHARES: 'forests/',
  FOREST_SUMMARY: 'forests/summary',
  PUBLIC_FOREST_SUMMARY: 'forests/summary/',
  MY_FORESTS: 'forests/held',
  PUBLIC_FORESTS: 'forests/held/',
  PATRONS: '/patrons',
  CONTRIBUTORS: '/transactions/contributors',
  TRANSACTIONS: '/transactions',
  PROJECT_VINTAGES: '/transactions/vintages',
  GALLERY: '/gallery',
  STRIPE_INIT: '/checkout/stripe-init',
  SEARCH: `${reforestumApiV2Url}search`,
  DISCOVER: `${reforestumApiV2Url}discover/projects`,
  DISCOVER_FILTERS: `${reforestumApiV2Url}discover/projects/filters`,
  DISCOVER_PROPOSAL_REQUEST: `${reforestumApiV2Url}proposal-request`,
  RATING: (projectId) => `${reforestumApiV2Url}forests/${projectId}/rating`,
  RfpResults: () => `${reforestumApiV2Url}proposals`,
  RfpProposalDetails: () => `${reforestumApiV2Url}proposals`,
  RfpProposal: (proposalId) => `${reforestumApiV2Url}proposals/${proposalId}`,
  RATING_ONLY: (projectId) => `${reforestumApiV2Url}forests/${projectId}/rating-public`,
  ORGANIZATION: `${reforestumApiV2Url}organization`,
  GET_ORGANIZATION_MEMBERS: `${reforestumApiV2Url}organization/`,

  GOOGLE_PLACES_DETAILS: `https://maps.googleapis.com/maps/api/place/details/json?key=${googleApiKey}`,
  GOOGLE_PLACES_AUTOCOMPLETE: `https://maps.googleapis.com/maps/api/place/autocomplete/json?key=${googleApiKey}`,
  GOOGLE_DISTANCE_MATRIX: `https://maps.googleapis.com/maps/api/distancematrix/json?key=${googleApiKey}`,

  ENTERPRISE_ACTIVATION: `${enterpriseActivation}`,
  GET_COMPANY_DETAILS: 'enterprise-organization',
  OFFSET_CLAIM: '/claim',

  CALCULATOR_PLACES_DETAILS_URL: `${reforestumApiV2Url}calculator/places/details`,
  CALCULATOR_PLACES_AUTOCOMPLETE_URL: `${reforestumApiV2Url}calculator/places/autocomplete`,
  CALCULATOR_PLACES_DISTANCE_URL: `${reforestumApiV2Url}calculator/places/distance`,

  //Request for proposals
  PROPOSALS: 'proposals',
};
