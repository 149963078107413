import { normalize } from 'normalizr';
import mixpanel from 'mixpanel-browser';

import HasAccountLink from '../components/UI/Modal/HasAccountLink';

import actionTypes from '../constants/actionTypes';
import { addAlert } from '../actions/alerts';
import api from '../constants/api';
import { carbonSourcesArray } from '../schemas/carbon';
import {
  getIsAdding,
  getIsFetching,
  getIsRemoving,
  getIsUpdating
} from '../selectors/carbon';
import { showModal } from '../actions/modal';
import {  MODAL_FIRST_SOURCE } from '../constants/modalTypes';
import { getIsUserAuthenticated } from '../selectors/userSession';
import { getSourcesList } from '../selectors/carbon';
import imageSrc from '@reforestum/shared-components/dist/assets/images/1st-source-modal-image.png';
import history from '../setup/history';

import { axiosV1Instance } from './';
import getIsAutheticatedFromStore from '../utils/getIsAutheticatedFromStore';
import { links } from '@reforestum/shared-utils';

export const getSources = () => ({
  shouldCallAPI: state => !getIsFetching(state),
  callAPI: () => axiosV1Instance.get(api.GET_SOURCES),
  actions: [
    actionTypes.CARBON_FETCH_START,
    {
      type: actionTypes.CARBON_FETCH_COMPLETE,
      payload: response => {
        const { sources } = response.data.data;
        const offsetedSources = sources.filter(source => source.offseted);

        // INTERCOM: Update balance attribute =========
        if (sources.length > 0) {
          window.Intercom('update', {
            "added_sources_number": sources.length,
            "offset_sources_number": offsetedSources.length,
          });
        }
        // =========

        return normalize(response.data.data.sources, carbonSourcesArray);
      }
    },
    {
      type: actionTypes.CARBON_FETCH_ERROR,
      payload: response => response
    }
  ],
});

export const addSource = (value, name, periodicity, calculatedPath, callback) => ({
  shouldCallAPI: state => !getIsAdding(state),
  callAPI: () => {
    if(calculatedPath.length === 0) {
      calculatedPath = null
    }
    return axiosV1Instance.post(
      api.ADD_SOURCE,
      {
        amount: value,
        name: name.trim(),
        periodicity: +periodicity,
        path: calculatedPath,
      },
    );
  },
  actions: [
    actionTypes.CARBON_ADD_START,
    {
      type: actionTypes.CARBON_ADD_COMPLETE,
      payload: (response, dispatch, state) => {
        if (typeof callback === 'function') {
          callback();
        }

        if (callback !== 'redirect') {
          dispatch(addAlert({
            type: 'success',
            message: response.data.data.message + ' - ' + name,
            dismissAfter: 3000
          }));


          if (!getIsUserAuthenticated(state) && getSourcesList(state).length === 0) {
            dispatch(showModal(MODAL_FIRST_SOURCE, {
              imageSrc,
              footerTextComponent: HasAccountLink,
              onConfirm: () => history.push(links.getRegisterUrl()),
            }));
          }
        } else {
          localStorage.setItem("current_source", JSON.stringify(response.data.data.source));
        }


        mixpanel.track("CO2", {
          "Authenticated": getIsAutheticatedFromStore(),
          "Action": "Add source",
          "Value": value,
          "Name": name,
          "Periodicity": periodicity,
          "Path": calculatedPath,
          "Type": response.data.data.type,
          "Domain": "App"
        });

        return {
          ...response.data.data.source,
          offset: 0,
        }
      }
    },
    {
      type: actionTypes.CARBON_ADD_ERROR,
      payload: (response, dispatch) => {
        dispatch(addAlert({
          type: 'error',
          message: response.data.error.errors.name[0],
          dismissAfter: 3000
        }));
      }
    }
  ]
});

export const removeSource = id => ({
  shouldCallAPI: state => !getIsRemoving(state),
  callAPI: () => {
    return axiosV1Instance.delete(`${api.REMOVE_SOURCE}${id}`);
  },
  actions: [
    {
      type: actionTypes.CARBON_REMOVE_START,
      payload: () => id,
    },
    {
      type: actionTypes.CARBON_REMOVE_COMPLETE,
      payload: (response, dispatch) => {
        dispatch(addAlert({
          type: 'success',
          message: response.data.data.message,
          dismissAfter: 3000
        }));

        mixpanel.track("CO2", {
          "Authenticated": getIsAutheticatedFromStore(),
          "Action": "Remove source",
          "Id": id,
          "Domain": "App"
        });

        return response.data.data.user_balance;
      }
    },
    {
      type: actionTypes.CARBON_REMOVE_ERROR,
      payload: response => response
    }
  ]
});

export const editSource = (id, amount, name, path, callback) => ({
  shouldCallAPI: state => !getIsUpdating(state),
  callAPI: () => {
    return axiosV1Instance.put(
      `${api.UPDATE_SOURCE}${id}`,
      { name: name.trim(), amount, path },
    );
  },
  actions: [
    actionTypes.CARBON_EDIT_START,
    {
      type: actionTypes.CARBON_EDIT_COMPLETE,
      payload: (response, dispatch) => {
        if (typeof callback === 'function') {
          callback();
        }
        dispatch(addAlert({
          type: 'success',
          message: response.data.data.message + ' - ' + name,
          dismissAfter: 3000
        }));
        return {id, amount, name, path};
      }
    },
    {
      type: actionTypes.CARBON_EDIT_ERROR,
      payload: (response, dispatch) => {
        dispatch(addAlert({
          type: 'error',
          message: response.data.error.message,
          dismissAfter: 3000
        }));
        return response.data
      }
    }
  ],
});

export const prepareItemForEditing = (id, name) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.CARBON_PREPARE_EDIT,
      payload: {
        id: id,
        name: name
      }
    });
  };
};

export const exitEditMode = () => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.CARBON_EXIT_EDIT_MODE,
      payload: {}
    });
  };
};

export const updateSourceFrequency = (id, periodicity = 0) => ({
  shouldCallAPI: state => !getIsUpdating(state),
  callAPI: () => {
    return axiosV1Instance.put(
      `${api.UPDATE_SOURCE}${id}`,
      { periodicity },
    );
  },
  actions: [
    actionTypes.CARBON_UPDATE_START,
    {
      type: actionTypes.CARBON_UPDATE_COMPLETE,
      payload: () => id
    },
    {
      type: actionTypes.CARBON_UPDATE_ERROR,
      payload: response => response.data
    }
  ],
});

export const markSourceAsOffseted = (id, offset = 1) => ({
  callAPI: () => {
    return axiosV1Instance.put(
      `${api.UPDATE_SOURCE}${id}`,
      { offset },
    );
  },
  actions: [
    actionTypes.CARBON_UPDATE_START,
    {
      type: actionTypes.CARBON_UPDATE_COMPLETE,
      payload: () => {

        mixpanel.track("CO2", {
          "Authenticated": getIsAutheticatedFromStore(),
          "Action": "Offset source",
          "Id": id,
          "Domain": "App"
        });

        return id;
      }
    },
    {
      type: actionTypes.CARBON_UPDATE_ERROR,
      payload: response => response.data
    }
  ],
});

export const setCarbonFilter = filter => ({
  type: actionTypes.SET_CARBON_FILTER,
  payload: filter
});
