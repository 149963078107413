import { AUTH_METHOD } from '../../constants/authMethods'

import GoogleAuth from './GoogleAuth'
import FacebookAuth from './FacebookAuth'

const socialAuthProvider = (authMethod) => {
  return new Promise((resolve) => {
    switch (authMethod) {
      case AUTH_METHOD.GOOGLE:
        new GoogleAuth(resolve)
        break;

      case AUTH_METHOD.FACEBOOK:
        new FacebookAuth(resolve)
        break;

      default:
        resolve({
          login: () => {},
          logout: (callback) => callback(),
          setOnExternalLogout: () => {},
          handleStatusChange: () => {},
        })
    }
  })
}

export default socialAuthProvider
