import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { intlShape } from 'react-intl';
import debounce from 'lodash/debounce';
import AutosizeInput from 'react-input-autosize';
import {required, numberNormalizer, requiredMinMaxNormalizer} from '../../utils/formHelpers';
import { withIntl } from '../../utils/intl';
import './stepCommon.css';
import {INPUT_STEP_DEFAULT_SIZE} from "./helpers/constants";
import {
  moveCalculatorElementToPageTopOnMobile
} from "./helpers/moveElementToPageTopOnMobile";
import {Element} from "react-scroll";
import {INTEGER} from "../../constants/stepDataTypes";
import ClearStep from "./ClearStep";

const MAX_LENGTH_NUMBER_INPUT = '7'
const MAX_LENGTH_TEXT_INPUT = '20'

class StepInput extends Component {

  constructor() {
    super()
    this.handleChange = this.handleChange.bind(this)
    this.handleChange = debounce(this.handleChange, 300)
    this.inputComponent = this.inputComponent.bind(this)
    this.textInput = null
    this.tryingToChange = false
    this.lastTimeValueChanged = Date.now()
    this.waitBeforeCalculate = 500
    this.isFocused = false
    this.lastValidationMessage = null
  }

  componentDidMount() {
    this.textInput.focus()
    this.timeSinceAPICall = 0
    this.countTimeSinceLastAPICall = setInterval(() => {this.timeSinceAPICall += 500; this.handleNextStepIfItIsTime()}, 500)
    this.previousFormValue = ''
    this.formValue = null
    this.lastFormValue = null
    if(this.props.value) {
      const { step, handleNextStep, value } = this.props
      handleNextStep(step, step.next_step_id, value)
    }
  }

  handleChange(event) {
    this.lastTimeValueChanged = Date.now()
    this.formValue = event ? event.target.value : this.props.value
  }

  handleNextStepIfItIsTime() {
    if(Date.now() - this.lastTimeValueChanged > this.waitBeforeCalculate && this.timeSinceAPICall > 500) {
      const { step, handleNextStep, value } = this.props
      if (this.lastFormValue !== this.formValue) {
        this.timeSinceAPICall = 0;
        this.lastFormValue = this.formValue;
        if((this.formValue !== '' && value !== this.formValue) || (this.tryingToChange && this.formValue !== '')) {
          this.tryingToChange = false
          handleNextStep(step, step.next_step_id, parseFloat(this.formValue))
        }
      }
    }
  }

  inputComponent(props) {

    const { step, isMostRecentStep, stepIndex, jumpBackToStepIndex } = this.props
    const { input, meta } = props

    let overwriteValue = {}

    if (!this.tryingToChange && (this.props.value)) {
      overwriteValue = {value: this.props.value}
    }

    this.previousFormValue = {...input, ...overwriteValue}

    if(step.data_type === INTEGER && this.previousFormValue.value) {
      this.previousFormValue.value = parseInt(this.previousFormValue.value)
    }

    return (
      <div>
        <div className={'input-and-jump-back-a-step-wrapper'} id={`${isMostRecentStep ? 'most-recent-step' : ''}`}>
          <AutosizeInput
            {...this.previousFormValue}
            ref={elem => (this.textInput = elem)}
            onKeyDownCapture={() => {
              this.lastTimeValueChanged = Date.now()
              this.tryingToChange = true
            }}
            onFocus={e => {
              this.isFocused = true
              this.moveCaretToEnd(e)
            }}
            onClick={() => moveCalculatorElementToPageTopOnMobile(`step-${this.props.stepIndex}`)}
            onBlur={() => {
              this.isFocused = false
            }}
            id={isMostRecentStep ? 'most-recent-step' : ''}
            className="line-input__input"
            autoComplete="off"
            placeholder={step.placeholder}
            inputClassName="AutosizeInput-class"
            minWidth={INPUT_STEP_DEFAULT_SIZE}
          />
          {
            (input.value) && <ClearStep
              step={step}
              stepIndex={stepIndex}
              jumpBackToStepIndex={jumpBackToStepIndex}
            />
          }
        </div>
        { meta && meta.error && !this.isFocused &&
          <div className="line-input__error">
            <span color="red">{meta.error}</span>
          </div>
        }
      </div>
    )
  }

  moveCaretToEnd(e) {
    var temp_value = e.target.value
    e.target.value = ''
    e.target.value = temp_value
  }

  render() {
    const {
      step,
      stepIndex
    } = this.props

    return (
      <Element className={'react-scroll-element'} name={`step-${stepIndex}`}>
        <div className="line-input__input-holder">
          <Field
            name={`field_${step.id}`}
            component={this.inputComponent}
            onChange={this.handleChange}
            validate={this.validateInput.bind(this)}
            maxLength={step.data_type === INTEGER ? MAX_LENGTH_NUMBER_INPUT : MAX_LENGTH_TEXT_INPUT}
            normalize={this.useCorrectNormalization()}
          />
        </div>
      </Element>
    );
  }

  validateInput(value) {
    const {intl} = this.props
    if(this.tryingToChange) {
      this.lastValidationMessage = withIntl(intl, required)(value)
    }
    return this.lastValidationMessage
  }

  useCorrectNormalization() {
    const {
      step
    } = this.props
    if(step.max_value) {
      return step.data_type === INTEGER ? value => numberNormalizer(requiredMinMaxNormalizer(value, this.previousFormValue.value, 1, step.max_value), this.previousFormValue.value) : null
    }
    return step.data_type === INTEGER ? value => numberNormalizer(value, this.previousFormValue.value) : null
  }
}

StepInput.propTypes = {
  handleNextStep: PropTypes.func,
  step: PropTypes.object.isRequired,
  value: PropTypes.string,
  intl: intlShape.isRequired,
  jumpBackToStepIndex: PropTypes.func,
  stepIndex: PropTypes.number
};

export default StepInput;
