import React from 'react';

const CommunityLogo = props => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<circle cx="16.5" cy="16.5" r="16.5" fill="#60B47D"/>
<path d="M12.875 18.5C15.1187 18.5 16.9375 16.6812 16.9375 14.4375C16.9375 12.1938 15.1187 10.375 12.875 10.375C10.6314 10.375 8.81255 12.1938 8.81255 14.4375C8.81255 16.6812 10.6314 18.5 12.875 18.5Z" stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="square"/>
<path d="M18.1416 10.5263C18.7004 10.3689 19.2864 10.333 19.8602 10.4211C20.434 10.5093 20.9823 10.7193 21.468 11.0372C21.9538 11.355 22.3658 11.7733 22.6764 12.2638C22.9869 12.7543 23.1887 13.3056 23.2682 13.8806C23.3477 14.4557 23.303 15.0411 23.1372 15.5974C22.9714 16.1538 22.6883 16.6681 22.307 17.1058C21.9257 17.5436 21.455 17.8945 20.9266 18.135C20.3982 18.3755 19.8245 18.4999 19.244 18.5" stroke="white" stroke-width="1.2" stroke-linecap="square"/>
<path d="M7.24971 21.4216C7.88419 20.5191 8.7265 19.7825 9.70553 19.274C10.6846 18.7655 11.7716 18.5 12.8748 18.5C13.978 18.5 15.065 18.7654 16.0441 19.2738C17.0231 19.7822 17.8655 20.5187 18.5001 21.4212" stroke="white" stroke-width="1.2" stroke-linecap="square"/>
<path d="M19.244 18.5C20.3473 18.4992 21.4345 18.7643 22.4137 19.2728C23.3928 19.7812 24.235 20.5182 24.869 21.4212" stroke="white" stroke-width="1.2" stroke-linecap="square"/>
</svg>

);

export default CommunityLogo;
