import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import {getFormattedWeight, getFormattedArea, formatUnit, getFormattedCredits} from '../../../utils/units';
import {graphMessages} from '../../../constants/messages'
import TinyInfoBlock from '../../UI/TinyInfoBlock/TinyInfoBlock';

const GraphFooter = ({carbonByYear, dataPointsCalculated, intl, surface, vcu, myTreesGraph, firstTreeCO2AmountValue, firstTreeCO2AmountUnit }) => {
  const baseSource = dataPointsCalculated.length ? dataPointsCalculated : carbonByYear //@NOTE: Legacy compatibility
  // const totalCo2Planting = (baseSource && baseSource.length)?getFormattedWeight(baseSource[0].carbon):0;
  const lastYearEstimate = (baseSource && baseSource.length)?getFormattedWeight(baseSource[baseSource.length - 1].carbon):0;
  const lastYearDate = carbonByYear && (carbonByYear.length > 0) && carbonByYear[carbonByYear.length - 1].date
  const forestArea = getFormattedArea(surface);
  const userVCUs = getFormattedCredits({credits: vcu});
  const userTotalReductions = getFormattedWeight(vcu * 1000);

  return (
    <div className="carbon-graph__footer">

      {forestArea.value > 0 && !myTreesGraph &&
        <TinyInfoBlock
            className="carbon-graph__tiny-info-block"
            title={intl.formatMessage(graphMessages.forestArea)}
            value={forestArea.value}
            unit={formatUnit(intl, forestArea.unit)}
            icon='surface'
        />
      }

      {firstTreeCO2AmountValue > 0 &&
        <TinyInfoBlock
            className="carbon-graph__tiny-info-block"
            title={intl.formatMessage(graphMessages.totalCo2Capt)}
            value={firstTreeCO2AmountValue}
            unit={formatUnit(intl, firstTreeCO2AmountUnit)}
            icon='emissions'
            tooltipPosition='top'
            tooltip={myTreesGraph ? 
              intl.formatMessage(graphMessages.totalCo2CaptmySharesTooltip) :
              intl.formatMessage(graphMessages.totalCo2CaptForestTooltip)
            }
        />
      }

      {lastYearEstimate.value > 0 &&
        <TinyInfoBlock
            colored
            className="carbon-graph__tiny-info-block"
            title={
              dataPointsCalculated.length ? 
                intl.formatMessage(graphMessages.yearEstimate, {year: (dataPointsCalculated.length - 1)}) :
                (lastYearDate && intl.formatMessage(graphMessages.yearEstimateDate, {year: lastYearDate.getFullYear()}))
            }
            value={lastYearEstimate.value}
            unit={formatUnit(intl, lastYearEstimate.unit)}
            icon='clockFuture'
            tooltipPosition='top-left'
            tooltip={myTreesGraph ? 
              intl.formatMessage(graphMessages.yearEstimatemySharesTooltip) :
              intl.formatMessage(graphMessages.yearEstimateForestTooltip)
            }
        />}

      { userVCUs.value > 0 &&
        <TinyInfoBlock
            className="carbon-graph__tiny-info-block"
            title={intl.formatMessage(graphMessages.totalOwnedVCUs)}
            value={userVCUs.value}
            unit={formatUnit(intl, userVCUs.unit)}
            icon='vcusCredits'
        />
      }
      { userTotalReductions.value > 0 &&
        <TinyInfoBlock
            colored
            className="carbon-graph__tiny-info-block"
            title={intl.formatMessage(graphMessages.totalOwnedReductions)}
            value={userTotalReductions.value}
            unit={userTotalReductions.unit}
            icon='totalReductions'
        />
      }
    </div>
  );
};

GraphFooter.propTypes = {
  carbonByYear: PropTypes.array.isRequired,
  dataPointsCalculated: PropTypes.array,
  surface: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  vcu: PropTypes.number,
  intl: intlShape,
};

export default injectIntl(GraphFooter);
