import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { links } from '@reforestum/shared-utils'

// constants
import getFeatureFlags from '../../constants/featureFlags';
import { ACTIVATION_STATUS } from '../../constants/enterpriseLanding';

// components
import Topbar from '../Layout/Topbar/Topbar';
import Nav from './Nav';
import User from './User';
import Guest from './Guest';
import Logo from '../UI/Logo/Logo';
import GlobeSimple from "../UI/Icons/GlobeSimple";
import Close from "../UI/Icons/Close";
import AvatarPlaceholder from "@reforestum/shared-components/dist/assets/images/avatar-placeholder.png";
import ActivateSharesButtonTooltip from '../Specials/ActivateSharesButtonTooltip';

import './Header.css';
import {getUserOrImpersonatingOrg, getUserOrOrgAppropriateNameToShow} from "../../utils/usersAndOrganizations"
import Search from "../Widgets/Search/Search"
import { MOBILE_RESOLUTION_MAX_WIDTH } from '../../constants/UI';
import SecondaryNavigation from './SecondaryNavigation/SecondaryNavigation';
import { NAV_PROFILES } from '../../constants/navProfiles';
class Header extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isOpen: false,
      navHeight: 0,
    };

    this.updatedHeight = this.updatedHeight.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updatedHeight);
    window.addEventListener("toggleNav", this.toggleNav);
    this.setContainerHeight();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatedHeight);
    window.removeEventListener("toggleNav", this.toggleNav);
  }

  setContainerHeight() {
    if (this.parentContainer) {
      this.setState({
        navHeight: this.props.isOpen
          ? this.parentContainer.offsetHeight
          : document.getElementsByClassName("l-frame")[0].offsetHeight
      })
    }
  }

  updatedHeight() {
    this.setContainerHeight();
  }

  toggleNav() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  renderPreferencesSwitcherSettingsIcon(props) {
    const {togglePreferencesSwitcherPopup} = props
    return (<GlobeSimple
      className={'topbar__manage-preferences-switcher-icon'}
      onClick={() => togglePreferencesSwitcherPopup()}
    />)
  }

  renderTopbarUserInfo() {
    const {user} = this.props
    return (<div onClick={() => this.toggleNav()} className={'topbar__user-info'}>
      <div className="topbar__user-info__user-image" style={{
        backgroundImage: `url(${getUserOrImpersonatingOrg(user).avatar_url || AvatarPlaceholder})`
      }} />
      <div className={'topbar__user-info__name-and-action-text'}>
        <div className={'topbar__user-info__name'}>
          {getUserOrOrgAppropriateNameToShow(getUserOrImpersonatingOrg(user))}
        </div>
        <a href="/account/" rel="noopener noreferrer" className={'topbar__user-info__action-text'}>
          <FormattedMessage
            id={'Header.topbar.user-action-text'}
            defaultMessage={'See your profile'}
          />
        </a>
      </div>
    </div>)
  }

  render() {
    const {
      isUserPopupOpen,
      toggleUserDropDown,
      isPreferencesSwitcherPopupOpen,
      user,
      logout,
      isAuthenticated,
      navProfile,
      status,
      activationId,
      showInteractiveOverlay,
      renderSuccessActivationAlert,
      renderErrorAlertWhileClaiming,
      handleOffsetClaimingClick,
      offsetClaimErrors,
    } = this.props;

    const {
      isOpen,
      navHeight,
    } = this.state;

    let topBarContainerStyles = {}
    const hideLoginAndRegisterButtons = navProfile === NAV_PROFILES.SUPPLIER_FORMS;
    const hideSearch = navProfile === NAV_PROFILES.SUPPLIER_FORMS;

    const isMobile = window.innerWidth <= MOBILE_RESOLUTION_MAX_WIDTH

    if(isMobile){
      topBarContainerStyles = {
        marginTop: isOpen ? 0 : -navHeight,
        minHeight: isOpen ? navHeight : null,
        display: isOpen ? "block" : "none"
      }
    }

    return (
      <div
        className="topbar-container"
        ref={element => this.parentContainer  = element}
        style={topBarContainerStyles}
      >
        <Topbar className="topbar">
          <div className={'topbar__logo-and-toggle'}>
            <Logo className="topbar__logo-and-toggle__logo" />
            <Close className={'topbar__logo-and-toggle__close'} onClick={this.toggleNav}/>
          </div>
          {this.renderTopbarUserInfo()}
          <div className={'topbar__branding-and-nav'}>
            <div className="topbar__branding">
              <Link to={links.getHomeUrl()}>
                <Logo className="topbar__logo" />
              </Link>
              {
                window.innerWidth > 1140 && !hideSearch && <Search />
              }
              {
                (window.innerWidth > 1000 && window.innerWidth <= 1140) && !hideSearch && <Search medium />
              }
              {
                (window.innerWidth > 768 && window.innerWidth <= 1000) && !hideSearch && <Search small />
              }
            </div>
            <Nav
              className="topbar__nav"
              isAuthenticated={isAuthenticated}
              navProfile={navProfile}
              logout={logout}
              status={status}
              hasPurchased={user.has_already_purchased}
              user={user}
            />
          </div>
          <div className='topbar__user-and-additional-nav-items_vertical-separator'/>
            <div className="topbar__user-and-additional-nav-items">
            {isAuthenticated && status === ACTIVATION_STATUS.PENDING &&
              <div>
                <ActivateSharesButtonTooltip
                    isAuthenticated={isAuthenticated}
                    activationId={activationId}
                    showInteractiveOverlay={showInteractiveOverlay}
                    renderSuccessActivationAlert={renderSuccessActivationAlert}
                    renderErrorAlertWhileClaiming={renderErrorAlertWhileClaiming}
                    handleOffsetClaimingClick={handleOffsetClaimingClick}
                    offsetClaimErrors={offsetClaimErrors}            
                  />
              </div> 
            } 
            {!isAuthenticated
              ?
                !hideLoginAndRegisterButtons && <Guest
                  className="topbar__guest"
                  navProfile={navProfile}
                  status={status}
                  activationId={activationId}
                  showInteractiveOverlay={showInteractiveOverlay}
                  isPreferencesSwitcherPopupOpen={isPreferencesSwitcherPopupOpen}
                  togglePreferencesSwitcherPopup={this.props.togglePreferencesSwitcherPopup}
                />
              :
                <User
                  className="topbar__user"
                  user={user}
                  isUserPopupOpen={isUserPopupOpen}
                  isPreferencesSwitcherPopupOpen={isPreferencesSwitcherPopupOpen}
                  toggleUserDropDown={toggleUserDropDown}
                  togglePreferencesSwitcherPopup={this.props.togglePreferencesSwitcherPopup}
                  logout={logout}
                />
            }
            {
              getFeatureFlags().PreferencesSwitchers && this.renderPreferencesSwitcherSettingsIcon(this.props)
            }
          </div>
        </Topbar>
        {!isMobile && <SecondaryNavigation/>}
      </div>
    );
  }
}

Header.propTypes = {
  isUserPopupOpen: PropTypes.bool.isRequired,
  isPreferencesSwitcherPopupOpen: PropTypes.bool.isRequired,
  toggleUserDropDown: PropTypes.func.isRequired,
  togglePreferencesSwitcherPopup: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  navProfile: PropTypes.string,
  showInteractiveOverlay: PropTypes.bool,
  renderSuccessActivationAlert: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  status: PropTypes.string,
  activationId: PropTypes.string,
  renderErrorAlertWhileClaiming: PropTypes.func.isRequired,
  handleOffsetClaimingClick: PropTypes.func.isRequired
};

export default withRouter(Header);
