import React from 'react';
import { FormattedMessage } from 'react-intl';
import Footprint from '../../UI/Icons/Footprint';
import ProjectsLinkIcon from '../../UI/Icons/ProjectsLinkIcon';
import { links } from '@reforestum/shared-utils';
import MapTrifold from '../../UI/Icons/MapTrifold';
import MarketInsightsLinkIcon from '../../UI/Icons/MarketInsightsLinkIcon';
import OrganizationsLinkIcon from '../../UI/Icons/OrganizationsLinkIcon';
import CarbonCalculatorLinkIcon from '../../UI/Icons/CarbonCalculatorLinkIcon';
import NeutralizeFootprintLinkIcon from '../../UI/Icons/NeutralizeFootprintLinkIcon';
import HeaderExpandableSectionDesktop from './HeaderExpandableSectionDesktop';
import HeaderExpandableSectionMobile from './HeaderExpandableSectionMobile';
import FolderIcon from '../../UI/Icons/FolderIcon';
import RfpLinkIcon from '../../UI/Icons/RfpLinkIcon';
import PortfolioLinkIcon from '../../UI/Icons/PortfolioLinkIcon';

export const expandableLinks = {
    portfolio: {
        headerLink: (
            <FormattedMessage
                id={'Header.portfolioSection'}
                defaultMessage={'Portfolio'}
            />
        ),
        headerIcon: <FolderIcon/>,
        dropdownContent: [
            {
                icon: <PortfolioLinkIcon/>,
                title: (
                    <FormattedMessage
                        id={'Header.portfolioSection.MyPortfolio'}
                        defaultMessage={'My Portfolio'}
                    />
                ),
                description: (
                    <FormattedMessage
                        id={'Header.portfolioSection.MyPortfolioDescription'}
                        defaultMessage={'Explore and manage the list of projects in your portfolio'}
                    />
                ),
                link: '/portfolio'
            },
            {
                icon: <RfpLinkIcon/>,
                title: (
                    <FormattedMessage
                        id={'Header.portfolioSection.MyRequestForProposals'}
                        defaultMessage={'My Request for Proposals'}
                    />
                ),
                description: (
                    <FormattedMessage
                        id={'Header.portfolioSection.MyRequestForProposalsDescription'}
                        defaultMessage={'See proposals for your RFP'}
                    />
                ),
                link: '/rfp/0'
            }
        ]
    },
    neutralizeFootprint: {
        headerLink: (
            <FormattedMessage
                id={'Header.neutralizeFootprintSection'}
                defaultMessage={'Neutralize your carbon footprint'}
            />
        ),
        headerIcon: <Footprint/>,
        dropdownContent: [
            {
                icon: <CarbonCalculatorLinkIcon/>,
                title:(
                    <FormattedMessage 
                        id='Header.neutralizeFootprint.calculateEmissionsTitle' 
                        defaultMessage='Carbon Calculator'
                    />
                ),
                description: (
                    <FormattedMessage
                        id='Header.neutralizeFootprint.calculateEmissionsDescription'
                        defaultMessage='Calculate your carbon emission from different activities'
                    />
                ),
                link: links.getCalculatorUrl()
            },
            {
                icon: <NeutralizeFootprintLinkIcon/>,
                title: (
                    <FormattedMessage
                        id='Header.neutralizeFootprint.compensateCarbon'
                        defaultMessage='Neutralize your emissions'
                    />
                ),
                description: (
                    <FormattedMessage
                        id='Header.neutralizeFootprint.compensateCarbonDescription'
                        defaultMessage='Neutralize your emissions by supporting sustainability projects'
                    />
                ),
                link: links.getCreateForestUrl()
            }
        ]
    },
    vcmSection: {
        headerLink: (
            <FormattedMessage
                id={'Header.Header.VCMSection'}
                defaultMessage={'Explore the carbon markets'}
            />
        ),
        headerIcon: <MapTrifold/>,
        dropdownContent: [
            {
                icon: <MarketInsightsLinkIcon/>,
                title: (
                    <FormattedMessage
                        id='Header.vcmSection.maketInsightsTitle'
                        defaultMessage='Market Insights'
                    />
                ),
                description: (
                    <FormattedMessage
                        id='Header.vcmSection.marketInsightsDescription'
                        defaultMessage='Analyze the market behaviour'
                    />
                ),
                link: '/market-insights'
            },
            {
                icon: <ProjectsLinkIcon/>,
                title: (
                    <FormattedMessage
                        id='Header.vcmSection.projectsTitle'
                        defaultMessage='Projects'
                    />
                ),
                description: (
                    <FormattedMessage
                        id='Header.vcmSection.projectsDescription'
                        defaultMessage='Explore projects across 3 different registries'
                    />
                ),
                link: '/projects/discover'
            },
            {
                icon: <OrganizationsLinkIcon/>,
                title: (
                    <FormattedMessage
                        id='Header.vcmSection.organizationsTitle'
                        defaultMessage='Organizations'
                    />
                ),
                description: (
                    <FormattedMessage
                        id='Header.vcmSection.organizationsDescription'
                        defaultMessage='Discover who is contributing in different carbon projects '
                    />
                ),
                link: '/organizations'
            },
            {
                hideInTopLevelNav: true,
                icon: null,
                title: (
                    <FormattedMessage
                        id='Header.vcmSection.projectsListTitle'
                        defaultMessage='Projects List'
                    />
                ),
                link: '/projects/list'
            },
        ]
    }
}


export const NeutralizeFootprintHeaderSectionDesktop = () => <HeaderExpandableSectionDesktop {...expandableLinks.neutralizeFootprint}/>
export const VCMHeaderSectionDesktop = () => <HeaderExpandableSectionDesktop {...expandableLinks.vcmSection}/>
export const PortfolioHeaderSectionDesktop = () => <HeaderExpandableSectionDesktop {...expandableLinks.portfolio}/>
export const NeutralizeFootprintHeaderSectionMobile = () => <HeaderExpandableSectionMobile {...expandableLinks.neutralizeFootprint}/>
export const VCMHeaderSectionMobile = () => <HeaderExpandableSectionMobile {...expandableLinks.vcmSection}/>
export const PortfolioHeaderSectionMobile = () => <HeaderExpandableSectionMobile {...expandableLinks.portfolio}/>