import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Field} from 'redux-form';
import {intlShape} from 'react-intl';
import { Element } from "react-scroll";
import get from 'lodash/get';
import AutosizeInput from 'react-input-autosize';
import {formMessages, calculatorMessages} from '../../constants/messages';
import {required} from '../../utils/formHelpers';
import {withIntl} from '../../utils/intl';
import highlightMatchingPartOfName from './helpers/highlightMatchingPartOfName';
import Autocomplete from 'react-autocomplete';
import throttle from 'lodash/throttle';
import './StepTypeahead.css';
import './stepCommon.css';
import {moveCalculatorElementToPageTopOnMobile} from "./helpers/moveElementToPageTopOnMobile";
import {isSmartphone} from "./helpers/constants";
import ClearStep from "./ClearStep";

const premadeRecipes = {}

const MIN_TEXT_HIGHLIGHT_LENGTH = 0
const SEARCH_LENGTH_BEFORE_DISPLAYING_NO_MATCHES = 30
let autocompleteItemCounter = 0

class StepSelectTypeahead extends Component {
  constructor(props) {
    super(props)

    this.STEP_TYPEAHEAD_TIP = props.intl.formatMessage(formMessages.startTypingToSeeCommonSources)
    this.MAX_PREMADE_SUGGESTIONS = 2
    this.optionsById = null
    this.userHasTypedOrClickOnComponent = false
    this.state = {
      selectedOptionText: props.intl.formatMessage(formMessages.selectDefaultOption),
      options: [],
      value: null,
      lastValue: null,
      typedInput: '',
      premadeSuggestions: [],
      isEditing: false,
      foundPaths: true,
      allowHighlights: true,
      scrollY: 0
    }

    this.referenceToInputForFocus = null
    this.valueFromOptions = undefined
    this.selectComponent = this.selectComponent.bind(this)
    this.autocompleteComponent = this.autocompleteComponent.bind(this)
    this.onScroll = this.onScroll.bind(this)
    setTimeout(() => {this.fetchItems = throttle(this.fetchItems, 300)}, 500)
  }

  componentDidMount() {
    document.getElementById('root').addEventListener("scroll", this.onScroll)
    document.getElementById('l-content-id').addEventListener("scroll", this.onScroll)
    document.querySelector('.l-container').addEventListener("scroll", this.onScroll)
    document.querySelector('.l-wrapper').addEventListener("scroll", this.onScroll)
    document.querySelector('.l-frame').addEventListener("scroll", this.onScroll)
  }
  
  componentWillUnmount() {
    document.getElementById('root').removeEventListener("scroll", this.onScroll)
    document.getElementById('l-content-id').removeEventListener("scroll", this.onScroll)
    document.querySelector('.l-container').removeEventListener("scroll", this.onScroll)
    document.querySelector('.l-wrapper').removeEventListener("scroll", this.onScroll)
    document.querySelector('.l-frame').removeEventListener("scroll", this.onScroll)
  }

  componentWillUpdate(nextProps, nextState) {
    const {step: {typeahead_query_url}} = nextProps
    const {value} = nextState

    if (typeahead_query_url && value !== this.state.value) {
      if (value) {
        this.fetchItems(typeahead_query_url, value)
      }
    }
  }

  componentWillMount() {
    if(!this.state.value && !this.optionsById) {
      this.optionsById = {}
      this.props.options.forEach(op => {
        this.optionsById[op.value] = op
      })
      if(this.optionsById && this.props.manuallyChangedFormValues && this.props.manuallyChangedFormValues.field_0) {
        this.setState({value: this.optionsById[this.props.manuallyChangedFormValues.field_0].name})
      }
    }
  }

  fetchItems(typeaheadQueryUrl, value) {
    if(value === this.state.lastValue) return
    else this.setState({lastValue: value})
    fetch(`${typeaheadQueryUrl}?q=${value}`, {headers: new Headers({'Accept-language': this.getUserLanguageCode() || navigator.language})})
      .then(response => response.json())
      .then(response => {
        let quickCalculations = []
        let foundPaths = false
        if(response.data && response.data.shortcuts && response.data.shortcuts.length > 0) {
          quickCalculations = response.data.shortcuts
          foundPaths = true
        }
        else if (this.state.value != null && this.state.value.length > SEARCH_LENGTH_BEFORE_DISPLAYING_NO_MATCHES){
          quickCalculations = [{id: "0", title: this.props.intl.formatMessage(calculatorMessages.noPathsFoundInBackend), path: "[[0, null]]"}]
        }
        this.setState({
          options: quickCalculations,
          foundPaths
        })
      })
  }

  useManuallySavedFormValuesIfPossible() {
    if (this.props.manuallyChangedFormValues && this.props.manuallyChangedFormValues['field_0']) {
      return this.props.manuallyChangedFormValues['field_0']
    }
    if (this.state.value !== null) {
      return this.state.value
    }
    if (this.props.value) {
      this.setState({value: this.props.value})
      return this.props.value
    }
  }

  lookupOptionName(value) {
    if (!this.props.options) return value
    let numValue = parseInt(value)
    for (let i in this.props.options) {
      if (this.props.options[i].value === numValue) return this.props.options[i].name
    }
    return value
  }

  stringsMatch(s1, s2) {
    return s1.toLowerCase().match(s2.toLowerCase())
  }

  getUserLanguageCode() {
    return this.props.intl.locale.substr(0, 2)
  }

  getOptionsMatchingSearchInput() {
    const {options} = this.props

    let results = []
    options.forEach(op => {
      let regex = this.state.value
      if (regex) regex = regex.toLowerCase()
      if (op.name.match(regex)) results.push(op)
    })
    return results
  }

  findMostSimilarPremadeSuggestions(text) {
    let premadeNames = []
    Object.keys(premadeRecipes).forEach(key => premadeNames.push(premadeRecipes[key][this.getUserLanguageCode()]))
    return premadeNames.filter(premadeName => this.stringsMatch(premadeName, text)).map(premadeKey => premadeRecipes[premadeKey]).slice(0, this.MAX_PREMADE_SUGGESTIONS)
  }

  autocompleteChange(event, valueToUseOnComponentClick) {
    this.userHasTypedOrClickOnComponent= true
    let inputValue = event ? event.target.value : valueToUseOnComponentClick

    this.setState({isEditing: true, allowHighlights: true})
    let typedInput = inputValue
    let premadeSuggestions = this.findMostSimilarPremadeSuggestions(typedInput)
    this.setState({
      typedInput,
      value: inputValue,
      premadeSuggestions,
    })
  }

  getItemsToShow() {
    const {options, intl} = this.props
    const quickCalculationHeading = {name: intl.formatMessage(formMessages.quickCalculationHeading), value: null, itemIsHeading: true}
    const defaultOptionsHeading = {name: intl.formatMessage(formMessages.defaultOptionsHeading), value: null, itemIsHeading: true}
    const noMatchesAndDefaultOptionsHeading = {name: intl.formatMessage(formMessages.noMatchesAndDefaultOptionsHeading), value: null, itemIsHeading: true}

    let result = []

    if(this.getOptionsMatchingSearchInput().length > 0) {
      if(this.state.value && this.state.value.length > 0) {
        result.push(defaultOptionsHeading, ...this.getOptionsMatchingSearchInput())
      }
      else {
        result.push(defaultOptionsHeading, ...this.getOptionsMatchingSearchInput())
      }
    }

    if(this.state.options.length > 0) {
      result.push(quickCalculationHeading,  ...this.state.options)
    }

    if(result.length < 2 && this.getOptionsMatchingSearchInput().length !== options.length) {
      result.push(noMatchesAndDefaultOptionsHeading, ...options)
    }

    if(!this.state.value || this.state.value.length === 0) {
      result = [defaultOptionsHeading, ...options]
    }

    result = result.map(item => item.name ? item : {...item, 'name': item.title})
    return result
  }

  autocompleteComponent(props) {
    const {step, handleNextStep, fieldId, doExitEditMode, stepIndex, jumpBackToStepIndex, isMostRecentStep, disabled} = this.props
    const {input} = props

    let itemsToShow = this.getItemsToShow()

    const determineOptionClassName = (item, isHighlighted) => {
      let noSearch = !this.state.value || this.state.value.length === 0 ? ` line-input-option-base__no-search-terms` : ''
      if(item.path && item.path === '[[0, null]]') {
        return `line-input-option-base line-input__step-select-typeahead-row__no-result${noSearch}`
      }
      if(item.title) {
        return isHighlighted && this.state.allowHighlights ? `line-input-option-base line-input__step-select-typeahead-row-highlighted${noSearch}` : `line-input-option-base line-input__step-select-typeahead-row${noSearch}`
      }
      let appendHighlighted = isHighlighted && this.state.allowHighlights ? '-highlighted' : ''
      if (item === true || item.isPremade) return 'line-input-option-base line-input__step-select-typeahead-row__premade' + appendHighlighted + noSearch
      return 'line-input-option-base line-input__step-select-typeahead-row' + appendHighlighted + noSearch
    }

    const appendHeadingStyle = (item, itemIndex) => {
      let appendHeading = !item.itemIsHeading ? '' :  ' step-select-typeahead-heading'
      let appendFirstHeading = itemIndex === 1 ? ' step-select-typeahead-heading__first-heading' :  ''
      return appendHeading + appendFirstHeading
    }

    const handleSelect = (value, item) => {
      if(item.path && item.path === '[[0, null]]') {
        return null
      }
      input.onChange(value)
      this.setState({value, isEditing: false})
      if (item.path) {
        this.props.setPremadePath(item.path, item.backendTitle || item.name)
        this.props.setPremadeLoaded(false)
      } else {
        doExitEditMode()
        let optionsDict = {}
        this.props.options.forEach(op => optionsDict[op.name] = op)
        const selectedOption = optionsDict[value]
        handleNextStep(step, item.value, selectedOption.value, item.final, fieldId)
      }
    }

    return (
      <Autocomplete
        autoHighlight={false}
        value={this.state.value ? this.state.value : ''}
        onChange={event => {
          this.autocompleteChange(event, null)
        }}
        items={itemsToShow}
        getItemValue={option => option.name}
        ref={elem => (this.referenceToInputForFocus = elem)}
        inputProps={{
          onFocus: () => {
            if(this.referenceToInputForFocus != null && typeof this.referenceToInputForFocus.focus === 'function'){
              moveCalculatorElementToPageTopOnMobile(`step-${this.props.stepIndex}`)
            }
          },
          onClick: (event) => {
            if(!isSmartphone()) event.target.select()
            const {step: {typeahead_query_url}} = this.props
            if(this.state.value && this.state.value.length > 0) this.fetchItems(typeahead_query_url, this.state.value)
            this.autocompleteChange(null, this.state.value)
          },
          placeholder: this.STEP_TYPEAHEAD_TIP,
          onKeyUp: this.allowHighlights.bind(this)
        }}
        renderInput={(props) => {
          autocompleteItemCounter = 0;
          const {ref, ...restProps} = props;
          return (
            <div className={'input-and-jump-back-a-step-wrapper'} id={`${isMostRecentStep ? 'most-recent-step' : ''}`}>
              <AutosizeInput
                {...restProps}
                disabled={disabled}
                inputRef={ref}
                inputClassName="AutosizeInput-class step-typeahead-input"
              />
              {
                (input.value) && <ClearStep
                  step={step}
                  stepIndex={stepIndex}
                  jumpBackToStepIndex={jumpBackToStepIndex}
                />
              }
            </div>
          )}
        }
        isItemSelectable={item => !item.itemIsHeading}
        renderMenu={
          (itemsToShow, value, style) => {
            if(!itemsToShow || itemsToShow.length === 0) return <span/>

            return (
            <div style={{...style, ...this.menuStyle, top: style.top - this.state.scrollY}}
              className="line-input__step-select-typeahead-holder larger-typeahead-holder">
              <div onMouseOut={() => this.disallowHighlights()} onMouseOver={() => this.allowHighlights()}>
                {itemsToShow}
              </div>
            </div>
          )}
        }
        renderItem={
          (item, isHighlighted) => {
          autocompleteItemCounter++
          return (
            <div
              className={determineOptionClassName(item, isHighlighted) + appendHeadingStyle(item, autocompleteItemCounter)}
              key={item.name}
            >
              <span className={'step-select-typeahead-item-text'}>{highlightMatchingPartOfName(item.name, this.state.value, MIN_TEXT_HIGHLIGHT_LENGTH)}</span>
              {(get(item, 'match.terms')) && <div className={'line-input-option-matching-term-tag'}><span className={'line-input-option-matching-term-arrow-icon'}>&#8594;</span>{highlightMatchingPartOfName(item.match.terms.join(' '), this.state.value, 0)}</div>}
            </div>
            )
          }
        }
        shouldItemRender={() => this.userHasTypedOrClickOnComponent}
        onSelect={(value, item) => handleSelect(value, item)}
      />
    )
  }

  disallowHighlights() {
    this.setState({allowHighlights: false})
  }

  allowHighlights() {
    this.setState({allowHighlights: true})
  }

  selectComponent(props) {
    const {options, step, intl} = this.props
    const {input, meta} = props
    const selectOptions = options.map((option, index) => (
      <option
        key={index}
        value={option.value}
        data-final={option.final}
        data-nextid={option.next_step_id || step.next_step_id}>
        {option.name}
      </option>
    ))

    if (input.name.substr(6, input.name.length) === '0' && this.props.stepsList.length > 1) {
      input.value = this.props.stepsList[1][1].toString()
    }

    for (let i = 0; i < this.props.stepsList.length - 1; i++) {
      if (
        this.props.stepsList[i][1] !== undefined && this.props.stepsList[i][0] === parseInt(input.name.substr(6, input.name.length))
      ) {
        input.value = this.props.stepsList[i + 1][1].toString()
        break
      }
    }

    this.valueFromOptions = input.value

    return (
      <div>
        <select
          {...input}
          className={cx(
            'line-input__input',
          )}
        >
          <option disabled value="">
            {intl.formatMessage(formMessages.selectDefaultOption)}
          </option>
          {selectOptions}
        </select>

        {meta.touched && meta.error &&
        <div className="line-input__error">
          <span color="red">{meta.error}</span>
        </div>
        }
      </div>
    )
  }

  render() {
    const {
      options,
      step,
      intl,
      fieldId
    } = this.props

    return (
      <Element className={'react-scroll-element'} name={`step-${this.props.stepIndex}`}>
        <Field
          name={`field_${fieldId || step.id}`}
          component={this.autocompleteComponent}
          validate={withIntl(intl, required)}
          items={options}
        />
        <span>&nbsp;</span>
      </Element>
    )
  }

  onScroll(ev) {
    this.setState({scrollY: ev.target.scrollTop});
  }
}

StepSelectTypeahead.propTypes = {
  handleNextStep: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  step: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  stepsList: PropTypes.array,
  className: PropTypes.string,
  fieldId: PropTypes.string,
  value: PropTypes.string,
  sessionToken: PropTypes.string,
  jumpBackToStepIndex: PropTypes.func,
  stepIndex: PropTypes.number
}

StepSelectTypeahead.defaultProps = {
  options: [],
}

export default StepSelectTypeahead
