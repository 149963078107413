import React from 'react';

const Plant = props => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M10.5 11.5L15 7M9.5 11.5L5 7" stroke="#A5B1A6"/>
		<path d="M2 4V3.4H1.4V4H2ZM9.99991 11.7004V12.3004H10.5999V11.7004H9.99991ZM18 4H18.6V3.4H18V4ZM10.0001 11.7005H9.40009V12.3005H10.0001V11.7005ZM2 4.6H4.39997V3.4H2V4.6ZM2.6 6.31013V4H1.4V6.31013H2.6ZM7.59994 11.1004C4.81677 11.1004 2.6 8.93438 2.6 6.31013H1.4C1.4 9.63986 4.19759 12.3004 7.59994 12.3004V11.1004ZM9.99991 11.1004H7.59994V12.3004H9.99991V11.1004ZM9.39991 9.39032V11.7004H10.5999V9.39032H9.39991ZM4.39997 4.6C7.18315 4.6 9.39991 6.76607 9.39991 9.39032H10.5999C10.5999 6.06059 7.80233 3.4 4.39997 3.4V4.6ZM18 3.4H15.6V4.6H18V3.4ZM18.6 6.31013V4H17.4V6.31013H18.6ZM12.4001 12.3005C15.8024 12.3005 18.6 9.63986 18.6 6.31013H17.4C17.4 8.93438 15.1832 11.1005 12.4001 11.1005V12.3005ZM10.0001 12.3005H12.4001V11.1005H10.0001V12.3005ZM9.40009 9.39032V11.7005H10.6001V9.39032H9.40009ZM15.6 3.4C12.1977 3.4 9.40009 6.06059 9.40009 9.39032H10.6001C10.6001 6.76607 12.8169 4.6 15.6 4.6V3.4Z" fill="#1F3B21"/>
		<path d="M10 10.0918V17.3644" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
	</svg>
);

export default Plant;
