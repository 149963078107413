import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Box } from 'react-layout-components';
import { FormattedNumber } from 'react-intl';

import { getFormattedArea, getFormattedCredits } from '../../../utils/units';

import Text from '../../UI/Text/Text';
import Small from '../../UI/Text/Small';
import AvatarPlaceholder from '@reforestum/shared-components/dist/assets/images/avatar-placeholder.png';
import FormattedUnit from '../../Utils/FormattedUnit';
import { OFFSET_TYPE_SQM_SHARES } from '../../../constants/offsetTypes';

import './PatronsPodium.css';
import { links } from '@reforestum/shared-utils';

const PatronsPodium = ({ patrons, forestType }) => {
  return (
    <Box className="patrons-podium-special" justifyContent="space-around" alignItems="flex-end">
      {patrons.map(patron => (
        <Box
          className="patrons-podium-special__patron"
          column
          key={patron.rank}
          alignItems="center"
          order={patron.rank === 2 ? -1 : patron.rank}>
          
          {/* make linkable to public profile when available */}
          {patron.public_profile_slug 
          ?
            <Link to={links.getPublicProfileUrl(patron.public_profile_slug)}>
              <div
                  className={cx(
                    'patrons-podium__avatar',
                    `patrons-podium__avatar--${patron.rank}`, {
                      'patrons-podium__avatar__public': patron.public_profile_slug
                    }
                  )}
                  style={{
                    backgroundImage: `url(${
                      (patron.avatar_url && CSS.escape(patron.avatar_url))
                      || AvatarPlaceholder
                    })`
                  }}>
                  <Box center
                    className="patrons-podium__rank">
                    {patron.rank}
                  </Box>
                </div>
            </Link>
          : 
            <div
              className={cx(
                'patrons-podium__avatar',
                `patrons-podium__avatar--${patron.rank}`
              )}
              style={{
                backgroundImage: `url(${
                  (patron.avatar_url && CSS.escape(patron.avatar_url))
                  || AvatarPlaceholder
                })`
              }}>
              <Box center
                className="patrons-podium__rank">
                {patron.rank}
              </Box>
            </div>
          }
          <Text>
            {patron.name}
          </Text>
          {forestType === OFFSET_TYPE_SQM_SHARES ?
           <Small color="green">
            <FormattedNumber
                    value={getFormattedArea(patron.surface).value}
             /> <FormattedUnit unit={getFormattedArea(patron.surface).unit}/>
           </Small>
          :
            <Small color="green">
             <FormattedNumber
                    value={getFormattedCredits({credits: patron.vcu}).value}
             /> <FormattedUnit unit={getFormattedCredits({credits: patron.vcu}).unit}/>
            </Small>
          }
        </Box>
      ))}
    </Box>
  )
};

PatronsPodium.propTypes = {
  patrons: PropTypes.array.isRequired,
  forestType: PropTypes.string
};

export default PatronsPodium;
