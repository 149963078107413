import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import {
  FormattedMessage,
  injectIntl,
  intlShape
} from 'react-intl';
import { links } from '@reforestum/shared-utils'

import {
  authMessages,
  settingsMessages,
  formMessages, 
} from '../../constants/messages';
import { PRIVACYLEVEL } from '../../constants/privacyLevels';

import history from '../../setup/history';

import { passwordStrength, required, email } from '../../utils/formHelpers';
import { withIntl } from '../../utils/intl';

import FeedbackMessage from '../UI/Forms/FeedbackMessage';
import Input from '../UI/Forms/Input';
import Row from '../UI/Forms/Row';
import RowAligner from '../UI/Forms/RowAligner';
import { Button } from '@reforestum/shared-components';
import SocialButtons from './SocialButtons';
import Small from '../UI/Text/Small';
import {EyeLashes} from '../UI/Icons/EyeLashes';
import Eye from '../UI/Icons/Eye';
import './Login.css';
import CustomSelect from '../UI/Forms/CustomSelectNew';
import AcceptTermsOfServiceCheckbox from './ReusableFormFields/AcceptTermsOfServiceCheckbox';

class SignupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        privacy: PRIVACYLEVEL.PUBLIC,
        selectedOption: this.props.privacyOptions[0],
        togglePasswordVisibility: false,
        togglePasswordConfirmVisibility: false
    }

    this.setPrivacyLevel = this.setPrivacyLevel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleTogglePasswordVisibility = this.handleTogglePasswordVisibility.bind(this);
    this.handleTogglePasswordConfirmVisibility = this.handleTogglePasswordConfirmVisibility.bind(this);
  }

  setPrivacyLevel(e){
    this.setState({ privacy: Number(e.target.value) })
  };

  handleChange(selectedOption){
    this.setState({ selectedOption })
    this.setState({ privacy: Number(selectedOption.value) })
  }

  submitForm(form){
    const { privacy } = this.state;

    const signupPayload = {...form, privacy, display_name: form.nickname}
    this.props.handleSignup(signupPayload);
  };

  handleTogglePasswordVisibility(){
    this.setState({ togglePasswordVisibility: !this.state.togglePasswordVisibility })
  }

  handleTogglePasswordConfirmVisibility(){
    this.setState({ togglePasswordConfirmVisibility: !this.state.togglePasswordConfirmVisibility })
  }

  render(){
    const {
      errorMessages,
      handleSubmit,
      intl,
      isLoading,
      privacyOptions,
      invalid
    } = this.props;

    const { privacy, togglePasswordVisibility, togglePasswordConfirmVisibility } = this.state;

    return (
      <form onSubmit={handleSubmit(this.submitForm)}>
      {
        errorMessages &&
        <FeedbackMessage type="error">{errorMessages}</FeedbackMessage>
      }
      <SocialButtons />
      <Row>
        <Field
          name="name"
          component={Input}
          disabled={isLoading}
          placeholder={intl.formatMessage(authMessages.namePlaceholder)}
          label={intl.formatMessage(authMessages.nameLabel)}
          type="text"
          validate={withIntl(intl, required)}
        />
        <Field
          name="surname"
          component={Input}
          disabled={isLoading}
          placeholder={intl.formatMessage(authMessages.surnamePlaceholder)}
          label={intl.formatMessage(authMessages.surnameLabel)}
          type="text"
          validate={withIntl(intl, required)}
        />
      </Row>
  
      <Row>
        <Field
          name="email"
          component={Input}
          disabled={isLoading}
          placeholder={intl.formatMessage(authMessages.emailPlaceholder)}
          label={intl.formatMessage(authMessages.emailLabel)}
          type="email"
          validate={withIntl(intl, [email, required])}
        />
      </Row>

      <Row>
        <CustomSelect
          label={intl.formatMessage(settingsMessages.privacyLabel)}
          defaultValue={privacyOptions[0]}
          options={privacyOptions}
          onChange={this.handleChange}
        />
      </Row>

      {privacy === 2 &&
        <Row>
          <Field
            name="nickname"
            disabled={isLoading}
            component={Input}
            label={intl.formatMessage(settingsMessages.nicknameLabel)}
            placeholder={intl.formatMessage(settingsMessages.nicknamePlaceholder)}
            type="text"
            validate={Number(privacy) > 1 ? withIntl(intl, required) : null}
          />
        </Row>
      }
  
      <Row className='special__wrapper'>
        <div className='special-wrapper-with-icon'>
          <Field
            className='special-input-with-icon'
            name="password"
            disabled={isLoading}
            component={Input}
            placeholder={intl.formatMessage(authMessages.passwordPlaceholder)}
            label={intl.formatMessage(authMessages.passwordLabel)}
            type={togglePasswordVisibility ? "text" : "password"}
            validate={withIntl(intl, [required, passwordStrength])}
          />
          {!togglePasswordVisibility
            ? <EyeLashes className='special-input__icon' onClick={this.handleTogglePasswordVisibility}/>
            : <Eye className='special-input__icon' onClick={this.handleTogglePasswordVisibility}/>
          }
        </div>

        <div className='special-wrapper-with-icon'>
          <Field
            className='special-input-with-icon'
            name="passwordConfirm"
            disabled={isLoading}
            component={Input}
            placeholder={intl.formatMessage(authMessages.repeatPasswordPlaceholder)}
            label={intl.formatMessage(authMessages.repeatPasswordLabel)}
            type={togglePasswordConfirmVisibility ? "text" : "password"}
            validate={withIntl(intl, required)}
          />
          {!togglePasswordConfirmVisibility
            ? <EyeLashes className='special-input__icon' onClick={this.handleTogglePasswordConfirmVisibility}/>
            : <Eye className='special-input__icon' onClick={this.handleTogglePasswordConfirmVisibility}/>
          }
        </div>
      </Row>
  
      <Row>
        <AcceptTermsOfServiceCheckbox disabled={isLoading} />
      </Row>
  
      <Row>
        <RowAligner>
          <Button
            disabled={invalid}
            type="submit"
            className='login__button'
            loading={isLoading}
          >
            <FormattedMessage
              id={'Signup.signupButton'}
              defaultMessage={'Sign up'}
            />
          </Button>
        </RowAligner>
      </Row>
      <Row>
        <div className='new-cta__wrapper'>
          <Small weight={'regular'}>
            <FormattedMessage 
              id={'Common.hasAccount'}
              defaultMessage={'Already have an account?'}
            />
            <span
              onClick={() => history.push(links.getLoginUrl())}
              style={{ color: 'var(--ref-green-300)', marginLeft: '0.3rem', cursor: 'pointer' }}>
              <FormattedMessage
                id={'Header.login'}
                defaultMessage={'Login'}
              />
            </span>
          </Small>
        </div>
      </Row>
    </form>
    );
  }
};

const validate = (formProps, props) => {
  const errors = {};
  const { password, passwordConfirm } = formProps;
  const { formatMessage } = props.intl;

  if ( passwordConfirm && (password !== passwordConfirm) ) {
    errors.passwordConfirm = formatMessage(formMessages.passwordNotMatch);
  }

  return errors;
};

SignupForm.propTypes = {
  className: PropTypes.string,
  handleSignup: PropTypes.func.isRequired,
  errorMessages: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  privacyOptions: PropTypes.array.isRequired,
  /* redux form */
  handleSubmit: PropTypes.func.isRequired,
  /* intl */
  intl: intlShape.isRequired,
};

export default injectIntl(reduxForm({
  form: 'signup',
  validate
})(SignupForm));
