import React, { Component } from 'react';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { injectIntl, intlShape } from 'react-intl';
import { links } from '@reforestum/shared-utils'

import { requestStep, clearForm, resetManuallySavedFormValues, calculateCarbon, manuallyChangeFormValues, convertStepIndexToMiles, } from '../actions/calculator';
import { addSource, editSource, exitEditMode } from '../actions/carbon';
import { addAlert } from '../actions/alerts';
import { showModal } from '../actions/modal';

import RedRainyCloud from '../components/UI/Icons/RedRainyCloud';

import {
  getCalcErrorMessages,
  getCalculatedCarbon,
  getFormSteps,
  getIsAdding,
  getIsCalculating,
  getIsFetching,
  getStepErrorMessages,
  getStepsList,
  getPossibleNextStepValues,
  getManuallyChangedFormValues,
  getPossibleNextStepType,
  getOptions,
  getcalculatedPath,
  getAdvices,
  getDistanceConverstionsForEditDone,
} from '../selectors/calculator';

import { getSourceToEdit, getSourceToEditName, getAllSources, getIsEditing } from '../selectors/carbon';
import { getToken, getIsUserAuthenticated } from '../selectors/userSession';

import { MODAL_DEFAULT } from '../constants/modalTypes';

import Calculator from '../components/Calculator/Calculator';

import history from '../setup/history';
import {modalCalcExitMessages} from '../constants/messages'
import getIsAutheticatedFromStore from '../utils/getIsAutheticatedFromStore';


const updateInnerHeightCssVariable = () => {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

updateInnerHeightCssVariable()

window.addEventListener('resize', () => {
  updateInnerHeightCssVariable()
})

class CalcContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      shouldReinitializeCalculatorComponent: false
    }
  }

  componentWillMount() {
    this.props.requestStep();
    this.setDefaultFrequency();
  }

  componentDidMount() {
    this.unblock = history.block(targetLocation => {
      const { isAuthenticated, stepsList, showModal, intl } = this.props;
      const isLoggingOut = isAuthenticated && targetLocation.pathname === links.getLoginUrl();

      if (stepsList.length > 1 && !isLoggingOut) {
        showModal(MODAL_DEFAULT, {
          title: intl.formatMessage(modalCalcExitMessages.title),
          text: intl.formatMessage(modalCalcExitMessages.text),
          confirmButtonText: intl.formatMessage(modalCalcExitMessages.confirmButtonText),
          cancelButtonText: intl.formatMessage(modalCalcExitMessages.cancelButtonText),
          iconComponent: RedRainyCloud,
          onReject: () => {
            this.unblock()
            history.push(targetLocation);
          },
        });
        return false;
      }
      return true;
    });

    mixpanel.track("Page view", {
      "Authenticated": getIsAutheticatedFromStore(),
      "Action": "My CO2 Sources",
      "Domain": "App"
    });


    const css = `
      @media (min-width: 769px) {
        /* just bottom padding */
        #intercom-container .intercom-launcher-frame {
          bottom: 90px !important;
        }

        /* bottom padding + 80px */
        #intercom-container .intercom-app-launcher-enabled .intercom-messenger-frame,
        #intercom-container .intercom-app-launcher-enabled .intercom-notifications-frame {
          bottom: 170px !important;
        }

        /* bottom padding + 70px */
        #intercom-container .intercom-app-launcher-enabled .intercom-borderless-frame {
          bottom: 160px !important;
        }

        /* bottom padding + 40px */
        #intercom-container .intercom-launcher-badge-frame {
          bottom: 130px !important;
        }
      }
    `
    this.intercomStylesId = 'calculator-intercom-styles'
    const intercomStyles = document.createElement('style')
    intercomStyles.id = this.intercomStylesId
    intercomStyles.setAttribute('type', 'text/css')
    intercomStyles.appendChild(document.createTextNode(css))
    document.head.appendChild(intercomStyles)
  }

  componentWillUnmount() {
    this.unblock();
    this.resetForm();

    const intercomStyles = document.getElementById(this.intercomStylesId)
    intercomStyles && intercomStyles.remove()
  }

  setReinitializeCalculatorComponent(shouldReinitialize=true) {
    if (shouldReinitialize) this.setState({shouldReinitializeCalculatorComponent: true})
    else this.setState({shouldReinitializeCalculatorComponent: false})
  }

  formSubmit() {

    const {
      addSource,
      calculatedCarbon,
      calculatedPath,
      formValues
    } = this.props;

    addSource(
      calculatedCarbon,
      formValues.field_last,
      formValues.field_frequency,
      calculatedPath,
      this.resetForm.bind(this)
    )
  }

  handleUpdateSubmit() {
    const {
      editSource,
      calculatedPath,
      calculatedCarbon,
      sourceToEdit,
      formValues
    } = this.props;

    editSource(
      sourceToEdit,
      calculatedCarbon,
      formValues.field_last,
      JSON.stringify(calculatedPath),
      () => {this.resetForm()}
    )
  }

  setFormValues(object) {
    Object.keys(object).forEach(
      k => this.props.change(k, object[k])
    )
  }

  async resetForm() {
    const { dispatch, requestStep } = this.props;
    this.setDefaultFrequency()
    exitEditMode()(this.props.dispatch)
    this.setReinitializeCalculatorComponent()
    dispatch(resetManuallySavedFormValues())
    await requestStep()
  }

  doExitEditMode(){
    exitEditMode()(this.props.dispatch);
  }

  setDefaultFrequency() {
    this.props.change('field_frequency', '0');
  }

  getInitialValuesIfEditingSource() {
    return this.props.manuallyChangedFormValues
  }

  render() {
    const {
      addAlert,
      calculatedCarbon,
      untouch,
      formValues,
      handleSubmit,
      isAdding,
      isEditing,
      change,
      isCalculating,
      sourceToEdit,
      sourceToEditName,
      sources,
      isFetching,
      stepErrorMessages,
      steps,
      stepsList,
      possibleNextStepValues,
      possibleNextStepType,
      manuallyChangedFormValues,
      manuallyChangeFormValues,
      distanceConverstionsForEditDone,
      convertStepIndexToMiles,
      valid,
      calcErrorMessages,
      options,
      token,
      requestStep,
      calculateCarbon,
      advices,
      calculatedPath
    } = this.props;

    return (
      <Calculator
        shouldReinitializeCalculatorComponent={this.state.shouldReinitializeCalculatorComponent}
        setReinitializeCalculatorComponent={this.setReinitializeCalculatorComponent.bind(this)}
        addAlert={addAlert}
        calcErrorMessages={calcErrorMessages}
        calculateCarbon={calculateCarbon}
        calculatedCarbon={calculatedCarbon}
        calculatedPath={calculatedPath}
        change={change}
        setFormValues={this.setFormValues.bind(this)}
        untouch={untouch}
        formSubmit={this.formSubmit.bind(this)}
        formValues={formValues}
        manuallyChangeFormValues={manuallyChangeFormValues}
        handleSubmit={handleSubmit}
        handleUpdateSubmit={this.handleUpdateSubmit.bind(this)}
        isAdding={isAdding}
        isEditing={isEditing}
        isCalculating={isCalculating}
        sourceToEdit={sourceToEdit}
        sourceToEditName={sourceToEditName}
        sources={sources}
        isFetching={isFetching}
        options={options}
        requestStep={requestStep}
        manuallyChangedFormValues={manuallyChangedFormValues}
        distanceConverstionsForEditDone={distanceConverstionsForEditDone}
        convertStepIndexToMiles={convertStepIndexToMiles}
        stepErrorMessages={stepErrorMessages}
        steps={steps}
        stepsList={stepsList}
        resetForm={this.resetForm.bind(this)}
        doExitEditMode={this.doExitEditMode.bind(this)}
        possibleNextStepValues={possibleNextStepValues}
        possibleNextStepType={possibleNextStepType}
        valid={valid}
        sessionToken={token.substring(0, 10)}
        advices={advices}
      />
    );
  }
}

CalcContainer.propTypes = {
  intl: intlShape.isRequired,
  addSource: PropTypes.func.isRequired,
  editSource: PropTypes.func.isRequired,
  calcErrorMessages: PropTypes.string,
  calculatedCarbon: PropTypes.number,
  change: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  manuallyChangedFormValues: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  isAdding: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  sourceToEdit: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object
  ]),
  sourceToEditName: PropTypes.string,
  sources: PropTypes.array,
  isCalculating: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  requestStep: PropTypes.func.isRequired,
  manuallyChangeFormValues: PropTypes.func.isRequired,
  distanceConverstionsForEditDone: PropTypes.bool.isRequired,
  exitEditMode: PropTypes.func,
  convertStepIndexToMiles: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  stepErrorMessages: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  steps: PropTypes.array.isRequired,
  stepsList: PropTypes.array.isRequired,
  possibleNextStepValues: PropTypes.array,
  valid: PropTypes.bool.isRequired,
  options: PropTypes.object.isRequired,
  calculatedPath: PropTypes.array.isRequired,
  calculateCarbon: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    calcErrorMessages: getCalcErrorMessages(state),
    calculatedCarbon: getCalculatedCarbon(state),
    formValues: getFormValues('calculator')(state),
    manuallyChangedFormValues: getManuallyChangedFormValues(state),
    isAdding: getIsAdding(state),
    isEditing: getIsEditing(state),
    sourceToEdit: getSourceToEdit(state),
    sourceToEditName: getSourceToEditName(state),
    sources: getAllSources(state),
    isCalculating: getIsCalculating(state),
    isFetching: getIsFetching(state),
    stepErrorMessages: getStepErrorMessages(state),
    steps: getFormSteps(state),
    stepsList: getStepsList(state),
    possibleNextStepValues: getPossibleNextStepValues(state),
    possibleNextStepType: getPossibleNextStepType(state),
    options: getOptions(state),
    distanceConverstionsForEditDone: getDistanceConverstionsForEditDone(state),
    calculatedPath: getcalculatedPath(state),
    token: getToken(state),
    isAuthenticated: getIsUserAuthenticated(state),
    advices: getAdvices(state),
  }
};

const validate = values => {
  const errors = {};

  if (!values.field_last) {
    errors.field_last = 'This field is required';
  }
  return errors;
};

const CalcForm = reduxForm({
  form: 'calculator',
  validate
})(injectIntl(CalcContainer));

export default connect(mapStateToProps, {
  addSource,
  editSource,
  clearForm,
  requestStep,
  manuallyChangeFormValues,
  convertStepIndexToMiles,
  calculateCarbon,
  addAlert,
  showModal,
})(CalcForm);
