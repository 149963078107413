import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en';

import Photo from '../../UI/Icons/Photo'
import './LatestActivityCard.css';

const LatestActivityCard = ({date, pics = [], title, type, text, openGallery, lang, postTextComponent}) => {
  const [ mainImage, ...thumbnails ] = pics;
  const locales = { en, es };

  return (
    <div className="latest-activity-card">
      <span className={`latest-activity-card__icon latest-activity-card__icon--${type.toLowerCase()}`} />
      <span className="latest-activity-card__date">{format(date, 'DD MMM, YYYY', { locale: locales[lang] })}</span>
      <h2 className="latest-activity-card__title">{title}</h2>
      {text && <p className="latest-activity-card__text">{text}</p>}
      {postTextComponent && postTextComponent}
      {mainImage && (
        <div
          role="img"
          onClick={openGallery(0)}
          className="latest-activity-card__main-image"
          style={{backgroundImage: `url(${mainImage.url})`}}
        />
      )}
      {thumbnails.length > 0 && (
        <div className="latest-activity-card__thumbnails-container">
          {thumbnails.slice(0, 4).map((thumbnail, index) => (
            <div
              key={index}
              role="img"
              onClick={openGallery(index + 1)}
              className={cx('latest-activity-card__thumbnail', { 'latest-activity-card__thumbnail--overlay': index === 3})}
              style={{backgroundImage: `url(${thumbnail.url})`}}
            >
              {index === 3 && thumbnails.length > 4 && (
                <span className="latest-activity-card__thumbnail-overlay-value">
                  <span role="img"><Photo /></span>+ {thumbnails.length - 4}
                </span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

LatestActivityCard.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  pics: PropTypes.array,
  postTextComponent: PropTypes.object,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  text: PropTypes.string,
  openGallery: PropTypes.func.isRequired,
  lang: PropTypes.string,
};

export default LatestActivityCard;
