import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ArrowRightSimple from '../Icons/ArrowRightSimple';
import ArrowLeftSimple from '../Icons/ArrowLeftSimple';
import { uuidv4 } from '../../../utils/uuidv4';

import './HorizontalScrollWithArrowsContainer.css'
import ArrowRightSimpleNoCircle from '../Icons/ArrowRightSimpleNoCircle';
import ArrowLeftSimpleNoCircle from '../Icons/ArrowLeftSimpleNoCircle';

const MAXIMUM_HORIZONTAL_OVERFLOW = 10 
class HorizontalScrollWithArrowsContainer extends Component {
  constructor() {
    super();

    this.state = {
      showTableScrollLeftButton: false,
      showTableScrollRightButton: false,
    }

    this.resizeEventListener = null;
    this.scrollEventListener = null;
    this.setCorrespondingScrollButtons = this.setCorrespondingScrollButtons.bind(this)
    this.scroll = this.scroll.bind(this)
    this.scrollContainerId = `SCROLL_CONTAINER_ID_${uuidv4()}`
  }

  setCorrespondingScrollButtons() {
    const {showTableScrollLeftButton, showTableScrollRightButton} = this.state
    const tableContainerRef = document.getElementById(this.scrollContainerId)
    if(!tableContainerRef) return
    
    const hasScrollBar =  tableContainerRef.scrollWidth > (tableContainerRef.clientWidth + MAXIMUM_HORIZONTAL_OVERFLOW)
    if(!hasScrollBar && (showTableScrollLeftButton || showTableScrollRightButton)) {
      this.setState({showTableScrollLeftButton: false, showTableScrollRightButton: false})
      return  
    }

    
    //When checking if the scrollbar is at the begining or at the end
    //we also check if the difference with the begining or the end of the scrollbar is less than 1
    //So we adjust this small remaining as the dom function scroll() can not handle decimals  
    const isAtBegining = Math.abs(tableContainerRef.scrollLeft) < 1
    if(isAtBegining) tableContainerRef.scrollLeft = 0

    const isAtEnd =  (Math.abs(tableContainerRef.scrollWidth - tableContainerRef.clientWidth) - Math.abs(tableContainerRef.scrollLeft)) < 1
    if (isAtEnd) tableContainerRef.scrollLeft = Math.abs(tableContainerRef.scrollWidth - tableContainerRef.clientWidth)

    if(hasScrollBar && isAtBegining && (showTableScrollLeftButton || !showTableScrollRightButton)) {
      this.setState({showTableScrollRightButton: true, showTableScrollLeftButton: false})
    } else if(hasScrollBar && isAtEnd && (!showTableScrollLeftButton || showTableScrollRightButton)) {
      this.setState({showTableScrollRightButton: false, showTableScrollLeftButton: true})
    } else if(hasScrollBar && !isAtBegining && !isAtEnd && (!showTableScrollLeftButton || !showTableScrollRightButton)) {
      this.setState({showTableScrollRightButton: true, showTableScrollLeftButton: true})
    }
  }

  scroll(scrollValue) {
    const tableContainerRef = document.getElementById(this.scrollContainerId)
    if(!tableContainerRef) return
    tableContainerRef.scrollLeft += scrollValue 
  }

  componentDidMount() {
    const tableContainerRef = document.getElementById(this.scrollContainerId)
    if(!tableContainerRef) return
    this.setCorrespondingScrollButtons()
    this.resizeEventListener = window.addEventListener("resize", this.setCorrespondingScrollButtons)
    this.scrollEventListener = tableContainerRef.addEventListener("scroll", this.setCorrespondingScrollButtons)
  }

  componentDidUpdate() {
    this.setCorrespondingScrollButtons()
  }

  componentWillUnmount() {
    this.resizeEventListener && this.resizeEventListener.removeEventListener("resize", this.setCorrespondingScrollButtons)
    this.scrollEventListener &&  this.resizeEventListener.removeEventListener("scroll", this.setCorrespondingScrollButtons)
  }

  render() {

    const { children, className, hideScrollbar, centered, overflowYVisible } = this.props

    const {
      showTableScrollLeftButton,
      showTableScrollRightButton,
    } = this.state

    //This sets the container to overflow visible to allow the transactions actions dropdown to overflow
    //This can only be done when there is not horizontal scrolling as there is a limitation to set the overflow-y visible when the overflow-x is set to scroll
    //See the following thread for more info: https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue
    const horizontalScrollContainerStyle = overflowYVisible && !(showTableScrollLeftButton || showTableScrollRightButton) ? {overflow: "visible"} : {}

    return (
      <div className='horizontal-scroll-with-arrows-container'>
        <div className={`horizontal-scroll-with-arrows-container-button-left-container`}>
            {showTableScrollLeftButton && (
              <div 
                className={
                  `horizontal-scroll-with-arrows-container-left-button 
                  ${centered ? 'horizontal-scroll-with-arrows-container-button--centered horizontal-scroll-with-arrows-container-button--centered--left' : ''}`
                } 
                onClick={() => this.scroll(-100)}
              >
                {centered ? <ArrowLeftSimpleNoCircle className="horizontal-scroll-with-arrows-container-centered-arrow"/> : <ArrowLeftSimple/>}
              </div>
            )}
        </div>
        <div className={`horizontal-scroll-with-arrows-container-button-right-container`}>
           {showTableScrollRightButton && (
              <div 
                className={
                  `horizontal-scroll-with-arrows-container-right-button 
                  ${centered ? 'horizontal-scroll-with-arrows-container-button--centered horizontal-scroll-with-arrows-container-button--centered--right' : ''}`
                } 
                onClick={() => this.scroll(100)}
              >
                {centered ? <ArrowRightSimpleNoCircle className="horizontal-scroll-with-arrows-container-centered-arrow"/> : <ArrowRightSimple/>}
              </div>
            )}
        </div>
        <div style={horizontalScrollContainerStyle} className={`horizontal-scroll-with-arrows-container-scroll-container ${hideScrollbar ? 'horizontal-scroll-with-arrows-container-scroll-container--hide-scrollbar' : ''} ${className ? className : ''}`} id={this.scrollContainerId}>
          {children}
        </div>
      </div>
    )
  }
}

HorizontalScrollWithArrowsContainer.propTypes = {
    className: PropTypes.string,
    hideScrollbar: PropTypes.bool,
};

export default HorizontalScrollWithArrowsContainer;