// Custom styles for customize Select (account privacy) component
const customSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'var(--ref-greyscale-300)' : 'none',
      cursor: 'pointer',
      "&:hover": {
        backgroundColor: "var(--ref-greyscale-100)",
      },
    }),
    container: base => ({
      ...base,
      width: "100%"
    }),
    control: () => ({
        "&:hover": {
          border: "1px solid var(--ref-greyscale-600)",
          opacity: 1,
          boxShadow: "0 0 transparent"
        },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 'calc(var(--unit) * 8)',
        minHeight: 'calc(var(--unit) * 8)',
        width: "100%",
        textAlign: "left",
        cursor: "pointer",
        fontSize: 16,
        borderRadius: "var(--border-radius)",
        padding: "calc(var(--unit) * 1.5)",
        backgroundColor: "var(--ref-greyscale-white)",
        border: "1px solid var(--aluminum)",
        transition: "boxShadow 0.2s ease, border 0.2s ease",
        transform: "translate3d(0,0,0)"
    }),
    input: () => ({
      width: 'auto',
    }),
    valueContainer: base => ({
      ...base,
      padding: 0,
      paddingLeft: 5
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: (base, state) => {
      const transform = state.isFocused ? "rotate(180deg)" : "rotate(0deg)";
      const transition = state.isFocused ? "transform 0.2s" : "transform 0.2s";
      return {...base, transform, transition }
    },
    placeholder: () => ({
      color: "var(--ref-greyscale-600)",
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '-0.0014em',
    })
  };

export default customSelectStyles;