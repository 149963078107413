import React from 'react'
import getCurrencySymbol from '../../utils/getCurrencySymbol'
import {FormattedMessage} from 'react-intl'

const renderPriceString = (
  {
    intl,
    minPrice,
    maxPrice,
    priceUnit
  }
) => {
  let numericString = `${intl.formatNumber(minPrice)}`

  if(!isNaN(minPrice) && !isNaN(maxPrice) && minPrice !== maxPrice) {
    numericString = `${intl.formatNumber(minPrice)} - ${intl.formatNumber(maxPrice)}`
  }

  return <span>
      {numericString}
    &nbsp;
    {getCurrencySymbol(priceUnit)}/
      <FormattedMessage
        id={'RfpResults.PriceUnitSingular'}
        defaultMessage={'ton'}
      />
    </span>
}

const renderVolumeString = (
  {
    intl,
    minVolume,
    maxVolume
  }
) => {
  let numericString = `${intl.formatNumber(minVolume)}`

  if(!isNaN(minVolume) && !isNaN(maxVolume) && minVolume !== maxVolume) {
    numericString = `${intl.formatNumber(minVolume)} - ${intl.formatNumber(maxVolume)}`
  }

  return <span>
      {numericString}
    &nbsp;
    <FormattedMessage
      id={'RfpResults.VolumeUnitTons'}
      defaultMessage={'tons'}
    />
    </span>
}

export {
  renderPriceString,
  renderVolumeString
}