import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import ForestSection from '../UI/ForestSection';
import ForestTitle from '../UI/ForestTitle';
import ForestTeamMember from '../UI/ForestTeamMember';
import Text from '../../UI/Text/Text';

import './Community.css';
import ReadMore from '../../UI/ReadMore';
import SpeciesItem from '../UI/SpeciesItem';
import { READ_MORE_CUT_LENGTH, READ_MORE_MIN_LENGTH } from '../../../constants/readMoreForestInfo';

const Community = ({forestName, team, openGallery, community, species, carbonCalculation}) => {
  const [ mainImage, thumbnailFirst, thumbnailSecond ] = (community || {images: []}).images;

  return (
    <div>
      {carbonCalculation && carbonCalculation.title && carbonCalculation.text && (carbonCalculation.text.length > 0) &&
        <ForestSection>
          <ForestTitle>
            {carbonCalculation.title}
          </ForestTitle>
          <Text color="light" tag="p" weight="regular">
            <ReadMore text={carbonCalculation.text} isHtml cutLength={READ_MORE_MIN_LENGTH} minLength={READ_MORE_CUT_LENGTH}/>
          </Text>
        </ForestSection>
      }
      {species.length > 0 &&
        <ForestSection>
          <ForestTitle badge={species.length}>
            <FormattedMessage 
              id={'ForestDetails.availableSpecies'}
              defaultMessage={'Available species'} 
              />
          </ForestTitle>

          <div className="forest-info__species-list">
            {species.map(speciesItem => (
              <SpeciesItem
                key={speciesItem.id}
                name={speciesItem.name}
                latin={speciesItem.latin_name}
                image={speciesItem.icon}
              />
            ))}
          </div>
        </ForestSection>
      }
      {community && community.text && (community.text.length > 0) && <ForestSection>
        <ForestTitle>
          <FormattedMessage id={'ForestDetails.communityTitle'} defaultMessage={'The Community’s role'} />
        </ForestTitle>

        {mainImage && (
          <div className="community__images-container">
            <img
              src={mainImage.URI}
              className={cx(
                'community__main-image',
                community.images && (community.images.length < 2) && 'community__main-image--full'
              )}
              onClick={openGallery(0)}
              alt="area and surroundings"
            />

            {thumbnailFirst && thumbnailSecond && (
              <div className="community__thumbnails-container">
                <img src={thumbnailFirst.URI} className="community__thumbnail-image" onClick={openGallery(1)} alt="area and surroundings" />
                <img src={thumbnailSecond.URI} className="community__thumbnail-image" onClick={openGallery(2)} alt="area and surroundings" />
              </div>
            )}
          </div>
        )}

        {community && community.text && <Text color="light" tag="p" weight="regular">
          <ReadMore text={community.text} isHtml cutLength={READ_MORE_MIN_LENGTH} minLength={READ_MORE_CUT_LENGTH}/>
        </Text>}
      </ForestSection>}

      {team && (team.length > 0) && <ForestSection>
        <ForestTitle badge={team.length}>
          <FormattedMessage id={'ForestDetails.forestTeamTitle'} defaultMessage={'{forest} team'} values={{forest: forestName}} />
        </ForestTitle>
        <div className="community__team-wrapper">
          {team.map(member => <ForestTeamMember className="community__team-member" key={member.id} member={member} />)}
        </div>
      </ForestSection>}
    </div>
  );
};

Community.propTypes = {
  team: PropTypes.array,
  forestName: PropTypes.string,
  mainImage: PropTypes.string,
  openGallery: PropTypes.func,
  community: PropTypes.object,
  species: PropTypes.array.isRequired,
  carbonCalculation: PropTypes.object,
};

export default Community;
