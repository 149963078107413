import actionTypes from '../constants/actionTypes';

const initialState = {
  isOpen: false,
  type: null,
  meta: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MODAL_SHOW:
      return {
        type: action.payload.type,
        meta: action.payload.meta,
        isOpen: true,
      }

    case actionTypes.MODAL_HIDE:
      return {
        type: null,
        meta: null,
        isOpen: false,
      }
    
    default:
      return state
  }
};
