import React from 'react';

const ArrowDownNoCircle = props => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M8 3.49996V12.5" stroke="#1F3B21" stroke-width="1.2" stroke-linejoin="round"/>
<path d="M12.4998 8L7.99985 12.5L3.49985 8" stroke="#1F3B21" stroke-width="1.2"/>
</svg>
)


export default ArrowDownNoCircle;
