import React from 'react';

const ArrowRightSimple = props => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<circle cx="12" cy="12" r="11.5" fill="white" stroke="#A5B1A6"/>
<path d="M10 8L14.5 12.5L10 17" stroke="#1F3B21" stroke-width="1.2"/>
</svg>
)


export default ArrowRightSimple;
