import * as fromActivationUser from '../reducers/activationUser';

export const getIsFetching = state =>
  fromActivationUser.getIsFetching(state.activationUser);

export const getUserActivationDetails = state =>
  fromActivationUser.getUserActivationDetails(state.activationUser);

export const getAccountActivationError = state =>
  fromActivationUser.getAccountActivationError(state.activationUser);
