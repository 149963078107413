import actionTypes from '../constants/actionTypes';
import { axiosV1Instance } from './';
import api from '../constants/api';
import { getIsPaying, getIsCryptoPaying, getIsFetchingPrice } from '../selectors/checkout';

export const toggleBillingPanel = () => ({
  type: actionTypes.CHECKOUT_TOGGLE_BILLING
});

export const resetCheckout = () => ({
  type: actionTypes.CHECKOUT_RESET
});

export const switchPaymentType = () => ({
  type: actionTypes.SWITCH_PAYMENT_TYPE
});

export const getCalculatedPrice = (forest, quantity, gateway) => ({
  shouldCallAPI: (state, gateway) => {
    return !getIsFetchingPrice(state);
  },
  callAPI: () => {
    return axiosV1Instance.post(
    api.GET_PRICE,
    {
      forest_id: forest,
      quantity,
      gateway
    },
    { withCurrencyHeader: true });
  },
  actions: [
    actionTypes.PRICE_START,
    {
      type: actionTypes.PRICE_COMPLETE,
      payload: response => {
        return response.data.data;
      }
    },
    {
      type: actionTypes.PRICE_ERROR,
      payload: response => response.error
    }
  ]
});

export const makeCryptoPayment = (coin, quantity, forestId, selectedSources) => ({
  shouldCallAPI: state => !getIsCryptoPaying(state),
  callAPI: (state) => {
    return axiosV1Instance.post(
    api.CRYPTO_PAYMENT,
    {
      forest_id: forestId,
      amount: Number(quantity.toFixed(2)),
      co2_sources: selectedSources
    },
    { withCurrencyHeader: true });
  },
  actions: [
    actionTypes.CRYPTO_CHECKOUT_PAY_START,
    {
      type: actionTypes.CRYPTO_CHECKOUT_PAY_COMPLETE,
      payload: (response) => {
        window.location.replace(response.data.data.hosted_url);
        return response.data.data;
      }
    },
    {
      type: actionTypes.CRYPTO_CHECKOUT_PAY_ERROR,
      payload: (response) => response.error
    }
  ]
});

export const makePayment = (
  stripe,
  total,
  quantity,
  forestId,
  selectedSources
) => (dispatch, getState) => {
  // If already making a payment, return early
  const state = getState();
  if (getIsPaying(state)) {
    return false;
  }

  // start the payment
  dispatch({
    type: actionTypes.CHECKOUT_PAY_START
  });

  // Get the session id
  axiosV1Instance.post(
    api.STRIPE_INIT,
    {
      "forest_id": forestId,
      "quantity": Number(quantity.toFixed(2)),
      "co2_sources": selectedSources 
    },
    { withCurrencyHeader: true }
  ).then((response) => {
    const sessionId = response.data.data.stripe_session_id;
    stripe.redirectToCheckout({
      sessionId: sessionId
    });
  }, error => {
    dispatch({
      type: actionTypes.CHECKOUT_PAY_ERROR,
      error: error.response.data.error.message
    });
  });
};
