import React from 'react'
import { FormattedMessage } from 'react-intl';

import withAuthProvider from '../../utils/social-auth/withAuthProvider'

import FacebookLogo from '../UI/Icons/FacebookLogo'

class FacebookButton extends React.PureComponent {
  render() {
    const { handleLogin, isDisabled } = this.props

    return (
      <button
        className="social-button social-button--facebook"
        type="button"
        onClick={handleLogin}
        disabled={isDisabled}
        style={{ cursor: isDisabled ? "not-allowed" : null}}
      >
        <FacebookLogo className="social-button__icon social-button__icon--facebook" />
        <span className="social-button__text">
          <FormattedMessage 
            id="Login.withFacebook" 
            defaultMessage="Login with Facebook"
          />
        </span>
        {/* fb-post is class used by tarteaucitron to show/hide allow button */}
        <div className="fb-post social-button__allow" />
      </button>
    )
  }
}

export default withAuthProvider(FacebookButton, 'facebook')
