import React from 'react';
import PropTypes from 'prop-types';

import './Widget.css';
import { Component } from 'react';
import ReactTable from 'react-table';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { getFormattedCredits } from '../../../utils/units';
import FormattedUnit from '../../Utils/FormattedUnit';
import { format } from 'date-fns'; 
import BACKEND_LOCALES from '../../../constants/backendLocales';
import HorizontalScrollWithArrowsContainer from '../../UI/HorizontalScrollWIthArrowsContainer/HorizontalScrollWIthArrowsContainer';
import { Button } from '@reforestum/shared-components';
import { posthog } from 'posthog-js';

const VINTAGES_PREVIEW_LENGTH = 2

class VintagesWidget extends Component {

    constructor(props) {
        super(props)

        const {vintages} = this.props
        const shouldShowCollapsableButton = vintages.length > VINTAGES_PREVIEW_LENGTH
        this.state = {
          collapsed: shouldShowCollapsableButton,
          shouldShowCollapsableButton: shouldShowCollapsableButton,
        }

        this.getVintagesColumns = this.getVintagesColumns.bind(this)
        this.vintagesExpandClick = this.vintagesExpandClick.bind(this)
    }

    getVintagesColumns(intl) {
      const {locale} = intl
      const dateLocaleFns = BACKEND_LOCALES.find((backendLocale) => backendLocale.backendKey === locale).dateFnsLocale
      return [
        {
          id: 'vintage_period',
          Header: <FormattedMessage id='transactionsTable.vintagePeriod' defaultMessage='Vintage Period'/>,
          minWidth: 150,
          accessor: row => {
              return (
              <span style={{whiteSpace: 'nowrap'}}>
                  {`${format(row.vintage_start, 'MM/YYYY', {locale: dateLocaleFns})} - ${format(row.vintage_end, 'MM/YYYY', {locale: dateLocaleFns})}`}
              </span>
              )
          }
          },
        {
          id: 'credits_verified',
          Header: <FormattedMessage id='vintagesTable.creditsVerified' defaultMessage='Credits Verified'/>,
          minWidth: 150,
          accessor: row => {
              return (
              <span style={{whiteSpace: 'nowrap'}}>
                  {`${intl.formatNumber(row.credits_verified)} VCUs`}
              </span>
              )
          }
        },
        {
          id: 'percentage_retired',
          Header: <FormattedMessage id='vintagesTable.creditsRetired' defaultMessage='Credits Retired'/>,
          minWidth: 100,
          accessor: row => {
            const percentage = (row.credits_retired / row.credits_verified) * 100
              return (
              <span style={{whiteSpace: 'nowrap'}}>
                  {percentage < 1 ? intl.formatNumber(percentage.toFixed(2)) : percentage.toFixed(0)}{'%'}
              </span>
              )
          }
        }
      ]
    }

    vintagesExpandClick() {
      const {projectId} = this.props
      this.setState({collapsed: false})
      posthog.capture("project_vintages_expand_click", {projectId: projectId})
    }


    render() {
        const {totalVcusVerified, vintages, intl} = this.props;
        const {collapsed, shouldShowCollapsableButton} = this.state
        const projectTotalVcusVerifiedFormatted = getFormattedCredits({credits: totalVcusVerified}) 
        return (
            <div className='windget-container--double'>
              <div className='widget widget-communities'>
                <p className='widget-title'>
                  <FormattedMessage id="VintagesWidget.title" defaultMessage="Credits verified in this project"/>
                </p>
                <p className='widget-text'>
                  <FormattedNumber
                      value={projectTotalVcusVerifiedFormatted.value}
                  /> <FormattedUnit unit={projectTotalVcusVerifiedFormatted.unit} />
                </p>
                <div style={{position: 'relative', margin: '16px 0px 0px'}}>
                  <HorizontalScrollWithArrowsContainer hideScrollbar centered className='widgets-vintages-table-container'>
                    <ReactTable
                      data={collapsed ? vintages.slice(0, VINTAGES_PREVIEW_LENGTH + 1) : vintages}
                      columns={this.getVintagesColumns(intl)}
                      showPagination={false}
                      sortable={false}
                      minRows={0}
                      loadingText={null}
                    />
                  </HorizontalScrollWithArrowsContainer>
                  {collapsed && 
                    <div className='widget-vintages-show-all-container'>
                      <Button onClick={this.vintagesExpandClick} className="btn--blank widget-vintages-show-all-less-button" small>
                        <FormattedMessage
                          id={'VintagesWidget.seeAllVintagesButton'}
                          defaultMessage={'See all vintages'}
                        />
                      </Button>
                    </div>
                  }
                  {shouldShowCollapsableButton && !collapsed &&  
                    <div className='widget-vintages-show-less-container'>
                      <Button onClick={() => this.setState({collapsed: true})} className="btn--blank widget-vintages-show-all-less-button" small>
                        <FormattedMessage
                          id={'VintagesWidget.seeLessVintagesButton'}
                          defaultMessage={'See less vintages'}
                        />
                      </Button>
                    </div>
                  }
                </div>
              </div>
            </div>
        );
    }
}

VintagesWidget.propTypes = {
  intl: PropTypes.object,
  totalVcusVerified: PropTypes.number,
  vitages: PropTypes.array,
  projectId: PropTypes.number,
};

export default injectIntl(VintagesWidget);
