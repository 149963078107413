import React from 'react';
import PropTypes from 'prop-types';

import './Widget.css';
import Tooltip from '../../UI/Tooltip/Tooltip';
import { Component } from 'react';
import { getProjectUrl, getPublicProfileUrl } from '@reforestum/shared-utils/src/links';
import ArrowRight from '../../UI/Icons/ArrowRight';
import ArrowRightActive from '../../UI/Icons/ArrowRightActive';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import AvatarPlaceholder from '@reforestum/shared-components/dist/assets/images/avatar-placeholder.png'

const MAX_CONTRIBUTORS_TO_SHOW = 6

class ContributorsWidget extends Component {

    constructor(props) {
        super(props)

        this.state = {
            buttonHovered: false,
        }
    }
    render() {
        const {topContributors, totalContributors, forestId, renderedFromProjectDetails, match} = this.props;
        const {buttonHovered} = this.state;
        return (
            <div className='widget-container'>
              <div className='widget widget-communities'>
                <p className='widget-title'>
                  <FormattedMessage id="ContributorsWidget.title" defaultMessage="Contributors"/>
                </p>
                <p className='widget-text'>{totalContributors} {totalContributors === 1 ? 
                  <FormattedMessage id="CommunitiesWidget.organization" defaultMessage="organization"/> : 
                  <FormattedMessage id="CommunitiesWidget.organizations" defaultMessage="organizations"/>}
                </p>
                <div className='widget-communities-orgs-container'>
                  {topContributors && ((totalContributors - MAX_CONTRIBUTORS_TO_SHOW) > 0) &&
                    <Link 
                      className='widget-communities-org' 
                      to={
                        renderedFromProjectDetails ?
                          `${match.url.slice(0, match.path.slice(0).indexOf('/:'))}/contributors` :
                          `${getProjectUrl(forestId)}/contributors`
                      }
                    >
                      <div className="widget-communities-org-rest-orgs">
                        <p>+{totalContributors - MAX_CONTRIBUTORS_TO_SHOW}</p>
                      </div>
                    </Link>
                  }
                   {topContributors && topContributors
                   .slice(0, 6)
                   //this reverse is used because from a style point of view, companies are rendered from left to right. Using float right.
                   .reverse()
                   .map((contributor) =>
                    <Link target="_blank" className='widget-communities-org' to={getPublicProfileUrl(contributor.public_profile_slug)}>
                        <Tooltip
                            position="top-right"
                            content={contributor.name}>
                              <img
                                  src={contributor.avatar_url || AvatarPlaceholder}
                                  alt={`org logo for contributor.name`}
                                  className="widget-communities-org-img"
                              /> 
                        </Tooltip>
                    </Link>
                   )}
                </div>
                <Link
                    to={
                      renderedFromProjectDetails ?
                      `${match.path.slice(0, match.path.lastIndexOf('/:'))}/contributors` :
                        `${getProjectUrl(forestId)}/contributors`
                    }
                    className='widget-button' 
                    onMouseEnter={() => this.setState({buttonHovered: true})}
                    onMouseLeave={() => this.setState({buttonHovered: false})}
                    >
                        <FormattedMessage id="ContributorsWidget.button" defaultMessage="All project contributors"/>{buttonHovered ? <ArrowRightActive  classname='widget-button-arrow'/> : <ArrowRight classname='widget-button-arrow'/>}
                </Link>
              </div>
            </div>
        );
    }
}

ContributorsWidget.propTypes = {
  topContributors: PropTypes.array,
  totalContributors: PropTypes.number,
  forestId: PropTypes.number,
  renderedFromProjectDetails: PropTypes.bool,
};

export default withRouter(ContributorsWidget);
