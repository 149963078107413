import React from 'react';

const NeutralizeFootprintLinkIcon = props => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<circle cx="24" cy="24" r="24" fill="#D7E8CF"/>
<path d="M24.875 27.125L32.75 19.25M23.125 27.125L15.25 19.25" stroke="#8DB99D" stroke-width="1.2" stroke-linecap="square"/>
<path d="M10 14V13.4H9.4V14H10ZM23.9999 27.4758V28.0758H24.5999V27.4758H23.9999ZM38 14H38.6V13.4H38V14ZM24.0002 27.4758H23.4002V28.0758H24.0002V27.4758ZM10 14.6H14.2V13.4H10V14.6ZM10.6 18.0427V14H9.4V18.0427H10.6ZM19.7999 26.8758C14.6972 26.8758 10.6 22.8997 10.6 18.0427H9.4C9.4 23.6052 14.078 28.0758 19.7999 28.0758V26.8758ZM23.9999 26.8758H19.7999V28.0758H23.9999V26.8758ZM23.3999 23.4331V27.4758H24.5999V23.4331H23.3999ZM14.2 14.6C19.3027 14.6 23.3999 18.5761 23.3999 23.4331H24.5999C24.5999 17.8706 19.9219 13.4 14.2 13.4V14.6ZM38 13.4H33.8V14.6H38V13.4ZM38.6 18.0427V14H37.4V18.0427H38.6ZM28.2001 28.0758C33.922 28.0758 38.6 23.6052 38.6 18.0427H37.4C37.4 22.8997 33.3028 26.8758 28.2001 26.8758V28.0758ZM24.0002 28.0758H28.2001V26.8758H24.0002V28.0758ZM23.4002 23.4331V27.4758H24.6002V23.4331H23.4002ZM33.8 13.4C28.0781 13.4 23.4002 17.8706 23.4002 23.4331H24.6002C24.6002 18.5761 28.6973 14.6 33.8 14.6V13.4Z" fill="#1F3B21"/>
<path d="M23.9995 24.6591V37.3863" stroke="#1F3B21" stroke-width="1.2" stroke-linecap="square"/>
</svg>






export default NeutralizeFootprintLinkIcon;
