import React from 'react';

const Carbon = props => <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
	<path d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4Z" fill="#F2F2F0"/>
	<path d="M17.0232 29.5C18.5808 29.5 19.8436 28.2037 19.8436 26.6047C19.8436 25.0056 18.5808 23.7093 17.0232 23.7093C15.4656 23.7093 14.2029 25.0056 14.2029 26.6047C14.2029 28.2037 15.4656 29.5 17.0232 29.5Z" stroke="#1F3B21" strokeWidth="1.2" strokeMiterlimit="10"/>
	<path d="M24.4042 23.7092C26.9229 23.7092 28.9647 21.6203 28.9647 19.0436C28.9647 16.4668 26.9229 14.378 24.4042 14.378C21.8854 14.378 19.8436 16.4668 19.8436 19.0436C19.8436 21.6203 21.8854 23.7092 24.4042 23.7092Z" stroke="#1F3B21" strokeWidth="1.2" strokeMiterlimit="10"/>
	<path d="M19.1909 24.759L21.3737 22.5237" stroke="#1F3B21" strokeWidth="1.2" strokeMiterlimit="10"/>
	<path d="M16.273 14.8203L20.2335 17.168" stroke="#1F3B21" strokeWidth="1.2" strokeMiterlimit="10"/>
	<path d="M13.8204 16.2907C15.378 16.2907 16.6407 14.9944 16.6407 13.3954C16.6407 11.7963 15.378 10.5 13.8204 10.5C12.2627 10.5 11 11.7963 11 13.3954C11 14.9944 12.2627 16.2907 13.8204 16.2907Z" stroke="#1F3B21" strokeWidth="1.2" strokeMiterlimit="10"/>
</svg>


export default Carbon;
