import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import {
  FormattedMessage,
  injectIntl,
  intlShape
} from 'react-intl';

import { authMessages } from '../../constants/messages';

import { required, email } from '../../utils/formHelpers';
import { withIntl } from '../../utils/intl';

import FeedbackMessage from '../UI/Forms/FeedbackMessage';
import Input from '../UI/Forms/Input';
import Row from '../UI/Forms/Row';
import RowAligner from '../UI/Forms/RowAligner';
import { Button } from '@reforestum/shared-components';
import { DisabledButton } from '@reforestum/shared-components';
import Small from '../UI/Text/Small';

import './Login.css';

const RecoveryForm = ({
  handleSubmit,
  handleRecovery,
  handleRegisterRedirection,
  errorMessages,
  isLoading,
  isRecoveryRequested,
  invalid,
  intl
}) => (
  <form onSubmit={handleSubmit(handleRecovery)}>
    {
      errorMessages && !isRecoveryRequested &&
      <FeedbackMessage type="error">{errorMessages}</FeedbackMessage>
    }
    {
      isRecoveryRequested &&
      <FeedbackMessage type="success">
        {intl.formatMessage(authMessages.recoverySuccess)}
      </FeedbackMessage>
    }
    <Row>
      <Field
        name="email"
        disabled={isRecoveryRequested}
        component={Input}
        placeholder={intl.formatMessage(authMessages.emailPlaceholder)}
        label={intl.formatMessage(authMessages.emailLabel)}
        type="email"
        validate={withIntl(intl, [email, required])}
      />
    </Row>

    <Row>
      <RowAligner>
        {
          isRecoveryRequested ?
          <DisabledButton>
            <FormattedMessage
              id={'Login.checkInbox'}
              defaultMessage={'Check your inbox'}
            />
          </DisabledButton> :
          <Button
            loading={isLoading}
            type="submit"
            className='login__button'
            disabled={invalid}
          >
            <FormattedMessage
              id={'Login.recoverButton'}
              defaultMessage={'Recover password'}
            />
          </Button>
        }
      </RowAligner>
    </Row>
    <Row>
      <div className='new-cta__wrapper'>
        <Small weight={'regular'}>
          <FormattedMessage 
            id={'Common.noAccount'}
            defaultMessage={"Don't have an account?"}
          />
          <span
            onClick={handleRegisterRedirection}
            style={{ color: 'var(--ref-green-300)', marginLeft: '0.3rem', cursor: 'pointer' }}>
            <FormattedMessage
              id={'Header.register'}
              defaultMessage={'Register'}
            />
          </span>
        </Small>
      </div>
    </Row>
  </form>
);

RecoveryForm.propTypes = {
  className: PropTypes.string,
  handleRecovery: PropTypes.func.isRequired,
  errorMessages: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isRecoveryRequested: PropTypes.bool.isRequired,
  /* redux form */
  handleSubmit: PropTypes.func.isRequired,
  /* intl */
  intl: intlShape.isRequired,
};

export default injectIntl(reduxForm({
  form: 'recovery'
})(RecoveryForm));
