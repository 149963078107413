import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';


import { Button } from '@reforestum/shared-components';
import Close from '../Icons/Close';
import Overlay from '../Overlay/Overlay';
import Text from '../Text/Text';

import './ModalDefault.css';

const ModalFirstSource = ({
  hideModal,
  imageSrc,
  imageAlt = '',
  onConfirm = () => {},
  onReject = () => {},
  hideAfterConfirm = true,
  hideAfterReject = true,
  iconComponent,
  footerTextComponent,
  location,
}) => {
  const handleConfirm = hideAfterConfirm
    ? () => { onConfirm(); hideModal(); }
    : onConfirm;

  const handleReject = hideAfterReject
    ? () => { onReject(); hideModal(); }
    : onReject;

  const Icon = iconComponent;
  const FooterText = footerTextComponent;
  return (
    <Overlay wrapperClassName="modal-default" onClick={hideModal}>
      <div className="modal-default__box">
        {imageSrc && (
          <img src={imageSrc} className="modal-default__image" alt={imageAlt} />
        )}

        {iconComponent && <Icon className="modal-default__icon" />}
          <Text tag="h2" className="modal-default__title">
            <FormattedMessage 
              id="ModalFirstSource.title"
              defaultMessage={'Congrats, you’ve added your first CO₂ source!'}
            />
          </Text>

          <Text tag="p" color="light" className="modal-default__text">
            <FormattedMessage 
              id="ModalFirstSource.text"
              defaultMessage={'Create an account to save your carbon footprint and update it anytime.'}
            />
          </Text>
          <footer className="modal-default__footer">
            <div className="modal-default__footer-buttons">
                <Button className="btn--blank" onClick={handleReject}>
                  <FormattedMessage 
                    id="ModalFirstSource.cancelButtonText"
                    defaultMessage={'No, thanks'}
                  />
                </Button>
                <Button onClick={handleConfirm}>
                  <FormattedMessage 
                    id="ModalFirstSource.confirmButtonText"
                    defaultMessage={'Join Reforestum'}
                  />
                </Button>
            </div>
              <div className="modal-default__footer-link">
                <FooterText hideModal={hideModal} location={location} />
              </div>
          </footer>

      </div>
      <Close className="modal-default__close" onClick={hideModal} />
    </Overlay>
  );
};

ModalFirstSource.propTypes = {
  hideModal: PropTypes.func.isRequired,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  buttonLinkProps: PropTypes.object,
  hideAfterConfirm: PropTypes.bool,
  iconComponent: PropTypes.func,
  footerTextComponent: PropTypes.func,
};

export default withRouter(ModalFirstSource);
