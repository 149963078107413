import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { links } from '@reforestum/shared-utils';

import Wrapper from '../components/Layout/Wrapper/Wrapper';
import SignupContainer from '../containers/SignupContainer';
import MobileTopbar from '../components/Header/MobileTopbar/MobileTopbar';

const Register = (props) => {
  return (
    <Wrapper>
      <Helmet>
        <title>
          Register | Reforestum
        </title>
        <link rel="canonical" href={`https://app.reforestum.com${links.getRegisterUrl()}`} />
        <meta name="description" content="Together we can curb climate change through massive reforestation!  Sign up now to use our free carbon footprint calculator and create & track your forests." />
      </Helmet>
      <MobileTopbar>
        <FormattedMessage
          id={'Header.register'}
          defaultMessage={'Register'}
        />
      </MobileTopbar>
      <SignupContainer {...props} />
    </Wrapper>
  );
};

export default Register;
