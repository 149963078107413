import React from 'react';
import PropTypes from 'prop-types';

import './IframeResizableFullPageContainer.css'

const IframeResizableFullPageContainer = ({ children, noMarginTop, noScroll }) => {
    return <div className={`iframe-resizable-full-page-container ${noMarginTop && 'iframe-resizable-full-page-container--no-margin-top'} ${noScroll && 'iframe-resizable-full-page-container--no-scroll'}`}>{children}</div>
};

IframeResizableFullPageContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]).isRequired
};

export default IframeResizableFullPageContainer;
