import React from 'react'

import './Dropdown.css'

class Dropdown extends React.PureComponent {
  render() {
    const {children} = this.props;
    return <div className='dropdown'>{children}</div>
  }
}

export default Dropdown
