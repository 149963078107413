import React from 'react'
import cx from 'classnames'

import './Divider.css'

class Divider extends React.PureComponent {
  render() {
    return <div className={cx('divider', this.props.className)}>
      <span className="divider__text">{this.props.text}</span>
    </div>
  }
}

export default Divider
