import React from 'react';

const GlobeSimple = props => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M11.9987 21.7312C14.2999 21.7312 16.1654 17.3742 16.1654 11.9996C16.1654 6.62503 14.2999 2.26807 11.9987 2.26807C9.69751 2.26807 7.83203 6.62503 7.83203 11.9996C7.83203 17.3742 9.69751 21.7312 11.9987 21.7312Z" stroke="#A5B1A6" strokeWidth="1.2" strokeMiterlimit="10"/>
		<path d="M2 12.0002H22" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#1F3B21" strokeWidth="1.2" strokeMiterlimit="10"/>
	</svg>

);

export default GlobeSimple;
