import * as React from 'react'
import { formatUnit } from '../../utils/units'
import { injectIntl, intlShape } from 'react-intl'
import PropTypes from 'prop-types'

class FormattedUnit extends React.Component {

  static propTypes = {
    intl: intlShape.isRequired,
    unit: PropTypes.string.isRequired
  }

  render = () => {

    const { intl, unit } = this.props

    return <span>{ formatUnit(intl, unit) }</span>
  }
}

export default injectIntl(FormattedUnit)