import React, { Component } from "react"
import {queryBeZero, queryBeZeroRatingOnly} from "../../Utils/queryBeZero"
import Loader from "../../UI/Loader/Loader"
import './BeZero.css'

import BeZeroPieChart from "./BeZeroPieGraph"
import BeZeroImage from './BeZero.png'
import {FormattedMessage, injectIntl} from "react-intl"
import {BeZero as BeZeroTexts} from "../../../constants/messages"
import PaywallBanner from "../../Widgets/PaywallBanner/PaywallBanner"
import { BEZERO_DATA_TEMPLATE, BEZERO_SAMPLE_DATA} from "../../../constants/bezero"
import {connect} from 'react-redux'
import {getIsUserAuthenticated, getUserDetails} from '../../../selectors/userSession'

class BeZero extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loadingBeZeroInfo: false,
			beZeroData: null,
			documentWidth: window.innerWidth,
			useCtaMode: false,
			unassessedRiskFactors: {}
		}
	}

	async getBeZeroInfoForProject() {
		this.setState({ loadingBeZeroInfo: true })
		let { result } = await queryBeZero(this.props.projectId)
		if (result) {
			result = {
				...result,
				riskSummaries: {
					additionalityPercentage: (result.riskSummaries.find(e => e.name === 'Additionality') || {score: null}).score,
					overCreditingPercentage: (result.riskSummaries.find(e => e.name === 'Over-crediting') || {score: null}).score,
					leakagePercentage: (result.riskSummaries.find(e => e.name === 'Leakage') || {score: null}).score,
					permanencePercentage: (result.riskSummaries.find(e => e.name === 'Non-permanence') || {score: null}).score,
					policyEnvironmentPercentage: (result.riskSummaries.find(e => e.name === 'Political/Policy') || {score: null}).score,
					perverseIncentives: (result.riskSummaries.find(e => e.name === 'Perverse Incentives') || {score: null}).score,
				}
			}
			let unassessedRiskFactors = {}
			Object.keys(result.riskSummaries).forEach(
				k => {
					if(result.riskSummaries[k] === null) {
						unassessedRiskFactors = {...unassessedRiskFactors, [k]: true}
					}
				}
			)
			this.setState( {unassessedRiskFactors} )
			result = {
				...result,
				riskSummaries: {
					additionalityPercentage: result.riskSummaries.additionalityPercentage || 0,
					overCreditingPercentage: result.riskSummaries.overCreditingPercentage || 0,
					leakagePercentage: result.riskSummaries.leakagePercentage || 0,
					permanencePercentage: result.riskSummaries.permanencePercentage || 0,
					policyEnvironmentPercentage: result.riskSummaries.policyEnvironmentPercentage || 0,
					perverseIncentives: result.riskSummaries.perverseIncentives || 0,
				}
			}
			this.setState({ beZeroData: result })
		}
		else {
			await this.getBeZeroRatingOnly()
			this.setState({ useCtaMode: true })
		}
		this.setState({ loadingBeZeroInfo: false })
	}

	async getBeZeroRatingOnly() {
		this.setState({ loadingBeZeroInfo: true })
		let { result } = await queryBeZeroRatingOnly(this.props.projectId)
		if (result) {
			this.setState({ beZeroData: result })
		}
		this.setState({ loadingBeZeroInfo: false })
	}

	componentDidMount() {
		if (this.props.projectId) {
			window.addEventListener('resize', () => this.updateDocumentWidth())
			this.getBeZeroInfoForProject()
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.projectId && prevProps.projectId !== this.props.projectId) {
			this.getBeZeroInfoForProject()
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', () => this.updateDocumentWidth())
	}

	updateDocumentWidth() {
		this.setState({ documentWidth: window.innerWidth })
	}

	getPieLabelsFromBeZeroData(beZeroData) {
		const {intl} = this.props
		let result = []
		if(beZeroData && beZeroData.riskSummaries) {
			Object.keys(beZeroData.riskSummaries).forEach(
				datumName => {
					if(BEZERO_DATA_TEMPLATE[datumName] && beZeroData && beZeroData.riskSummaries && !isNaN(beZeroData.riskSummaries[datumName])) {
						result.push({
							name: datumName,
							label: intl.formatMessage(BeZeroTexts[datumName]),
							value: beZeroData.riskSummaries[datumName],
							color: this.state.useCtaMode ? BEZERO_DATA_TEMPLATE[datumName].altColor : BEZERO_DATA_TEMPLATE[datumName].color
						})
					}
				}
			)
		}
		return result
	}

	getBeZeroRatingLabel(r) {
		const {intl} = this.props
		let result = ''
		if(r === 0) {
			result = intl.formatMessage(BeZeroTexts.ratingNotAssessed)
		}
		else if(r <= 0.3) {
			result = intl.formatMessage(BeZeroTexts.ratingSignificantRisk)
		}
		else if(r <= 0.5) {
			result = intl.formatMessage(BeZeroTexts.ratingNotableRisk)
		}
		else if(r <= 0.7) {
			result = intl.formatMessage(BeZeroTexts.ratingSomeRisk)
		}
		else if(r <= 0.9) {
			result = intl.formatMessage(BeZeroTexts.ratingLittleRisk)
		}
		else if(r <= 1) {
			result = intl.formatMessage(BeZeroTexts.ratingVeryLowRisk)
		}
		return result
	}

	renderPieLegend(legendData) {
		const {showLabelValues} = this.props
		return (<div className={'be-zero__pie-chart__legend'}>
			{
				(legendData || []).map(
					ld => (<div className={'be-zero__pie-chart__legend__group'}>
						<div className={'be-zero__pie-chart__legend__group__color-and-value'}>
							<div className={'be-zero__pie-chart__legend__group__color'} style={{backgroundColor: ld.color}}>
							</div>
							{
								showLabelValues && <div className={'be-zero__pie-chart__legend__group__value'}>
									{this.getBeZeroRatingLabel(ld.value)}
								</div>
							}
						</div>
						<div className={'be-zero__pie-chart__legend__group__label'}>
							{ld.label}
						</div>
					</div>)
				)
			}
		</div>)
	}

	renderPieChart() {
		const {beZeroData, useCtaMode} = this.state
		const data = useCtaMode ? BEZERO_SAMPLE_DATA : beZeroData
		const transformedRiskSummaries = this.getPieLabelsFromBeZeroData(data)
		return <BeZeroPieChart transformedRiskSummaries={transformedRiskSummaries}/>
	}

	renderCtaModeCta() {
		return <PaywallBanner
			title={
				<FormattedMessage id="BeZeroWidget.ctaTitle" defaultMessage="Insights about project risk factors"/>
			}
			description={
				this.userIsSignedIn()
					? <FormattedMessage id="BeZeroWidget.ctaDescription" defaultMessage="Reforestum has partenered with BeZero to bring you the most trusted, independently appraised risk factor evaluations of Carbon Projects. Talk to our team today to Get Access."/>
					: <FormattedMessage id="BeZeroWidget.ctaAuthWallDescription" defaultMessage="Reforestum has partenered with BeZero to bring you independent risk factor evaluations of Carbon Projects. Log in to see the BeZero summary for this project, or talk to our team today to get full access."/>
			}
			buttonText={
				<FormattedMessage id="BeZeroWidget.getAccessButton" defaultMessage="Get Access"/>
			}
			buttonLink={'https://reforestum.com/contact-us'}
		/>
	}

	renderDataIntroduction() {
		const {intl} = this.props
		return <div className={'be-zero__data-introduction'}>
			<h1>{intl.formatMessage(BeZeroTexts.beZeroDataTitle)}</h1>
			<p>{intl.formatMessage(BeZeroTexts.beZeroDataDesc)}</p>
		</div>
	}

	renderProjectSummary() {
		const {beZeroData} = this.state
		const data = this.userIsSignedIn() ? beZeroData : BEZERO_SAMPLE_DATA
		const {intl} = this.props
		return <div className={'be-zero__summary__container'}>
			<h1>{intl.formatMessage(BeZeroTexts.beZeroSummaryTitle)}</h1>
			<p className={'be-zero__summary t-main t-main--light t-main--regular'}>
				{(data.summary || '').split('\n').filter(t => t.length > 0).map(t => <p>{t}</p>)}
			</p>
		</div>
	}

	renderDataReferences() {
		const {intl} = this.props
		return <div className={'be-zero__data-references'}>
			<h1>{intl.formatMessage(BeZeroTexts.dataReferencesTitle)}</h1>
			<ul>
				<li><a target="_blank" rel="noopener noreferrer" href={'https://bezerocarbon.com/methodology/'}>{intl.formatMessage(BeZeroTexts.dataReferencesMethodology)}</a></li>
				<li><a target="_blank" rel="noopener noreferrer" href={'https://bezerocarbon.com/end-user-disclaimer-usage-guidelines/'}>{intl.formatMessage(BeZeroTexts.endUserDisclaimerAndUsageGuidelines)}</a></li>
			</ul>
		</div>
	}

	userIsSignedIn() {
		const {isAuthenticated} = this.props
		return isAuthenticated
	}

	renderLoadedBeZeroData() {
		const {beZeroData, useCtaMode} = this.state
		const data = useCtaMode ? BEZERO_SAMPLE_DATA : beZeroData
		const {intl, shouldRenderRiskSummaries} = this.props
		return  <div className={'be-zero__data-container'}>
				{
					useCtaMode && this.renderCtaModeCta()
				}
				<div className={useCtaMode ? 'be-zero__split__container__curtain' : ''}>
					{
						!useCtaMode && this.renderDataIntroduction()
					}
					<div className={'be-zero__split__container'}>
						<div className={'be-zero__split be-zero__split__chart'}>
							{shouldRenderRiskSummaries && this.renderPieChart()}
						</div>
						<div className={'be-zero__split'}>
							<div className={'be-zero__image-and-heading'}>
								<img alt={'BeZero logo'} className={'be-zero__image-and-heading__image'} src={BeZeroImage} />
								<h1>{intl.formatMessage(BeZeroTexts.beZeroCarbonRating)}</h1>
							</div>
							<div className={'be-zero__project__rating'}>{data.rating}{data.beZeroIsOnWatch ? <span className={'be-zero__on-watch'}>(rw)</span> : ''}</div>
							<div className={'be-zero__project__name-and-id'}>{data.projectId}</div>
							{this.renderPieLegend(this.getPieLabelsFromBeZeroData(data))}
						</div>
					</div>
					{(data.summary && !this.userIsSignedIn()) && this.renderProjectSummary()}
				</div>
				{(data.summary && this.userIsSignedIn()) && this.renderProjectSummary()}
				{this.renderDataReferences()}
			</div>
	}

	renderMain() {
		const {beZeroData, useCtaMode} = this.state
		return (
			<div className={'be-zero'}>
				{
					((beZeroData && beZeroData.rating) || useCtaMode)
					? this.renderLoadedBeZeroData()
					: <Loader />
				}
			</div>
		)
	}

	render() {
		return this.state.loadingBeZeroInfo ? (
			<Loader />
		) : this.props.projectId ? (
			this.renderMain()
		) : null
	}
}

const mapStateToProps = (state) => {
	return {
		user: getUserDetails(state),
		isAuthenticated: getIsUserAuthenticated(state)
	}
}

export default connect(mapStateToProps, {})(injectIntl(BeZero));