import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from 'react-intl';
import { getFormattedArea } from '../../../utils/units';
import FormattedUnit from '../../Utils/FormattedUnit';
import ForestSection from '../UI/ForestSection';
import ForestTitle from '../UI/ForestTitle';
import MyOtherForests from '../Header/MyOtherForests';
import Text from '../../UI/Text/Text';
import Graph from '../../Widgets/CarbonGraph/Graph';
import MyTreesOwnedBlock from './MyTreesOwnedBlock';
import './MyTrees.css';
import getYearsSinceDate from '../../../utils/getYearsSinceDate';
import {Button} from "@reforestum/shared-components"
import Share from "../../UI/Icons/Share"
import store from "../../../setup/store"
import {addAlert} from "../../../actions/alerts"
import {MyTreesAlerts} from "../../../constants/messages"
import {links} from "@reforestum/shared-utils"
import history from '../../../setup/history'

const MyTrees = (
  {
    forest,
    sector,
    authenticatedUserIsShareOwner,
    authenticatedUserShareInThisSector,
    userTotalSurface,
    userOwnerDisplayName,
    myFilteredOwnedForests,
    userOwnerAvatar,
    intl
  }
) => {
  const surface = getFormattedArea(userTotalSurface);
  const plantingDatePeriod = forest.planting_date ? getYearsSinceDate(Date.parse(forest.planting_date)) : null;

  const { locale } = intl

  const handleClickedShareCTA = () => {
    store.dispatch(addAlert({
      type: 'success',
      message: intl.formatMessage(MyTreesAlerts.sharingLinkCopied),
      dismissAfter: 5000
    }))
    navigator.clipboard.writeText(window.location.href)
  }

  const renderBackToMyShare = () => {
    return <div className={'my-trees__back-to-my-share'}>
      <span
        className={'my-trees__back-to-my-share__link'}
        onClick={() => history.push(links.getProjectUrl(forest.id, sector.sector_id, authenticatedUserShareInThisSector))}
      >
        <FormattedMessage
          id={'MyTrees.backToMyShare'}
          defaultMessage={'View my share in this forest'}
        />
      </span>
    </div>
  }

  const renderShareInfoBlock = () => {
    return <div className={'my-trees__share-info-block'}>
      <div className={'my-trees__share-info-block__avatar-and-info'}>
        <div className={'my-trees__share-info-block__avatar'} style={{backgroundImage: `url('${userOwnerAvatar}')`}}/>
        <div className={'my-trees__share-info-block__offsetting-message'}>
          <h1>
            {
              authenticatedUserIsShareOwner
                ? <FormattedMessage
                  id={'MyTrees.offsettingMessageUserIsShareOwner'}
                  defaultMessage={'You are offsetting your carbon footprint'}
                  values={{
                    userOwnerDisplayName
                  }}
                />
                : <FormattedMessage
                  id={'MyTrees.offsettingMessage'}
                  defaultMessage={'{userOwnerDisplayName} is offsetting their carbon footprint'}
                  values={{
                    userOwnerDisplayName
                  }}
                />
            }
          </h1>
          <p className={'my-trees__share-info-block__sharing-message'}>
            {
              authenticatedUserIsShareOwner
                ? <FormattedMessage
                  id={'MyTrees.sharingMessageUserIsShareOwner'}
                  defaultMessage={'Share this page to encourage others to do the same'}
                  values={{
                    userOwnerDisplayName
                  }}
                />
                : <FormattedMessage
                  id={'MyTrees.sharingMessage'}
                  defaultMessage={'Share this page to celebrate their action, or support the planet by offsetting your carbon footprint'}
                  values={{
                    userOwnerDisplayName
                  }}
                />
            }
          </p>
        </div>
      </div>
      <div className={'my-trees__share-info-block__calls-to-action'}>
        <Button className='button btn--blank' onClick={handleClickedShareCTA}>
          <Share />
          {
            authenticatedUserIsShareOwner
              ? <FormattedMessage
                id={'shareCTAUserIsShareOwner'}
                defaultMessage={'Share your offset'}
              />
              : <FormattedMessage
                id={'MyTrees.shareCTA'}
                defaultMessage={'Support by sharing'}
              />
          }
        </Button>
        <Button className='button' onClick={() => history.push(links.getCalculatorUrl())}>
          {
            authenticatedUserIsShareOwner
            ? <FormattedMessage
                id={'MyTrees.offsetCTAUserIsShareOwner'}
                defaultMessage={'Grow my impact'}
              />
            : <FormattedMessage
                id={'MyTrees.offsetCTA'}
                defaultMessage={'Offset your emissions'}
              />
          }
        </Button>
      </div>
    </div>
  }

  const titleMessageValues = {
    forestName: forest.name,
    surface: <Text color="green" tag="span">
      <FormattedNumber
        value={surface.value}
      /> <FormattedUnit unit={surface.unit} />
    </Text>,
    displayName: userOwnerDisplayName
  }

  return (
    <div>
      {
        (!authenticatedUserIsShareOwner && authenticatedUserShareInThisSector) && renderBackToMyShare()
      }
      {renderShareInfoBlock()}
      <ForestSection>
        <ForestTitle>
          {
            authenticatedUserIsShareOwner
              ? <FormattedMessage
                id={'ForestDetails.myTreesTitle'}
                defaultMessage={'You own {surface} of forest shares in {forestName}'}
                values={titleMessageValues}
              />
            : <FormattedMessage
                id={'ForestDetails.theirTreesTitle'}
                defaultMessage={'{displayName} owns {surface} of forest shares in {forestName}'}
                values={titleMessageValues}
              />
          }
        </ForestTitle >
        <MyTreesOwnedBlock forest={forest} user_total_surface={userTotalSurface} />
      </ForestSection>

      { forest.timeline_formula &&
        <ForestSection>
          <ForestTitle>
            {
              authenticatedUserIsShareOwner
              ? <FormattedMessage
                  id={'ForestDetails.yourForestCo2Capture'}
                  defaultMessage={'Your forest CO₂ Capture'}
                />
              : <FormattedMessage
                  id={'ForestDetails.thisShareCo2Capture'}
                  defaultMessage={`This share's CO₂ Capture`}
                />
            }
          </ForestTitle>
          {authenticatedUserIsShareOwner ? 
            <div className='my-shares__graph-subtitle'>
              <FormattedMessage
                id={'ForestDetails.shareGraphSubtitle'}
                defaultMessage={'The following graph shows the estimated carbon captured of your forest share during the project duration. You can hover or click on the graph to see the estimated carbon captured over time.'}
              />
            </div>
            :
            <div className='my-shares__graph-subtitle'>
              <FormattedMessage
                id={'ForestDetails.othersShareGraphSubtitle'}
                defaultMessage={'The following graph shows the estimated carbon captured of {userOwnerDisplayName} forest share during the project duration. You can hover or click on the graph to see the estimated carbon captured over time.'}
                values={{userOwnerDisplayName}}
              />
            </div>
          }
          <Graph
            className="forest-info__graph"
            years={forest.co2_years}
            formula={forest.timeline_formula}
            surface={userTotalSurface}
            plantingDatePeriod={plantingDatePeriod}
            myTreesGraph={true}
            locale={locale}
          />
        </ForestSection>
      }
      {
        (authenticatedUserIsShareOwner && myFilteredOwnedForests) &&
        <ForestSection>
          <MyOtherForests
            forest={forest}
            myOwnedForests={myFilteredOwnedForests}
          />
        </ForestSection>
      }
    </div>
  );
};

MyTrees.propTypes = {
  forest: PropTypes.object,
  authenticatedUserIsShareOwner: PropTypes.bool,
  userTotalSurface: PropTypes.number,
  userOwnerDisplayName: PropTypes.string,
  myFilteredOwnedForests: PropTypes.array,
  intl: intlShape
};

export default injectIntl(MyTrees);
