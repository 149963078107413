import React from 'react';
import { Helmet } from 'react-helmet';

import Wrapper from '../components/Layout/Wrapper/Wrapper';
import SearchResults from "../components/Widgets/SearchResults/SearchResults"
import MobileTopbar from "../components/Header/MobileTopbar/MobileTopbar"

const SearchPage = ({location}) => {

  const getSearchTerm = () => {
    const params = new URLSearchParams(location.search)
    return params.get('term') || ''
  }

  getSearchTerm()

  return (
    <Wrapper>
      <Helmet>
        <title>
          Search | Reforestum
        </title>
        <meta name="description" content="Welcome back to Reforestum.  Sign in now to check out your carbon footprint and create and follow forests." />
      </Helmet>
      <MobileTopbar>
      </MobileTopbar>
      <SearchResults searchTerm={getSearchTerm()}/>
    </Wrapper>
  );
};

export default SearchPage;
