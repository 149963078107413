import * as fromBalance from '../reducers/publicProfile';

export const getIsFetching = state =>
  fromBalance.getIsFetching(state.publicProfile);

export const getErrorMessages = state =>
  fromBalance.getErrorMessages(state.publicProfile);

export const getProfileBalance = state => 
  fromBalance.getProfileBalance(state.publicProfile);

export const getProfileDetails = state =>
fromBalance.getProfileDetails(state.publicProfile);

export const getProfileForests = state =>
fromBalance.getProfileForests(state.publicProfile);
