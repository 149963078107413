import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { links } from '@reforestum/shared-utils';

// utils
import { getCurrentUrlEncoded } from '../../utils/url';

// constants
import { toggleNav } from '../../constants/customEvents';
import { ACTIVATION_STATUS } from '../../constants/enterpriseLanding'
import { NAV_PROFILES } from '../../constants/navProfiles';

// components
import HeaderLink from './HeaderLink';
import { ButtonLink } from '@reforestum/shared-components';
import PreferencesSwitcherDropdown from "./PreferencesSwitcherDropdown"
import ActivateSharesButtonTooltip from '../Specials/ActivateSharesButtonTooltip';

import './Guest.css';

const Guest = (
  {
    className,
    location: { pathname, search },
    togglePreferencesSwitcherPopup,
    isPreferencesSwitcherPopupOpen,
    status,
    activationId,
    navProfile,
    showInteractiveOverlay
  }) => {

    const renderPreferencesSwitcherSettingsDropdown = () => (
    <div
      className="topbar-user__preferences-switcher-dropdown-handler"
       onClick={
         e => {togglePreferencesSwitcherPopup(); e.stopPropagation()}
       }
    >
      <div className="topbar-user__preferences-switcher-dropdown-holder special-user__preferences">
        <PreferencesSwitcherDropdown />
      </div>
    </div>
  );

  

  const renderLoginAndRegisterButtons = () => (
    <div className="topbar__guest">
      <HeaderLink
        to={
          status === ACTIVATION_STATUS.PENDING
            ? links.getEnterpriseActivationUrl(activationId, 'login') // special case for enterprise activations
            : `${links.getLoginUrl()}?next=${getCurrentUrlEncoded(pathname, search)}`
        }
        className="topbar-guest__item topbar-guest__login"
        >
        <FormattedMessage
          id={'Header.login'}
          defaultMessage={'Login'}
        />
      </HeaderLink>
      {/* special case for enterprise activations */}
      {status === ACTIVATION_STATUS.PENDING
        ? <ActivateSharesButtonTooltip 
            activationId={activationId}
            showInteractiveOverlay={showInteractiveOverlay}
          />
        : <ButtonLink
            onClick={() => {if(window.innerWidth <= 768) dispatchEvent(toggleNav);}}
            className="topbar-guest__item"
            to={links.getRegisterUrl()}>
            <FormattedMessage
              id={'Header.register'}
              defaultMessage={'Register'}
            />
          </ButtonLink>
      }
    </div>
  )

  const renderLoginOnlyInActivationActivated = () => (
    <div>
      <HeaderLink
        to={
          links.getEnterpriseActivationUrl(activationId, 'login') // special case for enterprise activations
        }
        className="topbar-guest__item topbar-guest__login"
        >
        <FormattedMessage
          id={'Header.login'}
          defaultMessage={'Login'}
        />
      </HeaderLink>
    </div>
  );

  if(navProfile === NAV_PROFILES.USER_ACTIVATION){
    return (
      isPreferencesSwitcherPopupOpen && renderPreferencesSwitcherSettingsDropdown()
    );
  }

  return (
    <div className={cx(className, 'topbar-guest')}>
      {navProfile === NAV_PROFILES.ENTERPRISE_ACTIVATION_ACTIVATED_AND_NO_AUTH 
        ? renderLoginOnlyInActivationActivated()
        : renderLoginAndRegisterButtons()
      }
      {isPreferencesSwitcherPopupOpen && renderPreferencesSwitcherSettingsDropdown()}
    </div>
  );
};

Guest.propTypes = {
  className: PropTypes.string,
  location: PropTypes.object.isRequired,
  showInteractiveOverlay: PropTypes.bool
};

export default withRouter(Guest);
