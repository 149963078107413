import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Text from '../UI/Text/Text';
import Popover from '../UI/Popover/Popover';
import { getSources, markSourceAsOffseted } from '../../actions/carbon';

import './CarbonItemPopover.css';
import HeaderLink from "../Header/HeaderLink";
import { links } from '@reforestum/shared-utils';

const CarbonItemPopover = ({
  className,
  deleteItem,
  editItem,
  itemName,
  frequency,
  id,
  updateFrequency,
  source,
  history,
  getSources,
  markSourceAsOffseted,
  dashboard,
  resetForm
}) => (
    <Popover
      className={cx(className, 'carbon-item-popover')}
      direction="left"
      theme="dark"
      useArrow={false}
    >
      {
        dashboard ?
          <HeaderLink to={links.getCalculatorUrl()}>
            <Text
              className="carbon-item-popover__item"
              tag="p"
              color="50"
              onMouseDown={
                () => {
                  setTimeout(() => editItem(id, itemName), 1000)
                }
              }>
              <FormattedMessage
                id={'CarbonItemPopover.editSource'}
                defaultMessage={'Edit source'}
              />
            </Text>
          </HeaderLink>
        :
          <Text
            className="carbon-item-popover__item"
            tag="p"
            color="50"
            onMouseDown={
              async () => {
                await resetForm()
                editItem(id, itemName);
              }
            }>
            <FormattedMessage
              id={'CarbonItemPopover.editSource'}
              defaultMessage={'Edit source'}
            />
          </Text>
      }
      <Text
        className="carbon-item-popover__item"
        tag="p"
        color="50"
        onMouseDown={() => { deleteItem(id); }}>
        <FormattedMessage
          id={'CarbonItemPopover.deleteSource'}
          defaultMessage={'Delete source'}
        />
      </Text>
      {frequency !== 0 &&
        <Text
          className="carbon-item-popover__item"
          tag="p"
          color="50"
          onMouseDown={() => { updateFrequency(id, 'none'); }}>
          <FormattedMessage
            id={'CarbonItemPopover.cancelRecurrency'}
            defaultMessage={'Cancel Recurrency'}
          />
        </Text>
      }

      {source.offset === 0 && <Text
        className="carbon-item-popover__item"
        tag="p"
        color="50"
        onMouseDown={() => {
          localStorage.setItem('current_source', JSON.stringify(source));
          history.push(links.getCreateForestUrl());
        }}>
        <FormattedMessage
          id={'CarbonItemPopover.offsetThisSource'}
          defaultMessage={'Offset this source'}
        />
      </Text>}

      {source.offset === 0 && <Text
        className="carbon-item-popover__item"
        tag="p"
        color="50"
        onMouseDown={async () => {
          await markSourceAsOffseted(source.id);
          await getSources();
        }}>
        <FormattedMessage
          id={'CarbonItemPopover.markAsOffset'}
          defaultMessage={'Mark as offset'}
        />
      </Text>}
    </Popover>
  );

CarbonItemPopover.propTypes = {
  className: PropTypes.string,
  deleteItem: PropTypes.func.isRequired,
  editItem: PropTypes.string.isRequired,
  itemName: PropTypes.func.isRequired,
  frequency: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  updateFrequency: PropTypes.func.isRequired,
  source: PropTypes.object,
  history: PropTypes.object,
  getSources: PropTypes.func.isRequired,
  markSourceAsOffseted: PropTypes.func.isRequired,
  dashboard: PropTypes.bool,
  resetForm: PropTypes.func
};


export default connect(null, {
  getSources,
  markSourceAsOffseted,
})(withRouter(CarbonItemPopover));
