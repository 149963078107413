import React from 'react';

const CopyToClipboardIcon = props => {
	const svgProps = {...props}
	const item1Props = props.item1Props ? props.item1Props : {}
	const item2Props = props.item2Props ? props.item2Props : {}
	delete svgProps.item1Props
	delete svgProps.item2Props
	return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M13.125 13.1245H16.875V3.12451H6.875V6.87451" stroke="#A5B1A6" {...item1Props} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
		<path d="M13.125 6.87451H3.125V16.8745H13.125V6.87451Z" stroke="#1F3B21" {...item2Props} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
	</svg>
}

export default CopyToClipboardIcon;
