export const orderSectorsByContributionAmount = ({sectors, isSqmSharesProject}) => {
    return sectors
        .sort((sectorOne, sectorTwo) => {
        if(isSqmSharesProject) {
            return sectorTwo.sector_surface - sectorOne.sector_surface
        } else {
            return sectorTwo.total_vcus - sectorOne.total_vcus
        }
        })
  };
  