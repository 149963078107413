import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedNumber, injectIntl, intlShape } from 'react-intl';

import TinyInfoBlock from '../../UI/TinyInfoBlock/TinyInfoBlock';
import Tags from '../../UI/Tags/Tags';

import { getFormattedArea, getFormattedTemperature } from '../../../utils/units';
import FormattedUnit from '../../Utils/FormattedUnit';

import './ForestHeaderDetails.css';
import {forestDetailsMessages} from '../../../constants/messages'
import Tooltip from '../../UI/Tooltip/Tooltip';
import Information from '../../UI/Icons/Information';

const SharesForestHeaderDetails = ({ forest, intl, sector }) => {

  const {
    total_surface,
    occupied_surface,
    user_total_surface,
    weather,
    available,
    planting_date,
    certifications,
    reforesters_amount,
    project_type,
    offset_type,
    registry
  } = forest;

  const number_of_reforesters = sector ? sector.reforesters_amount : reforesters_amount
  const hasSector = !!sector;
  const userSector = forest && forest.user_sectors && forest.user_sectors.find(s => hasSector && (s.sector_id === sector.sector_id))
  const user_surface_in_sector_or_forest = sector ? (userSector && userSector.user_surface) || 0 : user_total_surface
  const totalSurface = sector ? sector.sector_surface : total_surface;
  //@NOTE: Sector is 100% reforested by default so this will hack the metrics to be 100%
  const occupiedSurface = sector ? Number(sector.sector_surface) - Number(sector.available_surface) : occupied_surface;
  const occupiedSurfacePercentage = intl.formatNumber(((occupiedSurface / totalSurface)  * 100).toFixed(2));
  const formattedDegrees = getFormattedTemperature((weather || {}).temperature);
  const myShares = getFormattedArea(user_surface_in_sector_or_forest);
  const items = [
    {
      title: intl.formatMessage(forestDetailsMessages.weather),
      value: <div>
          <FormattedNumber
              value={formattedDegrees.value}
          /> <FormattedUnit unit={formattedDegrees.unit} />
        </div>,
      icon: (weather || {}).icon,
      divider: true
    },
    {
      title: intl.formatMessage(forestDetailsMessages.extension),
      value: <div>
          <FormattedNumber
              value={getFormattedArea(totalSurface).value}
          /> <FormattedUnit unit={getFormattedArea(totalSurface).unit} />
        </div>,      
      icon: 'surface',
      divider: true
    }
  ];
  
  const claimedItems = [
    {
      title:  (
        <div className="forest-header-details__claimedItems_wrapper">
          <span>{intl.formatMessage(forestDetailsMessages.occupiedSurfacePercentage, { occupiedSurfacePercentage })}</span>
          <Tooltip
            style={{marginLeft: '4px'}}
            size='medium'
            position='bottom'
            content={intl.formatMessage(forestDetailsMessages.occupiedSurfacePercentageTooltip)}
          >
            <Information/>
          </Tooltip>
        </div>
      ),
      progress: parseFloat(occupiedSurfacePercentage),
    }
  ]

  if(available && myShares.value > 0){
    items.push({
      title: intl.formatMessage(forestDetailsMessages.myShares),
      value: <div>
          <FormattedNumber
              value={myShares.value || 0}
          /> <FormattedUnit unit={myShares.unit} />
        </div>,      
      icon: 'my-shares',
      tooltip: intl.formatMessage(forestDetailsMessages.mySharesTooltip)
    })
  }

  if(number_of_reforesters && number_of_reforesters > 0){
    items.splice(1, 0, {
      title: intl.formatMessage(forestDetailsMessages.reforesters),
      value: `${number_of_reforesters}`,
      icon: 'users',
      divider: true,
      tooltip: intl.formatMessage(forestDetailsMessages.reforestersTooltip)
    })
  }

  // remove divider form last item
  items[items.length - 1].divider = false;

  return (
    <div className="forest-header-details">
      <div className="forest-header-details__wrapper">
        {items.map((item, index) => (
          <TinyInfoBlock
            className={cx('forest-header-details__item')}
            key={index}
            {...item}
          />
        ))}
      </div>
      <div className="forest-header-progress__wrapper">
        {claimedItems.map((item, index) => (
          <TinyInfoBlock
            className={cx('forest-header-details__claimedItems', {'forest-header-details__item--with-progress': item.progress})}
            key={index}
            {...item}
          />
        ))}
      </div>
      <Tags
        planting_date={planting_date}
        certifications={certifications}
        intl={intl}
        projectType={project_type}
        projectTypeTooltipPosition={"bottom-right"}
        offsetType={offset_type}
        registry={registry}
      />
    </div>
  );
};

SharesForestHeaderDetails.propTypes = {
  forest: PropTypes.object,
  intl: intlShape.isRequired,
  sector: PropTypes.object,
};

export default injectIntl(SharesForestHeaderDetails);
