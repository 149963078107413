import { USD, EUR, GBP } from '../constants/currencies';

export default function() {
  const userLanguage = navigator.languages
    ? navigator.languages[0]
    : (navigator.language || navigator.userLanguage);

  switch(userLanguage.toLowerCase()) {
    case 'en-us':
    case 'my':
      return {
        metric_distance: false,
        metric_weight: false,
        temp_celsius: false,
        currency: USD,
        big_weight: 'st'
      };

    case 'en-gb':
      return {
        metric_distance: false,
        metric_weight: true,
        temp_celsius: true,
        currency: GBP,
        big_weight: 'it',
      };

    default:
      return {
        metric_distance: true,
        metric_weight: true,
        temp_celsius: true,
        currency: EUR,
        big_weight: 'mt',
      };
  }
}
