// Dependencies
import React, { Component } from 'react';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import history from '../setup/history';
import { Helmet } from 'react-helmet';
import { links } from '@reforestum/shared-utils';

// Utils
import getIsAutheticatedFromStore from '../utils/getIsAutheticatedFromStore';

// Actions
import { getPublicProfile } from '../actions/publicProfile';
import { fetchMyContributionsInPublicProfile } from '../actions/publicProfileMyContributions';
import { openGallery } from '../actions/UI';

// Selectors
import {
  getErrorMessages,
  getIsFetching,
  getProfileBalance,
  getProfileDetails,
  getProfileForests
} from '../selectors/publicProfile';
import {
  getIsUserAuthenticated
} from '../selectors/userSession';
import {
  getMyContributionsInPublicProfile,
  getMyContributionsInPublicProfileErrors
} from '../selectors/publicProfileMyContributions';

// Components
import PublicDashboard from '../components/Dashboard/PublicDashboard';
import Loader from '../components/UI/Loader/Loader';
import {injectIntl} from "react-intl"
import {MyForestSummary as MyForestSummaryTexts} from "../constants/messages"
import {getFormattedWeight} from "../utils/units"

class PublicDashboardContainer extends Component {
  constructor(props){
    super(props)
    this.state = {
      companySlug: this.props.match.params.userName,
    }
  }

  componentWillMount() {
    const { companySlug } = this.state;
    this.props.getPublicProfile(companySlug);
    this.props.fetchMyContributionsInPublicProfile(companySlug);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.match.params.userName !== this.props.match.params.userName) {
      const companySlug = this.props.match.params.userName
      this.setState({companySlug})
      this.props.getPublicProfile(companySlug)
      this.props.fetchMyContributionsInPublicProfile(companySlug)
    }
  }

  componentDidMount() {
    mixpanel.track("Page view", {
      "Authenticated": getIsAutheticatedFromStore(),
      "Action": "Public profile: " + this.state.companySlug,
      "Domain": "App"
    });
  }

  getUseUnOnboardedCompanyMode() {
    return this.props.profileDetails && this.props.profileDetails.is_unonboarded === true
  }

  render() {
    const { companySlug } = this.state;
    const {
      balance,
      errorMessages,
      isFetching,
      profileDetails,
      profileProjects,
      myContributions,
      openGallery,
      intl
    } = this.props;

    let {
      captured,
      emitted,
      current_c02_captured,
      future_c02_captured,
      total_user_and_community_vcu,
      miterd_credits,
      has_community
    } = balance;

    if (errorMessages) {
      history.push(links.get404Url())
    }

    if (isFetching) return <Loader />;

    const generateSeoData = () => {
      let calculatedCompanyDisplayName = (profileDetails.nickname ? profileDetails.nickname : (profileDetails.name ? profileDetails.name : companySlug))
      calculatedCompanyDisplayName = calculatedCompanyDisplayName.charAt(0).toUpperCase() + calculatedCompanyDisplayName.slice(1)
      const description = intl.formatMessage(MyForestSummaryTexts.seoDataDescription, {
        companyName: calculatedCompanyDisplayName,
        quantity: `${intl.formatNumber(getFormattedWeight(captured).value)}${getFormattedWeight(captured).unit}`,
        projectCount: profileProjects.length
      })
      let title = intl.formatMessage(MyForestSummaryTexts.seoDataTitle, {
        companyName: calculatedCompanyDisplayName
      })
      let img = profileDetails.avatar_url
      let imgAlt = `${profileDetails.name} logo`
      const canonical = `https://app.reforestum.com${links.getPublicProfileUrl(companySlug)}`

      return { title, description, img, imgAlt, canonical }
    }

    const seoData = generateSeoData()

    return (
        <PublicDashboard
          companySlug={companySlug}
          emitted={emitted}
          captured={captured}
          current_c02_captured={current_c02_captured}
          future_c02_captured={future_c02_captured}
          total_vcus={total_user_and_community_vcu}
          miterd_credits={miterd_credits}
          has_community={has_community}
          profile={profileDetails}
          projects={profileProjects}
          openGallery={openGallery}
          myContributions={myContributions}
          unOnboardedCompanyMode={this.getUseUnOnboardedCompanyMode()}
          publicProfileSlug={companySlug}
        >
          <Helmet>

            {/* ---- CANONICAL ---- */}
            <link rel="canonical" href={seoData.canonical} />

            {/* ---- SEO ---- */}
            <title>{seoData.title} | Reforestum</title>
            <meta name="description" content={seoData.description} />

            {/* ---- SHARING ---- */}
            {/* SHARING: Essential META Tags */}
            <meta property="og:title" content={seoData.title} />
            <meta property="og:type" content="article" />
            <meta property="og:image" content={seoData.img} />
            <meta property="og:url" content={seoData.canonical} />
            <meta name="twitter:card" content="summary_large_image" />
            {/* SHARING: Non-Essential */}
            <meta property="og:description" content={seoData.description} />
            <meta property="og:site_name" content={seoData.title} />
            <meta name="twitter:image:alt" content={seoData.imgAlt} />
            {/* SHARING: Non-Essential (Analytics Related) */}
            <meta name="twitter:site" content="@reforestum" />
          </Helmet>
        </PublicDashboard>
    );
  }
}

const mapStateToProps = state => ({
  // balance
  errorMessages: getErrorMessages(state),
  balance: getProfileBalance(state),
  isFetching: getIsFetching(state),
  // user
  isAuthed: getIsUserAuthenticated(state),
  profileDetails: getProfileDetails(state),
  profileProjects: getProfileForests(state),
  myContributions: getMyContributionsInPublicProfile(state),
  myContributionsErrors: getMyContributionsInPublicProfileErrors(state)
});

PublicDashboardContainer.propTypes = {
  // balance
  captured: PropTypes.number,
  emitted: PropTypes.number,
  surface: PropTypes.number,
  balance: PropTypes.object,
  formula: PropTypes.string,
  getPublicProfile: PropTypes.func.isRequired,
  getProfileBalance: PropTypes.func,
  // fetchiing and errors
  isFetching: PropTypes.bool.isRequired,
  errorMessages: PropTypes.string,
  // profile details
  profileDetails: PropTypes.object,
  // projects
  profileProjects: PropTypes.array.isRequired,
  // others
  match: PropTypes.object.isRequired,
  // my contributions - enterprise activations
  fetchMyContributionsInPublicProfile: PropTypes.func.isRequired,
  myContributions: PropTypes.array,
  openGallery: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {
  getPublicProfile,
  fetchMyContributionsInPublicProfile,
  openGallery
})(injectIntl(PublicDashboardContainer));
