import React from "react"
import './PartySwitcher.css'
import AvatarPlaceholder from '@reforestum/shared-components/dist/assets/images/avatar-placeholder.png'
const PartySwitcherCard = (
	{
		partyName="",
		partyRoleString=null,
		onClick=null,
		avatar=AvatarPlaceholder
	}={}
) => <div className={`party-switcher-card ${onClick ? 'party-switcher-card__clickable' : ''}`}
	onClick={onClick ? onClick : (()=>{})}
>
	<div
		className="party-switcher-card__user-photo"
		style={{
			backgroundImage: `url(${avatar})`
		}}
	/>
	<div className={'party-switcher-card__details'}>
		<div className={'party-switcher-card__party-name'}>
			{partyName}
		</div>
		{
			partyRoleString && <div className={'party-switcher-card__party-role'}>
				{partyRoleString}
			</div>
		}
	</div>
</div>

export default PartySwitcherCard
