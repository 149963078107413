import React, { Component } from 'react';

import './StepLoading.css';

var sequencePointer = 0

const returnNumberFromSequence = (sequence) => {
  sequencePointer++
  if (sequencePointer >= sequence.length) sequencePointer = 0
  return sequence[sequencePointer]
}

const drawStepLoadingNumTimes = (itemsInPath) =>
  Array(itemsInPath).fill().map(() =>
    <div className={`loading-bg approximate-step-row approximate-step-size-${returnNumberFromSequence(['1', '2', '3'])}`}></div>
  )

class StepLoading extends Component {
  componentDidUpdate() {
    sequencePointer = 0
  }

  render() {
    let itemsInPath = 0
    if(this.props.sourceToEditPath) itemsInPath = this.props.sourceToEditPath.length
    return (
      <span>
        {
          itemsInPath <= 3 ?
            (
              <span>
              <div className="loading-bg approximate-step-row approximate-step-size-1"></div>
              <div className="loading-bg approximate-step-row approximate-step-size-2"></div>
              <div className="loading-bg approximate-step-row approximate-step-size-3"></div>
            </span>
            )
            :
            drawStepLoadingNumTimes(itemsInPath)
        }
      </span>
    );
  }
}

export default StepLoading;
