import React from "react"

const Photo = (props) => {
    return (
        <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.75 9.18749L4.50313 6.43435C4.54376 6.39373 4.59199 6.3615 4.64507 6.33952C4.69815 6.31753 4.75504 6.30621 4.81249 6.30621C4.86995 6.30621 4.92684 6.31753 4.97992 6.33952C5.033 6.3615 5.08123 6.39373 5.12185 6.43435L7.56563 8.87814C7.60626 8.91876 7.65449 8.95099 7.70757 8.97297C7.76065 8.99496 7.81754 9.00628 7.87499 9.00628C7.93244 9.00628 7.98934 8.99496 8.04242 8.97297C8.0955 8.95099 8.14373 8.91876 8.18435 8.87814L9.31563 7.74685C9.35626 7.70623 9.40449 7.674 9.45757 7.65202C9.51065 7.63003 9.56754 7.61871 9.62499 7.61871C9.68244 7.61871 9.73934 7.63003 9.79242 7.65202C9.8455 7.674 9.89373 7.70623 9.93435 7.74685L12.25 10.0625" stroke="#A5B1A6" strokeWidth="1.2" strokeLinecap="round"/>
        <path d="M8.53125 6.125C8.89369 6.125 9.1875 5.83119 9.1875 5.46875C9.1875 5.10631 8.89369 4.8125 8.53125 4.8125C8.16881 4.8125 7.875 5.10631 7.875 5.46875C7.875 5.83119 8.16881 6.125 8.53125 6.125Z" fill="#FBFAF9"/>
        <path d="M11.8125 2.625H2.1875C1.94588 2.625 1.75 2.82088 1.75 3.0625V10.9375C1.75 11.1791 1.94588 11.375 2.1875 11.375H11.8125C12.0541 11.375 12.25 11.1791 12.25 10.9375V3.0625C12.25 2.82088 12.0541 2.625 11.8125 2.625Z" stroke="#FBFAF9" strokeWidth="1.2" strokeLinecap="round"/>
        </svg>        
    )
}

export default Photo