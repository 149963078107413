import React from 'react';

export const EyeLashes = props => (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9773 13.3322L17.2773 13.8518L18.3165 13.2518L18.0165 12.7322L16.9773 13.3322ZM15.1952 10.2457L16.9773 13.3322L18.0165 12.7322L16.2345 9.64568L15.1952 10.2457Z" fill="#1F3B21"/>
        <path d="M12.0455 11.6612L12.6013 14.813" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
        <path d="M7.94757 11.6597L7.39172 14.812" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
        <path d="M1.97172 12.7446L1.67172 13.2642L2.71095 13.8642L3.01095 13.3446L1.97172 12.7446ZM3.76232 9.64316L1.97172 12.7446L3.01095 13.3446L4.80156 10.2432L3.76232 9.64316Z" fill="#1F3B21"/>
     <path d="M2.5 8.19337C3.81348 9.81919 6.22141 11.875 10 11.875C13.7787 11.875 16.1866 9.8192 17.5001 8.19338" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
    </svg>
);
