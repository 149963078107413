import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import {
  fetchForestDetails,
  fetchForests,
  fetchMyForests,
  fetchForestAboutGallery,
  forestSummary,
  fetchForestLatestActivity
} from '../actions/forests';
import { openGallery } from '../actions/UI';
import * as forestSelector from '../selectors/forests';

import SectorDetails from '../components/Forests/Details/SectorDetails';
import Loader from '../components/UI/Loader/Loader';

import mixpanel from 'mixpanel-browser';
import getIsAutheticatedFromStore from '../utils/getIsAutheticatedFromStore';

import { links } from '@reforestum/shared-utils';

const getSectorDetails = (sectors, sectorId) => {
  const match = sectors.filter(sector => Number(sector.sector_id) === sectorId)
  return match.length ? match[0] : false;
}

class SectorContainer extends Component {
  componentWillMount() {
    const { 
      selectedForestId,
      selectedSectorId,
      fetchForestAboutGallery,
      forestSummary,
      fetchForestLatestActivity
    } = this.props;
    fetchForestAboutGallery(selectedForestId);
    forestSummary();
    fetchForestLatestActivity(selectedForestId, selectedSectorId);
  }

  componentDidUpdate(prevProps) {
    const {
      selectedForestId,
      selectedSectorId,
      fetchForestAboutGallery,
      fetchForestLatestActivity
    } = this.props
    //Refresh forest page when new forest is selected
    if(prevProps && ((prevProps.selectedForestId !== selectedForestId) || (prevProps.selectedSectorId !== selectedSectorId))) {
      fetchForestAboutGallery(selectedForestId);
      fetchForestLatestActivity(selectedForestId, selectedSectorId);
    }
  }

  componentDidMount() {
    mixpanel.track("Page view", {
      "Authenticated": getIsAutheticatedFromStore(),
      "Action": "Sector page",
      "Sector": this.props.selectedForestId,
      "Domain": "App"
    });
  }

  render() {
    const {
      forest,
      isFetching,
      openGallery,
      species,
      team,
      gallery,
      selectedSectorId,
      myOwnedForests,
      isForestLevel,
      reforesterShares,
      shareIdToZoom,
      latestActivity
    } = this.props;

    if (isFetching) {
      return <Loader />;
    }

    if (!forest) {
      return <Redirect replace to={links.get404Url()} />;
    }

    if(!forest.sectors){
      return <Loader />;
    }

    const sector = getSectorDetails(forest.sectors, selectedSectorId)

    if(!sector) {
      return <Redirect replace to={links.getProjectUrl(forest.id)} />;
    }

    return (
      <SectorDetails
        forest={forest}
        openGallery={openGallery}
        species={species}
        team={team}
        gallery={gallery}
        sector={sector}
        myOwnedForests={myOwnedForests}
        isForestLevel={isForestLevel}
        reforesterShares={reforesterShares}
        shareIdToZoom={shareIdToZoom}
        latestActivity={latestActivity}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  forest: forestSelector.getForestById(state, ownProps.selectedForestId),
  isFetching: forestSelector.getIsFetchingDetails(state),
  species: forestSelector.getForestSpecies(state, ownProps.selectedForestId),
  isFetchingMyForests: forestSelector.getIsFetchingMyForests(state),
  team: forestSelector.getForestTeam(state, ownProps.selectedForestId),
  gallery: forestSelector.getGallery(state, ownProps.selectedForestId),
  myOwnedForests: forestSelector.getMyOwnedForests(state),
  latestActivity: forestSelector.getLatestActivity(state)
});

SectorContainer.propTypes = {
  fetchForestDetails: PropTypes.func.isRequired,
  reforesterShares: PropTypes.array,
  fetchMyForests: PropTypes.func.isRequired,
  fetchForestAboutGallery: PropTypes.func.isRequired,
  forestSummary: PropTypes.func.isRequired,
  fetchForestLatestActivity: PropTypes.func.isRequired,
  forest: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  openGallery: PropTypes.func.isRequired,
  isFetchingMyForests: PropTypes.bool.isRequired,
  selectedForestId: PropTypes.number.isRequired,
  selectedSectorId: PropTypes.number.isRequired,
  species: PropTypes.array.isRequired,
  team: PropTypes.array.isRequired,
  gallery: PropTypes.object,
  myOwnedForests: PropTypes.array,
  isForestLevel: PropTypes.bool,
  shareIdToZoom: PropTypes.number
};

export default withRouter(connect(mapStateToProps, {
  fetchForestDetails,
  fetchMyForests,
  fetchForests,
  openGallery,
  fetchForestAboutGallery,
  forestSummary,
  fetchForestLatestActivity
})(SectorContainer));
