import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Box } from 'react-layout-components';
import { FormattedNumber, FormattedMessage } from 'react-intl';

import { getUnit, keepToMetric, getFormattedArea } from '../../utils/units';

import Text from '../UI/Text/Text';
import HeadingSmall from '../UI/Text/HeadingSmall';
import FormattedUnit from '../Utils/FormattedUnit';

import { OFFSET_TYPE_SQM_SHARES } from '../../constants/offsetTypes';

import './Summary.css';

const Summary = ({ image, className, offsetType, quantity, offsetPriceFromServer, forestName, forestLocation, pricePerUnit }) => {
  const { /*amount,*/ currency } = offsetPriceFromServer.price;
  const headerStyle = { backgroundImage: `url(${image})`};

  return (
    <div className={cx(className, 'checkout-summary')}>

      <div className="checkout-summary__head">
        <div className="checkout-summary__head__img" style={headerStyle}/>
        <div>
          <div className="checkout-summary__head__name">{forestName}</div>
          <div className="checkout-summary__head__location">{forestLocation}</div>
        </div>
      </div>

      {offsetType === OFFSET_TYPE_SQM_SHARES &&
        <Box className="checkout-summary__item">

          <Text>
            <FormattedMessage
                id={'Summary.pricePerUnit'}
                defaultMessage={'Price / {unit}'}
                values={{
                  unit: getUnit('m', true),
                }}
            />
          </Text>

          <Text color="light">
            <FormattedNumber
                value={Number(keepToMetric('ft2', 'm2', pricePerUnit))}
                style="currency" // eslint-disable-line
                currency={currency}
                currencyDisplay="symbol"
            />
          </Text>
        </Box>
      }

      { offsetType === OFFSET_TYPE_SQM_SHARES &&
        <Box className="checkout-summary__item">

          <Text>
            <FormattedMessage
              id={'Summary.totalArea'}
              defaultMessage={'Total area'}
            />
          </Text>

          <Text color="light">
            <FormattedNumber
              value={getFormattedArea(quantity).value}
            /> <FormattedUnit unit={getFormattedArea(quantity).unit} />
          </Text>
        </Box>
      }

      <Box className="checkout-summary__item">

        <Text>
          <FormattedMessage
            id={'Summary.netAmount'}
            defaultMessage={'Net Amount'}
          />
        </Text>

        <Text color="light">
          <FormattedNumber
            value={offsetPriceFromServer.net_amount}
            style="currency" // eslint-disable-line
            currency={currency}
            currencyDisplay="symbol"
          />
        </Text>
      </Box>

      { offsetPriceFromServer.taxes_percentage > 0 &&
        <Box className="checkout-summary__item">

          <Text>
            <FormattedMessage
              id={'Summary.TaxRate'}
              defaultMessage={'Tax Rate'}
            />
          </Text>

          <Text color="light">
            <FormattedNumber
              value={offsetPriceFromServer.taxes_percentage * 100} // Multiply per 100 as it's comming from the BEV1 as a fraction
            />&#37;
          </Text>
        </Box>
      }

      { offsetPriceFromServer.taxes_amount > 0 &&
        <Box className="checkout-summary__item">

          <Text>
            <FormattedMessage
              id={'Summary.taxesAmount'}
              defaultMessage={'Taxes'}
            />
          </Text>

          <Text color="light">
            <FormattedNumber
              value={offsetPriceFromServer.taxes_amount}
              style="currency" // eslint-disable-line
              currency={currency}
              currencyDisplay="symbol"
            />
          </Text>
        </Box>
      }

      <Box className="checkout-summary__item">

        <Text>
          <FormattedMessage
            id={'Summary.paymentFees'}
            defaultMessage={'Payment Fees'}
          />
        </Text>

        <Text color="light">
          <FormattedNumber
            value={offsetPriceFromServer.payment_fees}
            style="currency" // eslint-disable-line
            currency={currency}
            currencyDisplay="symbol"
          />
        </Text>
      </Box>

      <Box className="checkout-summary__item">

        <Text>
          <FormattedMessage
            id={'Summary.totalPrice'}
            defaultMessage={'Total price'}
          />
        </Text>

        <HeadingSmall color="green">
          <FormattedNumber
            value={offsetPriceFromServer.total_amount}
            style="currency" // eslint-disable-line
            currency={currency}
            currencyDisplay="symbol"
          />
        </HeadingSmall>
      </Box>

    </div>
  );
};

Summary.propTypes = {
  image: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  offsetType: PropTypes.string,
  className: PropTypes.string,
  offsetPriceFromServer: PropTypes.object.isRequired,
  forestName: PropTypes.string.isRequired,
  forestLocation: PropTypes.string.isRequired,
  pricePerUnit: PropTypes.number.isRequired,
};

export default Summary;
