import React, { Component } from 'react';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form'

import { fetchForestDetails } from '../actions/forests';
import { fetchCountries } from '../actions/countries';
import { signup, updateUser } from '../actions/userActions';
import { refreshProfile } from '../actions/userSession';
import { getSources } from '../actions/carbon'
import { getAllSources } from '../selectors/carbon'

import {
  getIsFetchingDetails,
  getForestById,
} from '../selectors/forests';
import {
  getCountries,
  getCountriesErrorMessages,
} from '../selectors/countries';
import {
  getIsUserAuthenticated,
  getUserDetails,
  getCurrency,
} from '../selectors/userSession';
import {
  getIsUpdating,
  getUpdateErrorMessages,
  getSignupErrorMessages,
  getIsLoading as getIsSigningUp
} from '../selectors/userActions';
import {
  getIsBillingComplete,
  getIsPaying,
  getIsCryptoPaying,
  getPaymentErrors,
  getPaymentType,
  getCryptoPaymentErrors,
  getIsFetchingPrice,
  getPrice,
  getInvoiceId,
} from '../selectors/checkout';
import { getLastPurchaseInfo } from '../selectors/lastPurchase'

import {
  makePayment,
  makeCryptoPayment,
  resetCheckout,
  switchPaymentType,
  toggleBillingPanel,
  getCalculatedPrice
} from '../actions/checkout';
import { fetchLastPurchaseInformation } from '../actions/lastPurchase';

import Loader from '../components/UI/Loader/Loader';
import ThankYou from '../components/Checkout/ThankYou';
import Checkout from '../components/Checkout/Checkout';
import getIsAutheticatedFromStore from '../utils/getIsAutheticatedFromStore';
import { links } from '@reforestum/shared-utils';

class CheckoutSuccessContainer extends Component {
  constructor(props) {
    super(props);

    this.forestId = Number(
      new URLSearchParams(props.location.search).get('forest')
    );
    this.quantity = Number(
      new URLSearchParams(props.location.search).get('quantity')
    );
    this.selectedSources = (() => {
      const sources = new URLSearchParams(props.location.search).get('sources');
      if (sources && sources !== 'undefined') return sources.split(',');
      return [];
    })();
  }

  componentDidMount() {

    const { sources, getSources } = this.props

    if (!sources.length) getSources()

    mixpanel.track("Checkout", {
      "Authenticated": getIsAutheticatedFromStore(),
      "Action": "Load",
      "Domain": "App"
    });
    mixpanel.track("Page view", {
      "Authenticated": getIsAutheticatedFromStore(),
      "Action": "Checkout",
      "Domain": "App"
    });
  }

  componentWillMount() {
    const {
      history,
      fetchForestDetails,
      fetchCountries,
      getCalculatedPrice,
      paymentType,
      fetchLastPurchaseInformation
    } = this.props;

    if (!this.forestId || !this.quantity) {
      history.replace(links.getCreateForestUrl());
    }

    fetchForestDetails(this.forestId);
    fetchCountries();
    getCalculatedPrice(this.forestId, this.quantity, paymentType);
    fetchLastPurchaseInformation();
  }

  componentWillUnmount() {
    this.props.resetCheckout();
  }

  getTotalInCents(area, pricePerSqm) {
    return (area * pricePerSqm * 100).toFixed();
  }

  goBack = () => {
    this.props.history.goBack()
  }

  render() {

    const {
      billingComplete,
      forest,
      isAuthenticated,
      isFetching,
      isPaying,
      isCryptoPaying,
      isSigningUp,
      isUpdating,
      makePayment,
      makeCryptoPayment,
      paymentComplete,
      paymentErrors,
      cryptoPaymentErrors,
      paymentType,
      signup,
      signupErrorMessages,
      toggleBillingPanel,
      updateErrorMessages,
      updateUser,
      user,
      countries,
      refreshProfile,
      isFetchingPrice,
      price,
      isCompany,
      currency,
      invoiceId,
      sources,
      lastPurchaseInformation
    } = this.props;
    
    if (isFetching || isFetchingPrice || !forest) {
      return <Loader />;
    }

    if (paymentComplete) {

      mixpanel.track("Checkout", {
        "Authenticated": getIsAutheticatedFromStore(),
        "Action": "Completed :)",
        "Forest Id": forest.id,
        "Forest Name": forest.name,
        "Final Price": price.total_amount,
        "Amount": this.quantity,
        "Domain": "App"
      });

      let ga = window.ga;
      if (ga) {
        ga('ec:addProduct', {
          id: forest.id,
          name: forest.name,
          price: price.total_amount,
          quantity: 1,
        });

        ga('ec:setAction', 'purchase', {
          id: invoiceId,
          affiliation: "Reforestum app",
          revenue: price.total_amount,
        });

        ga('send', 'event', ['User'], ['Checkout complete']);
        ga('ec', 'clear');

      }

      return (
        <ThankYou
          price={price.total_amount}
          quantity={this.quantity}
          offsetType={forest.offset_type}
          forest={forest}
          currency={currency}
          lastPurchaseInformation={lastPurchaseInformation}
        />
      );
    }

    return (
      <Checkout
        area={this.quantity}
        billingComplete={billingComplete}
        countries={countries}
        forest={forest}
        getTotalInCents={this.getTotalInCents.bind(this)}
        selectPaymentType={this.selectPaymentType.bind(this)}
        isAuthenticated={isAuthenticated}
        isPaying={isPaying}
        isCryptoPaying={isCryptoPaying}
        paymentType={paymentType}
        isWorkingAuth={isSigningUp || isUpdating}
        makePayment={makePayment}
        makeCryptoPayment={makeCryptoPayment}
        paymentErrors={paymentErrors}
        cryptoPaymentErrors={cryptoPaymentErrors}
        refreshProfile={refreshProfile}
        signup={signup}
        signupErrorMessages={signupErrorMessages}
        toggleBillingPanel={toggleBillingPanel}
        updateErrorMessages={updateErrorMessages}
        updateUser={updateUser}
        user={user}
        price={price}
        isCompany={isCompany}
        goBack={this.goBack}
        sources={sources}
        selectedSources={this.selectedSources}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const forestId = new URLSearchParams(ownProps.location.search).get('forest');
  const billingFormSelector = formValueSelector('billing-form');

  return {
    billingComplete: getIsBillingComplete(state),
    forest: getForestById(state, Number(forestId)),
    isAuthenticated: getIsUserAuthenticated(state),
    isFetching: getIsFetchingDetails(state),
    isPaying: getIsPaying(state),
    isCryptoPaying: getIsCryptoPaying(state),
    isSigningUp: getIsSigningUp(state),
    isUpdating: getIsUpdating(state),
    paymentComplete: true,
    paymentErrors: getPaymentErrors(state),
    paymentType: getPaymentType(state),
    cryptoPaymentErrors: getCryptoPaymentErrors(state),
    signupErrorMessages: getSignupErrorMessages(state),
    updateErrorMessages: getUpdateErrorMessages(state),
    user: getUserDetails(state),
    countries: getCountries(state),
    countriesErrorMessages: getCountriesErrorMessages(state),
    isFetchingPrice: getIsFetchingPrice(state),
    price: getPrice(state),
    isCompany: billingFormSelector(state, 'is_company'),
    currency: getCurrency(state),
    invoiceId: getInvoiceId(state),
    sources: getAllSources(state),
    lastPurchaseInformation: getLastPurchaseInfo(state)
  };
};

CheckoutSuccessContainer.propTypes = {
  billingComplete: PropTypes.bool.isRequired,
  countries: PropTypes.array.isRequired,
  countriesErrorMessages: PropTypes.string,
  paymentType: PropTypes.string,
  fetchCountries: PropTypes.func.isRequired,
  fetchForestDetails: PropTypes.func.isRequired,
  forest: PropTypes.object,
  history: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isPaying: PropTypes.bool.isRequired,
  isSigningUp: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  makePayment: PropTypes.func.isRequired,
  makeCryptoPayment: PropTypes.func.isRequired,
  paymentComplete: PropTypes.bool.isRequired,
  paymentErrors: PropTypes.string,
  refreshProfile: PropTypes.func.isRequired,
  resetCheckout: PropTypes.func.isRequired,
  switchPaymentType: PropTypes.func.isRequired,
  signup: PropTypes.func.isRequired,
  signupErrorMessages: PropTypes.string,
  toggleBillingPanel: PropTypes.func.isRequired,
  updateErrorMessages: PropTypes.string,
  updateUser: PropTypes.func.isRequired,
  user: PropTypes.object,
  getCalculatedPrice: PropTypes.func.isRequired,
  isFetchingPrice: PropTypes.bool.isRequired,
  price: PropTypes.object.isRequired,
  isCompany: PropTypes.bool,
  invoiceId: PropTypes.number,
  sources: PropTypes.array.isRequired,
  getSources: PropTypes.func.isRequired,
  fetchLastPurchaseInformation: PropTypes.func.isRequired,
  lastPurchaseInformation: PropTypes.object
};

export default connect(mapStateToProps, {
  fetchForestDetails,
  signup,
  updateUser,
  toggleBillingPanel,
  makePayment,
  makeCryptoPayment,
  resetCheckout,
  switchPaymentType,
  fetchCountries,
  refreshProfile,
  getCalculatedPrice,
  getSources,
  fetchLastPurchaseInformation
})(CheckoutSuccessContainer);
