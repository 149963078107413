import React from 'react'

import Plant from "../../UI/Icons/Plant";

import './VisitSharesTag.css'

const VisitSharesTag = () => {
    return (
        <div className='shares__tag'>
            <Plant className='shares__icon'/>
        </div>
    )
}

export default VisitSharesTag