import React from 'react';

const Food = props => <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
	<path d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4Z" fill="#F2F2F0"/>
	<path d="M15.875 11V14.75" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
	<path d="M15.875 18.875V29" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
	<path d="M19.0918 10.9014L18.9932 10.3095L17.8095 10.5068L17.9082 11.0986L19.0918 10.9014ZM19.25 15.5H19.85C19.85 15.467 19.8473 15.434 19.8418 15.4014L19.25 15.5ZM15.875 18.875V18.275V18.875ZM12.5 15.5L11.9082 15.4014C11.9027 15.434 11.9 15.467 11.9 15.5H12.5ZM13.8418 11.0986L13.9405 10.5068L12.7568 10.3095L12.6582 10.9014L13.8418 11.0986ZM17.9082 11.0986L18.6582 15.5986L19.8418 15.4014L19.0918 10.9014L17.9082 11.0986ZM18.65 15.5C18.65 16.236 18.3576 16.9418 17.8372 17.4622L18.6857 18.3107C19.4312 17.5653 19.85 16.5542 19.85 15.5H18.65ZM17.8372 17.4622C17.3168 17.9826 16.611 18.275 15.875 18.275V19.475C16.9292 19.475 17.9403 19.0562 18.6857 18.3107L17.8372 17.4622ZM15.875 18.275C15.139 18.275 14.4332 17.9826 13.9128 17.4622L13.0643 18.3107C13.8097 19.0562 14.8208 19.475 15.875 19.475V18.275ZM13.9128 17.4622C13.3924 16.9418 13.1 16.236 13.1 15.5H11.9C11.9 16.5542 12.3188 17.5653 13.0643 18.3107L13.9128 17.4622ZM13.0918 15.5986L13.8418 11.0986L12.6582 10.9014L11.9082 15.4014L13.0918 15.5986Z" fill="#1F3B21"/>
	<path d="M27.125 23H21.875C21.875 23 23 12.5 27.125 11V29" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
</svg>


export default Food;
