import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { links } from '@reforestum/shared-utils';

import Wrapper from '../components/Layout/Wrapper/Wrapper';
// import CheckoutContainer from '../containers/CheckoutContainer';
import MobileTopbar from '../components/Header/MobileTopbar/MobileTopbar';

const Checkout = (props) => {
  return (
    <Wrapper>
      <Helmet>
        <title>
          Checkout | Reforestum
        </title>
        <link rel="canonical" href={`https://app.reforestum.com${links.getGiftAForestUrl()}`} />
      </Helmet>
      <MobileTopbar>
        <FormattedMessage
          id={'Header.checkout'}
          defaultMessage={'Checkout'}
        />
      </MobileTopbar>
      <h1>This is Gift a Forest</h1>
      {/* <CheckoutContainer {...props} /> */}
    </Wrapper>
  );
};

export default Checkout;
