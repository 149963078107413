import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import Wrapper from '../components/Layout/Wrapper/Wrapper';
import EnterpriseActivationContainer from '../containers/EnterpriseActivationContainer';
import MobileTopbar from '../components/Header/MobileTopbar/MobileTopbar';

const EnterpriseActivation = (props) => {
  const { location } = props;
  
  // @DECISION-TAKEN: as default, Header does not appear for Enterprise activation landings
  // Implementation open to customization
  const isEnterpriseLanding = location.pathname.startsWith('/activation');
  
  return (
    <Wrapper style={{ paddingTop: isEnterpriseLanding && '0' }}>
      {!isEnterpriseLanding &&
        <div>
          <Helmet>
            <title>
              Enterprise user activation | Reforestum
            </title>
            <meta name="robots" content="noindex" />
          </Helmet>
          <MobileTopbar>
            <FormattedMessage
              id={'Header.EnterpriseActivation'}
              defaultMessage={'Enterprise Activation'}
            />
          </MobileTopbar>
        </div>
      }
      <EnterpriseActivationContainer {...props} />
    </Wrapper>
  );
};

export default withRouter(EnterpriseActivation);
