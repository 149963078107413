import './tarteaucitron.css';
import './tarteaucitron.override.css';

import { intercomAppId, posthogProjectApiKey} from "../../constants/config";
import mixpanel from 'mixpanel-browser';
import { getCookie } from '../cookie';
import { WEBSITE_ID_LOCALSTORAGE_KEY } from '../../constants/analytics';

import store from '../../setup/store';
import { getUserDetails, getIsUserAuthenticated } from '../../selectors/userSession';
import { posthog } from 'posthog-js'

export const tarteaucitronInit = () => {
  const tarteaucitron = window.tarteaucitron;

  let cookieDomain;
  if (process.env.REACT_APP_ENV === 'production') {
    cookieDomain = '.reforestum.com';
  } else if (process.env.REACT_APP_ENV === 'staging') {
    cookieDomain = '.reforestum.com';
  } else if (process.env.REACT_APP_ENV === 'development' )
    cookieDomain = '.reforestum.org';
  else {
    cookieDomain = 'localhost';
  }

  let tarteaucitronOptions = {
    "privacyUrl": "https://reforestum.com/legal/privacy-policy", /* Privacy policy url */

    "hashtag": "#cookies", /* Open the panel with this hashtag */
    "cookieName": "tarteaucitron", /* Cookie name */

    "orientation": "bottom", /* Banner position (top - bottom) */

    "showAlertSmall": false, /* Show the small banner on bottom right */
    "cookieslist": false, /* Show the cookie list */

    "showIcon": false, /* Show cookie icon to manage cookies */
    "iconPosition": "BottomRight", /* BottomRight, BottomLeft, TopRight and TopLeft */

    "adblocker": false, /* Show a Warning if an adblocker is detected */

    "DenyAllCta" : false, /* Show the deny all button /
    "AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
    "highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */

    "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */

    "removeCredit": false, /* Remove credit link */
    "moreInfoLink": true, /* Show more info link */
    "useExternalCss": true, /* If false, the tarteaucitron.css file will be loaded */

    "cookieDomain": cookieDomain, /* Shared cookie for multisite */

    "readmoreLink": "https://reforestum.com/legal/cookies-policy", /* Change the default readmore link */

    "mandatory": true, /* Show a message about mandatory cookies */
    };

  if (!window.Intercom) {
    window.Intercom = function() {};
  }

  if (!window.ga) {
    window.ga = () => {};
  }

  tarteaucitron.init(tarteaucitronOptions);

  // google analytics (GA4 Consent Mode with GTM)
  tarteaucitron.user.ga4_measurement_id = process.env.REACT_APP_GA4_MEASUREMENT_ID;
  (tarteaucitron.job = tarteaucitron.job || []).push('gtm_gtag');

  // google login
  (tarteaucitron.job = tarteaucitron.job || []).push('gplus');

  // Facebook pixel
  tarteaucitron.user.facebookpixelId = '211667239377080';
  tarteaucitron.user.facebookpixelMore = function () {
    /* add here your optionnal facebook pixel function */
  };
  (tarteaucitron.job = tarteaucitron.job || []).push('facebookpixel');
  // Facebook
  (tarteaucitron.job = tarteaucitron.job || []).push('facebook');

  // Intercom
  tarteaucitron.user.intercomid = intercomAppId;
  (tarteaucitron.job = tarteaucitron.job || []).push('intercom');

  // Mixpanel
  tarteaucitron.user.mixpanelPrepare = function () {

      mixpanel.opt_in_tracking();
      const mixpanelWebsiteId =  localStorage.getItem(WEBSITE_ID_LOCALSTORAGE_KEY);
      localStorage.removeItem(WEBSITE_ID_LOCALSTORAGE_KEY);
      if(mixpanelWebsiteId) mixpanel.identify(mixpanelWebsiteId);
      
      //Merge identity with already logged in users in webapp
      //And with guest users that already have a guess id
      const userId = getUserDetails(store.getState()).id;
      if(getIsUserAuthenticated(store.getState())) {
        mixpanel.identify(userId)   
      }

  };
  (tarteaucitron.job = tarteaucitron.job || []).push('mixpanel');

  // PostHog
  tarteaucitron.user.posthogProjectApiKey = posthogProjectApiKey
  tarteaucitron.user.posthogPrepare = function () {

    if (window.posthog) {
      // make a copy of the configure and change to default storage
      const posthogConfig = posthog.config;
      posthogConfig['persistence'] = 'cookie';

      // get existing props and change persistence manager to cookie storage
      const posthogPersistenceProps = posthog.persistence.props;
      const posthogPersistence = new posthog.persistence.constructor(posthogConfig)
      posthogPersistence.props = posthogPersistenceProps
      posthogPersistence.save()
      posthog.persistence = posthogPersistence
    }

  };
  (tarteaucitron.job = tarteaucitron.job || []).push('posthog');


  // Hubspot tracking code
  tarteaucitron.user.hubspotId = '4727961';
  (tarteaucitron.job = tarteaucitron.job || []).push('hubspot');

  // Vimeo
  (tarteaucitron.job = tarteaucitron.job || []).push('vimeo');
  // Youtube
  (tarteaucitron.job = tarteaucitron.job || []).push('youtube');

}

export const getTarteaucitronState = (name) => {

	const cookie = getCookie('tarteaucitron');
	if (!cookie) {
		return false;
	}

	const cookieValues = cookie.split('!');
	for (let i=0; i < cookieValues.length; i++) {
		const serviceValuePair = cookieValues[i].split('=');
		if (serviceValuePair.length === 2 && serviceValuePair[0] === name) {
			return serviceValuePair[1] === 'true';
		}
	}
	return false;
}
