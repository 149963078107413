import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router';
import Popover from './Popover';
import './PopoverWithWrapper.css'

class PopoverWithWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPopoverOpen: false,
    };

    this.elementRef = null;

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleMouseOutside = this.handleMouseOutside.bind(this);
    this.setButtonWrapperRef = this.setButtonWrapperRef.bind(this);
    this.setPopoverWrapperRef = this.setPopoverWrapperRef.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    const {routeChangeClosesPopover} = this.props;
    const {isPopoverOpen} = this.state;
    if(routeChangeClosesPopover && prevProps.location.pathname !== this.props.location.pathname && isPopoverOpen) {
      this.setState({isPopoverOpen: false})
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setButtonWrapperRef(node) {
    this.wrapperRef = node;
  }

  setPopoverWrapperRef(node) {
    this.popoverWrapperRef = node;
  }

  handleClickOutside(event) {
    const {isPopoverOpen} = this.state;
    if (isPopoverOpen && this.wrapperRef && !this.wrapperRef.contains(event.target) && this.popoverWrapperRef && !this.popoverWrapperRef.contains(event.target)) {
        this.setState({isPopoverOpen: false})
    }
  }

  handleMouseOutside = (event) => {
    const {isPopoverOpen} = this.state;
    const {mouseOutClosesPopover} = this.props;
    if(!isPopoverOpen || !mouseOutClosesPopover) return
    //check if the mouse is outside the wrapperRef and popoverWrapperRef
    if (isPopoverOpen && this.wrapperRef && !this.checkMouseIsInsideElement(event, this.wrapperRef) && this.popoverWrapperRef && !this.checkMouseIsInsideElement(event, this.popoverWrapperRef)) {
      
      //for some reason the mouseout event is triggered when clicking inside the popover on touch devices
      //this timeout is a workaround to make the popover clicks on link to work on touch devices
      setTimeout(() => {
       this.setState({isPopoverOpen: false})
      }, )
    }
  }

  checkMouseIsInsideElement = (event, elementRef) => {
    const { left, top, width, height } = elementRef.getBoundingClientRect();
    const mouseX = event.clientX;
    const mouseY = event.clientY;
  
    //There is a precision issue and sometimes the mouse is detected inside the element when it is out becouse this func is triggered by a mouseout event
    //so we add a 4px margin to the left and right of the element to make sure we detect the mouseOut in those cases
    return mouseX > (left + 4) && mouseX < (left + width - 4) && mouseY > (top) && mouseY < (top + height);
  };



  render() {
    const {
      isPopoverOpen,
    } = this.state;

    const { content, renderButton, expandOnHover, customShadow } = this.props;
    return (
      <div ref={this.setButtonWrapperRef} style={{ position: 'relative', width: 'fit-contrent', height: 'fit-content'}}>
        <button
          className="popover-with-wrapper-button"
          popover-with-wrapper-button
          onClick={() => !expandOnHover && this.setState({ isPopoverOpen: !isPopoverOpen })}
          onMouseOver={() => {
            if(expandOnHover && !isPopoverOpen) this.setState({ isPopoverOpen: true })
          }}
          onMouseLeave={(event) => {
            this.handleMouseOutside(event)
          }}
        >
          {renderButton({ isPopoverOpen })}
        </button>
        {isPopoverOpen && (
          <div 
            className="popover-with-wrapper-popover-wrapper" 
            ref={this.setPopoverWrapperRef} 
            onMouseLeave={event => {
                this.handleMouseOutside(event)
            }}
          >
            <Popover customShadow={customShadow} direction="down" useArrow={false}>
              {content}
            </Popover>
          </div>
        )}
      </div>
    );
  }
}

PopoverWithWrapper.propTypes = {
  mouseOutClosesPopover: PropTypes.bool,
  routeChangeClosesPopover: PropTypes.bool,
  expandOnHover: PropTypes.bool,
  customShadow: PropTypes.string,
  renderButton: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
};

export default withRouter(PopoverWithWrapper);
