import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from 'react-intl';
import FormattedUnit from '../../Utils/FormattedUnit';
import { Link } from 'react-router-dom';

import { getFormattedCredits, getFormattedWeight } from '../../../utils/units';
import NoForestEmptyStateImg from '@reforestum/shared-components/dist/assets/images/no-forests-empty-state.png';

import ForestSection from '../UI/ForestSection';
import ForestTitle from '../UI/ForestTitle';
import MySharesForestDescription from '../UI/MySharesForestDescription';
import Text from '../../UI/Text/Text';
import IconText from '../../UI/Text/IconText';
import GreenPlus from '../../UI/Icons/GreenPlus';

import './MyCredits.css';
import MyOtherForests from '../Header/MyOtherForests';
import { links } from '@reforestum/shared-utils';

const MyCredits = ({forest, myFilteredOwnedForests, forestVirtualSector}) => {
  const vcuAmountInScope = (forestVirtualSector && forest.user_forest_virtual_sectors)
    ? forest.user_forest_virtual_sectors.find(f => f.forest_virtual_sector_id === forestVirtualSector.id).user_total_vcus
    : forest.user_total_vcus

  const vcus = getFormattedCredits({credits: vcuAmountInScope});
  const vcusWeight = getFormattedWeight(vcuAmountInScope*1000);
  const treesPerVcu = parseFloat(forest.trees_per_vcu)

  if (vcus.value <= 0) {
    return (
      <div className="my-credits__no-trees-container">
        <img src={NoForestEmptyStateImg} alt="Person watering plants" className="my-credits__no-trees-image" />
        <Text className="my-credits__no-trees-text">
          <FormattedMessage 
          id={'ForestDetails.noTrees'}
          defaultMessage={"You don't have any forest shares yet, time to plant your first forest!"} 
          />
        </Text>
        <Link to={links.getCreateForestUrl()} className="my-credits__no-trees-link">
          <IconText icon={GreenPlus}>
            <Text color="green">
              <FormattedMessage
                id={'ForestSources.create'}
                defaultMessage={'Create forest'}
              />
            </Text>
          </IconText>
        </Link>
      </div>
    );
  }

  return (
    <div>
      <ForestSection>
        <ForestTitle>
          <FormattedMessage 
            id={'ForestDetails.myCreditsTitle'} 
            defaultMessage={'You own {vcus} in {forestName}.'}
            values={{
              forestName: forest.name,
              vcus: <Text color="green" tag="span">
                <FormattedNumber
                    value={vcus.value}
                /> <FormattedUnit unit={vcus.unit} />
                </Text>
            }}
          />
        </ForestTitle >
        <MySharesForestDescription treesPerVcu={treesPerVcu} vcusWeight={vcusWeight} vcus={vcus}/>
      </ForestSection>

      <ForestSection>
        <MyOtherForests 
          forest={forest} 
          myOwnedForests={myFilteredOwnedForests}
        />
      </ForestSection>
    </div>
  );
};

MyCredits.propTypes = {
  forest: PropTypes.object,
  forestVirtualSector: PropTypes.object,
  intl: intlShape,
  myFilteredOwnedForests: PropTypes.array
};

export default injectIntl(MyCredits);
