// Dependencies
import React, { Component } from 'react';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import getIsAutheticatedFromStore from '../utils/getIsAutheticatedFromStore';

// Actions
import { fetchOrganizationDashboard } from '../actions/organizationDashboard';
import { openGallery } from '../actions/UI';

// Selectors
import {
  getErrorMessages,
  getIsFetching,
  getProfileBalance,
  getProfileDetails,
  getProfileForests
} from '../selectors/publicProfile';
import {
  getIsUserAuthenticated, getUserDetails
} from '../selectors/userSession';

// Components
import PublicDashboard from '../components/Dashboard/PublicDashboard';
import Loader from '../components/UI/Loader/Loader';
import { loadFromStorage } from '../utils/localStorage';
import { getUserOrImpersonatingOrg } from '../utils/usersAndOrganizations';

class OrganizationPortfolioDashboardContainer extends Component {
  componentWillMount() {
    this.props.fetchOrganizationDashboard();
  }

  componentDidMount() {
    mixpanel.track("Page view", {
      "Authenticated": getIsAutheticatedFromStore(),
      "Action": "Organization dashbord: " + loadFromStorage('organizationId'),
      "Domain": "App"
    });
  }

  render() {
    const {
      balance,
      isFetching,
      profileDetails,
      profileProjects,
      openGallery,
      user
    } = this.props;

    const org = getUserOrImpersonatingOrg(user)
    const publicProfileSlug = org.public_profile_on && org.profile_domain

    let {
      captured,
      emitted,
      current_c02_captured,
      future_c02_captured,
      total_user_and_community_vcu,
      miterd_credits,
      has_community
    } = balance;

    if (isFetching) return <Loader />;

    return (
        <PublicDashboard
          emitted={emitted}
          captured={captured}
          current_c02_captured={current_c02_captured}
          future_c02_captured={future_c02_captured}
          total_vcus={total_user_and_community_vcu}
          miterd_credits={miterd_credits}
          has_community={has_community}
          profile={profileDetails}
          projects={profileProjects}
          openGallery={openGallery}
          organizationPrivateDashboardMode={true}
          myContributions={[]}
          publicProfileSlug={publicProfileSlug}
        >
        </PublicDashboard>
    );
  }
}

const mapStateToProps = state => ({
  // balance
  errorMessages: getErrorMessages(state),
  balance: getProfileBalance(state),
  isFetching: getIsFetching(state),
  // user
  isAuthed: getIsUserAuthenticated(state),
  profileDetails: getProfileDetails(state),
  profileProjects: getProfileForests(state),
  user: getUserDetails(state),
});

OrganizationPortfolioDashboardContainer.propTypes = {
  // balance
  captured: PropTypes.number,
  emitted: PropTypes.number,
  surface: PropTypes.number,
  balance: PropTypes.object,
  formula: PropTypes.string,
  getPublicProfile: PropTypes.func.isRequired,
  getProfileBalance: PropTypes.func,
  // fetchiing and errors
  isFetching: PropTypes.bool.isRequired,
  errorMessages: PropTypes.string,
  // profile details
  profileDetails: PropTypes.object,
  // projects
  profileProjects: PropTypes.array.isRequired,
  // others
  match: PropTypes.object.isRequired,
  openGallery: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {
  fetchOrganizationDashboard,
  openGallery
})(OrganizationPortfolioDashboardContainer);
