import React from 'react';

const SurfaceArrowsOut = (props) => (
	<svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.8125 4.375H15.625V7.1875" stroke="#79897A" strokeWidth="1.2" strokeLinecap="square"/>
        <path d="M11.2632 7.88824L10.839 8.3125L11.6875 9.16103L12.1118 8.73676L11.2632 7.88824ZM12.1118 8.73676L16.0493 4.79926L15.2007 3.95074L11.2632 7.88824L12.1118 8.73676Z" fill="#79897A"/>
        <path d="M7.1875 15.625H4.375V12.8125" stroke="#79897A" strokeWidth="1.2" strokeLinecap="square"/>
        <path d="M8.73676 12.1118L9.16103 11.6875L8.3125 10.839L7.88824 11.2632L8.73676 12.1118ZM7.88824 11.2632L3.95074 15.2007L4.79926 16.0493L8.73676 12.1118L7.88824 11.2632Z" fill="#79897A"/>
        <path d="M15.625 12.8125V15.625H12.8125" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
        <path d="M12.1118 11.2632L11.6875 10.839L10.839 11.6875L11.2632 12.1118L12.1118 11.2632ZM11.2632 12.1118L15.2007 16.0493L16.0493 15.2007L12.1118 11.2632L11.2632 12.1118Z" fill="#1F3B21"/>
        <path d="M4.375 7.1875V4.375H7.1875" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
        <path d="M7.88824 8.73676L8.3125 9.16103L9.16103 8.3125L8.73676 7.88824L7.88824 8.73676ZM8.73676 7.88824L4.79926 3.95074L3.95074 4.79926L7.88824 8.73676L8.73676 7.88824Z" fill="#1F3B21"/>
    </svg>

);

export default SurfaceArrowsOut;