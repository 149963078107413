import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {TOOLTIP_POSITIONS} from '../../../constants/tooltip';

import cx from 'classnames';

import './Tooltip.css';

class Tooltip extends React.Component {
  
  render() {
    const { position, content, className, style, alwaysOpen, color } = this.props;
    const isContentTypeString = typeof(content) === 'string';

    return (
      <div className={cx("tooltip", className)} style={style}>
        <div className={`tooltip__popup tooltip__popup--${position} ${alwaysOpen ? 'tooltip__popup_always-open' : ''}`}>
          <div className="tooltip__container" style={color ? {"background": color} : {}}>
            {
              isContentTypeString ?
              (<div className="tooltip__text">
                <FormattedMessage 
                  id={'General.passValue'}
                  defaultMessage={' {value}'}
                  values={{value: content}} 
                />
              </div>)
              : content
            }
            <div className="tooltip__arrow"  style={
              color ? {"borderBottomColor" : color, "borderTopColor": color} : {}
              }/>
          </div>
        </div>
        {this.props.children}
      </div>
    );
  }
}

Tooltip.defaultProps = {
  position: 'bottom',
  style: {}
}

Tooltip.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  alwaysOpen: PropTypes.bool,
  position: PropTypes.oneOf(TOOLTIP_POSITIONS),
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  color: PropTypes.string,
};

export default Tooltip;
