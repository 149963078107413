import {loadFromStorage} from "./localStorage"
import {accountSwitcher} from "../constants/messages"
import USER_ORGANIZATION_ROLES from '../constants/userOrganizationRoles'
import getActingPartySubscriptions from "./getActingPartySubscriptions"
import USER_TYPE from "../constants/userType"
const {USER_ORGANIZATION_OWNER_ROLE, USER_ORGANIZATION_COLLABORATOR_ROLE} = USER_ORGANIZATION_ROLES

const isImpersonatingOrg = () => !!loadFromStorage('organizationId')

const getUserOrImpersonatingOrg = (user) => {
	const currentlyActingAsOrganizationId = loadFromStorage('organizationId')
	let result = user
	if(user && user.organizations && currentlyActingAsOrganizationId) {
		let foundOrg = user.organizations && user.organizations.find(
			organization => organization.organization_id === currentlyActingAsOrganizationId
		)
		result = foundOrg || result
	}
	return result
}

const getUserType = () => {
	let userType = USER_TYPE.USER
	if(isImpersonatingOrg()) {
		const {subscriptions} = getActingPartySubscriptions()
		const hasSubscriptions = subscriptions && Object.keys(subscriptions).length > 0
		userType = hasSubscriptions ? USER_TYPE.NEW_MODEL_ORGANIZATION : USER_TYPE.ORGANIZATION
	}

	return userType;
}

const getUserOrOrgAppropriateNameToShow = (userOrOrg) => {
	let result = ''
	if (userOrOrg.company_name) {
		result = userOrOrg.company_name
	}
	else if (userOrOrg.display_name) {
		result = userOrOrg.display_name
	}
	else if (userOrOrg.name && userOrOrg.surname) {
		result = `${userOrOrg.name} ${userOrOrg.surname}`
	}
	return result
}

const getOrganizationRoleNameFromRoleId = (roleId, intl) => {
	let result = null
	if(Number(roleId) === USER_ORGANIZATION_OWNER_ROLE) {
		result = intl.formatMessage(accountSwitcher.userOrganizationRoleOwner)
	}
	else if(Number(roleId) === USER_ORGANIZATION_COLLABORATOR_ROLE) {
		result = intl.formatMessage(accountSwitcher.userOrganizationRoleCollaborator)
	}
	return result
}

export {
	isImpersonatingOrg,
	getUserOrImpersonatingOrg,
	getUserOrOrgAppropriateNameToShow,
	getUserType,
	getOrganizationRoleNameFromRoleId
}
