import includes from 'lodash/includes';
import { formMessages } from '../constants/messages';

export const required = ({value}, intl) => {
  if (!value || (typeof(value) === "string" && value.length === 0)) {
    return intl.formatMessage ?
      intl.formatMessage(formMessages.required) :
      formMessages.required.defaultMessage;
  }
};

export const profileDomainRequired = ({value, allValues}, intl) => {
  if (allValues.public_profile_on && (!value || (typeof(value) === "string" && value.length === 0))) {
    return intl.formatMessage ?
      intl.formatMessage(formMessages.required) :
      formMessages.required.defaultMessage;
  }
};

export const onlyAlphabeticCharacters = ({value}, intl) => {
  //Support international names
  const internationalNamesRegex = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/
  if (value && !internationalNamesRegex.test(value)) {
    return intl.formatMessage ?
      intl.formatMessage(formMessages.onlyAlphabeticCharacters) :
      formMessages.onlyAlphabeticCharacters.defaultMessage;
  }
}

export const requiredMinMaxNormalizer = ({value}, previousValue, min, max) => {
  if (typeof (value) === 'string' && value.length === 0) return value
  if (parseFloat(value) < min || parseFloat(value) > max) return previousValue
  return value
};

export const mustAgree = ({value}, intl) => {
  if (!value) {
    return intl.formatMessage ?
      intl.formatMessage(formMessages.mustAgree) :
      formMessages.mustAgree.defaultMessage;
  }
};

export const email = ({value}, intl) => {
  if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return intl.formatMessage ?
      intl.formatMessage(formMessages.email) :
      formMessages.email.defaultMessage;
  }
};

export const phone = ({value}, intl) => {
  if (value && !/^[0-9-()+\\s-| ]+$/.test(value)) {
    return intl.formatMessage ?
      intl.formatMessage(formMessages.phone) :
      formMessages.phone.defaultMessage;
  }
};

export const mustBePresent = (arr, value, intl) => {
  if (value && !includes(arr, value)) {
    return intl.formatMessage ?
      intl.formatMessage(formMessages.option) :
      formMessages.option.defaultMessage;
  }
};

export const passwordStrength = ({value}, intl) => {
  if (value && !/^(?=.*[A-Z])(?=.*[0-9]).{8,}$/.test(value)) {
    return intl.formatMessage ?
      intl.formatMessage(formMessages.strength) :
      formMessages.strength.defaultMessage;
  }
};

export const maxLength = (maxLength) => ({value}, intl) => {
  if (value && value.length > maxLength) {
    return intl.formatMessage ?
      intl.formatMessage(formMessages.maxLength, { maxLength }) :
      formMessages.maxLength.defaultMessage;
  }
}

export const validCountry = (countries) => ({value}, intl) => {
  if (value && !includes(countries, value)) {
    return intl.formatMessage ?
      intl.formatMessage(formMessages.invalidCountry) :
      formMessages.country.defaultMessage;
  }
}

export const validProfileDomain = ({value}, intl) => {
  if (value && !/^[a-zA-Z0-9-]+$/.test(value)) {
    return intl.formatMessage ?
      intl.formatMessage(formMessages.invalidProfileDomain) :
      formMessages.invalidProfileDomain.defaultMessage;
  }
}

export const validFoundationYear = ({value}, intl) => {
  const MINIMUM_FOUNDATION_YEAR = 1800
  if (value && ((value < MINIMUM_FOUNDATION_YEAR) || (value >= new Date().getFullYear()))) {
    return intl.formatMessage ?
      intl.formatMessage(formMessages.invalidFoundationYear) :
      formMessages.invalidFoundationYear.defaultMessage;
  }
}



export const numberNormalizer = (value, previousValue) => !isNaN(value) ? value : previousValue

export const booleanNormalizer = value => value === 'true' ? true : value === 'false' ? false : value;
