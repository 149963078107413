import React from "react"

export default (name, searchedValue, minHighlightLength) => {
  if (searchedValue == null) return name
  if (
    searchedValue.length <= minHighlightLength
    || !name.toLowerCase().match(searchedValue.toLowerCase())
  ) return name

  const parts = name.split(new RegExp(`(${searchedValue})`, 'gi'))
  return (
    <span>
        {parts.map(
          part =>
            part.toLowerCase() === searchedValue.toLowerCase() ? <span className={'line-input__step-select-typeahead-highlight-matching-text'}>{part}</span> : part)}
      </span>
  )
}
