import React from 'react'
import './ProposalDetails.scss'
import './ProposalDetailsExtras.scss'
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs'
import ReadMore from '../UI/ReadMore'
import Tooltip from '../UI/Tooltip/Tooltip'
import HorizontalScrollWithArrowsContainer
  from '../UI/HorizontalScrollWIthArrowsContainer/HorizontalScrollWIthArrowsContainer'
import InformationForSmallChip from '../UI/Icons/InformationForSmallChip'
import DownloadableFile from '../DownloadableFile/DownloadableFile'

const EXTRA_TYPE_ARRAY = 'array'
const EXTRA_TYPE_RICH_TEXT = 'richText'
const EXTRA_TYPE_TABS = 'tabs'
const EXTRA_TYPE_ATTACHMENT = 'attachments'

const ProposalDetailsExtras = (
  {
    extrasToRender=[],
  }
) => {

  const renderRenderTypeArray = (extra, index) => <div key={index} className={'proposal-details-extra rfp-results__rfp-information__stats__group'}>
    {
      extra.label &&
      <div className={'rfp-results__rfp-information__stats__group-label'}>
        <span>{extra.label}</span>
      </div>
    }
    {
      extra.value
      && extra.value.map(
        ({tooltip, value}, valueIndex) =>
          <div
            key={`${index}-${valueIndex}`}
          >
            {
              tooltip
                ? <Tooltip position={"bottom"}  content={tooltip}>
                  <span className={'rfp-results__rfp-information__stats__group-value'}>{value}<InformationForSmallChip /></span>
                </Tooltip>
                : <span className={'rfp-results__rfp-information__stats__group-value'}>{value}</span>
            }
        </div>
      )
    }
  </div>

  const renderRenderTypeRichText = (extra, index) => <div key={index} className={'proposal-details-extra proposal-details-extras__rich-text'}>
    {extra.label && <div className={'proposal-details-extras__heading'}>{extra.label}</div>}
    <ReadMore text={extra.value} isHtml cutLength={120} minLength={80} />
  </div>

  const renderRenderTypeTabs = (extra, index) => <div key={index} className={'proposal-details-extra proposal-details-extras__tabs'}>
    <Tabs>
      <HorizontalScrollWithArrowsContainer hideScrollbar centered>
        <TabList>
          {extra.tabs && extra.tabs.map((tab, tabIndex) => (
            <Tab key={tabIndex}>{tab.tab_name}</Tab>
          ))}
        </TabList>
      </HorizontalScrollWithArrowsContainer>
      {extra.tabs.map((tab, tabIndex) => (
        <TabPanel key={tabIndex}>
          <div className={'proposal-details-extras__tabs__contents'}>
            {tab.contents.map((content, contentIndex) => renderExtra(content, `${tabIndex}-${contentIndex}`, false))}
          </div>
        </TabPanel>
      ))}
    </Tabs>
  </div>

  const renderRenderTypeAttachment = (extra, index) => {
    return (extra.items || []).length > 0 && <div className={'proposal-details-extra'}>
        {
          (extra.items).map(
            d => <DownloadableFile fileName={d.file_name} fileUrl={d.url} fileType={d.file_type} />
          )
        }
      </div>
  }

  const renderExtra = (extra, index, allowTabs=true) => {
    let result = null
    if (extra.type === EXTRA_TYPE_ARRAY) {
      result = renderRenderTypeArray(extra, index)
    }
    else if (extra.type === EXTRA_TYPE_RICH_TEXT) {
      result = renderRenderTypeRichText(extra, index)
    }
    else if (allowTabs && extra.type === EXTRA_TYPE_TABS) {
      result = renderRenderTypeTabs(extra, index)
    } 
    else if (extra.type === EXTRA_TYPE_ATTACHMENT) {
      result = renderRenderTypeAttachment(extra, index) 
    }

    return result
  }

  const renderMain = () => <div className={'proposal-details-extras'}>
    {
      Array.isArray(extrasToRender) && extrasToRender.map(renderExtra)
    }
  </div>

  return renderMain()
}


export default ProposalDetailsExtras