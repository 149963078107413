import {getUserOrImpersonatingOrg, isImpersonatingOrg} from './usersAndOrganizations'

const getActingPartySubscriptions = () => {
  const partyDetails = JSON.parse(localStorage.getItem('ref__state')).userSession.details

  let userSubscriptionType = null
  let impersonatingOrgSubscriptionType = null
  let userIsImpersonatingOrg = isImpersonatingOrg()
  let userOrOrgDetails = getUserOrImpersonatingOrg(partyDetails)

  if(userIsImpersonatingOrg && partyDetails.organizations && typeof Array.isArray(partyDetails.organizations)) {
    let foundOrganizationInformation = partyDetails.organizations.find(o => o.organization_id === userOrOrgDetails.organization_id)
    if(foundOrganizationInformation && foundOrganizationInformation.subscriptions) {
      impersonatingOrgSubscriptionType = foundOrganizationInformation.subscriptions
    }
  }

  if(!userIsImpersonatingOrg && partyDetails.subscriptions && typeof partyDetails.subscriptions === 'object') {
    userSubscriptionType = partyDetails.subscriptions
  }

  return {
    subscriptions: userIsImpersonatingOrg ? impersonatingOrgSubscriptionType : userSubscriptionType,
    actingUserOrOrg: userOrOrgDetails
  }
}

export default getActingPartySubscriptions