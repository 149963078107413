import React from 'react';

const MapTrifold = props => (
	<svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M8.04091 3.1069V14.5614H6.84091V3.1069H8.04091ZM13.1318 5.41818V16.8727H11.9318V5.41818H13.1318Z" fill="#A5B1A6"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M7.51072 2.36407L12.6016 4.90953L18.2227 3.50425V16.1957L12.462 17.6359L7.3711 15.0905L1.75 16.4957V3.80425L7.51072 2.36407ZM2.95 4.74119V14.9588L7.51072 13.8186L12.6016 16.3641L17.0227 15.2588V5.04119L12.462 6.18136L7.3711 3.63591L2.95 4.74119Z" fill="#223327"/>
	</svg>
);

export default MapTrifold;
