import { MAX_DIMENSION, VALID_FILE_TYPES } from "../constants/avatarFileUpload"

export const validImageFormat = (file) => {
    const type = file.type.split('/').pop().toLowerCase()
    if (!VALID_FILE_TYPES.includes(type)) {
        return false
    }
    return true
}

export const processAvatarImage = (file, fileName) => {
    const reader = new FileReader()
    const processedAvatarPromise =  new Promise((resolve, reject) => {

        reader.onload = () => {
            const image = new Image()
            image.src = reader.result 

            image.onload = async () => {
                const currentHeight = image.height
                const currentWidth = image.width
                const smallerOriginalDimension =  Math.min(currentHeight, currentWidth)

                const newDimension = smallerOriginalDimension > MAX_DIMENSION ?
                    MAX_DIMENSION : smallerOriginalDimension

                const canvas = document.createElement('canvas')
                canvas.width = newDimension
                canvas.height = newDimension

                canvas.getContext('2d').drawImage(
                    image,
                    (currentWidth - smallerOriginalDimension) / 2,
                    (currentHeight - smallerOriginalDimension) / 2,
                    smallerOriginalDimension,
                    smallerOriginalDimension,
                    0,
                    0,
                    newDimension,
                    newDimension,
                )

                const imageURL = canvas.toDataURL(file.type)
                const blob = await fetch(imageURL).then((r) => r.blob())
                const newImageFile = new File(
                    [blob], 
                    `${fileName}.${file.type.split('/').pop()}`, 
                    {
                        type: file.type
                    }
                )
                resolve({newImageFile: newImageFile, imageURL: imageURL})
            }

            image.onerror = reject
        }
        reader.onerror = reject
    })

    reader.readAsDataURL(file)
    return processedAvatarPromise
}

export const imageIsSquare = (file) => {
    const reader = new FileReader()
    const checkIsSquarePromise =  new Promise((resolve, reject) => {

        reader.onload = () => {
            const image = new Image()
            image.src = reader.result

            image.onload = () => {
              const height = image.height
              const width = image.width
              if (height === width) {
                return resolve(true)
              }
              return resolve(false)
            }
            image.onerror = reject
          }
        reader.onerror = reject
    })

    reader.readAsDataURL(file)
    return checkIsSquarePromise
}
