import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchForestPatrons, resetForestPatrons } from '../actions/forests';
import * as selector from '../selectors/forests';
import { getUserDetails } from '../selectors/userSession';

import Patrons from '../components/Forests/Patrons/Patrons';
import Loader from '../components/UI/Loader/Loader';
import FullErrorFetching from '../components/UI/Interface/FullErrorFetching';

class PatronsContainer extends Component {
  constructor() {
    super();

    this.pageLength = 10;
  }

  componentWillMount() {
    const { fetchForestPatrons, resetForestPatrons, forestId, page, sectorId, patronsForestId, patronsSectorId  } = this.props;
    if(patronsForestId !== forestId || patronsSectorId !== sectorId) {
        resetForestPatrons();
        fetchForestPatrons({ forestId, sectorId }, 1, this.pageLength);
    } else {
      fetchForestPatrons({forestId, sectorId}, page, this.pageLength);
    }
  }

  render() {
    const {
      data,
      registry,
      errorMessages,
      fetchForestPatrons,
      forestId,
      isFetching,
      page,
      patrons,
      podium,
      user,
      sectorId,
      patronsForestId,
      patronsSectorId,
    } = this.props;

    if (errorMessages) {
      return (
        <FullErrorFetching
          errorMessage={errorMessages}
          retry={fetchForestPatrons.bind(this, {forestId, sectorId}, page, this.pageLength)}
        />
      );
    }
    const isPreviousForestData = patronsForestId !== forestId || patronsSectorId !== sectorId;
    if ((isFetching && (Object.keys(patrons).length <= 0)) || isPreviousForestData) return <Loader />;
    return (
      <Patrons
        data={data}
        getMore={fetchForestPatrons.bind(this, {forestId, sectorId})}
        page={page}
        pageLength={this.pageLength}
        patrons={patrons}
        podium={podium}
        user={user}
        isFetching={isFetching}
        isSector={Boolean(sectorId)}
        registry={registry}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  data: selector.getPatronsData(state),
  errorMessages: selector.getPatronsErrorMessages(state),
  isFetching: selector.getIsFetchingPatrons(state),
  page: selector.getPatronsPage(state),
  patrons: selector.getPatrons(state),
  podium: selector.getPatronsPodium(state),
  patronsForestId: selector.getPatronsForestId(state),
  patronsSectorId: selector.getPatronsSectorId(state),
  user: getUserDetails(state),
});

PatronsContainer.propTypes = {
  fetchForestPatrons: PropTypes.func.isRequired,
  resetForestPatrons: PropTypes.func.isRequired,
  forestId: PropTypes.number.isRequired,
  sectorId: PropTypes.number,
  patronsForestId: PropTypes.number,
  patronsSectorId: PropTypes.number,
  data: PropTypes.object,
  errorMessages: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  page: PropTypes.number,
  patrons: PropTypes.array,
  podium: PropTypes.array,
  user: PropTypes.object,
  registry: PropTypes.string,
};

export default connect(mapStateToProps, {
  fetchForestPatrons,
  resetForestPatrons,
})(PatronsContainer);
