import React from 'react';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from "react-intl";

// localstorage
import { loadFromStorage, saveToStorage } from '../../utils/localStorage';

// components
import { Button } from '@reforestum/shared-components';
import Tooltip from '../UI/Tooltip/Tooltip';
import Overlay from '../UI/Overlay/Overlay';
import { links } from '@reforestum/shared-utils';

class ActivateSharesButtonTooltip extends React.Component {
    constructor (props) {
        super(props);
    
        this.state = {
            showTooltip: false,
            showOverlay: false,
            showButton: true
        };

        this.handleCloseTooltip = this.handleCloseTooltip.bind(this);
        this.handleActivationMixpanelTracking = this.handleActivationMixpanelTracking.bind(this);
    }

    componentDidMount() {
        this.enterpriseOffsetDetails = loadFromStorage('enterpriseOffset') || {};

        if(this.enterpriseOffsetDetails.showActivationOverlay && this.props.showInteractiveOverlay){
            this.timer = setTimeout(
                () => this.setState(({ showTooltip: true, showOverlay: true })),
                5000,
            );
        }
    }

    handleCloseTooltip(){
        this.setState({ showTooltip: false, showOverlay: false })
        saveToStorage("enterpriseOffset", {...this.enterpriseOffsetDetails, showActivationOverlay: false});
        clearTimeout(this.timer);

        // mixpanel tracking of clicks
        mixpanel.track_links('#special-dimiss__btn', 'Dismiss activation click');
        mixpanel.track('Dismiss activation click');
    }

    handleActivationMixpanelTracking(){
        mixpanel.track_links('#activate-shares-via-register__btn', 'Activate Shares in Corp profile no-auth click');
        mixpanel.track('Activate Shares in Corp profile no-auth click');
    }

    render(){
        const { activationId, isAuthenticated, offsetClaimErrors } = this.props;
        const { showTooltip, showOverlay } = this.state;

        return (
            <div>
                {showOverlay && <Overlay className="overlay-enterprise-activation"/>}
                <div className="activation-shares__wrapper">
                    {isAuthenticated && !offsetClaimErrors
                        ?   <Button 
                                className="topbar-guest__item activate-shares__btn"
                                id="activate-shares-and-offset-claim__btn"
                                onClick={() => this.props.handleOffsetClaimingClick()}
                            >
                                <FormattedMessage
                                id={'Header.sharesActivation'}
                                defaultMessage={'Activate my shares'}
                                />
                            </Button>
                        :   <NavLink to={links.getEnterpriseActivationUrl(activationId, 'register')}>
                                <Button
                                    className="topbar-guest__item activate-shares__btn"
                                    id="activate-shares-via-register__btn"
                                    onClick={() => this.handleActivationMixpanelTracking()}
                                >
                                    <FormattedMessage
                                    id={'Header.sharesActivation'}
                                    defaultMessage={'Activate my shares'}
                                    />
                                </Button>
                            </NavLink>
                    }
                    {showTooltip &&
                        <Tooltip
                            className="special-tooltip"
                            content={
                            <div>
                                {isAuthenticated 
                                ?   <FormattedMessage
                                        id={'EnterpriseActivation.TooltipAuthenticated'}
                                        defaultMessage={'We are pleased to have you here! Click to activate your shares and unlock access.'}
                                    />
                                :   <FormattedMessage
                                        id={'EnterpriseActivation.Tooltip'}
                                        defaultMessage={'Become part of this exclusive community! Register to activate your shares and unlock access.'}
                                    />
                                }
                                
                                <br />
                                <Button
                                    className="special-dimiss__btn"
                                    id="special-dimiss__btn"
                                    onClick={() => this.handleCloseTooltip()}
                                >
                                    <FormattedMessage
                                        id={'EnterpriseActivation.Dismiss'}
                                        defaultMessage={'Not now'}
                                    />
                                </Button>
                            </div>
                            }
                         />
                    }
                </div>
            </div>
        )
    }
}

ActivateSharesButtonTooltip.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    activationId: PropTypes.string.isRequired,
    renderSuccessActivationAlert: PropTypes.func.isRequired,
    showInteractiveOverlay: PropTypes.bool.isRequired,
    offsetClaimErrors: PropTypes.object,
    handleOffsetClaimingClick: PropTypes.func
}


export default ActivateSharesButtonTooltip;
