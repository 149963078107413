import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {intlShape} from 'react-intl';
import cx from 'classnames';
import {getUnit} from '../../../utils/units';
import StepInput from '../../Calculator/StepInput';
import StepTypeahead from '../../Calculator/StepTypeahead';
import StepSelect from '../../Calculator/StepSelect';
import StepLast from '../../Calculator/StepLast';
import StepAddresses from '../../Calculator/StepAddresses';

import {INTEGER, LIST, ONE_ADDRESS, TEXT, TWO_ADDRESSES, TYPEAHEAD,} from '../../../constants/stepDataTypes'

import './LineInput.css'
import StepSelectTypeahead from "../../Calculator/StepSelectTypeahead"

class Step extends Component {
  constructor(props) {
    super()

    this.renderPretext = this.renderPretext.bind(this)
    this.renderPostext = this.renderPostext.bind(this)
  }

  componentWillUnmount() {
    const {change, untouch, step, data_type} = this.props

    if (data_type === TWO_ADDRESSES) {
      change(`field_${step.id}_1`, '')
      change(`field_${step.id}_2`, '')

      if (untouch) {
        untouch(`field_${step.id}_1`)
        untouch(`field_${step.id}_2`)
      }
    } else {
      change(`field_${step.id}`, '')
      untouch && untouch(`field_${step.id}`)
    }
  }

  renderPretext() {
    const {pretext} = this.props.step
    return pretext &&
      <span className="line-input__text-before">
        {pretext}
      </span>
  }

  renderPostext(className) {
    const {postext, unit_base} = this.props.step
    return (
      <span className={cx('line-input__text-after', className)}>
        {unit_base && `${getUnit(unit_base)} `}
        {postext != null && postext.length > 0 ? postext : ''}
      </span>
    )
  }

  useManuallySavedFormValuesIfPossible(key) {
    if (this.props.manuallyChangedFormValues && this.props.manuallyChangedFormValues[`field_${key}`]) {
      return this.props.manuallyChangedFormValues[`field_${key}`].toString()
    }
    return this.props.formValues[`field_${key}`]
  }

  renderInputs() {
    const {
      addAlert,
      options,
      step,
      handleNextStep,
      intl,
      formValues,
      change,
      sessionToken,
      stepsList,
      doExitEditMode,
      sources,
      sourceToEdit,
      rememberTypedName,
      jumpingBackAStep,
      isFirstStep,
      setPremadePath,
      setPremadeLoaded,
      stepIndex,
      jumpBackToStepIndex,
      isMostRecentStep,
    } = this.props
    const {data_type: inputType} = step

    if (isFirstStep) {
      return (
        <StepSelectTypeahead
          sourceToEdit={sourceToEdit}
          isMostRecentStep={isMostRecentStep}
          stepIndex={stepIndex}
          jumpBackToStepIndex={jumpBackToStepIndex}
          doExitEditMode={doExitEditMode}
          manuallyChangedFormValues={this.props.manuallyChangedFormValues}
          options={options}
          step={step}
          intl={intl}
          stepsList={stepsList}
          handleNextStep={handleNextStep}
          setPremadePath={setPremadePath}
          setPremadeLoaded={setPremadeLoaded}
        />
      )
    }

    switch (inputType) {
      case LIST:
        return (
          <StepSelect
            isMostRecentStep={isMostRecentStep}
            stepIndex={stepIndex}
            jumpBackToStepIndex={jumpBackToStepIndex}
            rememberTypedName={rememberTypedName}
            doExitEditMode={doExitEditMode}
            handleNextStep={handleNextStep}
            options={options}
            step={step}
            intl={intl}
            stepsList={stepsList}
            manuallySavedValue={this.useManuallySavedFormValuesIfPossible(step.id)}
            jumpingBackAStep={jumpingBackAStep}
            formValues={formValues}
            change={change}
          />
        )

      case TYPEAHEAD:
        return <StepTypeahead
          isMostRecentStep={isMostRecentStep}
          stepIndex={stepIndex}
          jumpBackToStepIndex={jumpBackToStepIndex}
          options={options}
          manuallyChangedFormValues={this.props.manuallyChangedFormValues}
          sourceToEdit={this.props.sourceToEdit}
          stepsList={stepsList}
          handleNextStep={handleNextStep}
          step={step}
          intl={intl}
          value={formValues[`field_${step.id}`]}
          sessionToken={sessionToken}
        />

      case INTEGER:
      case TEXT:
        return (
          <StepInput
            isMostRecentStep={isMostRecentStep}
            sourceToEdit={this.props.sourceToEdit}
            stepIndex={stepIndex}
            jumpBackToStepIndex={jumpBackToStepIndex}
            step={step}
            handleNextStep={handleNextStep}
            intl={intl}
            value={this.useManuallySavedFormValuesIfPossible(step.id)}
          />
        )

      case ONE_ADDRESS:
        return (
          <StepTypeahead
            isMostRecentStep={isMostRecentStep}
            stepIndex={stepIndex}
            jumpBackToStepIndex={jumpBackToStepIndex}
            handleNextStep={handleNextStep}
            stepsList={stepsList}
            intl={intl}
            step={step}
            usePlacesAPI={true}
            value={formValues[`field_${step.id}`]}
            sessionToken={sessionToken}
          />
        )

      case TWO_ADDRESSES:
        return (
          <StepAddresses
            isMostRecentStep={isMostRecentStep}
            stepIndex={stepIndex}
            addAlert={addAlert}
            jumpBackToStepIndex={jumpBackToStepIndex}
            renderPostext={this.renderPostext}
            step={step}
            intl={intl}
            handleNextStep={handleNextStep}
            formValues={formValues}
            change={change}
            sessionToken={sessionToken}
            sources={sources}
            sourceToEdit={sourceToEdit}
          />
        )

      case 'last':
        return <StepLast
          isMostRecentStep={isMostRecentStep}
          rememberTypedName={this.props.rememberTypedName}
          typedSourceName={this.props.typedSourceName}
          sourceToEditName={this.useARememberedSourceName()}
          intl={intl}
          step={step}
        />

      default:
    }
  }

  useARememberedSourceName() {
    if (this.props.sourceToEditName && this.props.sourceToEditName !== '') return this.props.sourceToEditName
    if (this.props.typedSourceName && this.props.typedSourceName !== '') return this.props.typedSourceName
    return ''
  }

  render() {
    return (
      <div className="carbon-calc-step line-input">
        {this.renderPretext()}
        {this.renderInputs()}
        {this.props.step.data_type !== TWO_ADDRESSES && this.renderPostext()}
      </div>
    )
  }
}

Step.propTypes = {
  addAlert: PropTypes.func,
  change: PropTypes.func.isRequired,
  jumpBackToStepIndex: PropTypes.func,
  options: PropTypes.array,
  step: PropTypes.object.isRequired,
  handleNextStep: PropTypes.func,
  intl: intlShape.isRequired,
  formValues: PropTypes.object,
  manuallyChangedFormValues: PropTypes.object,
  stepsList: PropTypes.array,
  sessionToken: PropTypes.string,
  sourceToEditName: PropTypes.string,
  doExitEditMode: PropTypes.func,
  sources: PropTypes.array,
  sourceToEdit: PropTypes.any,
  rememberTypedName: PropTypes.func,
  jumpingBackAStep: PropTypes.bool
}

export default Step
