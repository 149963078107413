import React from 'react';

const Car = props => (
  <svg {...props} width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.2313 27.4193H31.7118C31.9573 27.4162 32.1916 27.3159 32.3634 27.1404C32.5352 26.965 32.6304 26.7286 32.6283 26.483V23.8242C32.6311 23.0408 32.3751 22.2784 31.9 21.6554C31.425 21.0325 30.7574 20.5839 30.0012 20.3793L24.5849 18.9768C24.2054 18.8771 23.86 18.6764 23.5854 18.3961L20.2471 14.9946C19.7776 14.5146 19.2172 14.1329 18.5986 13.8717C17.98 13.6106 17.3156 13.4753 16.6441 13.4736H9.69106C9.05569 13.4745 8.42688 13.6021 7.84145 13.8491C7.25603 14.096 6.72575 14.4572 6.28167 14.9117L2.3034 18.9729C1.90609 19.3794 1.60287 19.8682 1.41514 20.4048C1.22742 20.9413 1.15978 21.5125 1.21698 22.0781L1.6713 26.7042C1.69003 26.9005 1.78148 27.0827 1.92767 27.215C2.07387 27.3473 2.26425 27.4202 2.46143 27.4193H4.8397" stroke="#85A995" strokeWidth="1.2" strokeLinecap="round"/>
    <path d="M11.5957 27.4189H21.4051" stroke="#85A995" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M24.7821 30.8046C26.6476 30.8046 28.1599 29.2887 28.1599 27.4189C28.1599 25.549 26.6476 24.0332 24.7821 24.0332C22.9166 24.0332 21.4043 25.549 21.4043 27.4189C21.4043 29.2887 22.9166 30.8046 24.7821 30.8046Z" stroke="#1F3B20" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.21762 30.8046C10.0831 30.8046 11.5954 29.2887 11.5954 27.4189C11.5954 25.549 10.0831 24.0332 8.21762 24.0332C6.35213 24.0332 4.83984 25.549 4.83984 27.4189C4.83984 29.2887 6.35213 30.8046 8.21762 30.8046Z" stroke="#1F3B20" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.6484 19.0436H15.2207C15.1062 19.0436 14.9929 19.021 14.8872 18.977C14.7815 18.9331 14.6855 18.8687 14.6047 18.7876C14.524 18.7065 14.46 18.6102 14.4166 18.5043C14.3731 18.3984 14.3511 18.2849 14.3516 18.1705V14.5557" stroke="#1F3B20" strokeWidth="1.2" strokeLinecap="square"/>
    <path d="M32.6284 30.8047H1.19727" stroke="#1F3B20" strokeWidth="1.2" strokeLinecap="square" strokeLinejoin="round"/>
    <path d="M27.2518 12.7245C30.3173 12.7245 32.8024 10.2341 32.8024 7.16208C32.8024 4.09001 30.3173 1.59961 27.2518 1.59961C24.1863 1.59961 21.7012 4.09001 21.7012 7.16208C21.7012 10.2341 24.1863 12.7245 27.2518 12.7245Z" stroke="#1F3B20" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

);

export default Car;
