import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'react-layout-components';
import {
  injectIntl,
  intlShape
} from 'react-intl';

import { authMessages } from '../../constants/messages';
import { getPrivacyOptionsForIndividuals } from '../../utils/getPrivacyOptions';

import VerticalCenter from '../Layout/VerticalCenter/VerticalCenter';
import CoverImage from '../Layout/CoverImage/CoverImage';
import AuthHeader from './AuthHeader';
import AuthForm from './AuthForm';
import SignupForm from './SignupForm';

import Logo from '../UI/Logo/Logo';

import { SIGNUP_IMAGE } from '../../constants/signupImage';

const Signup = ({
  handleSignup,
  intl,
  isLoading,
  signupErrorMessages
}) => {
  
  const privacyOptions = getPrivacyOptionsForIndividuals(intl);

  return (
    <Box width="100%">
      <CoverImage img={SIGNUP_IMAGE} />
      <VerticalCenter className="signup-sidebar">
        <div className="reforestum-logo__wrapper">
          <Logo className="reforestum__logo" />
        </div>
        <AuthHeader
          title={intl.formatMessage(authMessages.signupTitle)}
          text={intl.formatMessage(authMessages.signupText)}
        />
        <AuthForm>
          <SignupForm
            handleSignup={handleSignup}
            errorMessages={signupErrorMessages}
            isLoading={isLoading}
            privacyOptions={privacyOptions}
          />
        </AuthForm>
      </VerticalCenter>
  
    </Box>
  ) 
};

Signup.propTypes = {
  className: PropTypes.string,
  handleSignup: PropTypes.func.isRequired,
  intl: intlShape,
  isLoading: PropTypes.bool.isRequired,
  signupErrorMessages: PropTypes.string,
};

export default injectIntl(Signup);
