import history from "../setup/history"

const urlSpecificAfterSwitchActions = () => {
	if(window.location.href.includes('/checkout') && window.location.href.includes('sources=')) {
		history.push('/create-forest/carbon')
		window.location.reload()
	} else if(window.location.href.includes('/activation')) {
		return
	}
	else {
		window.location.reload()
	}
}

export {
	urlSpecificAfterSwitchActions
}
