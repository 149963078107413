import {FormattedMessage} from 'react-intl'
import {DEFAULT_PROJECT_IMAGE} from '../../RequestForProposalsResponseCard/rfpConstants'
import {discoverMessages} from '../../../constants/messages'
import {Button, ButtonLink} from '@reforestum/shared-components'
import Tick from '../../UI/Icons/Tick'
import React from 'react'
import './RequestProposalModal.scss'
import {links} from '@reforestum/shared-utils'
import ExternalLink from '../../UI/Icons/ExternalLink'

const MAX_PROJECT_TITLE_LENGTH = 16

const RequestProposalModal = (
  {
    searchResults,
    selectedProjectId,
    setModalToShow,
    setApproximateTargetVolume,
    approximateTargetVolume,
    setAdditionalDetails,
    additionalDetails,
    renderProjectTinyInfoBlocks,
    handleSubmit=()=>{},
    intl
  }={}
) => {
  const renderRequestProposalModal = () => {
    const selectedProject = searchResults.find(p => p.id === selectedProjectId)
    if(!selectedProject) {
      setModalToShow(false)
    }
    return <div className={'discover__request-proposal-modal'}>
      <h1>
        <FormattedMessage
          id={`discover.DiscoverRequestProposalModalTitle`}
          defaultMessage={'Request Proposal'}
        />
      </h1>
      <p>
        <FormattedMessage
          id={`discover.DiscoverRequestProposalModalExplanationOne`}
          defaultMessage={`Request proposals from this project's suppliers.`}
        />
      </p>
      <div className={'discover__request-proposal-modal__project-view'}>
        <div className={'discover__request-proposal-modal__project-view__project-image'}>
          <img alt={'Project depiction'} src={selectedProject.image || DEFAULT_PROJECT_IMAGE}/>
        </div>
        <div className={'discover__request-proposal-modal__project__tiny-info-and-project-name'}>
          <div className={'discover__request-proposal-modal__project__project-name-and-link'}>
            <div className={'discover__request-proposal-modal__project__project-name'}>
              {selectedProject.name.length > MAX_PROJECT_TITLE_LENGTH ? `${selectedProject.name.substring(0, MAX_PROJECT_TITLE_LENGTH)}...` : selectedProject.name}
            </div>
            <ButtonLink
              onClick={(e) => {
                e.preventDefault();
                window.open(links.getProjectUrl(selectedProject.id), '_blank')
              }}
              className={'discover__request-proposal-modal__project__project-link'}
              to={links.getProjectUrl(selectedProject.id)}
              target="_blank"
            >
              <FormattedMessage
                id={`discover.DiscoverModalProjectLink`}
                defaultMessage={'Project Details'}
              />
              <ExternalLink />
            </ButtonLink>
          </div>
          <div className={'discover__request-proposal-modal__project__tiny-info'}>
            {renderProjectTinyInfoBlocks({project: selectedProject, shouldRender: {location: true, typology: true}})}
          </div>
        </div>
      </div>
      <p>
        <FormattedMessage
          id={`discover.DiscoverRequestProposalModalExplanationTwo`}
          defaultMessage={'Fill in the approximate quantity add (optionally) additional details for your request.'}
        />
      </p>
      <div className={'discover__request-proposal-modal__form-instruction'}>
        <FormattedMessage
          id={`discover.DiscoverRequestProposalModalApproximateTargetVolume`}
          defaultMessage={`Approximate target volume (tCO2e)`}
        />
      </div>
      <div className={'discover__request-proposal-modal__input-with-floating-value'}>
        <input
          tabIndex={1}
          type={'number'}
          autoComplete={false}
          autoFocus={true}
          onChange={(e) => setApproximateTargetVolume(e.target.value)}
          value={approximateTargetVolume}
        />
        <div className={'discover__request-proposal-modal__input-floating-value'}>
          <FormattedMessage
            id={`discover.DiscoverRequestProposalModalApproximateTargetVolumeValue`}
            defaultMessage={`tCO2e`}
          />
        </div>
      </div>
      <div className={'discover__request-proposal-modal__form-instruction'}>
        <FormattedMessage
          id={`discover.DiscoverRequestProposalModalAdditionalRequestDetails`}
          defaultMessage={`Additional request details (optional)`}
        />
      </div>
      <textarea
        tabIndex={1}
        autoComplete={false}
        onChange={(e) => setAdditionalDetails(e.target.value)}
        value={additionalDetails}
        placeholder={intl.formatMessage(discoverMessages.additionalDetailsPlaceholder)}
      />
      <div className={'discover__request-proposal-modal__form-final-message'}>
        <FormattedMessage
          id={`discover.DiscoverRequestProposalModalFinalMessage`}
          defaultMessage={`You will be able to view the submitted proposals in this platform in the dedicated proposals page.`}
        />
      </div>
      <div className={'discover__request-proposal-modal__cta-container'}>
        <Button
          onClick={handleSubmit}
        >
          <Tick stroke={'#fff'} />
          <FormattedMessage
            id={`discover.DiscoverRequestProposalModalCTA`}
            defaultMessage={'Confirm Request'}
          />
        </Button>
      </div>
    </div>
  }

  return renderRequestProposalModal()
}

export default RequestProposalModal