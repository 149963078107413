import React from 'react'
import PropTypes from 'prop-types'
import { ButtonLink } from '@reforestum/shared-components'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { greetingMessages,  greetingCTAMessages} from '../../constants/messages';

import './Greeting.css'
import {getUserOrImpersonatingOrg} from "../../utils/usersAndOrganizations"

const Greeting = ({ isAuthed, user, status, link }) => (
  <div>
      {isAuthed && 
        <div className="dashboard__loggedIn_greeting_text">
            <FormattedMessage
              id={'Dashboard.LoggedInGreeting'}
              defaultMessage={'Welcome {name}!'}
              values={{ name: getUserOrImpersonatingOrg(user).company_name || getUserOrImpersonatingOrg(user).name }}
            />
          </div>
      }
      <div className="dashboard__greeting_text_status">
        <FormattedMessage 
          {...greetingMessages[status]}
        />
      </div>
      <ButtonLink to={link} className="dashboard__greeting_button">
        <FormattedMessage 
          {...greetingCTAMessages[status]}
        />
      </ButtonLink>
  </div>
);


Greeting.propTypes = {
  isAuthed: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  status: PropTypes.oneOf(['neutral', 'positive', 'negative']).isRequired,
  link: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(Greeting)
