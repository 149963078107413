const scrollToElementId = ({id, offset=0, offsetSmallScreen=0, smallScreenBreakpoint=900, elementToScrollId=null}={}) => {
	let elementToScroll = window
	if(elementToScrollId) {
		elementToScroll = document.getElementById(elementToScrollId)
	}

	const element = document.getElementById(id)
	if(element && elementToScroll) {
		const headerOffset = window.innerWidth < smallScreenBreakpoint ? offsetSmallScreen : offset
		const elementPosition = element.getBoundingClientRect().top
		const offsetPosition = elementPosition + window.pageYOffset - headerOffset

		elementToScroll.scrollBy({
			top: offsetPosition,
			behavior: "smooth"
		})
	}
}

export default scrollToElementId
