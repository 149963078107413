import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { links } from '@reforestum/shared-utils';

import { fetchForests } from '../actions/forests';
import { getSources } from '../actions/carbon';
import { getBalance } from '../actions/balance';
import { updateLoadStatus } from '../actions/UI';
import { addSource } from '../actions/carbon';

import {
  getForests,
  getIsFetching as getIsFetchingForests,
  getErrorMessages as getForestsErrorMessages
} from '../selectors/forests';
import {
  getSourcesToOffset,
  getIsFetching as getIsFetchingSources,
  getErrorMessages as getSourcesErrorMessages,
  getIsAdding as getIsAddingSource,
  getAllSources
} from '../selectors/carbon';
import {
  getCaptured,
  getEmitted,
  getOxygen,
  getIsFetching as getIsFetchingBalance,
  getErrorMessages as getBalanceErrorMessages
} from '../selectors/balance';
import { getCurrency } from '../selectors/userSession';
import { REDIRECT_MIN_LOADER_TIME } from '../constants/app';

import Create from '../components/Forests/Create/Create';
import LoaderRedirect from '../components/UI/Loader/LoaderRedirect';
import Loader from '../components/UI/Loader/Loader';
import FullErrorFetching from '../components/UI/Interface/FullErrorFetching';

import {
  getCalculatedPrice
} from '../actions/checkout';
import { PAYMENT_GATEWAY } from '../reducers/checkout';
import { keepToMetric } from '../utils/units';
import { OFFSET_TYPE_CREDIT } from "../constants/offsetTypes";

import { carbonAmountIn } from '../utils/carbon';
import getIsAutheticatedFromStore from '../utils/getIsAutheticatedFromStore';

const initialFormState = {
  area: 0,
  budget: 0,
  selectedSources: [],
};

const initalFormKeys = Object.keys(initialFormState);

const queryStringToArray = string => (
  string.split(',').map(value => parseInt(value))
);

const getInitialParam = (URLParams, key) => {
  const value = URLParams.get(key);

  if (!value) return initialFormState[key];

  switch (key) {
    case 'selectedSources':
      return queryStringToArray(value);
    default:
      return parseInt(value);
  }
};

const getInitialFormState = (search) => {
  const state = {};
  const URLParams = new URLSearchParams(search);

  initalFormKeys.map(key => state[key] = getInitialParam(URLParams, key));

  return state;
};

const getNewSearchQuery = (state) => {
  let params = new URLSearchParams();

  initalFormKeys.forEach(key => {
    const value = state[key]

    if (key !== 'selectedSources') {
      value && params.append(key, value)
    } else {
      value.length && params.append(key, value)
    }
  })

  return params.toString()
}


class CreateForestContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      carbon: 0,
      forestDetailsId: null,
      loading: false,
      timeFinished: false,
      ...getInitialFormState(props.location.search)
    };

    this.setCurrentItemCheck = this.setCurrentItemCheck.bind(this);
    this.selectAll = this.selectAll.bind(this);
  }

  componentDidMount() {
    mixpanel.track("Page view", {
      "Authenticated": getIsAutheticatedFromStore(),
      "Action": "Create forest",
      "Mode": this.mode,
      "Domain": "App"
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state !== prevState) {
      // Timeout for better input rerender reaction
      setTimeout(this.updateSearchQuery, 100)
    }
  }

  componentWillMount() {
    const {
      history,
      match: { params: { mode } },
      addSource,
      isAddingSource,
      isFetchingForests,
      isFetchingSources,
      isFetchingBalance,
      forests,
      location,
    } = this.props;

    let self = this;

    const shouldSelectAll = get(location, ['state', 'selectAll']);

    if (mode !== 'area' && mode !== 'budget' && mode !== 'carbon') {
      history.replace(links.getCreateForestUrl('carbon'), { selectAll: shouldSelectAll });
    }

    if (shouldSelectAll) {
      this.selectAll();
    }

    if (this.props.match.params.mode === 'redirect') {
      self.setState({ loading: 'true' });
      this.props.updateLoadStatus('loading');
      let path = [];

      let data = this.props.location.search;
      data = data.replace('?', '');
      data = data.split('&');
      let weight = parseInt(data[1].replace('utm_carbon=', ''), 10);
      let name = data[2].replace('utm_source_name=', '');
      name = name.split('%20').join(' ');
      name = name.split('-').join(' ');
      let load_time = REDIRECT_MIN_LOADER_TIME;

      addSource(
        weight,
        name,
        1,
        path,
        'redirect'
      );

      setTimeout(() => {
        if (!isAddingSource) {
          this.fetch();
        }
      }, load_time / 2);


      setTimeout(() => {
        self.setState({ timeFinished: true });
        this.props.updateLoadStatus('active');
        if (
          !isFetchingForests &&
          !isFetchingSources &&
          !isFetchingBalance &&
          forests.length > 0
        ) {
          self.setState({ loading: false });
          self.setCurrentItemCheck();
        }

      }, load_time);
    } else {
      this.fetch();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isFetchingSources } = this.props

    if (isFetchingSources && !nextProps.isFetchingSources) {
      const { selectedSources } = this.state
      const { sources } = nextProps

      const summCarbon = sources.reduce((sum, { id, amount }) => {
        if (selectedSources.indexOf(id) !== -1) {
          return sum + amount
        }
        return sum
      }, 0)

      this.setState({ carbon: summCarbon })
    }
  }

  fetch() {
    const {
      fetchForests,
      getSources,
      getBalance,
    } = this.props;

    fetchForests();
    getSources();
    getBalance();
  }

  setCurrentItemCheck() {
    let source = localStorage.getItem("current_source");

    if (source) {
      let data = JSON.parse(source);
      this.setState({
        carbon: Number(data.amount),
        selectedSources: [data.id]
      });

    }
    localStorage.setItem("current_source", '');
  }

  selectAll() {
    const { sources } = this.props
    const nonOffsetSources = sources.filter(el=> el.offset === 0)

    const state = nonOffsetSources.reduce(({ carbon, selectedSources }, source) => {
      return {
        carbon: carbon + Number(source.amount),
        selectedSources: [...selectedSources, source.id],
      };
    }, { carbon: 0, selectedSources: [] });

    this.setState(state);
  }

  isPaymentPossible(forest, quantity) {

    mixpanel.track("Checkout", {
      "Authenticated": getIsAutheticatedFromStore(),
      "Action": "Pre-order",
      "Domain": "App"
    });

    this.props.getCalculatedPrice(forest, quantity, PAYMENT_GATEWAY.STRIPE);
  }

  openForestDetails(id) {
    this.setState({ forestDetailsId: id });
  }

  closeForestDetails() {
    this.setState({ forestDetailsId: null });
  }

  updateSearchQuery = () => {
    const { history, location: { pathname } } = this.props
    const search = getNewSearchQuery(this.state)

    history.replace(`${pathname}?${search}`)
  }

  handleChange(value, key) {
    this.setState({
      [key]: (isNaN(value) || value.length > 6) ?
        this.state[key]
        : Number(value)
    });
  }

  handleSelect(element, id) {
    const { checked, value } = element.target;
    const { carbon, selectedSources } = this.state;

    this.setState({
      carbon: checked ?
        carbon + Number(value)
        : (carbon - Number(value) < 0) ?
          0
          : carbon - Number(value),
      selectedSources: checked ?
        [...selectedSources, id]
        : selectedSources.filter(stateId => stateId !== id)
    });
  }

  getSelectedOffsetData(forest) {
    const { match: { params: { mode } } } = this.props;
    const { area, budget, carbon, selectedSources } = this.state;
    const carbonPerUnit = (forest.offset_type === OFFSET_TYPE_CREDIT) ?
      1000 :
      carbonAmountIn(forest.co2_years, forest.timeline_formula);

    // Not compatible
    if(mode === 'area' && forest.offset_type === OFFSET_TYPE_CREDIT) {
      return { offsetType: forest.offset_type, price: 0, quantity: 0, carbon: 0 };
    }

    switch (mode) {
      case 'area': {
        const metricArea = keepToMetric('ft2', 'm2', area);
        return {
          offsetType: forest.offset_type,
          price: metricArea * forest.price.amount,
          quantity: metricArea,
          carbon: carbonPerUnit * metricArea,
          mode: mode
        };
      }
      case 'budget': {
        return {
          offsetType: forest.offset_type,
          price: budget,
          quantity: budget / forest.price.amount,
          carbon: carbonPerUnit * budget / forest.price.amount,
          mode: mode
        };
      }
      default:
        return {
          offsetType: forest.offset_type,
          price: (carbon / carbonPerUnit) * forest.price.amount,
          quantity: carbon / carbonPerUnit, //carbon / forest.timeline_formula,
          carbon: carbon,
          selectedSources: selectedSources,
          mode: mode
        };
    }
  }

  componentWillUpdate() {
    this.setCurrentItemCheck();
  }

  render() {
    const {
      forests,
      match: { params: { mode } },
      sources,
      isFetchingForests,
      isFetchingSources,
      isFetchingBalance,
      forestsErrorMessages,
      sourcesErrorMessages,
      balanceErrorMessages,
      emitted,
      oxygen,
      captured,
      currency,
    } = this.props;

    const { loading, timeFinished, selectedSources, area, carbon, budget } = this.state;

    if (
      forestsErrorMessages ||
      sourcesErrorMessages ||
      balanceErrorMessages
    ) {
      return (
        <FullErrorFetching
          errorMessage={
            forestsErrorMessages ||
            sourcesErrorMessages ||
            balanceErrorMessages
          }
          retry={this.fetch.bind(this)}
        />
      );
    }

    if (loading) {
      if (
        timeFinished &&
        !isFetchingForests &&
        !isFetchingSources &&
        !isFetchingBalance &&
        forests.length > 0
      ) {
        this.setState({ loading: false });
        this.setCurrentItemCheck();
      }
      return <LoaderRedirect location={this.props.location}/>;
    }
    else {
      if (
        isFetchingForests ||
        isFetchingSources ||
        isFetchingBalance ||
        forests.length <= 0 ||
        !mode
      ) {
        return <Loader />;
      }
      else {
        return (
            <Create
              closeForestDetails={this.closeForestDetails.bind(this)}
              forestDetailsId={this.state.forestDetailsId}
              forests={forests}
              getSelectedOffsetData={this.getSelectedOffsetData.bind(this)}
              handleChange={this.handleChange.bind(this)}
              handleSelect={this.handleSelect.bind(this)}
              mode={mode}
              openForestDetails={this.openForestDetails.bind(this)}
              selectedSources={selectedSources}
              sources={sources}
              values={{
                area: area,
                budget: budget,
                carbon: carbon,
              }}
              emitted={emitted}
              oxygen={oxygen}
              captured={captured}
              isPaymentPossible={this.isPaymentPossible.bind(this)}
              currency={currency}
            >
              <Helmet>
                <link rel="canonical" href={`https://app.reforestum.com${links.getCreateForestUrl()}`} />
              </Helmet>
            </Create>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  forests: getForests(state),
  sources: getSourcesToOffset(state, getCaptured(state)),
  sourcess: getAllSources(state),
  isFetchingForests: getIsFetchingForests(state),
  isFetchingSources: getIsFetchingSources(state),
  isFetchingBalance: getIsFetchingBalance(state),
  isAddingSource: getIsAddingSource(state),
  forestsErrorMessages: getForestsErrorMessages(state),
  sourcesErrorMessages: getSourcesErrorMessages(state),
  balanceErrorMessages: getBalanceErrorMessages(state),
  emitted: getEmitted(state),
  oxygen: getOxygen(state),
  captured: getCaptured(state),
  currency: getCurrency(state),
});

CreateForestContainer.propTypes = {
  fetchForests: PropTypes.func.isRequired,
  forests: PropTypes.array,
  getBalance: PropTypes.func.isRequired,
  addSource: PropTypes.func.isRequired,
  getSources: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sources: PropTypes.array,
  isFetchingForests: PropTypes.bool.isRequired,
  isFetchingSources: PropTypes.bool.isRequired,
  isFetchingBalance: PropTypes.bool.isRequired,
  forestsErrorMessages: PropTypes.string,
  sourcesErrorMessages: PropTypes.string,
  balanceErrorMessages: PropTypes.string,
  emitted: PropTypes.number.isRequired,
  oxygen: PropTypes.number.isRequired,
  captured: PropTypes.number.isRequired,
  getCalculatedPrice: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, {
  fetchForests,
  getBalance,
  addSource,
  getSources,
  getCalculatedPrice,
  updateLoadStatus,
})(withRouter(CreateForestContainer));
