import React from "react"
import { PieChart, Pie, ResponsiveContainer } from 'recharts'


const BeZeroPieChart = ({ transformedRiskSummaries, height = 400, gridStroke = "#888888" }) => {


    // this configuration is useful for the radial grid
    const sixPiecesData = Array.from({ length: 6 }, () => ({ value: 1 }));
    const gridCommonProps = { data: sixPiecesData, dataKey: "value", cx: "50%", cy: "50%", fill: "none", stroke: gridStroke, startAngle: -30, endAngle: 330, isAnimationActive: false };
    const gridPercentages = ["100%", "90%", "80%", "70%", "60%", "50%", "40%", "30%", "20%", "10%"]

    // this configuration is useful for the pie chart representation
    const pieStartEndAngles = Array.from({ length: 6 }, (_, i) => [(i * 60) + 150, (i * 60) + 90]);
    const pieCommonProps = { dataKey: "value", nameKey: "name", cx: "50%", cy: "50%", isAnimationActive: false };
    
    return (<div className={'be-zero__pie-chart__container'}>
        <ResponsiveContainer width="100%" height={height}>
            <PieChart>
                {/* Grid is created using Pie sections first so it is in the back */}
                {gridPercentages.map((percentage, index) => <Pie key={`grid-pie-${index}`} outerRadius={percentage} {...gridCommonProps} />)}
                {transformedRiskSummaries.reverse().map((riskSummary, index) => (riskSummary.value > 0) && <Pie data={[{ value: 1, name: riskSummary.label}]} key={`risk-pie-${index}`} startAngle={pieStartEndAngles[index][0]} endAngle={pieStartEndAngles[index][1]} outerRadius={`${Number(riskSummary.value*100)}%`} fill={riskSummary.color} strokeWidth={0}
                {...pieCommonProps}/>)}
            </PieChart>
        </ResponsiveContainer>
    </div>)
}

export default BeZeroPieChart;