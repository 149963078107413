import React, {Component} from 'react'
import './StandardFormModal.css'
import {FormattedMessage, injectIntl} from "react-intl"
import {MyForestSummary as MyForestSummaryTexts} from "../../../constants/messages"
import {Button} from "@reforestum/shared-components"
import Modal from "./Modal"
import PropTypes from "prop-types"
import {axiosV2Instance} from "../../../actions"
import api from "../../../constants/api"
import store from "../../../setup/store"
import {addAlert} from "../../../actions/alerts"
import {getIsUserAuthenticated, getUserDetails} from "../../../selectors/userSession"
import {connect} from "react-redux"
import { posthog } from 'posthog-js'

class StandardFormModal extends Component {

	constructor(props) {
		super(props);

		this.state = {
			userInterestFormEmail: '',
			userInterestFormFirstName: '',
			userInterestFormLastName: '',
			userInterestFormMessage: '',
		}
	}

	componentDidMount() {
		posthog.capture('standard_form_mounted', {'action' : this.props.action})
	}

	userIsAuthenticated() {
		return !!this.props.isAuthenticated
	}

	getUserEmail() {
		let result = null
		if(this.userIsAuthenticated()) {
			result = this.props.user.email
		}
		return result
	}

	async defaultHandleSubmit() {
		let {submitCallback, additionalContext, successAlertSettings} = this.props
		if(!additionalContext) {
			additionalContext = {}
		}
		const userInput ={
			email: this.getUserEmail() || this.state.userInterestFormEmail,
			name: (this.state.userInterestFormFirstName && typeof this.state.userInterestFormFirstName === 'string' && this.state.userInterestFormFirstName.length > 0) ? this.state.userInterestFormFirstName : undefined,
			surname: (this.state.userInterestFormLastName && typeof this.state.userInterestFormLastName === 'string' && this.state.userInterestFormLastName.length > 0) ? this.state.userInterestFormLastName : undefined,
			details: JSON.stringify({formMessage: this.state.userInterestFormMessage, additionalContext})
		}
		const response = await axiosV2Instance.post(api.RECORD_FORM_RESPONSE_v2, {
			action: this.props.action ? this.props.action : 'request-project-updates',
			context: userInput
		})
		if(response && successAlertSettings) {
			store.dispatch(addAlert(successAlertSettings))
		}
		if(submitCallback && typeof submitCallback === 'function') {
			submitCallback()
			posthog.capture('standard_form_submitted', {'action' : this.props.action, 'context' : userInput })
		}
	}

	render() {
		const {intl, preHeading, heading, subHeading, description, handleSubmit, handleClose, cta} = this.props
		return <div className={'standard-form-modal__wrapper'}>
			<Modal
				clickClose={handleClose}
				content={<div className={'standard-form-modal'}>
					{preHeading}
					{
						heading &&
						<h2>
							{heading}
						</h2>
					}
					{
						description &&
						<p>
							{description}
						</p>
					}
					{
						subHeading &&
						<h3>
							{subHeading}
						</h3>
					}
					{
						(!this.getUserEmail() || (this.getUserEmail() && this.props.displayKnownEmail) ) &&
						<input
							disabled={(this.getUserEmail() && this.props.displayKnownEmail) || false}
							type={'email'}
							placeholder={intl.formatMessage(MyForestSummaryTexts.requestUpdatesEmail)}
							value={this.state.userInterestFormEmail || this.getUserEmail()}
							onChange={e => this.setState({userInterestFormEmail: e.target.value})}
						/>
					}
					{
						!this.props.hideFirstName && <input
							type={'text'}
							placeholder={intl.formatMessage(MyForestSummaryTexts.requestUpdatesFirstName)}
							value={this.state.userInterestFormFirstName}
							onChange={e => this.setState({userInterestFormFirstName: e.target.value})}
						/>
					}
					{
						!this.props.hideLastName && <input
							type={'text'}
							placeholder={intl.formatMessage(MyForestSummaryTexts.requestUpdatesLastName)}
							value={this.state.userInterestFormLastName}
							onChange={e => this.setState({userInterestFormLastName: e.target.value})}
						/>
					}
					{
						!this.props.hideTextArea && <textarea
							type={'text'}
							placeholder={intl.formatMessage(MyForestSummaryTexts.requestUpdatesNote)}
							value={this.state.userInterestFormMessage}
							onChange={e => this.setState({userInterestFormMessage: e.target.value})}
						/>
					}
					<Button onClick={() => {typeof handleSubmit === 'function' ? handleSubmit() : this.defaultHandleSubmit() }} small className="btn--blank standard-form-modal__cta">
						{
							cta ? cta : <FormattedMessage
								id={'PublicDashboard.unOnboardedCompany.recordInterest.recordInterestCTA'}
								defaultMessage={'Record Interest'}
							/>
						}
					</Button>
				</div>}
			/>
		</div>
	}
}

const mapStateToProps = (state) => {
	return {
		isAuthenticated: getIsUserAuthenticated(state),
		user: getUserDetails(state),
	};
};

StandardFormModal.propTypes = {
	intl: PropTypes.object,
	preHeading: PropTypes.any,
	heading: PropTypes.any,
	description: PropTypes.any,
	handleClose: PropTypes.func,
	handleSubmit: PropTypes.func,
	submitCallback: PropTypes.func,
	successAlertSettings: PropTypes.object,
	cta: PropTypes.object,
	allowDetails: PropTypes.bool,
	displayKnownEmail: PropTypes.bool,
	hideFirstName: PropTypes.bool,
	hideLastName: PropTypes.bool,
	hideTextArea: PropTypes.bool
};

export default connect(mapStateToProps)(injectIntl(StandardFormModal));
