/* global Stripe */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import { stripeToken } from '../../constants/config'

import { Button } from '@reforestum/shared-components';
import FeedbackMessage from '../UI/Forms/FeedbackMessage';

import Row from '../UI/Forms/Row';
import RowAligner from '../UI/Forms/RowAligner';

class StripeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    this.stripe = Stripe(stripeToken);
    this.elements = this.stripe.elements();
  }

  componentDidMount() {
  }

  setInputError(error, key) {
    this.setState({
      [key]: error ? true : false,
    });
  }

  moveToNextInput(input) {
    document.querySelector(`${input} input`).focus();
  }

  submitForm(event) {
    const {
      // eslint-disable-next-line 
      quantity,
      // eslint-disable-next-line 
      forestId,
      // eslint-disable-next-line 
      makePayment,
      // eslint-disable-next-line 
      price,
      sources
    } = this.props;

    event.preventDefault();

    let selectedSources = []

    Object.keys(sources).forEach(s => selectedSources.push(sources[s]['id']))
    makePayment(
      // Stripe
      this.stripe,
      // Total
      price,
      quantity,
      forestId,
      selectedSources
    );
  }

  render() {
    const { isPaying, paymentErrors } = this.props;

    return (
      <form
        id="payment-form"
        method="post"
        onSubmit={this.submitForm.bind(this)}>

        { paymentErrors &&
          <Row>
            <RowAligner>
              <FeedbackMessage type="error">
                {paymentErrors}
              </FeedbackMessage>
            </RowAligner>
          </Row>
        }

        <Row>
          <RowAligner>
            <Button big loading={isPaying}>
              <FormattedMessage
                id={'StripeForm.submit'}
                defaultMessage={'Start Payment'}
              />
            </Button>
          </RowAligner>
        </Row>

      </form>
    );
  }
}

StripeForm.propTypes = {
  quantity: PropTypes.number.isRequired,
  forestId: PropTypes.number.isRequired,
  forestName: PropTypes.string.isRequired,
  intl: intlShape,
  isPaying: PropTypes.bool.isRequired,
  makePayment: PropTypes.func.isRequired,
  paymentErrors: PropTypes.string,
  price: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  sources: PropTypes.array.isRequired
};

export default injectIntl(StripeForm);
