import React from 'react';
import { Redirect, withRouter } from "react-router";
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import history from '../setup/history';
import store from '../setup/store';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { links } from '@reforestum/shared-utils';

// constants
import { AUTH_METHOD } from '../constants/authMethods';
import { ENTERPRISE_ACTIVATION_VIEWS } from '../constants/enterpriseLanding'
import { ACTIVATION_STATUS } from '../constants/enterpriseLanding'
import { OffsetActivationAlerts } from '../constants/messages';

// localstorage
import { removeFromStorage, saveToStorage } from '../utils/localStorage';

// actions
import { signup } from '../actions/userActions';
import { createNewOrg } from '../actions/orgActions';
import { logout, authenticate, refreshProfile } from '../actions/userSession';
import { fetchEnterpriseOffsetCreated, fetchEnterpriseActivationCompanyDetails } from '../actions/enterpriseActivation';
import { addAlert } from '../actions/alerts';
import { offsetClaiming, resetOffsetClaimErrors } from '../actions/enterpriseActivation';

// selectors
import { getEnterpriseActivationOffset, getEnterpriseOffsetErrorMessages } from '../selectors/enterpriseActivation';
import { getEnterpriseActivationCompanyDetails, getEnterpriseActivationCompanyDetailsErrors } from '../selectors/companyDetails';
import {
  getIsFetchingOffsetClaimDetails,
  getOffsetClaimErrorMessages,
  getOffsetClaimDetails } from '../selectors/offsetClaim';
import * as authSelector from '../selectors/userActions';
import * as userSelector from '../selectors/userSession';

// components
import EnterpriseUserActivation from '../components/Auth/EnterpriseUserActivation';
import EnterpriseLoginActivation from '../components/Auth/EnterpriseLoginActivation';
import EnterpriseParamsLanding from '../components/Specials/EnterpriseParamsLanding';
import { defaultLandingImage } from '../constants/enterpriseLanding'
import Modal from '../components/UI/Modal/Modal'
import PartyInUseInformation from '../components/Checkout/PartyInUseInformation';
import { Button } from '@reforestum/shared-components';
import { PRIVACYLEVEL } from '../constants/privacyLevels';
import { posthog } from 'posthog-js';

const createEnterpriseActivationParameters = (enterpriseActivationInstance) => {
  return         {
    enterprise_org_id: enterpriseActivationInstance.state.orgId,
    enterprise_org_name: enterpriseActivationInstance.props.companyDetails.companyName.toLowerCase(),
    enterprise_activation_id: enterpriseActivationInstance.state.activationId,
    enterprise_activation_status: enterpriseActivationInstance.status
  }
}

class EnterpriseActivationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        detailsFormView: null,
        orgId: null,
        activationId: this.props.match.params.uuid,
        actionName: this.props.match.params.actionName || null,
        companyDetailsObj: null,
        offsetErrorMessage: null,
        status: ACTIVATION_STATUS.PENDING,
        companyProfileSlug: null,
        offsetClaimErrors: undefined,
        offsetClaim: undefined,
        showConfirmSelectedAccountModalBeforeActivating: false,
    }

    this.setShowActivationForm = this.setShowActivationForm.bind(this);
    this.handleActivateMySharesLoggedUser = this.handleActivateMySharesLoggedUser.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleSuccessfullyLogin = this.handleSuccessfullyLogin.bind(this);
    this.handleActivatedOffsetSpecialCaseRedirectionToReforesters = this.handleActivatedOffsetSpecialCaseRedirectionToReforesters.bind(this);
    this.handleSuccessfullyRegistration = this.handleSuccessfullyRegistration.bind(this);
    this.handleSuccessfullyRegistrationB2b = this.handleSuccessfullyRegistrationB2b.bind(this);
    this.claimOffsetsAndReedirectToPublicProfile = this.claimOffsetsAndReedirectToPublicProfile.bind(this);
    this.renderConfirmSelectedAccountModalBeforeActivating = this.renderConfirmSelectedAccountModalBeforeActivating.bind(this)
  }

  componentDidMount() {
    const { fetchEnterpriseOffsetCreated } = this.props;
    fetchEnterpriseOffsetCreated(this.state.activationId);

    // catching up signup error messages for undoing the redirection and staying at Register view
    if(this.props.signupErrorMessages){
      this.setState({ 
        detailsFormView: ENTERPRISE_ACTIVATION_VIEWS.REGISTER 
      })
    }

    // catching up login error messages for undoing the redirection and staying at Login view
    if(this.props.errorMessages){
      this.setState({ 
        detailsFormView: ENTERPRISE_ACTIVATION_VIEWS.LOGIN 
      })
    }
  }

  componentWillReceiveProps(newProps) {
    if(newProps.enterpriseOffsetErrorMessages){
      this.setState({ offsetErrorMessage:  newProps.enterpriseOffsetErrorMessages})
    }

    if(!newProps.enterpriseOffsetErrorMessages && newProps.enterpriseOffset){
      this.setState({ orgId: newProps.enterpriseOffset.enterpriseOffset.org_id });
      this.setState({ status: newProps.enterpriseOffset.enterpriseOffset.status });
    }
 
    if(this.state.offsetClaimErrors !== newProps.offsetClaimErrorMessages){
      this.setState({ offsetClaimErrors: newProps.offsetClaimErrorMessages });
    }
  }

  async componentDidUpdate(prevState, prevProps){
    const { fetchEnterpriseActivationCompanyDetails } = this.props;
    const { orgId, activationId, status } = this.state;

    if (prevProps.orgId !== this.state.orgId && !this.state.offsetErrorMessage) {

      // @SEE for clarifications on fetching in componentDidUpdate: https://github.com/facebook/react/issues/11224
      await fetchEnterpriseActivationCompanyDetails(orgId, activationId);

      // if errors during enterprise-organization/{orgId}/{activationId} api call
      if(this.props.companyDetailsErrors){
        history.push(links.get404Url())
      }
      
      this.setState({ companyDetailsObj: this.props.companyDetails })
      this.setState({ companyProfileSlug: this.props.companyDetails && this.props.companyDetails.corporateProfileSlug})

      if(!this.state.offsetErrorMessage && this.props.companyDetails){

        // this is executed on first load
        posthog.capture("enterprise_activation_landing", createEnterpriseActivationParameters(this))
        mixpanel.track("Page view", {
          "Action": "Enterprise activation landing",
          "Domain": "App",
          "referrerType": "enterpriseApi",
          "enterpriseOffsetActivationId": this.state.activationId,
          "enterpriseOrgId": this.state.orgId,
          "enterpriseOffsetActivationType": this.props.companyDetails.offsetActivationType,
          "enterpriseOrgName": this.props.companyDetails.companyName.toLowerCase(),
          "enterpriseOffsetStatus": status,
          "userForestSurface": this.props.companyDetails.forestSurface
        });
      }
      this.setState({ companyProfileSlug: this.props.companyDetails && this.props.companyDetails.corporateProfileSlug})

      // Patch: slug used for Header Container for the fetchMyContributionsInPublicProfile(slug)
      this.props.companyDetails
        && status === ACTIVATION_STATUS.PENDING
        && saveToStorage("companySlug", this.props.companyDetails.corporateProfileSlug)
    }

    if(!this.state.offsetErrorMessage
      && !this.props.companyDetailsErrors
      && this.props.companyDetails  
    ){
      mixpanel.track("Page view", {
        "Action": "Enterprise activation landing",
        "Domain": "App",
        "referrerType": "enterpriseApi",
        "enterpriseOffsetActivationId": this.state.activationId,
        "enterpriseOrgId": this.state.orgId,
        "enterpriseOffsetActivationType": this.props.companyDetails.offsetTransactionType,
        "enterpriseOrgName": this.props.companyDetails.companyName.toLowerCase(),
        "enterpriseOffsetStatus": status,
        "userForestSurface": this.props.companyDetails.forestSurface
      });
    }
  }

  // It helps to clean the redux newProps/state about claim messages
  // For edge case: error during first time claim, user returns to previous page (landing)
  // and clicks Activate Shares button again, that redirects to public profile
  // Now the same error alert should pop up again
  componentWillUnmount(){
    this.props.resetOffsetClaimErrors();
  }

  handleSuccessfullyRegistration(){
    const { companyProfileSlug, activationId } = this.state;
    const { offsetClaiming } = this.props;

    const { companyDetailsObj, status } = this.state;
    const { projectId, projectSubsectionId, sectorShareId } = companyDetailsObj;

    //Reedirect directly to shares if user shares are already activated
    if(status === ACTIVATION_STATUS.ACTIVATED){
      removeFromStorage("enterpriseOffset");
      history.push(links.getProjectUrl(projectId, projectSubsectionId, sectorShareId));
      return
    }


    // added callback to offsetClaiming call for handling actions after claming
    offsetClaiming(activationId, (error) => {
      if(error){
        // the component already handles the error via Redux props
        return ;
      }
  
      // this is executed on first load
      posthog.capture("enterprise_activation_claiming", {...createEnterpriseActivationParameters(this),...{type: "registration"}})
      // mixpanel track
      mixpanel.track("Session", {
        "Action": "User signup Enterprise Activation",
        "Domain": "App",
        "referrerType": "enterpriseApi",
        "enterpriseOffsetActivationId": this.state.activationId,
        "enterpriseOrgId": this.state.orgId,
        "enterpriseOffsetActivationType": this.props.companyDetails.offsetActivationType,
        "enterpriseOrgName": this.props.companyDetails.companyName.toLowerCase(),
        "enterpriseOffsetStatus": this.state.status,
        "userForestSurface": this.props.companyDetails.forestSurface
      });

      history.push(links.getPublicProfileUrl(companyProfileSlug))
    })
  }

  //Special case Mirakl: Create an org and then offset impersonating that organization
  async handleSuccessfullyRegistrationB2b({companyName, privacy, display_name}){
    const { companyProfileSlug, activationId } = this.state;
    const { offsetClaiming, createNewOrg } = this.props;

    const { companyDetailsObj, status } = this.state;
    const { projectId, projectSubsectionId, sectorShareId } = companyDetailsObj;

    //Reedirect directly to shares if user shares are already activated
    if(status === ACTIVATION_STATUS.ACTIVATED){
      removeFromStorage("enterpriseOffset");
      history.push(links.getProjectUrl(projectId, projectSubsectionId, sectorShareId));
      return
    }


     await createNewOrg({form: {
      company_name: companyName,
      //Organizations don't have privacy nickname so we set it as public
      privacy: (privacy === PRIVACYLEVEL.NICKNAME) ? PRIVACYLEVEL.PUBLIC : privacy,
      public_profile_on: false,
      display_name: display_name ? display_name : companyName,
    }}).then(() =>     
      // added callback to offsetClaiming call for handling actions after claming
      offsetClaiming(activationId, (error) => {
        if(error){
          // the component already handles the error via Redux props
          return ;
        }
    }))
  
      // mixpanel track
      posthog.capture("enterprise_activation_claiming", {...createEnterpriseActivationParameters(this),...{type: "registration_b2b"}})

      mixpanel.track("Session", {
        "Action": "User signup Enterprise Activation",
        "Domain": "App",
        "referrerType": "enterpriseApi",
        "enterpriseOffsetActivationId": this.state.activationId,
        "enterpriseOrgId": this.state.orgId,
        "enterpriseOffsetActivationType": this.props.companyDetails.offsetActivationType,
        "enterpriseOrgName": this.props.companyDetails.companyName.toLowerCase(),
        "enterpriseOffsetStatus": this.state.status,
        "userForestSurface": this.props.companyDetails.forestSurface
      });

      history.push(links.getPublicProfileUrl(companyProfileSlug))
    }

  async handleSuccessfullyLogin({user}){
    const { companyDetailsObj, status } = this.state;
    const { projectId, projectSubsectionId, sectorShareId } = companyDetailsObj;

    //Reedirect directly to shares if user shares are already activated
    if(status === ACTIVATION_STATUS.ACTIVATED){
      removeFromStorage("enterpriseOffset");
      history.push(links.getProjectUrl(projectId, projectSubsectionId, sectorShareId));
      return
    }


    //If user has at least one organization, show pop Up with organization select before offset claiming and reedirection
    if(user.organizations && user.organizations.length > 0) {
      this.setState({showConfirmSelectedAccountModalBeforeActivating: true})
    } else {
      this.claimOffsetsAndReedirectToPublicProfile({isLogin: true})
    }
  }

  handleLogin(data, authMethod=AUTH_METHOD.FORM) {
    const { orgId } = this.state;

    const activationFlowPayload = Object.assign(data, { referralOrgId: orgId });
    this.props.authenticate(
      activationFlowPayload,
      authMethod,
      null,
      this.handleSuccessfullyLogin
    );
  }

  claimOffsetsAndReedirectToPublicProfile({isLogin} = {}) {
        const { offsetClaiming } = this.props;
        const { companyProfileSlug, activationId } = this.state;
    
        const redirectUrl = links.getPublicProfileUrl(companyProfileSlug);

        // offset claiming for the case of the user is logged-in, the activation status='pending' and user has no organizations
        offsetClaiming(activationId, (error) => {
          if(error){
            // the component already handles the error via Redux props
            return ;
          }

          // capture for posthog
          isLogin ?
            posthog.capture("enterprise_activation_claiming", { ...createEnterpriseActivationParameters(this), ...{ type: "login" } }) :
            posthog.capture("enterprise_activation_claiming", { ...createEnterpriseActivationParameters(this), ...{ type: "registration" } })

          // mixpanel track
          isLogin ? 
            mixpanel.track("Session", {
              "Action": "User Login Enterprise Activation",
              "Domain": "App",
              "referrerType": "enterpriseApi",
              "enterpriseOffsetActivationId": this.state.activationId,
              "enterpriseOrgId": this.state.orgId,
              "enterpriseOffsetActivationType": this.props.companyDetails.offsetTransactionType,
              "enterpriseOrgName": this.props.companyDetails.companyName.toLowerCase(),
              "enterpriseOffsetStatus": this.state.status,
              "userForestSurface": this.props.companyDetails.forestSurface
            }) :
            mixpanel.track("Session", {
              "Action": "User signup Enterprise Activation",
              "Domain": "App",
              "referrerType": "enterpriseApi",
              "enterpriseOffsetActivationId": activationId,
              "enterpriseOrgId": this.state.orgId,
              "enterpriseOffsetActivationType": this.props.companyDetails.offsetActivationType,
              "enterpriseOrgName": this.props.companyDetails.companyName.toLowerCase(),
              "enterpriseOffsetStatus": this.state.status,
              "userForestSurface": this.props.companyDetails.forestSurface
            });
    
          history.push(redirectUrl);
        })

  }

  // function used when the user is logged-in and the activation status='pending'
  // errors or success + redirecting, are executed at componentWillReceiveProps()
  handleActivateMySharesLoggedUser(){
    //If user has at least one organization, show pop Up with organization select before offset claiming and reedirection
    const {user} = this.props
    if(user.organizations && user.organizations.length > 0) {
      this.setState({showConfirmSelectedAccountModalBeforeActivating: true})
    } else {
      this.claimOffsetsAndReedirectToPublicProfile()
    }
  }

  setShowActivationForm(){
    this.setState({ 
      detailsFormView: ENTERPRISE_ACTIVATION_VIEWS.REGISTER,
      actionName: null
    })
  }

  handleChangeLoginView(){
    this.setState({ 
      detailsFormView: ENTERPRISE_ACTIVATION_VIEWS.LOGIN,
      actionName: null
    })
  }

  handleActivatedOffsetRedirectToLogin(){
    const { intl } = this.props;
    this.setState({ 
      detailsFormView: ENTERPRISE_ACTIVATION_VIEWS.LOGIN,
      actionName: null
    })

    store.dispatch(addAlert({
      type: 'warning',
      message: intl.formatMessage(OffsetActivationAlerts.loginRequest),
      dismissAfter: 8000
    }))
  }

  // case for user authenticated and activation activated
  handleActivatedOffsetRedirectToMyShares(){
    const { projectId, projectSubsectionId, sectorShareId } = this.state.companyDetailsObj;
    
    const redirectionUrl = links.getProjectUrl(projectId, projectSubsectionId, sectorShareId);

    sectorShareId
    ? history.push(redirectionUrl)
    : history.push(`/project/${projectId}/sector/${projectSubsectionId}/my-shares`)
  }

  // special redirection case for Mirakl
  handleActivatedOffsetSpecialCaseRedirectionToReforesters(){
    const { projectId, projectSubsectionId } = this.state.companyDetailsObj;

    mixpanel.track_links('#visit_shares_btn', 'Visit my shares no-auth click');
    mixpanel.track('Visit my shares no-auth click');

    history.push(`/project/${projectId}/sector/${projectSubsectionId}/reforesters`)
  }

  renderConfirmSelectedAccountModalBeforeActivating() {
    const {user} = this.props
    return <Modal content={
      <div>
        <PartyInUseInformation isActivation user={user}/>
        <Button onClick={this.claimOffsetsAndReedirectToPublicProfile}><FormattedMessage defaultMessage="continue" id="OffsetActivation.accountSelectModalConfirmButton"/></Button>
      </div>
    } clickClose={() => this.setState({showConfirmSelectedAccountModalBeforeActivating: false})} />
  }

  render() {
    const {
      errorMessages,
      isAuthenticated,
      companyDetails,
      logout,
      lastAttemptedEmail,
      signup,
      signupErrorMessages,
      isLoading,
      intl
    } = this.props;

    const {
      activationId,
      offsetErrorMessage,
      status,
      companyProfileSlug,
      actionName,
      detailsFormView,
      orgId,
      showConfirmSelectedAccountModalBeforeActivating
    } = this.state;

    if(offsetErrorMessage){
      return (
        <Redirect replace to={links.get404Url()} />
      )
    }

    if(!companyDetails){
      return null;
    }

    const { offsetTransactionType } = companyDetails;
    const companyBrandImage = companyDetails ? companyDetails.landingImage : defaultLandingImage;
    const isMiraklCase = companyProfileSlug && companyProfileSlug === 'mirakl';
    
    if(
      offsetErrorMessage === null && 
      detailsFormView === null &&
      actionName === null
    ) {
      return (
        <div style={{display:"flex", width:"100%"}}>
          {showConfirmSelectedAccountModalBeforeActivating && this.renderConfirmSelectedAccountModalBeforeActivating()}
          <EnterpriseParamsLanding
            intl={intl}
            offsetTransactionType={offsetTransactionType}
            isMiraklCase={isMiraklCase}
            handleActivatedOffsetSpecialCaseRedirectionToReforesters={this.handleActivatedOffsetSpecialCaseRedirectionToReforesters}
            handleActivateMySharesLoggedUser={this.handleActivateMySharesLoggedUser}
            activationStatus={status}
            setShowActivationForm={this.setShowActivationForm}
            companyData={companyDetails}
            isAuthenticated={isAuthenticated}
            defaultLandingImage={defaultLandingImage}
            handleActivatedOffsetRedirectToLogin={this.handleActivatedOffsetRedirectToLogin.bind(this)}
            handleActivatedOffsetRedirectToMyShares={this.handleActivatedOffsetRedirectToMyShares.bind(this)}
          />
        </div>
      )
    }

    if(
      detailsFormView === ENTERPRISE_ACTIVATION_VIEWS.LOGIN || 
      actionName === ENTERPRISE_ACTIVATION_VIEWS.LOGIN
    ){
      return (
        <div style={{display:"flex", width:"100%"}}>
          {showConfirmSelectedAccountModalBeforeActivating && this.renderConfirmSelectedAccountModalBeforeActivating()}
          <EnterpriseLoginActivation
            enterpriseActivation={true}
            setShowActivationForm={this.setShowActivationForm}
            handleLogin={this.handleLogin}
            errorMessages={errorMessages}
            isLoading={isLoading}
            logout={logout}
            lastAttemptedEmail={lastAttemptedEmail}
            companyImage={companyBrandImage}
            defaultLandingImage={defaultLandingImage}
            activationId={activationId}
            orgId={orgId}
            companyProfileSlug={companyProfileSlug}
            activationStatus={status}
            offsetTransactionType={offsetTransactionType}
          />
        </div>
      )
    }

    if(
      detailsFormView === ENTERPRISE_ACTIVATION_VIEWS.REGISTER ||
      actionName === ENTERPRISE_ACTIVATION_VIEWS.REGISTER
    ){
      return (
        <div style={{display:"flex", width:"100%"}}>
          {showConfirmSelectedAccountModalBeforeActivating && this.renderConfirmSelectedAccountModalBeforeActivating()}
          <EnterpriseUserActivation
            offsetTransactionType={offsetTransactionType}
            handleSuccessfullyRegistration={this.handleSuccessfullyRegistration}
            handleSuccessfullyRegistrationB2b={this.handleSuccessfullyRegistrationB2b}
            handleLogin={this.handleLogin} // SOCIAL BUTTONS CASE, as only uses authenticate
            companyProfileSlug={companyProfileSlug}
            activationId={activationId}
            companyImage={companyBrandImage}
            changeLoginView={this.handleChangeLoginView.bind(this)}
            signup={signup}
            isLoading={isLoading}
            signupErrorMessages={signupErrorMessages}
            defaultLandingImage={defaultLandingImage}
            activationStatus={status}
            orgId={orgId}
          />
        </div>
      )
    };
  }
}

EnterpriseActivationContainer.propTypes = {
  authenticate: PropTypes.func,
  enterpriseOffset: PropTypes.object,
  enterpriseOffsetErrorMessages: PropTypes.object,
  companyDetails: PropTypes.object,
  logout: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  signup: PropTypes.func.isRequired,
  signupErrorMessages: PropTypes.string,
  errorMessages: PropTypes.string,
  offsetClaiming: PropTypes.func,
  resetOffsetClaimErrors: PropTypes.func,
  offsetClaimErrorMessages: PropTypes.string,
  intl: intlShape,
  fetchEnterpriseOffsetCreated: PropTypes.func.isRequired,
  lastAttemptedEmail: PropTypes.string,
}

const mapStateToProps = state => ({
  enterpriseOffset: getEnterpriseActivationOffset(state),
  enterpriseOffsetErrorMessages: getEnterpriseOffsetErrorMessages(state),
  companyDetails: getEnterpriseActivationCompanyDetails(state),
  companyDetailsErrors: getEnterpriseActivationCompanyDetailsErrors(state),
  isAuthenticated: userSelector.getIsUserAuthenticated(state),
  signupErrorMessages: authSelector.getSignupErrorMessages(state),
  isLoading: authSelector.getIsLoading(state),
  errorMessages: authSelector.getLoginErrorMessages(state),
  offsetClaim: getOffsetClaimDetails(state),
  offsetClaimErrorMessages: getOffsetClaimErrorMessages(state),
  isFetchingOffsetClaimDetails: getIsFetchingOffsetClaimDetails(state),
  user: userSelector.getUserDetails(state),
});

export default connect(
  mapStateToProps,
{
  fetchEnterpriseOffsetCreated,
  fetchEnterpriseActivationCompanyDetails,
  logout,
  signup,
  authenticate,
  offsetClaiming,
  resetOffsetClaimErrors,
  refreshProfile,
  createNewOrg,
 }
)(injectIntl(withRouter(EnterpriseActivationContainer)));
