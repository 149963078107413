import React from 'react'
import PropTypes from 'prop-types';

import './Dropdown.css'
import { Link } from 'react-router-dom';

class DropdownItem extends React.PureComponent {
  render() {
    const {
      isLink,
      link,
      onClick,
      children,
      destructive,
      className
    } = this.props

    return isLink ? <Link
      to={link}
      className={`dropdown-item ${destructive && 'dropdown-item--destructive'} ${className && className}`}>
      {children}
    </Link> :
    <button className={`dropdown-item-button dropdown-item ${destructive && 'dropdown-item--destructive'} ${className && className}`} onClick={onClick}>
      {children}
    </button>
    
  }
}

DropdownItem.propTypes = {
  isLink: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.object | PropTypes.string,
  destructive: PropTypes.bool
}

export default DropdownItem
