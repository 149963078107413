import React, {useState} from 'react'
import './SelectMulti.scss'
import Close from '../Icons/Close'
import {injectIntl} from 'react-intl'
import {searchMultiMessages} from '../../../constants/messages'

const SelectMulti = (
  {
    intl,
    noSelectionPillMessage=intl.formatMessage(searchMultiMessages.noSelectionDefaultPill),
    dropdownPlaceholder=null,
    options={label: 'Select', value: null},
    selected=[],
    setSelected=()=>{},
    multipleValues=true
  }={}
) => {

  const [searchString, setSearchString] = useState('')
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleSelection = (option) => {
    if (selected.find(o => o.value === option.value)) {
      setSelected(selected.filter(s => s.value !== option.value))
    } else {
      if(multipleValues) {
        setSelected([...selected, option])
      }
      else {
        setSelected([option])
      }
    }
    setSearchString('')
  }

  const handleSearch = e => {
    setSearchString(e.target.value)
  }

  const handleRemoveSelected = s => {
    setSelected(selected.filter(item => item !== s))
  }

  const handleSearchKeydown = e => {
    if (e.key === "Escape") {
      setSearchString('')
      closeDropdown()
    }
  }

  const handleOptionKeydown = ({e, option}) => {
    if (e.key === "Enter" || e.key === " ") {
      toggleSelection(option)
    }
  }

  const closeDropdown = () => {
    setDropdownOpen(false)
  }

  const openDropdown = () => {
    setDropdownOpen(true)
  }

  const filterOptionsBySearch = o => o && o.label.toLowerCase().includes(searchString.toLowerCase())

  // eslint-disable-next-line
  const renderDropdown = () => <div className={'select-multi-dropdown'}>
    <div className="select-multi-typeahead">
      <input
        placeholder={dropdownPlaceholder || ''}
        tabIndex={1}
        type={'text'}
        onChange={handleSearch}
        onKeyDown={handleSearchKeydown}
        value={searchString}
        autoFocus
      />
    </div>
    <div className="select-multi-options">
      {options.filter(filterOptionsBySearch).map((option, index) => (
        // eslint-disable-next-line
        <a
          key={index}
          className={
            `select-multi-option ${selected.find(o => o.value === option.value)
              ? 'select-multi-option__selected'
              : ''}`
          }
          tabIndex={1}
          type="checkbox"
          onClick={() => toggleSelection(option)}
          onKeyDown={e => handleOptionKeydown({e, option})}
        >
          {option.label}
        </a>
      ))}
    </div>
  </div>

  return (
    // eslint-disable-next-line
    <div tabIndex={1} className="select-multi" onFocus={openDropdown} onClick={openDropdown} onMouseLeave={closeDropdown}>
      <div className="selected-items">
        {
          (selected && !selected.length) && <div className="pill pill__no-close">
            {noSelectionPillMessage}
          </div>
        }
        {selected.map((s, index) => (
          <div key={index} className="pill" onClick={() => handleRemoveSelected(s)} >
            {s.label}
            <Close width={10} height={10}/>
          </div>
        ))}
      </div>
      {
        dropdownOpen && renderDropdown()
      }
    </div>
  )
}

export default injectIntl(SelectMulti)