import api from "../../constants/api"
import {getUserRequestHeaders} from "./getUserRequestHeaders"

export const queryRfpResults = async rfpId => {
	let result = null
	try {
		result = await fetch(
			`${api.RfpResults(rfpId)}`,
			{headers: getUserRequestHeaders()}
		)
		result = await result.json()
	}
	catch(e) {
		console.warn('Error fetching RFP Responses data', e)
	}
	return result
}

export const queryUpdateRfpProposalStatus = async (proposalId, statusToSet) => {
	let result = null
	try {
		result = await fetch(
			`${api.RfpProposal(proposalId)}/status`,
			{
				method: 'PATCH',
				headers: getUserRequestHeaders(),
				body: JSON.stringify({
					status: statusToSet
				})
			}
		)
		result = await result.json()
		return result
	}
	catch(e) {
		console.warn('Error sending RFP status update', e)
	}
	return result
}