import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ForestTitle from '../UI/ForestTitle';
import MyOtherForestsItem from './MyOtherForestsItem';

import './MyOtherForests.css'

const MyOtherForests = ({ myOwnedForests }) => {

    return (
        myOwnedForests && myOwnedForests.length > 0 
        ?
            <div className={'my-other-forests__wrapper'}>
                <div>
                    <ForestTitle>
                        <FormattedMessage                        
                            id={'ForestDetails.myOtherForestsTitle'} 
                            defaultMessage={'Your other forests'}
                        />
                    </ForestTitle>
                </div>
                <div>
                    {myOwnedForests.map(forest => (
                        <MyOtherForestsItem
                            key={forest.id}
                            forest={forest}
                        />)
                        )
                    }
                </div>
            </div>
        : null
    )
}

MyOtherForests.propTypes = {
    forest: PropTypes.object
};

export default MyOtherForests