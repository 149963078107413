import React from 'react'

export const Camera = () => (
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Rectangle 3</title>
      <desc>Created with Sketch.</desc>
      <g id="Account-Settings" stroke="none" fill="none">
          <g id="icon-camara">
              <rect id="Rectangle-3" x="0" y="0" width="32" height="32" />
              <rect id="Rectangle-4" fill="#DCE0E3" x="1" y="8" width="30" height="20" rx="1" />
              <circle id="Oval-5" fill="#BCC5CC" opacity="0.600000024" cx="16" cy="18" r="6.5" />
              <circle id="Oval-5" fill="#6D818F" opacity="0.800000012" cx="16" cy="18" r="3.5" />
              <rect id="Rectangle-5" fill="#CCD2D8" x="1" y="4.3999939" width="7.5" height="2.20000005" rx="1.10000002" />
          </g>
      </g>
  </svg>
)
