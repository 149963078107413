import React from 'react';

const MarketInsightsLinkIcon = props => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<circle cx="24" cy="24" r="24" fill="#FAD8AF"/>
<path d="M33.6995 18.4L14.3005 29.6" stroke="#1F3B21" stroke-width="1.2" stroke-linecap="square"/>
<path d="M12.9868 26.0474C12.5019 23.4231 12.9685 20.7119 14.3027 18.4007C15.637 16.0895 17.7515 14.3296 20.2666 13.4372V21.8445L12.9868 26.0474Z" stroke="#A5B1A6" stroke-width="1.2" stroke-linecap="square"/>
<path d="M23.9999 12.8L24 12.2L23.3998 12.1999L23.3999 12.8L23.9999 12.8ZM14.8591 29.3707L14.5551 28.8534L13.5205 29.4614L13.8245 29.9787L14.8591 29.3707ZM23.9997 13.4C25.8569 13.4004 27.6813 13.8886 29.2904 14.8159L29.8896 13.7762C28.0983 12.7439 26.0673 12.2004 24 12.2L23.9997 13.4ZM29.2904 14.8159C30.8995 15.7432 32.2367 17.0769 33.1681 18.6836L34.2063 18.0817C33.1694 16.2932 31.6808 14.8084 29.8896 13.7762L29.2904 14.8159ZM33.1681 18.6836C34.0996 20.2903 34.5926 22.1134 34.5977 23.9706L35.7977 23.9672C35.792 21.8999 35.2432 19.8703 34.2063 18.0817L33.1681 18.6836ZM34.5977 23.9706C34.6029 25.8277 34.1201 27.6536 33.1975 29.2654L34.239 29.8615C35.266 28.0672 35.8035 26.0346 35.7977 23.9672L34.5977 23.9706ZM33.1975 29.2654C32.275 30.8772 30.9453 32.2184 29.3414 33.1546L29.9463 34.191C31.7318 33.1488 33.2121 31.6558 34.239 29.8615L33.1975 29.2654ZM29.3414 33.1546C27.7375 34.0908 25.9157 34.5892 24.0586 34.5998L24.0655 35.7998C26.1329 35.788 28.1608 35.2331 29.9463 34.191L29.3414 33.1546ZM24.0586 34.5998C22.2015 34.6105 20.3742 34.1331 18.7597 33.2153L18.1667 34.2586C19.964 35.2802 21.9982 35.8117 24.0655 35.7998L24.0586 34.5998ZM18.7597 33.2153C17.1451 32.2976 15.8 30.9718 14.8591 29.3707L13.8245 29.9787C14.872 31.7611 16.3694 33.237 18.1667 34.2586L18.7597 33.2153ZM24.6003 24L24.5999 12.8L23.3999 12.8L23.4003 24L24.6003 24Z" fill="#1F3B21"/>
</svg>





export default MarketInsightsLinkIcon;
