import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { formatUnit } from '../../../utils/units';

import { intlShape, injectIntl } from 'react-intl';


const CarbonBalanceBar = ({
  futureAfforestationPercentage,
  currentAfforestationPercentage,
  vcusPercentage,
  miterdPercentage,
  emittedPercentage,
  emitted,
  formattedEmitted,
  intl,
}) => {
  return (
    <div className="carbon-balance-new-bar">
      <div className="carbon-balance-new-bar-item carbon-balance-new-bar--future-afforestation"
        style={
          { width: `${(futureAfforestationPercentage + currentAfforestationPercentage + vcusPercentage + miterdPercentage)}%`}}
      />
      <div className="carbon-balance-new-bar-item carbon-balance-new-bar--current-afforestation"
        style={
          { width: `${(currentAfforestationPercentage + vcusPercentage + miterdPercentage)}%`}}
      />
      <div className="carbon-balance-new-bar-item carbon-balance-new-bar--miterd"
        style={
          { width: `${(vcusPercentage + miterdPercentage)}%`}}
      />
      <div className="carbon-balance-new-bar-item carbon-balance-new-bar--vcus"
        style={
          { width: `${(vcusPercentage)}%`}}
      />
      {emitted !== 0 &&
        <div
          className="carbon-balance-new-bar-emitted"
          style={{ left: `${(emittedPercentage)}%` }}>
          <span
            className={
              cx(emittedPercentage <= 20 && "carbon-balance-new-bar-emitted-text--align-start")}
          >
            {`${intl.formatNumber(formattedEmitted.value)} ${formatUnit(intl, formattedEmitted.unit)} NEUTRAL`}
          </span>
          <div className="carbon-balance-new-bar-emitted_point" />
          <div className="carbon-balance-new-bar-emitted_line" />
        </div>}
    </div>
  )

}

CarbonBalanceBar.propTypes = {
  intl: intlShape,
  futureAfforestationPercentage: PropTypes.number,
  currentAfforestationPercentage: PropTypes.number,
  vcusPercentage: PropTypes.number,
  miterdPercentage: PropTypes.number,
  emittedPercentage: PropTypes.number,
  emitted: PropTypes.number,
  formattedEmitted: PropTypes.object,

};

export default injectIntl(CarbonBalanceBar);