import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Box } from 'react-layout-components';
import { FormattedMessage } from 'react-intl';

import Heading from '../../UI/Text/Heading';
import Text from '../../UI/Text/Text';
import { ButtonLink } from '@reforestum/shared-components';

import { OFFSET_TYPE_SQM_SHARES, OFFSET_TYPE_CREDIT } from '../../../constants/offsetTypes';

import SharesForestHeaderDetails from './SharesForestHeaderDetails';
import CreditsForestHeaderDetails from './CreditsForestHeaderDetails';

import './ForestHeader.css';
import Tooltip from '../../UI/Tooltip/Tooltip';
import { SECTOR_TYPES } from '../../../constants/sectorTypes';
import { links } from '@reforestum/shared-utils';

const ForestHeader = ({ className, location, name, available, forest, sector, hidePurchaseButton }) => {
  const communityForest = sector && ((sector.type === SECTOR_TYPES.COMMUNITY) || (sector.forest_virtual_sector_is_community));
  const showBecomeAReforesterButton = ((!sector && available) || (sector && available && sector.type === SECTOR_TYPES.COMMUNITY)) && !hidePurchaseButton;

  return (

    <div 
      className={`
        ${cx(className, 'forest-detail-header')}
        ${!available ? 'forest-detail-header__notAvailable' : ''}
        ${sector ? 'forest-detail-header__sector' : ''}  
      `}>
      <Box
        alignItems="center"
        justifyContent="space-between">
        <Box column>
          <div className="forest-detail-header__wrapper">
          {sector && !communityForest && 
            <img 
              src={sector.logo_url}
              alt={`company logo ${name}`}
              className="forest-detail-header-company__logo"
            />
            }
            <div className="forest-detail-header__info-block">
              <Heading className="forest-detail-header__name">
                  {sector ? sector.name : name}
                </Heading>
                <Text className="forest-detail-header__location">
                  {sector && !communityForest
                  ?
                    <div>
                        <FormattedMessage
                            id={'ForestDetails.foundedBy'}
                            defaultMessage={'Founded by'}
                          />
                        &nbsp;
                        <Link
                          to={links.getPublicProfileUrl(sector.profile_domain)}
                          className="forest-detail-header-publicProfile__link"
                        >
                        {sector.org_display_name ? sector.org_display_name : sector.company_name}
                        </Link>
                      <div className={'forest-detail-header__location__location'}>
                        {forest.location_desc ? forest.location_desc : null}
                      </div>
                    </div>
                    : ((location && location !== 'nan') ? location : null)
                  }
                </Text>
            </div>
          </div>
        </Box>
  
        { showBecomeAReforesterButton &&
          (document.location.pathname.split('/')[1] !== 'create-forest') &&
          <Tooltip 
            position="bottom-left" 
            content={ forest.offset_type === OFFSET_TYPE_SQM_SHARES ?
              <FormattedMessage
                id={'ForestDetails.fundThisForestSharesTooltip'}
                defaultMessge={'Fund this project by acquiring Forest Shares to offset your...'}
              /> :
              <FormattedMessage
                id={'ForestDetails.fundThisForestConservationTooltip'}
                defaultMessge={'Fund this project by acquiring certified Carbon Credits ...'}
              />
            }
          >
            <ButtonLink to={links.getCreateForestUrl()}>
              { forest.offset_type === OFFSET_TYPE_SQM_SHARES && (
                  forest.user_total_surface > 0 ? 
                    <FormattedMessage
                      id={'ForestDetails.expandMyShare'}
                      defaultMessge={'Expand my share'}
                    />
                  :
                    <FormattedMessage
                      id={'ForestDetails.becomeAReforester'}
                      defaultMessge={'Become a reforester'}
                    />
                )
              }
              { forest.offset_type === OFFSET_TYPE_CREDIT && (
                  forest.user_total_vcus > 0 ? 
                    <FormattedMessage
                      id={'ForestDetails.growMyImpact'}
                      defaultMessge={'Grow my impact'}
                    />
                  :
                    <FormattedMessage
                      id={'ForestDetails.becomeAReforester'}
                      defaultMessge={'Become a reforester'}
                    />
                )
              }
    
            </ButtonLink>
          </Tooltip>
        }
      </Box>
  
      {forest.offset_type === OFFSET_TYPE_SQM_SHARES &&
        <SharesForestHeaderDetails forest={forest} sector={sector}/>
      }
  
      {forest.offset_type === OFFSET_TYPE_CREDIT &&
        <CreditsForestHeaderDetails forest={forest} virtualSector={sector}/>
      }
    </div>
  )
};

ForestHeader.propTypes = {
  available: PropTypes.bool.isRequired,
  className: PropTypes.string,
  location: PropTypes.string.isRequired,
  name: PropTypes.string,
  forest: PropTypes.object,
  sector: PropTypes.object
};

export default ForestHeader;
