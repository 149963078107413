import '@reforestum/shared-components/dist/index.css';

import 'babel-polyfill';
import 'url-search-params-polyfill';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { render } from 'react-dom';
import { tarteaucitronInit, getTarteaucitronState } from './utils/tarteaucitron/tarteaucitron';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { matchPath } from 'react-router-dom';

import { mixpanelKey, sentryDsnUrl, posthogApiHost, posthogProjectApiKey} from './constants/config';
import { WEBSITE_ID_LOCALSTORAGE_KEY } from './constants/analytics';

import { getUserDetails, getIsUserAuthenticated } from './selectors/userSession';
import { setMixpanelId } from './actions/userSession';
import { posthog } from 'posthog-js'
import { posthogAfterLoaded } from './utils/posthog';
import { getCookie } from './utils/cookie';

import store from './setup/store';
import Root from './setup/Root';

import history from './setup/history';


import './reactTableStyles.css';
import './styles/designSystemStyles.css';

const posthogUiHost = "https://eu.posthog.com"

// Array of Route Config Objects for Sentry
// The longest url under the same parent should be placed first and in decreasing order.
const routes = [
  // --- Static Routes (no params) ---
  { path:  "/login"},
  { path:  "/register"},
  { path:  "/recover-password"},
  { path:  "/carbon-footprint-calculator"},
  { path:  "/checkout"},
  { path:  "/checkout-success"},
  { path:  "/checkout-error"},
  { path:  "/gift-a-forest"},
  // --- Dynamic Routes (with params) ---
  // - Optional Params -
  { path:  "/create-forest/:mode?"},
  { path:  "/crypto-payment-finished/:status?"},
  // - Mandatory Params -
  { path:  "/user-activation/:token"},
  { path:  "/public-profile/:userName"},
  // - Base on levels -
  { path:  "/activation/:uuid/:actionName?"},
  { path: "/project/:id/sector/:sectorId/share/:shareId"},
  { path: "/project/:id/sector/:sectorId"},
  { path: "/project/:id"},
  { path: "/projects"},
  // - Legacy Routes
  { path: "/my-forests/:id/shared-forest/:sectorId/share/:shareId"},
  { path: "/my-forests/:id/shared-forest/:sectorId"},
  { path: "/my-forests/:id?"},
  { path:  "/calculator"},
  // - Extra (canonical 404) -        
  { path: "/404"},
  // - Default Home Route (Should be the last) -   
  { path:  "/"}
];

const sentryIntegrations = []
// PostHog default initialization
if (posthogApiHost.startsWith("https://")) {

  // get cookie name
  const posthogCookieName = `ph_${posthogProjectApiKey}_posthog`

  // default posthog configuration
  const posthogConfig = {api_host: posthogApiHost, ui_host: posthogUiHost , loaded: posthogAfterLoaded}

  // turn off recordings for pre-render to save recordings
  if (window.navigator.userAgent && window.navigator.userAgent.includes("Prerender")) {
    posthogConfig.disable_session_recording = true
  }
  // cookie dependant logic (if the cookie already exists from previously)
  if (getTarteaucitronState('posthog') || getCookie(posthogCookieName)) {
    // if cookie authorization for posthog then use cookie (default)
  } else {
    // use cookieless memory storage (only per session)
    posthogConfig.persistence = 'memory'
  }
  // init posthog using the configuration
  posthog.init(posthogProjectApiKey, posthogConfig)

  // add pageview events on history change (general)
  history.listen(() => {
    posthog.capture('$pageview')
  })

  const sentryProjectId = parseInt(sentryDsnUrl.split("/").pop())
  sentryIntegrations.push(new posthog.SentryIntegration(posthog,'reforestum-eng', sentryProjectId))

}

sentryIntegrations.push(new BrowserTracing({
  routingInstrumentation: Sentry.reactRouterV4Instrumentation(history, routes, matchPath),
}))

Sentry.init({
  dsn: sentryDsnUrl,
  integrations: sentryIntegrations,
  tracesSampleRate: 1.0,
  normalizeDepth: 3
});

// start tarteAuCitron after so we can catch errors as well
tarteaucitronInit();

mixpanel.init(mixpanelKey, {
  loaded: function(mixpanel) {
    const queryParams = (new URL(document.location)).searchParams;
    const mixpanelId = queryParams.get("mixpanel_id");
    if (!getTarteaucitronState('mixpanel')) {
      mixpanel.opt_out_tracking();
      if(mixpanelId) localStorage.setItem(WEBSITE_ID_LOCALSTORAGE_KEY, mixpanelId)
    }
    if (mixpanelId) {
      mixpanel.identify(mixpanelId);
    }

    // Merge identity with already logged in users in webapp
    // And with guest users that already have a guest id
    const userId = getUserDetails(store.getState()).id;
    if(getIsUserAuthenticated(store.getState())) {
      mixpanel.identify(userId)
    }

    store.dispatch(setMixpanelId(mixpanelId || mixpanel.get_distinct_id()));
  }
});

render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <Root store={store} lang={getUserDetails(store.getState())} />
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);
