import { posthog } from 'posthog-js';
import { getIsUserAuthenticated, getUserDetails } from '../selectors/userSession';
import store from '../setup/store';
import { loadFromStorage } from "./localStorage";

export const posthogDistinctId = (partyId) => {
    return `reforestum_party_${partyId}`
}

export const posthogAfterLoaded = (posthog) => {

    // assign to window so it globally avaliable in console
    window.posthog = posthog

    // adds raw user agent (useful to filter bots)
    posthog.register({'$user_agent': window.navigator.userAgent})

    // if user is authenticated at load time identify user
    if (getIsUserAuthenticated(store.getState())) {
        const userDetails = getUserDetails(store.getState())
        posthogIdentify(userDetails)
        // group logic based on organization
        const currentOrganizationId = loadFromStorage('organizationId')
        posthogGroup(currentOrganizationId, userDetails)
    }

}

export const posthogIdentify = (userDetails, authMethod = null) => {
    if (window.posthog && userDetails && userDetails.id) {
        if (authMethod) {
            posthog.register({ auth_method: authMethod })
        }
        if (userDetails.id) {
            posthog.identify(posthogDistinctId(userDetails.id),
                {
                    email: userDetails.email,
                    name: userDetails.display_name
                }
            )
        }
    }
}

export const posthogReset = () => {

    // remove identify and generate new distinctId
    if (window.posthog) {
        posthog.reset()
    }
}

export const posthogGroup = (organizationId, userDetails) => {

    // sets user to an organization group to track organization-level activity
    if (window.posthog && organizationId) {

        if (userDetails.organizations) {
            const organizationDetails = userDetails.organizations.find(org => { return org.organization_id === organizationId })
            posthog.group('organization', posthogDistinctId(organizationId), {
                name: organizationDetails.company_name
            })
        }
    }
}


export const posthogResetGroups = () => {

    // detach completly from all groups
    if (window.posthog) {
        posthog.resetGroups()
    }
}
