import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'react-layout-components';
import { FormattedNumber, injectIntl } from 'react-intl';

import Text from '../UI/Text/Text';
import Small from '../UI/Text/Small';
import IconText from '../UI/Text/IconText';

import CarbonCloud from '../UI/Icons/CarbonCloud';
import SurfaceArrowsOut from '../UI/Icons/SurfaceArrowsOut';
import CarbonCredits from '../UI/Icons/CarbonCredits';
import VisitSharesTag from '../UI/Tags/VisitSharesTag';

import FormattedUnit from '../Utils/FormattedUnit';
import { carbonAmountIn } from '../../utils/carbon';
import {OFFSET_TYPE_SQM_SHARES} from "../../constants/offsetTypes";

import {getFormattedWeight, getFormattedArea, getFormattedVCUs} from '../../utils/units';
import getFeatureFlags from '../../constants/featureFlags';

import { links } from '@reforestum/shared-utils';

import './ForestItem.css';
import Card from '../UI/Card/Card';

const ForestItem = ({ forest, isSectorShare }) => {
  const {
    offset_type,
    co2_years,
    timeline_formula,
    user_total_surface,
    captured_co2,
    id,
    main_image,
    name // either for the sector and the forest
  } = forest;
  
  const {
    forest_id,
    forest_name,
    sector_id,
    share_id
  } = forest; // destructured sector forest properties
  
  const ff = getFeatureFlags();

  const formattedCarbonCaptured = offset_type === OFFSET_TYPE_SQM_SHARES ?
    getFormattedWeight(carbonAmountIn(co2_years, timeline_formula, user_total_surface)) :
    getFormattedWeight(captured_co2);
  
  return (

    <Card href={isSectorShare && ff.findMyShares
      ? links.getProjectUrl(forest_id, sector_id, share_id)
      : links.getProjectUrl(forest_id || id)}>
        <Box center>
          <div
            className="forest-item__image special-dashboard__image"
            style={{
              backgroundImage: `url(${main_image})`
            }}
          />

          <Box column className="carbon-item__content">

            <div className='carbon-item-content__wrapper'>
              <Text tag="h3" className="forest-item__text">
                {name}
              </Text>
              {ff.findMyShares && isSectorShare && <VisitSharesTag /> }
            </div>
            {isSectorShare &&
            <Small className="forest-item-small__text">
              {forest_name}
            </Small>
            }

            {offset_type === OFFSET_TYPE_SQM_SHARES ?
              <Box className="carbon-item__info">
                <IconText icon={SurfaceArrowsOut} className="forest-item__carbon">
                  <Small color="light">
                    <FormattedNumber
                      value={getFormattedArea(user_total_surface).value}
                    /> <FormattedUnit unit={getFormattedArea(user_total_surface).unit} />
                  </Small>
                </IconText>
                <IconText icon={CarbonCloud} className="forest-item__carbon">
                  <Small color="green">
                    <FormattedNumber
                      value={formattedCarbonCaptured.value}
                    /> <FormattedUnit unit={formattedCarbonCaptured.unit} />
                  </Small>
                </IconText>
              </Box>
              :
              <Box className="carbon-item__info">
                <IconText icon={CarbonCredits} className="forest-item__carbon">
                  <Small color="light">
                    <FormattedNumber
                      value={getFormattedVCUs(captured_co2).value}
                    /> <FormattedUnit unit={getFormattedVCUs(captured_co2).unit} />
                  </Small>
                </IconText>
                <IconText icon={CarbonCloud} className="forest-item__carbon">
                  <Small color="green">
                    <FormattedNumber
                      value={formattedCarbonCaptured.value}
                    /> <FormattedUnit unit={formattedCarbonCaptured.unit} />
                  </Small>
                </IconText>
              </Box>
            }
          </Box>
        </Box>
    </Card>
  )
};

ForestItem.propTypes = {
  forest: PropTypes.object.isRequired,
};

export default injectIntl(ForestItem);
