import React from 'react';

const EditProfileIcon = props => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <path d="M18.7993 26.4165C22.6975 26.4165 25.8576 23.2564 25.8576 19.3582C25.8576 15.4599 22.6975 12.2998 18.7993 12.2998C14.901 12.2998 11.7409 15.4599 11.7409 19.3582C11.7409 23.2564 14.901 26.4165 18.7993 26.4165Z" stroke="#60B47D" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="square"/>
  <path d="M29.969 34.0825L30.4113 34.4879L31.2222 33.6034L30.7799 33.1979L29.969 34.0825ZM5.04077 37.1197C6.36118 35.1319 8.09652 33.4537 10.1274 32.2006L9.49727 31.1793C7.31887 32.5235 5.4575 34.3236 4.0412 36.4558L5.04077 37.1197ZM10.1274 32.2006C12.1583 30.9474 14.4366 30.1491 16.8054 29.8605L16.6603 28.6693C14.1194 28.9788 11.6757 29.8352 9.49727 31.1793L10.1274 32.2006ZM16.8054 29.8605C19.1743 29.5718 21.5776 29.7998 23.85 30.5286L24.2165 29.3859C21.7791 28.6042 19.2012 28.3597 16.6603 28.6693L16.8054 29.8605ZM23.85 30.5286C26.1224 31.2574 28.2099 32.4699 29.969 34.0825L30.7799 33.1979C28.893 31.4682 26.6539 30.1677 24.2165 29.3859L23.85 30.5286Z" fill="#60B47D"/>
  <path d="M25 48.6067C38.0375 48.6067 48.6066 38.0377 48.6066 25.0001C48.6066 11.9626 38.0375 1.39355 25 1.39355C11.9625 1.39355 1.39344 11.9626 1.39344 25.0001C1.39344 38.0377 11.9625 48.6067 25 48.6067Z" stroke="#1F3B21" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="square"/>
  <path d="M37.8813 30.3586L38.123 30.9077L38.226 30.8624L38.3056 30.7829L37.8813 30.3586ZM30.3744 33.6635L29.8253 33.4218L30.6162 34.2127L30.3744 33.6635ZM33.6793 26.1566L33.2552 25.7322L33.1756 25.8118L33.1302 25.9149L33.6793 26.1566ZM47.6787 19.7127L37.457 29.9343L38.3056 30.7829L48.5272 20.5612L47.6787 19.7127ZM37.6395 29.8095L30.1326 33.1144L30.6162 34.2127L38.123 30.9077L37.6395 29.8095ZM30.9235 33.9053L34.2285 26.3984L33.1302 25.9149L29.8253 33.4218L30.9235 33.9053ZM34.1034 26.581L46.3002 14.3922L45.4519 13.5434L33.2552 25.7322L34.1034 26.581Z" fill="#1F3B21"/>
  <path d="M33.6793 26.1804C34.6809 26.4623 35.5934 26.9967 36.3291 27.7325C37.0649 28.4683 37.5994 29.3807 37.8812 30.3824V30.3824" stroke="#1B4124" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M35.8992 27.2826L35.4748 27.7067L36.323 28.5555L36.7475 28.1314L35.8992 27.2826ZM46.7425 16.4472L35.8992 27.2826L36.7475 28.1314L47.5907 17.296L46.7425 16.4472Z" fill="#1F3B21"/>
  </svg>
  


)


export default EditProfileIcon;
