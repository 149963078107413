import { featureFlagEnv } from '../config'

const defaultFlags = {
    // Stable (all true)
    miraklLanding: true,
    forestShares: true,
    'forestDetails.viewPatronProfile': true,
    mrv: true,
    newPublicDashboard: true,
    publicDashboardNavigation: false,
    mrvGraphFooterInfo: true,
    forestCertificationsTags: true,
    PreferencesSwitchers: true,
    welcomeTextForestSectorProfile: true,
    beZeroInForestInfo: true,
    parametrizedLandingPage: true,
    mapSearch: true,
    mySharesInCorporateProfile: true,
    sharingButtonsInCorporateProfile: true,
    findMyShares: true,
    sponsorsTab: true,
    transactionsTab: true,
    partySwitcher: true,
    eventsInCorporateProfile: true,
    // Under development (maybe not always false or true)
    giftAForest: false,
    'nav.communityTab': false,
    'carbonItem.dateAdded': false,
    viewForestDetailsInModal: false,
    removedElementsFromNavPublicProfiles: false,
    titleAndDescriptionInGalleryImages: false,
    corporateTagInCorporateProfile: false,
    communityInCorporateProfile: true,
    corporateProfileAnalytics: true,
    contributors: true,
    craggHighResolutionImage: true,
    showTutorialSearchBarTooltip: true,
    vintagesWidgetInTransactionsTab: true,
    marketInsightsScreen: true,
    rfpResults: true,
    enterpriseActivationProfileLink: false,
    showCommunitiesInSearchResults: false,
    openGeneralSearchFromSearch: false,
    alternativeDashboardNotLoggedUsers: true,
    activateAccountTermsOfService: false,
    proposalDetailsExtras: true,
    proposalDetailsSidebar: true,
}

// avoid changing the feature flags in staging and production
// only change them in development environment...
// ...but when feature is ready, remove all fflags even those in development
const environmentFlags = {
    local: {
        enterpriseActivationProfileLink: true, // this is left here for E2E testing purposes
        activateAccountTermsOfService: true,
    },
    development: {
        activateAccountTermsOfService: true,
    },
    staging: {
    },
    production: {
    }
}

const getFeatureFlags = () => {
    return Object.assign({}, defaultFlags, environmentFlags[featureFlagEnv])
}

export default getFeatureFlags;
