// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { TabList, Tab, TabPanel } from 'react-tabs';
import { Box } from 'react-layout-components';
import {ShareButtons, generateShareIcon} from 'react-share'

import { links } from '@reforestum/shared-utils';
import store from '../../setup/store';

// Constants
import getFeatureFlags from '../../constants/featureFlags';
import {
  MyForestSummary as MyForestSummaryTexts,
  newsletterPopup,
  transactionsInProjectSection
} from '../../constants/messages'
import {PUBLIC_DASHBOARD_SECTION_IDS} from '../../constants/publicProfile'

// Components
import CompanyInfoSection from './CompanyInfoSection';
import RelatedCompanies from './RelatedCompanies';
import MySharesBlock from './MySharesBlock';
import MyForestSummary from '../Widgets/MyForestSummary/MyForestSummary';
import CommunityContainer from './CommunityContainer';
import PublicDashboardNavigation from './PublicDashboardNavigation';
import AvatarPlaceholder from '@reforestum/shared-components/dist/assets/images/avatar-placeholder.png';

import HeadingSmall from '../UI/Text/HeadingSmall';
import Token from '../UI/Text/Token';
import RoutedTabbed from '../UI/Tabs/RoutedTabbed';

// Styles
import './PublicDashboard.css';
import cx from 'classnames';
import {Button} from "@reforestum/shared-components"
import Text from "../UI/Text/Text"
import Modal from "../UI/Modal/Modal"
import CommunityPatronsPodium from "./CommunityPatronsPodium"
import {withRouter} from "react-router"
import {addAlert} from "../../actions/alerts"
import ClaimOrganization from "../AccountSettings/OrganizationSettings/OrganizationInformation/ClaimOrganization"
import RoundedWhitePlus from '../UI/Icons/RoundedWhitePlus';
import EditProfileIcon from '../UI/Icons/EditProfileIcon';
import StandardFormModal from "../UI/Modal/StandardFormModal"
import {getUserOrImpersonatingOrg} from "../../utils/usersAndOrganizations"
import {getUserDetails} from "../../selectors/userSession"
import {connect} from "react-redux"
import BetaTooltip from '../UI/Tabs/BetaTooltip';
import CopyToClipboardIcon from "../UI/Icons/CopyToClipboardIcon"
import GlobeSimple from "../UI/Icons/GlobeSimple"
import CarbonCredits from "../UI/Icons/CarbonCredits"

const {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
} = ShareButtons

const FacebookIcon = generateShareIcon('facebook')
const TwitterIcon = generateShareIcon('twitter')
const WhatsappIcon = generateShareIcon('whatsapp')
const LinkedinIcon = generateShareIcon('linkedin')
const RedditIcon = generateShareIcon('reddit')

const ff = getFeatureFlags();

const ONE_COLUMN_UI_MAX_WIDTH = 900;

class PublicDashboard extends Component {

  constructor (props) {
    super(props);

    this.state = {
      showClaimOrganizationModal: false,
      showOnboardYourCompanyModal: false,
      showSharingModal: false,
      showRequestThatCompanyOnboardModal: false,
      showAddNewProjectModal: false,
      showNewsletterModal: false,
      isOneColumn: window.innerWidth <= ONE_COLUMN_UI_MAX_WIDTH
    }

    this.setIsOneColumn = this.setIsOneColumn.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.setIsOneColumn)
    if(this.props.unOnboardedCompanyMode && (this.props.user && this.props.user.guest === undefined)) {
      this.newsletterTimeout = setTimeout(() => this.handleNewsletterPopup(), 30000)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setIsOneColumn)
    clearTimeout(this.newsletterTimeout)
  }

  setIsOneColumn() {
    const isOneColumn = window.innerWidth <= ONE_COLUMN_UI_MAX_WIDTH
    if(isOneColumn !== this.state.isOneColumn) this.setState({isOneColumn: isOneColumn});
  }

  handleNewsletterPopup() {
    const hasSeenNewsletterPopup = localStorage.getItem('ref__hasSeenNewsletterPopup')
    if(!hasSeenNewsletterPopup) {
      this.setState({showNewsletterModal: true})
      localStorage.setItem('ref__hasSeenNewsletterPopup', true)
    }
  }

  render() {
    const {
      intl,
      profile,
      projects,
      myContributions,
      openGallery,
      unOnboardedCompanyMode,
      organizationPrivateDashboardMode,
      children
    } = this.props
    const {
      avatar_url,
      name,
      bio,
      year_foundation,
      website,
      environmental_statement,
      community_size,
      has_unverified_transactions,
    } = profile;

    const {isOneColumn} = this.state
 
    const companyNickname = profile.nickname ? profile.nickname : name;

    const showCommunity = Boolean(ff.communityInCorporateProfile && profile && community_size) && !organizationPrivateDashboardMode

    const tabs = {
      FORESTS: 'forests',
      COMMUNITY: 'community'
    }

    // PATHS FOR TABS
    const paths = [
      tabs.FORESTS,
    ]

    if(showCommunity || unOnboardedCompanyMode) paths.push(tabs.COMMUNITY)

    const currentTab = window.location.href.split("/").pop();

    const isCurrentTab = (tab) => {
      return tab === currentTab
    }

    const updateRoute = param => {
      const currentLocation = this.props.history.location.pathname
      if(currentLocation.indexOf(param) >= 0) {
        this.props.history.push(`${currentLocation.split(`/${param}`)[0]}`)
      }
      else {
        this.props.history.push(`${currentLocation}/${param}`)
      }
    }

    const handleShowOnboardYourCompanyModal = () => {
      updateRoute('get-started')
      this.setState({showOnboardYourCompanyModal: !this.state.showOnboardYourCompanyModal})
    }

    const handleShowRequestThatCompanyOnboardModal = () => {
      updateRoute('request-action')
      this.setState({showRequestThatCompanyOnboardModal: !this.state.showRequestThatCompanyOnboardModal})
    }

    // PROJECTS BLOCK
    const renderFoundedForests = () => {
      return (
        projects.map((forest, index) => {

          const {
            transactions,
            registry,
            certifications,
            forest_id,
            sector_id,
            name,
            forest_surface,
            sector_surface,
            user_owned_surface,
            vcus,
            main_image,
            location,
            reforesters_amount,
            planting_date,
            offset_type,
            timeline_formula,
            years_co2,
            description,
            events,
            org_total_credits,
            gallery,
            project_type
          } = forest;

          const exploreLink = sector_id
            ? links.getProjectUrl(forest_id, sector_id)
            : links.getProjectUrl(forest_id);

          const reforestersLink = `${exploreLink}/reforesters`;
          const isForestLevel = Boolean(sector_id)

          return (<MyForestSummary
            key={index}
            type={offset_type}
            forestImage={main_image}
            forestName={name}
            location={location}
            exploreLink={exploreLink}
            reforestersLink={reforestersLink}
            certifications={certifications}
            transactions={transactions}
            registry={registry}
            area={forest_surface}
            sectorSurface={sector_surface}
            userOwnedSurface={user_owned_surface}
            companyImage={avatar_url}
            overlayCompanyImageOnForestImage={!!forest.org_is_owner}
            reforestumProjectType={forest.reforestum_project_type}
            orgIsOwner={forest.org_is_owner}
            reforesters_amount={reforesters_amount}
            plantingDate={planting_date}
            formula={timeline_formula}
            vcus={vcus}
            project_type={project_type}
            orgTotalCredits={org_total_credits}
            years={years_co2}
            description={forest.org_is_owner ? description : ''}
            openGallery={openGallery}
            events={events}
            gallery={gallery}
            companyNickname={companyNickname}
            unOnboardedCompanyMode={unOnboardedCompanyMode}
            organizationPrivateDashboardMode={organizationPrivateDashboardMode}
            handleShowOnboardYourCompanyModal={handleShowOnboardYourCompanyModal}
            handleShowRequestThatCompanyOnboardModal={handleShowRequestThatCompanyOnboardModal}
            isForestLevel={isForestLevel}
          />)
        })
      )
    }

    // my contributions - MySharesBlock
    const myContributionsArray = myContributions && Array.isArray(myContributions) ? myContributions : null;

    // projects and sectors data for CommunityContainer
    let communityProjectIds = []
    let communitySectorsIds = []
    projects && projects.forEach(project => {
      // Don't take into account in community
      // projects that are not owned by the company
      // (e.g. comunnity forests compensations)
      if(!project.org_is_owner) {
        return
      }

      communityProjectIds.push(project.forest_id)
      communitySectorsIds.push(project.sector_id)
    })

    const getCommunityTab = () => {
      const onboardedOnlyComponent = unOnboardedCompanyMode
        ? null
        : <Token
          content={community_size && community_size.toString()}
          color={isCurrentTab(tabs.COMMUNITY) ? 'green' : 'inactive'}
        />
      return (
        unOnboardedCompanyMode
          ? <div id={PUBLIC_DASHBOARD_SECTION_IDS.PROJECTS_AND_COMMUNITY} onClick={handleShowOnboardYourCompanyModal} className="public-dashboard__unonboarded-company__pseudo-tab">
          <div className="public-dashboard-new-forests-list-title__wrapper">
            <HeadingSmall className={
              cx(
                'public-dashboard-new-forests-list-title__heading',
                {'public-dashboard-new-forests-list-title__heading--innactive': !isCurrentTab(tabs.COMMUNITY)}
              )}
            >
              <FormattedMessage
                id={'Header.community'}
                defaultMessage={'Community'} />
            </HeadingSmall>
            {onboardedOnlyComponent}
          </div>
        </div>
          : <Tab id={PUBLIC_DASHBOARD_SECTION_IDS.PROJECTS_AND_COMMUNITY}>
          <div className="public-dashboard-new-forests-list-title__wrapper">
            <HeadingSmall className={
              cx(
                'public-dashboard-new-forests-list-title__heading',
                {'public-dashboard-new-forests-list-title__heading--innactive': !isCurrentTab(tabs.COMMUNITY)}
              )}
            >
              <FormattedMessage
                id={'Header.community'}
                defaultMessage={'Community'} />
            </HeadingSmall>
            {onboardedOnlyComponent}
          </div>
        </Tab>
      )
    }

    const getCommunityTabPanelContents = () => {
      let result
      const exampleProfileLeaderboard = [
        {
          "display_name": <FormattedMessage
            id={'PublicDashboard.unOnboardedCompany.community-tab-customer'}
            defaultMessage={`John (Customer)`}
          />,
          "avatar_url": "https://default.avatars.reforestum.com/JF_yellow.svg",
          "co2_amount": 2.33,
          "vcu_amount": 2.33,
          "sqm_amount": 0,
          "num_forest": 1
        },
        {
          "display_name": <FormattedMessage
            id={'PublicDashboard.unOnboardedCompany.community-tab-partner'}
            defaultMessage={`ACME (Partner)`}
          />,
          "avatar_url": "https://default.avatars.reforestum.com/AC_green.svg",
          "co2_amount": 0.558,
          "vcu_amount": 0.558,
          "sqm_amount": 0,
          "num_forest": 1
        },
        {
          "display_name": <FormattedMessage
            id={'PublicDashboard.unOnboardedCompany.community-tab-employee'}
            defaultMessage={`Peter (Employee)`}
          />,
          "avatar_url": "https://default.avatars.reforestum.com/PL_red.svg",
          "co2_amount": 0.34,
          "vcu_amount": 0.34,
          "sqm_amount": 0,
          "num_forest": 1
        }
      ]
      if(!unOnboardedCompanyMode) {
        result = <CommunityContainer userName={name.toLowerCase()} projectsIds={communityProjectIds.join(',')} sectorsIds={communitySectorsIds.join(',')} />
      }
      else {
        result = <div>
          <Box className="community-container public-dashboard-unonboarded-company-community-tab-explanation__community-container">
            <div className={'public-dashboard-unonboarded-company-community-tab-explanation'}>
              <h2>
                <FormattedMessage
                  id={'PublicDashboard.unOnboardedCompany.community-tab-explanation-heading'}
                  defaultMessage={`Explore and recognize the community contributing to these offsets`}
                  values={{companyName: companyNickname}}
                />
              </h2>
              <h3>
                <FormattedMessage
                  id={'PublicDashboard.unOnboardedCompany.community-tab-explanation'}
                  defaultMessage={`Bring transparency to your offsetting; visualize the community whose collaborations are making a difference.`}
                  values={{companyName: companyNickname}}
                />
              </h3>
            </div>
            <CommunityPatronsPodium profileLeaderboard={exampleProfileLeaderboard} />
            <div className={'public-dashboard-unonboarded-company-community-tab-CTAs'}>
              <Button onClick={handleShowOnboardYourCompanyModal} small className="btn--blank public-dashboard-unonboarded-company-community-tab-CTAs__button">
                <Text className={'public-dashboard-unonboarded-company__page-end-CTA__button__text'}>
                  <FormattedMessage
                    id={'PublicDashboard.unOnboardedCompany.finalCTA.button'}
                    defaultMessage={'Add Your Company'}
                  />
                </Text>
              </Button>
              <Button onClick={handleShowRequestThatCompanyOnboardModal} small className="btn--blank public-dashboard-unonboarded-company-community-tab-CTAs__button">
                <Text className={'my-forest-summary__unonboarded-company-activity__CTA__button__text'}>
                  <FormattedMessage
                    id={'MyForestSummary.unonboardedCompanyDefaultEventsCTAContactCompany'}
                    defaultMessage={'Request updates'}
                    values={{companyName: companyNickname}}
                  />
                </Text>
              </Button>
            </div>
          </Box>
        </div>
      }
      return result
    }

    const shouldShowSimilarCompanies = () => unOnboardedCompanyMode

    const renderAfterMainContent = () =>
      <div className={'public-dashboard-after-main-content'}>
        <div className="public-dashboard-new-content-left__wrapper"></div>
        <div className="public-dashboard-new-content-right">
          <section id={PUBLIC_DASHBOARD_SECTION_IDS.SIMILAR_COMPANIES} className={'generic-public-dashboard-content-section'}>
              <h2 id={'related-companies'}>
                <FormattedMessage
                  id={'PublicDashboard.relatedCompanies.title'}
                  defaultMessage={`Discover Similar Companies`}
                />
              </h2>
              <RelatedCompanies organizationId={this.props.profile.org_id} />
          </section>
        </div>
      </div>

    const renderUnonboardedCompanyEndPageCTA = () => <div className={'public-dashboard-unonboarded-company__page-end-CTA'}>
      <h2 className={'public-dashboard-unonboarded-company__page-end-CTA__title'}>
        <FormattedMessage
          id={'PublicDashboard.unOnboardedCompany.finalCTA.title'}
          defaultMessage={`Join the World's Top Companies`}
        />
      </h2>
      <h3 className={'public-dashboard-unonboarded-company__page-end-CTA__subtitle'}>
        <FormattedMessage
          id={'PublicDashboard.unOnboardedCompany.finalCTA.subtitle'}
          defaultMessage={'Bring Transparency and Community to your Carbon Offsetting Efforts'}
        />
      </h3>
      <a target={'_blank'} href={'https://reforestum.com/contact-us'}>
        <Button small className="btn--blank public-dashboard-unonboarded-company__page-end-CTA__button">
          <Text className={'public-dashboard-unonboarded-company__page-end-CTA__button__text'}>
            <FormattedMessage
              id={'PublicDashboard.unOnboardedCompany.finalCTA.button'}
              defaultMessage={'Add Your Company'}
            />
          </Text>
        </Button>
      </a>
    </div>

    const renderOnboardYourCompanyModal = () => <Modal
      clickClose={handleShowOnboardYourCompanyModal}
      content={<div className={'public-dashboard__unonboarded-company__onboarding-modal'}>
        <div className="public-dashboard__unonboarded-company__onboarding-modal__avatar">
          <img className="public-dashboard__unonboarded-company__onboarding-modal__avatar__img" src={avatar_url || AvatarPlaceholder} alt="company logo" />
        </div>
        <h2>
          <FormattedMessage
            id={'PublicDashboard.unOnboardedCompany.activationCTA.title'}
            defaultMessage={`Complete your company's profile`}
          />
        </h2>
        <h3>
          <FormattedMessage
            id={'PublicDashboard.unOnboardedCompany.activationCTA.subtitle'}
            defaultMessage={'Bring transparency and community to your CO2 neutralization efforts'}
          />
        </h3>
        <Button onClick={() => this.setState({showClaimOrganizationModal: true, showOnboardYourCompanyModal: false})} small className="btn--blank public-dashboard__unonboarded-company__cta">
          <FormattedMessage
            id={'PublicDashboard.unOnboardedCompany.activationCTA.getStartedCTA'}
            defaultMessage={'Get Started'}
          />
        </Button>
      </div>}
    />

    const renderSharingModal = () => <Modal
      clickClose={toggleSharingModal}
      content={
        <div className={'public-dashboard__sharing-modal'}>
          <h1>
            {this.props.intl.formatMessage(MyForestSummaryTexts.sharingMessage, {companyName: companyNickname})}
          </h1>
          <p>
            {this.props.intl.formatMessage(MyForestSummaryTexts.sharingDescription, {companyName: companyNickname})}
          </p>
          {renderSharingButtons()}
        </div>
      }
    />

    const renderRequestThatCompanyOnboardModal = () => <StandardFormModal
      intl={this.props.intl}
      preHeading={
        <div className="standard-form-modal__avatar">
          <img className="standard-form-modal__avatar__img" src={avatar_url || AvatarPlaceholder} alt="company logo" />
        </div>
      }
      heading={
        <FormattedMessage
          id={'PublicDashboard.unOnboardedCompany.recordInterest.title'}
          defaultMessage={`Let this company know that you'd like to know more`}
          values={{companyName: companyNickname}}
        />
      }
      description={
        <FormattedMessage
          id={'PublicDashboard.unOnboardedCompany.recordInterest.text'}
          defaultMessage={"In Reforestum's next meeting with this company, we will let them know that you and others like you are interested in their improved neutralization activities, community and transparency, taking into consideration any specific notes you may have."}
          values={{companyName: companyNickname}}
        />
      }
      handleClose={handleShowRequestThatCompanyOnboardModal}
      submitCallback={() => this.setState({showRequestThatCompanyOnboardModal: false})}
      additionalContext={{company: name}}
      successAlertSettings={{
        type: 'success',
        message: this.props.intl.formatMessage(MyForestSummaryTexts.unonboardedCompanyInterestFormSubmissionSuccess),
        dismissAfter: 8000
      }}
    />

    const renderPublicProfileProjectsAndCommunityTabs = () => (
      projects && projects.length > 0
        ?
        (<RoutedTabbed paths={paths} theme="public-profile">
          <TabList id={"test"} className="public-dashboard-new-forests-tab-list">
            <div className="public-dashboard-new-forests-list-title">
              <Tab>
                <div className="public-dashboard-new-forests-list-title__wrapper">
                  <HeadingSmall className={
                    cx(
                      'public-dashboard-new-forests-list-title__heading',
                      {'public-dashboard-new-forests-list-title__heading--innactive': !isCurrentTab(tabs.FORESTS)}
                    )}
                  >
                    <FormattedMessage
                      id={'Dashboard.projectsListPublicProfile'}
                      defaultMessage={'Projects'}
                    />
                  </HeadingSmall>
                  <Token
                    content={projects.length.toString()}
                    color={isCurrentTab(tabs.FORESTS) ? 'green' : 'inactive'}
                  />
                </div>
              </Tab>
              {(showCommunity || unOnboardedCompanyMode)
                ? getCommunityTab()
                : null
              }
            </div>
          </TabList>
          <TabPanel>
            <div className='public-dashboard-new-forests-container'>
              {renderFoundedForests()}
            </div>
          </TabPanel>
          {showCommunity && <TabPanel>
            {getCommunityTabPanelContents()}
          </TabPanel>
          }
        </RoutedTabbed>
        ) : 
        null
    )

    const renderOrgaizationPrivateDashboardPortfolio = () => (
      <div>
            <div className="public-dashboard-new-forests-list-title public-dashboard-new-forests-list-title--private-dashboard">
                <div className="public-dashboard-new-forests-list-title__wrapper">
                  <p className='public-dashboard-new-forests-list-title__heading--private-dashboard'>
                    {
                      //@Pablo D TODO: Translate this text
                    }
                    <FormattedMessage
                      id={'Dashboard.portfolioOfProjectsTitle'}
                      defaultMessage={'Portfolio of projects'}
                    />
                  </p>
                </div>
                <Button onClick={() => this.setState({showAddNewProjectModal: true})} className={"public-dashboard-new-add-project-button"}>
                  <RoundedWhitePlus className="public-dashboard-new-add-project-button-icon"/>
                    <FormattedMessage
                      id={'Dashboard.addNewProjectButton'}
                      defaultMessage={'Add new project'}
                    />
                </Button>
            </div>
            <div className='public-dashboard-new-forests-container'>
              {(projects && projects.length > 0) ? 
                renderFoundedForests() :
                <div className="public-dashboard-new-information-message-box public-dashboard-new-information-message-box--no-projects">
                  <div></div>
                  <div>
                    <p className="public-dashboard-new-information-message-box-text--bold">
                      <FormattedMessage
                          id={'OrganizationPrivateDashboard.noProjectsTitle'}
                          defaultMessage={'You don\'t have any project in your portfolio yet.'}
                      /> 
                    </p>
                    <p className="public-dashboard-new-information-message-box-text" style={{marginTop: '8px'}}>
                      <FormattedMessage
                          id={'OrganizationPrivateDashboard.noProjectsText'}
                          defaultMessage={'Add your first project today!'}
                      /> 
                    </p>
                  </div>
                </div>
              }
            </div>
      </div>
    )

    const renderOrganizationDashboardWelcomeSnack = () => (
      <div className="public-dashboard-new-information-message-box public-dashboard-new-information-message-box--welcome">
                  <EditProfileIcon className="public-dashboard-new-information-message-box-icon"/>
                  <div>
                    <p className="public-dashboard-new-information-message-box-text--bold">
                    <FormattedMessage
                        id={'OrganizationPrivateDashboard.welcomeToDashboardTitle'}
                        defaultMessage={'Welcome to {companyName} dashboard!'}
                        values={{companyName: companyNickname}}
                    /> 
                    </p>
                    <p className="public-dashboard-new-information-message-box-text">
                      <FormattedMessage
                          id={'OrganizationPrivateDashboard.welcomeToDashboardText'}
                          defaultMessage={'Here you can manage your portfolio of projects and your public profile information.'}
                      />  
                    </p>
                  </div>
                </div>
    )

    const renderBetaBannerSnack = () => (
      <div className="public-dashboard-new-information-message-box public-dashboard-new-information-message-box--beta">
                  <BetaTooltip/>
                  <div>
                    <p className="public-dashboard-new-information-message-box-text--bold">
                    <FormattedMessage
                        id={'OrganizationPrivateDashboard.betaDashboardTitle'}
                        defaultMessage={'This dashboard has unverified transactions'}
                    /> 
                    </p>
                    <p className="public-dashboard-new-information-message-box-text">
                      <FormattedMessage
                          id={'OrganizationPrivateDashboard.betaDashboardText'}
                          defaultMessage={'The information shown in this dashboard may not be 100% accurate as some autoimported transactions can be wrong and not belong to this organization.'}
                      />  
                    </p>
                  </div>
                </div>
    )

    const toggleSharingModal = () => {
      this.setState({showSharingModal: !this.state.showSharingModal})
    }

    const copyLinkTextToClipboard = (link) => {
      this.setState({showSharingModal: false})
      navigator.clipboard.writeText(link)
      store.dispatch(addAlert({
        type: 'success',
        message: intl.formatMessage(MyForestSummaryTexts.textCopiedToClipboard),
        dismissAfter: 6000
      }))
    }

    const getNavSections = () => {
      let result = [
        {id: PUBLIC_DASHBOARD_SECTION_IDS.PROJECTS_AND_COMMUNITY, title: intl.formatMessage(MyForestSummaryTexts.jumpToSectionProjectsAndCommunity), icon: <CarbonCredits width={24} height={24}/>},
      ]

      if(shouldShowSimilarCompanies()) {
        result.push({id: PUBLIC_DASHBOARD_SECTION_IDS.SIMILAR_COMPANIES, title: intl.formatMessage(MyForestSummaryTexts.jumpToSectionSimilarCompanies), icon: <GlobeSimple />})
      }

      return result
    }

    const renderSharingButtons = () => {
      const shareButtonProps = {
        url: window.location.href.replace(/\/forests$/gm, ''),
        className: 'public-dashboard__sharing__button',
      }

      const shareIconProps = {
        size: 45,
        round: true,
        className: 'public-dashboard__sharing__icon',
        iconBgStyle: {fill: 'var(--ref-greyscale-500)'}
      }

      const shareButtons = [
        {button: LinkedinShareButton, icon: LinkedinIcon},
        {button: FacebookShareButton, icon: FacebookIcon},
        {button: RedditShareButton, icon: RedditIcon},
        {button: WhatsappShareButton, icon: WhatsappIcon},
        {button: TwitterShareButton, icon: TwitterIcon}
      ]

      return <div className={'public-dashboard__sharing'}>
        {shareButtons.map(
          (b, i) => <b.button key={`sharing-button-${i}`} {...shareButtonProps}>
            <b.icon {...shareIconProps} />
          </b.button>
        )}
        <div className={'public-dashboard__sharing__copy-to-clipboard-icon__container'} onClick={() => copyLinkTextToClipboard(shareButtonProps.url)}>
          <CopyToClipboardIcon item1Props={{stroke: '#fff'}} item2Props={{stroke: '#fff'}}/>
        </div>
      </div>
    }

    return (
      <div id='public-dashboard-container' className={`public-dashboard-new-container ${unOnboardedCompanyMode ? 'public-dashboard-new-container__unonboarded-company-mod' : ''}`}>
        {
          this.state.showClaimOrganizationModal &&
          <ClaimOrganization
            addAlert={(alertObject) => store.dispatch(addAlert(alertObject))}
            orgId={this.props.profile.org_id}
            showClaimOrganizationModal={this.state.showClaimOrganizationModal}
            setShowClaimOrganizationModal={(value) => this.setState({showClaimOrganizationModal: value})}
          />
        }
        {this.state.showOnboardYourCompanyModal && renderOnboardYourCompanyModal()}
        {this.state.showSharingModal && renderSharingModal()}
        {this.state.showRequestThatCompanyOnboardModal && renderRequestThatCompanyOnboardModal()}
        {
          this.state.showAddNewProjectModal && <StandardFormModal
            hideFirstName
            hideLastName
            action={'add-organization-project'}
            intl={intl}
            heading={<span>{intl.formatMessage(transactionsInProjectSection.genericContactFormHeading)}</span>}
            description={<span>{intl.formatMessage(transactionsInProjectSection.genericContactFormInstructions)}</span>}
            handleClose={() => this.setState({showAddNewProjectModal: false})}
            submitCallback={() => this.setState({showAddNewProjectModal: false})}
            additionalContext={{partyId: getUserOrImpersonatingOrg(this.props.user.id)}}
            successAlertSettings={{
              type: 'success',
              message: this.props.intl.formatMessage(transactionsInProjectSection.genericSuccessMessage),
              dismissAfter: 8000
            }}
          />
        }
        {
          this.state.showNewsletterModal && <StandardFormModal
            action={'request-newsletter-subscription'}
            displayKnownEmail
            hideFirstName
            hideLastName
            hideTextArea
            intl={intl}
            heading={<span>{intl.formatMessage(newsletterPopup.newsletterPopupHeading)}</span>}
            description={<span>{intl.formatMessage(newsletterPopup.newsletterPopupDescription)}</span>}
            cta={<span>{intl.formatMessage(newsletterPopup.newsletterPopupCTA)}</span>}
            handleClose={() => this.setState({showNewsletterModal: false})}
            submitCallback={() => this.setState({showNewsletterModal: false})}
            successAlertSettings={{
              type: 'success',
              message: this.props.intl.formatMessage(newsletterPopup.newsletterPopupSubscriptionSuccess),
              dismissAfter: 8000
            }}
          />
        }
        <div className="public-dashboard-new-content">
          <div className="public-dashboard-new-content-left__wrapper">
            {has_unverified_transactions && !organizationPrivateDashboardMode && isOneColumn && renderBetaBannerSnack()}
            {organizationPrivateDashboardMode && isOneColumn && renderOrganizationDashboardWelcomeSnack()}
            <CompanyInfoSection
              className="public-dashboard-new-content-left"
              name={name}
              avatar_url={avatar_url}
              bio={bio}
              foundation_year={year_foundation}
              website={website}
              environmental_statement={environmental_statement}
              companyNickname={companyNickname}
              unOnboardedCompanyMode={unOnboardedCompanyMode}
              organizationPrivateDashboardMode={organizationPrivateDashboardMode}
              toggleSharingModal={toggleSharingModal}
              handleShowOnboardYourCompanyModal={handleShowOnboardYourCompanyModal}
            />
            {ff.mySharesInCorporateProfile && myContributionsArray.length > 0 && <MySharesBlock myContributions={myContributionsArray} name={name}/>}
            {
              (ff.publicDashboardNavigation && getNavSections() && getNavSections().length > 1) && <PublicDashboardNavigation
                sectionIdsAndTitles={getNavSections()}
              />
            }
          </div>

          <div className="public-dashboard-new-content-right" style={{transform: "translateY(-20px)"}}>
            {organizationPrivateDashboardMode && !isOneColumn && renderOrganizationDashboardWelcomeSnack()}
            {has_unverified_transactions && !organizationPrivateDashboardMode && !isOneColumn && renderBetaBannerSnack()}

            {organizationPrivateDashboardMode ? renderOrgaizationPrivateDashboardPortfolio() : renderPublicProfileProjectsAndCommunityTabs()}
          </div>
        </div>
        {shouldShowSimilarCompanies() && renderAfterMainContent()}
        {unOnboardedCompanyMode && renderUnonboardedCompanyEndPageCTA()}
        {children}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: getUserDetails(state)
})

PublicDashboard.propTypes = {
  captured: PropTypes.number.isRequired,
  emitted: PropTypes.number.isRequired,
  companySlug: PropTypes.string.isRequired,
  current_c02_captured: PropTypes.number,
  future_c02_captured: PropTypes.number,
  total_vcus: PropTypes.number,
  miterd_credits: PropTypes.number,
  has_community: PropTypes.bool,
  formula: PropTypes.string,
  oxygen: PropTypes.number,
  profile: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  intl: intlShape,
  myContributions: PropTypes.array,
  openGallery: PropTypes.func.isRequired,
  unOnboardedCompanyMode: PropTypes.bool,
  organizationPrivateDashboardMode: PropTypes.bool,
  publicProfileSlug: PropTypes.string,
};

export default connect(
  mapStateToProps,
)(
  injectIntl(withRouter(PublicDashboard))
)
