import React from 'react';
import PropTypes from 'prop-types';
import Box from 'react-layout-components';

import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import {
  getPrivacyOptionsForIndividuals,
  getPrivacyOptionsForCompany
} from '../../utils/getPrivacyOptions';

import { OFFSET_ACTIVATION_TYPES } from '../../constants/enterpriseLanding';

import AuthForm from './AuthForm';
import EnterpriseUserActivationForm from './EnterpriseUserActivationForm';
import Heading from '../UI/Text/Heading';
import Text from '../UI/Text/Text';
import Logo from '../UI/Logo/Logo';

import './EnterpriseUserActivation.css'

const EnterpriseUserActivation = ({
  companyImage,
  activationId,
  changeLoginView,
  signup,
  signupErrorMessages,
  isLoading,
  defaultLandingImage,
  companyProfileSlug,
  handleSuccessfullyRegistration,
  handleSuccessfullyRegistrationB2b,
  offsetTransactionType,
  activationStatus,
  orgId,
  handleLogin,
  intl
}) => {

  const privacyOptions = offsetTransactionType === OFFSET_ACTIVATION_TYPES.B2B2B 
    ? getPrivacyOptionsForCompany(intl) // special case for B2B2B
    : getPrivacyOptionsForIndividuals(intl);

  const addDefaultSrc = (ev) => ev.target.src = defaultLandingImage

  return (
    <Box className="activation-main__wrapper">
      <div className="left-side__wrapper">
        <img
          onError={addDefaultSrc}
          src={companyImage}
          className="enterprise-cover__image"
          alt="enterprise cover"
        />
      </div>

      <div className="enterprise-sidebar">
        <div className="reforestum-logo__wrapper">
            <Logo className="reforestum__logo" />
        </div>
        <Heading>
            <FormattedMessage 
                id={'EnterpriseParamsForm.welcomeHeader'}
                defaultMessage={'Join Reforestum'}
            />
        </Heading>
        <div className='enterprise-form-welcome-text__wrapper'>
            <Text>
                <FormattedMessage 
                    id={'EnterpriseParamsForm.welcomeText'}
                    defaultMessage={'Thank you for wanting to make the world a better place!'}
                />
                <br></br>
                <FormattedMessage 
                    id={'EnterpriseParamsForm.indicationsText'}
                    defaultMessage={'Type your information below to get started.'}
                />
            </Text>
        </div>
        <AuthForm>
          <EnterpriseUserActivationForm
            offsetTransactionType={offsetTransactionType}
            handleSuccessfullyRegistration={handleSuccessfullyRegistration}
            handleSuccessfullyRegistrationB2b={handleSuccessfullyRegistrationB2b}
            handleLogin={handleLogin}
            signup={signup}
            isLoading={isLoading}
            errorMessages={signupErrorMessages}
            activationId={activationId}
            changeLoginView={changeLoginView}
            privacyOptions={privacyOptions}
            companyProfileSlug={companyProfileSlug}
            orgId={orgId}
            activationStatus={activationStatus}
          />
        </AuthForm>
      </div>
    </Box>
  );
};

EnterpriseUserActivation.propTypes = {
  companyImage: PropTypes.string,
  activationId: PropTypes.string,
  changeLoginView: PropTypes.func.isRequired,
  signup: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  signupErrorMessages: PropTypes.string,
  offsetTransactionType: PropTypes.string.isRequired,
  intl: intlShape
};

export default injectIntl(EnterpriseUserActivation);
