import React from 'react';
import { Helmet } from 'react-helmet';

import Wrapper from '../components/Layout/Wrapper/Wrapper';
import MobileTopbar from "../components/Header/MobileTopbar/MobileTopbar"
import OrganizationsListContainer from '../containers/OrganizationsListContainer';

const OrganizationsList = () => {
  return (
    <Wrapper>
        <Helmet>
            <title>
            Organizations | Reforestum
            </title>
            <link rel="canonical" href={`https://app.reforestum.com/organizations`} />
            <meta name="description" content="Explore the Organizations contributing in different projects across the Voluntary Carbon Markets"/>
        </Helmet>
        <MobileTopbar>
        </MobileTopbar>
        <OrganizationsListContainer/>
    </Wrapper>
  );
};

export default OrganizationsList;
