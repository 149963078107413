import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { injectIntl, intlShape } from 'react-intl';

import Row from '../UI/Forms/Row';
import RowAligner from '../UI/Forms/RowAligner';
import HeadingSmall from '../UI/Text/HeadingSmall';
import StripeForm from './StripeForm';
import CoinbaseForm from './CoinbaseForm';
import FinePrint from './FinePrint';
import CheckboxRadio from '../UI/Forms/CheckboxRadio';
import { checkoutMessages } from '../../constants/messages';
import { PAYMENT_GATEWAY } from '../../reducers/checkout';

import './Payment.css';

class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formHeight: 0,
      payWithCrypto: false
    };
  }

  componentDidMount() {
    this.updateFormHeight();
  }

  componentDidUpdate() {
    this.updateFormHeight();
  }

  updateFormHeight() {
    if (this.state.formHeight !== this.form.clientHeight) {
      this.setState({
        formHeight: this.form.clientHeight
      });
    }
  }

  render() {
    const {
      quantity,
      billingComplete,
      forestId,
      forestName,
      price,
      user,
      intl,
      isPaying,
      isCryptoPaying,
      paymentErrors,
      cryptoPaymentErrors,
      makePayment,
      makeCryptoPayment,
      selectPaymentType,
      paymentType,
      sources,
    } = this.props;

    return (
      <div className="checkout-payment-form">
        <Row>
          <RowAligner>
            <div>
              <HeadingSmall color={billingComplete ? 'dark' : 'light'}>
                <FormattedMessage
                  id={'Payment.title'}
                  defaultMessage={'2. Payment Information'}
                />
              </HeadingSmall>
            </div>
          </RowAligner>
        </Row>

        <div
          className="checkout-payment-form__form-wrapper"
          style={{
            maxHeight: billingComplete ? this.state.formHeight : 0
          }}>



            <div
              className="checkout-payment-form__form"
              ref={form => this.form = form}>

              <div onClick={() => selectPaymentType(paymentType)} className='payment-gateway-radio-container'>
                <CheckboxRadio
                  input={
                    {checked: paymentType === PAYMENT_GATEWAY.COINBASE ? false : true}
                  }
                  label={intl.formatMessage(checkoutMessages.payWithCardLabel)}
                  type={'radio'}
                  meta={{touched: true}}
                  className='payment-gateway-radio'
                />
              </div>

              {
                paymentType === PAYMENT_GATEWAY.COINBASE
                ?
                <div>
                  <CoinbaseForm
                    quantity={quantity}
                    forestId={forestId}
                    forestName={forestName}
                    isCryptoPaying={isCryptoPaying}
                    makeCryptoPayment={makeCryptoPayment}
                    cryptoPaymentErrors={cryptoPaymentErrors}
                    price={price}
                    user={user}
                    coin={'BTC'}
                    sources={sources}
                  />
                  <FinePrint version={PAYMENT_GATEWAY.COINBASE}/>
                </div>
                :
                <div>
                  <StripeForm
                    quantity={quantity}
                    forestId={forestId}
                    forestName={forestName}
                    isPaying={isPaying}
                    makePayment={makePayment}
                    paymentErrors={paymentErrors}
                    price={price}
                    user={user}
                    sources={sources}
                  />

                  <FinePrint version={PAYMENT_GATEWAY.STRIPE}/>
                </div>
              }

            </div>

        </div>

      </div>
    );
  }
}

Payment.propTypes = {
  quantity: PropTypes.number.isRequired,
  billingComplete: PropTypes.bool.isRequired,
  forestId: PropTypes.number.isRequired,
  forestName: PropTypes.string.isRequired,
  isPaying: PropTypes.bool.isRequired,
  isCryptoPaying: PropTypes.bool.isRequired,
  makePayment: PropTypes.func.isRequired,
  makeCryptoPayment: PropTypes.func.isRequired,
  paymentErrors: PropTypes.string,
  cryptoPaymentErrors: PropTypes.string,
  price: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  selectPaymentType: PropTypes.func.isRequired,
  intl: intlShape,
  paymentType: PropTypes.string.isRequired,
  sources: PropTypes.array.isRequired
};

export default injectIntl(Payment);
