import actionTypes from '../constants/actionTypes';
import { axiosV1Instance } from './';
import api from '../constants/api';
import { getIsFetching } from '../selectors/balance';

export const getBalance = () => ({
  shouldCallAPI: state => !getIsFetching(state),
  callAPI: () => axiosV1Instance.get(api.GET_BALANCE),
  actions: [
    actionTypes.BALANCE_START,
    {
      type: actionTypes.BALANCE_COMPLETE,
      payload: response => {
        // INTERCOM: Update balance attribute =========
        const { captured } = response.data.data;
        if (captured > 0) {
          window.Intercom('update', {
            "captured_co2": response.data.data.captured,
          });
        }
        // =========

        return response.data.data;
      }
    },
    {
      type: actionTypes.BALANCE_ERROR,
      payload: response => response
    }
  ],
});
