import { combineReducers } from 'redux';
import actionTypes from '../constants/actionTypes';
import {
  extractNormalizedEntity,
  createBooleanReducer,
  createErrorMessagesReducer,
  createReducer
} from '../utils/reducersHelpers';
import {
  convertStepIndexToMiles
} from '../utils/units'

const calculatedCarbon = (state = { co2: null, advices: []}, action) => {
  switch (action.type) {
    case actionTypes.CALC_STEP_START:
    case actionTypes.CALC_ERROR:
    case actionTypes.CALC_STEP_CLEAR: {
      return {
        co2: null,
        advices: [],
      };
    }
    case actionTypes.CALC_COMPLETE: {
      return {
        co2: action.payload.co2,
        advices: action.payload.advices || [],
      }
    }
    default:
      return state;
  }
};

const manuallyChangedFormValues = (state = {formValuesProvidedByEdit: undefined}, action) => {
  switch (action.type) {
    case actionTypes.CALCULATOR_MANUALLY_SET_FORM_VALUES:
      return {
        formValuesProvidedByEdit: action.payload.values
      }
    case actionTypes.CALCULATOR_RESET_MANUALLY_SET_FORM_VALUES:
      return {formValuesProvidedByEdit: undefined}
    default:
      return state;
  }
};

const possibleNextStepValues = (state = {possibleNextSteps: undefined, possibleNextStepType: undefined}, action) => {
  switch (action.type) {
    case actionTypes.CALC_ADD_NEXT_POSSIBLE_STEP_VALUES:
      return {
        possibleNextSteps: action.payload.possibleNextStepValues,
        possibleNextStepType: action.payload.possibleNextStepType
      }
    default:
      return state;
  }
};

const steps = (state = {}, action) => {
  if (action.type === actionTypes.CALC_STEP_COMPLETE && action.response) {
    return {
      ...state,
      ...action.response.entities['steps'],
    }
  }
  return state;
}

const removeDoubling = stepsList => {
  let result = []
  let lastElement = null
  stepsList.forEach(e => {
    if (lastElement === null) {
      result.push(e)
      lastElement = e
      return}
    else if(lastElement[0] && e[0] && lastElement[0] === e[0]) {
      return
    }
    result.push(e)
    lastElement = e
    return
  })
  return result
}

const stepsList = (state = [], action) => {
  switch (action.type) {
    case actionTypes.CALC_START: {
      // we use the calculated path to ensure coherence in stepsList
      return action.stepsList;
    }
    case actionTypes.CALC_STEP_START: {
      let actionIndex = state.findIndex(nested => nested[0] === action.ownStep);

      if (state[actionIndex + 1]) {
        state[actionIndex + 1].splice(1, 1, action.ownStepValue);
      }
      return state.slice(0, (actionIndex + 1));
    }
    case actionTypes.CALC_STEP_COMPLETE:
      return removeDoubling([...state, [action.nextStep, !isNaN(action.ownStepValue) ? Number(action.ownStepValue) : action.ownStepValue]])
    case actionTypes.CALC_STEP_CLEAR:
      return [];
    case actionTypes.CALCULATOR_CONVERT_STEP_INDEX_TO_MILES:
      return convertStepIndexToMiles(state, action.payload.stepsIndex)
    default:
      return state;
  }
};

const distanceConverstionsForEditDone = (state = false, action) => {
  switch (action.type) {
    case actionTypes.CALCULATOR_MANUALLY_SET_FORM_VALUES:
      return false
    case actionTypes.CALCULATOR_CONVERT_STEP_INDEX_TO_MILES:
      return true
    default:
      return state;
  }
};

const stepArray = [
  actionTypes.CALC_STEP_START,
  actionTypes.CALC_STEP_COMPLETE,
  actionTypes.CALC_STEP_ERROR
];

const calcArray = [
  actionTypes.CALC_START,
  actionTypes.CALC_COMPLETE,
  actionTypes.CALC_ERROR
];

const carbonArray = [
  actionTypes.CARBON_ADD_START,
  actionTypes.CARBON_ADD_COMPLETE,
  actionTypes.CARBON_ADD_ERROR
];

export default combineReducers({
  isFetching: createBooleanReducer(stepArray),
  isCalculating: createBooleanReducer(calcArray),
  isAdding: createBooleanReducer(carbonArray),
  calculatedCarbon,
  stepsList,
  manuallyChangedFormValues,
  possibleNextStepValues,
  distanceConverstionsForEditDone,
  steps,
  options: extractNormalizedEntity(actionTypes.CALC_STEP_COMPLETE, 'options'),
  stepErrorMessages: createErrorMessagesReducer(stepArray),
  calcErrorMessages: createErrorMessagesReducer(calcArray),
  calculatedPath: createReducer([], {
    [actionTypes.CALC_COMPLETE]: (_, action) => action.calculatedPath
  }),
});

// Direct access to reducers
export const getIsFetching = (state) => state.isFetching;
export const getIsCalculating = (state) => state.isCalculating;
export const getPossibleNextStepValues = (state) => state.possibleNextStepValues.possibleNextSteps;
export const getPossibleNextStepType = (state) => state.possibleNextStepValues.possibleNextStepType;
export const getIsAdding = (state) => state.isAdding;
export const getStepsList = (state) => state.stepsList;
export const getManuallyChangedFormValues = (state) => state.manuallyChangedFormValues;
export const getSteps = (state) => state.steps;
export const getOptions = (state) => state.options;
export const getCalculatedCarbon = (state) => state.calculatedCarbon.co2;
export const getAdvices = (state) => state.calculatedCarbon.advices;
export const getStepErrorMessages = (state) => state.stepErrorMessages;
export const getCalcErrorMessages = (state) => state.calcErrorMessages;
export const getcalculatedPath = (state) => state.calculatedPath;
export const getDistanceConverstionsForEditDone = (state) => state.distanceConverstionsForEditDone;

// Logic access
export const getStepById = (state, id) => state.steps[id];

export const getOptionById = (state, id) => state.options[id];

export const getFormSteps = (state) =>
  state.stepsList.map(step => state.steps[step[0]]);

export const getStepOptions = (state, step) =>
  step.children.map(option => state.options[option]);
