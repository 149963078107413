import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { links } from '@reforestum/shared-utils'

// constants
import { localizedLinkMessages } from '../../constants/messages';
import { NAV_PROFILES } from '../../constants/navProfiles';
import getFeatureFlags from "../../constants/featureFlags";

// components
import HeaderLinkWithIcon from "./HeaderLinkWithIcon";
import SquaresFour from "../UI/Icons/SquaresFour";
import PreferencesSwitcherSelects from "./PreferencesSwitcherSelects"

import './Nav.css';
import PartySwitcher from "./PartySwitcher"
import {urlSpecificAfterSwitchActions} from "../../utils/partySwitcher"
import { NeutralizeFootprintHeaderSectionDesktop, NeutralizeFootprintHeaderSectionMobile, PortfolioHeaderSectionDesktop, PortfolioHeaderSectionMobile, VCMHeaderSectionDesktop, VCMHeaderSectionMobile } from './ExpandableLinks/ExpandableLinks';
import { getIsMobileResolution } from '../../selectors/UI';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getUserType } from '../../utils/usersAndOrganizations';
import USER_TYPE from '../../constants/userType';


const ff = getFeatureFlags()

const Nav = ({ className, isAuthenticated, intl, navProfile, logout, hasPurchased, user, isMobileResolution }) => {

  const navBaseFeatures = {
    dashboard: true,
    portfolio: true,
    forests: true,
    community: true,
    footprint: true,
    createForest: true,
    accountSettings: true,
    tos: true,
    how: true,
    blog: true,
    contactUs: true,
    unitSelector: true
  };

  const navMainCTAsDisables = {
    dashboard: false,
    forests: false,
    community: false,
    footprint: false,
    createForest: false,
  };

  const navFeatures = {};
  const {
    USER_ACTIVATION,
    ENTERPRISE_ACTIVATION_PENDING_AND_AUTH,
    ENTERPRISE_ACTIVATION_PENDING_AND_NO_AUTH,
    ENTERPRISE_ACTIVATION_ACTIVATED_AND_NO_AUTH,
    SUPPLIER_FORMS
  } = NAV_PROFILES;

  navFeatures[USER_ACTIVATION] = {
    ...navBaseFeatures,
    ...navMainCTAsDisables
  }

  navFeatures[ENTERPRISE_ACTIVATION_PENDING_AND_AUTH] = {
    ...navBaseFeatures,
  }

  navFeatures[ENTERPRISE_ACTIVATION_PENDING_AND_NO_AUTH] = {
    ...navBaseFeatures,
    ...navMainCTAsDisables
  }

  navFeatures[ENTERPRISE_ACTIVATION_ACTIVATED_AND_NO_AUTH] = {
    ...navBaseFeatures,
    ...navMainCTAsDisables
  }

  navFeatures[SUPPLIER_FORMS] = {}

  const shouldRenderSeparator = (list) => (features) => list.some(item => features[item]);
  const shouldRenderCTASeparator = shouldRenderSeparator(['dashboard', 'forests', 'community', 'footprint', 'createForest']);
  const shouldRenderSupportOptionsSeparator = shouldRenderSeparator(['blog', 'tos', 'contactUs']);

  const currentFeatures = navFeatures[navProfile] || navBaseFeatures;

  const { getHomeUrl } = links;

  return (
    <nav className={cx(className, 'topbar-nav')}>
      {shouldRenderCTASeparator(currentFeatures) && isAuthenticated && <div className={'topbar__small-screen-separator'} />}
      {currentFeatures.dashboard &&
        <HeaderLinkWithIcon icon={<SquaresFour/>} exact to={getHomeUrl()} className="topbar-nav__item">
          <FormattedMessage
            id={'Header.dashboard'}
            defaultMessage={'Dashboard'}
          />
        </HeaderLinkWithIcon>
      }
      {currentFeatures.portfolio && getUserType() === USER_TYPE.NEW_MODEL_ORGANIZATION &&
        (isMobileResolution ? <PortfolioHeaderSectionMobile/> : <PortfolioHeaderSectionDesktop/>)
      }
      {currentFeatures.forests &&
        (isMobileResolution ? <VCMHeaderSectionMobile/> : <VCMHeaderSectionDesktop/>)
      }
      {currentFeatures.footprint && !(getUserType() === USER_TYPE.NEW_MODEL_ORGANIZATION) &&
        (isMobileResolution ? <NeutralizeFootprintHeaderSectionMobile/> : <NeutralizeFootprintHeaderSectionDesktop/>)
      }
      {currentFeatures.accountSettings && isAuthenticated && <div className={'topbar__small-screen-separator'} />}
      <div className="topbar-nav__external">
        {currentFeatures.accountSettings && isAuthenticated &&
          <a
            className="topbar-nav__item"
            href="/account/"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              id={'Header.accountSettings'}
              defaultMessage={'Account settings'}
            />
          </a>
        }
        {shouldRenderSupportOptionsSeparator(currentFeatures) && <div className={'topbar__small-screen-separator'} />}
        {currentFeatures.blog &&
          <a
            className="topbar-nav__item"
            href={intl.formatMessage(localizedLinkMessages.blog)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              id={'tosMessages.blog'}
              defaultMessage={'Blog'}
            />
          </a>
        }
        {currentFeatures.tos &&
          <a
            className="topbar-nav__item"
            href={intl.formatMessage(localizedLinkMessages.tos)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              id={'tosMessages.tosShort'}
              defaultMessage={'Terms of service'}
            />
          </a>
        }
        {currentFeatures.contactUs &&
          <a
            className="topbar-nav__item"
            href={intl.formatMessage(localizedLinkMessages.contact)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              id={'externalLinks.contacts'}
              defaultMessage={'Contact Us'}
            />
          </a>
        }
        <div className={'topbar__small-screen-separator'} />
        {isAuthenticated &&
          <span className="topbar-nav__item topbar-nav__item--destructive" onClick={logout}>
            <FormattedMessage
              id={'Header.logout'}
              defaultMessage={'Logout'}
            />
          </span>
        }

        <PreferencesSwitcherSelects />

        {
          ff.partySwitcher && isAuthenticated && <div className={'party-switcher__in-nav-wrapper'}>
            <div className="user-dropdown__divider" />
            <PartySwitcher
              user={user}
              actionAfterSwitch={urlSpecificAfterSwitchActions}
            />
          </div>
        }
      </div>
    </nav>
  )
};

Nav.propTypes = {
  className: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  intl: intlShape,
  navProfile: PropTypes.string,
  hasPurchased: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isMobileResolution: getIsMobileResolution(state),

});

export default injectIntl(withRouter(connect(mapStateToProps, {})(Nav)));
