/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'react-layout-components';
import {FormattedMessage, injectIntl} from 'react-intl'
import mixpanel from 'mixpanel-browser';

import ForestMap from '../components/Forests/Map/ForestMap';
import SimpleMap from '../components/Forests/Map/SimpleMap';

import ForestContainer from './ForestContainer';
import Loader from '../components/UI/Loader/Loader';
import FullErrorFetching from '../components/UI/Interface/FullErrorFetching';

import Container from '../components/Layout/Container/Container';
import Content from '../components/Layout/Container/Content';
import DynamicWidth from '../components/Layout/DynamicWidth/DynamicWidth';
import Sidebar from '../components/Layout/Sidebar/Sidebar';
import HeadingSmall from '../components/UI/Text/HeadingSmall';
import SidebarToggle from '../components/UI/SidebarToggle/SidebarToggle';

import getIsAutheticatedFromStore from '../utils/getIsAutheticatedFromStore';
import { MONITORING_LEVEL } from '../constants/monitoringLevel';

import { links } from '@reforestum/shared-utils';
import StandardFormModal from "../components/UI/Modal/StandardFormModal"
import {newsletterPopup} from "../constants/messages"
import {connect} from "react-redux"
import {getUserDetails} from "../selectors/userSession"

const getSelectedForestGeodata = (selectedForest) => {
  const hasSectors = selectedForest.sectors && selectedForest.sectors.length > 0;
  let sectorGeoJsonArray;
  if (hasSectors)  {
    sectorGeoJsonArray = 
      selectedForest.sectors
        .filter((sector) => sector.geo_json)
        .map((sector) => ({...sector.geo_json, sectorId: sector.sector_id}));
  }

  return {
    forest_sectors: hasSectors ? [selectedForest.geo_json, ...sectorGeoJsonArray] : selectedForest.geo_json,
    fitbounds_map: selectedForest.fitbounds,
    center_map: selectedForest.coordinates,
    markers_map: selectedForest.sectors 
      ? selectedForest.sectors
        .filter(sector => sector.label_coords)
        .map(sector => ({
        coords: JSON.parse(sector.label_coords),
        name: sector.name,
        linkTo: sector.navigable ? links.getProjectUrl(selectedForest.id, sector.sector_id) : null,
        logo: sector.logo_url,
        sectorId: sector.sector_id,
        surface: Number(sector.sector_surface),
        reforestersAmount: sector.reforesters_amount,
        type: sector.type,
      }))
      : []
  }
}

const renderMap = (selectedForest, myForests, isFetchingTrees, selectedForestId, trees) => {
  if(selectedForest) {
    const {center_map, markers_map, forest_sectors, fitbounds_map} = selectedForest && getSelectedForestGeodata(selectedForest);
    const forestHasNoSectorsNoGeojson = selectedForest && (!forest_sectors || (forest_sectors === [])) && !selectedForest.geo_json
    return (
      <SimpleMap
        userSharesGeojson={selectedForest.user_sectors}
        center={center_map}
        markers={markers_map}
        forestHasNoSectorsNoGeojson={forestHasNoSectorsNoGeojson}
        geojson={forest_sectors}
        fitbounds={fitbounds_map ? fitbounds_map : []}
        mapType='forest'
        selectedForestId={Number(selectedForestId)}
        selectedForest={selectedForest}
        isMonitoredProject={selectedForestId && selectedForest.monitoring_level !== MONITORING_LEVEL.NOT_MONITORED}
        forestName={selectedForestId && selectedForest.name}
      />
    )
  }
  return (
    <ForestMap
      forests={myForests}
      isFetchingTrees={isFetchingTrees}
      selectedForest={selectedForest}
      selectedForestId={Number(selectedForestId)}
      trees={trees}
    />
  )
}
class MyForestsContainer extends Component {
  constructor(props){
    super(props);

    this.state = {
      windowWidth: window.innerWidth,
      showNewsletterModal: false
    };

    this.updateWindowWidth = this.updateWindowWidth.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowWidth);
    const { match: { params: { id: selectedForestId }} } = this.props;

    mixpanel.track("Page view", {
      "Authenticated": getIsAutheticatedFromStore(),
      "Action": "Forests view",
      "Forest": selectedForestId,
      "Domain": "App"
    });
  }

  componentDidUpdate() {
    if(this.props.user.guest || (this.props.user && this.props.user.guest === undefined)) {
      this.newsletterTimeout = setTimeout(() => this.handleNewsletterPopup(), 30000)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.newsletterTimeout)
  }

  handleNewsletterPopup() {
    const hasSeenNewsletterPopup = localStorage.getItem('ref__hasSeenNewsletterPopup')
    if(!hasSeenNewsletterPopup) {
      this.setState({showNewsletterModal: true})
      localStorage.setItem('ref__hasSeenNewsletterPopup', true)
    }
  }

  updateWindowWidth() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  render() {
    const {
      detailErrorMessages,
      errorMessages,
      fetchForestDetails,
      fetchMyForests,
      isFetching,
      isFetchingTrees,
      match: { params: { id: selectedForestId }},
      myForests,
      selectedForest,
      trees,
      withSecondaryNavigation
    } = this.props;

    if (errorMessages) {
      return (
        <FullErrorFetching
          errorMessage={errorMessages}
          retry={() => { fetchMyForests(); }}
        />
      );
    }

    if (detailErrorMessages) {
      return (
        <FullErrorFetching
          errorMessage={detailErrorMessages}
          retry={fetchForestDetails.bind(this, selectedForestId)}
        />
      );
    }

    if (isFetching) return <Loader />;

    if (!selectedForestId && myForests.length <= 0) return <Loader />;

    if(selectedForestId && !selectedForest) return <Loader/>;

    const {intl} = this.props

    return (
      <Container>
        {
          this.state.showNewsletterModal && <StandardFormModal
            action={'request-newsletter-subscription'}
            displayKnownEmail
            hideFirstName
            hideLastName
            hideTextArea
            intl={intl}
            heading={<span>{intl.formatMessage(newsletterPopup.newsletterPopupHeading)}</span>}
            description={<span>{intl.formatMessage(newsletterPopup.newsletterPopupDescription)}</span>}
            cta={<span>{intl.formatMessage(newsletterPopup.newsletterPopupCTA)}</span>}
            handleClose={() => this.setState({showNewsletterModal: false})}
            submitCallback={() => this.setState({showNewsletterModal: false})}
            successAlertSettings={{
              type: 'success',
              message: intl.formatMessage(newsletterPopup.newsletterPopupSubscriptionSuccess),
              dismissAfter: 8000
            }}
          />
        }
        {this.state.windowWidth > 768 ?
          <Content coverage="full" withSecondaryNavigation={withSecondaryNavigation}>
            <DynamicWidth
              id="forest-map-container"
              style={{
                'width': selectedForestId ? '50%' : '100%',
              }}>
              {renderMap(selectedForest, myForests, isFetchingTrees, selectedForestId, trees)}
            </DynamicWidth>
            <DynamicWidth
              style={{
                'width': selectedForestId ? '50%' : '0%',
              }}>
                {
                selectedForestId &&
                  <ForestContainer
                  selectedForestId={Number(selectedForestId)}
                  />
                }
            </DynamicWidth>
          </Content>
          :
          <Content withSecondaryNavigation={withSecondaryNavigation} coverage="full">
            <DynamicWidth
              id="forest-map-container"
              style={{
                'width': '100%',
              }}>
              {renderMap(selectedForest, myForests, isFetchingTrees, selectedForestId, trees)}
            </DynamicWidth>
            {selectedForestId && <Sidebar
              className="forest-sidebar__main"
              locked={selectedForestId ? false : true}
              >
              <Box
                center
                justifyContent="space-between"
                className="forest-sidebar__title">

                <HeadingSmall>
                  <FormattedMessage
                    id={'Forests.forestdetails'}
                    defaultMessage={'Forest details'}
                  />
                </HeadingSmall>

                <SidebarToggle/>
              </Box>
                {selectedForestId &&
                  <ForestContainer
                    selectedForestId={Number(selectedForestId)}
                  />
                }
            </Sidebar>}
          </Content>
        }
      </Container>
    );
  }
}


MyForestsContainer.propTypes = {
  detailErrorMessages: PropTypes.string,
  errorMessages: PropTypes.string,
  fetchForestDetails: PropTypes.func.isRequired,
  fetchMyForests: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isFetchingTrees: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  myForests: PropTypes.array.isRequired,
  selectedForest: PropTypes.object,
  trees: PropTypes.array.isRequired,
  latestActivity: PropTypes.array
};

const mapStateToProps = state => ({
  user: getUserDetails(state)
})

export default  connect(
  mapStateToProps,
)(
  injectIntl(MyForestsContainer)
)
