import React from 'react'
import './Modal.css'
import Close from '../Icons/Close'

const Modal = (
	{
		content,
		clickClose,
		large,
	} = {}
) => {
	const renderMain = () => <div
		className={`modal-void`}
		onClick={
			event => {
				event.preventDefault()
				clickClose()
			}
		}
	>
		<div className={`modal ${large ? 'modal--large' : ''}`} onClick={event => event.stopPropagation()}>
			<div className={'modal__close'}>
				<Close
					onClick={clickClose}
					className={`modal__close-icon`}
					stroke={'var(--ref-green-600)'}
				/>
			</div>
			<div className={'modal__content'}>
				{content}
			</div>
		</div>
	</div>

	return renderMain()
}

export default Modal
