import { normalize } from 'normalizr';
import mixpanel from 'mixpanel-browser';

import api from '../constants/api';
import actionTypes from '../constants/actionTypes';
import { TWO_ADDRESSES } from '../constants/stepDataTypes';
import { steps } from '../schemas/calculator';
import {
  getIsFetching,
  getIsCalculating,
} from '../selectors/calculator';

import { axiosV1Instance } from './';
import getIsAutheticatedFromStore from '../utils/getIsAutheticatedFromStore';

export const manuallyChangeFormValues = values => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.CALCULATOR_MANUALLY_SET_FORM_VALUES,
      payload: {values}
    });
  };
};

export const convertStepIndexToMiles = stepsIndex => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.CALCULATOR_CONVERT_STEP_INDEX_TO_MILES,
      payload: {stepsIndex}
    });
  };
};

export const requestStep = (ownStep, nextStep, value) => {
  return async (dispatch, getState) => {
    // If already fetching return early
    if (getIsFetching(getState())) return;

    // Start the proccess
    dispatch({
      type: actionTypes.CALC_STEP_START,
      ownStep: ownStep,
      ownStepValue: value,
      nextStep: nextStep,
    });
    // Call API
    let response = await axiosV1Instance.post(api.GET_STEPS, {
      step_id: nextStep,
      value: value
    })
    let error = null
    if(response.data && response.data.error) {
      error = response.data.error
    }
    if(!error) {
      // handle errors
      if (response.data.status >= 400) {
        return dispatch({
          type: actionTypes.CALC_STEP_ERROR,
          payload: response.data.error
        });
      }
      let responseData = response.data.data;
      // handle incomplete response
      if (!responseData.children
        && !responseData.typeahead_query_url
        && responseData.data_type !== TWO_ADDRESSES) {

        mixpanel.track("CO2", {
          "Authenticated": getIsAutheticatedFromStore(),
          "Action": "Get next step w/o logic",
          "Next Step": nextStep,
          "Domain": "App"
        });

        return dispatch({
          type: actionTypes.CALC_STEP_ERROR,
          // this string is the ID of a message located at /src/constants/messages.js
          // @TODO: Reefactor for INTL
          payload: { id: 'calculatorMessages.noLogic' },
        });
      }

      if (nextStep) {
        mixpanel.track("CO2", {
          "Authenticated": getIsAutheticatedFromStore(),
          "Action": "Get next step",
          "Next Step": nextStep,
          "Domain": "App"
        });
      }

      dispatch({
        type: actionTypes.CALC_ADD_NEXT_POSSIBLE_STEP_VALUES,
        payload: {
          possibleNextStepValues: response.data.data.children,
          possibleNextStepType: response.data.data.data_type
        },
      });

      // handle correct response
      return dispatch({
        type: actionTypes.CALC_STEP_COMPLETE,
        response: normalize(responseData, steps),
        ownStepValue: value,
        nextStep: responseData.id,
        possibleNextStepValues: response.data.data.children,
        possibleNextStepType: response.data.data.data_type
      });
    }
    else {
      dispatch({
        type: actionTypes.CALC_STEP_ERROR,
        payload: error.message,
      });
    }
  };
};

export const calculateCarbon = (calculatedPath, stepsList) => ({
  shouldCallAPI: state => !getIsCalculating(state),
  callAPI: async () => {
    const response = await axiosV1Instance.post(api.CALC_CO2, { "values": calculatedPath });

    if (!response.data.data.co2) {
      const data = { ...response.data, status: 450 };

      return {
        ...response,
        data,
      };
    }

    return response;
  },
  actions: [
    actionTypes.CALC_START,
    {
      type: actionTypes.CALC_COMPLETE,
      payload: response => {
        mixpanel.track("CO2", {
          "Authenticated": getIsAutheticatedFromStore(),
          "Action": "Final calculation",
          "Amount": response.data.data.co2,
          "Steps": stepsList,
          "Domain": "App"
        });

        return response.data.data;
      }
    },
    (response, dispatch) => {
      dispatch({
        type: actionTypes.CALC_ERROR,
        // this string is the ID of a message located at /src/constants/messages.js
        // @TODO: Reefactor for INTL
        payload: 'CalculatorTotal.notAvailable',
      });

      dispatch({
        type: actionTypes.CALC_STEP_ERROR,
        // this string is the ID of a message located at /src/constants/messages.js
        // @TODO: Reefactor for INTL
        payload: 'Calculator.failedCarbonCalculation',
      });
    },
  ],
  payload: {
    calculatedPath,
    stepsList
  }
});

export const clearForm = () => ({
  type: actionTypes.CALC_STEP_CLEAR
});

export const resetManuallySavedFormValues = () => ({
  type: actionTypes.CALCULATOR_RESET_MANUALLY_SET_FORM_VALUES
});
