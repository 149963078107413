import React from "react"
import air from '@reforestum/shared-components/dist/assets/carbon/air.png';
import bus from '@reforestum/shared-components/dist/assets/carbon/bus.png';
import car from '@reforestum/shared-components/dist/assets/carbon/car.png';
import clothing from '@reforestum/shared-components/dist/assets/carbon/clothing.png';
import co2 from '@reforestum/shared-components/dist/assets/carbon/co2.png';
import elec from '@reforestum/shared-components/dist/assets/carbon/electric.png';
import food from '@reforestum/shared-components/dist/assets/carbon/food.png';
import heating from '@reforestum/shared-components/dist/assets/carbon/heating.png';
import holiday from '@reforestum/shared-components/dist/assets/carbon/holiday.png';
import housing from '@reforestum/shared-components/dist/assets/carbon/housing.png';
import misc from '@reforestum/shared-components/dist/assets/carbon/misc.png';
import office from '@reforestum/shared-components/dist/assets/carbon/office.png';
import phone from '@reforestum/shared-components/dist/assets/carbon/phone.png';
import taxi from '@reforestum/shared-components/dist/assets/carbon/taxi.png';
import Flight from "../components/UI/Icons/Flight";
import CarTravel from "../components/UI/Icons/CarTravel";
import Carbon from "../components/UI/Icons/Carbon";
import Electricity from "../components/UI/Icons/Electricity";
import Food from "../components/UI/Icons/Food";
import FamilyHousehold from "../components/UI/Icons/FamilyHousehold";
import PublicTransport from "../components/UI/Icons/PublicTransport";

const carbonIcons = {
  AIRT: air,
  CART: car,
  CLTH: clothing,
  COMM: phone,
  ELEC: elec,
  FOOD: food,
  HEAT: heating,
  HOLY: holiday,
  HOUS: housing,
  MANU: co2,
  MISC: misc,
  OFFI: office,
  OTRN: taxi,
  PTRN: bus,
};

const v3CarbonIcons = {
  AIRT: <Flight />,
  CART: <CarTravel />,
  CLTH: <Carbon />,
  COMM: <Electricity />,
  ELEC: <Electricity />,
  FOOD: <Food />,
  HEAT: <Electricity />,
  HOLY: <Flight />,
  HOUS: <FamilyHousehold />,
  MANU: <Carbon />,
  MISC: <Carbon />,
  OFFI: <FamilyHousehold />,
  OTRN: <CarTravel />,
  PTRN: <PublicTransport />,
};

export {carbonIcons, v3CarbonIcons};
