import React, { Component } from 'react';
import { connect } from 'react-redux';
import IframeResizable from '../components/UI/IframeResizable/IframeResizable';
import IframeResizableFullPageContainer from '../components/UI/IframeResizable/IframeResizableFullPageContainer';
import { ORGANIZATIONS_LIST_DASHBOARD } from '../constants/metabaseDashboards';
import { withRouter } from 'react-router-dom';

class OrganizationsListContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      iframeSrcWithQueryParams: '',
    };

    this.updateIframeSrc = this.updateIframeSrc.bind(this)
  }


  componentDidMount() {
    this.updateIframeSrc();

  }

  componentDidUpdate() {
      this.updateIframeSrc();
  }

  getIframeSrc() {
    const searchParams = new URLSearchParams(window.location.search);
    const iframeParsedQueryParams = {}
    if(searchParams.get('name')) {
      //This correspond to the name of the filter in the metabase dashboard
      iframeParsedQueryParams.organization_name = searchParams.get('name');
    }

    //iframeSrcWithQueryParams using URL utils in case there are already query params in the url
    const iframeSrcWithQueryParams = new URL(ORGANIZATIONS_LIST_DASHBOARD);
    iframeSrcWithQueryParams.search = new URLSearchParams(iframeParsedQueryParams).toString();

    return iframeSrcWithQueryParams.toString()
  }

  updateIframeSrc () {
    const currentIframe = this.getIframeSrc()
    if(this.state.iframeSrcWithQueryParams !== currentIframe) {
      this.setState({ iframeSrcWithQueryParams: currentIframe });
    }
  }

  render() {
    const {iframeSrcWithQueryParams} = this.state;
    return (
      <IframeResizableFullPageContainer>
        <IframeResizable
          id="organizationsList"
          title='Organizations List'
          src={iframeSrcWithQueryParams}
        />
      </IframeResizableFullPageContainer>
    )
  }
}

const mapStateToProps = (state) => ({
});

OrganizationsListContainer.propTypes = {
};

export default withRouter(connect(mapStateToProps, {
})(OrganizationsListContainer));
