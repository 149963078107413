import mixpanel from 'mixpanel-browser';
import { axiosV1Instance, axiosV2Instance } from './';
import store from '../setup/store';

import { addAlert } from '../actions/alerts';
import { getIsLoading, getIsUpdating } from '../selectors/userActions';

import actionTypes from '../constants/actionTypes';
import api from '../constants/api';
import { AUTH_METHOD } from '../constants/authMethods'
import { PRIVACYLEVEL } from '../constants/privacyLevels';

import getIsAutheticatedFromStore from '../utils/getIsAutheticatedFromStore';
import {clearForm, requestStep} from "./calculator";
import { AlertMessages } from '../constants/messages';
import { posthogIdentify } from '../utils/posthog';

export const requestRecovery = email => ({
  shouldCallAPI: state => !getIsUpdating(state),
  callAPI: () => axiosV1Instance.post(api.RECOVER, { email }),
  actions: [
    actionTypes.RECOVERY_START,
    {
      type: actionTypes.RECOVERY_COMPLETE,
      payload: response => response
    },
    {
      type: actionTypes.RECOVERY_ERROR,
      payload: response => response.error
    }
  ],
});

export const resetPassword = (token, password) => ({
  shouldCallAPI: state => !getIsUpdating(state),
  callAPI: () => axiosV1Instance.put(api.RESET_PASSWORD, { token, password }),
  actions: [
    actionTypes.RESET_START,
    {
      type: actionTypes.RESET_COMPLETE,
      payload: response => response
    },
    {
      type: actionTypes.RESET_ERROR,
      payload: response => response.error
    }
  ],
});

export const signup = (form, callback, extraCallback) => ({
  shouldCallAPI: state => !getIsLoading(state),
  callAPI: () => {
    const { 
      privacy,
      orgId,
      activationToken,
      ...credentials } = form;
    return axiosV1Instance.put(
      api.SIGNUP, {
        ...credentials,
        "privacy": privacy ? privacy : PRIVACYLEVEL.PRIVATE,
        "referralOrgId": orgId ? orgId : undefined,
        "nickname": credentials.nickname ? credentials.nickname :  undefined,
        "token": activationToken ? activationToken : undefined
      },
    );
  },
  actions: [
    actionTypes.SIGNUP_START,
    {
      type: actionTypes.SIGNUP_COMPLETE,
      payload: (response) => {

        posthogIdentify(response.data.user, AUTH_METHOD.FORM)
        mixpanel.identify(response.data.user.id);

        mixpanel.people.set({
          "$email": response.data.user.email,
          "Auth Method": AUTH_METHOD.FORM
        });

        mixpanel.track("Session", {
          "Authenticated": getIsAutheticatedFromStore(),
          "Action": "User signup",
          "Domain": "App",
          "Auth Method": AUTH_METHOD.FORM
        });

        if (typeof callback === 'function') callback();
        if (typeof extraCallback === 'function') extraCallback();
        
        return response.data;
      }
    },
    {
      type: actionTypes.SIGNUP_ERROR,
      payload: (response, dispatch) => {
        const { name, email } = response.data.error.user;

        mixpanel.track("Session", {
          "Authenticated": getIsAutheticatedFromStore(),
          "Action": "Register FAILED",
          "email": name || email,
          "Domain": "App"
        });

        dispatch(addAlert({
          type: 'error',
          message: response.data.error.message || response.data.error.errors.email[0],
          dismissAfter: 3000
        }));

        return response.data || response
      }
    },
  ],
});

export const setNewPassword = (oldPassword, newPassword, callback) => ({
  shouldCallAPI: state => !getIsUpdating(state),
  callAPI: () => axiosV1Instance.put(api.UPDATE_PASSWORD,
    {
      "password_current": oldPassword,
      "password_new": newPassword
    },
  ),
  actions: [
    actionTypes.NEW_PASS_START,
    {
      type: actionTypes.NEW_PASS_COMPLETE,
      payload: (response, dispatch) => {
        dispatch(addAlert({
          type: 'info',
          message: response.data.message,
          dismissAfter: 5000
        }));
        if (typeof callback === 'function') {
            callback();
        }
      }
    },
    {
      type: actionTypes.NEW_PASS_ERROR,
      payload: response => response.error
    },
  ]
});

export const updateUser = ({form, intl}, callback, extraCallback) => ({
  shouldCallAPI: state => !getIsUpdating(state),
  callAPI: () => {
    const formToSend = {...form, privacy: form.privacy ? Number(form.privacy) : undefined};
    return axiosV2Instance.put(api.UPDATE_USER, formToSend);
  },
  actions: [
    actionTypes.UPDATE_START,
    {
      type: actionTypes.UPDATE_COMPLETE,
      payload: (response, dispatch) => {

        dispatch(addAlert({
          type: 'success',
          message: intl ? intl.formatMessage(AlertMessages.userSuccessfullyUpdated) : 'User successfully updated',
          dismissAfter: 3000
        }));

        dispatch(clearForm());
        dispatch(requestStep());

        if (typeof callback === 'function') {
            callback();
        }
        if (typeof extraCallback === 'function') {
            extraCallback();
        }
      }
    },
    {
      type: actionTypes.UPDATE_ERROR,
      payload: response => response.error || response
    }
  ]
});

export const updateAvatar = ({formData, intl}) => ({
  shouldCallAPI: state => !getIsUpdating(state),
  callAPI: () => {
    const meta = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      onUploadProgress: progressEvent => {
        store.dispatch({
          type: actionTypes.UPLOAD_PROGRESS,
          payload: Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        });
      },
    };
    return axiosV1Instance.post(api.UPLOAD_AVATAR, formData, meta);
  },
  actions: [
    actionTypes.UPLOAD_START,
    {
      type: actionTypes.UPLOAD_COMPLETE,
      payload: (response, dispatch) => {
        dispatch(addAlert({
          type: 'success',
          message: intl ? intl.formatMessage(AlertMessages.avatarSuccessfullyUpdated) : 'Profile picture successfully updated',
          dismissAfter: 2000
        }));
        return response.data.data;
      }
    },
    {
      type: actionTypes.UPLOAD_ERROR,
      payload: (response = {}, dispatch) => {
        dispatch(addAlert({
          type: 'error',
          message: (response.error || {}).message || 'Internal server error.',
          dismissAfter: 2000
        }));
        return response.error;
      }
    }
  ]
});
