import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader/Loader';

class IframeResizable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    }

    this.onLoad = this.onLoad.bind(this);
  }

  onLoad() {
    const { id } = this.props;
    window.iFrameResize({}, `#${id}`);
    this.setState({ isLoading: false })
  }

  render() {
    const { id, title, ...props } = this.props;
    const { isLoading } = this.state;
    return (
      <div style={{width: "100%"}}>
        {isLoading && <div style={{height: "50vh", wiidth: "100%"}}><Loader backgroundTransparent/></div>}
        <iframe
          id={id}
          title={title}
          {...props}
          style={{border: "none"}}
          onLoad={this.onLoad}
        />
      </div>
    )
  }
};

IframeResizable.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default IframeResizable;
