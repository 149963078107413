// Dependencies
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getUserOrImpersonatingOrg } from '../utils/usersAndOrganizations';

// Actions
import { fetchOrganizationDashboard } from '../actions/organizationDashboard';

// Selectors
import {
  getErrorMessages,
  getIsFetching,
  getProfileBalance,
  getProfileDetails,
  getProfileForests
} from '../selectors/publicProfile';
import { getUserDetails
} from '../selectors/userSession';

// Components
import Loader from '../components/UI/Loader/Loader';
import OrganizationDashboard from '../components/Dashboard/OrganizationDashboard/OrganizationDashboard';
import { fetchProposals } from '../apiCalls/apiCalls';
import useDataFetching from '../utils/customHooks/useFetchData';

const OrganizationDashboardContainer = ({
  balance,
  fetchOrganizationDashboard,
  isFetching,
  profileProjects,
  user,
}) => {
  useEffect(() => {
    fetchOrganizationDashboard();
  }, [fetchOrganizationDashboard]);

  const [rfpData] = useDataFetching({
    apiCallFunction: fetchProposals,
    initialResponseValue: {},
  });

  const portfolioWidgetData = useMemo(() => {
    if (!profileProjects || profileProjects.length === 0) return null;

    const registryNumber = profileProjects.length && profileProjects.reduce((acc, project) => {
      if (acc.indexOf(project.registry) === -1) {
        acc.push(project.registry);
      }
      return acc;
    }, []).length;

    const projectTypes = profileProjects.length && profileProjects.reduce((acc, project) => {
      if (acc.indexOf(project.project_type) === -1) {
        acc.push(project.project_type);
      }
      return acc;
    }, []).length;

    return {
      captured: balance.captured,
      registryNumber,
      projectTypes
    }
  }, [balance.captured, profileProjects]);

  const org = getUserOrImpersonatingOrg(user);

  if (isFetching) return <Loader />;
  return <OrganizationDashboard portfolioWidgetData={portfolioWidgetData} rfpWidgetData={rfpData} org={org} />;
};

const mapStateToProps = state => ({
  // balance
  errorMessages: getErrorMessages(state),
  balance: getProfileBalance(state),
  profileDetails: getProfileDetails(state),
  profileProjects: getProfileForests(state),
  user: getUserDetails(state),
  // fetching
  isFetching: getIsFetching(state),
});

OrganizationDashboardContainer.propTypes = {
  // balance
  captured: PropTypes.number,
  // fetchiing and errors
  isFetching: PropTypes.bool.isRequired,
  errorMessages: PropTypes.string,
  // profile details
  profileDetails: PropTypes.object,
  // projects
  profileProjects: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, {
  fetchOrganizationDashboard,
})(OrganizationDashboardContainer);