import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Container.css';

const Container = ({
  children,
  className,
  fixedWidth,
  toggleSearch,
  isSearchOpen,
  ...props
}) => {
  [toggleSearch, isSearchOpen].forEach(e => e) // To remove from props, and to prevent unused var
  return (
    <div {...props} className={cx(className, 'l-container', fixedWidth && 'l-container--fixed-width')}>
      { children }
    </div>
  )
}

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  fixedWidth: PropTypes.bool,
};

export default Container;
