import * as fromPublicProfileCommunity from '../reducers/publicProfileCommunity';

export const getIsFetching = state =>
  fromPublicProfileCommunity.getIsFetching(state.publicProfileCommunity);

export const getErrorMessages = state =>
  fromPublicProfileCommunity.getErrorMessages(state.publicProfileCommunity);

export const getProfileCommunity = state => 
fromPublicProfileCommunity.getProfileCommunity(state.publicProfileCommunity);

export const getProfileLeaderboard = state =>
fromPublicProfileCommunity.getProfileLeaderboard(state.publicProfileCommunity);

export const getPagination = state =>
fromPublicProfileCommunity.getPagination(state.publicProfileCommunity);