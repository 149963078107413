import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';

import Wrapper from '../components/Layout/Wrapper/Wrapper';
import CryptoPaymentFinished from '../containers/CryptoPaymentFinished';
import MobileTopbar from '../components/Header/MobileTopbar/MobileTopbar';

const Checkout = (props) => {
  return (
    <Wrapper>
      <Helmet>
        <title>
          Checkout | Reforestum
        </title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <MobileTopbar>
        <FormattedMessage
          id={'Header.checkout'}
          defaultMessage={'Checkout'}
        />
      </MobileTopbar>
      <CryptoPaymentFinished
        {...props}
      />
    </Wrapper>
  );
};

export default Checkout;
