import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import mixpanel from 'mixpanel-browser';

import {
  fetchForestDetails,
  fetchForests,
  fetchMyForests,
  fetchForestAboutGallery,
  forestSummary,
  resetMrv,
  fetchForestLatestActivity
} from '../actions/forests'
import { openGallery } from '../actions/UI'
import * as forestSelector from '../selectors/forests'

import SectorDetails from '../components/Forests/Details/SectorDetails';
import Loader from '../components/UI/Loader/Loader';

import getIsAutheticatedFromStore from '../utils/getIsAutheticatedFromStore';
import getIsImpactAvailable from '../utils/getIsImpactAvailable';

import { links } from '@reforestum/shared-utils';

const getForestVirtualSectorDetails = (virtualSectors, selectedVirtualSectorId) => {
  const match = virtualSectors.filter(virtualSector => Number(virtualSector.forest_virtual_sector_id) === selectedVirtualSectorId)
  return match.length ? match[0] : false
}

const adaptVirtualSectorFieldNamesForSectorComponent = (virtualSector) => {
  let result = null
  if(virtualSector) {
    result = {
      ...virtualSector,
      isVirtualSector: true,
      description: virtualSector.forest_virtual_sector_description,
      id: virtualSector.forest_virtual_sector_id,
      name: virtualSector.forest_virtual_sector_name,
      title: virtualSector.forest_virtual_sector_title,
      user_owner_id: virtualSector.forest_virtual_sector_user_owner_id,
      slug: virtualSector.forest_virtual_sector_slug,
      logo_url: virtualSector.forest_virtual_sector_logo_url,
      company_name: virtualSector.forest_virtual_sector_company_name,
    }
  }
  return result
}

class VirtualSectorContainer extends Component {
  componentWillMount() {
    const { 
      fetchMyForests,
      fetchForestDetails,
      selectedForestId,
      fetchForestAboutGallery,
      forestSummary,
      fetchForestLatestActivity,
      selectedVirtualSectorId
    } = this.props

    fetchMyForests()
    fetchForestDetails(selectedForestId)
    fetchForestAboutGallery(selectedForestId)
    forestSummary();
    fetchForestLatestActivity(selectedForestId, selectedVirtualSectorId);
  }

  componentDidUpdate(prevProps) {
    const {
      selectedForestId,
      selectedVirtualSectorId,
      fetchForestDetails,
      fetchForestAboutGallery,
      fetchForestLatestActivity
    } = this.props
    //Refresh forest page when new forest is selected
    if(prevProps && ((prevProps.selectedForestId !== selectedForestId) || (prevProps.selectedVirtualSectorId !== selectedVirtualSectorId))) {
      fetchForestDetails(selectedForestId);
      fetchForestAboutGallery(selectedForestId);
      fetchForestLatestActivity(selectedForestId, selectedVirtualSectorId);
    }
  }

  componentDidMount() {
    mixpanel.track("Page view", {
      "Authenticated": getIsAutheticatedFromStore(),
      "Action": "Vcu set page",
      "Sector": this.props.selectedForestId,
      "Domain": "App"
    })
  }

  render() {
    const {
      forest,
      isFetching,
      openGallery,
      species,
      team,
      gallery,
      selectedVirtualSectorId,
      myOwnedForests,
      isForestLevel,
      latestActivity
    } = this.props

    if (isFetching) {
      return <Loader />
    }

    if (!forest) {
      return <Redirect replace to={links.get404Url()} />
    }

    if(!forest.sectors){
      return <Loader />
    }

    const virtualSector = adaptVirtualSectorFieldNamesForSectorComponent(getForestVirtualSectorDetails(forest.virtual_sectors, selectedVirtualSectorId))

    if(!virtualSector) {
      return <Redirect replace to={links.getProjectUrl(forest.id)} />
    }

    const isImpactAvailable = getIsImpactAvailable(forest);

    return (
      <SectorDetails
        forest={forest}
        openGallery={openGallery}
        species={species}
        team={team}
        gallery={gallery}
        sector={virtualSector}
        myOwnedForests={myOwnedForests}
        latestActivity={latestActivity}
        isForestLevel={isForestLevel}
        // this allows to feature MRV tracking also for virtual sectors
        isImpactAvailable={isImpactAvailable}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  forest: forestSelector.getForestById(state, ownProps.selectedForestId),
  isFetching: forestSelector.getIsFetchingDetails(state),
  species: forestSelector.getForestSpecies(state, ownProps.selectedForestId),
  isFetchingMyForests: forestSelector.getIsFetchingMyForests(state),
  team: forestSelector.getForestTeam(state, ownProps.selectedForestId),
  gallery: forestSelector.getGallery(state, ownProps.selectedForestId),
  myOwnedForests: forestSelector.getMyOwnedForests(state),
  latestActivity: forestSelector.getLatestActivity(state)
})

VirtualSectorContainer.propTypes = {
  fetchForestDetails: PropTypes.func.isRequired,
  fetchMyForests: PropTypes.func.isRequired,
  fetchForestAboutGallery: PropTypes.func.isRequired,
  forestSummary: PropTypes.func.isRequired,
  fetchForestLatestActivity: PropTypes.func.isRequired,
  resetMrv: PropTypes.func.isRequired,
  forest: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  openGallery: PropTypes.func.isRequired,
  isFetchingMyForests: PropTypes.bool.isRequired,
  selectedForestId: PropTypes.number.isRequired,
  selectedVirtualSectorId: PropTypes.number.isRequired,
  species: PropTypes.array.isRequired,
  team: PropTypes.array.isRequired,
  gallery: PropTypes.object,
  myOwnedForests: PropTypes.array,
  isForestLevel: PropTypes.bool,
  latestActivity: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

export default withRouter(connect(mapStateToProps, {
  fetchForestDetails,
  fetchMyForests,
  fetchForests,
  openGallery,
  fetchForestAboutGallery,
  forestSummary,
  resetMrv,
  fetchForestLatestActivity
})(VirtualSectorContainer))
