import convert from 'convert-units';
import sharedUtils from '@reforestum/shared-utils'
import store from '../setup/store';
import {
  getUseMetricDistance,
  getUseMetricWeight,
  getUseCelsiusForTemp,
  getBigWeight,
} from '../selectors/userSession';
import {unitsMessages} from '../constants/messages'
import {REGISTRY_GOLD_STANDARD, REGISTRY_MITERD, REGISTRY_VERRA} from '../constants/registries';

const { isDistanceUnit } = sharedUtils.units
export const useMetricDistance = () => getUseMetricDistance(store.getState());
export const useMetricWeight = () => getUseMetricWeight(store.getState());
export const useCelsiusForTemp = () => getUseCelsiusForTemp(store.getState());
export const getTonUnit = () => getBigWeight(store.getState());

const KM_MILE_CONVERSION = 0.621371;


export const getUnit = (metricSymbol, appendSquare = false) => {
  const useMetricUnits = metricSymbol === 'km' || metricSymbol === 'm'
    ? useMetricDistance
    : useMetricWeight;

  const metricToImperial = {
    t: 't',
    ton: 'ton',
    gr: 'oz',
    kg: 'lb',
    km: 'mi',
    m: 'ft',
  };

  const unit = useMetricUnits() ? metricSymbol : metricToImperial[metricSymbol];
  return `${unit}${appendSquare ? '²' : ''}`;
};

export const getFormattedTemperature = celsius => useCelsiusForTemp()
  ? { value: celsius, unit: 'C' }
  : { value: Math.round(celsius * 9/5 + 32), unit: 'F' };

export const keepToMetric = (from, to, value) => (isDistanceUnit(from) ? useMetricDistance() : useMetricWeight())
    ? value
    : value * convert(1).from(from).to(to);
/**
 * getFormattedWeight
 *
 * @param  {Integer} value - Amount to format
 * @return {Object} Returns object with 'value' and 'unit' keys
 */

const amountTooBig = (value) => value >= 999999999;
const amountBig = (value) => (value > 9999 && value < 999999999) || value < -9999;
const amountSmall = (value) => value < 999 && value > -999;
const amountTooSmall = (value) => value < 1 && value > -1;

const specialAmountForVCUInKg = (value) => value >= 1 && value < 10;
const specialHiperSmallAmountOfVcus = (value) => value > 0 && value < 0.0099

export const kilometersToMiles = value => {
  value = parseFloat(value)
  return parseFloat((value * KM_MILE_CONVERSION).toFixed(2))
};

export const convertStepIndexToMiles = (stepList, index) => {
  stepList.forEach(step => step[1] = parseInt(step[1]))
  stepList.forEach(step => {
    if (step[0] === index) {step[1] = kilometersToMiles(step[1])}
  })
  return stepList
}

export const getMetricWeight = (value) => {
  if (amountTooSmall(value)) {
    return {
      value: getNumber((value * 1000)),
      unit: 'gr'
    };
  } else {
    return {
      value: getNumber(value),
      unit: 'kg'
    };
  }
};

export const getImperialWeight = value => {
  if (amountTooSmall(value)) {
    return {
      value: getNumber((value * 16)),
      unit: 'oz'
    };
  } else {
    return {
      value: getNumber(value),
      unit: 'lb'
    };
  }
};

export const getFormattedWeight = value => {

  const isMetric = useMetricWeight();
  const imperialValue = convert(value).from('kg').to('lb');

  if (amountBig(value) || amountTooBig(value) || (!isMetric && (amountBig(imperialValue) || amountTooBig(imperialValue) ))) {
    return {
          value: getNumber(value / 1000),
          unit: 't',
    };
  }

  return isMetric ? getMetricWeight(value) : getImperialWeight(imperialValue);
};

export const getFormattedArea = value => {
  return useMetricDistance() ? getMetricArea(value) : getImperialArea(value);
};

export const getMetricArea = (value) => {
  if(amountSmall(value)) {
    return {
      value: getNumber(value),
      unit: 'm2'
    };
  } else {
    const amountInHectares = convert(value).from('m2').to('ha');
    if (amountTooBig(value)) {
      return {
        value: (amountInHectares/Math.pow(10, 6)).toFixed(2),
        unit: 'M.ha'
      };
    } else {
      return {
        value: (amountInHectares).toFixed(2),
        unit: 'ha'
      };
    }
  }
};

export const getImperialArea = value => {
  const amount = convert(value).from('m2').to('ft2');

  if(amountSmall(amount)) {
    return {
      value: getNumber(amount),
      unit: 'ft2'
    };
  } else {
    const amountInAcres = convert(amount).from('ft2').to('ac');
    if (amountTooBig(amountInAcres)) {
      return {
        value: (amountInAcres/Math.pow(10, 6)).toFixed(2),
        unit: 'M.ac'
      };
    } else {
      return {
        value: (amountInAcres).toFixed(2),
        unit: 'ac'
      };
    }
  }
};

// @LEGACY: this function is returning value as a string due to the toFixed()
// ...so the tests with be adapted to it
// @TODO: fix tests and inconsistencies in the code base with the solution:
// Number(value.toFixed(0)) && Number(value.toFixed(2))
export const getNumber = value => {
  if(value === 0 || value === undefined || value === null){
    return 0
  }
  else if(specialHiperSmallAmountOfVcus(value)){
    return Number(value).toFixed(3);
  } 
  else if(Number.isInteger(value)) {
    return Number(value).toFixed(0);
  } 
  else {
    return Number(value).toFixed(2);
  }
}

// Specific getNumber function used for VCUs...
// ...to cover specialAmountForVCUInKg cases like 3kgs === 0.003VCUs without losing precision
export const getNumberForVCUAmount = value => {
  return Number(value).toFixed(3)
}

// Format VCU when receiving kgs of CO2
export const getFormattedVCUs = value => {
  const formattedValue = value / 1000

  if (specialAmountForVCUInKg(value)) {
    return {
      value: getNumberForVCUAmount(formattedValue),
      unit: 'vcu'
    }
  } else {
    return {
      value: getNumber(formattedValue),
      unit: 'vcu',
    }
  }
};

// Format VCU when receiving VCUs
export const getFormattedCredits = ({credits, roundToMillionsIfAppropiate = false, registry = REGISTRY_VERRA}) => {
  if (roundToMillionsIfAppropiate && credits >= Math.pow(10, 6)) {
    const millions = (credits/Math.pow(10, 6))
    return {
      value: credits >= Math.pow(10, 8) ? millions.toFixed(0) : millions.toFixed(2),
      unit: (registry === REGISTRY_GOLD_STANDARD || registry === REGISTRY_MITERD) ? 'tco2e' : REGISTRY_VERRA ? 'M.vcu' : 'M.credits'
    }
  } else {
    return {
      value: getNumber(credits),
      unit: (registry === REGISTRY_GOLD_STANDARD || registry === REGISTRY_MITERD) ? 'tco2e' : REGISTRY_VERRA ? 'vcu' : 'credits'
    };
  }
};

export const formatUnit = (intl, unit) => intl.formatMessage(unitsMessages[unit])
