import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box } from 'react-layout-components';

import './Card.css'



const Card = ({children, href, onClick, className}) =>  {
    const Wrapper = href ? Link : 'div';
    return (
        <Wrapper to={href}
            onClick={onClick}
        >
            <Box
                justifyContent="space-between"
                alignItems="center"
                className={`ui-components-card ${className}`}
            >
                {children}
            </Box>
        </Wrapper>
    )
}

Card.prototype = {
    href: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
}






export default Card;