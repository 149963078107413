import React from 'react';

const ArrowLeftSimpleNoCircle = props => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M14.5 8L10 12.5L14.5 17" stroke={props.stroke ? props.stroke : '#1F3B21'} stroke-width="1.2"/>
</svg>
)


export default ArrowLeftSimpleNoCircle;
