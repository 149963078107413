import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import history from '../../../setup/history';

import { MyForestSummary as MyForestSummaryTexts, graphMessages } from '../../../constants/messages';
import {OFFSET_TYPE_SQM_SHARES, OFFSET_TYPE_CREDIT} from '../../../constants/offsetTypes'

import { getFormattedArea, getFormattedWeight, formatUnit } from '../../../utils/units';
import { carbonAmountIn } from '../../../utils/carbon';

import LatestActivityContainer from '../../../containers/LatestActivityContainer';

import { Button } from '@reforestum/shared-components';
import MapTrifold from '../../UI/Icons/MapTrifold';
import TinyInfoBlock from '../../UI/TinyInfoBlock/TinyInfoBlock';
import Tags from '../../UI/Tags/Tags';
import Heading from '../../UI/Text/Heading';
import Text from '../../UI/Text/Text';
import ReadMore from '../../UI/ReadMore';
import Photo from '../../UI/Icons/Photo';

import getFeatureFlags from '../../../constants/featureFlags';

import './MyForestSummary.css';
import TransactionsInProjectSection from './TransactionsInProjectSection';
import {REGISTRY_GOLD_STANDARD, REGISTRY_MITERD} from '../../../constants/registries';

const ff = getFeatureFlags()

const MyForestSummary = ({
  forestImage,
  companyImage,
  overlayCompanyImageOnForestImage,
  type,
  forestName,
  reforestumProjectType,
  exploreLink,
  reforestersLink,
  sectorSurface,
  userOwnedSurface,
  area,
  captured,
  orgTotalCredits,
  orgIsOwner,
  reforesters_amount,
  plantingDate,
  certifications,
  transactions,
  registry,
  project_type,
  offset_type,
  description,
  location,
  events,
  gallery,
  openGallery,
  years,
  formula,
  companyNickname,
  unOnboardedCompanyMode,
  organizationPrivateDashboardMode,
  intl,
  handleShowOnboardYourCompanyModal,
  handleShowRequestThatCompanyOnboardModal,
  isForestLevel,
}) => {

  const formattedOwnedSurface = area && getFormattedArea(orgIsOwner ? (sectorSurface || area) : userOwnedSurface);

  const formattedCaptured = captured && getFormattedWeight(captured);

  const yearsCo2Estimation = years && formula && (sectorSurface || userOwnedSurface) 
    ? carbonAmountIn(years, formula, orgIsOwner ? sectorSurface : userOwnedSurface)
    : null;

  const ML_SECONDS_IN_A_YEAR = 31536000000
  const lastYearDate = yearsCo2Estimation ? new Date(plantingDate + (years * ML_SECONDS_IN_A_YEAR)).getFullYear() : null
  const formattedYearsCo2Estimation = getFormattedWeight(yearsCo2Estimation);

  // GALLERY
  const [...images] = gallery;
  // reverse the images list as it could be the same as in the first event featured
  const inversedGalleryList = images.reverse()
  const openGalleryFunction = (index) => () => openGallery(inversedGalleryList.map(({url}) => ({URI: url})), index)

  const [mainImage, ...imagesList] = inversedGalleryList

  const renderTinyInfoBlocks = () => {

    const getReforestersLinkComponentProperties = () => {
      let result
      if(unOnboardedCompanyMode) {
        result = {
          className: "my-forest-summary-area-info-item",
          title: intl.formatMessage(MyForestSummaryTexts.reforesters),
          ctaText: intl.formatMessage(MyForestSummaryTexts.reforestersLink),
          ctaAction: handleShowOnboardYourCompanyModal,
          icon: "users"
        }
      }
      else {
        result = {
          className: "my-forest-summary-area-info-item",
          title: intl.formatMessage(MyForestSummaryTexts.reforesters),
          value: reforesters_amount,
          linkText: intl.formatMessage(MyForestSummaryTexts.reforestersLink),
          linkHref: reforestersLink,
          icon: "users"
        }
      }
      return result
    }

    let tinyInfoBlocksProps = [
      (type === OFFSET_TYPE_SQM_SHARES && area) ? ({
        className: "my-forest-summary-area-info-item",
        title: orgIsOwner ? intl.formatMessage(MyForestSummaryTexts.area) : intl.formatMessage(MyForestSummaryTexts.areaOwned),
        value: formattedOwnedSurface.value,
        unit: formatUnit(intl, formattedOwnedSurface.unit),
        icon: "surface",
      }) : null,
      (yearsCo2Estimation && years) ? {
        className: "my-forest-summary-area-info-item",
        title: intl.formatMessage(graphMessages.yearEstimateDate, {year: lastYearDate}),
        value: formattedYearsCo2Estimation.value,
        unit: `${formatUnit(intl, formattedYearsCo2Estimation.unit)} ${intl.formatMessage(MyForestSummaryTexts.co2UnitUpdated)}`,
        icon: 'clockFuture',
        tooltipPosition: 'top-left',
        tooltip: intl.formatMessage(graphMessages.yearEstimateForestTooltip)
      } : null,
      orgTotalCredits ? {
        className: "my-forest-summary-area-info-item",
        title: (registry === REGISTRY_GOLD_STANDARD) ? <FormattedMessage id={'CarbonBalance.verifiedOffsets'} defaultMessage={'Verified Offsets'}/> : (registry === REGISTRY_MITERD) ? <FormattedMessage id={'CarbonBalance.miterdCredits'} defaultMessage={'MITERD Carbon Credits'}/> : intl.formatMessage(MyForestSummaryTexts.conservation),
        value: orgTotalCredits,
        unit: intl.formatMessage(MyForestSummaryTexts.tonsFixed),
        icon: "credits"
      } : null,
      captured ? {
        className: "my-forest-summary-area-info-item",
        title: intl.formatMessage(MyForestSummaryTexts.co2),
        value: formattedCaptured.value,
        unit: `${formatUnit(intl, formattedCaptured.unit)} ${intl.formatMessage(MyForestSummaryTexts.co2UnitUpdated)}`,
        icon: "emissions"
      } : null,
      (isForestLevel && !!reforesters_amount && reforesters_amount > 0) ? getReforestersLinkComponentProperties() : null
    ]

    tinyInfoBlocksProps = tinyInfoBlocksProps.filter(b => !!b)

    for(let i = 0; i < tinyInfoBlocksProps.length; i++) {
      if(i < tinyInfoBlocksProps.length - 1) {
        tinyInfoBlocksProps[i] = {...tinyInfoBlocksProps[i], divider: true}
      }
    }

    return tinyInfoBlocksProps.map((blockProps, index) => <TinyInfoBlock key={index} {...blockProps}/>)
  }

  const getDefaultUnonboardedCompanyEvents = () => {
    return [
      {
        date: Date.now(),
        forestId: 2,
        pics: [],
        text: intl.formatMessage(MyForestSummaryTexts.unonboardedCompanyDefaultEventsDescription, {companyNickname}),
        title: intl.formatMessage(MyForestSummaryTexts.unonboardedCompanyDefaultEventsTitle),
        type: "TXT",
        postTextComponent: <div className={'my-forest-summary__unonboarded-company__CTAs'}>
          <Button onClick={handleShowRequestThatCompanyOnboardModal} small className="btn--blank my-forest-summary__unonboarded-company-activity__CTA__button">
            <Text className={'my-forest-summary__unonboarded-company-activity__CTA__button__text'}>
              {intl.formatMessage(MyForestSummaryTexts.unonboardedCompanyDefaultEventsCTAContactCompany)}
            </Text>
          </Button>
          <Button onClick={handleShowOnboardYourCompanyModal} small className="btn--blank my-forest-summary__unonboarded-company-activity__CTA__button">
            <Text className={'my-forest-summary__unonboarded-company-activity__CTA__button__text'}>
              {intl.formatMessage(MyForestSummaryTexts.unonboardedCompanyDefaultEventsCTASignInToAddUpdates)}
            </Text>
          </Button>
        </div>
      }
    ]
  }

  const renderEventsInCorporateProfile = (events) => {
    let result
    let eventsToUse = unOnboardedCompanyMode ? getDefaultUnonboardedCompanyEvents() : events

    if(events.length || unOnboardedCompanyMode) {
      result = <section className="my-forest-summary__section">
        <Heading className={'my-forest-summary-project-description-title'}>
          <FormattedMessage
            id={'Dashboard.latestUpdatesBlock'}
            defaultMessage={'Latest updates'}
          />
        </Heading>
        <LatestActivityContainer isCorpProfile events={eventsToUse} openGallery={openGallery} />
      </section>
    }
    return result
  }

  return (
    <div className="my-forest-summary-container">
      {gallery.length > 0 && !organizationPrivateDashboardMode && 
        <section className="main-images__container">
          {mainImage && (
            <img
              src={mainImage.url}
              onClick={openGalleryFunction(0)}
              className="main__image"
              alt="forest, local communities and landscape"
            />
          )}
          {imagesList.length > 0 && (
            <div className="thumbnails__container">
              {imagesList.slice(0, 2).map((thumbnail, index) => (
                <div
                  key={index}
                  role="img"
                  onClick={openGalleryFunction(index + 1)}
                  className={cx('thumbnail__image', { 'latest-activity-card__thumbnail--overlay': index === 1})}
                  style={{backgroundImage: `url(${thumbnail.url})`}}
                >
                  {index === 1 && imagesList.length > 2 && (
                    <span className="latest-activity-card__thumbnail-overlay-value">
                      <span role="img"><Photo /></span>+ {imagesList.length - 2}
                    </span>
                  )}
                </div>
              ))}
            </div>
          )}
        </section>
      }
      <div className="my-forest-summary-header">
        {forestImage
          ? <img className="my-forest-summary-header-forest-image" alt="forest" src={forestImage} aria-hidden />
          : <div className="my-forest-summary-header-forest-image"/>
        }
        {
          overlayCompanyImageOnForestImage && <img
            className="my-forest-summary-header-company-image"
            alt="company logo"
            src={companyImage}
            aria-hidden
          />
        }
        <div
          className={cx(
            "my-forest-summary-header-info-container",
            "my-forest-summary-header-info-container--with-company"
          )
          }
        >
          <p className="my-forest-summary-header-info-name">{forestName}</p>
          <p className="my-forest-summary-header-info-location">{location}</p>
        </div>
        <Button onClick={() => history.push(exploreLink)} outline small className="btn--blank my-forest-summary-header-explore-button">
          <MapTrifold />
          <Text className="my-forest-summary-header-explore-button-text">
            <FormattedMessage
              id={'MyForestSummary.explore'}
              defaultMessage={'Explore'}
            />
          </Text>
        </Button>
      </div>
      <div className="my-forest-summary-area-info">
        {renderTinyInfoBlocks()}
      </div>
      <Tags
        intl={intl}
        certifications={certifications}
        reforestumProjectType={reforestumProjectType}
        projectType={project_type}
        tagBackground="light"
        planting_date={new Date(plantingDate)}
        forceEstablishedDatePhrasing={type === OFFSET_TYPE_CREDIT}
        offsetType={type}
        registry={registry}
      />

      <div className="projects-card__divider" />

      {description && !organizationPrivateDashboardMode &&
        <section className="my-forest-summary__section">
          <Heading className={'my-forest-summary-project-description-title'}>
            <FormattedMessage 
              id={'Dashboard.ourIntentionBlock'}
              defaultMessage={'Our intention behind this project'}
            />
          </Heading>
          <Text tag="p" weight="regular" className="my-forest-summary-project-description">
              <ReadMore text={description} isHtml cutLength={300} minLength={1000}/>
          </Text>
        </section>
      }

      {ff.eventsInCorporateProfile && !organizationPrivateDashboardMode &&
        renderEventsInCorporateProfile(events)
      }

      { transactions && <TransactionsInProjectSection registry={registry} organizationPrivateDashboardMode={organizationPrivateDashboardMode} transactions={transactions}/>}

    </div>
  );
};

MyForestSummary.propTypes = {
  type: PropTypes.oneOf(['SQM-SHARES', 'FOUNDED-FOREST', 'VCU']),
  forestImage: PropTypes.string,
  companyImage: PropTypes.string,
  forestName: PropTypes.string,
  exploreLink: PropTypes.string,
  reforestersLink: PropTypes.string,
  area: PropTypes.number,
  sectorSurface: PropTypes.number,
  userOwnedSurface: PropTypes.number,
  orgIsOwner: PropTypes.bool,
  captured: PropTypes.number,
  vcus: PropTypes.number,
  orgTotalCredits: PropTypes.number,
  certifications: PropTypes.arrayOf(PropTypes.object),
  reforestumProjectType: PropTypes.object,
  formula: PropTypes.string,
  years: PropTypes.number,
  reforesters_amount: PropTypes.number,
  plantingDate: PropTypes.number,
  intl: intlShape,
  overlayCompanyImageOnForestImage: PropTypes.bool,
  description: PropTypes.string.isRequired,
  location: PropTypes.string,
  openGallery: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  gallery: PropTypes.array,
  project_type: PropTypes.string,
  offset_type: PropTypes.string,
  companyNickname: PropTypes.string,
  unOnboardedCompanyMode: PropTypes.bool,
  organizationPrivateDashboardMode: PropTypes.bool,
  transactions: PropTypes.array,
  registry: PropTypes.string,
  handleShowOnboardYourCompanyModal: PropTypes.func,
  handleShowRequestThatCompanyOnboardModal: PropTypes.func,
  isForestLevel: PropTypes.bool,
};

export default  injectIntl(MyForestSummary);
