import React from 'react'
import PropTypes from 'prop-types'
import SponsorsCard from "../components/UI/SponsorsCard/SponsorsCard"
import { OFFSET_TYPE_SQM_SHARES } from '../constants/offsetTypes'
import { SECTOR_TYPES } from '../constants/sectorTypes'
import { orderSectorsByContributionAmount } from '../utils/orderSectorsByContributionAmount'

const SponsorsContainer = ({forest}) => {
  const isSqmSharesProject = forest.offset_type === OFFSET_TYPE_SQM_SHARES
  let sectors = isSqmSharesProject ? forest.sectors : forest.virtual_sectors

  const getIsCommunity = (sector) => (sector.type === SECTOR_TYPES.COMMUNITY) || sector.forest_virtual_sector_is_community
  //Order sectors by amount (descendant)
  sectors = orderSectorsByContributionAmount({sectors, isSqmSharesProject})

  //Set community forests first
  sectors = [
    ...sectors.filter(sector => getIsCommunity(sector)),
    ...sectors.filter(sector => !getIsCommunity(sector))
  ]



  const renderMain = () => {
    return <div>
      {isSqmSharesProject ?
        sectors.map(sector => {
            const isCommunity = getIsCommunity(sector)
            return <div className={`sponsor-card-container`}>
              <SponsorsCard
                isCommunity={isCommunity}
                isSqmSharesProject
                forestId={forest.id}
                id={sector.sector_id}
                logo={sector.logo_url}
                companyName={isCommunity ? sector.name : sector.company_name}
                profileDomain={!isCommunity && sector.profile_domain}
                reforestersAmount={sector.reforesters_amount}
                totalSurface={sector.sector_surface}
                description={sector.description}
              />
            </div>
          }) : 
        sectors.map(
          virtualSector => {
            const isCommunity = getIsCommunity(virtualSector)
            return (
              <div className={`sponsor-card-container`}>
                <SponsorsCard
                  isCommunity={isCommunity}
                  forestId={forest.id}
                  id={virtualSector.forest_virtual_sector_id}
                  logo={virtualSector.forest_virtual_sector_logo_url}
                  companyName={isCommunity ? virtualSector.forest_virtual_sector_name : virtualSector.forest_virtual_sector_company_name}
                  profileDomain={!isCommunity && virtualSector.profile_domain}
                  reforestersAmount={virtualSector.reforesters_amount}
                  totalCredits={virtualSector.total_vcus}
                  description={virtualSector.forest_virtual_sector_description}
                />
              </div>
            )
          }
        )
        
      }
    </div>
    }

  return sectors ? renderMain() : null
}

SponsorsContainer.propTypes = {
  forest: PropTypes.object.isRequired,
}

export default SponsorsContainer
