import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';


import './PersonalInformationForm.css';
import Row from '../../../UI/Forms/Row';
import Input from '../../../UI/Forms/Input';
import Typeahead from '../../../UI/Forms/Typeahead';
import { addressMessages, authMessages, settingsMessages } from '../../../../constants/messages';
import { withIntl } from '../../../../utils/intl';
import { email as emailValidation, maxLength, onlyAlphabeticCharacters, required, validCountry } from '../../../../utils/formHelpers';
import { getPrivacyOptionsForIndividuals } from '../../../../utils/getPrivacyOptions';
import { PRIVACYLEVEL } from '../../../../constants/privacyLevels';
import {Button} from '@reforestum/shared-components';
import { USER_FIELDS_MAX_LENGTH } from '../../../../constants/userFieldsMaxLength';
import CustomSelect from '../../../UI/Forms/CustomSelectNew';
class PersonalInformationForm extends Component {
    constructor(props) {
        const { intl } = props;
        super(props);
        this.state = {
            privacyOptions: getPrivacyOptionsForIndividuals(intl),
            privacy: null,
            updatingUser: false,
        }
        this.submitForm = this.submitForm.bind(this);
        this.handlePrivacySelectChange = this.handlePrivacySelectChange.bind(this);
        this.hasPrivacyChanged = this.hasPrivacyChanged.bind(this)
        this.hasCountryChanged = this.hasCountryChanged.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        const { initialValues } = nextProps;
        const { addAlert } = this.props;
        
        //Sets the privacy level to the initial value
        if(initialValues && initialValues.privacy && this.state.privacy === null) {
            this.setState({
                privacy: initialValues.privacy
            })
        }

        //Shows update user error message if the user update returns an error
        if(nextProps.updateErrorMessages && (nextProps.updateErrorMessages !== this.props.updateErrorMessages)) {
            addAlert({
                type: 'error',
                message: nextProps.updateErrorMessages,
                dismissAfter: 5000,
            })
        }
    } 

    handlePrivacySelectChange(selectedOption) {
        this.setState({
            privacy: Number(selectedOption.value),
        })
    }

    hasPrivacyChanged() {
        const {privacy} = this.state;
        const initialPrivacy = this.props.initialValues.privacy;
        return Number(privacy) !== Number(initialPrivacy);
    }

    hasCountryChanged() {
        const {country} = this.props.initialValues;
        const {country: initialCountry} = this.props.initialValues;
        return country !== initialCountry;
    }

    submitForm(form){
        const { updateUser, countries, refreshProfile, intl } = this.props;
        const { privacy } = this.state;

        const countryCode = countries.find(
            (country)  => form.country === country.country_name)
            .country_code;

        this.setState({updatingUser: true});
        updateUser({
            form: {
                ...form,
                privacy,
                country: countryCode,
            },
            intl,
        }).then(() => {
            refreshProfile().then(() => this.setState({updatingUser: false}));
        })

    };


    render() {

        const {
            handleSubmit, 
            countries,
            initialValues,
            dirty,
            invalid,
            loadingInitialValues,
            intl,
        } = this.props;

        const { privacy, privacyOptions, updatingUser } = this.state;

        return (
                <form  onSubmit={handleSubmit(this.submitForm)} className="personal-information-form-container">         
                    <Row>
                        <Field
                            required
                            name="name"
                            component={Input}
                            placeholder={intl.formatMessage(authMessages.namePlaceholder)}
                            label={intl.formatMessage(authMessages.nameLabel)}
                            type="text"
                            disabled={loadingInitialValues}
                            validate={withIntl(intl, [required, onlyAlphabeticCharacters, maxLength(USER_FIELDS_MAX_LENGTH.name)])}
                        />
                        <Field
                            required
                            name="surname"
                            component={Input}
                            placeholder={intl.formatMessage(authMessages.surnamePlaceholder)}
                            label={intl.formatMessage(authMessages.surnameLabel)}
                            type="text"
                            disabled={loadingInitialValues}
                            validate={withIntl(intl, [required, onlyAlphabeticCharacters, maxLength(USER_FIELDS_MAX_LENGTH.surname)])}
                        />
                    </Row>
                    <Row>
                        <Field
                            name="email"
                            disabled
                            component={Input}
                            placeholder={intl.formatMessage(authMessages.emailPlaceholder)}
                            label={intl.formatMessage(authMessages.emailLabel)}
                            type="email"
                            validate={withIntl(intl, [emailValidation, required])}
                        />
                    </Row>

                    <Row>
                        <Field
                            name="address_line"
                            component={Input}
                            label={intl.formatMessage(addressMessages.addressLabel)}
                            placeholder={intl.formatMessage(addressMessages.addressPlaceholder)}
                            type="text"
                            disabled={loadingInitialValues}
                            validate={withIntl(intl, [required, maxLength(USER_FIELDS_MAX_LENGTH.address_line)])}
                        />
                    </Row>

                    <Row>
                        <Field
                            name="city"
                            component={Input}
                            label={intl.formatMessage(addressMessages.cityLabel)}
                            placeholder={intl.formatMessage(addressMessages.cityPlaceholder)}
                            type="text"
                            disabled={loadingInitialValues}
                            validate={withIntl(intl, [required, maxLength(USER_FIELDS_MAX_LENGTH.city)])}
                        />
                        <Field
                            name="zip"
                            component={Input}
                            label={intl.formatMessage(addressMessages.zipLabel)}
                            placeholder={intl.formatMessage(addressMessages.zipPlaceholder)}
                            type="text"
                            disabled={loadingInitialValues}
                            validate={withIntl(intl, [required, maxLength(USER_FIELDS_MAX_LENGTH.zip)])}
                        />
                    </Row>
                    <Row>
                        <Field
                            name="state"
                            component={Input}
                            label={intl.formatMessage(addressMessages.stateLabel)}
                            placeholder={intl.formatMessage(addressMessages.statePlaceholder)}
                            type="text"
                            disabled={loadingInitialValues}
                            validate={withIntl(intl, [required, maxLength(USER_FIELDS_MAX_LENGTH.state)])}
                        />
                        <Field
                            dirty={false}
                            name="country"
                            required
                            component={Typeahead}
                            initialValue={initialValues.country}
                            items={countries}
                            getItemValue={(item) => item.country_name}
                            label={intl.formatMessage(addressMessages.countryLabel)}
                            placeholder={intl.formatMessage(addressMessages.countryPlaceholder)}
                            disabled={loadingInitialValues}
                            validate={withIntl(intl, [required, validCountry(countries.map(country => country.country_name))])}
                        />
                    </Row>
                    <Row>
                            <CustomSelect
                                label={intl.formatMessage(settingsMessages.privacyLabel)}
                                value={(privacy !== null) ? privacyOptions.find(option => option.value === privacy) : null}
                                options={privacyOptions}
                                onChange={this.handlePrivacySelectChange}
                                isSearchable={false}
                            />
                    </Row>
                    {privacy === PRIVACYLEVEL.NICKNAME &&
                        <Row>
                            <Field
                                name="display_name"
                                component={Input}
                                disabled={loadingInitialValues}
                                label={intl.formatMessage(settingsMessages.nicknameLabel)}
                                placeholder={intl.formatMessage(settingsMessages.nicknamePlaceholder)}
                                type="text"
                                validate={withIntl(intl, [required, maxLength(USER_FIELDS_MAX_LENGTH.display_name)])}
                            />
                        </Row>
                    }
                    <Row>
                        <Button
                            disabled={loadingInitialValues || invalid || (!(dirty || this.hasPrivacyChanged()))}
                            type="submit"
                            className='personal-information-form-submit-button'
                            loading={loadingInitialValues || updatingUser}
                        >
                            <FormattedMessage id="Form.updateInforamtionButton" defaultMessage="Update Information"/>
                        </Button>
                    </Row>
                </form>
        )
    }
}

PersonalInformationForm.propTypes = {
    initialValues: PropTypes.object,
    intl: PropTypes.object,
    countries: PropTypes.array,
    loadingInitialValues: PropTypes.bool,
    updateUser: PropTypes.func,
    updateErrorMessages: PropTypes.string,
    /* redux form */
    handleSubmit: PropTypes.func,
};

export default injectIntl(reduxForm({
  form: 'personal-information-form',
})(PersonalInformationForm));
