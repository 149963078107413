import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Close from '../Icons/Close';
import Overlay from '../Overlay/Overlay';
import Text from '../Text/Text';
import { Button } from '@reforestum/shared-components';

import './ModalDefault.css';

class ModalNotifyForest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  render() {
    const {
      hideModal,
      imageSrc,
      imageAlt = '',
      forestName,
      onConfirm,
    } = this.props;

    return (
      <Overlay wrapperClassName="modal-default" onClick={hideModal}>
        <div className="modal-default__box">
          {imageSrc && (
            <img
              src={imageSrc}
              className="modal-default__image"
              alt={imageAlt}
            />
          )}

          <Text tag="h2" className="modal-default__title">
            <FormattedMessage 
              id="ModalNotifyForest.title"
              defaultMessage={'You\'re signed up!'}
            />
          </Text>

          <Text tag="p" color="light" className="modal-default__text">
            <FormattedMessage
              id="ModalNotifyForest.info"
              defaultMessage={'We\'ll email you as soon as {forestName} comes online.'}
              values={{ forestName }}
            />
          </Text>

          <footer className="modal-default__footer">
            <div>
              <Button className="btn--blank" onClick={hideModal}>
                <FormattedMessage 
                  id="ModalNotifyForest.cancelButtonText"
                  defaultMessage={'Cancel'}
                />
              </Button>

              <Button
                loading={this.state.isLoading}
                onClick={async () => {
                  this.setState({ isLoading: true });
                  await onConfirm();
                  hideModal();
                }}
              >
                <FormattedMessage 
                  id="ModalNotifyForest.confirmButtonText"
                  defaultMessage={'OK'}
                />
              </Button>
            </div>
          </footer>
        </div>
        <Close className="modal-default__close" onClick={hideModal} />
      </Overlay>
    );
  }
}

ModalNotifyForest.propTypes = {
  hideModal: PropTypes.func.isRequired,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  forestName: PropTypes.string,
  onConfirm: PropTypes.func,
};

export default ModalNotifyForest;
