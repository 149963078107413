import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Autocomplete from 'react-autocomplete';
import { intlShape } from 'react-intl';
import throttle from 'lodash/throttle';
import get from 'lodash/get';
import { required } from '../../utils/formHelpers';
import { withIntl } from '../../utils/intl';
import api from '../../constants/api';
import poweredByGoogleImage from '@reforestum/shared-components/dist/assets/images/google-places-api/powered_by_google_on_white.png';
import highlightMatchingPartOfName from './helpers/highlightMatchingPartOfName';
import AutosizeInput from "react-input-autosize";
import {GENERAL_INPUT_DEFAULT_SIZE} from "./helpers/constants";
import {Element} from "react-scroll/modules";
import {
  moveCalculatorElementToPageTopOnMobile
} from "./helpers/moveElementToPageTopOnMobile"
import ClearStep from "./ClearStep";

const MIN_TEXT_HIGHLIGHT_LENGTH = 1

class StepTypeahead extends Component {
  constructor() {
    super();

    this.state = {
      options: [],
      value: '',
      lastValue: '',
      changedByUser: false,
      loadedInitialValue: false
    };

    this.autocompleteComponent = this.autocompleteComponent.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.fetchItems = throttle(this.fetchItems, 300);

    this.fetchPlaces = this.fetchPlaces.bind(this);
    this.fetchPlaces = throttle(this.fetchPlaces, 300);

    this.referenceToInputForFocus = null;

    this.stateValueUsingIndex = ''

    this.manuallySavedFormValue = null
  }

  componentDidMount() {
    if(this.referenceToInputForFocus != null && typeof this.referenceToInputForFocus.focus === 'function'){
      if(typeof this.props.stepIndex.match !== 'function' || (typeof this.props.stepIndex.match === 'function' && !this.props.stepIndex.match('_2'))){
        setTimeout(this.referenceToInputForFocus.focus, 400)
      }
    }
  }

  componentWillMount() {
    const {manuallyChangedFormValues} = this.props
    const {id} = this.props.step
    if (manuallyChangedFormValues && manuallyChangedFormValues[`field_${id}`]) {
      this.manuallySavedFormValue =  manuallyChangedFormValues[`field_${id}`].toString()
    }
  }

  componentWillUpdate(nextProps, nextState) {

    const { step: { typeahead_query_url }, usePlacesAPI } = nextProps;
    const { value } = nextState;

    if(nextProps.stepsList && nextProps.stepsList.length > nextProps.stepIndex){
      this.stateValueUsingIndex = nextProps.stepsList[nextProps.stepIndex][1]
    }

    if ((typeahead_query_url || usePlacesAPI) && value !== this.state.value) {
      if (!value) {
        this.setState({options: []});
      } else {
        usePlacesAPI
          ? this.fetchPlaces(value)
          : this.fetchItems(typeahead_query_url, value);
      }
    }
  }

  fetchItems(typeaheadQueryUrl, value) {
    if(value === this.state.lastValue) return
    else this.setState({lastValue: value})
    fetch(`${typeaheadQueryUrl}?q=${value}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          options: response.data.airports,
        });
      });
  }

  fetchPlaces(value) {
    fetch(
      `${api.CALCULATOR_PLACES_AUTOCOMPLETE_URL}?input=${encodeURIComponent(value)}`,
      {headers: new Headers({Authorization: `Bearer ${this.props.sessionToken}`})}
      )
      .then(response => response.json())
      .then(response => {
        this.setState({
          options: response.data.map(prediction => ({
            key: `${prediction.description}-${prediction.place_id}`,
            name: prediction.description,
            placeId: prediction.place_id,
          })),
        })
      })
  }

  getPlaceDetailsUsingId(id, input) {
    fetch(
      `${api.CALCULATOR_PLACES_DETAILS_URL}?placeId=${id}`,
      {headers: new Headers({Authorization: `Bearer ${this.props.sessionToken}`})}
    )
      .then(response => response.json())
      .then(response => {
        const {formatted_address} = response
        this.setState({value: formatted_address})
        input.onChange(formatted_address)
        this.props.addValueToField(this.props.oneOrTwo, {
          name: formatted_address,
          placeId: id
        })
      })
  }

  useManuallySavedFormValuesIfPossible() {
    if(this.state.changedByUser) {
      return this.state.value
    }
    if(this.props.value) {
      return this.props.value
    }
    if(this.manuallySavedFormValue != null) return this.manuallySavedFormValue
    if (this.state.value != null && this.state.value !== '') {
      return this.state.value
    }
    else if(this.manuallySavedFormValue != null && (this.stateValueUsingIndex == null || this.stateValueUsingIndex === '')){
      return this.manuallySavedFormValue
    }
    else {
      return this.stateValueUsingIndex
    }
  }

  lookupOptionName(value) {
    if(!this.props.options) return value
    let numValue = parseInt(value)
    for(let i in this.props.options) {
      if (this.props.options[i].value === numValue) return this.props.options[i].name
    }
    return value
  }

  getInitialStepValue(input) {
    if(!this.state.loadedInitialValue && !this.state.changedByUser && this.props.initialValue && this.props.initialValue !== '') {
      this.setState({value: '...', loadedInitialValue: true})
      this.getPlaceDetailsUsingId(this.props.initialValue, input)
    }
  }

  autocompleteComponent(props) {
    const { step, options, handleNextStep, usePlacesAPI, fieldId, value, isMostRecentStep,stepIndex, jumpBackToStepIndex, disabled } = this.props;
    const { input } = props;
    let showOptions = options
    if(
      options
      && options[0]
      && (options[0].name === 'number of people' || options[0].name === 'I flew to')
    ) {
      showOptions = []
    }
    this.getInitialStepValue(props.input)
    return (
      <Autocomplete
        autoHighlight={false}
        ref={elem => (this.referenceToInputForFocus = elem)}
        value={this.lookupOptionName(this.useManuallySavedFormValuesIfPossible())}
        onChange={
          event => {
            disabled
              ? (() => {})()
              : this.setState({
                changedByUser: true,
                value: event.target.value
              })
          }
        }
        items={this.state.options.length > 0 ? this.state.options : showOptions}
        getItemValue={option => option.name}
        inputProps={{
          onBlur: () => {
            this.setState({value})
          },
          placeholder: step.placeholder,
          onFocus: this.inputFocused.bind(this),
          onClick: event => {
            event.target.select()
            moveCalculatorElementToPageTopOnMobile(`step-${this.props.stepIndex}`)
          },
        }}
        renderInput={(props) => {
          const {ref, ...restProps} = props;
          return (
            <div className={'input-and-jump-back-a-step-wrapper'} id={`${isMostRecentStep ? 'most-recent-step' : ''}`}>
              <AutosizeInput
                disabled={disabled}
                {...restProps}
                inputRef={ref}
                id={isMostRecentStep ? 'most-recent-step' : ''}
                inputClassName="AutosizeInput-class"
                minWidth={GENERAL_INPUT_DEFAULT_SIZE}
              />
              {
                (input.value) && <ClearStep
                  step={step}
                  stepIndex={stepIndex}
                  jumpBackToStepIndex={jumpBackToStepIndex}
                />
              }
            </div>
          );
        }}
        renderItem={
          (item, isHighlighted) => (
            <div
              className={this.determineOptionClassName(item, isHighlighted)}
              key={item.name}>
              {highlightMatchingPartOfName(item.name, this.state.value, MIN_TEXT_HIGHLIGHT_LENGTH)}
            </div>
          )
        }
        renderMenu={
          (items, value, _) => {
            if(!items || items.length === 0) return <div/>
            return (
            <div className="line-input__typeahead-holder">
              {items}
              {(items.length > 0 && usePlacesAPI) &&
              <img src={poweredByGoogleImage} className="line-input__typeahead-watermark" alt="powered by google" />}
            </div>
          )}
        }
        shouldItemRender={
          (item, value) => {
            if (!usePlacesAPI && value) {
              const name = item.name.toLowerCase();
              return name.indexOf(value.toLowerCase()) !== -1;
            }
            return true;
          }
        }
        onSelect={
          disabled
            ? (() => {})()
            : (value, item) => {
              input.onChange(value)
              this.setState({value})
              handleNextStep(step, step.next_step_id || item.next_step_id, usePlacesAPI ? item : item.value, item.final, fieldId)
            }
        }
      />
    );
  }

  inputFocused(e) {
    const {step, usePlacesAPI} = this.props
    if(get(step, `typeahead_query_url`,'') && !usePlacesAPI) {
      this.fetchItems(get(step, `typeahead_query_url`), e.target.value)
    }
    this.moveCaretToEnd(e)
  }

  moveCaretToEnd (e) {
    const temp_value = e.target.value
    e.target.value = ''
    e.target.value = temp_value
  }

  determineOptionClassName(item, isHighlighted) {
    return isHighlighted ? 'line-input-option-base line-input__step-select-typeahead-row-highlighted' : 'line-input-option-base line-input__step-select-typeahead-row'
  }

  render() {
    const {
      options,
      step,
      intl,
      fieldId
    } = this.props

    return (
      <Element className={'react-scroll-element'} name={`step-${this.props.stepIndex}`}>
        <Field
          name={`field_${fieldId || step.id}`}
          component={this.autocompleteComponent}
          validate={withIntl(intl, required)}
          items={options}
        />
      </Element>
    );
  }
}

StepTypeahead.propTypes = {
  className: PropTypes.string,
  handleNextStep: PropTypes.func.isRequired,
  options: PropTypes.array,
  step: PropTypes.object.isRequired,
  fieldId: PropTypes.string,
  intl: intlShape.isRequired,
  value: PropTypes.string,
  usePlacesAPI: PropTypes.bool,
  sessionToken: PropTypes.string.isRequired,
  jumpBackToStepIndex: PropTypes.func,
  stepIndex: PropTypes.number
};

StepTypeahead.defaultProps = {
  options: [],
};

export default StepTypeahead;
