import React from 'react';

const ArrowRight = props => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.5" stroke="#A5B1A6"/>
    <path d="M16.5 12L7.5 12" stroke="#1F3B21" strokeWidth="1.2"/>
    <path d="M12 7.5L16.5 12L12 16.5" stroke="#1F3B21" strokeWidth="1.2"/>
  </svg>


)


export default ArrowRight;
