import React from 'react'

const Plus = props => (
	<svg
		id="Layer_1"
		version="1.1"
		viewBox="0 0 50 50"
		xmlns="http://www.w3.org/2000/svg"
		stroke="#000000"
		{...props}
	>
		<rect fill="none"/>
		<line fill="none" strokeMiterlimit="10" strokeWidth="2" strokeLinecap="square" x1="9" x2="41" y1="25" y2="25"/>
		<line fill="none" strokeMiterlimit="10" strokeWidth="2" strokeLinecap="square" x1="25" x2="25" y1="9" y2="41"/>
	</svg>
)

export default Plus
