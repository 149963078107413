import React from 'react';

const CloudArrowUp = props => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
	<path d="M14.5264 12.6516L11.8748 9.99997L9.22315 12.6516" stroke="#79897A" strokeWidth="1.2" strokeLinecap="square"/>
	<path d="M11.2746 16.25L11.2746 16.85L12.4746 16.85L12.4746 16.25L11.2746 16.25ZM12.4746 16.25L12.4746 10L11.2746 10L11.2746 16.25L12.4746 16.25Z" fill="#79897A"/>
	<path d="M7.50002 16.85H8.10002V15.65H7.50002V16.85ZM5.62502 16.25L5.62443 16.85H5.62502V16.25ZM7.50002 15.65H5.62502V16.85H7.50002V15.65ZM5.6256 15.65C5.09025 15.6495 4.56113 15.5351 4.07337 15.3144L3.57878 16.4078C4.22159 16.6986 4.9189 16.8493 5.62443 16.85L5.6256 15.65ZM4.07337 15.3144C3.5856 15.0938 3.15035 14.7719 2.79649 14.3702L1.89601 15.1634C2.36235 15.6928 2.93596 16.117 3.57878 16.4078L4.07337 15.3144ZM2.79649 14.3702C2.44264 13.9685 2.17828 13.4961 2.02095 12.9844L0.873939 13.337C1.08127 14.0114 1.42967 14.6339 1.89601 15.1634L2.79649 14.3702ZM2.02095 12.9844C1.86363 12.4726 1.81695 11.9333 1.884 11.4022L0.69345 11.2519C0.605082 11.9519 0.666607 12.6626 0.873939 13.337L2.02095 12.9844ZM1.884 11.4022C1.95105 10.871 2.13031 10.3602 2.40987 9.90369L1.38648 9.27705C1.01806 9.87874 0.781817 10.5519 0.69345 11.2519L1.884 11.4022ZM2.40987 9.90369C2.68943 9.44713 3.0629 9.05525 3.5055 8.75407L2.83039 7.76199C2.2471 8.15891 1.75491 8.67536 1.38648 9.27705L2.40987 9.90369ZM3.5055 8.75407C3.94809 8.45289 4.44968 8.24929 4.97698 8.15678L4.76963 6.97483C4.07471 7.09674 3.41368 7.36506 2.83039 7.76199L3.5055 8.75407ZM4.97698 8.15678C5.50428 8.06428 6.04522 8.08499 6.56391 8.21753L6.86101 7.05489C6.17745 6.88021 5.46455 6.85292 4.76963 6.97483L4.97698 8.15678Z" fill="#1F3B21"/>
	<path d="M6.25 10.0001C6.2501 9.00984 6.48551 8.03375 6.93683 7.15229C7.38815 6.27083 8.04247 5.50923 8.84587 4.93025C9.64926 4.35127 10.5787 3.97148 11.5577 3.82217C12.5367 3.67287 13.5371 3.75833 14.4766 4.07151C15.416 4.38469 16.2676 4.91662 16.9612 5.62347C17.6547 6.33033 18.1704 7.19187 18.4657 8.13711C18.761 9.08234 18.8274 10.0842 18.6596 11.0602C18.4917 12.0361 18.0943 12.9582 17.5002 13.7505" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square" strokeLinejoin="round"/>
</svg>

export default CloudArrowUp;
