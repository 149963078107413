import { renderToString as renderJSXToString } from 'react-dom/server'
import React from 'react'

export const FIRST_PART_OF_GRAPH_STROKE_ID = 'firstPartGraphStroke'
export const LAST_PART_OF_GRAPH_STROKE_ID = 'lastPartGraphStroke'
export const FIRST_PART_OF_GRAPH_FILL_ID = 'firstPartGraphFill'

export const updateGraphStroke = (percentage, prevPercentage, carbonGraphDefsId, gradient = false) => {
    //Update graph stroke only if the percentage to show is different than the previous one
    //We need to do it directly interacting with the dom, as updating the state and forcing a new rerender breaks the graph
    const carbonDefs = document.getElementById(carbonGraphDefsId)
    if(carbonDefs && (prevPercentage !== percentage)) {
      carbonDefs.innerHTML = `
      ${renderJSXToString(getGraphLineInitialPartFill(percentage, gradient))}
        ${renderJSXToString(getGraphLineInitialPartStroke(percentage))}
        ${renderJSXToString(getGraphLineFinalPartStroke(percentage))}}
      `

      return percentage
    }

    return prevPercentage
  }

  export const getGraphLineInitialPartStroke = (percentage) => {
    return(
        <linearGradient id={FIRST_PART_OF_GRAPH_STROKE_ID} x1="0%" y1="0" x2="100%" y2="0">
            <stop offset="0%" stopColor="#60B47D" />
            <stop offset={`${percentage}%`} stopColor="#60B47D" />
            <stop offset={`${percentage}%`} stopColor={"transparent"} />
            <stop offset={`${100}%`} stopColor="transparent" />
        </linearGradient>
    )
  }

  export const getGraphLineInitialPartFill = (percentage, gradient = false) => {
    return(
      <linearGradient id={FIRST_PART_OF_GRAPH_FILL_ID} x1="0" y1="0" x2="1" y2="0">
        <stop offset="0%" stopColor="#60B47D" stopOpacity={gradient ? 0 : 0.6}/>
        <stop offset={`${percentage}%`} stopColor="#60B47D" stopOpacity={gradient ? 0.2 : 0.6}/>
        <stop offset={`${percentage}%`} stopColor="transparent"/>
        <stop offset={`100%`} stopColor="transparent"/>
      </linearGradient>
    )
  }

  export const getGraphLineFinalPartStroke = (percentage) => {
    return (
      <linearGradient id={LAST_PART_OF_GRAPH_STROKE_ID} x1="0%" y1="0" x2="100%" y2="0">
        <stop offset="0%" stopColor="transparent" />
        <stop offset={`${percentage}%`} stopColor="transparent" />
        <stop offset={`${percentage}%`} stopColor="#60B47D" />
        <stop offset={`${100}%`} stopColor="#60B47D" />
    </linearGradient>
    )
  }