import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './TinyInfoBlock.css';
import {FormattedNumber} from "react-intl";
import Tooltip from '../Tooltip/Tooltip';
import Information from '../Icons/Information';

const TinyInfoBlock = ({value, unit, progress, title, className, colored, icon, iconComponent, divider, dividerSeparation, linkText, linkHref, tooltip, tooltipPosition, ctaText, ctaAction}) => {
  const progressBar = progress && (
    <div className="tiny-info-block__progress-bar">
      <div className="tiny-info-block__progress-bar-value" style={{width: `${progress}%`}} />
    </div>
  );

  const iconNode = icon && <span className={`tiny-info-block__icon tiny-info-block__icon--${icon}`} />
  const iconComponentNode = <span className={`tiny-info-block__icon-component-wrapper`}>{iconComponent}</span>
  const itemValue = () => {
      if(!isNaN(value) && unit) {
        return (
          <span className="tiny-info-block__value">
            <FormattedNumber value={value}/> {unit}
          </span>);
      } else {
        return (
          <span className="tiny-info-block__value">
            {value}
          </span>);
      }
  };

  const renderTooltip = (tooltipText) => {
    return (
      <Tooltip
      style={{marginLeft: '4px'}}
      size='medium'
      content={tooltipText}
      position={tooltipPosition}
    >
      <Information/>
    </Tooltip>
    )
  }

  return (
    <div className="tiny-info-block__wrapper">
      <div className={cx('tiny-info-block', {
        'tiny-info-block--with-progress': progressBar,
        'tiny-info-block--colored': colored
      }, className)}>
        <div className="tiny-info-block__top">
          {progressBar}
          {iconNode}
          {iconComponentNode}
          {itemValue()}
          {tooltip && renderTooltip(tooltip)}
          {linkText && linkHref && <a className="tiny-info-block__link" href={linkHref}>{linkText}</a>}
          {ctaText && ctaAction && <span className="tiny-info-block__link-like-cta" onClick={ctaAction}>{ctaText}</span>}
        </div>
        <span className={cx("tiny-info-block__title", {"tiny-info-block__title--with-icon": iconNode})}>{title}</span>
      </div>
      <div style={{display: "flex"}}>
        {divider && (
          <svg className={`divider-vertical divider-vertical-separation--${dividerSeparation}`} preserveAspectRatio='none' viewBox='0 0 1 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M0 0V48' stroke='#A5B1A6' strokeLinecap='square' strokeDasharray='2 5' />
          </svg>)
        }
      </div>
    </div>
  );
};

TinyInfoBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  value: PropTypes.any,
  unit: PropTypes.string,
  progress: PropTypes.number,
  colored: PropTypes.bool,
  icon: PropTypes.string,
  linkText: PropTypes.string,
  linkHref: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  dividerSeparation: PropTypes.oneOf(['S', 'M']),
};

export default TinyInfoBlock;
