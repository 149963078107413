import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl'

import './OrganizationTeam.css';
import SettingHeader from '../../SettingHeader/SettingHeader';
import {settingsMessages} from "../../../../constants/messages"
import api from "../../../../constants/api"
import {getUserRequestHeaders} from "../../../Utils/getUserRequestHeaders"
import Plus from "../../../UI/Icons/Plus"
import Loader from "../../../UI/Loader/Loader"
import InviteNewTeamMember from "./InviteNewTeamMember"
import { enviroment } from '../../../../constants/config';

class OrganizationTeam extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showInviteNewTeamMemberModal: false,
            addTeamMemberFirstName: '',
            addTeamMemberLastName: '',
            addTeamMemberEmail: '',
            addingTeamMember: false,
            loadingTeamMembers: false,
        }

        this.filterReforestumMembersOfTeamMembersInProduction = this.filterReforestumMembersOfTeamMembersInProduction.bind(this)
    }

    handlePressedInviteNewTeamMember() {
        this.setState({showInviteNewTeamMemberModal: true})
    }

    async queryOrgMembers() {
        this.setState({loadingTeamMembers: true})
        const orgId = this.props.org.organization_id
        let result = []
        try {
            result = await fetch(
              `${api.GET_ORGANIZATION_MEMBERS}${orgId}/members`,
              {headers: getUserRequestHeaders()}
            )
            result = await result.json()
        }
        catch(e) {
            console.warn('Error team member query', e)
        }
        this.setState({loadingTeamMembers: false})
        return result
    }

    async getOrgMembers() {
        this.setState({isLoading: true})
        let result = await this.queryOrgMembers()
        this.setState({orgMembers: result, isLoading: false})
    }

    componentDidMount() {
        this.getOrgMembers()
    }

    renderAddTeamMemberButton() {
        const {intl} = this.props
        return <div onClick={() => this.handlePressedInviteNewTeamMember()} className={'organization-team__add-new-team-member-button'}>

            {intl.formatMessage(settingsMessages.orgTeamInviteNewMember)}
            <Plus className={'organization-team__add-new-team-member-icon'}/>
        </div>
    }

    //This filter hides reforestum team members from the organization team members list 
    filterReforestumMembersOfTeamMembersInProduction(member) {
        if(enviroment !== 'production') {
            return true;
        }

        //do not filter if impersonating reforestum
        if(this.props.org && this.props.org.organization_id === 30419) {
            return true;
        }
        
        return (member.email && member.email.indexOf('@reforestum.com') === -1)
    }

    renderOrgMembers() {
        const {intl} = this.props
        let result
        if(this.state.orgMembers) {
            result = this.state.orgMembers
            .filter(this.filterReforestumMembersOfTeamMembersInProduction)
            .map(
              (m, i) => <div className={`organization-team__member ${i % 2 === 0 ? 'organization-team__member__alt' : ''}`}>
                  <div className={'organization-team__member__user'}>
                      <div className={'organization-team__member__avatar'} style={{backgroundImage: `url(${m.avatar_url})`}}/>
                      <div className={'organization-team__member__name'}>
                          {m.name}
                      </div>
                  </div>
                  <div className={'organization-team__member__email'}>
                      {m.email}
                  </div>
              </div>
            )
        }
        return <div className={'organization-team__members'}>
            <div className={'organization-team__members__headings'}>
                <div className={'organization-team__members__heading'}>
                    {intl.formatMessage(settingsMessages.orgTeamHeadingUser)}
                </div>
                <div className={'organization-team__members__heading'}>
                    {intl.formatMessage(settingsMessages.orgTeamHeadingEmail)}
                </div>
            </div>
            {result}
        </div>
    }

    render() {
        const {
            settingName,
            addAlert,
        } = this.props;

        return (
            <div className={'organization-team'}>
                <SettingHeader settingName={settingName}>{this.renderAddTeamMemberButton()}</SettingHeader>
                {this.state.loadingTeamMembers ? <Loader /> : this.renderOrgMembers()}
                <InviteNewTeamMember
                  addAlert={addAlert}
                  orgId={this.props.org.organization_id}
                  showInviteNewTeamMemberModal={this.state.showInviteNewTeamMemberModal}
                  setShowInviteNewTeamMemberModal={(value) => this.setState({showInviteNewTeamMemberModal: value})}
                />
            </div>
        )
    }
}

OrganizationTeam.propTypes = {
    settingName: PropTypes.string,
    addAlert: PropTypes.func,
    updateOrganizationAvatar: PropTypes.func,
    updateOrg: PropTypes.func,
    createNewOrg: PropTypes.func,
    intl: PropTypes.object,
    countries: PropTypes.array,
    updateErrorMessages: PropTypes.string,
};

export default injectIntl(OrganizationTeam);
