import React from 'react';

const Monitor = props => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.334 2H2.66732C1.93094 2 1.33398 2.59695 1.33398 3.33333V10C1.33398 10.7364 1.93094 11.3333 2.66732 11.3333H13.334C14.0704 11.3333 14.6673 10.7364 14.6673 10V3.33333C14.6673 2.59695 14.0704 2 13.334 2Z" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
    <path d="M5.33398 14H10.6673" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
    <path d="M8 11.333V13.9997" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
  </svg>
);

export default Monitor;
