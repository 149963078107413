import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';


import './OrganizationInformation.css';
import SettingHeader from '../../SettingHeader/SettingHeader';
import ProfilePictureSetting from '../../ProfilePictureSetting/ProfilePictureSetting';
import OrganizationInformationForm from './OrganizationInformationForm';

class OrganizationInformation extends Component {


    constructor(props) {
        super(props)

        this.state = {
            newOrgAvatarUrl: null,
            newOrgAvatarForm: null,
        }

        this.storeNewOrgAvatar = this.storeNewOrgAvatar.bind(this)
        this.createNewOrgHandler = this.createNewOrgHandler.bind(this)
        this.updateOrgHandler = this.updateOrgHandler.bind(this)
    }

    storeNewOrgAvatar({imageURL, formData}) {
        this.setState({newOrgAvatarUrl: imageURL, newOrgAvatarForm: formData})
        return Promise.resolve()
    }

    async createNewOrgHandler(newOrg) {
        const {createNewOrg, intl} = this.props
        const {newOrgAvatarForm} = this.state
        return createNewOrg({form: newOrg, avatarForm: newOrgAvatarForm, intl})
    }

    async updateOrgHandler(newOrg) {
        const {updateOrg, intl} = this.props
        return updateOrg({form: newOrg, intl})
    }


    render() {

        const {
            newOrgAvatarUrl
        } = this.state
        const {
            settingName, 
            org,
            addAlert,
            updateOrganizationAvatar,
            refreshProfile,
            countries,
            country,
            createNewOrgScreen,
        } = this.props;
        
        const {
            avatar_url,
            id,
        } = org || {};


        const loadingInitialValues =  !createNewOrgScreen && (countries.length < 1 || Object.keys(org).length < 1)

        const formInitialValues = createNewOrgScreen ? 
        {} : 
        {
            ...org,
            country: country,
            avatar_url: undefined,
        }

        return (
            <div>
                <SettingHeader settingName={settingName}/>
                <ProfilePictureSetting 
                    profilePictureUrl={createNewOrgScreen ? newOrgAvatarUrl : avatar_url}
                    addAlert={addAlert}
                    updateAvatar={createNewOrgScreen ? this.storeNewOrgAvatar : updateOrganizationAvatar}
                    refreshProfile={refreshProfile}
                    id={id}
                    className='organization-information-profile-picture-setting'/>
                <OrganizationInformationForm 
                    initialValues={formInitialValues}
                    countries={countries}
                    loadingInitialValues={loadingInitialValues}
                    updateOrg={createNewOrgScreen ? this.createNewOrgHandler : this.updateOrgHandler}
                    refreshProfile={refreshProfile}
                    updateErrorMessages={undefined}
                    addAlert={addAlert}
                    createNewOrgScreen={createNewOrgScreen}
                />
            </div>
        )
    }
}

OrganizationInformation.propTypes = {
    settingName: PropTypes.string,
    addAlert: PropTypes.func,
    updateOrganizationAvatar: PropTypes.func,
    updateOrg: PropTypes.func,
    createNewOrg: PropTypes.func,
    intl: PropTypes.object,
    countries: PropTypes.array,
    updateErrorMessages: PropTypes.string,
};

export default injectIntl(OrganizationInformation);
