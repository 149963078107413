import React from 'react';
import { Helmet } from 'react-helmet';
import Wrapper from '../components/Layout/Wrapper/Wrapper';
import NewSupplierContainer from '../containers/NewSupplierContainer';
import { Redirect, withRouter } from 'react-router-dom';
import { ZEGNA_UUID } from '../constants/rfpUids';

const NewSupplier = ({match}) => {
  if(match.params.uuid !== ZEGNA_UUID) {
    return <Redirect to="/404" />
  }


  return (
    <Wrapper style={{ paddingTop: '0' }}>
      <Helmet>
        <title>
          New Supplier | Reforestum
        </title>
        <link rel="canonical" href={`https://app.reforestum.com/new-supplier`} />
        <meta name="description" content="Register as a new supplier." />
      </Helmet>

      <NewSupplierContainer/>
    </Wrapper>
  );
};

export default withRouter(NewSupplier);
