import React from 'react';

const CarbonCalculatorLinkIcon = props => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<circle cx="24" cy="24" r="24" fill="#F9CFBF"/>
<path d="M20.5 33.3483H21.1V32.1483H20.5V33.3483ZM17.875 32.7483L17.8744 33.3483H17.875V32.7483ZM20.5 32.1483H17.875V33.3483H20.5V32.1483ZM17.8756 32.1483C17.0921 32.1475 16.3177 31.9801 15.6038 31.6572L15.1092 32.7505C15.9781 33.1436 16.9207 33.3474 17.8744 33.3483L17.8756 32.1483ZM15.6038 31.6572C14.8899 31.3342 14.2529 30.8632 13.735 30.2752L12.8345 31.0684C13.4649 31.784 14.2403 32.3574 15.1092 32.7505L15.6038 31.6572ZM13.735 30.2752C13.2171 29.6872 12.8302 28.9958 12.5999 28.2469L11.4529 28.5996C11.7332 29.5111 12.2041 30.3527 12.8345 31.0684L13.735 30.2752ZM12.5999 28.2469C12.3697 27.498 12.3014 26.7086 12.3995 25.9313L11.2089 25.781C11.0895 26.7272 11.1727 27.688 11.4529 28.5996L12.5999 28.2469ZM12.3995 25.9313C12.4976 25.1539 12.76 24.4063 13.1691 23.7381L12.1458 23.1115C11.6477 23.9248 11.3284 24.8348 11.2089 25.781L12.3995 25.9313ZM13.1691 23.7381C13.5783 23.0699 14.1249 22.4964 14.7727 22.0556L14.0976 21.0635C13.3091 21.6 12.6438 22.2981 12.1458 23.1115L13.1691 23.7381ZM14.7727 22.0556C15.4204 21.6148 16.1546 21.3168 16.9263 21.1814L16.719 19.9994C15.7796 20.1642 14.886 20.5269 14.0976 21.0635L14.7727 22.0556ZM16.9263 21.1814C17.698 21.046 18.4898 21.0763 19.2489 21.2703L19.546 20.1077C18.622 19.8715 17.6583 19.8347 16.719 19.9994L16.9263 21.1814Z" fill="#1F3B21"/>
<path d="M18.75 23.9984C18.7501 22.612 19.0797 21.2454 19.7116 20.0114C20.3434 18.7773 21.2595 17.7111 22.3842 16.9005C23.509 16.09 24.8102 15.5582 26.1808 15.3492C27.5513 15.1402 28.9519 15.2598 30.2672 15.6983C31.5824 16.1367 32.7747 16.8814 33.7456 17.871C34.7166 18.8606 35.4386 20.0668 35.852 21.3901C36.2654 22.7135 36.3584 24.1161 36.1234 25.4824C35.8884 26.8488 35.332 28.1397 34.5003 29.2489" stroke="#1F3B21" stroke-width="1.2" stroke-linecap="square"/>
<path d="M22.9127 27.7107L26.625 23.9983L30.3373 27.7107" stroke="#FAA584" stroke-width="1.2" stroke-linecap="square"/>
<path d="M26.025 32.7483V33.3483H27.225V32.7483H26.025ZM27.225 32.7483V23.9983H26.025V32.7483H27.225Z" fill="#FAA584"/>
</svg>






export default CarbonCalculatorLinkIcon;
