import React from 'react';
import PropTypes from 'prop-types';

import SummaryText from './SummaryText';
import Summary from './Summary';
import BillingForm from './BillingForm';
import Payment from './Payment';

import Container from '../Layout/Container/Container';
import Content from '../Layout/Container/Content';
import COSources from './COSources';
import { CHECKOUT_MODE_BUDGET, CHECKOUT_MODE_AREA } from '../../constants/app';
import { OFFSET_TYPE_CREDIT } from '../../constants/offsetTypes';
import { carbonAmountIn } from '../../utils/carbon';

import './Checkout.css';
import { links } from '@reforestum/shared-utils';
import PartyInUseInformation from "./PartyInUseInformation"

const Checkout = ({
  quantity,
  offsetType,
  billingComplete,
  countries,
  forest,
  isAuthenticated,
  isPaying,
  isCryptoPaying,
  isWorkingAuth,
  makeCryptoPayment,
  makePayment,
  paymentErrors,
  cryptoPaymentErrors,
  refreshProfile,
  signup,
  signupErrorMessages,
  toggleBillingPanel,
  updateErrorMessages,
  updateUser,
  user,
  price,
  isCompany,
  history,
  sources,
  selectedSources,
  selectPaymentType,
  paymentType,
  mode
}) => {

  if (countries.length < 1) {
    return false;
  }

  const { ...initialValues } = user;
  if (typeof initialValues.is_company === 'undefined') {
    initialValues.is_company = false;
  }

  const humanReadableCountryName = () => {
    if (!initialValues.country) {
      return '';
    }
    const initialCountry = countries.find(
      (element) => initialValues.country.toUpperCase() === element.country_code
    );
    return initialCountry.country_name;
  };

  const selectedSourcesData = sources.filter(({ id }) => (
    selectedSources.indexOf(`${id}`) !== -1
  ));

  const backUrl = mode === CHECKOUT_MODE_BUDGET
    ? `${links.getCreateForestUrl(mode)}/?budget=${price.net_amount}`
    : mode === CHECKOUT_MODE_AREA
    ? `${links.getCreateForestUrl(mode)}/?area=${quantity}`
      : `${links.getCreateForestUrl(mode)}/?selectedSources=${selectedSources}`;

  const carbonCaptured = (offsetType === OFFSET_TYPE_CREDIT) ?
    quantity * 1000 :
    carbonAmountIn(forest.co2_years, forest.timeline_formula, quantity);

  return (
    <Container>
      <Content className="checkout-container">

        <div className="checkout-container__l">
          <div className="checkout-container__header">
            Checkout
          </div>
          <SummaryText
            className="checkout-container__text"
            quantity={quantity}
            offsetType={offsetType}
            forestName={forest.name}
            forestLocation={forest.location_desc}
          />

          <span onClick={_ =>history.push(backUrl)}
              className="checkout-container__edit-link">
            Edit
          </span>
  
          <div className="checkout-container__summary">
            <Summary
              image={forest.main_image}
              quantity={quantity}
              offsetType={offsetType}
              offsetPriceFromServer={price}
              forestName={forest.name}
              forestLocation={forest.location_desc}
              pricePerUnit={forest.price.amount}
            />
          </div>

          <COSources
            forestTimelineFormula={forest.timeline_formula} 
            sources={selectedSourcesData}
            carbonCaptured={carbonCaptured}
          />
        </div>

        <div className="checkout-container__r">
          <div className="checkout-container__form">
            {
              (user.organizations && user.organizations.length > 0) &&
              <PartyInUseInformation user={user}/>
            }
            <BillingForm
              billingComplete={billingComplete}
              initialValues={{ ...initialValues, country: humanReadableCountryName() }}
              isAuthenticated={isAuthenticated}
              isWorkingAuth={isWorkingAuth}
              signup={signup}
              signupErrorMessages={signupErrorMessages}
              toggleBillingPanel={toggleBillingPanel}
              updateErrorMessages={updateErrorMessages}
              updateUser={updateUser}
              countries={countries}
              refreshProfile={refreshProfile}
              isCompany={isCompany}
              user={user}
            />
            <Payment
              quantity={quantity}
              billingComplete={billingComplete}
              forestId={forest.id}
              forestName={forest.name}
              isPaying={isPaying}
              isCryptoPaying={isCryptoPaying}
              makePayment={makePayment}
              makeCryptoPayment={makeCryptoPayment}
              paymentErrors={paymentErrors}
              cryptoPaymentErrors={cryptoPaymentErrors}
              selectPaymentType={selectPaymentType}
              price={price.total_amount}
              user={user}
              paymentType={paymentType}
              sources={selectedSourcesData}
            />
          </div>
        </div>

      </Content>
    </Container>
  );
};

Checkout.propTypes = {
  quantity: PropTypes.number.isRequired,
  offsetType: PropTypes.string.isRequired,
  billingComplete: PropTypes.bool.isRequired,
  countries: PropTypes.array.isRequired,
  forest: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isPaying: PropTypes.bool.isRequired,
  isCryptoPaying: PropTypes.bool.isRequired,
  isWorkingAuth: PropTypes.bool.isRequired,
  makePayment: PropTypes.func.isRequired,
  makeCryptoPayment: PropTypes.func.isRequired,
  paymentErrors: PropTypes.string,
  cryptoPaymentErrors: PropTypes.string,
  selectPaymentType: PropTypes.func.isRequired,
  refreshProfile: PropTypes.func.isRequired,
  signup: PropTypes.func.isRequired,
  signupErrorMessages: PropTypes.string,
  toggleBillingPanel: PropTypes.func.isRequired,
  updateErrorMessages: PropTypes.string,
  updateUser: PropTypes.func.isRequired,
  user: PropTypes.object,
  price: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
  sources: PropTypes.array.isRequired,
  selectedSources: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired
};

export default Checkout;
