import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';


import './Preferences.css';
import SettingHeader from '../../SettingHeader/SettingHeader';
import PreferencesForm from './PreferencesForm';

class Preferences extends Component {


    render() {

        const {
            settingName, 
            user,
            addAlert,
            updateUser,
            refreshProfile,
            updateErrorMessages,
        } = this.props;


        const loadingInitialValues =  Object.keys(user).length < 1

        const formInitialValues = user.preferences

        return (
            <div>
                <SettingHeader settingName={settingName}/>
                <PreferencesForm 
                    initialValues={formInitialValues}
                    loadingInitialValues={loadingInitialValues}
                    updateUser={updateUser}
                    refreshProfile={refreshProfile}
                    updateErrorMessages={updateErrorMessages}
                    addAlert={addAlert}
                />
            </div>
        )
    }
}

Preferences.propTypes = {
    settingName: PropTypes.string,
    user: PropTypes.object,
    addAlert: PropTypes.func,
    intl: PropTypes.object,
    updateErrorMessages: PropTypes.string,
};

export default injectIntl(Preferences);
