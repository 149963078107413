import React from 'react';

const CloseCircleCalculator = props => (
  <svg {...props} width="22" height="22" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
  	<g className="svg-cross-circle" fill="none" stroke="none" fillRule="evenodd">
		<circle className="svg-cross-circle__outer" opacity="0.8" cx="16" cy="16" r="16"/>
		<circle fill="#ffffff" opacity="1" cx="16" cy="16" r="14"/>
		<g className="svg-cross-circle__stroke" strokeWidth="2">
			<path d="M10 16 L22.5 16"/>
		</g>
	</g>
  </svg>
);

export default CloseCircleCalculator;
