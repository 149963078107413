import { updateRequestHeaders } from "../actions"
import { refreshProfile } from "../actions/userSession"
import { getUserDetails } from "../selectors/userSession"
import store from "../setup/store"
import { removeFromStorage, saveToStorage } from "./localStorage"
import { posthogGroup, posthogResetGroups } from "./posthog"

export const actAsOrganization = async ({organizationId, actionAfterSwitch}) => {
    saveToStorage("organizationId", organizationId)
    updateRequestHeaders()
    await store.dispatch(refreshProfile())
    posthogGroup(organizationId, getUserDetails(store.getState()))
    actionAfterSwitch ? actionAfterSwitch() : window.location.reload()
}

export const stopActingAsOrganization = async ({actionAfterSwitch}) => {
    removeFromStorage("organizationId")
    updateRequestHeaders()
    await store.dispatch(refreshProfile())
    posthogResetGroups()
    actionAfterSwitch ? actionAfterSwitch() : window.location.reload()
}