import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import Select from 'react-select';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import {
  authMessages,
  settingsMessages,
  formMessages
} from '../../constants/messages';
import { PRIVACYLEVEL } from '../../constants/privacyLevels';

import { passwordStrength, required, email } from '../../utils/formHelpers';
import { withIntl } from '../../utils/intl';

import FeedbackMessage from '../UI/Forms/FeedbackMessage';
import Row from '../UI/Forms/Row';
import RowAligner from '../UI/Forms/RowAligner';
import Input from '../UI/Forms/Input';
import { Button } from '@reforestum/shared-components';
import Small from '../UI/Text/Small';
import { EyeLashes } from '../UI/Icons/EyeLashes';
import Eye from '../UI/Icons/Eye';
import customSelectStyles from '../UI/Forms/customSelectStyles';
import { formatOptionLabel } from '../UI/Forms/customSelectOld';
import AcceptTermsOfServiceCheckbox from './ReusableFormFields/AcceptTermsOfServiceCheckbox';

const validatePassword = (formProps, props) => {
  const { password, passwordConfirm } = formProps;
  const { formatMessage } = props.intl;

  if ( passwordConfirm && (password !== passwordConfirm) ) {
    return formatMessage(formMessages.passwordNotMatch);
  }
}

class UserActivationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
        privacy: PRIVACYLEVEL.PUBLIC,
        selectedOption: this.props.privacyOptions[0],
        togglePasswordVisibility: false,
        togglePasswordConfirmVisibility: false
    }

    this.setPrivacyLevel = this.setPrivacyLevel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTogglePasswordVisibility = this.handleTogglePasswordVisibility.bind(this);
    this.handleTogglePasswordConfirmVisibility = this.handleTogglePasswordConfirmVisibility.bind(this);
    this.submitForm = this.submitForm.bind(this)
  }

  submitForm(form) {
    const { handleUserActivation } = this.props

    handleUserActivation(form);
  }

  setPrivacyLevel(e){
    this.setState({ privacy: Number(e.target.value) })
  };

  handleChange(selectedOption){
    this.setState({ selectedOption })
    this.setState({ privacy: Number(selectedOption.value) })
  }

  handleTogglePasswordVisibility(){
    this.setState({ togglePasswordVisibility: !this.state.togglePasswordVisibility })
  }

  handleTogglePasswordConfirmVisibility(){
    this.setState({ togglePasswordConfirmVisibility: !this.state.togglePasswordConfirmVisibility })
  }

  render() {
    const {
      handleSubmit,
      isUpdating,
      intl,
      errorMessages,
      privacyOptions,
      invalid,
    } = this.props;

    const { privacy, togglePasswordVisibility, togglePasswordConfirmVisibility } = this.state;

    return (
      <form onSubmit={handleSubmit(this.submitForm)}>
        <Row>
          <Field
            name="name"
            disabled={true}
            component={Input}
            label={intl.formatMessage(authMessages.nameLabel)}
            placeholder={intl.formatMessage(authMessages.namePlaceholder)}
            type="text"
            validate={withIntl(intl, required)}
          />
          <Field
            name="surname"
            disabled={true}
            component={Input}
            label={intl.formatMessage(authMessages.surnameLabel)}
            placeholder={intl.formatMessage(authMessages.surnamePlaceholder)}
            type="text"
            validate={withIntl(intl, required)}
          />
        </Row>

        <Row>
          <Field
            name="email"
            disabled={true}
            component={Input}
            label={intl.formatMessage(authMessages.emailLabel)}
            placeholder={intl.formatMessage(authMessages.emailPlaceholder)}
            type="text"
            validate={withIntl(intl, [email, required])}
          />
        </Row>

        {/* Customized Select component */}
        <div style={{ marginTop: "16px" }}>
          <Small
              tag="label"
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',      
                color: "#1F3B21"
              }}>
              {intl.formatMessage(settingsMessages.privacyLabel)}
          </Small>
        </div>
        <Row>
          <Select
            defaultValue={privacyOptions[0]}
            options={privacyOptions}
            onChange={this.handleChange}
            isSearchable={false}
            styles={customSelectStyles}
            formatOptionLabel={formatOptionLabel}
          />
        </Row>

        {privacy === 2 &&
        <Row>
          <Field
            name="nickname"
            disabled={isUpdating}
            component={Input}
            label={intl.formatMessage(settingsMessages.nicknameLabel)}
            placeholder={intl.formatMessage(settingsMessages.nicknamePlaceholder)}
            type="text"
            validate={Number(privacy) > 1 ? withIntl(intl, required) : null}
          />
        </Row>
        }

        <Row className='special__wrapper'>
          <div className='special-wrapper-with-icon'>
            <Field
              className='special-input-with-icon'
              name="password"
              disabled={isUpdating}
              component={Input}
              placeholder={intl.formatMessage(authMessages.passwordPlaceholder)}
              label={intl.formatMessage(authMessages.passwordLabel)}
              type={togglePasswordVisibility ? "text" : "password"}
              validate={withIntl(intl, [required, passwordStrength])}
            />
            {!togglePasswordVisibility
              ? <EyeLashes className='special-input__icon' onClick={this.handleTogglePasswordVisibility}/>
              : <Eye className='special-input__icon' onClick={this.handleTogglePasswordVisibility}/>
            }
          </div>

          <div className='special-wrapper-with-icon'>
            <Field
              className='special-input-with-icon'
              name="passwordConfirm"
              disabled={isUpdating}
              component={Input}
              placeholder={intl.formatMessage(authMessages.repeatPasswordPlaceholder)}
              label={intl.formatMessage(authMessages.repeatPasswordLabel)}
              type={togglePasswordConfirmVisibility ? "text" : "password"}
              validate={withIntl(intl, required)}
            />
            {!togglePasswordConfirmVisibility
              ? <EyeLashes className='special-input__icon' onClick={this.handleTogglePasswordConfirmVisibility}/>
              : <Eye className='special-input__icon' onClick={this.handleTogglePasswordConfirmVisibility}/>
            }
          </div>
        </Row>

        <Row>
          <AcceptTermsOfServiceCheckbox disabled={isUpdating} />
        </Row>

        {
          errorMessages &&
          <FeedbackMessage type="error">{errorMessages}</FeedbackMessage>
        }

        <Row>
          <RowAligner>
            <Button
              disabled={invalid}
              type="submit"
              className='login__button'
              loading={isUpdating}
            >
              <FormattedMessage
                id={'userActivation.submit'}
                defaultMessage={'Create Account'}
              />
            </Button>
          </RowAligner>
        </Row>

      </form>
    );
  }
}

const validate = (formProps, props) => {
  const errors = {};
  errors.passwordConfirm = validatePassword(formProps, props)
  return errors;
};

UserActivationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  intl: intlShape,
  isUpdating: PropTypes.bool.isRequired,
  privacy: PropTypes.oneOf([ 0, 1, 2, '0', '1', '2' ]),
  errorMessages: PropTypes.string,
  activateUser: PropTypes.func.isRequired,
};

export default injectIntl(reduxForm({
  form: 'userActivation',
  validate
})(UserActivationForm));
