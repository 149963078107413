import React, {Component} from 'react';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import {
  authMessages,
  settingsMessages,
  formMessages,
  localizedLinkMessages
} from '../../constants/messages';
import { PRIVACYLEVEL } from '../../constants/privacyLevels';
import { OFFSET_ACTIVATION_TYPES } from '../../constants/enterpriseLanding';
import { passwordStrength, required, email, mustAgree, maxLength } from '../../utils/formHelpers';
import { withIntl } from '../../utils/intl';

import FeedbackMessage from '../UI/Forms/FeedbackMessage';
import Row from '../UI/Forms/Row';
import RowAligner from '../UI/Forms/RowAligner';
import Input from '../UI/Forms/Input';
import Checkbox from '../UI/Forms/Checkbox';
import { Button } from '@reforestum/shared-components';
import SocialButtons from './SocialButtons';
import Small from '../UI/Text/Small';
import { EyeLashes } from '../UI/Icons/EyeLashes';
import Eye from '../UI/Icons/Eye';

import './Login.css';
import CustomSelect from '../UI/Forms/CustomSelectNew';
import { USER_FIELDS_MAX_LENGTH } from '../../constants/userFieldsMaxLength';
import { ORG_FIELDS_MAX_LENGTH } from '../../constants/orgFieldsMaxLength';

const validate = (formProps, props) => {
  const errors = {};
  const { password, passwordConfirm } = formProps;
  const { formatMessage } = props.intl;

  if ( passwordConfirm && (password !== passwordConfirm) ) {
    errors.passwordConfirm = formatMessage(formMessages.passwordNotMatch);
  }

  return errors;
};

class EnterpriseUserActivationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
        togglePasswordVisibility: false,
        togglePasswordConfirmVisibility: false,
        // @DECISION: public option for everyone, until it's set up in the DB/endpoint for cases as BAL (private as default)
        privacy: PRIVACYLEVEL.PUBLIC,
        selectedOption: this.props.privacyOptions[0]
    }

    this.setPrivacyLevel = this.setPrivacyLevel.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTogglePasswordVisibility = this.handleTogglePasswordVisibility.bind(this);
    this.handleTogglePasswordConfirmVisibility = this.handleTogglePasswordConfirmVisibility.bind(this);
  }

  componentDidMount(){
    const {
      activationId,
      orgId,
      offsetTransactionType,
      companyProfileSlug,
      activationStatus
    } = this.props;
    
      mixpanel.track("Page view", {
        "Action": "Enterprise activation signup",
        "Domain": "App",
        "referrerType": "enterpriseApi", 
        "enterpriseOffsetActivationId": activationId,
        "enterpriseOrgId": orgId,
        "enterpriseOffsetActivationType": offsetTransactionType,
        "enterpriseOrgName": companyProfileSlug ? companyProfileSlug.toLowerCase() : null,
        "enterpriseOffsetStatus": activationStatus,
      });
  }
  
  submitForm(form){
    const { handleSuccessfullyRegistration, handleSuccessfullyRegistrationB2b, signup, orgId, offsetTransactionType } = this.props;
    const { privacy } = this.state;

    const signupPayload = { ...form, privacy, orgId }
    //Special case for Mirakl: Create a new org after user signup and activate shares for than organization
    const isB2bRegistration = offsetTransactionType === OFFSET_ACTIVATION_TYPES.B2B2B
    signup(signupPayload, isB2bRegistration ? () => handleSuccessfullyRegistrationB2b({companyName: form.company, privacy: privacy, display_name: form.nickname ? form.nickname : null}) : handleSuccessfullyRegistration);
  }

  setPrivacyLevel(e){
    this.setState({ privacy: Number(e.target.value) })
  }

  handleChange(selectedOption){
    this.setState({ selectedOption })
    this.setState({ privacy: Number(selectedOption.value) })
  }

  handleTogglePasswordVisibility(){
    this.setState({ togglePasswordVisibility: !this.state.togglePasswordVisibility })
  }

  handleTogglePasswordConfirmVisibility(){
    this.setState({ togglePasswordConfirmVisibility: !this.state.togglePasswordConfirmVisibility })
  }

  render(){
    const {
      handleSubmit,
      isLoading,
      intl,
      changeLoginView,
      errorMessages,
      privacyOptions,
      offsetTransactionType,
      invalid,
      activationId,
      handleLogin
    } = this.props;

    const { privacy, selectedOption, togglePasswordVisibility, togglePasswordConfirmVisibility } = this.state;

    return (
      <form onSubmit={handleSubmit(this.submitForm)}>
      {
        errorMessages &&
        <FeedbackMessage type="error">{errorMessages}</FeedbackMessage>
      }
      <SocialButtons activationId={activationId} handleLogin={handleLogin} />
      <Row>
        <Field
          name="name"
          disabled={isLoading}
          component={Input}
          label={intl.formatMessage(authMessages.nameLabel)}
          placeholder={intl.formatMessage(authMessages.namePlaceholder)}
          type="text"
          validate={withIntl(intl, [required, maxLength(USER_FIELDS_MAX_LENGTH.name)])}
        />
        <Field
          name="surname"
          disabled={isLoading}
          component={Input}
          label={intl.formatMessage(authMessages.surnameLabel)}
          placeholder={intl.formatMessage(authMessages.surnamePlaceholder)}
          type="text"
          validate={withIntl(intl, [required, maxLength(USER_FIELDS_MAX_LENGTH.surname)])}
        />
      </Row>

      {/* Special case for B2B2B */}
      {offsetTransactionType === OFFSET_ACTIVATION_TYPES.B2B2B &&
        <Row>
            <Field
              name="company"
              disabled={false}
              component={Input}
              label={intl.formatMessage(authMessages.companyNameLabel)}
              placeholder={intl.formatMessage(authMessages.companyNamePlaceholder)}
              type="text"
              validate={withIntl(intl, [required, maxLength(ORG_FIELDS_MAX_LENGTH.companyName)])}
            />
        </Row> 
      }
  
      <Row>
        <Field
          name="email"
          disabled={isLoading}
          component={Input}
          label={offsetTransactionType === OFFSET_ACTIVATION_TYPES.B2B2B 
            ? intl.formatMessage(authMessages.companyEmailLabel) // Special case for B2B2B
            : intl.formatMessage(authMessages.emailLabel)
          }
          placeholder={offsetTransactionType === OFFSET_ACTIVATION_TYPES.B2B2B 
              ? intl.formatMessage(authMessages.companyEmailPlaceholder) // Special case for B2B2B
              : intl.formatMessage(authMessages.emailPlaceholder)
            }
          type="text"
          validate={withIntl(intl, [email, required])}
        />
      </Row>

      <Row>
        <CustomSelect
          label={intl.formatMessage(settingsMessages.privacyLabel)}
          defaultValue={selectedOption}
          options={privacyOptions}
          onChange={this.handleChange}
          isSearchable={false}
        />
      </Row>

      {privacy === 2 &&
        <Row>
          <Field
            name="nickname"
            disabled={isLoading}
            component={Input}
            label={intl.formatMessage(settingsMessages.nicknameLabel)}
            placeholder={intl.formatMessage(settingsMessages.nicknamePlaceholder)}
            type="text"
            validate={Number(privacy) > 1 ? withIntl(intl, [required, maxLength(ORG_FIELDS_MAX_LENGTH.displayName)]) : null}
          />
        </Row>
      }
  
      <Row>
        <div className='special-wrapper-with-icon'>
          <Field
            className='special-input-with-icon special-input-with-icon2'
            name="password"
            disabled={isLoading}
            component={Input}
            placeholder={intl.formatMessage(authMessages.passwordPlaceholder)}
            label={intl.formatMessage(authMessages.passwordLabel)}
            type={togglePasswordVisibility ? "text" : "password"}
            validate={withIntl(intl, [required, passwordStrength])}
          />
          {!togglePasswordVisibility
            ? <EyeLashes className='special-input__icon special-input__icon2' onClick={this.handleTogglePasswordVisibility}/>
            : <Eye className='special-input__icon special-input__icon2' onClick={this.handleTogglePasswordVisibility}/>
          }
        </div>
        <div className='special-wrapper-with-icon'>
          <Field
            className='special-input-with-icon special-input-with-icon2'
            name="passwordConfirm"
            disabled={isLoading}
            component={Input}
            placeholder={intl.formatMessage(authMessages.repeatPasswordPlaceholder)}
            label={intl.formatMessage(authMessages.repeatPasswordLabel)}
            type={togglePasswordConfirmVisibility ? "text" : "password"}
            validate={withIntl(intl, required)}
          />
          {!togglePasswordConfirmVisibility
            ? <EyeLashes className='special-input__icon special-input__icon2 special-input__icon3' onClick={this.handleTogglePasswordConfirmVisibility}/>
            : <Eye className='special-input__icon special-input__icon2 special-input__icon3' onClick={this.handleTogglePasswordConfirmVisibility}/>
          }
        </div>
      </Row>
  
      <Row>
        <Field
          name="tos"
          disabled={isLoading}
          component={Checkbox}
          label={
            <span>
              <FormattedMessage
                id={'tosMessages.message'}
                defaultMessage={"I agree with Reforestum's"}
              />
              &nbsp;
              <a
                href={intl.formatMessage(localizedLinkMessages.tos)}
                rel="noopener noreferrer"
                target="_blank"
                style={{ color: 'var(--dark-green)'}}
              >
                <FormattedMessage
                  id={'tosMessages.tos'}
                  defaultMessage={'Terms of service'}
                />
              </a>
            </span>
          }
          validate={withIntl(intl, mustAgree)}
        />
      </Row>
  
      <Row>
        <RowAligner>
          <Button
            disabled={invalid}
            type="submit"
            className='login__button'
            loading={isLoading}
          >
            <FormattedMessage
              id={'enterpriseActivation.submit'}
              defaultMessage={'Register'}
            />
          </Button>
        </RowAligner>
      </Row>
  
      <Row>
        <div className='new-cta__wrapper'>
          <Small weight={'regular'}>
            <FormattedMessage 
              id={'Common.hasAccount'}
              defaultMessage={'Already have an account?'}
            />
            <span
              onClick={() => changeLoginView()}
              style={{ color: 'var(--ref-green-300)', marginLeft: '0.3rem', cursor: 'pointer' }}>
              <FormattedMessage
                id={'Header.login'}
                defaultMessage={'Login'}
              />
            </span>
          </Small>
        </div>
      </Row>
    </form>
    )
  }
};

EnterpriseUserActivationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleSuccessfullyRegistration: PropTypes.func.isRequired,
  handleSuccessfullyRegistrationB2b: PropTypes.func.isRequired,
  activationId: PropTypes.string.isRequired,
  intl: intlShape,
  isLoading: PropTypes.bool,
  errorMessages: PropTypes.string,
  signup: PropTypes.func.isRequired,
  privacyOptions: PropTypes.array.isRequired,
  offsetTransactionType: PropTypes.string.isRequired
};

export default injectIntl(reduxForm({
  form: 'enterpriseUserActivation',
  validate
})(EnterpriseUserActivationForm));
