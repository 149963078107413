import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tabs } from 'react-tabs';
import './Tabbed.css';

const Tabbed = ({ theme = 'default', ...props}) => (
    <Tabs
        {...props}
        className={cx(
          'react-tabs',
          `react-tabs--${theme}`
    )} />
);

Tabbed.propTypes = {
  theme: PropTypes.oneOf(['default', 'carbon', 'public-profile'])
};

export default Tabbed;
