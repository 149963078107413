import { axiosV2Instance } from './';
import api from '../constants/api';
import actionTypes from '../constants/actionTypes';
import { getIsFetching } from '../selectors/activationUser';

export const getUserProfile = (token) => ({
  shouldCallAPI: state => !getIsFetching(state),
  callAPI: () => axiosV2Instance.get(`${api.USER_ACTIVATION_DETAILS}/${token}`),
  actions: [
    actionTypes.GET_ACTIVATION_PROFILE_START,
    {
      type: actionTypes.GET_ACTIVATION_PROFILE_COMPLETE,
      payload: response => response.data.data
    },
    {
      type: actionTypes.GET_ACTIVATION_PROFILE_ERROR,
      payload: response => response.message
    }
  ]
});