import Select from 'react-select';
import React from 'react';
import Small from '../Text/Small';

// Custom styles for customize Select (account privacy) component
const customSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'var(--ref-greyscale-300)' : 'none',
      cursor: 'pointer',
      "&:hover": {
        backgroundColor: "var(--ref-greyscale-100)",
      },
    }),
    container: base => ({
      ...base,
      width: "100%"
    }),
    control: () => ({
        "&:hover": {
          border: "1px solid var(--ref-greyscale-600)",
          opacity: 1,
          boxShadow: "0 0 transparent"
        },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 'fit-content',
        width: "100%",
        textAlign: "left",
        cursor: "pointer",
        fontSize: 16,
        borderRadius: "var(--border-radius)",
        padding: "calc(var(--unit) * 1.5)",
        backgroundColor: "var(--ref-greyscale-white)",
        border: "1px solid var(--aluminum)",
        transition: "boxShadow 0.2s ease, border 0.2s ease",
        transform: "translate3d(0,0,0)"
    }),
    input: () => ({
      width: 'auto',
    }),
    valueContainer: base => ({
      ...base,
      padding: 0,
      paddingLeft: 5
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: (base, state) => {
      const transform = state.isFocused ? "rotate(180deg)" : "rotate(0deg)";
      const transition = state.isFocused ? "transform 0.2s" : "transform 0.2s";
      return {...base, transform, transition }
    },
    placeholder: () => ({
      color: "var(--ref-greyscale-600)",
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '-0.0014em',
    })
  };

// used for giving custom styles to Select options
// it receives privacyOptions automatically...
// ...no need no pass them as the argument in the Select element
const formatOptionLabel = (options) => {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div
                style={{
                color: "var(--ref-greyscale-600)",
                fontSize: 14,
                fontWeight: 500
            }}>
                {options.label}
            </div>
            <div
                style={{
                marginTop: "0.3rem",
                color: "var(--ref-greyscale-600)",
                fontSize: 14
                }}>
                {options.customDescription}
            </div>
        </div>
    )
};

const CustomSelect = ({label, ...props}) => {
    return (
        <div>
            {/* Customize Select component */}
            <div style={{ marginBottom: "12px" }}>
                <Small
                    tag="label"
                    style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',            
                    color: "#1F3B21"
                    }}>
                    {label}
                </Small>
            </div>
            <Select
                {...props}
                styles={customSelectStyles}
                formatOptionLabel={formatOptionLabel}
            />
        </div>
    )
}

export default CustomSelect;
