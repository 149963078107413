import actionTypes from '../constants/actionTypes';
import { createReducer } from '../utils/reducersHelpers';

export const PAYMENT_GATEWAY = {
  STRIPE: 'stripe',
  COINBASE: 'coinbase',
};

export default createReducer({
  fetchingPrice: false,
  price: {},
  isBillingComplete: false,
  isPaying: false,
  isCryptoPaying: false,
  isPaymentComplete: false,
  paymentErrors: null,
  invoiceId: null,
  paymentType: PAYMENT_GATEWAY.STRIPE
}, {
  [actionTypes.CHECKOUT_PAY_START]: state => ({
    ...state, isPaying: true,
  }),
  [actionTypes.CRYPTO_CHECKOUT_PAY_START]: state => {
    return {
      ...state, isCryptoPaying: true,
    };
  },
  [actionTypes.CHECKOUT_PAY_COMPLETE]: (state, action) => ({
    ...state,
    isPaying: false,
    isPaymentComplete: true,
    paymentErrors: null,
    invoiceId: action.payload,
  }),
  [actionTypes.CRYPTO_CHECKOUT_PAY_COMPLETE]: (state, action) => ({
    ...state,
    isCryptoPaying: false,
    isCryptoPaymentComplete: true,
    cryptoPaymentErrors: null,
    invoiceId: action.payload,
  }),
  [actionTypes.CHECKOUT_PAY_ERROR]: (state, action) => {
    return {
      ...state,
      isPaying: false,
      isPaymentComplete: false,
      paymentErrors: action.error,
    };
  },
  [actionTypes.CRYPTO_CHECKOUT_PAY_ERROR]: (state, action) => {
    return {
      ...state,
      isCryptoPaying: false,
      isCryptoPaymentComplete: false,
      cryptoPaymentErrors: action.error,
    };
  },
  [actionTypes.CHECKOUT_TOGGLE_BILLING]: state => ({
    ...state,
    isBillingComplete: !state.isBillingComplete
  }),
  [actionTypes.CHECKOUT_RESET]: state => ({
    ...state,
    isBillingComplete: false,
    isPaymentComplete: false,
    paymentErrors: null
  }),
  [actionTypes.SWITCH_PAYMENT_TYPE]: state => ({
    ...state,
    paymentType: state.paymentType === PAYMENT_GATEWAY.STRIPE ? PAYMENT_GATEWAY.COINBASE : PAYMENT_GATEWAY.STRIPE,
  }),
  [actionTypes.PRICE_START] : state => ({
    ...state,
    fetchingPrice: true,
  }),
  [actionTypes.PRICE_COMPLETE] : (state, action) => ({
    ...state,
    fetchingPrice: false,
    price: action.payload,
  }),
  [actionTypes.PRICE_ERROR] : (state, action) => ({
    ...state,
    fetchingPrice: false,
    paymentErrors: action.payload,
  }),
});

export const getIsFetchingPrice = (state) => state.fetchingPrice;
export const getPrice = (state) => state.price;
export const getIsBillingComplete = (state) => state.isBillingComplete;
export const getIsPaying = (state) => state.isPaying;
export const getIsCryptoPaying = (state) => state.isCryptoPaying;
export const getIsPaymentComplete = (state) => state.isPaymentComplete;
export const getIsCryptoPaymentComplete = (state) => state.isCryptoPaymentComplete;
export const getPaymentErrors = (state) => state.paymentErrors;
export const getPaymentType = (state) => state.paymentType;
export const getCryptoPaymentErrors = (state) => state.cryptoPaymentErrors;
export const getInvoiceId = (state) => state.invoiceId;
