import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Container from '../Container/Container';

import './CoverImage.css';

const CoverImage = ({
  className,
  img,
  defaultLandingImage,
  ...other
}) => {
  
  const addDefaultSrc = (ev) => ev.target.src = defaultLandingImage;

  return (
    <Container
      {...other}
      className={cx(className, 'cover-image')}
    >
      <div className="cover-image__image">
        <img
          onError={addDefaultSrc}
          className="left__image"
          src={img}
          alt="forest"
        />
      </div>
    </Container>
  )
};

CoverImage.propTypes = {
  className: PropTypes.string,
  img: PropTypes.string.isRequired
};

export default CoverImage;
