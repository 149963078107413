import React from "react";
import './OrganizationDashboard.css'
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from 'prop-types';
import PortfolioWidget from "./Widgets/PortfolioWidget";
import RfpWidget from "./Widgets/RfpWidget";
import { withRouter } from "react-router-dom";

const OrganizationDashboard = ({
    portfolioWidgetData,
    rfpWidgetData,
    org,
    intl,
}) => {

    const {
        display_name,
    } = org;

    return (
        <div className="organization-dashboard">
            <div className="organization-dashboard-container">
                <h1 className="design-system-text-label-s">
                    <FormattedMessage id="OrganizationGeneralDashboard.title" defaultMessage={'Welcome to {companyName}\'s dashboard!'} values={{companyName: display_name}}/>
                </h1>
                <p className="design-system-text-title-m-regular organization-dashboard-subtitle">
                    <FormattedMessage id="OrganizationGeneralDashboard.subtitle" defaultMessage="It’s time to make a difference"/>
                </p>
                <div className="organization-dashboard-widgets-container">
                    <PortfolioWidget intl={intl} portfolioWidgetData={portfolioWidgetData}/>
                    {rfpWidgetData && <RfpWidget intl={intl} rfpWidgetData={rfpWidgetData}/>}
                </div>
            </div>
        </div>
    )
}

OrganizationDashboard.propTypes  = {
    portfolioWidgetData: PropTypes.object,
    rfpWidgetData: PropTypes.object,
}

export default injectIntl(withRouter(OrganizationDashboard))