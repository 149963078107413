import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage, FormattedNumber } from 'react-intl';

import { setIsImpactTabOpened, setHideMrvSnack, fetchForestMrvGraph, showOnlyGraphControlledRasterSources } from '../actions/forests';
import * as forestSelector from '../selectors/forests';

import Text from '../components/UI/Text/Text';
import ForestSection from '../components/Forests/UI/ForestSection';
import Graph from '../components/Widgets/CarbonGraph/Graph';
import MRVGraph from '../components/Widgets/MrvGraph/MRVGraph';
import MRVGraphFooter from '../components/Widgets/MrvGraph/MRVGraphFooter';
import Chart from '../components/UI/Icons/Chart';
import Tooltip from '../components/UI/Tooltip/Tooltip';
import Information from '../components/UI/Icons/Information';

import NoForestEmptyStateImg from '@reforestum/shared-components/dist/assets/images/no-forests-empty-state.png';

import getFeatureFlags from '../constants/featureFlags';
import { MOBILE_MAX_WIDTH } from '../constants/impactTab';

import getYearsSinceDate from '../utils/getYearsSinceDate';

import './ImpactContainer.css';

class ImpactContainer extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            windowWidth: window.innerWidth,
        }

        this.updateWindowWith = this.updateWindowWith.bind(this);
        this.renderMrvGraph = this.renderMrvGraph.bind(this);
        this.renderMrvGraphAlternativeMobile = this.renderMrvGraphAlternativeMobile.bind(this);

        if(props.isImpactAvailable) {
            props.setIsImpactTabOpened(true);
            props.showOnlyGraphControlledRasterSources(true)
            props.setHideMrvSnack();
        }
    }

    updateWindowWith() {
        this.setState({width: window.innerWidth});
    }

    componentDidMount() {
        const {isImpactAvailable, forestId} = this.props;
        
        this.updateWindowWith();
        window.addEventListener('resize', this.updateWindowWith);

        if(isImpactAvailable) {
            fetchForestMrvGraph(forestId);
        }
    }

    componentWillUnmount() {
        if(this.props.isImpactAvailable){
            this.props.setIsImpactTabOpened(false)
            this.props.showOnlyGraphControlledRasterSources(false)
        };
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    renderMrvGraph(ff) {
        const {forestId, totalVcus, forestName, vcuRegistryUrl, mrvGraph, renderedStandalone} = this.props;
        return (
            <div>
                <MRVGraph renderedWithoutMap={renderedStandalone} mrvGraph={mrvGraph} totalVcus={totalVcus} forestId={forestId} forestName={forestName}/>
                <MRVGraphFooter  mrvGraph={mrvGraph}/>
                {ff.mrvGraphFooterInfo && totalVcus && vcuRegistryUrl &&
                    <div className="impact-container_VCU-container">
                        <div className="impact-container_VCU-container-item">
                            <Chart />
                            <span className="impact-container_VCU-vcus">
                                <FormattedNumber value={totalVcus}/>&nbsp;VCUs
                            </span>
                            <Tooltip
                                position='top'
                                style={{marginLeft: '4px'}}
                                size='medium'
                                content={
                                    <FormattedMessage 
                                        id="ImpactMRV.totalVCUsTooltip"
                                        defaultMessages={'Total amount of Carbon Credits issued in this project by the certifying body to date.'}
                                    /> 
                                }
                            >
                            <Information/>
                            </Tooltip>
                            <a 
                                href={vcuRegistryUrl}
                                rel="noopener noreferrer"
                                target="_blank"
                                className="impact-container_VCU-registries"
                            >
                                <FormattedMessage
                                    id={'ImpactMRV.vcuRegistries'}
                                    defaultMessage={'See registries'}
                                />
                            </a>
                        </div>
                        <div>
                            <span className="impact-container_VCU-legend">
                                <FormattedMessage
                                    id={'ImpactMRV.vcuLegend'}
                                    defaultMessage={'CREDITS ISSUED TO DATE'}
                                />
                            </span>
                        </div>
                    </div>
                }
            </div>
        );
    }

    renderMrvGraphAlternativeMobile() {
        return (
            <div className="my-credits__no-trees-container">
                <img src={NoForestEmptyStateImg} alt="Person watering plants" className="my-credits__no-trees-image" />
                <Text className="my-credits__no-trees-text">
                    <FormattedMessage 
                        id={'ForestDetails.ImpactMrvMobileAlternative'}
                        defaultMessage={"This content is only available on larger screens"}
                    />
                </Text>
            </div>
        )
    }

    render(){
        const { 
            forestName,
            isImpactAvailable,
            formula,
            years,
            surface,
            plantingDate,
            intl
        } = this.props

        const {
            windowWidth
        } = this.state;

        const showNormalGraph = formula && !isImpactAvailable;
        const noImpactAvailable = !formula && !isImpactAvailable;

        const plantingDatePeriod = plantingDate ? getYearsSinceDate(Date.parse(plantingDate)) : null;

        const { locale } = intl;

        const ff = getFeatureFlags();

        return (
            <div className="impact-container-info__wrapper">
                {isImpactAvailable &&
                    (
                        (MOBILE_MAX_WIDTH < windowWidth) ?
                        this.renderMrvGraph(ff) :
                        this.renderMrvGraphAlternativeMobile()
                    )
                }
                {showNormalGraph &&
                    <ForestSection>
                        <Graph
                            className="forest-info__graph"
                            years={years|| 25}
                            formula={formula}
                            surface={surface}
                            forestName={forestName}
                            plantingDatePeriod={plantingDatePeriod}
                            locale={locale}
                        />
                    </ForestSection>
                }
                {noImpactAvailable &&
                    <div className="my-credits__no-trees-container">
                    <img src={NoForestEmptyStateImg} alt="Person watering plants" className="my-credits__no-trees-image" />
                    <Text className="my-credits__no-trees-text">
                    <FormattedMessage 
                        id={'ForestDetails.noImpactTabInformation'}
                        defaultMessage={"{forest} will be tracked soon on Reforestum's platform"}
                        values={{ forest: forestName }} 
                    />
                    </Text>
                </div>
                }
            </div>
        )
    }
}

ImpactContainer.propTypes = {
    forestName: PropTypes.string.isRequired,
    forestId: PropTypes.number,
    setIsImpactTabOpened: PropTypes.func.isRequired,
    showOnlyGraphControlledRasterSources: PropTypes.func.isRequired,
    setHideMrvSnack: PropTypes.func.isRequired,
    isImpactAvailable: PropTypes.bool,
    formula: PropTypes.string.isRequired,
    surface: PropTypes.number.isRequired,
    years: PropTypes.number,
    totalVcus: PropTypes.number,
    vcuRegistryUrl: PropTypes.string,
    renderedStandalone: PropTypes.bool,
    intl: intlShape
};

const mapStateToProps = (state) => ({
    mrvGraph: forestSelector.getMrvGraph(state),
  });

export default connect(mapStateToProps, {
  setIsImpactTabOpened,
  showOnlyGraphControlledRasterSources,
  setHideMrvSnack,
  fetchForestMrvGraph
})(injectIntl(ImpactContainer));
