import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Button } from '@reforestum/shared-components';
import Close from '../Icons/Close';
import Overlay from '../Overlay/Overlay';
import Text from '../Text/Text';

import './ModalDefault.css';

const ModalDefault = ({
  hideModal,
  imageSrc,
  imageAlt = '',
  title,
  text,
  confirmButtonText,
  cancelButtonText,
  onConfirm = () => {},
  onReject = () => {},
  hideAfterConfirm = true,
  hideAfterReject = true,
  iconComponent,
  footerTextComponent,
  location,
}) => {
  const handleConfirm = hideAfterConfirm
    ? () => { onConfirm(); hideModal(); }
    : onConfirm;

  const handleReject = hideAfterReject
    ? () => { onReject(); hideModal(); }
    : onReject;

  const Icon = iconComponent;
  const FooterText = footerTextComponent;
  return (
    <Overlay wrapperClassName="modal-default" onClick={hideModal}>
      <div className="modal-default__box">
        {imageSrc && (
          <img src={imageSrc} className="modal-default__image" alt={imageAlt} />
        )}

        {iconComponent && <Icon className="modal-default__icon" />}

        {title && (
          <Text tag="h2" className="modal-default__title">
            {title}
          </Text>
        )}
        {text && (
          <Text tag="p" color="light" className="modal-default__text">
            {text}
          </Text>
        )}

        {(cancelButtonText || confirmButtonText) && (
          <footer className="modal-default__footer">
            <div className="modal-default__footer-buttons">
              {cancelButtonText && (
                <Button className="btn--blank" onClick={handleReject}>
                  {cancelButtonText}
                </Button>
              )}
              {confirmButtonText && (
                <Button onClick={handleConfirm}>
                  {confirmButtonText}
                </Button>
              )}
            </div>

            {footerTextComponent && (
              <div className="modal-default__footer-link">
                <FooterText hideModal={hideModal} location={location} />
              </div>
            )}
          </footer>
        )}
      </div>
      <Close className="modal-default__close" onClick={hideModal} />
    </Overlay>
  );
};

ModalDefault.propTypes = {
  hideModal: PropTypes.func.isRequired,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  buttonLinkProps: PropTypes.object,
  cancelButtonText: PropTypes.string,
  hideAfterConfirm: PropTypes.bool,
  iconComponent: PropTypes.func,
  footerTextComponent: PropTypes.func,
};

export default withRouter(ModalDefault);
