import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { links } from '@reforestum/shared-utils';

import Wrapper from '../components/Layout/Wrapper/Wrapper';
import MyForestsContainer from '../containers/MyForestsContainer';
import MobileTopbar from '../components/Header/MobileTopbar/MobileTopbar';
import MySectorContainer from '../containers/MySectorContainer';
import { connect } from 'react-redux';

import {
  fetchForestDetails,
  fetchMyForests
} from '../actions/forests';
import * as selector from '../selectors/forests';
import Loader from '../components/UI/Loader/Loader';
import { withRouter } from 'react-router';

class MyForests extends Component {

  componentWillMount() {
    const {
      match: { params: { id: forestId }},
      fetchForestDetails,
      fetchMyForests,
    } = this.props;

    fetchMyForests();
    forestId && fetchForestDetails(forestId);
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params: { id: forestId }},
      fetchForestDetails,
    } = this.props;

    const {
      match: { params: { id: prevForestId }},
    } = prevProps

    if(forestId !== prevForestId) fetchForestDetails(forestId)
  }

  render() {
    const {
      match: { params: { id: forestId }},
      selectedForest
    } = this.props;
  
    let navigateToSector;
    let sectorId;

    if(forestId && !selectedForest) {
      return <Loader/>
    }

    if(selectedForest && selectedForest.navigate_to_sector) {
      navigateToSector = true;
      sectorId = selectedForest.navigate_to_sector;
    }
  
    const generateSeoData = () => {
      // All Projects
      let title = 'Projects' 
      let description = 'Tailor-made solutions in the best-in-class certified forestry projects to offset your emissions according to your corporate needs.'
      let img = 'https://images.prismic.io/reforestumcom/18d8e2bc-f05a-4b32-ae4b-ea69cc30d7ae_large-Reforestum+%E2%80%93+WebsiteThumbnail.png?auto=compress,format'
      let imgAlt = 'Worker developing an afforestation project'
      const canonical = `https://app.reforestum.com${links.getProjectUrl(forestId, sectorId)}`
      
      // Specific Project
      if(forestId){
        img = selectedForest.main_image
        imgAlt = selectedForest.description
        title = selectedForest.name
        description = selectedForest.short_desc
      }

      return { title, description, img, imgAlt, canonical } 
    }
    
    const seoData = generateSeoData() 

    const renderContainer = () => {
      if(navigateToSector){
        return <MySectorContainer isForestLevel selectedForestId={forestId} sectorId={sectorId} {...this.props} />
      }
      return <MyForestsContainer {...this.props} />
    }

    return (
      <Wrapper>
        <Helmet>
          {/* ---- CANONICAL ---- */}
          <link rel="canonical" href={seoData.canonical} />
          
          {/* ---- SEO ---- */}
          <title>{seoData.title} | Reforestum</title>
          <meta name="description" content={seoData.description} />
        
          {/* ---- SHARING ---- */}
          {/* SHARING: Essential META Tags */}
          <meta property="og:title" content={seoData.title} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={seoData.img} />
          <meta property="og:url" content={seoData.canonical} />
          <meta name="twitter:card" content="summary_large_image" />
          {/* SHARING: Non-Essential */}
          <meta property="og:description" content={seoData.description} />
          <meta property="og:site_name" content={seoData.title} />
          <meta name="twitter:image:alt" content={seoData.imgAlt} />
          {/* SHARING: Non-Essential (Analytics Related) */}
          <meta name="twitter:site" content="@reforestum" />                
        </Helmet>
        <MobileTopbar>
          <FormattedMessage
            id="Header.projects"
            defaultMessage={'Explore projects'}
          />
        </MobileTopbar>
        {renderContainer(navigateToSector)}
      </Wrapper>
    );

  }
}

const mapStateToProps = (state, ownProps) => ({
  detailErrorMessages: selector.getDetailsErrorMessages(state),
  errorMessages: selector.getErrorMessages(state),
  isFetching: selector.getIsFetching(state),
  isFetchingTrees: selector.getIsFetchingTrees(state),
  myForests: selector.getMyForests(state),
  selectedForest: selector.getForestById(state, ownProps.match.params.id),
  trees: selector.getForestTrees(state, ownProps.match.params.id),
});

export default withRouter(connect(mapStateToProps,{
  fetchForestDetails,
  fetchMyForests
})(MyForests));
