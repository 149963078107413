import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ForestSection from '../UI/ForestSection';
import ForestTitle from '../UI/ForestTitle';
import PatronsPodium from './PatronsPodium';
import PatronsList from './PatronsList';
import Pagination from '../../UI/Pagination/Pagination';

const Patrons = ({
  data,
  registry,
  getMore,
  isFetching,
  page,
  pageLength,
  patrons,
  podium,
  user,
  isSector=false
}) => (
  <div>
    {!isSector && podium &&
      <ForestSection>
        <ForestTitle>
          <FormattedMessage
            id={'ForestDetails.patronsTitle'}
            defaultMessage={'Leaderboard'}
          />
        </ForestTitle>
          <PatronsPodium
            patrons={podium}
            forestType={data.forest_type}
            registry={registry}
          />
      </ForestSection> 
    }

    <ForestSection>
      <div style={{opacity: isFetching ? '0.5' : '1'}}>
        <PatronsList
          patrons={patrons}
          selfPosition={data.self_rank}
          selfAmount={data.self_amount}
          forestType={data.forest_type}
          registry={registry}
          user={user}
        />
      </div>
    </ForestSection>

    { data.total ?
      (data.total > pageLength) &&           
        <ForestSection>       
          <Pagination
            current={page}
            getMore={getMore}
            pageLength={pageLength}
            total={data.total}
            maxItems={5}
          />
        </ForestSection>
    :
      <FormattedMessage
        id={'ForestDetails.patronsEmpty'}
        defaultMessage={'There are no reforesters yet! :('}
      />
    }
  </div>
);

Patrons.propTypes = {
  data: PropTypes.object.isRequired,
  registry: PropTypes.string,
  getMore: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  pageLength: PropTypes.number.isRequired,
  patrons: PropTypes.array.isRequired,
  podium: PropTypes.array.isRequired,
  user: PropTypes.object,
  isSector: PropTypes.bool
};

export default Patrons;
