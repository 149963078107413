import { axiosV1Instance } from './';

// constants
import actionTypes from '../constants/actionTypes';
import api from '../constants/api';

// Last purchase - retrieve specific information about sector too
export const fetchLastPurchaseInformation = () => ({
    shouldCallAPI: () => true,
    callAPI: () => axiosV1Instance.get(`${api.LAST_PURCHASE}`),
    actions: [
      actionTypes.LAST_PURCHASE_START,
      {
        type: actionTypes.LAST_PURCHASE_COMPLETE,
        payload: response => response.data.data
      },
      {
        type: actionTypes.LAST_PURCHASE_ERROR,
        payload: response => response.error
      }
    ]
});
