import React from 'react';

const OrganizationsLinkIcon = props => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<circle cx="24" cy="24" r="24" fill="#C4E5EA"/>
<g clip-path="url(#clip0_1778_14630)">
<path d="M11.9717 37.1765V24.9224L16.7232 29.0993V24.9224L21.6987 29.0861V24.9224L25.5861 28.3436" stroke="#478EA0" stroke-width="1.2"/>
<path d="M37.0123 37.1763V12.6681H25.5815V37.1763" stroke="#1F3B21" stroke-width="1.2" stroke-linecap="square"/>
<path d="M25.5816 12.6681H22.5881V21.358" stroke="#1F3B21" stroke-width="1.2" stroke-linecap="square"/>
<path d="M28.7717 14.9081V16.4959" stroke="#478EA0" stroke-width="1.2" stroke-linecap="square"/>
<path d="M32.7156 14.9081V16.4959" stroke="#478EA0" stroke-width="1.2" stroke-linecap="square"/>
<path d="M30.74 18.67V20.2578" stroke="#478EA0" stroke-width="1.2" stroke-linecap="square"/>
<path d="M34.8333 23.3412V24.929" stroke="#478EA0" stroke-width="1.2" stroke-linecap="square"/>
<path d="M14.9314 17.9256C16.952 17.9256 18.59 16.3358 18.59 14.3746C18.59 12.4134 16.952 10.8235 14.9314 10.8235C12.9107 10.8235 11.2727 12.4134 11.2727 14.3746C11.2727 16.3358 12.9107 17.9256 14.9314 17.9256Z" stroke="#478EA0" stroke-width="1.2" stroke-linecap="square"/>
</g>
<defs>
<clipPath id="clip0_1778_14630">
<rect width="28" height="28" fill="white" transform="translate(10 10)"/>
</clipPath>
</defs>
</svg>





export default OrganizationsLinkIcon;
