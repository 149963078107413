import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { links } from '@reforestum/shared-utils';

import Wrapper from '../components/Layout/Wrapper/Wrapper';
import DashboardContainer from '../containers/DashboardContainer';
import MobileTopbar from '../components/Header/MobileTopbar/MobileTopbar';
import OrganizationDashboardContainer from '../containers/OrganizationDashboardContainer';
import OrganizationPortfolioDashboardContainer from '../containers/OrganizationPortfolioDashboardContainer';
import USER_TYPE from '../constants/userType';
import { getUserType } from '../utils/usersAndOrganizations';

const Dashboard = (props) => {
  const dashboardToShow = () => {
    const userType = getUserType();
    switch(userType) {
      case USER_TYPE.NEW_MODEL_ORGANIZATION:
        return <OrganizationDashboardContainer {...props} />;
      case USER_TYPE.ORGANIZATION:
        return <OrganizationPortfolioDashboardContainer />;
      default:
        return <DashboardContainer {...props} />;
     }
  }
  return (
    <Wrapper>
      <Helmet>
        <title>
          Dashboard | Reforestum
        </title>
        <link rel="canonical" href={`https://app.reforestum.com${links.getDashboardUrl()}`} />
        <meta name="description" content="Together, we can stop climate change through massive reforestation! Manage your carbon footprint and create & track forests from your dashboard." />
      </Helmet>
      <MobileTopbar>
        <FormattedMessage
          id={'Header.dashboard'}
          defaultMessage={'Dashboard'}
        />
      </MobileTopbar>
      {dashboardToShow()}
    </Wrapper>
  );
};

export default Dashboard;
