import get from 'lodash/get'

const languageIs = (lang, userDetails) => {
  let result = false
  if(get(userDetails, 'preferences.lang')) {
    result = get(userDetails, 'preferences.lang') === lang
  }
  else {
    result = navigator.language.substr(0,2 ) === lang.substr(0, 2)
  }
  return result
}

export {languageIs}
