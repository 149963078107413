import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Redirect } from "react-router";

import mixpanel from 'mixpanel-browser';

import { injectIntl, intlShape } from 'react-intl';

import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

// setup
import history from '../setup/history';
import store from '../setup/store';

// utils
import { links } from '@reforestum/shared-utils';
import getIsAutheticatedFromStore from '../utils/getIsAutheticatedFromStore';

// actions
import { addAlert } from '../actions/alerts';
import { getUserProfile } from '../actions/userActivation';
import { signup } from '../actions/userActions';

// selectors
import { getUserDetails, getIsUserAuthenticated } from '../selectors/userSession';
import { getIsLoading, getSignupErrorMessages } from '../selectors/userActions';
import { getUserActivationDetails, getAccountActivationError } from '../selectors/activationUser';

// constants
import { UserActivationAlerts } from '../constants/messages';

// components
import UserActivation from '../components/Auth/UserActivation';
import Loader from '../components/UI/Loader/Loader';

class AccountActivationContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      showActivationForm: true,
      userDetails: null,
    }

    this.handleSuccessfulRegistration = this.handleSuccessfulRegistration.bind(this)
  }

  componentWillMount() {
    const { isAuthenticated, getUserProfile, intl } = this.props;

    this.activationToken = this.props.match.params.token;

    if (isAuthenticated) {
      store.dispatch(addAlert({
        type: 'warning',
        message: intl.formatMessage(UserActivationAlerts.userLoggedIn),
        dismissAfter: 6000
      }))
      return history.push(links.getHomeUrl());
    }

    getUserProfile(this.activationToken);
  }

  componentWillReceiveProps(newProps) {
    const { intl } = this.props;
    const { isAuthenticated, errorMessages, userActivationDetails } = newProps;

    if (isAuthenticated || !this.activationToken) {
      history.push(links.getHomeUrl());
    }

    if(errorMessages){
      store.dispatch(addAlert({
        type: 'error',
        message: intl.formatMessage(UserActivationAlerts.getAccountActivationError),
        dismissAfter: 8000
      }))
      return history.push(links.getHomeUrl());
    }

    if(userActivationDetails && userActivationDetails.activated){
      store.dispatch(addAlert({
        type: 'error',
        message: intl.formatMessage(UserActivationAlerts.accountAlreadyActivated),
        dismissAfter: 8000
      }))

      return history.push(links.getHomeUrl())
    }

    if(userActivationDetails){
      this.setState({ userDetails: userActivationDetails })

      mixpanel.track("Page view", {
        "Authenticated": getIsAutheticatedFromStore(),
        "Action": "User activation",
        "Campaign": userActivationDetails.campaign_name,
        "userActivationToken": this.activationToken,
        "Domain": "App"
      });
    }
  }

  handleSuccessfulRegistration(){
    if(!this.props.signupErrorMessages){
      store.dispatch(addAlert({
        type: 'success',
        message: this.props.intl.formatMessage(UserActivationAlerts.successfullyRegistered),
        dismissAfter: 8000
      }))
      history.push(links.getCalculatorUrl())
    }
  }

  handleUserActivation(form){
    const payload = Object.assign(form, { activationToken: this.activationToken })
    this.props.signup(payload, this.handleSuccessfulRegistration)
  }

  setShowActivationForm(){
    this.setState({ showActivationForm: true })
  }

  render() {
    const {
      isUpdating,
      privacy,
      errorMessages,
     } = this.props;

    const { showActivationForm, userDetails } = this.state;

    if(errorMessages){
      return (
        <Redirect replace to="/404" />
      )
    }

    if(!userDetails){
      return <Loader />;
    }

    return (
      showActivationForm && userDetails &&
        <UserActivation
          isUpdating={isUpdating}
          user={userDetails}
          privacy={privacy}
          errorMessages={errorMessages}
          handleUserActivation={this.handleUserActivation.bind(this)}
        />
    );
  }
}

const AccountActivationFormSelector = formValueSelector('userActivation');

const mapStateToProps = state => ({
  isAuthenticated: getIsUserAuthenticated(state),
  isUpdating: getIsLoading(state),
  user: getUserDetails(state),
  userActivationDetails: getUserActivationDetails(state),
  privacy: AccountActivationFormSelector(state, 'privacy'),
  errorMessages: getAccountActivationError(state),
  signupErrorMessages: getSignupErrorMessages(state),
});

AccountActivationContainer.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  getUserActivationDetails: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  privacy: PropTypes.oneOf([ 0, 1, 2, '0', '1', '2' ]),
  errorMessages: PropTypes.string,
  activateUserProfile: PropTypes.func.isRequired,
  intl: intlShape
};

export default connect(
  mapStateToProps,
  {
    getUserProfile,
    signup
  }
)(injectIntl(AccountActivationContainer));
