import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import cx from 'classnames';

import { getFormattedWeight } from '../../../utils/units';

import HeadingSmall from '../../UI/Text/HeadingSmall';
import Huge from '../../UI/Text/Huge';
import FormattedUnit from '../../Utils/FormattedUnit';

const GraphHeader = ({ totalCarbon, className, me, hasVCUs, hasTrees }) => (
  <div className={cx(className, 'carbon-graph__header')}>
    <HeadingSmall>
      {
        me ?
          <FormattedMessage
            id={'CarbonGraph.graphTitle'}
            defaultMessage={"My forest CO₂ Capture"}
          />
      :
          <FormattedMessage
              id={'CarbonGraph.general.graphTitle'}
              defaultMessage={'CO₂ Capture'}
          />
      }
    </HeadingSmall>

    <Huge color="green" className="carbon-graph__header-amount">
      <FormattedNumber
        value={getFormattedWeight(totalCarbon).value}
      /> <FormattedUnit unit={getFormattedWeight(totalCarbon).unit} />
    </Huge>

    <HeadingSmall color="light" tag="p">
        { hasVCUs && hasTrees ?
          <FormattedMessage
            id={'CarbonGraph.capturedByVCUsAndTrees'}
            defaultMessage={'Combined tree planting and emission reductions'}
          />
          :
            ( hasVCUs ?
            <FormattedMessage
                id={'CarbonGraph.capturedByVCUs'}
                defaultMessage={'From emission reductions'}
            />
            :
            <FormattedMessage
                id={'CarbonGraph.capturedByTrees'}
                defaultMessage={'From tree planting'}
            />
          )
        }
    </HeadingSmall>
  </div>
);

GraphHeader.propTypes = {
  totalCarbon: PropTypes.number.isRequired,
  hasVCUs: PropTypes.bool.isRequired,
  hasTrees: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  me: PropTypes.bool,
};

export default GraphHeader;
