import React, { Component } from 'react';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import history from '../setup/history';
import { links } from '@reforestum/shared-utils'

import { requestRecovery, resetPassword } from '../actions/userActions';
import * as authSelector from '../selectors/userActions';
import * as userSelector from '../selectors/userSession';
import * as UISelector from '../selectors/UI';

import Recovery from '../components/Auth/Recovery';
import getIsAutheticatedFromStore from '../utils/getIsAutheticatedFromStore';

import { authenticate } from '../actions/userSession';
import { AUTH_METHOD } from '../constants/authMethods';

class RecoveryContainer extends Component {

  componentDidMount() {
    mixpanel.track("Page view", {
      "Authenticated": getIsAutheticatedFromStore(),
      "Action": "Recovery password",
      "Domain": "App"
    });
  }

  getAccountActivationParameters() {
    const params = new URLSearchParams(this.props.history.location.search)
    return {
      token: params.get('token') || '',
      org: params.get('o') || '',
      type: params.get('t') || '',
      //replace spaces in url by + symbols
      //for some reason the URL decoding is interpreting + as space
      email: params.get('email') ? params.get('email').replace(/ /g, '+') : null,
    }
  }

  componentWillMount() {
    const { isAuthenticated, location } = this.props;

    if (isAuthenticated) {
      history.push(links.getHomeUrl());
    }

    this.recoveryToken = new URLSearchParams(location.search).get('token');
  }

  componentWillReceiveProps(newProps) {
    const { isAuthenticated } = newProps;

    if (isAuthenticated) {
      history.push(links.getHomeUrl());
    }
  }

  handleRecovery({ email }) {
    this.props.requestRecovery(email);
  }

  handleReset({ password }) {
    this.props.resetPassword(this.recoveryToken, password).then(() => {
      //If is activation flow, login after reset
      const activationParameters = this.getAccountActivationParameters()
      if(activationParameters.type && activationParameters.email) {
        this.props.authenticate({ username: activationParameters.email, password }, AUTH_METHOD.FORM, links.getHomeUrl())
      }
    })
  }

  handleRegisterRedirection(){
    history.push(links.getRegisterUrl())
  }

  render() {
    const {
      recoveryErrorMessages,
      resetErrorMessages,
      isLoading,
      isPasswordReset,
      isRecoveryRequested,
    } = this.props;

    return (
      <Recovery
        activationParameters={this.getAccountActivationParameters()}
        recoveryErrorMessages={recoveryErrorMessages}
        resetErrorMessages={resetErrorMessages}
        handleRecovery={this.handleRecovery.bind(this)}
        handleReset={this.handleReset.bind(this)}
        handleRegisterRedirection={this.handleRegisterRedirection.bind(this)}
        isLoading={isLoading}
        isPasswordReset={isPasswordReset}
        isRecoveryRequested={isRecoveryRequested}
        recoveryToken={this.recoveryToken}
      />
    );
  }
}

const mapStateToProps = state => ({
  recoveryErrorMessages: authSelector.getRecoveryErrorMessages(state),
  resetErrorMessages: authSelector.getResetErrorMessages(state),
  isAuthenticated: userSelector.getIsUserAuthenticated(state),
  isLoading: authSelector.getIsLoading(state),
  isPasswordReset: UISelector.getIsPasswordReset(state),
  isRecoveryRequested: UISelector.getIsRecoveryRequested(state),
});

RecoveryContainer.propTypes = {
  recoveryErrorMessages: PropTypes.string,
  resetErrorMessages: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPasswordReset: PropTypes.bool.isRequired,
  isRecoveryRequested: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  requestRecovery: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  { requestRecovery, resetPassword, authenticate }
)(RecoveryContainer);
