import React, { Component } from 'react'
import Wrapper from '../components/Layout/Wrapper/Wrapper'
import { Helmet } from 'react-helmet'
import MobileTopbar from '../components/Header/MobileTopbar/MobileTopbar'
import RequestForProposalsResults from '../containers/RequestForProposalsResults'
import {getUserDetails} from '../selectors/userSession'
import {connect} from 'react-redux'

class RequestForProposalsResultsView extends Component {
  render() {
    return (
      <Wrapper>
        <Helmet>
          <title>Request For Proposal Results | Reforestum</title>
          <link rel="canonical" href={`https://app.reforestum.com/rfp/0}`} />
          <meta name="description" content="The results of your requests for proposals" />
        </Helmet>
        <MobileTopbar></MobileTopbar>
        <RequestForProposalsResults user={this.props.user} />
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: getUserDetails(state),
  }
}

export default connect(mapStateToProps)(RequestForProposalsResultsView)