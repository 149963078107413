import Tooltip from "../components/UI/Tooltip/Tooltip"
import React from 'react'

const renderTooltipForTrimmedText = ({text, maxLength, trimmedUpperCase = false, noWrap = false}) => (
    <Tooltip
      content={text}
      position={'bottom'}
      style={{maxWidth: '100%'}}
    >
      <span
        style={{textTransform: (trimmedUpperCase ? 'uppercase' : ''), textWrap: noWrap ? 'nowrap' : ''}}>{text.substring(0, maxLength).concat('...')}</span>
    </Tooltip>
  )

  export default renderTooltipForTrimmedText