import React from 'react';

const ExternalLink = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.5734 6.47607V2.66655H9.76392" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
    <path d="M13.9978 3.09077C14.2321 2.85645 14.2321 2.47655 13.9978 2.24224C13.7634 2.00793 13.3835 2.00793 13.1492 2.24224L13.9978 3.09077ZM7.81562 7.57584L7.39135 8.00011L8.23988 8.84864L8.66414 8.42437L7.81562 7.57584ZM13.1492 2.24224L7.81562 7.57584L8.66414 8.42437L13.9978 3.09077L13.1492 2.24224Z" fill="#1F3B21"/>
    <path d="M6.64657 2.6665H3.89324C3.06849 2.6665 2.3999 3.34874 2.3999 4.19031V11.8094C2.3999 12.6509 3.06849 13.3332 3.89324 13.3332H12.1066C12.9313 13.3332 13.5999 12.6509 13.5999 11.8094V9.76174" stroke="#1F3B21" strokeWidth="1.2"/>
  </svg>
)

export default ExternalLink;