import React from 'react';
import './NewSupplierProposalContainer.css';
import Heading from '../components/UI/Text/Heading';
import MobileTopbar from '../components/Header/MobileTopbar/MobileTopbar';
import { FormattedMessage } from 'react-intl';

const NewSupplierProposalContainer = () => {

  const iframeSrc = "https://form.jotform.com/231904949025358"
  // pass search params to iframe to set defaults
  const searchParams = new URLSearchParams(window.location.search);
  const iframeSrcWithQueryParams = new URL(iframeSrc);
  iframeSrcWithQueryParams.search = searchParams.toString();

  return (
    <div className="new-supplier-proposal_top_div">
      <MobileTopbar>
        <FormattedMessage
          id={'Header.newSupplierProposalForm'}
          defaultMessage={'New Supplier Proposal'}
        />
      </MobileTopbar>
      <div className="new-supplier-proposal_content_wrapper">
        <div className="new-supplier-proposal_header_wrapper">
          <Heading className="new-supplier-proposal_title">
            New Project Proposal for Zegna
          </Heading>
        </div>
        <div className="new-supplier-proposal_form_wrapper">
          <iframe id="newSupplierProposalForm" title="New Supplier Form" className="new-supplier-proposal_form_iframe" src={iframeSrcWithQueryParams} frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
      </div>
    </div>
  )
}


export default NewSupplierProposalContainer;