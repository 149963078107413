export const SYLVESTRIS_DEVELOPER_PROFILE_EN = {
    "data": {
      "projects": [
        {
          "certifications": [],
          "description": "The project \"Absorbemos Co2 En Ejulve (Teruel)\" is located in Ejulve, in the province of Teruel. With a total area of 3,88 hectares, it is expected to absorb 759.0 tons of CO2 in 40 years. The project consists of a reforestation in an area burned by plantation of Quercus ilex ssp ballota, Quercus faginea, Pinus nigra ssp salzmannii, Prunus spinosa, Crataegus monogyna, Sorbus domestica and Celtis australis.",
          "events": [],
          "forest_id": 2002019007,
          "forest_surface": 38800,
          "forest_total_credits": 0,
          "reforestum_project_type": {
            "title": "MITECO project",
            "description": "Project certified by the Spanish Ministry for the Ecological Transition and the Demographic Challenge (MITECO)"
          },
          "project_roles": [
            {
              "name": "developer",
              "title": "Developer",
              "description": "Developer of the project"
            }
          ],
          "gallery": [
            {
              "url": "https://reforestum-static-images.s3.eu-central-1.amazonaws.com/forests/galleries/miterd/2002019007_2019-b007_tcm30-497376_2002019007_0_1.jpg",
              "title": "Auto imported gallery of 2002019007",
              "description": "Auto imported gallery of 2002019007"
            },
            {
              "url": "https://reforestum-static-images.s3.eu-central-1.amazonaws.com/forests/galleries/miterd/2002019007_2019-b007_tcm30-497376_2002019007_0_2.jpg",
              "title": "Auto imported gallery of 2002019007",
              "description": "Auto imported gallery of 2002019007"
            }
          ],
          "location": "Ejulve, Teruel, Spain",
          "logo_url": null,
          "main_image": "https://reforestum-static-images.s3.eu-central-1.amazonaws.com/forests/galleries/miterd/2002019007_2019-b007_tcm30-497376_2002019007_0_1.jpg",
          "name": "Absorbemos Co2 En Ejulve (Teruel)",
          "navigable": false,
          "offset_type": "VCU",
          "registry": "MITERD",
          "org_total_credits": 125,
          "org_is_owner": false,
          "planting_date": 1554847200000,
          "project_type": "ARR",
          "reforesters_amount": 0,
          "sector_id": null,
          "sector_surface": 0,
          "slug": "absorbemos-co2-en-ejulve-(teruel)",
          "timeline_formula": null,
          "user_captured_co2": 125000,
          "user_owned_surface": 0,
          "years_co2": 0
        },
        {
          "certifications": [],
          "description": "The project \"Absorbemos Co2 En Torremocha De Jarama (Madrid). Sylvestris is located in Torremocha de Jarama, in the province of Madrid. With a total area of 1.45 hectares, it is expected to absorb 213.0 tons of CO2 in 50 years. The project consists of reforestation in agricultural area by plantation of Quercus faginea, Quercus ilex, Pinus pina, Fraxinus angustifolia, Crataegus monogyna, Prunus dulcis, Populus alba, Acer monspessulanum, Pistacia terebinthus, Salix alba and Populus nigra.",
          "events": [],
          "forest_id": 2002020010,
          "forest_surface": 14500,
          "forest_total_credits": 0,
          "reforestum_project_type": {
            "title": "MITECO project",
            "description": "Project certified by the Spanish Ministry for the Ecological Transition and the Demographic Challenge (MITECO)"
          },
          "project_roles": [
            {
              "name": "developer",
              "title": "Developer",
              "description": "Developer of the project"
            }
          ],
          "gallery": [
            {
              "url": "https://reforestum-static-images.s3.eu-central-1.amazonaws.com/forests/galleries/miterd/2002020010_2020-b010_tcm30-509307_2002020010_0_0.jpg",
              "title": "Auto imported gallery of 2002020010",
              "description": "Auto imported gallery of 2002020010"
            },
            {
              "url": "https://reforestum-static-images.s3.eu-central-1.amazonaws.com/forests/galleries/miterd/2002020010_2020-b010_tcm30-509307_2002020010_0_1.jpg",
              "title": "Auto imported gallery of 2002020010",
              "description": "Auto imported gallery of 2002020010"
            }
          ],
          "location": "Torremocha de Jarama, Madrid, Spain",
          "logo_url": null,
          "main_image": "https://reforestum-static-images.s3.eu-central-1.amazonaws.com/forests/galleries/miterd/2002020010_2020-b010_tcm30-509307_2002020010_0_0.jpg",
          "name": "Absorbemos Co2 En Torremocha De Jarama (Madrid). Sylvestris",
          "navigable": false,
          "offset_type": "VCU",
          "registry": "MITERD",
          "org_total_credits": 39,
          "org_is_owner": false,
          "planting_date": 1589925600000,
          "project_type": "ARR",
          "reforesters_amount": 0,
          "sector_id": null,
          "sector_surface": 0,
          "slug": "absorbemos-co2-en-torremocha-de-jarama-(madrid).-s",
          "timeline_formula": null,
          "user_captured_co2": 39000,
          "user_owned_surface": 0,
          "years_co2": 0
        },
        {
          "certifications": [],
          "description": "The project \"Absorbemos Co2 In Montserrat. Sylvestris\" is located in El Bruc, in the province of Barcelona. With a total area of 3.63 hectares, 412.0 tons of CO2 are expected to be absorbed in 50 years. It is a plantation of Quercus ilex ssp ballota, Pinus pina, Pinus halepensis, Prunus dulcis and Crataegus monogyna.",
          "events": [],
          "forest_id": 2002019002,
          "forest_surface": 36300,
          "forest_total_credits": 0,
          "reforestum_project_type": {
            "title": "MITECO project",
            "description": "Project certified by the Spanish Ministry for the Ecological Transition and the Demographic Challenge (MITECO)"
          },
          "project_roles": [
            {
              "name": "developer",
              "title": "Developer",
              "description": "Developer of the project"
            }
          ],
          "gallery": [
            {
              "url": "https://reforestum-static-images.s3.eu-central-1.amazonaws.com/forests/galleries/miterd/2002019002_2019-b002_tcm30-489618_2002019002_0_1.jpg",
              "title": "Auto imported gallery of 2002019002",
              "description": "Auto imported gallery of 2002019002"
            },
            {
              "url": "https://reforestum-static-images.s3.eu-central-1.amazonaws.com/forests/galleries/miterd/2002019002_2019-b002_tcm30-489618_2002019002_0_2.jpg",
              "title": "Auto imported gallery of 2002019002",
              "description": "Auto imported gallery of 2002019002"
            }
          ],
          "location": "El Bruc, Barcelona, Spain",
          "logo_url": null,
          "main_image": "https://reforestum-static-images.s3.eu-central-1.amazonaws.com/forests/galleries/miterd/2002019002_2019-b002_tcm30-489618_2002019002_0_1.jpg",
          "name": "Absorbemos Co2 En Montserrat. Sylvestris",
          "navigable": false,
          "offset_type": "VCU",
          "registry": "MITERD",
          "org_total_credits": 24,
          "org_is_owner": false,
          "planting_date": 1553122800000,
          "project_type": "ARR",
          "reforesters_amount": 0,
          "sector_id": null,
          "sector_surface": 0,
          "slug": "absorbemos-co2-en-montserrat.-sylvestris",
          "timeline_formula": null,
          "user_captured_co2": 24000,
          "user_owned_surface": 0,
          "years_co2": 0
        },
        {
          "certifications": [],
          "description": "The project \"Absorbemos Co2 In Vall D'Ebo. Sylvestris\" is located in Vall d'Ebo, in the province of Alicante/Alacant. With a total area of 2.9 hectares, it is expected to absorb 186.0 tons of CO2 in 40 years. It is a plantation of Quercus ilex and Pinus halepensis.",
          "events": [],
          "forest_id": 2002019006,
          "forest_surface": 29000,
          "forest_total_credits": 0,
          "reforestum_project_type": {
            "title": "MITECO project",
            "description": "Project certified by the Spanish Ministry for the Ecological Transition and the Demographic Challenge (MITECO)"
          },
          "project_roles": [
            {
              "name": "developer",
              "title": "Developer",
              "description": "Developer of the project"
            }
          ],
          "gallery": [
            {
              "url": "https://reforestum-static-images.s3.eu-central-1.amazonaws.com/forests/galleries/miterd/2002019006_2019-b006_tcm30-489665_2002019006_0_1.jpg",
              "title": "Auto imported gallery of 2002019006",
              "description": "Auto imported gallery of 2002019006"
            },
            {
              "url": "https://reforestum-static-images.s3.eu-central-1.amazonaws.com/forests/galleries/miterd/2002019006_2019-b006_tcm30-489665_2002019006_0_2.jpg",
              "title": "Auto imported gallery of 2002019006",
              "description": "Auto imported gallery of 2002019006"
            }
          ],
          "location": "Vall d'Ebo, Alicante/Alacant, Spain",
          "logo_url": null,
          "main_image": "https://reforestum-static-images.s3.eu-central-1.amazonaws.com/forests/galleries/miterd/2002019006_2019-b006_tcm30-489665_2002019006_0_1.jpg",
          "name": "Absorbemos Co2 En Vall D'Ebo. Sylvestris",
          "navigable": false,
          "offset_type": "VCU",
          "registry": "MITERD",
          "org_total_credits": 13,
          "org_is_owner": false,
          "planting_date": 1552604400000,
          "project_type": "ARR",
          "reforesters_amount": 0,
          "sector_id": null,
          "sector_surface": 0,
          "slug": "absorbemos-co2-en-vall-d'ebo.-sylvestris",
          "timeline_formula": null,
          "user_captured_co2": 13000,
          "user_owned_surface": 0,
          "years_co2": 0
        }
      ],
      "profile": {
        "avatar_url": "https://gruposylvestris.com/wp-content/uploads/2017/09/sylvestris-natural-engineering.png",
        "is_unonboarded": true,
        "name": "Sylvestris",
        "website": "https://gruposylvestris.com/",
        "year_foundation": 0,
        "org_id": "144291",
        "bio": "Our mission is to fill the world of trees, get a greener and healthy environment, counting on the work of disadvantaged people or at risk of exclusion. We sow oxygen and compensate CO2, through reforestations, integrating people and nature.",
        "community_size": 0,
        "has_unverified_transactions": false
      }
    }
  }