import React from 'react'
import PropTypes from 'prop-types'
import './RelatedCompanies.css'
import '../UI/Tags/Tags.css'
import { injectIntl, intlShape } from "react-intl"
import { withRouter } from "react-router"
import api from "../../constants/api"
import {getUserRequestHeaders} from "../Utils/getUserRequestHeaders"
import Loader from "../UI/Loader/Loader"
import {links} from "@reforestum/shared-utils"
import Link from "react-router-dom/Link"
import AvatarPlaceholder from '@reforestum/shared-components/dist/assets/images/avatar-placeholder.png';

class RelatedCompanyCard extends React.Component {
	render() {
		const {
			profileDomain,
			displayName,
			avatarUrl
		} = this.props
		return <Link className="related-company" to={links.getPublicProfileUrl(profileDomain)}>
			<img className="related-company__avatar" src={avatarUrl || AvatarPlaceholder} alt="company logo" />
			<div className="related-company__name">
				{displayName}
			</div>
		</Link>
	}
}

class RelatedCompanies extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,
			relatedCompanies: [],
		}
	}

	componentDidMount() {
		this.getRelatedCompanies()
	}

	componentDidUpdate(prevProps) {
		if(this.props.organizationId !== prevProps.organizationId) {
			this.getRelatedCompanies()
		}
	}

	async queryRelatedCompanies(organizationId) {
		let result = null
		try {
			result = await fetch(
				`${api.ORGANIZATION}/${organizationId}/similar`,
				{headers: getUserRequestHeaders()}
			)
			result = await result.json()
		}
		catch(e) {
			console.warn('Error getting related companies', e)
		}
		return result
	}

	async getRelatedCompanies() {
		this.setState({ isLoading: true })
		let result = await this.queryRelatedCompanies(this.props.organizationId)
		this.setState({ relatedCompanies: result, isLoading: false })
	}

	render() {
		const {isLoading, relatedCompanies} = this.state
		return <div className={'related-companies'}>
			{isLoading && <Loader />}
			{
				relatedCompanies && Array.isArray(relatedCompanies) && relatedCompanies.map((related, i) =>
					<RelatedCompanyCard
						key={`related-company-${i}`}
						profileDomain={related.profile_domain}
						companyName={related.company_name}
						displayName={related.display_name}
						avatarUrl={related.avatar_url}
					/>
				)
			}
		</div>
	}
}

RelatedCompanies.propTypes = {
	organizationId: PropTypes.array,
	intl: intlShape
}

export default injectIntl(
	withRouter(RelatedCompanies)
)
