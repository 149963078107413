import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl'

import './OrganizationTeam.css';
import {settingsMessages} from "../../../../constants/messages"
import api from "../../../../constants/api"
import {getUserRequestHeaders} from "../../../Utils/getUserRequestHeaders"
import Modal from "../../../UI/Modal/Modal"
import {Button} from "@reforestum/shared-components"

class InviteNewTeamMember extends Component {

	constructor(props) {
		super(props)

		this.state = {
			showManualInviteFlowModal: false,
			showAlreadyInvitedModal: false,
			addTeamMemberFirstName: '',
			addTeamMemberLastName: '',
			addTeamMemberEmail: '',
			addingTeamMember: false,
		}
	}

	async handleAddNewTeamMemberRequest() {
		const {intl, addAlert, setShowInviteNewTeamMemberModal} = this.props
		this.setState({addingTeamMember: true})
		const orgId = this.props.orgId
		let result = []
		try {
			result = await fetch(
				`${api.GET_ORGANIZATION_MEMBERS}${orgId}/members`,
				{
					method: 'put',
					headers: {...getUserRequestHeaders(), 'Content-Type': 'application/json'},
					body: JSON.stringify({
						name: this.state.addTeamMemberFirstName,
						surname: this.state.addTeamMemberLastName,
						email: this.state.addTeamMemberEmail,
					})
				}
			)
			let body = await result.json()

			if(result.status === 200) {
				addAlert({
					type: 'success',
					message: this.state.addTeamMemberEmail + ' ' + intl.formatMessage(settingsMessages.orgTeamModalUserHasBeenInvited),
					dismissAfter: 3000
				})
			} else if (result.status === 409 && (body.extra && body.extra.id === "email-already-member")) {
				this.setState({showAlreadyInvitedModal: true})
			} else {
				this.setState({showManualInviteFlowModal: true})
			}
		}
		catch(e) {
			console.warn('Error in add team member query', e)
		}
		this.setState({addingTeamMember: false})
		setShowInviteNewTeamMemberModal(false)
		return result
	}

	renderAddTeamMemberModal() {
		const {intl, setShowInviteNewTeamMemberModal} = this.props
		return <Modal
			clickClose={() => setShowInviteNewTeamMemberModal(false)}
			content={
				<div className={'organization-team__add-new-team-member-modal'}>
					<h1 className={'organization-team__add-new-team-member-modal__title'}>
						{intl.formatMessage(settingsMessages.orgTeamModalAddUserTitle)}
					</h1>
					<p className={'organization-team__add-new-team-member-modal__description'}>
						{intl.formatMessage(settingsMessages.orgTeamModalAddUserDescription)}
					</p>

					<div className={'organization-team__add-new-team-member-modal__input-heading'}>
						{intl.formatMessage(settingsMessages.orgTeamModalAddUserInputHeadingFirstName)}
					</div>
					<input
						className={'organization-team__add-new-team-member-modal__input'}
						type={'text'}
						value={this.state.addTeamMemberFirstName}
						onChange={e => this.setState({addTeamMemberFirstName: e.target.value})}
					/>

					<div className={'organization-team__add-new-team-member-modal__input-heading'}>
						{intl.formatMessage(settingsMessages.orgTeamModalAddUserInputHeadingLastName)}
					</div>
					<input
						className={'organization-team__add-new-team-member-modal__input'}
						type={'text'}
						value={this.state.addTeamMemberLastName}
						onChange={e => this.setState({addTeamMemberLastName: e.target.value})}
					/>

					<div className={'organization-team__add-new-team-member-modal__input-heading'}>
						{intl.formatMessage(settingsMessages.orgTeamModalAddUserInputHeadingEmail)}
					</div>
					<input
						className={'organization-team__add-new-team-member-modal__input'}
						type={'email'}
						placeholder={intl.formatMessage(settingsMessages.orgTeamModalAddUserEmailInputPlaceholder)}
						value={this.state.addTeamMemberEmail}
						onChange={e => this.setState({addTeamMemberEmail: e.target.value})}
					/>

					<Button onClick={() => this.handleAddNewTeamMemberRequest()} small className="organization-team__add-new-team-member-modal__cta">
						<FormattedMessage
							id={'Setting.orgTeamModalAddUserCTA'}
							defaultMessage={'Add Team Member'}
						/>
					</Button>
				</div>
			}
		/>
	}

	renderManualInviteFlowModal() {
		const {intl} = this.props
		return <Modal
			clickClose={() => this.setState({showManualInviteFlowModal: false})}
			content={
				<div className={'organization-team__add-new-team-member-modal'}>
					<h1 className={'organization-team__add-new-team-member-modal__title'}>
						{intl.formatMessage(settingsMessages.orgTeamManualInviteTitle)}
					</h1>
					<p className={'organization-team__add-new-team-member-modal__description'}>
						{intl.formatMessage(settingsMessages.orgTeamManualInviteDescription)}
					</p>
				</div>
			}
		/>
	}

	renderTeamMemberAlreadyInvitedModal() {
		const {intl} = this.props
		return <Modal
			clickClose={() => this.setState({showAlreadyInvitedModal: false})}
			content={
				<div className={'organization-team__add-new-team-member-modal'}>
					<h1 className={'organization-team__add-new-team-member-modal__title'}>
						{intl.formatMessage(settingsMessages.orgTeamAlreadyInvitedTitle)}
					</h1>
					<p className={'organization-team__add-new-team-member-modal__description'}>
						{intl.formatMessage(settingsMessages.orgTeamAlreadyInvitedDescription)}
					</p>
				</div>
			}
		/>
	}

	render() {
		return (
			<div className={'invite-new-team-member'}>
				{this.props.showInviteNewTeamMemberModal && this.renderAddTeamMemberModal()}
				{this.state.showManualInviteFlowModal && this.renderManualInviteFlowModal()}
				{this.state.showAlreadyInvitedModal && this.renderTeamMemberAlreadyInvitedModal()}
			</div>
		)
	}
}

InviteNewTeamMember.propTypes = {
	addAlert: PropTypes.func,
	showInviteNewTeamMemberModal: PropTypes.bool,
	setShowInviteNewTeamMemberModal: PropTypes.func
};

export default injectIntl(InviteNewTeamMember);
