import React from 'react';
import PropTypes from 'prop-types';
import { ScrollView } from 'react-layout-components';
import { TabList, Tab, TabPanel } from 'react-tabs';
import { FormattedMessage } from 'react-intl';
import RoutedTabbed from '../../UI/Tabs/RoutedTabbed';
import ForestHeader from '../Header/ForestHeader';
import ForestInfo from '../Info/ForestInfo';
import MyTrees from '../MyTrees/MyTrees';
import BreadCrumb from '../../UI/BreadCrumb/BreadCrumb';
import BetaTooltip from '../../UI/Tabs/BetaTooltip'
import MyCredits from "../MyCredits/MyCredits";
import PatronsContainer from '../../../containers/PatronsContainer';
import LatestActivityContainer from '../../../containers/LatestActivityContainer';
import ImpactContainer from '../../../containers/ImpactContainer';
import {OFFSET_TYPE_CREDIT} from "../../../constants/offsetTypes";
import {OFFSET_TYPE_SQM_SHARES} from "../../../constants/offsetTypes";
import { links } from '@reforestum/shared-utils';

import './ForestDetails.css';
import { withRouter } from 'react-router';

const paths = [
  'about',
  'reforesters',
  'latest-activity',
  'tracking',
  'my-shares'
]

const SECTOR_DETAILS_ID = 'SECTOR_DETAILS_ID'

const SectorDetails = ({
  sector,
  forest,
  openGallery,
  gallery,
  species,
  team,
  myOwnedForests,
  isForestLevel,
  isImpactAvailable,
  latestActivity,
  reforesterShares,
  shareIdToZoom
}) => {
    const {
      name,
      location_desc,
      available,
      offset_type,
      my_surface,
      captured_co2,
      carbon_calculation_desc,
      short_desc,
      long_desc,
      timeline_formula,
      generated_o2,
      descriptions,
      total_surface,
      user_total_surface,
      main_image,
      co2_years,
      community,
      total_vcus,
      vcu_registry_url,
      id
    } = forest;

    const resetScroll = () => {
      document.getElementById(SECTOR_DETAILS_ID).scrollTo(0,0)
    }

    const authenticatedUserShareInThisSector = () => {
      let result = null

      myOwnedForests && myOwnedForests.forEach(
        f => {
          (f && f.user_sectors_in_forest) && f.user_sectors_in_forest.forEach(
            s => {
              if (Number(s.sector_id) === Number(sector.sector_id)) {
                result = s.share_id
              }
            }
          )
        }
      )

      return result
    }

    if(!shareIdToZoom) {
      shareIdToZoom = authenticatedUserShareInThisSector()
    }

    const authenticatedUserIsShareOwnerOfShareBeingViewed = () => {
      let result = false

      myOwnedForests && myOwnedForests.forEach(
        f => {
          (f && f.user_sectors_in_forest) && f.user_sectors_in_forest.forEach(
            s => {
              if (s.share_id === shareIdToZoom) {
                result = true
              }
            }
          )
        }
      )

      return result
    }

    const getZoomedShareInfo = () => reforesterShares ? reforesterShares.find(s => s.shareId === shareIdToZoom) || {name: null, sqmReforested: 0} : {}

    const myFilteredOwnedForests = myOwnedForests && myOwnedForests.length > 0 ? myOwnedForests.filter(myForest => myForest.id !== forest.id) : null
    const { description, title } = sector;

  return (
    <ScrollView
      id={SECTOR_DETAILS_ID}
      justifyContent="flex-start"
      alignItems="center"
      flex="1"
      className="forest-details">
      <div className="forest-details__content">
        {sector && (isForestLevel
        ?
          <BreadCrumb
            textFrom={<FormattedMessage
              id={'ForestBreadcrumb.projects'}
              defaultMessage={'Projects'}
            />}
            textTo={sector.name}
            linkTo={(`${links.getProjectUrl()}/map`)}
          /> : 
          <BreadCrumb
          textOrigin={
            <FormattedMessage
              id={'ForestBreadcrumb.projects'}
              defaultMessage={'Projects'}
            />
          }
          textFrom={name}
          textTo={sector.name}
          linkToOrigin={(`${links.getProjectUrl()}/map`)}
          linkTo={links.getProjectUrl(id)}
          sector={!isForestLevel}
        />
        )}
        <ForestHeader
          name={name}
          location={location_desc}
          available={available}
          forest={forest}
          sector={sector}
        />
        <RoutedTabbed resetScroll={resetScroll} paths={paths}>
          <div className="forest-details__tabs">
            <TabList>
              <Tab>
                <FormattedMessage
                  id={'ForestDetails.tabInfo'}
                  defaultMessage={'About'}
                />
              </Tab>
              <Tab>
                <FormattedMessage
                  id={'ForestDetails.tabPatrons'}
                  defaultMessage={'Reforesters'}
                />
              </Tab>
              <Tab>
                <FormattedMessage
                  id={'ForestDetails.tabLatestActivity'}
                  defaultMessage={'Latest Activity'}
                />
              </Tab>
              <Tab>
                <FormattedMessage
                  id={'ForestDetails.Tracking'}
                  defaultMessage={'Tracking'}
                />
                {isImpactAvailable && <BetaTooltip />}
              </Tab>
              <Tab>
                {
                  (forest.offset_type === OFFSET_TYPE_SQM_SHARES ?
                      shareIdToZoom
                        ? <FormattedMessage
                          id={'ForestDetails.usersTrees'}
                          defaultMessage={'Shares'}
                        />
                        : null
                      :
                      <FormattedMessage
                        id={'ForestDetails.tabMyCredits'}
                        defaultMessage={'My carbon credits'}
                      />
                  )
                }
              </Tab>
            </TabList>
          </div>
  
          <TabPanel>
            <ForestInfo
              area={my_surface}
              capturedCo2={captured_co2}
              shortDescription={short_desc}
              longDescription={long_desc}
              welcomeDescription={description}
              welcomeTitle={title}
              formula={timeline_formula}
              generatedO2={generated_o2}
              descriptions={descriptions}
              surface={total_surface}
              userSurface={user_total_surface}
              forestName={name}
              mainImage={main_image}
              gallery={gallery}
              years={co2_years}
              openGallery={(index) => () => openGallery(gallery.images, index)}
              species={species}
              team={team}
              community={community}
              carbonCalculation={carbon_calculation_desc}
              beZeroAvailable={forest.be_zero_available}
            />
          </TabPanel>
          <TabPanel>
            <PatronsContainer registry={forest.registry} forestId={id} sectorId={Number(sector.isVirtualSector ? sector.id : sector.sector_id) || null}/>
          </TabPanel>
  
          <TabPanel>
            <LatestActivityContainer openGallery={openGallery} latestActivity={latestActivity} />
          </TabPanel>

          <TabPanel>
            <ImpactContainer 
              forestName={sector.name}
              formula={timeline_formula}
              years={co2_years}
              // total_surface for Virtual Sectors' cases, in which we should use the forests' surface
              surface={sector.sector_surface || total_surface}
              plantingDate={forest.planting_date}
              totalVcus={total_vcus}
              vcuRegistryUrl={vcu_registry_url}
              // this allows to feature MRV tracking also for virtual sectors
              isImpactAvailable={isImpactAvailable}
              forestId={id}
            />
          </TabPanel>

          {
            shareIdToZoom
              ? <TabPanel>
                  {offset_type === OFFSET_TYPE_SQM_SHARES &&
                    <MyTrees
                      forest={forest}
                      sector={sector}
                      authenticatedUserShareInThisSector={authenticatedUserShareInThisSector()}
                      authenticatedUserIsShareOwner={authenticatedUserIsShareOwnerOfShareBeingViewed()}
                      userTotalSurface={
                        authenticatedUserIsShareOwnerOfShareBeingViewed()
                          ? forest.user_total_surface
                          : getZoomedShareInfo().sqmReforested
                      }
                      userOwnerDisplayName={getZoomedShareInfo().name}
                      userOwnerAvatar={getZoomedShareInfo().image}
                      myFilteredOwnedForests={myFilteredOwnedForests}
                    />
                  }
                  {offset_type === OFFSET_TYPE_CREDIT &&
                    <MyCredits forest={forest} forestVirtualSector={sector.isVirtualSector ? sector : null} myFilteredOwnedForests={myFilteredOwnedForests}/>
                  }
                </TabPanel>
              : <TabPanel>
                {
                  offset_type === OFFSET_TYPE_CREDIT &&
                  <MyCredits forest={forest} forestVirtualSector={sector.isVirtualSector ? sector : null} myFilteredOwnedForests={myFilteredOwnedForests}/>
                }
              </TabPanel>
          }
        </RoutedTabbed>
      </div>
  
    </ScrollView>
  )
};

SectorDetails.propTypes = {
  forest: PropTypes.object,
  openGallery: PropTypes.func.isRequired,
  species: PropTypes.array,
  team: PropTypes.array.isRequired,
  gallery: PropTypes.object,
  sector: PropTypes.object.isRequired,
  isForestLevel: PropTypes.bool,
  myOwnedForests: PropTypes.array,
  reforesterShares: PropTypes.array,
  isImpactAvailable: PropTypes.bool,
  latestActivity: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default withRouter(SectorDetails);
