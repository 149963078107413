import React from 'react';

const Chart = props => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.1963 5L2.80396 11" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
<path d="M2.10005 9.0969C1.84027 7.69103 2.09022 6.2386 2.80499 5.00044C3.51977 3.76228 4.65255 2.81951 5.99992 2.34143V6.84531L2.10005 9.0969Z" stroke="#79897A" strokeWidth="1.2" strokeLinecap="square"/>
<path d="M7.99989 2L8 1.4L7.39989 1.39989V2H7.99989ZM3.34321 10.736L3.03921 10.2187L2.00464 10.8267L2.30864 11.344L3.34321 10.736ZM7.99978 2.6C8.94587 2.60017 9.87531 2.84891 10.695 3.3213L11.2942 2.28159C10.2923 1.70422 9.15633 1.40021 8 1.4L7.99978 2.6ZM10.695 3.3213C11.5147 3.79368 12.196 4.47314 12.6705 5.29163L13.7086 4.68977C13.1287 3.6894 12.2961 2.85895 11.2942 2.28159L10.695 3.3213ZM12.6705 5.29163C13.145 6.11012 13.3961 7.03891 13.3988 7.985L14.5988 7.98167C14.5955 6.82534 14.2886 5.69015 13.7086 4.68977L12.6705 5.29163ZM13.3988 7.985C13.4014 8.93109 13.1554 9.86126 12.6854 10.6824L13.7269 11.2785C14.3013 10.2749 14.602 9.13799 14.5988 7.98167L13.3988 7.985ZM12.6854 10.6824C12.2155 11.5035 11.5381 12.1867 10.721 12.6637L11.3259 13.7C12.3246 13.1171 13.1525 12.282 13.7269 11.2785L12.6854 10.6824ZM10.721 12.6637C9.90389 13.1406 8.97585 13.3945 8.02978 13.3999L8.03666 14.5999C9.19298 14.5933 10.3273 14.2829 11.3259 13.7L10.721 12.6637ZM8.02978 13.3999C7.0837 13.4053 6.15281 13.1621 5.3303 12.6946L4.73731 13.7378C5.74259 14.3093 6.88035 14.6065 8.03666 14.5999L8.02978 13.3999ZM5.3303 12.6946C4.5078 12.2271 3.82257 11.5517 3.34321 10.736L2.30864 11.344C2.89452 12.3409 3.73203 13.1664 4.73731 13.7378L5.3303 12.6946ZM8.59989 8V2H7.39989V8H8.59989Z" fill="#1F3B21"/>
</svg>

);

export default Chart;
