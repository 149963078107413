import React, {useEffect, useRef, useState} from 'react'
import {queryDiscover, queryDiscoverFilters} from '../components/Utils/queryDiscover'
import {FormattedMessage, injectIntl} from 'react-intl'
import {discoverMessages, rfpCard} from '../constants/messages'
import Loader from '../components/UI/Loader/Loader'
import SelectMulti from '../components/UI/SelectMulti/SelectMulti'
import './DiscoverContainer.scss'
import SearchAlt from '../components/UI/Icons/SearchAlt'
import {links} from '@reforestum/shared-utils'
import {DEFAULT_PROJECT_IMAGE} from '../components/RequestForProposalsResponseCard/rfpConstants'
import MapPinSimple from '../components/UI/Icons/MapPinSimple'
import renderTooltipForTrimmedText from '../utils/renderTooltipForTrimmedText'
import Plant from '../components/UI/Icons/Plant'
import Certification from '../components/UI/Icons/Certification'
import TinyInfoBlock from '../components/UI/TinyInfoBlock/TinyInfoBlock'
import {Button, ButtonLink} from '@reforestum/shared-components'
import Chats from '../components/UI/Icons/Chats'
import Token from '../components/UI/Text/Token'
import ExternalLink from '../components/UI/Icons/ExternalLink'
import Modal from '../components/UI/Modal/Modal'
import Clock from '../components/UI/Icons/Clock'
import RequestProposalModal from '../components/Discover/RequestProposalModal/RequestProposalModal'
import {queryDiscoverProposalRequest} from '../components/Utils/queryDiscoverProposalRequest'
import Tooltip from '../components/UI/Tooltip/Tooltip'
import store from '../setup/store'
import {addAlert} from '../actions/alerts'
import {getUserOrImpersonatingOrg} from '../utils/usersAndOrganizations'
import SUBSCRIPTIONS from '../constants/subscriptions'
import {getUserDetails} from '../selectors/userSession'
import {connect} from 'react-redux'
import RequestAccessModal from '../components/Discover/RequestAccessModal/RequestAccessModal'

const PROJECT_DETAILS_MAX_TINY_INFO_TAG_LENGTH = 10
const MODAL_CONTACT_REQUEST_PROPOSAL = 'MODAL_CONTACT_REQUEST_PROPOSAL'
const DiscoverContainer = (
  {
    intl,
    user
  }
) => {
  const {subscriptions} = getUserOrImpersonatingOrg(user)
  const userHasRequestProposalPermission = subscriptions && subscriptions[SUBSCRIPTIONS.GENERIC]
  const scrollableResultsContainerRef = useRef(null)

  const [didFirstLoad, setDidFirstLoad] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [filters, setFilters] = useState([])
  const [debounceSearchString, setDebounceSearchString] = useState(searchString)
  const [searchResults, setSearchResults] = useState([])
  const [totalProjects, setTotalProjects] = useState(0)
  const [totalMatches, setTotalMatches] = useState(0)
  const [modalToShow, setModalToShow] = useState(null)
  const [selectedProjectId, setSelectedProjectId] = useState(null)
  const [approximateTargetVolume, setApproximateTargetVolume] = useState(0)
  const [additionalDetails, setAdditionalDetails] = useState('')
  const [isUpdatingSearch, setIsUpdatingSearch] = useState(false)
  const [isUpdatingLoadMore, setIsUpdatingLoadMore] = useState(false)
  const [filtersValues, setFiltersValues] = useState({})
  const [currentPage, setCurrentPage] = useState(1)

  const getFilters = async () => {
    const results = await queryDiscoverFilters()
    setFilters(results)
  }

  const doDiscoverSearch = async () => {
    setIsUpdatingSearch(true)
    setCurrentPage(0)
    const results = await queryDiscover(
      {
        searchString,
        filters,
        filtersValues
      }
    )
    if(results.totalProjects && results.totalMatches && results.matches) {
      setTotalProjects(results.totalProjects)
      setTotalMatches(results.totalMatches)
      setSearchResults(results.matches)
    }
    else if((results.totalMatches || 0) === 0) {
      setTotalProjects(0)
      setTotalMatches(0)
      setSearchResults([])
    }
    setIsUpdatingSearch(false)
  }

  const loadMoreItems = async () => {
    setIsUpdatingLoadMore(true)
    const results = await queryDiscover(
      {
        searchString,
        filters,
        filtersValues,
        currentPage: currentPage + 1
      }
    )
    if(results.matches && results.matches.length > 0) {
      setTotalMatches(results.totalMatches)
      setSearchResults([...searchResults, ...results.matches])
      setCurrentPage(currentPage + 1)
    }
    setIsUpdatingLoadMore(false)
  }

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollableResultsContainerRef.current
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight - 5
    if (!isUpdatingLoadMore && !isUpdatingSearch && scrolledToBottom && searchResults.length < totalMatches) {
      loadMoreItems()
    }
  }

  useEffect(() => {
    const scrollableElement = scrollableResultsContainerRef.current

    if (scrollableElement) {
      scrollableElement.addEventListener('scroll', handleScroll)

      return () => {
        scrollableElement.removeEventListener('scroll', handleScroll)
      }
    }
  }, [searchResults, isUpdatingSearch, isUpdatingLoadMore])

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebounceSearchString(searchString)
    }, 500)

    return () => {
      clearTimeout(timerId)
    }
  }, [searchString])

  const manageDebouncedSearchString = async () => {
    await setDidFirstLoad(true)
    await getFilters()
    doDiscoverSearch()
  }

  useEffect(
    () => {
      manageDebouncedSearchString()
    },
    [debounceSearchString]
  )

  const manageFilters = async () => {
    if(didFirstLoad) {
      await setSearchResults([])
      await doDiscoverSearch()
    }
  }

  useEffect(
    () => {
      manageFilters()
    },
    [
      filtersValues
    ]
  )

  const updateSearch = e => {
    setSearchString(e.target.value)
  }

  const handleSearchKeydown = e => {
    if (e.key === "Escape") {
      updateSearch({ target: { value: '' } })
    }
  }
  
  const handleProposalRequestSubmit = async () => {
    const result = await queryDiscoverProposalRequest({
      projectId: selectedProjectId,
      approximateTargetVolumeTons: approximateTargetVolume,
      details: additionalDetails
    })
    if(result.status === 200) {
      setModalToShow(null)
      store.dispatch(addAlert({
        type: 'success',
        message: 'You have successfully requested proposals for this project',
        dismissAfter: 8000
      }))
      setSearchResults(
        [...searchResults].map(
          p => {
            let result = p
            if(p.id === selectedProjectId) {
              result = {...p, proposalRequested: true}
            }
            return result
          }
        )
      )
    }
  }

  const getSelectedFiltersFor = (param) => {
    let result = []
    if(filtersValues[param]) {
      result = filtersValues[param]
    }
    return result
  }

  const setSelectedFiltersFor = (param) => {
    return (e) => {
      setFiltersValues({
          ...filtersValues,
          [param]: e
        }
      )
    }
  }

  const renderSearchFilters = () => {
    const filtersToRender = filters.map(
      (f, i) => {
        return <div key={`filter-${i}`}>
          <div className={'discover__search-filter-label'}>
            {f.name}
          </div>
          <SelectMulti
            dropdownPlaceholder={intl.formatMessage(discoverMessages.selectMultiPlaceholderDefault)}
            options={f.possibleValues}
            selected={getSelectedFiltersFor(f.param)}
            setSelected={setSelectedFiltersFor(f.param)}
            multipleValues={f.multipleValues}
          />
        </div>
      }
    )

    return filtersToRender
  }

  const renderSearchAndFilters = () => {
    return <div className={'discover__search-and-filters'}>
      <h1>
        <FormattedMessage
          id={`discover.SearchAndFiltersHeading`}
          defaultMessage={'Discover Projects'}
        />
      </h1>
      <div className={'discover__search'}>
        <SearchAlt width={20} height={20} />
        <input
          tabIndex={1}
          type={'text'}
          onKeyDown={handleSearchKeydown}
          autoFocus={true}
          onChange={updateSearch}
          value={searchString}
          placeholder={intl.formatMessage(discoverMessages.searchPlaceholder)}
        />
      </div>
      {renderSearchFilters()}
      {
        (didFirstLoad) && <div className={'discover__results-counts'}>
          {
            (totalProjects === 0)
              ? <>
                No results found
              </>
              : (totalMatches !== totalProjects)
                ? <>
                  <span className={'discover__results-counts__emphasis'}>{totalMatches}</span> of <span className={'discover__results-counts__emphasis'}>{totalProjects}</span> projects meet your criteria
                </>
                : <>
                Search <span className={'discover__results-counts__emphasis'}>{totalProjects}</span> voluntary carbon market projects
                </>
          }
        </div>
      }
      <div className={'discover__small-screen-helper-button__container'}>
        <Button onClick={smallScreenScrollToResults} className={'discover__small-screen-helper-button'}>Jump to results</Button>
      </div>
    </div>
  }

  const smallScreenScrollToResults = () => {
    const element = document.getElementsByClassName('l-wrapper')[0]
    if(element) {
      element.scrollTo({behavior: 'smooth', top: 19999})
    }
  }

  const showRequestProposalModal = (projectId) => {
    setSelectedProjectId(projectId)
    setModalToShow(modalToShow === MODAL_CONTACT_REQUEST_PROPOSAL ? null : MODAL_CONTACT_REQUEST_PROPOSAL)
  }

  const renderProjectTinyInfoBlocks = (
    {
      project,
      shouldRender={
        location: true,
        typology: true,
        rating: true,
        standard: true,
      }
    } ={}
  ) => {
    const {
      location: projectLocation,
      typology: projectTypology,
      rating: projectRating,
      certifications: projectCertifications
    } = project

    const blocks = [
      {
        shouldRender: shouldRender.location && projectLocation,
        props: {
          className: 'discover__project__tiny-info-block',
          title: intl.formatMessage(rfpCard.location),
          value: <span>
            <MapPinSimple/>
            {projectLocation && projectLocation.length > PROJECT_DETAILS_MAX_TINY_INFO_TAG_LENGTH
              ? renderTooltipForTrimmedText({
                text: projectLocation,
                maxLength: PROJECT_DETAILS_MAX_TINY_INFO_TAG_LENGTH,
                trimmedUpperCase: true
              })
              : <span style={{textTransform: 'uppercase'}}>{projectLocation}</span>}
         </span>,
          unit: 'unit'
        }
      },
      {
        shouldRender: shouldRender.typology && projectTypology,
        props: {
          className: 'discover__project__tiny-info-block',
          title: intl.formatMessage(rfpCard.projectType),
          value: <span>
            <Plant/>
            {
              projectTypology && projectTypology.length > PROJECT_DETAILS_MAX_TINY_INFO_TAG_LENGTH
                ? renderTooltipForTrimmedText({
                  text: projectTypology,
                  maxLength: PROJECT_DETAILS_MAX_TINY_INFO_TAG_LENGTH,
                  trimmedUpperCase: true
                })
                : <span style={{textTransform: 'uppercase'}}>{projectTypology}</span>
            }
          </span>
        }
      },
      {
        shouldRender: shouldRender.standard && projectCertifications && projectCertifications.length > 0,
        props: {
          className: 'discover__project__tiny-info-block',
          title: intl.formatMessage(rfpCard.registry),
          value: <span>
            <Certification/>
            {
              projectCertifications.filter((c, i) => i === 0).map(
                (cert, i) => <Tooltip
                  key={`tooltip-${i}`}
                  position={"bottom-right"}
                  content={cert.tooltip}
                >
                  {cert.name}
                </Tooltip>
              )
            }
          </span>
        }
      },
      {
        shouldRender: shouldRender.rating && projectRating,
        props: {
          className: 'discover__project__tiny-info-block',
          title: intl.formatMessage(rfpCard.beZeroRating),
          value: projectRating
        }
      },
    ]

    const blocksToRender = blocks.filter(b => b.shouldRender)

    return blocksToRender
      .filter(b => b.shouldRender)
      .map(
        (b, i) => <TinyInfoBlock
          key={`tiny-info-block-${i}`}
          index={`tiny-info-block-${i}`}
          divider={blocksToRender.length > i + 1}
          dividerSeparation={'S'}
          {...b.props}
        />
      )
  }

  const renderProjectMainActionButton = project => {
    let result
    if(project.proposalRequested) {
      result = <Button
        disabled
        className={'discover__project__button'}
      >
        <Clock />
        <FormattedMessage
          id={`discover.DiscoverProjectActionCollectingProposals`}
          defaultMessage={'Collecting Proposals'}
        />
        <Token
          className={'discover__project__button__collecting-proposals'}
          color={'green'}
          content={`${project.proposalReceivedCount}`}
        />
      </Button>
    }
    else {
      result = <Button
        className={'discover__project__button'}
        onClick={() => showRequestProposalModal(project.id)}
      >
        <Chats />
        <FormattedMessage
          id={`discover.DiscoverProjectActionRequest`}
          defaultMessage={'Request Proposals'}
        />
      </Button>
    }
    return result
  }

  const renderProject = (project, i) => {
    return <div key={`key-${project.id}-${i}`} className={'discover__project__container'}>
      <div className={'discover__project__image'}>
        <img alt={'Project depiction'} src={project.image || DEFAULT_PROJECT_IMAGE}/>
      </div>
      <div className={'discover__project__info'}>
        <div className={'discover__project__title-and-project-info-button'}>
          <div className={'discover__project__title'}>
            {project.name}
          </div>
          <ButtonLink
            to={links.getProjectUrl(project.id)}
            onClick={(e) => {
              e.preventDefault();
              window.open(links.getProjectUrl(project.id), '_blank')
            }}
            className={'discover__project__view-project'}
          >
            <div>
              <FormattedMessage
                id={`discover.DiscoverResultsProjectLink`}
                defaultMessage={'View Project'}
              />
              <ExternalLink />
            </div>
          </ButtonLink>
        </div>
        <div className={'discover__project__tiny-info-and-actions'}>
          <div className={'discover__project__tiny-info'}>
            {renderProjectTinyInfoBlocks({project})}
          </div>
          <div className={'discover__project__actions'}>
            {renderProjectMainActionButton(project)}
          </div>
        </div>
      </div>
    </div>
  }

  const renderResults = () => {
    return <div ref={scrollableResultsContainerRef} className={`discover__results ${isUpdatingSearch ? 'discover__results__loader' : ''}`}>
      {
        ((Object.values(filtersValues).length > 0 || searchString.length > 0) && didFirstLoad && (!isUpdatingLoadMore && !isUpdatingSearch) && searchResults.length === 0)
        && <div className={'discover__results__no-results'}>
          <p>
            No results were found for your search. Please adjust your search terms and/or filters.
          </p>
        </div>
      }
      {
        isUpdatingSearch
          ? <Loader />
          : searchResults.map(renderProject)
      }
      {
        isUpdatingLoadMore
          ? <div className={'discover__results__loading-more'}><Loader /></div>
          : null
      }
    </div>
  }

  const renderCorrectModals = () => {
    let result = null
    if(modalToShow === MODAL_CONTACT_REQUEST_PROPOSAL && userHasRequestProposalPermission) {
      result = <Modal
        clickClose={() => setModalToShow(null)}
        content={
        <RequestProposalModal
          searchResults={searchResults}
          selectedProjectId={selectedProjectId}
          setModalToShow={setModalToShow}
          setApproximateTargetVolume={setApproximateTargetVolume}
          approximateTargetVolume={approximateTargetVolume}
          setAdditionalDetails={setAdditionalDetails}
          additionalDetails={additionalDetails}
          renderProjectTinyInfoBlocks={renderProjectTinyInfoBlocks}
          handleSubmit={handleProposalRequestSubmit}
          intl={intl}
        />
      }
      />
    }
    else if(modalToShow === MODAL_CONTACT_REQUEST_PROPOSAL && !userHasRequestProposalPermission) {
      result = <Modal
        clickClose={() => setModalToShow(null)}
        content={
        <RequestAccessModal
          intl={intl}
          user={user}
        />
      }
      />
    }

    return result
  }

  const renderMain = () => <div className={'discover__container'}>
    {renderCorrectModals()}
    <div className={'discover__search-and-filters-container'}>
      {renderSearchAndFilters()}
    </div>
    {renderResults()}
  </div>

  return renderMain()
}

const mapStateToProps = state => ({
  user: getUserDetails(state)
})

export default connect(
  mapStateToProps,
)(
  injectIntl(DiscoverContainer)
)
