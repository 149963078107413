import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Divider from '../UI/Divider/Divider';
import { AUTH_METHOD } from '../../constants/authMethods';
import { authenticate, logout } from '../../actions/userSession';

import GoogleButton from './GoogleButton';
import FacebookButton from './FacebookButton';

class SocialButtons extends React.PureComponent {
  render() {
    const {
      authenticate,
      logout,
      location,
      activationId,
      handleLogin
    } = this.props;

    const nextUrl = new URLSearchParams(location.search).get('next');
    
    return (
      <div>
        <GoogleButton
          onSuccess={googleUser => {
            const data = {
              email: googleUser.getBasicProfile().getEmail(),
              auth_token: googleUser.getAuthResponse().id_token,
            }
            if(activationId){
              handleLogin(data, AUTH_METHOD.GOOGLE)
            } else {
              authenticate(data, AUTH_METHOD.GOOGLE, nextUrl)
            }
          }}
          onExternalLogout={logout}
        />
        <FacebookButton
          onSuccess={response => {
            const data = {
              email: response.email,
              auth_token: response.accessToken,
            }
            if(activationId){
              handleLogin(data, AUTH_METHOD.FACEBOOK)
            } else {
              authenticate(data, AUTH_METHOD.FACEBOOK, nextUrl)
            }
          }}
          onExternalLogout={logout}
        />
        <Divider className="login-divider" text="or" />
      </div>
    )
  }
}

export default connect(null, {
  authenticate,
  logout,
})(withRouter(SocialButtons))

