import getFeatureFlags from '../constants/featureFlags';
import { MONITORING_LEVEL } from '../constants/monitoringLevel';

export default function getIsImpactAvailable(forest) {
    const mrvFeatureFlag = getFeatureFlags().mrv;
    const monitoringLevel = forest.monitoring_level;

    if(mrvFeatureFlag && monitoringLevel ===  MONITORING_LEVEL.MONITORED){
        return true;
    }

    if(mrvFeatureFlag && monitoringLevel ===  MONITORING_LEVEL.MRV_MONITORED){
        return true;
    }

    return false;
};