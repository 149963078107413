import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedNumber } from 'react-intl';
import cx from 'classnames';
import { Box } from 'react-layout-components';

// utils
import { getFormattedArea, getFormattedCredits } from '../../utils/units';
import FormattedUnit from '../../components/Utils/FormattedUnit';

// actions
import { getCommunityMembers } from '../../actions/publicProfileCommunity'

// selectors
import * as communitySelectors from '../../selectors/publicProfileCommunity'

// components
import Pagination from "../UI/Pagination/Pagination";
import Text from '../../components/UI/Text/Text';
import Small from '../../components/UI/Text/Small';
import AvatarPlaceholder from '@reforestum/shared-components/dist/assets/images/avatar-placeholder.png';

// styles
import './CommunityContainer.css'
import CommunityPatronsPodium from "./CommunityPatronsPodium"

class CommunityContainer extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            currentPageIndex: 1,
            total: null
        }
    
        this.getMoreCommunityMembers = this.getMoreCommunityMembers.bind(this);
      }

    componentWillMount(){
        const {userName, projectsIds, sectorsIds, getCommunityMembers} = this.props
        const { currentPageIndex } = this.state
        getCommunityMembers(userName, projectsIds, sectorsIds, currentPageIndex)
    }

    componentWillReceiveProps(newProps){
        if(newProps.pagination && newProps.pagination.total){
            this.setState({ total: newProps.pagination.total})
        }
    }

    getMoreCommunityMembers(selectedPage){
        const {userName, projectsIds, sectorsIds, getCommunityMembers} = this.props
        getCommunityMembers(userName, projectsIds, sectorsIds, selectedPage)
    }

    render(){
        const {profileCommunity, profileLeaderboard, pagination} = this.props;

        return (
            <div className="community-container">
            <CommunityPatronsPodium profileLeaderboard={profileLeaderboard} />
            <Box column className="patrons-list">
                {profileCommunity && profileCommunity.length > 0
                    ? profileCommunity.map((patron, index) => (
                        <Box
                            className={cx(
                            'patron-item',
                            )}
                            alignItems="center"
                            key={index}
                        >
                            <Box alignItems="center" className="patron-item__patron">
                                <Text className="patron-item__rank">
                                    {(patron && pagination.from + (index + 1))}
                                </Text>
                                <div
                                    className="patron-item__avatar"
                                    style={{backgroundImage: `url(${
                                        (patron && patron.avatar_url && CSS.escape(patron.avatar_url))
                                        || AvatarPlaceholder
                                        })`
                                    }}
                                />
                            </Box>
                            <Box className={cx('patron-item__name__surface-wrapper')}>
                                <Text className="patron-item__name">
                                    {patron.display_name}
                                </Text>
                                {patron.sqm_amount
                                    ?
                                    <Small color="green">
                                        <FormattedNumber value={getFormattedArea(patron.sqm_amount).value} />
                                        &nbsp;
                                        <FormattedUnit unit={getFormattedArea(patron.sqm_amount).unit} />
                                    </Small>
                                    :
                                    <Small color="green">
                                        <FormattedNumber value={getFormattedCredits({credits: patron.vcu_amount}).value} />
                                        &nbsp;
                                        <FormattedUnit unit={getFormattedCredits({credits: patron.vcu_amount}).unit} />
                                    </Small>
                                }
                            </Box>
                        </Box>
                    ))
                    : null
                }
                {pagination && pagination.lastPage !== 1 &&
                    <Box className="community-container-pagination-container">
                        <Pagination
                            current={pagination.currentPage}
                            getMore={this.getMoreCommunityMembers}
                            pageLength={pagination.perPage}
                            total={this.state.total}
                            maxItems={5}
                        />
                    </Box>
                }
            </Box>
            </div>

        )
    }
}

CommunityContainer.propTypes  = {
    projectsAndSectorsIds: PropTypes.array.isRequired,
    intl: intlShape,
    userName: PropTypes.string,
    projectsIds: PropTypes.string,
    sectorsIds: PropTypes.string,
    getCommunityMembers: PropTypes.func,
    profileCommunity: PropTypes.array,
    profileLeaderboard: PropTypes.array,
    pagination: PropTypes.object
}

const mapStateToProps = state => ({
    errorMessages: communitySelectors.getErrorMessages(state),
    isFetching: communitySelectors.getIsFetching(state),
    // community
    profileCommunity: communitySelectors.getProfileCommunity(state),
    // leaderboard
    profileLeaderboard: communitySelectors.getProfileLeaderboard(state),
    // pagination
    pagination: communitySelectors.getPagination(state)
})

export default connect(
    mapStateToProps,
  {
    getCommunityMembers,
   }
  )(injectIntl(CommunityContainer));
