import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from 'react-intl';
import FormattedUnit from '../../Utils/FormattedUnit';

import Text from '../../UI/Text/Text';
import Bespoke from '../../UI/Icons/Bespoke';
import Car from '../../UI/Icons/Car';
import Information from '../../UI/Icons/Information';

import './MySharesForestDescription.css';

const MySharesForestDescription = ({ treesPerVcu, vcusWeight, vcus }) => {
    const DAILY_CAR_C02 = 4598 /*KG of CO2 emitted by a car in a year*/ / 365;
    return (
        <div>
            <div className="myShares-forest-description__wrapper">
                {treesPerVcu ? <Bespoke /> : <Car className="myShares-compensation-equivalent-icon"/>}
                <div className="myShares-forest-description-texts__wrapper">
                    <Text>
                        <FormattedMessage 
                            id={'ForestDetails.myCreditsMainTitleDescription'} 
                            defaultMessage={'So far your avoided emissions are {co2Amount} of CO2.'}
                            values={{ 
                                co2Amount: <Text color="green" tag="span">
                                    <FormattedNumber
                                        value={vcusWeight.value}
                                    /> <FormattedUnit unit={vcusWeight.unit} />
                                </Text>                            
                            }}
                        />
                    </Text>
                    {treesPerVcu 
                    ? 
                        <div>
                            <Text>
                                <FormattedMessage 
                                    id={'ForestDetails.myCreditsDescription'}
                                    defaultMessage={'That\'s the equivalent of {treesPerVcuAmount} trees protected from deforestation and harm!'}
                                    values={{ 
                                        treesPerVcuAmount: <Text color="green" tag="span">
                                            <FormattedNumber
                                                value={treesPerVcu*vcus.value}
                                            />
                                        </Text>
                                    }}
                                />
                            </Text>
                        </div>
                    :
                        <div>
                            <Text className="myShares-compensation-equivalent-text">
                                <FormattedMessage
                                    id={'Dashboard.compensedEquivalent'}
                                    defaultMessage={'That\'s the equivalent of {value} cars taken off the road for a day!'}
                                    values={{ 
                                        value: <Text color="green" tag="span">
                                        <FormattedNumber
                                            value={Number(vcusWeight.value / DAILY_CAR_C02).toFixed(0)}
                                            />
                                        </Text>
                                    }}
                                />
                            </Text>
                        </div>
                    }
                </div>

            </div>
    
                <div className={"myShares-forest-extra-info___wrapper"}>
                    <Information />
                    <Text className="myShares-forest-extra-info__text" color="light" weight="regular">
                        <FormattedMessage
                            id={'ForestDetails.myCreditsInformation'}
                            defaultMessage={'1 VCU (Verified Carbon Unit) = 1,000 Kg of CO2 emissions avoided or captured from the air.'}
                        />
                    </Text>
                </div>
        </div>
    )
};

MySharesForestDescription.propTypes = {
    intl: intlShape,
    treesPerVcu: PropTypes.number,
    vcusWeight: PropTypes.object
};

export default injectIntl(MySharesForestDescription);
