import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';


import './PersonalInformation.css';
import SettingHeader from '../../SettingHeader/SettingHeader';
import ProfilePictureSetting from '../../ProfilePictureSetting/ProfilePictureSetting';
import PersonalInformationForm from './PersonalInformationForm';

class PersonalInformation extends Component {


    render() {

        const {
            settingName, 
            user,
            addAlert,
            updateAvatar,
            updateUser,
            refreshProfile,
            countries,
            country,
            updateErrorMessages,
        } = this.props;
        
        const {
            avatar_url,
            id,
        } = user || {};


        const loadingInitialValues =  countries.length < 1 || Object.keys(user).length < 1

        const formInitialValues = {
            ...user,
            country: country,
            avatar_url: undefined,
            organizations: undefined,
        }

        return (
            <div>
                <SettingHeader settingName={settingName}/>
                <ProfilePictureSetting 
                    profilePictureUrl={avatar_url}
                    addAlert={addAlert}
                    updateAvatar={updateAvatar}
                    refreshProfile={refreshProfile}
                    id={id}
                    className='personal-information-profile-picture-setting'/>
                <PersonalInformationForm 
                    initialValues={formInitialValues}
                    countries={countries}
                    loadingInitialValues={loadingInitialValues}
                    updateUser={updateUser}
                    refreshProfile={refreshProfile}
                    updateErrorMessages={updateErrorMessages}
                    addAlert={addAlert}
                />
            </div>
        )
    }
}

PersonalInformation.propTypes = {
    settingName: PropTypes.string,
    user: PropTypes.object,
    addAlert: PropTypes.func,
    updateAvatar: PropTypes.func,
    intl: PropTypes.object,
    countries: PropTypes.array,
    updateErrorMessages: PropTypes.string,
};

export default injectIntl(PersonalInformation);
