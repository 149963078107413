import React from 'react'
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import './index.css';
import { injectIntl, intlShape } from 'react-intl';
import { ReadLessMessage, ReadMoreMessage } from '../../../constants/messages';

function reduceHtmlToCutLength(htmlText, cutLength, readMoreId, notExpandable) {
    let isShortened = false;
    let htmlObject = document.createElement('span');
    const htmlTextFormatted = htmlText ? htmlText.replace(/\u00a0/g, ' ') : null;
    htmlObject.innerHTML = htmlTextFormatted;

    let lengthAlreadyCounted = 0;
    let cutLengthAlreadyReached = false;
    let isReadMoreSet = false;

    function iterateChildren(node) {
        let indexToRemoveFrom;
        let numberOfChildren = node.childNodes.length;
        node.childNodes.forEach((childNode, index) => {

            //If cutLength already reached return without counting
            if (cutLengthAlreadyReached) {
                //If child index to remove from not setted set it to current index
                if(indexToRemoveFrom === undefined) {
                    indexToRemoveFrom = index;
                }
                    return;
            }

            //If element has childNodes iterate over them
            if(childNode.childNodes.length > 0) {
                iterateChildren(childNode);
                return;
            }

            if(!childNode.nodeValue) {
                return;
            }

            if ((childNode.nodeValue.length + lengthAlreadyCounted) > cutLength) {
                cutLengthAlreadyReached = true;
                indexToRemoveFrom = index + 1;
                isShortened = true;
                childNode.nodeValue = childNode.nodeValue.substring(0, cutLength - lengthAlreadyCounted - 1).concat('...');
                return;
            }

            lengthAlreadyCounted = lengthAlreadyCounted + childNode.nodeValue.length;
            if (lengthAlreadyCounted === cutLength) {
                cutLengthAlreadyReached = true;
                indexToRemoveFrom = index + 1;
            }

        });

        //Remove nodes after the index where the text has been truncated
        for ( let i = indexToRemoveFrom; i <= numberOfChildren -1; i++) {
                        node.removeChild(node.lastChild);
        }

        //remove element from parent component if we need to remove all childs
        if(indexToRemoveFrom === 0) {
            node.parentNode.removeChild(node);
        }

        //After the truncated text a node with readMoreId is injected
        //So we can later inject a react 'Read More' button
        if (cutLengthAlreadyReached && !isReadMoreSet && !notExpandable) {
            let readMoreNode = document.createElement("span");
            readMoreNode.setAttribute('id', readMoreId);

            //If the node is an anchor, insert the readMoreNode after the anchor
            if(node.tagName === 'A') {
                node.parentNode.insertBefore(readMoreNode, node.nextSibling);
            } else {
            node.appendChild(readMoreNode);
            isReadMoreSet = true;
            }
        }
    }


    iterateChildren(htmlObject);
    
    return [
        htmlObject.innerHTML,
        isShortened,
    ]
}

const ReadMoreButton = (({text, ...props}) => 
    (<button {...props} className="read-more_button">
        {text}
    </button>)
)

const ReadLessButton = (({text, ...props}) => 
    (<button {...props} className="read-less_button">
        {text}
    </button>)
)
class ReadMore extends React.Component {

    switchExpandedState(e) {
        //prevent click to propagate to parent elements
        e.stopPropagation();
        this.setState({
            textExpanded: !this.state.textExpanded,
        })
    }

    constructor(props) {
        const {intl} = props;
        super(props);
        this.state = {
            readMoreId: `read-more-${new Date().getTime()}${Number((Math.random() * 1000000).toFixed(0))}`,
            readMoreText: intl.formatMessage(ReadMoreMessage.text),
            readLessText: intl.formatMessage(ReadLessMessage.text)
        }

        this.switchExpandedState = this.switchExpandedState.bind(this);
    }

    componentDidMount() {
        const {readMoreText} = this.state;
        if (document.getElementById(`${this.state.readMoreId}`)) {
            ReactDOM.render(<ReadMoreButton onClick={this.switchExpandedState} text={readMoreText}/>, document.querySelector(`#${this.state.readMoreId}`));
        }
    }

    componentDidUpdate() {
        const {readMoreText} = this.state;
        if (document.getElementById(`${this.state.readMoreId}`)) {
            ReactDOM.render(<ReadMoreButton text={readMoreText} onClick={(this.switchExpandedState)}/>, document.querySelector(`#${this.state.readMoreId}`));
        }
    }

    renderText(text, isHtml, showReadMore = false) {
        const {textExpanded, readMoreText, readLessText} = this.state;
        return (
            <div>
                {
                    isHtml ? <span dangerouslySetInnerHTML={{__html: text}}/> :
                        (<span>{text}{showReadMore && <ReadMoreButton onClick={this.switchExpandedState} text={readMoreText}/>}</span>)
                }
               {textExpanded && <ReadLessButton onClick={this.switchExpandedState} text={readLessText}/>} 
            </div>
        );
    }


    render() {
    let isShortened;
    let textToRender; 
    const { readMoreId, textExpanded} = this.state;
    const {text, isHtml, cutLength, minLength = 0, notExpandable = false } = this.props;
    
    if(textExpanded) {
        return this.renderText(text, isHtml, false);
    }

    if (isHtml) {
        //check if the text is longer than the min length
        [textToRender, isShortened] = reduceHtmlToCutLength(text, minLength, readMoreId, notExpandable);
        if(isShortened) {
            //In case shortened, get the reducedHtml to render
            [textToRender] = reduceHtmlToCutLength(text, cutLength, readMoreId, notExpandable);
        }

    } else {
        isShortened = text.length > cutLength;
        textToRender =  text.length > minLength ? text.substring(0, cutLength).concat('...') : text;
    }

    return this.renderText(textToRender, isHtml, isShortened);
    }
}

ReadMore.propTypes = {
    text: PropTypes.string,
    isHtml: PropTypes.bool,
    cutLength: PropTypes.number,
    minLength: PropTypes.number,
    notExpandable: PropTypes.bool,
    intl: intlShape,
  };

export default  injectIntl(ReadMore);