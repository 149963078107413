import React from 'react';
import PropTypes from 'prop-types';
import FrequencyForm from './FrequencyForm';
import Total from './Total';

import Bottom from '../Layout/Container/Bottom';

const Footer = ({
  calcErrorMessages,
  calculatedCarbon,
  change,
  formSubmit,
  formValues,
  handleSubmit,
  handleUpdateSubmit,
  isAdding,
  isEditing,
  isCalculating,
  isFetching,
  stepErrorMessages,
  valid,
  sourceToEdit,
  resetForm,
  isSmallWidth,
  calculatedPath,
  calculatedPathOfSourceToEdit,
  nameOfSourceToEdit,
  calculatedCarbonOfSourceToEdit,
  stepsList
}) => (
  <div>
    <FrequencyForm change={change} />
    <Bottom className="carbon-calculator__footer">
      <Total
        isSmallWidth={isSmallWidth}
        calcErrorMessages={calcErrorMessages}
        calculatedCarbon={calculatedCarbon}
        calculatedPath={calculatedPath}
        calculatedPathOfSourceToEdit={calculatedPathOfSourceToEdit}
        currentSourceName={formValues && formValues.field_last ? formValues.field_last : undefined}
        nameOfSourceToEdit={nameOfSourceToEdit}
        calculatedCarbonOfSourceToEdit={calculatedCarbonOfSourceToEdit}
        formSubmit={formSubmit}
        frequency={formValues && formValues['field_frequency']}
        handleSubmit={handleSubmit}
        handleUpdateSubmit={handleUpdateSubmit}
        isAdding={isAdding}
        isEditing={isEditing}
        isCalculating={isCalculating}
        isFetching={isFetching}
        stepErrorMessages={stepErrorMessages}
        valid={valid}
        sourceToEdit={sourceToEdit}
        resetForm={resetForm}
        stepsList={stepsList}
      />
    </Bottom>
  </div>
);

Footer.propTypes = {
  calcErrorMessages: PropTypes.string,
  calculatedCarbon: PropTypes.number,
  change: PropTypes.func.isRequired,
  formSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  handleUpdateSubmit: PropTypes.func.isRequired,
  sourceToEdit: PropTypes.number,
  isAdding: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isCalculating: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  stepErrorMessages: PropTypes.bool,
  valid: PropTypes.bool.isRequired,
  resetForm: PropTypes.func,
  isSmallWidth: PropTypes.bool,
  stepsList: PropTypes.array
};

export default Footer;
