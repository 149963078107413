import React from 'react';

const ThreeDots = props => (
	<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M12.9043 13C13.4566 13 13.9043 12.5523 13.9043 12C13.9043 11.4477 13.4566 11 12.9043 11C12.352 11 11.9043 11.4477 11.9043 12C11.9043 12.5523 12.352 13 12.9043 13Z" fill="#1F3B21" stroke="#1F3B21" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.9043 6C13.4566 6 13.9043 5.55228 13.9043 5C13.9043 4.44772 13.4566 4 12.9043 4C12.352 4 11.9043 4.44772 11.9043 5C11.9043 5.55228 12.352 6 12.9043 6Z" fill="#1F3B21" stroke="#1F3B21" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.9043 20C13.4566 20 13.9043 19.5523 13.9043 19C13.9043 18.4477 13.4566 18 12.9043 18C12.352 18 11.9043 18.4477 11.9043 19C11.9043 19.5523 12.352 20 12.9043 20Z" fill="#1F3B21" stroke="#1F3B21" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

export default ThreeDots;