import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'react-layout-components';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';

import Text from '../../UI/Text/Text';
import Small from '../../UI/Text/Small';
import IconText from '../../UI/Text/IconText';
import { Button } from '@reforestum/shared-components';

import CarbonCloud from '../../UI/Icons/CarbonCloud';
import SurfaceArrowsOut from '../../UI/Icons/SurfaceArrowsOut';
import CarbonCredits from '../../UI/Icons/CarbonCredits';

import FormattedUnit from '../../Utils/FormattedUnit';
import {OFFSET_TYPE_SQM_SHARES} from "../../../constants/offsetTypes";

import {getFormattedWeight, getFormattedArea, getFormattedVCUs} from '../../../utils/units';

import './MyOtherForestsItem.css';

import { links } from '@reforestum/shared-utils';

const MyOtherForestsItem = ({ forest }) => {

    return (
      <a href={links.getProjectUrl(forest.id)}>
        <Box
          justifyContent="space-between"
          alignItems="center"
          className="myOtherForest-item">

          <Box center>
            <div
              className="myOtherForest-item__image"
              style={{
                backgroundImage: `url(${forest.main_image})`
              }}
            />
    
            <Box column className="carbon-item__content">
    
              <Text tag="h3" className="myOtherForest-item__text">
                {forest.name}
              </Text>
    
              { forest.offset_type === OFFSET_TYPE_SQM_SHARES ?
                <Box className="carbon-item__info">
                  <IconText icon={SurfaceArrowsOut} className="myOtherForest-item__carbon">
                    <Small color="light">
                      <FormattedNumber
                        value={getFormattedArea(forest.user_total_surface).value}
                      /> <FormattedUnit unit={getFormattedArea(forest.user_total_surface).unit} />
                    </Small>
                  </IconText>
                  <IconText icon={CarbonCloud} className="myOtherForest-item__carbon">
                    <Small color="green">
                      <FormattedNumber
                        value={getFormattedWeight(forest.captured_co2).value}
                      /> <FormattedUnit unit={getFormattedWeight(forest.captured_co2).unit} />
                    </Small>
                  </IconText>
                </Box>
              :
              <Box className="carbon-item__info">
                <IconText icon={CarbonCredits} className="myOtherForest-item__carbon">
                  <Small color="light">
                    <FormattedNumber
                        value={getFormattedVCUs(forest.captured_co2).value}
                    /> <FormattedUnit unit={getFormattedVCUs(forest.captured_co2).unit} />
                  </Small>
                </IconText>
                <IconText icon={CarbonCloud} className="myOtherForest-item__carbon">
                  <Small color="green">
                    <FormattedNumber
                        value={getFormattedWeight(forest.captured_co2).value}
                    /> <FormattedUnit unit={getFormattedWeight(forest.captured_co2).unit} />
                  </Small>
                </IconText>
              </Box>
              }
    
            </Box>
          </Box>
            <Button className='myOtherForest-item-explore__button'>
                <FormattedMessage 
                  id={'ForestItemMyShares.explore'}
                  defaultMessage={'Explore'}
                />
            </Button>
        </Box>
        </a>
      );
} 

MyOtherForestsItem.propTypes = {
  forest: PropTypes.object.isRequired,
};

export default injectIntl(MyOtherForestsItem);
