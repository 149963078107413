import React from 'react';

const Bespoke = () => (
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.1288 33.6635C15.1288 33.6635 19.5904 27.5678 29.6521 30.4412C29.6521 30.4412 40.6684 25.2727 44.4507 36.6655C44.4507 36.6655 56.5045 40.0255 50.574 50.3075" stroke="#60B47D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.37964 47.076L10.6669 33.5442L26.0164 33.9482C27.0451 33.9762 28.056 34.2235 28.9816 34.6734L41.4852 40.7324C41.9033 40.9372 42.2342 41.285 42.4178 41.7128C42.6014 42.1407 42.6255 42.6201 42.4859 43.0642V43.0642C42.351 43.498 42.0666 43.8698 41.6833 44.1135C41.3001 44.3573 40.8428 44.4572 40.3928 44.3954L27.9626 42.6603L37.4642 49.0865C37.9132 49.3922 38.4368 49.5699 38.979 49.6006L54.7416 50.5187C55.2888 50.581 55.7891 50.8573 56.1331 51.2874C56.4772 51.7174 56.637 52.2662 56.5777 52.8137V52.8137C56.5278 53.2836 56.3183 53.7222 55.9842 54.0563C55.6501 54.3905 55.2115 54.5999 54.7416 54.6498L40.4754 56.4859C39.6855 56.5871 38.8839 56.5499 38.1069 56.3757L6.37964 47.076Z" stroke="#1F3B21" strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M10.667 33.5443L6.37058 32.1581L1.45911 47.682L5.7555 49.0682L10.667 33.5443Z" stroke="#1F3B21" strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M38.768 29.9179C39.5208 25.594 40.6041 16.6982 38.2539 10.1527C38.2539 10.1527 37.9785 -0.2762 23.9326 1.70675C23.9326 1.70675 23.9326 12.2733 38.2539 10.1527" stroke="#60B47D" strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M39.6862 20.1407C39.6862 20.1407 42.1832 12.2915 52.6029 14.4948C52.6029 14.4948 50.5006 23.4823 39.6862 20.1407Z" stroke="#60B47D" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
)


export default Bespoke;