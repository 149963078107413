import React from 'react';
import './RequestForProposalsResults.scss';
import { FormattedMessage } from 'react-intl';
import Modal from '../components/UI/Modal/Modal'
import Mail from '../components/UI/Icons/Mail';
import { Button } from '@reforestum/shared-components';
import store from '../setup/store';
import { addAlert } from '../actions/alerts';
import { rfpCard } from '../constants/messages';

function ContactSupplierModal({ onClose, email, setModalToShow, intl }) {
    function copyEmailToClipboard() {
        navigator.clipboard.writeText(email)
        .then(() => {
          setModalToShow(null)
          store.dispatch(addAlert(
            {
              type: 'success',
              message: intl.formatMessage(rfpCard.SupplierEmailCopied),
              dismissAfter: 8000
            }
          ))
        })
    }
  
    return (
      <Modal
        clickClose={onClose}
        content={
          <div>
            <h1>
              <FormattedMessage
                id={'RfpResults.ContactSupplierTitle'}
                defaultMessage={'Contact Supplier'}
              />
            </h1>
            <p>
              <FormattedMessage
                id={'RfpResults.ContactSupplierP1'}
                defaultMessage={
                  'We are working on a in platform messaging system that will centralise and simplify all communications with suppliers that related with a given proposal.'
                }
              />
            </p>
            <p>
              <FormattedMessage
                id={'RfpResults.ContactSupplierP2'}
                defaultMessage={
                  'For the time being you can contact directly the supplier via email discuss the proposal and discuss additional contracting conditions:'
                }
              />
            </p>
            <div className={'rfp-p-card__mail-to-link-container'}>
              <a href={`mailto:${email}`}>{email}</a>
            </div>
            <div className={'rfp-modal-button-container'}>
              <Button onClick={copyEmailToClipboard}>
                <Mail />
                <FormattedMessage
                  id={'RfpResults.ContactSupplierButtonText'}
                  defaultMessage={'Copy Supplier Email'}
                />
              </Button>
            </div>
          </div>
        }
      />
    );
  }

  export default ContactSupplierModal;