import React from 'react';

const PublicTransport = props => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<circle cx="24" cy="24" r="24" fill="#D7E8CF"/>
<g clip-path="url(#clip0_1778_14614)">
<path d="M15.8305 35.1359H10.0554L15.8305 27.7345L10.7933 27.7346L15.8305 21.2216" stroke="#8DB99D" stroke-width="1.2" stroke-linecap="square"/>
<path d="M15.8306 35.136V38.5694" stroke="#8DB99D" stroke-width="1.2" stroke-linecap="square"/>
<path d="M32.8318 35.618H38.6068L32.8318 28.2241L37.869 28.2242L32.8318 21.7113H36.604L32.8318 15.1983" stroke="#8DB99D" stroke-width="1.2" stroke-linecap="square"/>
<path d="M32.832 35.6178V39.0587" stroke="#8DB99D" stroke-width="1.2" stroke-linecap="square"/>
<path d="M24.6551 18.0968L25.1018 17.7514H24.2084L24.6551 18.0968ZM18.5714 25.9651L18.1246 25.6197L17.4209 26.5298H18.5714V25.9651ZM30.7388 25.9651V26.5298H31.8893L31.1856 25.6197L30.7388 25.9651ZM24.2084 17.7514L18.1246 25.6197L19.0181 26.3105L25.1018 18.4423L24.2084 17.7514ZM18.5714 26.5298H30.7388V25.4004H18.5714V26.5298ZM31.1856 25.6197L25.1018 17.7514L24.2084 18.4423L30.2921 26.3105L31.1856 25.6197Z" fill="#1F3B20"/>
<path d="M24.6551 10.2287L20.0923 18.097H29.2104L24.6551 10.2287Z" stroke="#1F3B20" stroke-width="1.2" stroke-linecap="square"/>
<path d="M24.655 25.9653L25.0777 25.5907L24.2326 25.5905L24.655 25.9653ZM17.6752 33.8335L17.2528 33.4587L16.4194 34.3982H17.6752V33.8335ZM31.6272 33.8335V34.3982H32.8822L32.0499 33.4589L31.6272 33.8335ZM24.2326 25.5905L17.2528 33.4587L18.0977 34.2082L25.0775 26.3399L24.2326 25.5905ZM17.6752 34.3982H31.6272V33.2688H17.6752V34.3982ZM32.0499 33.4589L25.0777 25.5907L24.2324 26.3398L31.2045 34.208L32.0499 33.4589Z" fill="#1F3B20"/>
<path d="M24.655 33.8334V39.0588" stroke="#1F3B20" stroke-width="1.2" stroke-linecap="square"/>
<path d="M13.376 16.9826C15.6173 16.9826 17.4343 15.1824 17.4343 12.9619C17.4343 10.7413 15.6173 8.94116 13.376 8.94116C11.1346 8.94116 9.31763 10.7413 9.31763 12.9619C9.31763 15.1824 11.1346 16.9826 13.376 16.9826Z" stroke="#8DB99D" stroke-width="1.2" stroke-linecap="square"/>
</g>
<defs>
<clipPath id="clip0_1778_14614">
<rect width="32" height="32" fill="white" transform="translate(8 8)"/>
</clipPath>
</defs>
</svg>



export default PublicTransport;
