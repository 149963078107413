import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box } from 'react-layout-components';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { axiosV1Instance } from '../../../actions';
import { requiredIf } from '../../../utils/requiredIf';
import { showModal } from '../../../actions/modal';
import { addAlert } from '../../../actions/alerts';
import {
  MODAL_FOREST_NOTIFY,
  MODAL_DEFAULT,
} from '../../../constants/modalTypes';
import {
  getIsUserAuthenticated,
  getUserDetails,
} from '../../../selectors/userSession';

import ForestGalleryContainer from '../../../containers/ForestGalleryContainer';
import HeadingSmall from '../../UI/Text/HeadingSmall';
import Text from '../../UI/Text/Text';
import { DisabledButton } from '@reforestum/shared-components';
import { ButtonLink } from '@reforestum/shared-components';
import { Button } from '@reforestum/shared-components';
import IconLink from '../../UI/Text/IconLink';
import Eye from '../../UI/Icons/Eye';
import ForestData from './ForestData';
import ForestNotification from '../../UI/Icons/ForestNotification';
import Tooltip from '../../UI/Tooltip/Tooltip';
import HasAccountLink from '../../UI/Modal/HasAccountLink';

import history from '../../../setup/history';
import {notifyForestAlertMessages, notifyForestTooltipMessages, modalNotifyForestNotAuthMessages} from '../../../constants/messages'

import getFeatureFlags from '../../../constants/featureFlags';

import './Forest.css';
import { links } from '@reforestum/shared-utils';

const Forest = ({
  forest,
  getSelectedOffsetData,
  openForestDetails,
  available,
  thankYou,
  windowWidth,
  isPaymentPossible,
  purchasable,
  showModal,
  isAuthenticated,
  addAlert,
  intl,
  currency=null,
  user,
  userName
}) => {

  const ff = getFeatureFlags();

  return (
    <Box className="create-forest-item">
      {windowWidth <= 768 &&
        !thankYou && (
          <Box column className="create-forest-item__info">
            <HeadingSmall className="create-forest-item__name">
              {forest.name}
            </HeadingSmall>
            <Text color="light">{forest.location_desc}</Text>
            {ff.viewForestDetailsInModal &&
              <IconLink
                className="create-forest-item__view-details"
                icon={Eye}
                onClick={() => {
                  openForestDetails(forest.id);
                }}
              >
                <Text color="light">
                  <FormattedMessage 
                    id={'Forest.viewDetails'} 
                    defaultMessage={'View Details'}  
                  />
                </Text>
              </IconLink>
            }
          </Box>
        )}

      <Box flexBasis="50%" className="create-forest-item__image">
        <ForestGalleryContainer
          image={forest.main_image}
          forestId={forest.id}
        />
      </Box>
      <Box
        column
        flexBasis="50%"
        alignItems="flex-start"
        justifyContent="space-between"
        className="create-forest-item__content"
      >
        {(windowWidth > 768 || thankYou) && (
          <Box column className="create-forest-item__info">
            <HeadingSmall className="create-forest-item__name">
              {forest.name}
            </HeadingSmall>
            <Text color="light">{forest.location_desc}</Text>
            <br/>
            {userName &&             
            <ButtonLink 
                to={{
                  pathname: links.getProjectUrl(forest.id),
                }}
              >
                <FormattedMessage
                    id={"Forest.viewDetails"}
                    defaultMessage={'View Details'}
                />
              </ButtonLink>}
          </Box>
        )}

        <Box column className="create-forest-item__action">
          {(available || thankYou) && (
            <ForestData
              className="create-forest-item__data"
              data={available ? getSelectedOffsetData() : {}}
              currency={currency}
            />
          )}

          {!thankYou &&
            purchasable && (
              <Box className="create-forest-item__last">
                {!available && (
                  <Tooltip content={intl.formatMessage(notifyForestTooltipMessages.text)}>
                    <Button
                      outline
                      className="create-forest-item__last-button"
                      onClick={() => {
                        if (isAuthenticated) {
                          return showModal(MODAL_FOREST_NOTIFY, {
                            imageSrc: forest.main_image,
                            forestName: forest.name,
                            onConfirm: async () => {
                              try {
                                await axiosV1Instance.put(`/forests/${forest.id}/notify-availability`);

                                addAlert({
                                  type: 'success',
                                  message: intl.formatMessage(notifyForestAlertMessages.successMessage),
                                  label: intl.formatMessage(notifyForestAlertMessages.successLabel),
                                  dismissAfter: 5000,
                                });
                              } catch (err) {
                                addAlert({
                                  type: 'error',
                                  message: intl.formatMessage(notifyForestAlertMessages.errorMessage),
                                  label: intl.formatMessage(notifyForestAlertMessages.errorLabel),
                                  dismissAfter: 5000,
                                });
                              }

                            },
                            // @INTL
                            confirmButtonText:
                              'ModalNotifyForest.confirmButtonText',
                            cancelButtonText:
                              'ModalNotifyForest.cancelButtonText',
                          });
                        }
                        return showModal(MODAL_DEFAULT, {
                          imageSrc: forest.main_image,
                          title: intl.formatMessage(modalNotifyForestNotAuthMessages.title),
                          text: intl.formatMessage(modalNotifyForestNotAuthMessages.text),
                          confirmButtonText: intl.formatMessage(modalNotifyForestNotAuthMessages.confirmButtonText),
                          cancelButtonText: intl.formatMessage(modalNotifyForestNotAuthMessages.cancelButtonText),
                          onConfirm: () => {
                            history.push(links.getRegisterUrl());
                          },
                          footerTextComponent: HasAccountLink,
                        });
                      }}
                    >
                      <ForestNotification className="create-forest-item__last-button-icon" />
                      <FormattedMessage
                        id={'Forest.notifyMe'}
                        defaultMessage={'Notify me'}
                      />
                    </Button>
                  </Tooltip>
                )}

                {available &&
                  (!getSelectedOffsetData().price || getSelectedOffsetData().price <= 0 ? (
                    <DisabledButton>
                      <FormattedMessage
                        id={'Forest.chooseForest'}
                        defaultMessage={'Choose Forest'}
                      />
                    </DisabledButton>
                  ) : (
                      <ButtonLink
                        to={
                          `${links.getCheckoutUrl()}?forest=${forest.id}` +
                          `&mode=${getSelectedOffsetData().mode}` +
                          `&quantity=${getSelectedOffsetData().quantity}` +
                          `&offsetType=${getSelectedOffsetData().offsetType}` +
                          ((getSelectedOffsetData().selectedSources)?`&sources=${getSelectedOffsetData().selectedSources}`:``)
                        }
                        onClick={() => {
                          isPaymentPossible(forest.id, getSelectedOffsetData().quantity);
                        }}
                      >
                        <FormattedMessage
                          id={'Forest.chooseForest'}
                          defaultMessage={'Choose Forest'}
                        />
                      </ButtonLink>
                    ))}

                {ff.viewForestDetailsInModal && windowWidth > 768 && (
                  <IconLink
                    className="create-forest-item__view-details"
                    icon={Eye}
                    onClick={() => {
                      openForestDetails(forest.id);
                    }}
                  >
                    <Text color="light">
                      <FormattedMessage
                        id={'Forest.viewDetails'}
                        defaultMessage={'View Details'}
                        />
                    </Text>
                  </IconLink>
                )}
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
};

Forest.propTypes = {
  available: PropTypes.bool.isRequired,
  purchasable: PropTypes.bool.isRequired,
  forest: PropTypes.object.isRequired,
  getSelectedOffsetData: PropTypes.func.isRequired,
  openForestDetails: requiredIf(
    PropTypes.func,
    (props) => props.type === 'function',
  ),
  thankYou: PropTypes.bool,
  windowWidth: PropTypes.number,
  isPaymentPossible: PropTypes.func,
  currency: PropTypes.string,
  intl: intlShape,
};

const mapStateToProps = (state) => ({
  isAuthenticated: getIsUserAuthenticated(state),
  user: getUserDetails(state)
});

export default connect(
  mapStateToProps,
  { showModal, addAlert },
)(injectIntl(Forest));
