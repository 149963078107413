import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import api from '../../constants/api';
import { calculatorMessages } from '../../constants/messages';

import StepTypeahead from './StepTypeahead';

const pathContainsToFromAddressInformation = path => {
  if (!Array.isArray(path)) return false
  if (path.length < path.length - 1) return false
  return Array.isArray(path[path.length - 1][1])
}

class StepAddresses extends Component {
  constructor(props) {
    super(props);

    this.fields = {
      [`field_${props.step.id}_1`]: false,
      [`field_${props.step.id}_2`]: false,
    };

    this.handleNextStep = this.handleNextStep.bind(this);
    this.allFieldsFilled = this.allFieldsFilled.bind(this);
  }

  componentWillUnmount() {
    const { step, change } = this.props;
    change(`field_${step.id}_1`, '');
    change(`field_${step.id}_2`, '');
  }

  allFieldsFilled() {
    return !Object.keys(this.fields).some(key => this.fields[key] === false);
  }

  handleNextStep(step, nextStepId, value, isFinal, fieldId) {
    const { handleNextStep, addAlert, intl } = this.props;
    this.fields[`field_${fieldId}`] = value;

    if (this.allFieldsFilled()) {
      const from = this.fields[`field_${step.id}_1`];
      const to = this.fields[`field_${step.id}_2`];

      fetch(
        `${api.CALCULATOR_PLACES_DISTANCE_URL}?origin=${encodeURIComponent(from.placeId)}&destination=${encodeURIComponent(to.placeId)}`,
        {headers: new Headers({Authorization: `Bearer ${this.props.sessionToken}`})}
      )
        .then(response => response.json())
        .then(response => {
          const result = response.meters
          if ('meters' in response) {
            const distanceInKm = result / 1000;
            handleNextStep(step, nextStepId, [from.placeId, to.placeId, distanceInKm], isFinal)
          } else {
            addAlert({
              type: 'warning',
              message: intl.formatMessage(calculatorMessages.noWalkingDirections, {
                origin: from.name,
                destination: to.name
              }),
              dismissAfter: 3000
            })
          }
        })
    }
  }

  useAddressFromGoogleAPIOrForm(fieldNumber, formValues, step, path) {
    if(pathContainsToFromAddressInformation(path)){
      if(fieldNumber === 1) return path[path.length - 1][1][0]
      if(fieldNumber === 2) return path[path.length - 1][1][1]
    }
    return ''
  }

  addValueToField(oneOrTwo, value) {
    if(oneOrTwo === 1) this.fields[`field_${this.props.step.id}_1`] = value
    else this.fields[`field_${this.props.step.id}_2`] = value
  }

  render() {
    const { disabled, renderPostext, intl, formValues, step, sessionToken, stepIndex, isMostRecentStep, jumpBackToStepIndex} = this.props
    let sourcesDict = {}
    this.props.sources.forEach(e => sourcesDict[e.id] = e)
    let path
    if(sourcesDict[this.props.sourceToEdit] && sourcesDict[this.props.sourceToEdit].path) {
      path = JSON.parse(sourcesDict[this.props.sourceToEdit].path)
    }
    return (
      <div className="line-input__input-holder">
        <StepTypeahead
          disabled={disabled}
          isMostRecentStep={isMostRecentStep}
          jumpBackToStepIndex={jumpBackToStepIndex}
          stepIndex={`${stepIndex}_1`}
          fieldId={`${step.id}_1`}
          handleNextStep={this.handleNextStep}
          intl={intl}
          step={step}
          usePlacesAPI={true}
          value={formValues[`field_${step.id}_1`]}
          sessionToken={sessionToken}
          initialValue={this.useAddressFromGoogleAPIOrForm(1, formValues, step, path)}
          oneOrTwo={1}
          addValueToField={this.addValueToField.bind(this)}
        />
        {renderPostext('line-input__text-middle')}
        <StepTypeahead
          disabled={disabled}
          isMostRecentStep={isMostRecentStep}
          jumpBackToStepIndex={jumpBackToStepIndex}
          stepIndex={`${stepIndex}_2`}
          fieldId={`${step.id}_2`}
          handleNextStep={this.handleNextStep}
          intl={intl}
          step={step}
          usePlacesAPI={true}
          value={formValues[`field_${step.id}_2`]}
          sessionToken={sessionToken}
          initialValue={this.useAddressFromGoogleAPIOrForm(2, formValues, step, path)}
          oneOrTwo={2}
          addValueToField={this.addValueToField.bind(this)}
        />
      </div>
    );
  }
}

StepAddresses.propTypes = {
  addAlert: PropTypes.func.isRequired,
  renderPostext: PropTypes.func.isRequired,
  step: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  sessionToken: PropTypes.string.isRequired,
};

export default StepAddresses;
