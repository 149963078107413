import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector, getFormMeta, reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';


import './OrganizationInformationForm.css';
import Row from '../../../UI/Forms/Row';
import Input from '../../../UI/Forms/Input';
import Typeahead from '../../../UI/Forms/Typeahead';
import { addressMessages, AlertMessages, Languages, settingsMessages } from '../../../../constants/messages';
import { withIntl } from '../../../../utils/intl';
import { maxLength, profileDomainRequired, required, validCountry, validFoundationYear, validProfileDomain } from '../../../../utils/formHelpers';
import { getPrivacyOptionsForCompany } from '../../../../utils/getPrivacyOptions';
 
import { USER_FIELDS_MAX_LENGTH } from '../../../../constants/userFieldsMaxLength';
import CustomSelect from '../../../UI/Forms/CustomSelectNew';
import { ORG_FIELDS_MAX_LENGTH } from '../../../../constants/orgFieldsMaxLength';
import Checkbox from '../../../UI/Forms/Checkbox';
import { connect } from 'react-redux';
import { Tab, TabPanel, Tabs } from 'react-tabs';
import TabList from 'react-tabs/lib/components/TabList';
import BACKEND_LOCALES from '../../../../constants/backendLocales';
import Textarea from '../../../UI/Forms/Textarea';
import { Button } from '@reforestum/shared-components';
import CopyToClipboardIcon from '../../../UI/Icons/CopyToClipboardIcon';
class OrganizationInformationForm extends Component {
    constructor(props) {
        const { intl, createNewOrgScreen } = props;
        super(props);
        this.state = {
            privacyOptions: getPrivacyOptionsForCompany(intl),
            privacy: createNewOrgScreen? Number(getPrivacyOptionsForCompany(intl)[0].value) : null,
            updatingOrg: false,
        }
        this.submitForm = this.submitForm.bind(this);
        this.handlePrivacySelectChange = this.handlePrivacySelectChange.bind(this);
        this.hasPrivacyChanged = this.hasPrivacyChanged.bind(this)
        this.hasCountryChanged = this.hasCountryChanged.bind(this)
        this.processEnvStatementsAndBios = this.processEnvStatementsAndBios.bind(this)
        this.copyProfileDomainUrlClicked = this.copyProfileDomainUrlClicked.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        const { initialValues } = nextProps;
        const { addAlert, formMeta } = this.props;

        //Updates display name when it was initially empty and the field is still untouched
        if(
            (nextProps.companyName !== this.props.companyName) && 
            (!initialValues.display_name) && 
            !(formMeta && formMeta.display_name && formMeta.display_name.visited)
        ) {
            this.props.change("display_name", nextProps.companyName || "")
        }
        
        //Sets the privacy level to the initial value
        if(initialValues && (initialValues.privacy !== undefined) && this.state.privacy === null) {
            this.setState({
                privacy: initialValues.privacy
            })
        }

        //Shows update user error message if the user update returns an error
        if(nextProps.updateErrorMessages && (nextProps.updateErrorMessages !== this.props.updateErrorMessages)) {
            addAlert({
                type: 'error',
                message: nextProps.updateErrorMessages,
                dismissAfter: 5000,
            })
        }
    } 

    handlePrivacySelectChange(selectedOption) {
        this.setState({
            privacy: Number(selectedOption.value),
        })
    }

    hasPrivacyChanged() {
        const {privacy} = this.state;
        const initialPrivacy = this.props.initialValues.privacy;
        return Number(privacy) !== Number(initialPrivacy);
    }

    hasCountryChanged() {
        const {country} = this.props.initialValues;
        const {country: initialCountry} = this.props.initialValues;
        return country !== initialCountry;
    }

    processEnvStatementsAndBios(form) {
        let firstNotNullableEnvStatement = null;
        let firstNotNullableBio = null;
        const envStatementKey = 'environmental_statement_'
        const bioKey = 'bio_'

        BACKEND_LOCALES.forEach((locale) => {
            if(form[`${envStatementKey}${locale.backendKey}`] && !firstNotNullableEnvStatement) {
                firstNotNullableEnvStatement = form[`${envStatementKey}${locale.backendKey}`]
            }

            if(form[`${bioKey}${locale.backendKey}`] && !firstNotNullableBio) {
                firstNotNullableBio = form[`${bioKey}${locale.backendKey}`]
            }

        })

        return BACKEND_LOCALES.reduce((acc, locale) => {
            return {
                ...acc,
                [`${envStatementKey}${locale.backendKey}`]: 
                    form[`${envStatementKey}${locale.backendKey}`] || firstNotNullableEnvStatement,
                [`${bioKey}${locale.backendKey}`]: 
                    form[`${bioKey}${locale.backendKey}`] || firstNotNullableBio
            }
        }, {})
    }

    submitForm(form){
        const { updateOrg, countries } = this.props;
        const { privacy } = this.state;

        const countryCode = form.country ? (countries.find((country)  => form.country === country.country_name).country_code) : null;

        const processedEnvStatementsAndBios = this.processEnvStatementsAndBios(form)
        this.setState({updatingOrg: true});
        updateOrg({ 
                ...form,
                privacy,
                country: countryCode,
                ...processedEnvStatementsAndBios,
                profile_domain: form.profile_domain ? form.profile_domain : null,
                public_profile_on: Boolean(form.public_profile_on)
            }
        ).then(() => {
                this.setState({updatingOrg: false})
        })

    };

    copyProfileDomainUrlClicked() {
        const {formProfileDomain, addAlert, intl} = this.props;
        navigator.clipboard.writeText(`${window.location.host}/public-profile/${formProfileDomain}`).then(() => {
            addAlert({
                type: 'success',
                message: intl.formatMessage(AlertMessages.corporateProfileUrlCopiedToClipboard),
                dismissAfter: 3000
              })
              addAlert({
                type: 'warning',
                message: intl.formatMessage(AlertMessages.corporateProfileUrlCopiedNotice),
                dismissAfter: 6000
              })
        })
    }


    render() {

        const {
            handleSubmit, 
            countries,
            initialValues,
            dirty,
            invalid,
            loadingInitialValues,
            intl,
            formPublicProfileOn,
            createNewOrgScreen,
            formProfileDomain
        } = this.props;

        const { privacy, privacyOptions, updatingOrg } = this.state;
        return (
                <form  onSubmit={handleSubmit(this.submitForm)} className="personal-information-form-container">         
                    <Row>
                        <Field
                            required
                            name="company_name"
                            component={Input}
                            label={intl.formatMessage(settingsMessages.companyName)}
                            type="text"
                            disabled={loadingInitialValues}
                            validate={withIntl(intl, [required, maxLength(ORG_FIELDS_MAX_LENGTH.companyName)])}
                        />
                        <Field
                            required
                            name="display_name"
                            component={Input}
                            disabled={loadingInitialValues}
                            label={intl.formatMessage(settingsMessages.displayNameLabel)}
                            placeholder={intl.formatMessage(settingsMessages.displayNamePlaceholder)}
                            type="text"
                            validate={withIntl(intl, [required, maxLength(ORG_FIELDS_MAX_LENGTH.displayName)])}
                        />
                    </Row>
                    <Row>
                        <Field
                            name="vat_number"
                            component={Input}
                            label={intl.formatMessage(settingsMessages.vatNumber)}
                            type="text"
                            disabled={loadingInitialValues}
                            validate={withIntl(intl, [maxLength(ORG_FIELDS_MAX_LENGTH.vatNumber)])}
                        />
                        <Field
                            name="address_line"
                            component={Input}
                            label={intl.formatMessage(addressMessages.addressLabel)}
                            placeholder={intl.formatMessage(addressMessages.addressPlaceholder)}
                            type="text"
                            disabled={loadingInitialValues}
                            validate={withIntl(intl, [maxLength(USER_FIELDS_MAX_LENGTH.address_line)])}
                        />
                    </Row>
                    <Row>
                        <Field
                            name="city"
                            component={Input}
                            label={intl.formatMessage(addressMessages.cityLabel)}
                            placeholder={intl.formatMessage(addressMessages.cityPlaceholder)}
                            type="text"
                            disabled={loadingInitialValues}
                            validate={withIntl(intl, [maxLength(USER_FIELDS_MAX_LENGTH.city)])}
                        />
                        <Field
                            name="zip"
                            component={Input}
                            label={intl.formatMessage(addressMessages.zipLabel)}
                            placeholder={intl.formatMessage(addressMessages.zipPlaceholder)}
                            type="text"
                            disabled={loadingInitialValues}
                            validate={withIntl(intl, [maxLength(USER_FIELDS_MAX_LENGTH.zip)])}
                        />
                    </Row>
                    <Row>
                        <Field
                            name="state"
                            component={Input}
                            label={intl.formatMessage(addressMessages.stateLabel)}
                            placeholder={intl.formatMessage(addressMessages.statePlaceholder)}
                            type="text"
                            disabled={loadingInitialValues}
                            validate={withIntl(intl, [maxLength(USER_FIELDS_MAX_LENGTH.state)])}
                        />
                        <Field
                            dirty={false}
                            name="country"
                            component={Typeahead}
                            initialValue={initialValues.country}
                            items={countries}
                            getItemValue={(item) => item.country_name}
                            label={intl.formatMessage(addressMessages.countryLabel)}
                            placeholder={intl.formatMessage(addressMessages.countryPlaceholder)}
                            disabled={loadingInitialValues}
                            validate={withIntl(intl, [validCountry(countries.map(country => country.country_name))])}
                        />
                    </Row>
                    <Row>
                            <CustomSelect
                                label={intl.formatMessage(settingsMessages.privacyLabel)}
                                value={(privacy !== null) ? privacyOptions.find(option => option.value === privacy) : null}
                                options={privacyOptions}
                                onChange={this.handlePrivacySelectChange}
                                isSearchable={false}
                            />
                    </Row>
                    <div className='public-profile-section-container'>
                        <Row>
                            <Field
                                name="public_profile_on"
                                component={Checkbox}
                                label={<FormattedMessage id="Form.publicProfileOn" defaultMessage="Public profile on"/>}
                                type="checkbox"
                                disabled={loadingInitialValues}
                            />
                        </Row>
                        <Row>
                            <Field
                                className='organization-information-from-field-half-width'
                                required={formPublicProfileOn}
                                name="profile_domain"
                                component={Input}
                                label={<FormattedMessage id="Form.profileDomainSuffix" defaultMessage="Profile domain suffix"/>}
                                type="text"
                                disabled={loadingInitialValues || !formPublicProfileOn}
                                validate={
                                    withIntl(
                                        intl,
                                        [profileDomainRequired, validProfileDomain, maxLength(ORG_FIELDS_MAX_LENGTH.profileDomain)],
                                    )
                                }
                            />
                        </Row>
                        {formProfileDomain && formPublicProfileOn && 
                            <Row>
                                <div className='organization-information-from-copy-to-clipboard'>
                                    <p className='organization-information-from-copy-to-clipboard-label'>
                                        <FormattedMessage id="Form.profileDomainUrlLabel" defaultMessage="Profile domain URL"/>
                                    </p>
                                    <div className='organization-information-from-copy-to-clipboard-container'>
                                        <p>{`${window.location.host}/public-profile/${formProfileDomain}`}</p>
                                        <CopyToClipboardIcon className="organization-information-from-copy-to-clipboard-button" onClick={this.copyProfileDomainUrlClicked}/>
                                    </div>
                                </div>
                            </Row>
                        }
                        <Row>
                            <Field
                                name="website_url"
                                component={Input}
                                label={<FormattedMessage id="Form.websiteUrl" defaultMessage="Website URL"/>}
                                type="text"
                                disabled={loadingInitialValues}
                            />
                            <Field
                                name="year_foundation"
                                component={Input}
                                label={<FormattedMessage id="Form.foundationYear" defaultMessage="Foundation Year"/>}
                                type="number"
                                disabled={loadingInitialValues}
                                validate={withIntl(intl,[validFoundationYear],)}
                            />
                        </Row>
                        <p className='public-profile-section-language-tabs-explanation-paragraph'>
                        <FormattedMessage id="Form.languageDependantFieldsDescription" defaultMessage="Fill in the following fields for each language of your Corporate Profile"/>
                        </p>
                        <Tabs>
                            <TabList>
                                {                            
                                    BACKEND_LOCALES.map((locale) => (
                                        <Tab>{intl.formatMessage(Languages[locale.translationKey])}</Tab>
                                     ))
                                }
                            </TabList>
                                {
                                    BACKEND_LOCALES.map((locale) => 
                                        <TabPanel forceRender key={locale.backendKey}>
                                             <Row>
                                                <Field
                                                    name={`bio_${locale.backendKey}`}
                                                    component={Textarea}
                                                    label={`${intl.formatMessage(settingsMessages.companyBioLabel)} ${intl.formatMessage(Languages[locale.translationKey])}`}
                                                    type="textarea"
                                                    disabled={loadingInitialValues}
                                                    validate={withIntl(intl,[maxLength(ORG_FIELDS_MAX_LENGTH.bio)],)}
                                                />
                                            </Row>
                                            <Row>
                                                <Field
                                                    name={`environmental_statement_${locale.backendKey}`}
                                                    component={Textarea}
                                                    label={`${intl.formatMessage(settingsMessages.companyEnvStatementLabel)} in ${intl.formatMessage(Languages[locale.translationKey])}`}
                                                    type="textarea"
                                                    disabled={loadingInitialValues}
                                                    validate={withIntl(intl,[maxLength(ORG_FIELDS_MAX_LENGTH.bio)],)}
                                                />
                                            </Row>
                                        </TabPanel>
                                    )
                                }
                            </Tabs>
                        <Row>
                            <Button
                                disabled={loadingInitialValues || invalid || (!(dirty || this.hasPrivacyChanged()))}
                                type="submit"
                                className='personal-information-form-submit-button'
                                loading={loadingInitialValues || updatingOrg}
                            >
                                {createNewOrgScreen ? 
                                    <FormattedMessage id="Form.createOrgButton" defaultMessage="Create Organization"/> : 
                                    <FormattedMessage id="Form.updateInforamtionButton" defaultMessage="Update Information"/>
                                }
                            </Button>
                        </Row>
                    </div>
                </form>
        )
    }
}

OrganizationInformationForm.propTypes = {
    initialValues: PropTypes.object,
    intl: PropTypes.object,
    countries: PropTypes.array,
    loadingInitialValues: PropTypes.bool,
    updateOrg: PropTypes.func,
    updateErrorMessages: PropTypes.string,
    createNewOrgScreen: PropTypes.bool,
    /* redux form */
    handleSubmit: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
    const organizationFormSelector = formValueSelector('organization-information-form');
    return {
        formPublicProfileOn: organizationFormSelector(state, 'public_profile_on'),
        formProfileDomain: organizationFormSelector(state, 'profile_domain'),
        companyName: organizationFormSelector(state, 'company_name'),
        displayName: organizationFormSelector(state, 'display_name'),
        formMeta: getFormMeta('organization-information-form')(state)
    }
}

export default connect(mapStateToProps, {
})(
    injectIntl(
        reduxForm({
            form: 'organization-information-form',
        })(OrganizationInformationForm))
);
