import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { toggleNav } from '../../constants/customEvents';
import './HeaderLinkWithIcon.css';
import ArrowDown from '../UI/Icons/ArrowDown';

const HeaderLinkWithIcon = ({children, expandable, icon, sectionExpanded, isMobile, onClick, ...props}) => {
  const Wrapper = props.to ? NavLink : 'div';
  return (
    <Wrapper
      {...props}
      className={cx(props.className, 'header-link-with-icon', sectionExpanded && 'header-link-with-icon--section-expanded', isMobile && 'header-link-with-icon--is-mobile')}
      activeClassName="active"
      onClick={() => {
        if((window.innerWidth <= 768 && !expandable) && !props.dontOpenMenu) dispatchEvent(toggleNav);
        if(onClick) onClick();
      }}
    >
      {icon && <span className="header-link-with-icon-icon">{icon}</span>}
      <div className="header-link-with-icon-body">
        {children}
        {expandable && <ArrowDown className="header-link-with-icon-expand-icon"/>}
      </div>
    </Wrapper>
  );
};

HeaderLinkWithIcon.propTypes = {
  className: PropTypes.string,
  expandable: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func
  ])
};

export default HeaderLinkWithIcon;