import actionTypes from '../constants/actionTypes';
import { combineReducers } from 'redux';
import {
  createReducer,
  createBooleanReducer,
  createErrorMessagesReducer
} from '../utils/reducersHelpers';
import get from 'lodash/get';

import {SHOULD_SHOW_MRV_SNACK_LOCALSOTORAGE_KEY} from '../constants/localstorageKeys';
import {saveToStorage, loadFromStorage} from '../utils/localStorage';

const forests = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FORESTS_FETCH_COMPLETE:
    case actionTypes.MY_FORESTS_COMPLETE: {
      let returnObject = { ...state };
      action.payload.result.map(forest => {
        return returnObject[forest] = {
          ...state[forest],
          ...action.payload.entities['forests'][forest],

        };
      });
      return returnObject;
    }
    case actionTypes.FORESTS_DETAILS_COMPLETE: {
      return {
        ...state,
        [action.payload.result]: {
          ...state[action.payload.result],
          ...action.payload.entities['forests'][action.payload.result]
        }
      };
    }
    case actionTypes.FORESTS_TREES_COMPLETE:
      return {
        [action.id]: {
          ...state[action.id],
          trees: action.response.result
        }
      };
    default:
      return state;
  }
};

// add prop myForestsSummary as a valid array (@SEE the use of Object.keys().map) inside the state object
// issue solved: avoid state.forests pollution made by merging different api call to the same state.forests prop
// for supporting the legacy behaviour of the app (state.forests), the prop state.forestsSummary was created to contain only the summary information (or nothing)
const myForestsSummary = (state = {}, action) => {
  // @NOTE: optionalChaining not supported with the current js version
  const forestsEntities = action.payload && action.payload.entities && action.payload.entities.forests ? action.payload.entities['forests'] : {}
  switch(action.type){
    case actionTypes.FORESTS_SUMMARY_COMPLETE: {
      return {
        ...state,
        forestsSummary: Object.keys(forestsEntities).map(id => forestsEntities[id])
      }
    }
    default:
      return state;
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FORESTS_FETCH_START:
      return true;
    case actionTypes.FORESTS_FETCH_COMPLETE:
    case actionTypes.FORESTS_FETCH_ERROR:
    case actionTypes.MY_FORESTS_COMPLETE:
    case actionTypes.MY_FORESTS_ERROR:
    case actionTypes.FORESTS_PRICE_COMPLETE:
      return false;
    default:
      return state;
  }
};

const isFetchingForestSummary = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FORESTS_SUMMARY_START:
      return true;
    case actionTypes.FORESTS_SUMMARY_COMPLETE:
    case actionTypes.FORESTS_SUMMARY_ERROR: 
      return false;
    default:
      return state;
  }

};

const isFetchingMyForests = (state = false, action) => {
  switch (action.type) {
    case actionTypes.MY_FORESTS_START:
      return true;
    case actionTypes.MY_FORESTS_COMPLETE:
    case actionTypes.MY_FORESTS_ERROR:
      return false;
    default:
      return state;
  }
};

const isFetchingDetails = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FORESTS_DETAILS_START:
      return true;
    case actionTypes.FORESTS_DETAILS_COMPLETE:
    case actionTypes.FORESTS_DETAILS_ERROR:
      return false;
    default:
      return state;
  }
};

const errorMessages = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FORESTS_FETCH_START:
    case actionTypes.MY_FORESTS_START:
    case actionTypes.FORESTS_FETCH_COMPLETE:
    case actionTypes.MY_FORESTS_COMPLETE:
    case actionTypes.FORESTS_PRICE_COMPLETE:
      return null;
    case actionTypes.FORESTS_FETCH_ERROR:
    case actionTypes.MY_FORESTS_ERROR:
      return action.payload.error ? action.payload.error.message : `Error ${action.payload.code}`;
    default:
      return state;
  }
};

const mrvProjectInitialState = {
  isImpactTabOpened: false,
  showOnlyGraphControlledLayers: false,
  graphControlledRasterSources: null,
};
const mrvProject = (state = mrvProjectInitialState, action) => {
  switch (action.type) {
    case actionTypes.FOREST_IMPACT_TAB_OPENED:
      return { ...state, isImpactTabOpened: action.payload };
    case actionTypes.FOREST_UPDATE_SHOW_ONLY_GRAPH_CONTROLLED_LAYERS:
      return {...state, showOnlyGraphControlledLayers: action.payload}
    case actionTypes.FOREST_UPDATE_MRV_RASTER_SOURCES:
       return { ...state,  graphControlledRasterSources: action.payload};
       case actionTypes.FOREST_RESET_MRV:
        return { ...state,  graphControlledRasterSources: null, showOnlyGraphControlledLayers: false};
    default:
      return state;
  }
}

const shouldShowMrvSnackLocalStorage = loadFromStorage(SHOULD_SHOW_MRV_SNACK_LOCALSOTORAGE_KEY);
const shouldShowMrvSnackInitialState = shouldShowMrvSnackLocalStorage !== undefined ? shouldShowMrvSnackLocalStorage : true;
;
const shouldShowMrvSnack = (state = shouldShowMrvSnackInitialState, action) => {
  switch(action.type) {
    case actionTypes.FORESTS_HIDE_MRV_SNACK:
      saveToStorage(SHOULD_SHOW_MRV_SNACK_LOCALSOTORAGE_KEY,false);
      return false;
    default:
      return state;
  }
}

export default combineReducers({
  isFetching,
  isFetchingForestSummary,
  isFetchingMyForests,
  isFetchingDetails,
  isFetchingTrees: createBooleanReducer([
    actionTypes.FORESTS_TREES_START,
    actionTypes.FORESTS_TREES_COMPLETE,
    actionTypes.FORESTS_TREES_ERROR,
  ]),
  errorMessages,
  detailsErrorMessages: createErrorMessagesReducer([
    actionTypes.FORESTS_DETAILS_START,
    actionTypes.FORESTS_DETAILS_COMPLETE,
    actionTypes.FORESTS_DETAILS_ERROR,
  ]),
  forestsList: createReducer([], {
    [actionTypes.FORESTS_FETCH_COMPLETE]: (state, action) => {
      return [ ...action.payload.result ];
    }
  }),
  myForestsList: createReducer([], {
    [actionTypes.MY_FORESTS_COMPLETE]: (state, action) => {
      return [ ...action.payload.result ];
    },
  }),
  team: createReducer({}, {
    [actionTypes.FORESTS_DETAILS_COMPLETE]: (state, action) => {
      return { ...state, ...action.payload.entities['team'] };
    }
  }),
  species: createReducer({}, {
    [actionTypes.FORESTS_DETAILS_COMPLETE]: (state, action) => {
      return { ...state, ...action.payload.entities['species'] };
    }
  }),
  forests,
  myForestsSummary,
  trees: createReducer({}, {
    [actionTypes.FORESTS_TREES_COMPLETE]: (state, action) => {
      return { ...state, ...action.response.entities['trees'] };
    }
  }),
  patrons: createReducer({
    isFetching: false,
    errorMessages: null,
    currentPage: 1,
    forestId: null,
    sectorId: null,
    data: {},
    patrons: {},
  }, {
    [actionTypes.FOREST_PATRONS_RESET]: () => {
      return {
        isFetching: false,
        errorMessages: null,
        currentPage: 1,
        forestId: null,
        sectorId: null,
        data: {},
        patrons: {},
      };
    },
    [actionTypes.FOREST_PATRONS_START]: (state, action) => {
      return {
        ...state,
        isFetching: true,
        currentPage: action.page,
      };
    },
    [actionTypes.FOREST_PATRONS_COMPLETE]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        forestId: action.forestId,
        sectorId: action.sectorId,
        patrons: {
          ...state.patrons,
          ...action.payload.entities['patrons']
        },
        data: {
          ...action.payload.entities['patronsResponse'][action.payload.result]
        }
      };
    },
    [actionTypes.FOREST_PATRONS_ERROR]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        errorMessages: action.error.message,
      };
    },
  }),
  contributors: createReducer({
    isFetching: false,
    errorMessages: null,
    currentPage: 1,
    forestId: null,
    sectorId: null,
    pagination: {
      currentPage: 1,
    },
    contributors: [],
    topContributors: [],
  }, {
    [actionTypes.FOREST_CONTRIBUTORS_RESET]: () => {
      return {
        isFetching: false,
        errorMessages: null,
        forestId: null,
        pagination: {
          currentPage: 1,
        },
        contributors: [],
        topContributors: [],
      };
    },
    [actionTypes.FOREST_CONTRIBUTORS_START]: (state, action) => {
      return {
        ...state,
        isFetching: true,
        pagination: {
          ...state.pagination,
          currentPage: action.page,
        }
      };
    },
    [actionTypes.FOREST_CONTRIBUTORS_COMPLETE]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        forestId: action.payload.data.forest,
        total: action.payload.pagination.total || state.total,
        topContributors: (action.payload.pagination.currentPage === 1) ? action.payload.data.contributors :  state.topContributors,
        contributors: [
          ...action.payload.data.contributors
        ],
        pagination: {
          ...action.payload.pagination
        }
      };
    },
    [actionTypes.FOREST_CONTRIBUTORS_ERROR]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        errorMessages: action.error.message,
      };
    },
  }),
  vintages: createReducer({
    isFetching: false,
    errorMessages: null,
    projectId: null,
    vintages: [],

  }, {
    [actionTypes.PROJECT_VINTAGES_RESET]: () => {
      return {
        isFetching: false,
        errorMessages: null,
        projectId: null,
        vintages: [],
      };
    },
    [actionTypes.PROJECT_VINTAGES_START]: (state, action) => {
      return {
        ...state,
        isFetching: true,
        vintages: [],
        projectId: action.projectId
      };
    },
    [actionTypes.PROJECT_VINTAGES_COMPLETE]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        vintages: [
          ...action.payload.data
        ],
      };
    },
    [actionTypes.PROJECT_VINTAGES_ERROR]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        errorMessages: action.error.message,
      };
    },
  }),
  transactions: createReducer({
    isFetching: false,
    errorMessages: null,
    currentPage: 1,
    forestId: null,
    sectorId: null,
    pagination: {
      currentPage: 1,
    },
    transactions: {},
  }, {
    [actionTypes.FOREST_TRANSACTIONS_RESET]: () => {
      return {
        isFetching: false,
        errorMessages: null,
        forestId: null,
        pagination: {
          currentPage: 1,
        },
        transactions: [],
      };
    },
    [actionTypes.FOREST_TRANSACTIONS_START]: (state, action) => {
      return {
        ...state,
        isFetching: true,
        pagination: {
          ...state.pagination,
          currentPage: action.page,
        }
      };
    },
    [actionTypes.FOREST_TRANSACTIONS_COMPLETE]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        forestId: action.payload.forestId,
        total: action.payload.pagination.total || state.total,
        transactions: [
          ...action.payload.data.transactions
        ],
        pagination: {
          ...action.payload.pagination
        }
      };
    },
    [actionTypes.FOREST_TRANSACTIONS_ERROR]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        errorMessages: action.error.message,
      };
    },
  }),
  gallery: createReducer({ isFetching: null }, {
    [actionTypes.FOREST_GALLERY_START]: (_, action) => {
      // We need to use the forest id instead of a boolean to show
      // the loading state only on the respective image.
      return { isFetching: action.id };
    },
    // We don't need to do anything with the data because
    // the action will also pass it to the "gallery" UI reducer.
    [actionTypes.FOREST_GALLERY_COMPLETE]: () => {
      return { isFetching: null };
    },
    [actionTypes.FOREST_GALLERY_ERROR]: () => {
      return { isFetching: null };
    },
  }),
  galleries: createReducer({}, {
    [actionTypes.FOREST_GALLERIES_START]: (state, action) => ({
      ...state,
      [action.id]: {
        isFetching: true,
      },
    }),
    [actionTypes.FOREST_GALLERIES_COMPLETE]: (state, action) => ({
      ...state,
      [action.payload.id]: {
        isFetching: false,
        images: action.payload.images,
      }
    }),
    [actionTypes.FOREST_GALLERIES_ERROR]: (state, action) => ({
      ...state,
      [action.id]: {
        isFetching: false,
      }
    }),
  }),
  latestActivity: createReducer({isFetching: false, latestActivity: []}, {
    [actionTypes.FOREST_LATEST_ACTIVITY_START]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [actionTypes.FOREST_LATEST_ACTIVITY_COMPLETE]: (state, action) => ({
      ...state,
      isFetching: false,
      latestActivity: action.payload.latestActivity,
    }),
    [actionTypes.FOREST_GALLERIES_ERROR]: (state) => ({
      ...state,
      isFetching: false,
    }),
  }),
  mrvGraph: createReducer({isFetching: false, mrvGraph: {}}, {
    [actionTypes.FOREST_MRV_GRAPH_START]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [actionTypes.FOREST_MRV_GRAPH_COMPLETE]: (state, action) => ({
      ...state,
      isFetching: false,
      mrvGraph: action.payload.mrvGraph,
    }),
    [actionTypes.FOREST_MRV_GRAPH_ERROR]: (state) => ({
      ...state,
      isFetching: false,
    }),
  }),
  reforesterShares: createReducer({isFetchingReforesterShares: false, reforesterShares: {}}, {
    [actionTypes.REFORESTER_SHARES_START]: (state) => ({
      ...state,
      isFetchingReforesterShares: true,
      reforesterShares: undefined
    }),
    [actionTypes.REFORESTER_SHARES_COMPLETE]: (state, action) => ({
      ...state,
      isFetchingReforesterShares: false,
      reforesterShares: action.payload.reforesterShares,
    }),
    [actionTypes.REFORESTER_SHARES_ERROR]: (state) => ({
      ...state,
      isFetchingReforesterShares: false,
      reforesterShares: undefined,
    }),
  }),
  mrvProject,
  shouldShowMrvSnack,
});

// UI
export const getIsFetching = state => state.isFetching;
export const getIsFetchingForestSummary = state => state.isFetchingForestSummary;
export const getIsFetchingMyForests = state => state.isFetchingMyForests;
export const getIsFetchingDetails = state => state.isFetchingDetails;
export const getIsFetchingReforesterShares = state => state.isFetchingReforesterShares;
export const getIsFetchingTrees = state => state.isFetchingTrees;
export const getErrorMessages = state => state.errorMessages;
export const getDetailsErrorMessages = state => state.detailsErrorMessages;

// Get my owned forests
export const getMyOwnedForests = state => {
  return state.myForestsSummary.forestsSummary
}

// Get all forests
export const getForests = state => {
  return state.forestsList.map( forestId => state.forests[forestId] );
};
export const getMyForests = state => {
  return state.myForestsList.map( forestId => state.forests[forestId] );
};
// Forest by ID
export const getForestById = (state, id) => state.forests[id];

// Get forest specific info:
// Species
export const getForestSpecies = (state, id) => {
  if (!id || !state.forests[id] || !state.forests[id].species) return [];

  return state.forests[id].species.map( specie => state.species[specie] );
};
// Team
export const getForestTeam = (state, id) => {
  if (!id || !state.forests[id] || !state.forests[id].team) return [];

  return state.forests[id].team.map( member => {
    return state.team[member];
  });
};
// Trees
export const getForestTrees = (state, id) => {
  if (!id || !state.forests[id] || !state.forests[id].trees) return [];

  return state.forests[id].trees.map( tree => state.trees[tree] );
};
// Reforester Shares
export const getReforesterShares = state => get(state, 'reforesterShares.reforesterShares', []);
// Patrons
export const getIsFetchingPatrons = state => state.patrons.isFetching;
export const getPatronsErrorMessages = state => state.patrons.errorMessages;
export const getPatronsForestId = state => state.patrons.forestId;
export const getPatronsSectorId = state => state.patrons.sectorId;
export const getPatronsPage = state => state.patrons.currentPage;
export const getPatronsData = state => state.patrons.data;
export const getPatronsPodium = state => {
  if (!state.patrons.data.patrons) return [];

  return Object.keys(state.patrons.patrons).filter(patronId => {
    return state.patrons.patrons[patronId].rank <= 3;
  }).map(patron => state.patrons.patrons[patron]);
};
export const getPatrons = state => {
  if (!state.patrons.data.patrons) return [];

  return state.patrons.data.patrons.map(patronId => {
    return state.patrons.patrons[patronId];
  });
};

// Contributors
export const getIsFetchingContributors = state => state.contributors.isFetching;
export const getContributorsErrorMessages = state => state.contributors.errorMessages;
export const getContributorsForestId = state => state.contributors.forestId;
export const getContributorsPage = state => state.contributors.pagination.currentPage;
export const getContributorsTotal = state => {
  return state.contributors.total
};
export const getContributors = state => state.contributors.contributors;
export const getTopContributors = state => state.contributors.topContributors;

// Vintages
export const getIsFetchingVintages = state => state.vintages.isFetching;
export const getVintagesErrorMessages = state => state.vintages.errorMessages;
export const getVintagesProjectId = state => state.vintages.projectId;
export const getVintages = state => state.vintages.vintages;

// transactions
export const getIsFetchingTransactions = state => state.transactions.isFetching;
export const getTransactionsErrorMessages = state => state.transactions.errorMessages;
export const getTransactionsForestId = state => state.transactions.forestId;
export const getTransactionsPage = state => state.transactions.pagination.currentPage;
export const getTransactionsTotal = state => state.transactions.total;
export const getTransactions = state => state.transactions.transactions;

// Gallery
export const getIsFetchingGallery = state => state.gallery.isFetching;

// Numbers
const getMyTotals = (state, key) => {
  const totalVales = state.myForestsList.map(forest => {
    return state.forests[forest][key];
  });

  return totalVales.reduce((a,b) => {
    return a + b;
  }, 0);
};
export const getTotalCapturedCarbon = state => {
  return getMyTotals(state, 'captured_co2');
};
export const getTotalOxygen = state => {
  return getMyTotals(state, 'generated_o2');
};

export const getForestGallery = (state, forestId) => state.galleries[forestId];

export const getLatestActivity = state => state.latestActivity;

// MRV project

export const getMrvGraph = state => state.mrvGraph;

export const isImpactTabOpened = state => state.mrvProject.isImpactTabOpened;

export const showOnlyGraphControlledLayers = state => state.mrvProject.showOnlyGraphControlledLayers;

export const getShouldShowMrvSnack = state => state.shouldShowMrvSnack;

export const getGraphControlledRasterSources = state => state.mrvProject.graphControlledRasterSources;
