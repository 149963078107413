import mixpanel from 'mixpanel-browser';

import { axiosV1Instance, axiosV2Instance } from './';
import api from '../constants/api';
import actionTypes from '../constants/actionTypes';
import { intercomAppId } from '../constants/config';

import { getIsLoading } from '../selectors/userActions';
import { getToken, getAuthMethod } from '../selectors/userSession';
import { addAlert } from '../actions/alerts';
import store from '../setup/store';
import history from '../setup/history';
import socialAuthProvider from '../utils/social-auth/socialAuthProvider';
import { AUTH_METHOD } from '../constants/authMethods'
import getIsAutheticatedFromStore from '../utils/getIsAutheticatedFromStore';
import { links } from '@reforestum/shared-utils'
import { actAsOrganization, stopActingAsOrganization } from '../utils/impersonationHelpers';
import { posthogIdentify, posthogReset } from '../utils/posthog';

export const authenticate = (data = {}, authMethod, redirect, callback) => ({
  shouldCallAPI: state => !getIsLoading(state),
  callAPI: (state) => {
    const guestToken = getToken(state);
    const { email, referralOrgId, ...credentials } = data;

    return axiosV1Instance.put(
      api.LOGIN,
      {
        ...credentials,
        "referralOrgId": referralOrgId ? referralOrgId : undefined,
        "auth_method": authMethod === AUTH_METHOD.FORM ? null : authMethod,
        "guest_token": guestToken
      },
    );
  },
  actions: [
    actionTypes.AUTH_START,
    {
      type: actionTypes.AUTH_COMPLETE,
      payload: (response, dispatch) => {
        startRefreshInterval();
        //Stop acting as an organization in case there was an organization stored before the login 
        stopActingAsOrganization({actionAfterSwitch: () => {}})
        //If there is only one organization, switch to it
        const userOrgs = response.data.data.user.organizations || []
        if(userOrgs.length === 1){
          actAsOrganization({organizationId: userOrgs[0].organization_id})
        }


        dispatch(addAlert({
          type: 'info',
          message: response.data.data.message,
          dismissAfter: 3000
        }));

        // identify user at login
        posthogIdentify(response.data.data.user, authMethod)

        mixpanel.identify(response.data.data.user.id);

        mixpanel.people.set({
          "$email": response.data.data.user.email,
          "Auth Method": authMethod
        });

        const signup = response.data.data.signup

        if (signup != null && signup !== false) {
          mixpanel.track("Session", {
            "Authenticated": getIsAutheticatedFromStore(),
            "Action": "User signup",
            "Domain": "App",
            "Auth Method": signup
          })
        }

        mixpanel.track("Session", {
          "Authenticated": getIsAutheticatedFromStore(),
          "Action": "Login registered",
          "Domain": "App"
        });

        // INTERCOM: Update session with authenticated user data =========
        window.Intercom("update", {
          email: response.data.data.user.email,
          user_id: response.data.data.user.id,
          name: `${response.data.data.user.name} ${response.data.data.user.surname}`,
        });
        // =========

        if(callback){
          callback({user: response.data.data.user})
        }

        if (redirect != null)
          history.push(redirect)

        return {
          ...response.data.data,
          authMethod
        };
      }
    },
    {
      type: actionTypes.AUTH_ERROR,
      payload: (response, dispatch) => {
        const { username, email } = response.data;

        mixpanel.track("Session", {
          "Authenticated": getIsAutheticatedFromStore(),
          "Action": "Login FAILED",
          "email": username || email,
          "Domain": "App"
        });

        dispatch(addAlert({
          type: 'error',
          message: response.data.error.message,
          dismissAfter: 3000
        }));

        return {username: data.username, message: response.data.error.message};
      }
    }
  ]
});

export const guestAuthenticate = () => ({
  shouldCallAPI: state => !getIsLoading(state),
  callAPI: () => axiosV1Instance.put(api.LOGIN, { "guest": true }),
  actions: [
    actionTypes.GUEST_START,
    {
      type: actionTypes.GUEST_COMPLETE,
      payload: response => {
        //Stop acting as an organization in case there was an organization stored before the login 
        stopActingAsOrganization({actionAfterSwitch: () => {}})
        startRefreshInterval();

        mixpanel.track("Session", {
          "Authenticated": getIsAutheticatedFromStore(),
          "Action": "Create guest session",
          "Domain": "App"
        });

        return response.data.data;
      }
    },
    {
      type: actionTypes.GUEST_ERROR,
      payload: response => response.data
    }
  ]
});

export const refreshProfile = () => ({
  shouldCallAPI: state => !getIsLoading(state),
  callAPI: () => axiosV2Instance.get(api.GET_PROFILE),
  actions: [
    actionTypes.GET_PROFILE_START,
    {
      type: actionTypes.GET_PROFILE_COMPLETE,
      payload: response => response.data
    },
    {
      type: actionTypes.GET_PROFILE_ERROR,
      payload: response => response.data.error
    }
  ]
});

export const logout = () => (dispatch, getState) => {
  // INTERCOM: Shutdown session =========
  window.Intercom('shutdown');
  // =========

  // INTERCOM: Boot guest session  =========
  window.Intercom('boot', { app_id: intercomAppId });
  // ==========

  const authMethod = getAuthMethod(getState());
  socialAuthProvider(authMethod).then(auth => {
    auth.logout(() => {
      dispatch({ type: actionTypes.AUTH_LOGOUT });
      // stop acting as an organization in case there was an organization stored before the logout
      stopActingAsOrganization({ actionAfterSwitch: () => { } })
      posthogReset()
      dispatch(addAlert({
        type: 'info',
        message: 'Successfully logged out',
        dismissAfter: 3000
      }));
      history.push(links.getLoginUrl());
    });
  })
};

export const toggleMetricUnits = () => dispatch =>
  dispatch({ type: actionTypes.TOGGLE_METRIC_UNITS });

// Token refresh code.
let refreshInterval;
let interval = 1000 * 60 * 30; // 30 min

export const startRefreshInterval = () => dispatch => {
  clearInterval(refreshInterval);
  refreshInterval = setInterval(() => dispatch(refreshToken()), interval);
};

export const clearRefreshInterval = () => {
  clearInterval(refreshInterval);
};

export const refreshToken = () => ({
  callAPI: () => axiosV1Instance.get(api.REFRESH_TOKEN),
  actions: [
    actionTypes.REFRESH_START,
    {
      type: actionTypes.REFRESH_COMPLETE,
      payload: response => response,
    },
    {
      type: actionTypes.REFRESH_ERROR,
      payload: response => {
        logout();
        startRefreshInterval();
        return response.error;
      }
    }
  ]
});

export const setMixpanelId = (mixpanelId) => ({
  type: actionTypes.SET_MIXPANEL_ID,
  payload: mixpanelId,
});

export const changeLanguage = (lang) => ({
  type: actionTypes.CHANGE_LANGUAGE,
  payload: lang,
});

socialAuthProvider(getAuthMethod(store.getState())).then(auth => {
  auth.setOnExternalLogout(() => store.dispatch(logout()))
  auth.handleStatusChange()
})
