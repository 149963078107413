import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Overlay.css';

const Overlay = ({ wrapperClassName, className, onClick, children }) => (
  <div className={wrapperClassName}>
    <div className={cx(className, "overlay")} onClick={onClick}></div>
    {children}
  </div>
);

Overlay.propTypes = {
  onClick: PropTypes.func,
};

export default Overlay;
