import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import {
  MODAL_DEFAULT,
  MODAL_FOREST_NOTIFY,
  MODAL_FIRST_SOURCE
} from '../../../constants/modalTypes';

import ModalDefault from '../Modal/ModalDefault';
import ModalForestNotify from '../Modal/ModalForestNotify';
import ModalFirstSource from '../Modal/ModalFirstSource';

import './ModalRoot.css';

export const ModalRoot = ({ isOpen, showModal, hideModal, type, meta }) => {
  const mappedModals = {
    [MODAL_DEFAULT]: ModalDefault,
    [MODAL_FOREST_NOTIFY]: ModalForestNotify,
    [MODAL_FIRST_SOURCE]: ModalFirstSource,
  };
  const Modal = mappedModals[type];

  return (
    <TransitionGroup>
      {Modal && (
        <CSSTransition
          timeout={200}
          in={isOpen}
          unmountOnExit
          classNames="modal"
        >
          <Modal showModal={showModal} hideModal={hideModal} {...meta} />
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

ModalRoot.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  type: PropTypes.string,
  meta: PropTypes.object,
};

export default ModalRoot;
