import React from 'react';

const Information = (props) => (
    <svg {...props} width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 12.25C9.8995 12.25 12.25 9.8995 12.25 7C12.25 4.10051 9.8995 1.75 7 1.75C4.10051 1.75 1.75 4.10051 1.75 7C1.75 9.8995 4.10051 12.25 7 12.25Z" stroke="#79897A" strokeWidth="1.2" strokeLinecap="square"/>
        <path d="M6.5625 6.5625H7V9.625H7.4375" stroke="#79897A" strokeWidth="1.2" strokeLinecap="square"/>
        <path d="M7 5.25C7.36244 5.25 7.65625 4.95619 7.65625 4.59375C7.65625 4.23131 7.36244 3.9375 7 3.9375C6.63756 3.9375 6.34375 4.23131 6.34375 4.59375C6.34375 4.95619 6.63756 5.25 7 5.25Z" fill="#79897A"/>
    </svg>
)

export default Information;


    