import React from 'react'

import FacebookAuth from '../../utils/social-auth/FacebookAuth'
import GoogleAuth from '../../utils/social-auth/GoogleAuth'

import './SocialButton.css'

export default (SocialButton, provider) => {
  return class extends React.PureComponent {
    state = {
      isDisabled: true,
    }

    componentDidMount() {
      const AuthProvider = provider === 'google' ? GoogleAuth : FacebookAuth

      this.auth = new AuthProvider(() => {
        this.setState({ isDisabled: false })
      })
    }

    handleLogin = () => {
      const { onSuccess, onExternalLogout } = this.props

      this.auth.login(onSuccess)
      this.auth.setOnExternalLogout(onExternalLogout)
    }

    render() {
      return (
        <SocialButton
          handleLogin={this.handleLogin}
          isDisabled={this.state.isDisabled}
        />
      )
    }
  }
}
