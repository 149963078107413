import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  forestSummary,
} from '../actions/forests';
import {
  getErrorMessages,
  getFilter,
  getFilteredSources,
  getIsAdding,
  getIsFetching as getIsFetchingCarbon,
  getIsRemoving,
  getIsEditing,
  getIsUpdating,
  getTotalCarbon,
} from '../selectors/carbon';
import {
  getMyOwnedForests,
  getIsFetchingForestSummary
} from '../selectors/forests';

import { getOffsetClaimDetails, getIsFetchingOffsetClaimDetails } from '../selectors/offsetClaim';

import { getIsUserAuthenticated } from '../selectors/userSession';

import {
  addSource,
  getSources,
  removeSource,
  prepareItemForEditing,
  setCarbonFilter,
  updateSourceFrequency,
} from '../actions/carbon';

import CarbonSidebar from '../components/Carbon/CarbonSidebar';
import {closeSidebar} from "../constants/customEvents";

class CarbonSidebarContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentlySelected: null,
      isSearchOpen: false,
      myOwnedForests: undefined,
    };
  }

  componentWillMount() {
    this.fetchSources();
    if (this.props.dashboard) {
      this.fetchForests();
    }
  }

  componentWillReceiveProps(newProps){
    if(newProps.forests){
      this.setState({ myOwnedForests: newProps.forests });
    }

    if(this.props.isFetchingOffsetClaimDetails
      && !newProps.isFetchingOffsetClaimDetails
      && newProps.offsetClaim){
      this.fetchForests();
    }
  }

  fetchSources() {
    this.props.getSources();
  }

  fetchForests() {
    this.props.forestSummary();
  }

  setFilter(event) {
    this.props.setCarbonFilter(event.target.value);
  }

  selectItem(id = null) {
    this.setState({ currentlySelected: id });
  }

  toggleSearch() {
    this.setState({ isSearchOpen: !this.state.isSearchOpen })
  }

  deleteItem(id) {
    this.props.removeSource(id);
  }

  prepareItemForEditing(id, itemName) {
    dispatchEvent(closeSidebar)
    this.props.prepareItemForEditing(id, itemName);
  }

  updateFrequency(frequency = '0') {
    this.props.updateSourceFrequency(frequency);
  }

  render() {
    const {
      errorMessages,
      filter,
      isAdding,
      isFetching,
      isRemoving,
      isEditing,
      isUpdating,
      sources,
      totalCarbon,
      dashboard,
      isFetchingForests,
      forests,
      isSmallWidth,
      isSmallHeight,
      resetForm,
      isAuthed,
    } = this.props;

    if(!isAuthed && (!sources || sources.length === 0)) return null

    return (
      <CarbonSidebar
        currentlySelected={this.state.currentlySelected}
        deleteItem={this.deleteItem.bind(this)}
        editItem={this.prepareItemForEditing.bind(this)}
        errorMessages={errorMessages}
        fetchSources={this.fetchSources.bind(this)}
        filter={filter}
        isAdding={isAdding}
        isFetching={isFetching}
        isRemoving={isRemoving}
        isEditing={isEditing}
        isUpdating={isUpdating}
        selectItem={this.selectItem.bind(this)}
        setFilter={this.setFilter.bind(this)}
        sources={sources}
        totalCarbon={totalCarbon}
        updateFrequency={this.updateFrequency.bind(this)}
        dashboard={dashboard}
        isFetchingForests={isFetchingForests}
        forests={forests}
        isSmallWidth={isSmallWidth}
        isSmallHeight={isSmallHeight}
        isSearchOpen={this.state.isSearchOpen}
        toggleSearch={this.toggleSearch.bind(this)}
        resetForm={resetForm}
      />
    );
  }
}

CarbonSidebarContainer.propTypes = {
  errorMessages: PropTypes.string,
  filter: PropTypes.string,
  getSources: PropTypes.func.isRequired,
  isAdding: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isRemoving: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  removeSource: PropTypes.func.isRequired,
  prepareItemForEditing: PropTypes.func.isRequired,
  setCarbonFilter: PropTypes.func.isRequired,
  sources: PropTypes.array,
  totalCarbon: PropTypes.number.isRequired,
  updateSourceFrequency: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  dashboard: PropTypes.bool,
  forestSummary: PropTypes.func.isRequired,
  isFetchingForests: PropTypes.bool.isRequired,
  forests: PropTypes.array,
  resetForm: PropTypes.func,
  isAuthed: PropTypes.bool,
};

const mapStateToProps = state => ({
  errorMessages: getErrorMessages(state),
  filter: getFilter(state),
  isAdding: getIsAdding(state),
  isFetching: getIsFetchingCarbon(state),
  isRemoving: getIsRemoving(state),
  isEditing: getIsEditing(state),
  isUpdating: getIsUpdating(state),
  sources: getFilteredSources(state, getFilter(state)),
  totalCarbon: getTotalCarbon(state),
  isAuthenticated: getIsUserAuthenticated(state),
  isFetchingForests: getIsFetchingForestSummary(state),
  forests: getMyOwnedForests(state),
  offsetClaim: getOffsetClaimDetails(state),
  isFetchingOffsetClaimDetails: getIsFetchingOffsetClaimDetails(state)
});

export default connect(mapStateToProps, {
  addSource,
  getFilteredSources,
  getSources,
  removeSource,
  prepareItemForEditing,
  setCarbonFilter,
  updateSourceFrequency,
  forestSummary,
})(CarbonSidebarContainer);
