import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {FormattedMessage, FormattedNumber, injectIntl, intlShape } from 'react-intl'
import { forestDetailsMessages } from '../../../constants/messages'
import FormattedUnit from '../../Utils/FormattedUnit';

import TinyInfoBlock from '../../UI/TinyInfoBlock/TinyInfoBlock';
import Tags from '../../UI/Tags/Tags';

import { getFormattedCredits, getFormattedArea, getFormattedTemperature } from '../../../utils/units';
import './ForestHeaderDetails.css';
import CarbonCloud from '../../UI/Icons/CarbonCloud';

const SharesForestHeaderDetails = ({ forest, intl, virtualSector }) => {
  const {
    weather,
    total_surface,
    certifications,
    reforesters_amount,
    planting_date,
    project_type,
    total_vcus,
    offset_type,
    registry
  } = forest;

  //const reforestedProgress = forest.reforested_surface > 0 ? forest.total_surface / forest.reforested_surface * 100 : 0;
  const formattedDegrees = getFormattedTemperature((forest.weather || {}).temperature);
  const myCredits = getFormattedCredits({credits: forest.user_total_vcus});
  const vcusIssued = getFormattedCredits({credits: forest.total_vcus, roundToMillionsIfAppropiate: true, registry: forest.registry})

  const items = [
    {
      title: intl.formatMessage(forestDetailsMessages.weather),
      value: <div>
          <FormattedNumber
              value={formattedDegrees.value}
          /> <FormattedUnit unit={formattedDegrees.unit} />
        </div>,      
      icon: (weather || {}).icon,
      divider: true
    }
  ];
  
  if(total_surface && total_surface > 0) {
    items.push({
      title: intl.formatMessage(forestDetailsMessages.extension),
      value: <div>
            <FormattedNumber
                value={getFormattedArea(total_surface).value}
            /> <FormattedUnit unit={getFormattedArea(total_surface).unit} />
          </div>,
      icon: 'surface',
      divider: true
    })
  }

  if(!virtualSector && total_vcus) {
    items.push({
      title: <FormattedMessage id="ForestDetails.carbonCreditsIssued" defaultMessage="Carbon credits issued"/>,
      value: <div><FormattedNumber value={vcusIssued.value || 0}/> <FormattedUnit unit={vcusIssued.unit} /></div>,
      divider: true,
      iconComponent: <CarbonCloud/>
    })
  }

  if (myCredits.value > 0) {
    items.push({
      title: intl.formatMessage(forestDetailsMessages.myCredits),
      value: <div>
            <FormattedNumber
                value={myCredits.value || 0}
            /> <FormattedUnit unit={myCredits.unit} />
          </div>,      
      icon: 'credits',
      tooltip: intl.formatMessage(forestDetailsMessages.myCreditsTooltip),
      divider: true
    });
  }

  if(!virtualSector && reforesters_amount && reforesters_amount > 0){
    items.splice(1, 0, {
      title: intl.formatMessage(forestDetailsMessages.reforesters),
      value: `${reforesters_amount}`,
      icon: 'users',
      divider: true,
      tooltip: intl.formatMessage(forestDetailsMessages.reforestersTooltip)
    })
  }
  else if(virtualSector && virtualSector.reforesters_amount && virtualSector.reforesters_amount > 0){
    items.splice(1, 0, {
      title: intl.formatMessage(forestDetailsMessages.reforesters),
      value: `${virtualSector.reforesters_amount}`,
      icon: 'users',
      divider: true,
      tooltip: intl.formatMessage(forestDetailsMessages.reforestersTooltip)
    })
  }

  // remove divider from last item in list, this is more robust
  // all elements avoid need divider: true
  if (items.length > 0) {
    items[items.length - 1]["divider"] = false
  }

  return (
    <div className="forest-header-details">
        <div className="forest-header-details__wrapper">
          {items.map((item, index) => (
            <TinyInfoBlock
              tooltip={item.tooltip}
              className={cx('forest-header-details__item', {'forest-header-details__item--with-progress': item.progress})}
              key={index}
              {...item}
            />
          ))}
        </div>
        <Tags
          planting_date={planting_date}
          certifications={certifications}
          projectType={project_type}
          projectTypeTooltipPosition={"bottom-right"}
          intl={intl}
          offsetType={offset_type}
          registry={registry}
        />
    </div>
  );
};

SharesForestHeaderDetails.propTypes = {
  forest: PropTypes.object,
  virtualSector: PropTypes.object,
  intl: intlShape.isRequired,
};

export default injectIntl(SharesForestHeaderDetails);
