import {
  axiosV1Instance,
  axiosV2Instance,
  axiosEnterpriseActivationInstance
} from './';

// constants
import actionTypes from '../constants/actionTypes';
import { ACTIVATION_STATUS } from '../constants/enterpriseLanding';
import api from '../constants/api';

// localstorage
import { saveToStorage, removeFromStorage } from '../utils/localStorage';

// Enterprise activation - offset (activation microservice)
export const fetchEnterpriseOffsetCreated = (id) => ({
    shouldCallAPI: () => true,
    callAPI: () => axiosEnterpriseActivationInstance.get(`${api.ENTERPRISE_ACTIVATION}activation/${id}`),
    actions: [
      actionTypes.ENTERPRISE_ACTIVATION_START,
      {
        type: actionTypes.ENTERPRISE_ACTIVATION_COMPLETE,
        payload: response => {
            if(response){
              saveToStorage(
                "enterpriseOffset",
                {
                  ...response.data.data,
                  activationId: id,
                  showActivationOverlay: response.data.data.status === ACTIVATION_STATUS.PENDING 
                });
            }
            return {
              enterpriseOffset: response.data.data
            }
        },
      },
      {
        type: actionTypes.ENTERPRISE_ACTIVATION_ERROR,
        payload: response => response.errors || response.message
      }
    ]
});

// Enterprise activation - company's details (BEV2)
export const fetchEnterpriseActivationCompanyDetails = (orgId, activationId) => ({
    shouldCallAPI: () => true,
    callAPI: () => axiosV2Instance.get(`${api.GET_COMPANY_DETAILS}/${orgId}/${activationId}`),
    actions: [
      actionTypes.ENTERPRISE_ACTIVATION_COMPANY_DETAILS_START,
      {
        type: actionTypes.ENTERPRISE_ACTIVATION__COMPANY_DETAILS_COMPLETE,
        payload: response => ({
          companyDetails: response.data.data,
        }),
      },
      {
        type: actionTypes.ENTERPRISE_ACTIVATION__COMPANY_DETAILS_ERROR,
        payload: response => response.message
      }
    ]
});

// Enterprise activation - user offset claim (BEV1)
export const offsetClaiming = (activationId, callback) => ({
  shouldCallAPI: () => true,
  callAPI: () => axiosV1Instance.put(
    `${api.GET_COMPANY_DETAILS}${api.OFFSET_CLAIM}`,
    {
      "activationId": activationId ? activationId : undefined
    }),
  actions: [
    actionTypes.OFFSET_CLAIM_START,
    {
      type: actionTypes.OFFSET_CLAIM_COMPLETE,
      payload: (response) => {
        removeFromStorage("enterpriseOffset");

        // error first argument pattern in callback
        if (typeof callback === 'function') callback(null, response.data);

        return response.data
      },
    },
    {
      type: actionTypes.OFFSET_CLAIM_ERROR,
      payload: (response) => {
        if (typeof callback === 'function') callback(response);

        return response
      }
    }
  ]
});

export const resetOffsetClaimErrors = () => ({
  type: actionTypes.OFFSET_CLAIM_ERROR_RESET,
});