import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import {
  FormattedMessage,
  injectIntl,
  intlShape
} from 'react-intl';

import { authMessages, formMessages } from '../../constants/messages';

import { passwordStrength, required } from '../../utils/formHelpers';
import { withIntl } from '../../utils/intl';

import FeedbackMessage from '../UI/Forms/FeedbackMessage';
import Input from '../UI/Forms/Input';
import Row from '../UI/Forms/Row';
import RowAligner from '../UI/Forms/RowAligner';
import { Button } from '@reforestum/shared-components';
import { DisabledButton } from '@reforestum/shared-components';
import {EyeLashes} from '../UI/Icons/EyeLashes';
import Eye from '../UI/Icons/Eye';

import './Login.css';
import AcceptTermsOfServiceCheckbox from './ReusableFormFields/AcceptTermsOfServiceCheckbox';
import getFeatureFlags from '../../constants/featureFlags';

class ResetForm extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      togglePasswordVisibility: false,
      togglePasswordConfirmVisibility: false
    }

    this.handleTogglePasswordVisibility = this.handleTogglePasswordVisibility.bind(this);
    this.handleTogglePasswordConfirmVisibility = this.handleTogglePasswordConfirmVisibility.bind(this);
  }

  handleTogglePasswordVisibility(){
    this.setState({ togglePasswordVisibility: !this.state.togglePasswordVisibility })
  }

  handleTogglePasswordConfirmVisibility(){
    this.setState({ togglePasswordConfirmVisibility: !this.state.togglePasswordConfirmVisibility })
  }

  render(){
    const {
      activationParameters,
      handleSubmit,
      handleReset,
      errorMessages,
      isLoading,
      isPasswordReset,
      invalid,
      intl
    } = this.props;

    const featureFlags = getFeatureFlags();
    const isActivationFlow = activationParameters.type;

    const { togglePasswordVisibility, togglePasswordConfirmVisibility } = this.state;

    const renderFormattedButtonForActivationOrPasswordReset = () => {
      let result
      if(isActivationFlow) {
        result = <FormattedMessage
          id={'Login.activationButton'}
          defaultMessage={'Activate Account'}
        />
      }
      else {
        result = <FormattedMessage
          id={'Login.resetButton'}
          defaultMessage={'Reset password'}
        />
      }
      return result
    }

    const renderSuccessMessageForActivationOrPasswordReset = () => {
      let result
      if(!isActivationFlow) {
        result = <FeedbackMessage type="success">
          {intl.formatMessage(authMessages.resetSuccess)}
        </FeedbackMessage>
      } else {
        result = ''
      }
      return result
    }

    return (
      <form onSubmit={handleSubmit(handleReset)}>
          {
            errorMessages && !isPasswordReset &&
            <FeedbackMessage type="error">{errorMessages}</FeedbackMessage>
          }
          {
            isPasswordReset && renderSuccessMessageForActivationOrPasswordReset()
          }
          <Row className='special-reset-form__wrapper'>
            <div className='special-wrapper-with-icon extra-style'>
              <Field
                className='special-input-with-icon'
                name="password"
                disabled={isLoading}
                component={Input}
                placeholder={intl.formatMessage(authMessages.passwordPlaceholder)}
                label={intl.formatMessage(authMessages.passwordLabel)}
                type={togglePasswordVisibility ? "text" : "password"}
                validate={withIntl(intl, [required, passwordStrength])}
              />
              {!togglePasswordVisibility
                ? <EyeLashes className='special-login__icon extra-style__icon' onClick={this.handleTogglePasswordVisibility}/>
                : <Eye className='special-login__icon extra-style__icon' onClick={this.handleTogglePasswordVisibility}/>
              }
            </div>
          </Row>

          <Row>
            <div className='special-wrapper-with-icon extra-style'>
              <Field
                className='special-input-with-icon extra-style'
                name="passwordConfirm"
                disabled={isLoading}
                component={Input}
                placeholder={intl.formatMessage(authMessages.repeatPasswordPlaceholder)}
                label={intl.formatMessage(authMessages.repeatPasswordLabel)}
                type={togglePasswordConfirmVisibility ? "text" : "password"}
                validate={withIntl(intl, required)}
              />
              {!togglePasswordConfirmVisibility
                ? <EyeLashes className='extra-confirm-style__icon' onClick={this.handleTogglePasswordConfirmVisibility}/>
                : <Eye className='extra-confirm-style__icon' onClick={this.handleTogglePasswordConfirmVisibility}/>
              }
            </div>
          </Row>

          {featureFlags.activateAccountTermsOfService && isActivationFlow && <AcceptTermsOfServiceCheckbox />}

          <Row>
            <RowAligner>
              {
                isPasswordReset ?
                <DisabledButton>
                  {renderFormattedButtonForActivationOrPasswordReset()}
                </DisabledButton> :
                <Button
                  loading={isLoading}
                  type="submit"
                  className='login__button'
                  disabled={invalid}
                >
                  {renderFormattedButtonForActivationOrPasswordReset()}
                </Button>
              }
            </RowAligner>
          </Row>
        </form>
    )
  }
}

const validate = (formProps, props) => {
  const errors = {};
  const { password, passwordConfirm } = formProps;
  const { formatMessage } = props.intl;

  if ( passwordConfirm && (password !== passwordConfirm) ) {
    errors.passwordConfirm = formatMessage(formMessages.passwordNotMatch);
  }

  return errors;
};

ResetForm.propTypes = {
  className: PropTypes.string,
  activationParameters: PropTypes.object,
  handleReset: PropTypes.func.isRequired,
  errorMessages: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isPasswordReset: PropTypes.bool.isRequired,
  /* redux form */
  handleSubmit: PropTypes.func.isRequired,
  /* intl */
  intl: intlShape.isRequired,
};

export default injectIntl(reduxForm({
  form: 'reset',
  validate
})(ResetForm));
