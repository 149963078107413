import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import HorizontalScrollWithArrowsContainer from '../../UI/HorizontalScrollWIthArrowsContainer/HorizontalScrollWIthArrowsContainer';
import { expandableLinks } from '../ExpandableLinks/ExpandableLinks';

import './SecondaryNavigation.css'

class SecondaryNavigation extends Component {
    constructor (props) {
        super(props);
        this.state = {
          secondaryNavigation: this.getSecondaryNavigationGroup(props.location.pathname),
        };
      }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
          this.setState({
            secondaryNavigation: this.getSecondaryNavigationGroup(this.props.location.pathname),
          })
        }
    }

    getSecondaryNavigationGroup(pathname) {
        //iterate all the objects inside object expandable links to return array of objects
        const secondaryNavigationGroups = Object.values(expandableLinks).map(group => group.dropdownContent)
    
      return secondaryNavigationGroups.find(group => group.find(tab => pathname.startsWith(tab.link))) 
    }

    render() {

        const {secondaryNavigation} = this.state;
        const ativeIndex = secondaryNavigation && secondaryNavigation.findIndex(tab => this.props.location.pathname.startsWith(tab.link))

        if(!secondaryNavigation) return null
        return (
            <HorizontalScrollWithArrowsContainer centered hideScrollbar>
                <ul className="secondary-navigation__list">
                    {secondaryNavigation.map(({title, link}, index) => (
                        <li className="secondary-navigation__list-item" key={link}>
                            <Link className={`secondary-navigation__list-item-link ${ativeIndex === index && 'secondary-navigation__list-item-link--active'}`} to={link} tabIndex="0">
                                {title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </HorizontalScrollWithArrowsContainer>
        )
    }
}

export default withRouter(SecondaryNavigation)