import actionTypes from '../constants/actionTypes'
import {axiosV2Instance} from './'
import api from '../constants/api'
import {getIsFetching} from '../selectors/publicProfile'



export const fetchOrganizationDashboard = () => ({
	shouldCallAPI: state => !getIsFetching(state),
	callAPI: () => axiosV2Instance.get(api.GET_ORGANIZATION_DASHBOARD),
	actions: [
		actionTypes.PUBLIC_PROFILE_START,
		{
			type: actionTypes.PUBLIC_PROFILE_COMPLETE,
			payload: response => response.data.data
		},
		{
			type: actionTypes.PUBLIC_PROFILE_ERROR,
			payload: response => response
		}
	],
})

