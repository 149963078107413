import React from 'react';

const Coin = props => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M8.5 4.5V5.5" stroke="#1F3B21" stroke-width="1.2" stroke-linecap="square"/>
<path d="M8.5 10.5V11.5" stroke="#1F3B21" stroke-width="1.2" stroke-linecap="square"/>
<path d="M8.5 14C11.8137 14 14.5 11.3137 14.5 8C14.5 4.68629 11.8137 2 8.5 2C5.18629 2 2.5 4.68629 2.5 8C2.5 11.3137 5.18629 14 8.5 14Z" stroke="#79897A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 10.5H9.25C9.58152 10.5 9.89946 10.3683 10.1339 10.1339C10.3683 9.89946 10.5 9.58152 10.5 9.25C10.5 8.91848 10.3683 8.60054 10.1339 8.36612C9.89946 8.1317 9.58152 8 9.25 8H7.75C7.41848 8 7.10054 7.8683 6.86612 7.63388C6.6317 7.39946 6.5 7.08152 6.5 6.75C6.5 6.41848 6.6317 6.10054 6.86612 5.86612C7.10054 5.6317 7.41848 5.5 7.75 5.5H10" stroke="#1F3B21" stroke-width="1.2" stroke-linecap="square"/>
</svg>


);

export default Coin;
