import { useEffect, useState } from 'react';

const useDataFetching = ({ apiCallFunction, apiCallData = null, intialResponseValue = null }) => {
  const [response, setResponse] = useState(intialResponseValue);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      return apiCallFunction(apiCallData)
        .then(apiResponse => {
          setResponse(apiResponse);
        })
        .catch(error => {
          setError(error);
        });
    };

    fetchData();
  }, [apiCallFunction, apiCallData]);

  return [response, error];
};

export default useDataFetching;