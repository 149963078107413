import axios from 'axios';

import api from '../constants/api';
import store from '../setup/store';
import { getToken, getCurrency, getUserLang } from '../selectors/userSession';
import {loadFromStorage} from "../utils/localStorage"

const onFulfilledInterceptor = function (config) {
  const state = store.getState();
  config.headers['Authorization'] = `Bearer ${getToken(state)}`;
  config.headers['Accept-Language'] = getUserLang(state) || navigator.language;

  if (config.withCurrencyHeader) {
    config.headers['X-Accept-Currency'] = getCurrency(state);
  }
  return config;
};

const onErrorInterceptor = function (error) {
  return Promise.reject(error);
};

const getOrganizationImpersonationHeaders = () => {
  const currentlyActingAsOrganizationId = loadFromStorage('organizationId')
  return (currentlyActingAsOrganizationId
  ? {'x-organization': currentlyActingAsOrganizationId}
  : {})
}

export const axiosV1Instance = axios.create({
  baseURL: api.ROOT_URL,
  headers: getOrganizationImpersonationHeaders()
});

export const axiosV2Instance = axios.create({
  baseURL: api.ROOT_V2_URL,
  headers: getOrganizationImpersonationHeaders()
});

export const axiosEnterpriseActivationInstance = axios.create({
  baseURL: api.ENTERPRISE_ACTIVATION
})

export const updateRequestHeaders = () => {
  axiosV1Instance.defaults.headers = getOrganizationImpersonationHeaders()
  axiosV2Instance.defaults.headers = getOrganizationImpersonationHeaders()
}

axiosV1Instance.interceptors.request.use(onFulfilledInterceptor, onErrorInterceptor);
axiosV2Instance.interceptors.request.use(onFulfilledInterceptor, onErrorInterceptor);
// Enterprise Activations does not currently need any Bearer token
// axiosEnterpriseActivationInstance.interceptors.request.use(onFulfilledInterceptor, onErrorInterceptor);
