import React, {useState} from 'react'
import './RequestForProposalsResponseCard.css'
import {links} from '@reforestum/shared-utils'
import {Button} from '@reforestum/shared-components'
import {FormattedMessage, injectIntl} from 'react-intl'
import Heart from '../UI/Icons/Heart'
import MapTrifold from '../UI/Icons/MapTrifold'
import Trash from '../UI/Icons/Trash'
import Mail from '../UI/Icons/Mail'
import {rfpCard} from '../../constants/messages'
import TinyInfoBlock from '../UI/TinyInfoBlock/TinyInfoBlock'
import Plant from '../UI/Icons/Plant'
import Certification from '../UI/Icons/Certification'
import MapPinSimple from '../UI/Icons/MapPinSimple'
import Clock from '../UI/Icons/Clock'
import PlusCircle from '../UI/Icons/PlusCircle'
import formatDate from '../../constants/formatDate'
import Modal from '../UI/Modal/Modal'
import {
  queryUpdateRfpProposalStatus
} from '../Utils/queryRfpResults'
import Loader from '../UI/Loader/Loader'
import {axiosV2Instance} from '../../actions'
import api from '../../constants/api'
import store from '../../setup/store'
import {addAlert} from '../../actions/alerts'
import {getUserOrImpersonatingOrg} from '../../utils/usersAndOrganizations'
import HeartFilled from '../UI/Icons/HeartFilled'
import {Link, withRouter} from 'react-router-dom'
import {
  DEFAULT_PROJECT_IMAGE,
  PROPOSAL_STATUS_ARCHIVE,
  PROPOSAL_STATUS_DEFAULT,
  PROPOSAL_STATUS_SHORTLIST
} from './rfpConstants'
import getFeatureFlags from '../../constants/featureFlags'
import ArrowRight from '../UI/Icons/ArrowRight'
import renderTooltipForTrimmedText from '../../utils/renderTooltipForTrimmedText'
import Tooltip from '../UI/Tooltip/Tooltip'
import { renderPriceString } from '../ProposalDetails/helpers'

const MAX_VINTAGE_TAGS = 1
const PROJECT_DETAILS_MAX_TITLE_LENGTH = 45
const PROJECT_DETAILS_MAX_SUPPLIER_NAME_LENGTH = 43
const MODAL_FORM_ACTION__RFP_ADD_TO_PORTFOLIO_REQUEST = 'rfp-edit'
const MODAL_ADD_TO_PORTFOLIO = 'MODAL_ADD_TO_PORTFOLIO'

const RequestForProposalsResponseCard = (
  {
    activeTab = null,
    proposalId = '',
    projectId = '',
    projectName = '',
    projectImage = 'https://statics.reforestum.com/rfp/proposal_fallback.png',
    projectRating = '',
    projectLocation = '',
    projectDetailedLocation = '',
    projectTypology = '',
    projectStandard = '',
    supplierName = '',
    supplierEmail = '',
    receivedDate = 0,
    validUntilDate = 0,
    vintagesTagArray = [],
    minVolume = 0,
    maxVolume = 0,
    minPrice = 0,
    maxPrice = 0,
    priceUnit = 'USD',
    shortlisted = false,
    archived = false,
    intl,
    forceRefreshCount = 0,
    setForceRefreshCount = () => {
    },
    clickedContactSupplierModal = (o) => o,
    user,
    history
  }
) => {

  const ff = getFeatureFlags()
  const [modalToShow, setModalToShow] = useState(null)
  const [addToPortfolioRequestInput, setAddToPortfolioRequestInput] = useState(null)
  const [isUpdatingShortlistStatus, setIsUpdatingShortlistStatus] = useState(null)
  const [isUpdatingArchiveStatus, setIsUpdatingArchiveStatus] = useState(null)

  const handleShortlist = async () => {
    let statusToSet = shortlisted ? PROPOSAL_STATUS_DEFAULT : PROPOSAL_STATUS_SHORTLIST
    setIsUpdatingShortlistStatus(true)
    await queryUpdateRfpProposalStatus(proposalId, statusToSet)
    store.dispatch(addAlert({
        type: 'success',
        message: shortlisted ?
          <FormattedMessage id={'RfpResults.UnshortlistSuccess'} defaultMessage={'Proposal unshortlisted'}/> :
          <FormattedMessage id={'RfpResults.ShortlistSuccess'} defaultMessage={'Proposal shortlisted'}/>,
        dismissAfter: 3000
      })
    )
    setForceRefreshCount(forceRefreshCount + 1)
    setIsUpdatingShortlistStatus(false)

  }

  const handleArchive = async () => {
    let statusToSet = archived ? PROPOSAL_STATUS_DEFAULT : PROPOSAL_STATUS_ARCHIVE
    setIsUpdatingArchiveStatus(true)
    await queryUpdateRfpProposalStatus(proposalId, statusToSet).then(
      () => store.dispatch(addAlert({
        type: 'success',
        message: archived ?
          <FormattedMessage id={'RfpResults.UnarchiveSuccess'} defaultMessage={'Proposal unarchived'}/> :
          <FormattedMessage id={'RfpResults.ArchiveSuccess'} defaultMessage={'Proposal archived'}/>,
        dismissAfter: 3000
      }))
    )
    setForceRefreshCount(forceRefreshCount + 1)
    setIsUpdatingArchiveStatus(false)
  }

  const renderNoSidebarButtons = () => <>
    <Link to={links.getProjectUrl(projectId)} target="_blank">
      <Button target="_blank" size="M" outlineBlack>
        <MapTrifold/>
        <FormattedMessage
          id={'RfpResults.SeeProjectDetails'}
          defaultMessage={'See Project Details'}
        />
      </Button>
    </Link>
    <Button size="M" outlineBlack onClick={() => clickedContactSupplierModal({supplierEmail})}>
      <Mail/>
      <FormattedMessage
        id={'RfpResults.ContactSupplier'}
        defaultMessage={'Contact Supplier'}
      />
    </Button>
    <Button size="M" outlineBlack onClick={() => setModalToShow(MODAL_ADD_TO_PORTFOLIO)}>
      <PlusCircle/>
      <FormattedMessage
        id={'RfpResults.Portfolio'}
        defaultMessage={'Portfolio'}
      />
    </Button>
  </>

  const renderSidebarButtons = () => <>
    <Button size="L" outlineBlack onClick={(e) => {
      if (ff.proposalDetailsSidebar) {
        e.stopPropagation()
        openProposalDetails()
      }
    }}
    >
      <FormattedMessage
        id={'RfpResults.OpenProposalDetails'}
        defaultMessage={'See proposal details'}
      />
      <ArrowRight style={{width: '20px', height: '20px', marginLeft: '4px'}}/>
    </Button>
  </>

  const renderVintageTag = (v, i) =>
    <div
      key={`vintage-tag-${i}`}
      className={'rfp-p-card__quick-points__group__tag'}
    >
      {v}
    </div>


  const renderQuickPoints = () => <div className={'rfp-p-card__quick-points'}>
    <div className={'rfp-p-card__quick-points__group'}>
      {
        Array.isArray(vintagesTagArray) && vintagesTagArray.length > 0 &&
        <div className={'rfp-p-card__label rfp-p-card__quick-points__group__label'}>
          <FormattedMessage id={'RfpResults.Vintages'} defaultMessage={'Vintages'}/>
        </div>
      }
      {
        Array.isArray(vintagesTagArray) && <>
          {
            //If it is only one tag bigger, it doesn't make sense to add +1, instead we put directly the vintage
            //that is why we add +1 in the comparation
            vintagesTagArray.length > (MAX_VINTAGE_TAGS + 1)
              ? <div className='rfp-p-card__quick-points__group__tag__container'>
                {vintagesTagArray.slice(0, MAX_VINTAGE_TAGS).map(renderVintageTag)}
                <div className={'rfp-p-card__quick-points__group__tag rfp-p-card__quick-points__group__tag__plus-more'}>
                  <Tooltip
                    position={'bottom'}
                    content={
                        vintagesTagArray.slice(MAX_VINTAGE_TAGS).join(', ')
                    }
                  >
                    <span>+{vintagesTagArray.length - MAX_VINTAGE_TAGS}</span>
                  </Tooltip>
                </div>
              </div>
              : <div
                className='rfp-p-card__quick-points__group__tag__container'>{vintagesTagArray.map(renderVintageTag)}</div>
          }
        </>
      }
    </div>
    {validUntilDate && <div className={'rfp-p-card__quick-points__group'}>
      <div className={'rfp-p-card__label rfp-p-card__quick-points__group__label'}>
        <FormattedMessage id={'RfpResults.ValidUntil'} defaultMessage={'Valid Until'}/>
      </div>
      <div className={'rfp-p-card__quick-points__group__value rfp-p-card__quick-points__group__value__valid-until'}>
        {formatDate(validUntilDate)}
      </div>
    </div>}
  </div>

  const renderActionsAndReceivedDate = () => <>
    <Button onClick={e => {e.stopPropagation(); handleShortlist()}}>
      {
        isUpdatingShortlistStatus
          ? <Loader/>
          : shortlisted
            ? <HeartFilled fill={'#F08862'}/>
            : <Heart fill={shortlisted ? 'var(--ref-green-300)' : null}/>
      }
      <span
        className={`rfp-p-card__actions__shortlist-button-text ${shortlisted ? 'rfp-p-card__actions__shortlist-button-text__shortlisted' : ''}`}
      >
        <FormattedMessage
          id={'RfpResults.Shortlist'}
          defaultMessage={'Shortlist'}
        />
      </span>
    </Button>
    <Button onClick={e => {e.stopPropagation(); handleArchive()}}>
      {
        isUpdatingArchiveStatus
          ? <Loader/>
          : <Trash/>
      }
      {
        archived
          ? <FormattedMessage
            id={'RfpResults.Unarchive'}
            defaultMessage={'Unarchive'}
          />
          : <FormattedMessage
            id={'RfpResults.Archive'}
            defaultMessage={'Archive'}
          />
      }
    </Button>
    <Button onClick={(e) => {e.stopPropagation(); clickedContactSupplierModal({supplierEmail})}}>
      <Mail/>
      <FormattedMessage
        id={'RfpResults.ContactSupplier'}
        defaultMessage={'Contact Supplier'}
      />
    </Button>
    <div className={'rfp-p-card__received'}>
      <Clock/>
      <div className={'rfp-p-card__label rfp-p-card__received__label'}>
        <FormattedMessage id={'RfpResults.RfpReceived'} defaultMessage={'Received'}/>
      </div>
      <div className={'rfp-p-card__received__value'}>
        {formatDate(receivedDate)}
      </div>
    </div>
  </>

  const renderVolumeString = () => {
    let numericString = `${intl.formatNumber(minVolume)}`

    if (minVolume && maxVolume && minVolume !== maxVolume) {
      numericString = `${intl.formatNumber(minVolume)} - ${intl.formatNumber(maxVolume)}`
    }

    return <span>
                                       <span style={{whiteSpace: "nowrap"}}>{numericString}</span>
      &nbsp;
      <FormattedMessage
        id={'RfpResults.VolumeUnitTons'}
        defaultMessage={'tons'}
      />
</span>
  }

  const renderKeyInfo = () => <div className={'rfp-p-card__key-info__group-wrapper'}>
    {
      minVolume &&
      <div className={'rfp-p-card__key-info__group'}>
        <div className={'rfp-p-card__key-info__group__value'}>
          {renderVolumeString({intl, minVolume, maxVolume})}
        </div>
        <div className={'rfp-p-card__label rfp-p-card__key-info__group__label'}>
          <FormattedMessage id={'RfpResults.Volume'} defaultMessage={'Volume'}/>
        </div>
      </div>
    }


    {(minPrice || maxPrice) && <div className={'rfp-p-card__key-info__group'}>
      <div className={'rfp-p-card__key-info__group__value'}>
        {renderPriceString({intl, minPrice, maxPrice, priceUnit})}
      </div>
      <div className={'rfp-p-card__label rfp-p-card__key-info__group__label'}>
        <FormattedMessage id={'RfpResults.Price'} defaultMessage={'Price'}/>
      </div>
    </div>}

  </div>

  const renderProjectInfo = () => <div className={'rfp-p-card__project-image-and-info'}>
    <div className={'rfp-p-card__project-image'}>
      <img alt={'Project depiction'} src={projectImage || DEFAULT_PROJECT_IMAGE}/>
    </div>
    <div className={'rfp-p-card__project-details'}>
      <div>
        <h1>
          {
            projectName.length > PROJECT_DETAILS_MAX_TITLE_LENGTH
              ? renderTooltipForTrimmedText({text: projectName, maxLength: PROJECT_DETAILS_MAX_TITLE_LENGTH})
              : projectName
          }
        </h1>
        <div className={'rfp-p-card__supplier-and-vintage'}>
          <span>
            <FormattedMessage id={'RfpResults.ProposalBy'} defaultMessage={'Proposal by'}/>
            &nbsp;
            <span className={'rfp-p-card__supplier-name'}>{
            supplierName.length > PROJECT_DETAILS_MAX_SUPPLIER_NAME_LENGTH
              ? renderTooltipForTrimmedText({text: supplierName, maxLength: PROJECT_DETAILS_MAX_SUPPLIER_NAME_LENGTH})
              : supplierName
            }</span>
          </span>
          {renderQuickPoints()}
        </div>
      </div>
      <div className={'rfp-p-card__info-wrapper'}>
        <div className={'rfp-p-card__tiny-info-blocks'}>
          {renderProjectTinyInfoBlocks()}
        </div>
        <div className={'rfp-p-card__key-info'}>
          {renderKeyInfo()}
        </div>
      </div>
    </div>
  </div>

  const renderProjectTinyInfoBlocks = () => {

    //Add/remove the changed blocks bellow here
    const numberOfRenderedBlocks = [projectLocation, projectDetailedLocation, projectTypology, projectStandard, projectRating]
      .reduce((accumulator, currentValue) => accumulator + (currentValue ? 1 : 0), 0);

    const  PROJECT_DETAILS_MAX_TINY_INFO_TAG_LENGTH = Math.round(60 / ((numberOfRenderedBlocks > 1) ? numberOfRenderedBlocks : 1))
    const blocks = [
      {
        shouldRender: projectLocation,
        props: {
          className: 'rfp-card__tiny-info-block',
          title: intl.formatMessage(rfpCard.country),
          value: <span>
            <MapTrifold/>
            {projectLocation && projectLocation.length > PROJECT_DETAILS_MAX_TINY_INFO_TAG_LENGTH
              ? renderTooltipForTrimmedText({
                text: projectLocation,
                maxLength: PROJECT_DETAILS_MAX_TINY_INFO_TAG_LENGTH,
                trimmedUpperCase: true
              })
              : <span style={{textTransform: 'uppercase'}}>{projectLocation}</span>}
         </span>,
          unit: 'unit'
        }
      },
      {
        shouldRender: projectDetailedLocation,
        props: {
          className: 'rfp-card__tiny-info-block',
          title: intl.formatMessage(rfpCard.location),
          value: <span>
             <MapPinSimple/>
            {projectDetailedLocation && projectDetailedLocation.length > PROJECT_DETAILS_MAX_TINY_INFO_TAG_LENGTH
              ? renderTooltipForTrimmedText({
                text: projectDetailedLocation,
                maxLength: PROJECT_DETAILS_MAX_TINY_INFO_TAG_LENGTH,
                trimmedUpperCase: true
              })
              : <span style={{textTransform: 'uppercase'}}>{projectDetailedLocation}</span>}
         </span>,
          unit: 'unit'
        }
      },
      {
        shouldRender: projectTypology,
        props: {
          className: 'rfp-card__tiny-info-block',
          title: intl.formatMessage(rfpCard.projectType),
          value: <span>
            <Plant/>
            {
              projectTypology && projectTypology.length > PROJECT_DETAILS_MAX_TINY_INFO_TAG_LENGTH
                ? renderTooltipForTrimmedText({
                  text: projectTypology,
                  maxLength: PROJECT_DETAILS_MAX_TINY_INFO_TAG_LENGTH,
                  trimmedUpperCase: true
                })
                : <span style={{textTransform: 'uppercase'}}>{projectTypology}</span>
            }
          </span>
        }
      },
      {
        shouldRender: projectStandard,
        props: {
          className: 'rfp-card__tiny-info-block',
          title: intl.formatMessage(rfpCard.registry),
          value: <span>
            <Certification/>
            {projectStandard}
          </span>
        }
      },
      {
        shouldRender: projectRating,
        props: {
          className: 'rfp-card__tiny-info-block',
          title: intl.formatMessage(rfpCard.beZeroRating),
          value: projectRating
        }
      },
    ]

    const blocksToRender = blocks.filter(b => b.shouldRender)

    return blocksToRender
      .filter(b => b.shouldRender)
      .map(
        (b, i) => <TinyInfoBlock
          index={`tiny-info-block-${i}`}
          divider={blocksToRender.length > i + 1}
          dividerSeparation={'S'}
          {...b.props}
        />
      )
  }

  const submitAddToPortfolio = async () => {
    const userInput = {
      addToPortfolioRequestInput
    }
    await axiosV2Instance.post(api.RECORD_FORM_RESPONSE_v2, {
      action: MODAL_FORM_ACTION__RFP_ADD_TO_PORTFOLIO_REQUEST,
      context: {
        userInput,
        proposalId,
        userId: `${user.id}`,
        email: user.email,
        orgId: `${getUserOrImpersonatingOrg(user).organization_id}`
      }
    })
    setModalToShow(null)
    store.dispatch(addAlert(
      {
        type: 'success',
        message: intl.formatMessage(rfpCard.AddToPortfolioRFPFormSubmitted),
        dismissAfter: 8000
      }
    ))
    setModalToShow(null)
  }


  const openProposalDetails = () => {
    history.push(`/rfp/0/proposal/${proposalId}/${activeTab || ''}`)
  }

  const renderCorrectModals = () => {
    let result = null

    if (modalToShow === MODAL_ADD_TO_PORTFOLIO) {
      result = <Modal
        large
        clickClose={() => setModalToShow(null)}
        content={<div>
          <h1>
            <FormattedMessage
              id={'RfpResults.AddToPortfolio'}
              defaultMessage={'Add to Portfolio'}
            />
          </h1>
          <p>
            <FormattedMessage
              id={'RfpResults.AddToPortfolioP1'}
              defaultMessage={'Have you already agreed and contracted some quantity of this proposal with the supplier? '}
            />
          </p>
          <p>
            <FormattedMessage
              id={'RfpResults.AddToPortfolioP2'}
              defaultMessage={'Please provide further information about the amounts contracted and delivery conditions in order to track them in the platform.'}
            />
          </p>
          <p className={'rfp-modal-instruction'}>
            <FormattedMessage
              id={'RfpResults.AddToPortfolioFormInstruction'}
              defaultMessage={'Contracted Amount and Delivery Information'}
            />
          </p>
          <textarea
            placeholder={intl.formatMessage(rfpCard.AddToPortfolioFormPlaceholder)}
            onChange={e => setAddToPortfolioRequestInput(e.target.value)}
          >
            {addToPortfolioRequestInput}
          </textarea>
          <p>
            <FormattedMessage
              id={'RfpResults.AddToPortfolioP3'}
              defaultMessage={'The Reforestum team will add these to your current portfolio so you can centrally manage all your VCM contributions.'}
            />
          </p>
          <div className={'rfp-modal-button-container rfp-modal-button-container__portfolio'}>
            <Button disabled={!addToPortfolioRequestInput}
                    className={!addToPortfolioRequestInput && 'rfp-modal-button--disabled'}
                    onClick={submitAddToPortfolio}>
              <PlusCircle/>
              <FormattedMessage
                id={'RfpResults.AddToPortfolioButtonText'}
                defaultMessage={'Add to Current Portfolio'}
              />
            </Button>
          </div>
        </div>}
      />
    }

    return result
  }

  const renderCard = () => <div
    className={'rfp-p-card design-system-elevation-base'}
    onClick={(e) => {
      if (ff.proposalDetailsSidebar) {
        openProposalDetails()
      }
      e.stopPropagation()
    }}
  >
    {renderCorrectModals()}
    <div className={'rfp-p-card__top-half'}>
      <div className={'rfp-p-card__project-info'}>
        {renderProjectInfo()}
      </div>
    </div>
    <div className={'rfp-p-card__bottom-half'}>
      <div className={'rfp-p-card__actions-and-key-info'}>
        <div className={'rfp-p-card__actions'}>
          {renderActionsAndReceivedDate()}
        </div>
      </div>
      <div className={'rfp-p-card__buttons'}>
        {ff.proposalDetailsSidebar ? renderSidebarButtons() : renderNoSidebarButtons()}
      </div>
    </div>
  </div>

  const renderMain = () => {
    return renderCard()
  }


  return renderMain()
}

export default injectIntl(withRouter(RequestForProposalsResponseCard))