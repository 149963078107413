import actionTypes from '../constants/actionTypes';

const initialState = {
  isFetchingMyContributions: false,
  myContributions: [],
  myContributionsErrorMessages: null
};

export default (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.MY_CONTRIBUTIONS_START:
        return {
          ...state,
          isFetchingMyContributions: true
        }
      case actionTypes.MY_CONTRIBUTIONS_COMPLETE:
        return {
          ...state,
          isFetchingMyContributions: false,
          myContributions: action.payload
        };
      case actionTypes.MY_CONTRIBUTIONS_ERROR:
        return {
          ...state,
          isFetchingMyContributions: false,
          myContributionsErrorMessages: action.payload
        }
      default:
        return state;
    }
};

export const getMyContributionsInPublicProfile = state => state.myContributions;
export const getIsFetchingMyContributionsInPublicProfile = state => state.isFetchingMyContributions;
export const getMyContributionsInPublicProfileErrors = state => state.myContributionsErrorMessages;