import React from 'react'
import './SidebarPanel.css'
import Close from '../Icons/Close'

const SidebarPanel = (
  {
    content,
    toolbarContent=null,
    clickClose=()=>{},
    scrollRef=null,
  } = {}
) => {
  const [closeClicked, setCloseClicked] = React.useState(false)
  const sidebarPanelContentProps = scrollRef ? {ref: scrollRef} : {}
  const renderMain = () => <div className={`sidebar-panel ${closeClicked ? 'sidebar-panel--closing' : ''}`} onClick={event => event.stopPropagation()}>
    <div
      className={'sidebar-panel__toolbar'}
    >
      {toolbarContent}
      <Close
        onClick={() => {
          setCloseClicked(true)
          setTimeout(() => {
            clickClose()
          }
          , 300)
        }}
        className={`sidebar-panel__close-icon`}
        stroke={'var(--ref-green-600)'}
      />
    </div>
    <div className={'sidebar-panel__content'} {...sidebarPanelContentProps}>
      {content}
    </div>
  </div>

  return renderMain()
}

export default SidebarPanel
