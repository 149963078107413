import React from 'react'
import PropTypes from 'prop-types'

import Tabbed from '../../UI/Tabs/Tabbed'

import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Component } from 'react'

//If no tab is specified as a param in the url, a reedirection is done to include the first Tab name in the url
// and the first tab is render. In case there is a tab specified in the URL, that tab is rendered.
class RoutedTabbed extends Component {

  constructor(props) {
    super(props)
      //Property used to go to the top of the page when clicking a link that updates the tab 
      //and keep the scroll position when the user clicks on the routted tabs 
      this.doNotScrollBarUp = false

  }

  componentWillReceiveProps(nextProps) {
    //If the tab is not updated, we end the lifecycle event
    //same if the tab is updated but the old tab was the root of the new tab
    if((nextProps.location.pathname === this.props.location.pathname) || nextProps.location.pathname.indexOf(this.props.location.pathname) !== -1) {
      this.doNotScrollBarUp = false
      return
    }

    const {resetScroll} = this.props
    if(!this.doNotScrollBarUp) {
      resetScroll && resetScroll()
      
    } else {
      //Reset to default value
      this.doNotScrollBarUp = false
    }
  }



  render() {
   const { match,
    history,
    paths,
    tabsWithoutRouting,
    ...props} = this.props 

    if(tabsWithoutRouting) {
      return (
        <Tabbed
          {...props}
          onSelect={() => {
            //Indicate that in the next component rerender, the scrollbar should keep in the same position
            this.doNotScrollBarUp = true
          }} />
      )
    }

  return (
    <div>
      <Switch>
        <Redirect from={`${match.path}`} to={`${match.path}/${paths[0]}`} exact />
      </Switch>
      <Switch>
        <Route path={`${match.url}/:tab`} children={
              (args) => {
                //Reedirect to first tab when wrong url
                const nonExistingTab = paths.indexOf(args.match.params.tab) === -1
                if(nonExistingTab) {
                  history.push(`${match.url}/${paths[0]}`)
                }

                return (
                  <Tabbed
                    {...props}
                    selectedIndex={!nonExistingTab ? paths.indexOf(args.match.params.tab) : 0}
                    onSelect={(tabIndex) => {
                      //Indicate that in the next component rerender, the scrollbar should keep in the same position
                      this.doNotScrollBarUp = true
                      history.push(`${match.url}/${paths[tabIndex]}`)
                    }} />
                );
              }
            }/>
          <Route path={`${match.url}`} exact children={
              () => {
                return (
                  <Tabbed
                    {...props}
                    selectedIndex={0}
                    onSelect={(tabIndex) => {
                       //Indicate that in the next component rerender, the scrollbar should keep in the same position
                       this.doNotScrollBarUp = true
                      history.push(`${match.url}/${paths[tabIndex]}`)}
                    } />
                );
              }
            }/>
        </Switch>
    </div>
  )
}

}


RoutedTabbed.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  paths: PropTypes.array.isRequired,
}

export default withRouter(RoutedTabbed)
