import React from 'react';

const PublicTransport = props => <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
	<path d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4Z" fill="#F2F2F0"/>
	<path d="M12.125 20H27.875" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
	<path d="M12.125 14.75H27.875" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
	<path d="M14.27 30.14L13.91 30.62L14.87 31.34L15.23 30.86L14.27 30.14ZM16.52 27.14L14.27 30.14L15.23 30.86L17.48 27.86L16.52 27.14Z" fill="#1F3B21"/>
	<path d="M24.77 30.86L25.13 31.34L26.09 30.62L25.73 30.14L24.77 30.86ZM22.52 27.86L24.77 30.86L25.73 30.14L23.48 27.14L22.52 27.86Z" fill="#1F3B21"/>
	<path d="M25.625 11H14.375C13.1324 11 12.125 12.0074 12.125 13.25V25.25C12.125 26.4926 13.1324 27.5 14.375 27.5H25.625C26.8676 27.5 27.875 26.4926 27.875 25.25V13.25C27.875 12.0074 26.8676 11 25.625 11Z" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
	<path d="M20 14.75V20" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
	<path d="M15.875 25.25C16.4963 25.25 17 24.7463 17 24.125C17 23.5037 16.4963 23 15.875 23C15.2537 23 14.75 23.5037 14.75 24.125C14.75 24.7463 15.2537 25.25 15.875 25.25Z" fill="#1F3B21"/>
	<path d="M24.125 25.25C24.7463 25.25 25.25 24.7463 25.25 24.125C25.25 23.5037 24.7463 23 24.125 23C23.5037 23 23 23.5037 23 24.125C23 24.7463 23.5037 25.25 24.125 25.25Z" fill="#1F3B21"/>
</svg>


export default PublicTransport;
