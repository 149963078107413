import React, { Component } from 'react';
import HeaderLinkWithIcon from '../HeaderLinkWithIcon';
import './HeaderExpandableSectionMobile.css';

class HeaderExpandableSectionMobile extends Component {
    constructor(props) {
        super(props);
        this.state = { isExpanded: false };
    }

    render() {
        const { isExpanded } = this.state;
        return ( 
            <div style={{width: "100%"}}>
                <HeaderLinkWithIcon onClick={() => this.setState({isExpanded: !this.state.isExpanded})} sectionExpanded={isExpanded} icon={this.props.headerIcon} expandable isMobile className="topbar-nav__item">
                    {this.props.headerLink}
                </HeaderLinkWithIcon>
                {isExpanded && <div className="header-expandable-section-mobile-content">
                    {this.props.dropdownContent.map(({title, link}) => 
                                <HeaderLinkWithIcon key={JSON.stringify(title)} to={link} className="topbar-nav__item topbar-nav__item-secondary">
                                    {title}
            
                                </HeaderLinkWithIcon>
                    )}
                </div>}
            </div>
        )
    }
}

export default HeaderExpandableSectionMobile;