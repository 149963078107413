import React, { Component } from 'react';
import Proptypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Router } from 'react-router-dom';

import AlertsProvider from '../containers/AlertsProvider';
import GalleryProvider from '../containers/GalleryProvider';

import { intercomAppId } from '../constants/config';

import { getToken, getUserDetails, getIsUserAuthenticated, getUserLang } from '../selectors/userSession';
import { refreshToken, startRefreshInterval, guestAuthenticate } from '../actions/userSession';
import { getIsLoading, getGuestErrorMessages } from '../selectors/userActions';

import { showModal, hideModal } from '../actions/modal';
import { setIsMobileResolution } from '../actions/UI';

import Frame from '../components/Layout/Frame/Frame';
import Loader from '../components/UI/Loader/Loader';
import ModalRoot from '../components/UI/ModalRoot/ModalRoot';

import FullErrorFetching from '../components/UI/Interface/FullErrorFetching';

import { messages, getMessagesLocale } from './messages';
import history from './history';
import routes from './routes';
import { getIsMobileResolution } from '../selectors/UI';
import { MOBILE_RESOLUTION_MAX_WIDTH } from '../constants/UI';
import { posthog } from 'posthog-js';
import { getCookie } from '../utils/cookie';

class Root extends Component {

  constructor(props){
    super(props);

    this.guestAuth = this.guestAuth.bind(this);
    this.handleIntercomSession = this.handleIntercomSession.bind(this);
    this.handleResize = this.handleResize.bind(this);

  }

  componentWillMount(){
    const {setIsMobileResolution} = this.props;
    this.guestAuth(this.props.token);
    this.props.store.dispatch(refreshToken());
    this.props.store.dispatch(startRefreshInterval());

    // only send event if cookie not present (otherwise banner will not be visible)
    if (!getCookie("tarteaucitron")) {
      posthog.capture("cookie_banner_visible")
    }

    if(window.innerWidth > 768) this.handleIntercomSession();

    setIsMobileResolution(window.innerWidth < MOBILE_RESOLUTION_MAX_WIDTH)
    window.addEventListener("resize", this.handleResize)
  }

  componentWillReceiveProps(newProps){
    if (!newProps.isWorking && !newProps.token) {
      this.guestAuth(newProps.token);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize() {
    const {isMobileResolution, setIsMobileResolution} = this.props
    const newResolutionIsMobile = window.innerWidth < MOBILE_RESOLUTION_MAX_WIDTH
    if(newResolutionIsMobile !== isMobileResolution) {
      setIsMobileResolution(newResolutionIsMobile)
    }
  }

  guestAuth(token) {
    if (!token) {
      this.props.store.dispatch(guestAuthenticate());
    }
  }

  handleIntercomSession() {
    const { isUserAuthenticated, userDetails } = this.props;

    history.listen((location) => {
      // INTERCOM: Update session on route change ==========
      window.Intercom('update')
      // ==========
      if (window.ga) {
        window.ga('send', 'pageview', {
          page: location.pathname
        });
      }
    });

    if (isUserAuthenticated) {
      // INTERCOM: Boot session with authenticated user data =========
      window.Intercom('boot', {
        app_id: intercomAppId,
        user_id: userDetails.id,
        email: userDetails.email,
        name: `${userDetails.name} ${userDetails.surname}`,
      });
      // ==========
    } else {
      // INTERCOM: Boot guest session =========
      window.Intercom('boot', { app_id: intercomAppId });
      // ==========
    }
  }

  render() {
    const {
      guestError,
      store,
      token,
      modal,
      showModal,
      hideModal,
      lang
    } = this.props;

    if (!token) {
      return (
        <Frame>
          <Loader />
        </Frame>
      );
    }

    return (
      <IntlProvider
        locale={lang}
        messages={messages(lang)}
      >
        <Provider store={store}>
          <AlertsProvider>
            <Router history={history}>
              <div>
                {
                  guestError &&
                  <Frame>
                    <FullErrorFetching
                      retry={this.guestAuth(token)}
                      errorMessage={guestError}
                    />
                  </Frame>
                }
                <GalleryProvider/>
                {routes()}
                <ModalRoot {...modal} showModal={showModal} hideModal={hideModal} />
              </div>
            </Router>
          </AlertsProvider>
        </Provider>
      </IntlProvider>
    );
  }

}

const mapStateToProps = state => ({
  guestError: getGuestErrorMessages(state),
  isWorking: getIsLoading(state),
  token: getToken(state),
  isUserAuthenticated: getIsUserAuthenticated(state),
  userDetails: getUserDetails(state),
  modal: state.modal,
  isMobileResolution: getIsMobileResolution(state),
  lang: getUserLang(state) != null ? getMessagesLocale(getUserLang(state)) : getMessagesLocale(navigator.language),
});

Root.propTypes = {
  guestError: Proptypes.string,
  isWorking: Proptypes.bool.isRequired,
  store: Proptypes.object.isRequired,
  token: Proptypes.string,
  modal: Proptypes.object.isRequired,
  showModal: Proptypes.func.isRequired,
  hideModal: Proptypes.func.isRequired,
  lang: Proptypes.string.isRequired,
};

export default connect(mapStateToProps, { showModal, hideModal, setIsMobileResolution})(Root);
