import React, { Component } from 'react';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import history from '../setup/history';
import { links } from '@reforestum/shared-utils'

import { signup } from '../actions/userActions';
import * as authSelector from '../selectors/userActions';
import * as userSelector from '../selectors/userSession';

import Signup from '../components/Auth/Signup';
import getIsAutheticatedFromStore from '../utils/getIsAutheticatedFromStore';

class SignupContainer extends Component {

  constructor(props){
    super(props)

    this.handleRedirectionOnSignupSuccessfully = this.handleRedirectionOnSignupSuccessfully.bind(this)    
  }

  componentDidMount() {
    mixpanel.track("Page view", {
      "Authenticated": getIsAutheticatedFromStore(),
      "Action": "Sign up",
      "Domain": "App"
    });
  }

  componentWillMount() {
    const { isAuthenticated } = this.props;

    if (isAuthenticated) {
      history.push(links.getHomeUrl());
    }
  }

  componentWillReceiveProps(newProps) {
    const { isAuthenticated } = newProps;

    if (isAuthenticated) {
      history.push(links.getHomeUrl());
    }
  }

  handleRedirectionOnSignupSuccessfully(){
    if(!this.props.signupErrorMessages){
      history.push(links.getHomeUrl());
    }
  }

  handleSignup(form) {
    this.props.signup(form, this.handleRedirectionOnSignupSuccessfully);
  }

  render() {

    const {
      signupErrorMessages,
      isLoading
    } = this.props;

    return (
      <Signup
        signupErrorMessages={signupErrorMessages}
        handleSignup={this.handleSignup.bind(this)}
        isLoading={isLoading}
      />
    );
  }
}

const mapStateToProps = state => ({
  signupErrorMessages: authSelector.getSignupErrorMessages(state),
  isAuthenticated: userSelector.getIsUserAuthenticated(state),
  isLoading: authSelector.getIsLoading(state),
});

SignupContainer.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  signup: PropTypes.func.isRequired,
  signupErrorMessages: PropTypes.string,
};

export default connect(
  mapStateToProps,
  { signup }
)(SignupContainer);
