import React from 'react';
import { links } from '@reforestum/shared-utils'
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../../../setup/history';
import { getUserOrImpersonatingOrg, isImpersonatingOrg } from '../../../../utils/usersAndOrganizations';

//selectors
import {getIsUserAuthenticated, getToken, getUserDetails} from '../../../../selectors/userSession';
import { getCountries } from '../../../../selectors/countries';

//actions
import { addAlert } from '../../../../actions/alerts';
import { updateAvatar, updateUser } from '../../../../actions/userActions';
import { updateOrganizationAvatar, updateOrg, createNewOrg } from '../../../../actions/orgActions';
import { refreshProfile } from '../../../../actions/userSession';
import { fetchCountries } from '../../../../actions/countries';
import { fetchInvoices } from '../../../../actions/invoices'
import { fetchCertificates } from '../../../../actions/certificates'
import { humanReadableCountryName } from '../../../../utils/countries';
import { getUpdateErrorMessages } from '../../../../selectors/userActions';
import { getInvoices } from '../../../../selectors/invoices';
import { getCertificates } from '../../../../selectors/certificates';
import { injectIntl, intlShape } from 'react-intl';
import OrganizationInformation from './OrganizationInformation';
import PersonalInformation from '../../UserSettings/PersonalInformation/PersonalInformation';
import { settingsMessages } from '../../../../constants/messages';

class UpdateInformationInModalContainer extends Component {

  componentDidMount() {
    const { isAuthenticated } = this.props;

    if (!isAuthenticated) {
      history.push(links.getHomeUrl());
    }
  }


  componentWillReceiveProps(newProps) {
    const { isAuthenticated } = newProps;
    if (!isAuthenticated) {
      history.push(links.getHomeUrl());
    }
  }

  render() {
    const { user, countries, intl } = this.props;
    const impersonatingOrg = isImpersonatingOrg()
    const userOrImpersonatingOrg = getUserOrImpersonatingOrg(user)
    const userOrOrgProps = {
      country: humanReadableCountryName(userOrImpersonatingOrg.country, countries),
      [impersonatingOrg ? 'org' : 'user']: userOrImpersonatingOrg,
      settingName: impersonatingOrg ? intl.formatMessage(settingsMessages.orgInformation) : intl.formatMessage(settingsMessages.personalInformation)
    }

    const props = {
      ...this.props,
      ...userOrOrgProps
     
    }
    return (isImpersonatingOrg ? <OrganizationInformation {...props}/> : <PersonalInformation {...props}/>);
  }
}

const mapStateToProps = state => ({
  isAuthenticated: getIsUserAuthenticated(state),
  user: getUserDetails(state),
  countries: getCountries(state),
  updateErrorMessages: getUpdateErrorMessages(state),
  invoices: getInvoices(state),
  certificates: getCertificates(state),
  token: getToken(state),
});

UpdateInformationInModalContainer.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  createNewOrgScreen: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default connect(
  mapStateToProps,
  {
    addAlert,
    updateAvatar,
    updateOrganizationAvatar,
    updateUser,
    updateOrg,
    createNewOrg,
    fetchCountries,
    refreshProfile,
    fetchInvoices,
    fetchCertificates,
  }
)(injectIntl(UpdateInformationInModalContainer));
