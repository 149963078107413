import React from 'react'
import '../ProposalDetails.scss'
import './ProposalDetailsPriceAndAvailability.scss'
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl'
import ReactTable from 'react-table'
import HorizontalScrollWithArrowsContainer from '../../UI/HorizontalScrollWIthArrowsContainer/HorizontalScrollWIthArrowsContainer'
import FormattedUnit from '../../Utils/FormattedUnit'
import getCurrencySymbol from '../../../utils/getCurrencySymbol'
import ProposalDetailsExtras from '../ProposalDetailsExtras'

const ProposalDetailsPriceAndAvailability = ({intl, data, extrasToRender}={}) => {


  function getTonsRangeFormatted({minTons, maxTons}) {
    return minTons || maxTons ? (
      <span>
        {(minTons || minTons === 0) ? <FormattedNumber value={minTons}/> : ''}
        {(minTons || minTons === 0) && maxTons ? ' - ' : ''}
        {maxTons ? <FormattedNumber value={maxTons}/> : ''}
        {' '}
        <FormattedUnit unit={'t'}/>
      </span>
    ) : null;
  }


  function getExPostColumns() {
    return [
      {
        Header: <FormattedMessage id={'proposalDetails.priceAndAvailability.columns.vintage'} defaultMessage={'Vintage'}/>,
        accessor: 'vintage',
        id: 'vintage',
      },
      {
        Header: <FormattedMessage id={'proposalDetails.priceAndAvailability.columns.purchasingVolume'} defaultMessage={'Purchasing Volume'}/>,
        accessor: (row) => {
          return getTonsRangeFormatted({
            minTons: row.min_purchasing_volume,
            maxTons: row.max_purchasing_volume,
          })
        },
        id: 'purchasingVolume',
      },
      {
        Header: <FormattedMessage id={'proposalDetails.priceAndAvailability.columns.price'} defaultMessage={'Price'}/>,
        accessor: (row) => {
          return (
            <span>
              <FormattedNumber value={row.price}/>
              {' '}
              {getCurrencySymbol(row.price_unit)}
              /ton
            </span>
          )
        },
        id: 'price',
      },
    ];
  }

  function getExanteColumns() {
    return [
      {
        Header: <FormattedMessage id={'proposalDetails.priceAndAvailability.columns.deliveryDate'} defaultMessage={'Delivery Date'}/>,
        accessor: 'delivery_date',
        id: 'delivery_date',
        maxWidth: 110,
      },
      {
        Header: <FormattedMessage id={'proposalDetails.priceAndAvailability.columns.verificationPeriod'} defaultMessage={'Verification Period'}/>,
        accessor: 'verification_period',
        id: 'verification_period',
        maxWidth: 160,
      },
      {
        Header: <FormattedMessage id={'proposalDetails.priceAndAvailability.columns.purchasingVolume'} defaultMessage={'Purchasing Volume'}/>,
        accessor: (row) => {
          return getTonsRangeFormatted({
            minTons: row.min_purchasing_volume,
            maxTons: row.max_purchasing_volume,
          })
        },
        id: 'purchasingVolume',
      },
      {
        Header: <FormattedMessage id={'proposalDetails.priceAndAvailability.columns.price'} defaultMessage={'Price'}/>,
        id: 'price',
        maxWidth: 100,
        accessor: (row) => {
          return (
            <span>
              <FormattedNumber value={row.price}/>
              {' '}
              {getCurrencySymbol(row.price_unit)}
              /ton
            </span>
          )
        }
      },
    ]
  }

  function renderExPostSection(data, intl) {
  return (
    <div className={'proposal-details-price-and-availability-section'}>
      <div className={'proposal-details-price-and-availability-section__title'}>
        <FormattedMessage id={'proposalDetails.priceAndAvailability.exPost.title'} defaultMessage={'Ex-Post Credits'} />
      </div>
      <HorizontalScrollWithArrowsContainer hideScrollbar centered className={'proposal-details-price-and-availability-section__table'}>
        <ReactTable
          data={data}
          columns={getExPostColumns(intl)}
          showPagination={false}
          sortable={false}
          minRows={0}
          loadingText={null}
        />
      </HorizontalScrollWithArrowsContainer>
    </div>
  );
  }

  function renderExAnteSection(data, intl) {
    return (
      <div className={'proposal-details-price-and-availability-section'}>
        <div className={'proposal-details-price-and-availability-section__title'}>
          <FormattedMessage id={'proposalDetails.priceAndAvailability.exAnte.title'} defaultMessage={'Ex-Ante Deliveries (Future Credits)'} />
        </div>
        <HorizontalScrollWithArrowsContainer hideScrollbar centered className={'proposal-details-price-and-availability-section__table'}>
          <ReactTable
            data={data}
            columns={getExanteColumns(intl)}
            showPagination={false}
            sortable={false}
            minRows={0}
            loadingText={null}
          />
        </HorizontalScrollWithArrowsContainer>
      </div>
    );
  }

  return (
    <div className={'proposal-details-price-and-availability-container'}>
      {data.ex_post && data.ex_post.length > 0 && renderExPostSection(data.ex_post, intl)}
      {data.ex_ante && data.ex_ante.length > 0 && renderExAnteSection(data.ex_ante, intl)}
      {
        extrasToRender &&
        <ProposalDetailsExtras
          extrasToRender={extrasToRender}
        />
      }
    </div>
  ) 
}


export default injectIntl(ProposalDetailsPriceAndAvailability)