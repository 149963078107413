import esFns from 'date-fns/locale/es';
import enFns from 'date-fns/locale/en';
import frFns from 'date-fns/locale/fr';

const BACKEND_LOCALES = [
    {
        translationKey: 'spanish',
        backendKey: 'es',
        dateFnsLocale: esFns

    }, 
    {
        translationKey: 'english',
        backendKey: 'en',
        dateFnsLocale: enFns
    }, 
    {
        translationKey: 'french',
        backendKey: 'fr',
        dateFnsLocale: frFns
    }
]
export default BACKEND_LOCALES
