import { links } from '@reforestum/shared-utils';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import BACKEND_LOCALES from '../../constants/backendLocales';

import Tick from '../../components/UI/Icons/Tick';
import ArrowDownNoCircle from '../../components/UI/Icons/ArrowDownNoCircle'
import ThreeDots from '../../components/UI/Icons/ThreeDots';
import PopoverWithWrapper from '../../components/UI/Popover/PopoverWithWrapper';
import Dropdown from '../../components/UI/Dropdown/Dropdown';
import DropdownItem from '../../components/UI/Dropdown/DropdownItem';
import {REGISTRY_GOLD_STANDARD, REGISTRY_MITERD, REGISTRY_VERRA} from '../../constants/registries';
import {getFormattedCredits} from '../../utils/units';
import FormattedUnit from '../../components/Utils/FormattedUnit';

//@Pablo D TODO: Translate this texts
const TRANSACTION_STATUS = {
    NOT_VERIFIED : {
        key: 'NOT_VERIFIED',
        text: <FormattedMessage id='transactionsTable.statusNotVerified' defaultMessage='Not verified (auto-imported)'/>,
        color: '#A0723A'
    },
    VERIFIED : {
        key: 'VERIFIED',
        text: <FormattedMessage id='transactionsTable.statusVerified' defaultMessage='Verified'/>,
        color: '#2A7A4A'
    },
    IN_PROCESS_OF_VERIFICATION : {
        key: 'IN_PROCESS_OF_VERIFICATION',
        text: <FormattedMessage id='transactionsTable.statusInVerification' defaultMessage='In process of verification'/>,
        color: '#4C8089'
    },
    REJECTED : {
        key: 'REJECTED',
        text: <FormattedMessage id='transactionsTable.statusRejected' defaultMessage='Rejected'/>,
        color: '#A44A36'
    }
}


export const getTransactionsColumns = (intl, verificationButtonPressHandler, rejectButtonPressHandler) => {

const {locale} = intl
const dateLocaleFns = BACKEND_LOCALES.find((backendLocale) => backendLocale.backendKey === locale).dateFnsLocale

    const columns = {
        retirement_date: {
        id: 'retirement_date',
        Header: (
            <div style={{display: "flex", alignItems: "center", width: 'fit-content'}}>
                    <FormattedMessage id='transactionsTable.retirementDate' defaultMessage='Retirement Date'/>
                <ArrowDownNoCircle className="transactions-table-icon-next-header-column"/>
            </div>
        ),
        accessor: row => format(row.retirement_date, 'DD/MM/YYYY', {locale: dateLocaleFns})
        },
        beneficiary: {
        id: 'beneficiary',
        Header: <FormattedMessage id='transactionsTable.beneficiary' defaultMessage='Beneficiary'/>,
        accessor: row => (
            <div>
            <div className='transactions-table-benificiary-column'>{row.beneficiary}{row.is_beneficiary_digitalized && <Tick className='transactions-table-benificiary-column-tick'/>}</div>
            {row.is_beneficiary_digitalized && row.public_profile_slug && (
                <Link target={'_blank'} className='transactions-table-view-profile-link' to={links.getPublicProfileUrl(row.public_profile_slug)}>
                View profile
                </Link>
            )} 
            </div>
        )
        },
        getQuantity: (registry) => ({
        id: 'quantity',
        Header: <FormattedMessage id='transactionsTable.quantity' defaultMessage='Quantity'/>,
        accessor: row => {

            const credits = getFormattedCredits({credits: row.quantity, registry: registry})
            return (
            <span style={{whiteSpace: 'nowrap'}}>
                {intl.formatNumber(credits.value)} <FormattedUnit unit={credits.unit}/>
            </span>
            )
        }
        }),
        vintage_period: {
        id: 'vintage_period',
        Header: <FormattedMessage id='transactionsTable.vintagePeriod' defaultMessage='Vintage Period'/>,
        width: 200,
        accessor: row => {
            return (
            <span style={{whiteSpace: 'nowrap'}}>
                {`${format(row.vintage_start, 'DD/MM/YYYY', {locale: dateLocaleFns})} - ${format(row.vintage_end, 'DD/MM/YYYY', {locale: dateLocaleFns})}`}
            </span>
            )
        }
        },
        status: {
            id: 'status',
            Header: <FormattedMessage id='transactionsTable.status' defaultMessage='Status'/>,
            accessor: row => {
                const status = row.registry === REGISTRY_MITERD ? TRANSACTION_STATUS.VERIFIED : TRANSACTION_STATUS[row.status]
                return (
                <span style={{color: status && status.color}}>
                    {status && status.text}
                </span>
                )
            },
        },
        miterdStatus: {
            id: 'status',
            Header: <FormattedMessage id='transactionsTable.status' defaultMessage='Status'/>,
            accessor: (row) => {
                const status =  TRANSACTION_STATUS.VERIFIED
                return (
                    <span style={{color: status && status.color}}>
                        {status && status.text}
                    </span>
                )
            }
        },
            actions: {
                id: 'actions',
                width: 50,
                Header: (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <FormattedMessage id='transactionsTable.actions' defaultMessage='Actions'/>
                    </div>
                ),
                accessor: row => {
                    const status = TRANSACTION_STATUS[row.status]
                    return (
                        <div style={{display: "flex", justifyContent: "center", width: '100%'}}>
                            <PopoverWithWrapper mouseOutClosesPopover  renderButton={() => <ThreeDots/>} content={
                                <Dropdown>
                                    {(status.key === TRANSACTION_STATUS.NOT_VERIFIED.key) && 
                                        <DropdownItem onClick={verificationButtonPressHandler}>
                                            <FormattedMessage id='transactionsTable.actionAskForVerification' defaultMessage='Ask for verification'/>
                                        </DropdownItem>
                                    }
                                    {(status.key === TRANSACTION_STATUS.REJECTED.key) &&
                                        <DropdownItem onClick={verificationButtonPressHandler}>
                                            <FormattedMessage id='transactionsTable.actionAskForVerification' defaultMessage='Ask for verification'/>
                                        </DropdownItem>
                                    }
                                    {(status.key !== TRANSACTION_STATUS.REJECTED.key) &&
                                        <DropdownItem destructive onClick={rejectButtonPressHandler}>
                                            <FormattedMessage id='transactionsTable.actionReject' defaultMessage='Reject'/>
                                        </DropdownItem>
                                    }
                                </Dropdown>
                            }/>
                        </div>
                    )
                }
                },
    }   

    return {
            projectDetailsTransactionsTabColumnsVerra: [
                columns.retirement_date,
                columns.beneficiary,
                columns.getQuantity(REGISTRY_VERRA),
                columns.vintage_period
            ],
            projectDetailsTransactionsTabColumnsMiterd: [
                columns.retirement_date,
                columns.beneficiary,
                columns.getQuantity(REGISTRY_MITERD), 
            ],
            projectDetailsTransactionsTabColumnsGoldStandard: [
                columns.retirement_date,
                columns.beneficiary,
                columns.getQuantity(REGISTRY_GOLD_STANDARD),
            ],
            orgsDashboardVerraProjectTransactionsColumns: [
                columns.retirement_date,
                columns.beneficiary,
                columns.getQuantity(REGISTRY_VERRA),
                columns.vintage_period,
                columns.status,
                columns.actions,
            ],
            orgsDashboardGoldStandardProjectTransactionsColumns: [
                columns.retirement_date,
                columns.beneficiary,
                columns.getQuantity(REGISTRY_GOLD_STANDARD),
                columns.vintage_period,
                columns.status,
                columns.actions,
            ],
            publicDashboardVerraProjectTransactionsColumns: [
                columns.retirement_date,
                columns.beneficiary,
                columns.getQuantity(REGISTRY_VERRA),
                columns.vintage_period,
                columns.status,
            ],
            orgsDashboardMiterdProjectTransactionsColumns: [
                columns.retirement_date,
                columns.beneficiary,
                columns.getQuantity(REGISTRY_MITERD),
                columns.miterdStatus,
            ],
            publicDashboardMiterdProjectTransactionsColumns: [
                columns.retirement_date,
                columns.beneficiary,
                columns.getQuantity(REGISTRY_MITERD),
                columns.miterdStatus,
            ],
    }
}
