import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { getFormattedWeight, getFormattedArea, getFormattedVCUs } from '../../utils/units';
import FormattedUnit from '../Utils/FormattedUnit';
import { Link } from 'react-router-dom';

import { OFFSET_TYPE_SQM_SHARES } from "../../constants/offsetTypes";

import IconText from '../UI/Text/IconText';
import Small from '../UI/Text/Small';
import Text from '../UI/Text/Text';
import CarbonCloud from '../UI/Icons/CarbonCloud';
import SurfaceArrowsOut from '../UI/Icons/SurfaceArrowsOut';
import CarbonCredits from '../UI/Icons/CarbonCredits';

import './MyContributionsForestItem.css';

import { links } from '@reforestum/shared-utils';

const MyContributionsForestItem = ({ forest }) => {

    const {
      forest_id,
      sector_id,
      virtual_sector_id,
      sector_share_id,
      forest_image,
      sector_name,
      offset_type,
      sqm,
      total_kg_co2_capturable_by_user_shares_in_sector,
      amount
    } = forest;

    return (
      <Link // Link to your shares from your owned forest in the specfic public profile's
        to={sector_share_id 
              ? links.getProjectUrl(forest_id, (sector_id || virtual_sector_id), sector_share_id)
              : `/project/${forest_id}/sector/${(sector_id || virtual_sector_id)}/my-shares`
          }
      >
        <div className="forest-item forest-item--special-style">
          <div
            className="forest-item__image"
            style={{
              backgroundImage: `url(${forest_image})`
            }}
          />
          <div className="carbon-item__content">
            <Text tag="h3" className="forest-item__text forest-item--special-style__text">
              {sector_name}
            </Text>
            {offset_type === OFFSET_TYPE_SQM_SHARES
              ?
                <div className="carbon-item__info">
                  <IconText icon={SurfaceArrowsOut} className="forest-item__carbon">
                    <Small color="light">
                      <FormattedNumber
                        value={getFormattedArea(sqm).value}
                      /> <FormattedUnit unit={getFormattedArea(sqm).unit} />
                    </Small>
                  </IconText>
                  <IconText icon={CarbonCloud} className="forest-item__carbon">
                    <Small color="green">
                      <FormattedNumber
                        value={getFormattedWeight(total_kg_co2_capturable_by_user_shares_in_sector).value}
                      /> <FormattedUnit unit={getFormattedWeight(total_kg_co2_capturable_by_user_shares_in_sector).unit} />
                    </Small>
                  </IconText>
                </div>
              :
                <div className="carbon-item__info">
                  <IconText icon={CarbonCredits} className="forest-item__carbon">
                    <Small color="green">
                        <FormattedNumber
                          value={amount}
                        /> <FormattedUnit unit={getFormattedVCUs(amount).unit} />
                    </Small>
                  </IconText>
                </div>
            }
          </div>
        </div>
      </Link>
    )
}

MyContributionsForestItem.propTypes = {
    forest: PropTypes.object.isRequired,
}

export default MyContributionsForestItem;
