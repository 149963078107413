import React from 'react';
import PropTypes from 'prop-types';

import './AccountSettings.css';
import { Redirect, Route, Switch } from 'react-router';

import * as Sentry from "@sentry/react";
import PersonalInformation from './UserSettings/PersonalInformation/PersonalInformation';
import Preferences from './UserSettings/Preferences/Preferences';
import OrganizationInformation from './OrganizationSettings/OrganizationInformation/OrganizationInformation';
import OrganizationTeam from './OrganizationSettings/OrganizationInformation/OrganizationTeam';
import Billing from './UserSettings/Billing/Billing';
import Certificates from './UserSettings/Certificates/Certificates';
import HeaderLink from '../Header/HeaderLinkWithIcon';
import { FormattedMessage, intlShape } from 'react-intl';
import { settingsMessages } from '../../constants/messages';

const SentryRoute = Sentry.withSentryRouting(Route);

const accountSettingsUserPaths = (intl) => [
    {
        path: '/account/personal-information',
        linkText: intl.formatMessage(settingsMessages.personalInformation),
        component: PersonalInformation
    },
    {
        path: '/account/preferences',
        linkText: intl.formatMessage(settingsMessages.preferences),
        component: Preferences
    },
    {
        path: '/account/billing',
        linkText: intl.formatMessage(settingsMessages.billing),
        component: Billing
    },
    {
        path: '/account/certificates',
        linkText: intl.formatMessage(settingsMessages.certificates),
        component: Certificates
    },
]

const accountSettingsOrgsPaths = (intl) => [
    {
        path: '/account/organization-information',
        linkText: intl.formatMessage(settingsMessages.orgInformation),
        component: OrganizationInformation
    },
    {
        path: '/account/team',
        linkText: intl.formatMessage(settingsMessages.orgTeam),
        component: OrganizationTeam
    },
    {
        path: '/account/billing',
        linkText: intl.formatMessage(settingsMessages.billing),
        component: Billing
    },
    {
        path: '/account/certificates',
        linkText: intl.formatMessage(settingsMessages.certificates),
        component: Certificates
    },
]

const createNewOrgPaths = (intl) => [
    {
        path: '/create-new-organization',
        linkText: intl.formatMessage(settingsMessages.createNewOrg),
        component: OrganizationInformation
    }
]

const AccountSettings = (props) => {
    const {
        impersonatingOrg,
        createNewOrgScreen,
        intl
    } = props;
        
    let paths = []
    if(createNewOrgScreen) {
        paths = createNewOrgPaths(intl) 
    } else {
        paths = impersonatingOrg ? accountSettingsOrgsPaths(intl) : accountSettingsUserPaths(intl);
    }

    const settingsRoutes = () => 
        paths.map(({ path, component, linkText }) =>
            {
                const SettingComponent = component;
                return (
                    <SentryRoute
                        key={path}
                        path={path}
                    >
                        <SettingComponent {...props} settingName={linkText}/>
                    </SentryRoute>
                )                         
            }
        )
        
    return (
        <div className="account-settings-wrapper">
            <div className="account-settings">
                <div className="account-settings-list-wrapper">
                    <p className='account-settings-list-title'>
                        <FormattedMessage id="settings.pageTitle" defaultMessage="Settings"/>
                    </p>
                    <nav className="account-settings-list">
                        {
                            paths.map(({ path, linkText }) => 
                                <HeaderLink dontOpenMenu key={path} to={path} className="account-settings-list-item">{linkText}</HeaderLink>
                            )
                        }
                    </nav>
                </div>
                <div className="account-settings-content-wrapper">
                <Switch>
                    {settingsRoutes()}
                    <Redirect to={paths[0].path}/>
                </Switch>
                </div>
            </div>
        </div>
    )
};

AccountSettings.propTypes = {
    impersonatingOrg: PropTypes.bool,
    org: PropTypes.object,
    user: PropTypes.object,
    country: PropTypes.string,
    intl: intlShape.isRequired,
};

export default AccountSettings;
