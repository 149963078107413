import React from 'react';

const CarTravel = props => <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
	<path d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4Z" fill="#F2F2F0"/>
	<path d="M9.5 19.25H30.5" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
	<path d="M29 25.25V27.5C29 27.6989 28.921 27.8897 28.7803 28.0303C28.6397 28.171 28.4489 28.25 28.25 28.25H26C25.8011 28.25 25.6103 28.171 25.4697 28.0303C25.329 27.8897 25.25 27.6989 25.25 27.5V25.25" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
	<path d="M14.75 25.25V27.5C14.75 27.6989 14.671 27.8897 14.5303 28.0303C14.3897 28.171 14.1989 28.25 14 28.25H11.75C11.5511 28.25 11.3603 28.171 11.2197 28.0303C11.079 27.8897 11 27.6989 11 27.5V25.25" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
	<path d="M14 22.25H15.5" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
	<path d="M24.5 22.25H26" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
	<path d="M29 19.25L26.198 12.9454C26.139 12.8128 26.0429 12.7002 25.9213 12.6211C25.7996 12.5421 25.6577 12.5 25.5126 12.5H14.4874C14.3423 12.5 14.2004 12.5421 14.0787 12.6211C13.9571 12.7002 13.861 12.8128 13.802 12.9454L11 19.25V25.25H29V19.25Z" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
</svg>


export default CarTravel;
