import React from "react";
import PropTypes from 'prop-types';

import './SettingHeader.css';

const SettingHeader = ({settingName, children}) => {
    return (
        <div className="account-setting-content-header">
            {settingName}
            {children}
        </div>
    );
}

SettingHeader.propTypes = {
    settingName: PropTypes.string.isRequired
}

export default SettingHeader;
