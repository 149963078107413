import React from 'react'

const SearchAlt = props => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#1F3B21" {...props}>
		<path d="M10.5 17C14.0899 17 17 14.0899 17 10.5C17 6.91018 14.0899 4.00003 10.5 4.00003C6.91018 4.00003 4.00003 6.91018 4.00003 10.5C4.00003 14.0899 6.91018 17 10.5 17Z"  strokeWidth="1.2" strokeLinecap="square"/>
		<path d="M20 20L15.5 15.5" strokeWidth="1.2" strokeLinecap="square"/>
	</svg>
)

export default SearchAlt
