import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Box } from 'react-layout-components';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { requiredIf } from '../../../utils/requiredIf';
import { getFormattedArea, getFormattedCredits } from '../../../utils/units';
import getFeatureFlags from '../../../constants/featureFlags';

import Text from '../../UI/Text/Text';
import { Button } from '@reforestum/shared-components';
import AvatarPlaceholder from '@reforestum/shared-components/dist/assets/images/avatar-placeholder.png';
import FormattedUnit from '../../Utils/FormattedUnit';
import { OFFSET_TYPE_SQM_SHARES } from '../../../constants/offsetTypes';

import './PatronItem.css';
import { links } from '@reforestum/shared-utils';

const PatronItem = ({ patron, user, selfAmount, selfPosition, forestType, registry }) => {
  const ff = getFeatureFlags()
  const publicProfileSlug = patron ? patron.public_profile_slug : null;

  const vcuExceptionValue = patron && (patron.credits === 0 || patron.credits === undefined || patron.credits === null)

  return (
    <Box
      className={cx(
        'patron-item-original',
        { 'patron-item--self': user || selfPosition === patron.rank }
      )}
      alignItems="center">
      <Box alignItems="center" className="patron-item__patron">
        <Text className="patron-item__rank">
            {(patron && patron.rank) || selfPosition}
          </Text>
        <div
          className="patron-item__avatar"
          style={{backgroundImage: `url(${
              (patron && patron.avatar_url && CSS.escape(patron.avatar_url))
              || (user && user.avatar_url && CSS.escape(user.avatar_url))
              || AvatarPlaceholder
            })`
          }}
         />
        <Box className={cx('patron-item__name__surface-wrapper')}>
          <Text className="patron-item__name">
            {(patron && patron.name) || (user && `${user.name} ${user.surname === null ? '' : user.surname}`)}
          </Text>
          {forestType === OFFSET_TYPE_SQM_SHARES
          ?
            <Text color="light" className="patron-item__surface">
                <FormattedNumber
                    value={getFormattedArea((patron && patron.surface) || selfAmount).value}
                /> < FormattedUnit unit={getFormattedArea((patron && patron.surface) || selfAmount).unit} />
            </Text>
          :
            <Text color="light" className="patron-item__surface">
                <FormattedNumber
                    value={patron && vcuExceptionValue
                      ? 0 
                      : getFormattedCredits({credits: ((patron && (patron.credits)) || selfAmount), registry: registry}).value
                    }
                /> < FormattedUnit unit={getFormattedCredits({credits: ((patron && patron.credits) || selfAmount), registry: registry}).unit}/>
            </Text>
          }
        </Box>
      </Box>
      {ff['forestDetails.viewPatronProfile'] && publicProfileSlug &&
        <Link target="_blank" to={links.getPublicProfileUrl(publicProfileSlug)}>
            <Button>
              <FormattedMessage
                id={'ForestDetails.viewPatronProfile'}
                defaultMessage={'View profile'}
              />
          </Button>
        </Link>
      }
    </Box>
  )
};

PatronItem.propTypes = {
  patron: requiredIf( PropTypes.object, props => !props.user),
  selfPosition: requiredIf( PropTypes.number, props => props.user),
  selfAmount: requiredIf( PropTypes.number, props => props.user),
  forestType: PropTypes.string,
  registry: PropTypes.string,
  user: requiredIf( PropTypes.object, props => !props.patron),
};

export default PatronItem;
