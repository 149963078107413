import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Box } from 'react-layout-components';
import { FormattedNumber, injectIntl, intlShape } from 'react-intl';
import Text from '../UI/Text/Text';
import Small from '../UI/Text/Small';
import IconText from '../UI/Text/IconText';
import CarbonItemPopover from './CarbonItemPopover';
import { getFormattedWeight } from '../../utils/units';
import { carbonMessages } from '../../constants/messages';
import {v3CarbonIcons} from '../../constants/carbonIcons';
import './CarbonItem.css';
import FormattedUnit from '../Utils/FormattedUnit';
import CloudArrowUp from "../UI/Icons/CloudArrowUp";
import getFeatureFlags from "../../constants/featureFlags";

const CarbonItem = ({
  className,
  currentlySelected,
  deleteItem,
  editItem,
  frequency,
  id,
  image,
  name,
  selectItem,
  updateFrequency,
  value,
  intl,
  source,
  dashboard,
  resetForm
}) => {

  const renderFrequencyStringForCarbonItemFrequency = () => {
    let result = null
    if (frequency !== 0) {
      switch(frequency) {
        case 1:
          result = <span>&nbsp;{`/${intl.formatMessage(carbonMessages.freqFormDay)}`}</span>
          break;
        case 2:
          result = <span>&nbsp;{`/${intl.formatMessage(carbonMessages.freqFormWeek)}`}</span>
          break;
        case 3:
          result = <span>&nbsp;{`/${intl.formatMessage(carbonMessages.freqFormMonth)}`}</span>
          break;
        default:
          result = null
      }
    }
    return result
  }

  return <Box
    justifyContent="space-between"
    alignItems="center"
    className={cx(className, 'carbon-item')}
    onMouseLeave={() => { selectItem(null); }}
    onClick={() => { selectItem(currentlySelected === id ? null : id); }}
  >
    <Box className={'carbon-item__image-and-content'} center>
      <div
        className="carbon-item__image"
      >
        {v3CarbonIcons[image]}
      </div>

      <Box className="carbon-item__content">
        <Box className="carbon-item__title-and-date">
          <Text tag="h3" className="carbon-item__title">
            {name}
          </Text>
          {
            getFeatureFlags()['carbonItem.dateAdded'] &&
            <Text className="carbon-item__date" data-testid={'carbonItemDateAdded'}>
              display date
            </Text>
          }
        </Box>
        <IconText icon={CloudArrowUp} className="carbon-item__carbon">
          <Small className={'carbon-item__carbon-amount'}>
            <FormattedNumber
              value={getFormattedWeight(value).value}
            /> <FormattedUnit unit={getFormattedWeight(value).unit} />
            {renderFrequencyStringForCarbonItemFrequency()}
          </Small>
        </IconText>
      </Box>
    </Box>

    <Box
      className="carbon-item__options"
    >
      {
        currentlySelected === id &&
        <CarbonItemPopover
          className="carbon-item__popover"
          id={id}
          deleteItem={deleteItem}
          editItem={editItem}
          itemName={name}
          frequency={frequency}
          updateFrequency={updateFrequency}
          source={source}
          dashboard={dashboard}
          resetForm={resetForm}
        />
      }
    </Box>
  </Box>
}

CarbonItem.propTypes = {
  className: PropTypes.string,
  currentlySelected: PropTypes.number,
  deleteItem: PropTypes.func.isRequired,
  editItem: PropTypes.func.isRequired,
  frequency: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  selectItem: PropTypes.func.isRequired,
  updateFrequency: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  intl: intlShape,
  source: PropTypes.object,
  dashboard: PropTypes.bool,
  resetForm: PropTypes.func
};

export default injectIntl(CarbonItem);
