import React from 'react';
import PropTypes from 'prop-types';

import './Widget.css';
import Tooltip from '../../UI/Tooltip/Tooltip';
import { Component } from 'react';
import { getProjectUrl } from '@reforestum/shared-utils/src/links';
import ArrowRight from '../../UI/Icons/ArrowRight';
import ArrowRightActive from '../../UI/Icons/ArrowRightActive';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const MAX_SECTORS_TO_SHOW = 6

class CommunitiesWidget extends Component {

    constructor(props) {
        super(props)

        this.state = {
            buttonHovered: false,
        }
    }
    render() {
        const {sectors, forestId, renderedFromProjectDetails, match} = this.props;
        const {buttonHovered} = this.state;
        return (
            <div className='widget-container'>
              <div className='widget widget-communities'>
                <p className='widget-title'>
                  <FormattedMessage id="CommunitiesWidget.title" defaultMessage="Communities"/>
                </p>
                <p className='widget-text'>{sectors.length} {sectors.length === 1 ? 
                  <FormattedMessage id="CommunitiesWidget.organization" defaultMessage="organization"/> : 
                  <FormattedMessage id="CommunitiesWidget.organizations" defaultMessage="organizations"/>}
                </p>
                <div className='widget-communities-orgs-container'>
                  {sectors && ((sectors.length - MAX_SECTORS_TO_SHOW) > 0) &&
                    <Link className='widget-communities-org' to={`${getProjectUrl(forestId)}/communities`}>
                      <div className="widget-communities-org-rest-orgs">
                        <p>+{sectors && sectors.length - MAX_SECTORS_TO_SHOW}</p>
                      </div>
                    </Link>
                  }
                   {sectors && sectors
                   .slice(0, 6)
                   //this reverse is used because from a style point of view, companies are rendered from left to right. Using float right.
                   .reverse()
                   .map((sector) =>
                    <Link target="_blank" className='widget-communities-org' to={getProjectUrl(forestId, sector.sector_id || sector.forest_virtual_sector_id)}>
                        <Tooltip
                            position="top-right"
                            content={sector.company_name || sector.forest_virtual_sector_company_name}>{
                            (sector.logo_url || sector.forest_virtual_sector_logo_url)  ? 
                            <img
                                src={sector.logo_url || sector.forest_virtual_sector_logo_url}
                                alt={`company logo for ${sector.company_name || sector.forest_virtual_sector_company_name}`}
                                className="widget-communities-org-img"
                            /> 
                            :
                            <div className="widget-communities-org-img"/>
                            }
                        </Tooltip>
                    </Link>
                   )}
                </div>
                <Link
                    to={renderedFromProjectDetails ?
                      `${match.path.slice(0, match.path.lastIndexOf('/:'))}/communities` :
                      `${getProjectUrl(forestId)}/communities`
                    }
                    className='widget-button' 
                    onMouseEnter={() => this.setState({buttonHovered: true})}
                    onMouseLeave={() => this.setState({buttonHovered: false})}
                    >
                        <FormattedMessage id="CommunitiesWidget.button" defaultMessage="Meet our communities"/>{buttonHovered ? <ArrowRightActive  classname='widget-button-arrow'/> : <ArrowRight classname='widget-button-arrow'/>}
                </Link>
              </div>
            </div>
        );
    }
}

CommunitiesWidget.propTypes = {
  sectors: PropTypes.array,
  renderedFromProjectDetails: PropTypes.bool,
};

export default withRouter(CommunitiesWidget);
