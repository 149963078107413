import React, { Component } from 'react';
import PropTypes from 'prop-types';
import mixpanel from 'mixpanel-browser';
import { reduxForm, Field, change } from 'redux-form';
import {
  FormattedMessage,
  injectIntl,
  intlShape
} from 'react-intl';

import { authMessages } from '../../constants/messages';

import history from '../../setup/history';
import { required, email } from '../../utils/formHelpers';
import { withIntl } from '../../utils/intl';

import FeedbackMessage from '../UI/Forms/FeedbackMessage';
import Input from '../UI/Forms/Input';
import Row from '../UI/Forms/Row';
import RowAligner from '../UI/Forms/RowAligner';
import { Button } from '@reforestum/shared-components';
import Small from '../UI/Text/Small';
import {EyeLashes} from '../UI/Icons/EyeLashes';
import Eye from '../UI/Icons/Eye';

import SocialButtons from './SocialButtons';

import './Login.css'
import { links } from '@reforestum/shared-utils';
class LoginForm extends Component{
  constructor (props) {
    super(props);
    if(this.props.lastAttemptedEmail){
      this.props.dispatch(change('login', 'username', this.props.lastAttemptedEmail))
    }

    this.state = {
      togglePasswordVisibility: false,
    }

    this.handleTogglePasswordVisibility = this.handleTogglePasswordVisibility.bind(this);
  }

  componentDidMount(){
    const { activationId, orgId, offsetTransactionType, companyProfileSlug, activationStatus } = this.props;

    if(companyProfileSlug && activationId){
      mixpanel.track("Page view", {
        "Action": "Enterprise activation login",
        "Domain": "App",
        "referrerType": "enterpriseApi", 
        "enterpriseOffsetActivationId": activationId,
        "enterpriseOrgId": orgId,
        "enterpriseOffsetActivationType": offsetTransactionType,
        "enterpriseOrgName": companyProfileSlug.toLowerCase(),
        "enterpriseOffsetStatus": activationStatus
      });
    };
  }

  handleTogglePasswordVisibility(){
    this.setState({ togglePasswordVisibility: !this.state.togglePasswordVisibility })
  }

  render(){ 
    const {
      handleSubmit,
      handleRegisterRedirection,
      setShowActivationForm,
      enterpriseActivation,
      handleLogin,
      errorMessages,
      invalid,
      isLoading,
      activationId,
      intl
    } = this.props;
    
    const { togglePasswordVisibility } = this.state;

    return(
      <form onSubmit={handleSubmit(handleLogin)}>
        {
          errorMessages &&
          <FeedbackMessage type="error">{errorMessages}</FeedbackMessage>
        }
        <SocialButtons activationId={activationId} handleLogin={handleLogin} />
        <Row>
          <Field
            name="username"
            component={Input}
            placeholder={intl.formatMessage(authMessages.emailPlaceholder)}
            label={intl.formatMessage(authMessages.emailLabel)}
            type="email"
            disabled={isLoading}
            validate={withIntl(intl, [email, required])}
          />
        </Row>

        <Row>
          <div className='special-wrapper-with-icon'>
            <Field
              className='special-input-with-icon special-input-with-icon2'
              name="password"
              disabled={isLoading}
              component={Input}
              placeholder={intl.formatMessage(authMessages.passwordPlaceholder)}
              label={intl.formatMessage(authMessages.passwordLabel)}
              type={togglePasswordVisibility ? "text" : "password"}
              validate={withIntl(intl, required)}
              action={{
                label: intl.formatMessage(authMessages.forgot),
                action: () => {
                  history.push(links.getRecoverPasswordUrl());
                }
              }}
            />
            {!togglePasswordVisibility
              ? <EyeLashes className='special-login__icon' onClick={this.handleTogglePasswordVisibility}/>
              : <Eye className='special-login__icon' onClick={this.handleTogglePasswordVisibility}/>
            }
          </div>
        </Row>

        <Row>
          <RowAligner>
            <Button
              disabled={invalid}
              type="submit"
              className='login__button'
              loading={isLoading}
            >
              <FormattedMessage
                id={'Login.loginButton'}
                defaultMessage={'Login'}
              />
            </Button>
          </RowAligner>
        </Row>
        <Row>
          <div className='new-cta__wrapper'>
            <Small weight={'regular'}>
              <FormattedMessage 
                id={'Common.noAccount'}
                defaultMessage={"Don't have an account?"}
              />
              <span
                onClick={() => enterpriseActivation ? setShowActivationForm() : handleRegisterRedirection()}
                style={{ color: 'var(--ref-green-300)', marginLeft: '0.3rem', cursor: 'pointer' }}>
                <FormattedMessage
                  id={'Header.register'}
                  defaultMessage={'Register'}
                />
              </span>
            </Small>
          </div>
        </Row>
      </form>
    );
  }
}

LoginForm.propTypes = {
  className: PropTypes.string,
  handleLogin: PropTypes.func.isRequired,
  errorMessages: PropTypes.string,
  lastAttemptedEmail: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  activationId: PropTypes.string.isRequired,
  /* redux form */
  handleSubmit: PropTypes.func.isRequired,
  /* intl */
  intl: intlShape.isRequired,
  logout: PropTypes.func.isRequired,
};

export default injectIntl(
  reduxForm({
    form: 'login'
  })(LoginForm));
