import React from 'react';
import { Field } from 'redux-form';
import Checkbox from '../../UI/Forms/Checkbox';
import { FormattedMessage, injectIntl } from 'react-intl';
import { localizedLinkMessages } from '../../../constants/messages';
import { withIntl } from '../../../utils/intl';
import { mustAgree } from '../../../utils/formHelpers';

const acceptTermsOfServiceCheckbox = ({disabled, intl}) => {
    return (
        <Field
        name="tos"
        disabled={disabled}
        component={Checkbox}
        label={
          <span>
            <FormattedMessage
              id={'tosMessages.message'}
              defaultMessage={"I agree with Reforestum's"}
            />
            &nbsp;
            <a
              href={intl.formatMessage(localizedLinkMessages.tos)}
              rel="noopener noreferrer"
              target="_blank"
              style={{ color: 'var(--dark-green)'}}
            >
              <FormattedMessage
                id={'tosMessages.tos'}
                defaultMessage={'Terms of service'}
              />
            </a>
          </span>
        }
        validate={withIntl(intl, mustAgree)}
      />
    );
    }

export default injectIntl(acceptTermsOfServiceCheckbox);