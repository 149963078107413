import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from 'react-intl';

import IconText from '../../UI/Text/IconText';
import Text from '../../UI/Text/Text';
import Uppercase from '../../UI/Text/Uppercase';
import PlantGreen from '../../UI/Icons/PlantGreen';
import Plant from '../../UI/Icons/Plant';
import SurfaceArrowsOut from '../../UI/Icons/SurfaceArrowsOut';

import { getFormattedArea } from '../../../utils/units';
import FormattedUnit from '../../Utils/FormattedUnit';


import './MyTreesOwnedBlock.css'
import Tooltip from '../../UI/Tooltip/Tooltip';
import Information from '../../UI/Icons/Information';

const MyTreesOwnedBlock = (
  {
      forest,
      user_total_surface,
      intl
  }
) => {

    const ownedTrees = user_total_surface * forest.trees_sqm;
    const roundedOwnedTrees = ownedTrees % 1 === 0 ? ownedTrees : `${Math.round(ownedTrees)}`

    const shareArea = getFormattedArea(user_total_surface);

    return (
        <div className={'myTrees-block__wrapper'}>
            <div className={'myTrees-block-svg__wrapper'}>
                <IconText icon={PlantGreen} className={'myTrees-block-trees'}>
                    <span>{roundedOwnedTrees}</span>
                </IconText>
            </div>
            <div className={'myTrees-block-info__wrapper'}>
            {/* ------ PLANTED TREES -------- */}
                <div className={'myTrees-block-info-collection__wrapper'}>
                    <div className={'myTrees-block-info-icon__wrapper'}>
                        <Plant className={'myTrees-block-info__icon'}/>
                    </div>
                    <div className={'myTrees-block-extraInfo__wrapper'}>
                        <div className={'myTrees-block-extraInfo-item-value_wrapper'}>
                            <Text>{roundedOwnedTrees}</Text>
                            <Tooltip
                                style={{marginLeft: '4px'}}
                                size='medium'
                                content={
                                    <FormattedMessage 
                                        id="ForestDetails.ownedTreesTooltip"
                                        defaultMessages={'Estimated number of trees in the area corresponding to your Shares in this Forest'}
                                    /> 
                                }
                                >
                                <Information/>
                            </Tooltip>
                        </div>
                        <Uppercase color="light">
                            <FormattedMessage 
                                id="ForestDetails.ownedTrees"
                                defaultMessages={'Owned trees'}
                            />
                        </Uppercase>
                    </div>
                </div>
            {/* ------- FOREST SURFACE ------- */}
                <div className={'myTrees-block-info-collection__wrapper'}>
                    <div className={'myTrees-block-info-icon__wrapper'}>
                        <SurfaceArrowsOut className={'myTrees-block-info__icon'}/>
                    </div>
                    <div className={'myTrees-block-extraInfo__wrapper'}>
                        <Text>
                            <FormattedNumber
                                value={shareArea.value}
                            /> <FormattedUnit unit={shareArea.unit}/>
                        </Text>
                        <Uppercase color="light">
                            <FormattedMessage 
                                id="ForestDetails.shareArea"
                                defaultMessages={'Forest share area'}
                            />
                        </Uppercase>
                    </div>
                </div>
            </div>
        </div>
    )
};

MyTreesOwnedBlock.propTypes = {
    forest: PropTypes.object,
    user_total_surface: PropTypes.number,
    intl: intlShape
};
  

export default injectIntl(MyTreesOwnedBlock);
