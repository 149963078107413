import React from 'react';
import PropTypes from 'prop-types';
import Box from 'react-layout-components';

import { injectIntl, intlShape } from 'react-intl';

import { authMessages } from '../../constants/messages';

import AuthForm from './AuthForm';
import AuthHeader from './AuthHeader';
import LoginForm from './LoginForm';

import Logo from '../UI/Logo/Logo';

import './EnterpriseUserActivation.css'

const EnterpriseLoginActivation = ({
    enterpriseActivation,
    setShowActivationForm,
    handleRegisterRedirection,
    handleLogin,
    errorMessages,
    isLoading,
    logout,
    lastAttemptedEmail,
    companyImage,
    defaultLandingImage,
    activationId,
    orgId,
    companyProfileSlug,
    activationStatus,
    offsetTransactionType,
    intl
  }) => {

  const addDefaultSrc = (ev) => ev.target.src = defaultLandingImage

  return (
    <Box className="activation-main__wrapper">
      <div className="left-side__wrapper">
        <img
          onError={addDefaultSrc}
          src={companyImage}
          className="enterprise-cover__image"
          alt="enterprise cover"
        />
      </div>
      <div className="enterprise-sidebar">
        <div className="reforestum-logo__wrapper">
            <Logo className="reforestum__logo" />
        </div>
        <AuthHeader
          title={intl.formatMessage(authMessages.loginTitle)}
          text={intl.formatMessage(authMessages.loginText)}
        />
        <AuthForm>
          <LoginForm
            enterpriseActivation={enterpriseActivation}
            setShowActivationForm={setShowActivationForm}
            handleLogin={handleLogin}
            handleRegisterRedirection={handleRegisterRedirection}
            errorMessages={errorMessages}
            isLoading={isLoading}
            logout={logout}
            lastAttemptedEmail={lastAttemptedEmail}
            activationId={activationId}
            orgId={orgId}
            companyProfileSlug={companyProfileSlug}
            activationStatus={activationStatus}
            offsetTransactionType={offsetTransactionType}
          />
        </AuthForm>
      </div>
    </Box>
  );
};

EnterpriseLoginActivation.propTypes = {
  companyImage: PropTypes.string,
  activationId: PropTypes.string,
  changeLoginView: PropTypes.func.isRequired,
  signup: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  signupErrorMessages: PropTypes.string,
  offsetTransactionType: PropTypes.string.isRequired,
  intl: intlShape
};

export default injectIntl(EnterpriseLoginActivation);
