import React from 'react'
import './ProposalDetails.scss'
import {FormattedMessage, injectIntl} from 'react-intl'
import {rfpProposalDetails} from '../../constants/messages'
import MapPinSimple from '../UI/Icons/MapPinSimple'
import Plant from '../UI/Icons/Plant'
import TinyInfoBlock from '../UI/TinyInfoBlock/TinyInfoBlock'
import ReadMore from '../UI/ReadMore'
import Monitor from '../UI/Icons/Monitor'
import {Button} from '@reforestum/shared-components'
import Mail from '../UI/Icons/Mail'
import renderTooltipForTrimmedText from '../../utils/renderTooltipForTrimmedText'
import ProposalDetailsExtras from './ProposalDetailsExtras'
import './ProposalDetailsAboutSupplier.scss'

const DEFAULT_SUPPLIER_LOGO = 'https://statics.reforestum.com/rfp/proposal_fallback.png'
const PROJECT_DETAILS_MAX_TAG_LENGTH = 10
const SUPPLIER_NAME_MAX_LENGTH = 35

const ProposalDetailsOverview = (
  {
    proposalDetails,
    clickedContactSupplierModal,
    extrasToRender=null,
    intl
  }={}
) => {
  const renderSupplierDetailsTinyInfoBlocks = () => {
    const blocks = [
      {
        shouldRender: proposalDetails.supplier && proposalDetails.supplier.country,
        props: {
          className: 'rfp-details__tiny-info-block',
          title: intl.formatMessage(rfpProposalDetails.supplierCountry),
          value: <span>
            <MapPinSimple />
            {((proposalDetails.supplier && proposalDetails.supplier.country) || '').length > PROJECT_DETAILS_MAX_TAG_LENGTH ?
              renderTooltipForTrimmedText({text: proposalDetails.supplier.country, maxLength: PROJECT_DETAILS_MAX_TAG_LENGTH, trimmedUpperCase: true, noWrap: true})
              : proposalDetails.supplier.country
            }
         </span>,
          unit: 'unit'
        }
      },
      {
        shouldRender: proposalDetails.supplier && proposalDetails.supplier.type,
        props: {
          className: 'rfp-details__tiny-info-block',
          title: intl.formatMessage(rfpProposalDetails.supplierType),
          value: <span>
            <Plant />
            {(proposalDetails.supplier.type || '').length > PROJECT_DETAILS_MAX_TAG_LENGTH ?
              renderTooltipForTrimmedText({text: proposalDetails.supplier.type, maxLength: PROJECT_DETAILS_MAX_TAG_LENGTH, trimmedUpperCase: true, noWrap: true})
              : proposalDetails.supplier.type
            }
          </span>
        }
      },
    ]

    const blocksToRender = blocks.filter(b => b.shouldRender)

    return blocksToRender
      .filter(b => b.shouldRender)
      .map(
        (b, i) => <TinyInfoBlock
          index={`tiny-info-block-${i}`}
          divider={blocksToRender.length > i + 1}
          {...b.props}
        />
      )
  }

  const renderMain = () => {
    const websiteLink = proposalDetails.supplier && proposalDetails.supplier.website_link
    const description = proposalDetails.supplier && proposalDetails.supplier.description
    const email = (proposalDetails.supplier && proposalDetails.supplier.email)
    const logo = proposalDetails.supplier && proposalDetails.supplier.logo
    const name = proposalDetails.supplier && proposalDetails.supplier.name

    return <div className={'rfp-detail__about-supplier'}>
      <div className={'rfp-detail__about-supplier__details'}>
        <div className={'rfp-detail__about-supplier__details__image-and-name'}>
          <img alt={'Supplier logo'} src={logo || DEFAULT_SUPPLIER_LOGO} />
          <h2 className={'design-system-text-title-s-regular'}>
            {
              name && name.length > SUPPLIER_NAME_MAX_LENGTH ?
                renderTooltipForTrimmedText({text: name, maxLength: SUPPLIER_NAME_MAX_LENGTH})
                : name
            }
          </h2>
        </div>
        <div className={'rfp-detail__about-supplier__details__tiny-info-blocks'}>
          {renderSupplierDetailsTinyInfoBlocks()}
        </div>
      </div>
      {
        description &&
        <div className={'rfp-detail__about-supplier__description'}>
          <ReadMore text={description} isHtml cutLength={150} minLength={100} />
        </div>
      }
      {
        extrasToRender &&
        <ProposalDetailsExtras
          extrasToRender={extrasToRender}
        />
      }
      <div className={'rfp-detail__about-supplier__link-and-button'}>
        {
          websiteLink && <div className={'rfp-detail__about-supplier__link-and-button__icon-and-link'}>
            <Monitor />
            <a href={
              websiteLink.indexOf('http') === -1 ?
                `https://${websiteLink}`
                : 
                websiteLink
              } 
              target='__blank'>
                {websiteLink}
            </a>
          </div>
        }
        {
          email &&
          <Button target="_blank" size="S" outlineBlack onClick={() => clickedContactSupplierModal({supplierEmail: email})}>
            <Mail />
            <FormattedMessage
              id={`ProposalDetails.ContactSupplier`}
              defaultMessage={'Contact Supplier'}
            />
          </Button>
        }
      </div>
    </div>
  }

  return proposalDetails ? renderMain() : null
}


export default injectIntl(ProposalDetailsOverview)