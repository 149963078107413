import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'react-layout-components';

import CarbonItem from './CarbonItem';
import EmptyCarbon from './EmptyCarbon';
import CarbonItemLoading from './CarbonItemLoading';

const CarbonList = ({
  currentlySelected,
  deleteItem,
  editItem,
  isAdding,
  isFetching,
  selectItem,
  sources,
  updateFrequency,
  type,
  dashboard,
  resetForm
}) => {
  if (Object.keys(sources).length <= 0 && !isAdding) {
    return <EmptyCarbon type={type} />;
  }

  return (
    <Box column fit>
      {isAdding &&
        <CarbonItemLoading />
      }
      {sources.map(source => (
        <CarbonItem
          key={source.id}
          id={source.id}
          name={source.name}
          value={source.amount}
          frequency={source.periodicity}
          image={source.type}
          currentlySelected={currentlySelected}
          selectItem={selectItem}
          editItem={editItem}
          deleteItem={deleteItem}
          updateFrequency={updateFrequency}
          source={source}
          dashboard={dashboard}
          resetForm={resetForm}
        />
      ))
      }
      {isFetching &&
        <CarbonItemLoading />
      }
    </Box>
  );
};

CarbonList.propTypes = {
  currentlySelected: PropTypes.number,
  deleteItem: PropTypes.func.isRequired,
  editItem: PropTypes.func.isRequired,
  isAdding: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  selectItem: PropTypes.func.isRequired,
  sources: PropTypes.array.isRequired,
  updateFrequency: PropTypes.func.isRequired,
  type: PropTypes.string,
  dashboard: PropTypes.bool,
  resetForm: PropTypes.func
};

export default CarbonList;
