import React from "react";
import './NotLoggedUserDashboardBody.css'
import { expandableLinks } from "../../Header/ExpandableLinks/ExpandableLinks";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";


const NotLoggedUserDashboardBody = () => {

    const renderCardItem = ({icon, title, description, link}) => (
        <Link className='not-logged-user-dashboard-card-item' to={link}>
            <div className='not-logged-user-dashboard-card-item-wrapper'>
                {icon}
                <div>
                    <p className='not-logged-user-dashboard-card-item-title'>
                        {title}
                    </p>
                    <p className='not-logged-user-dashboard-card-item-description'>
                        {description}
                    </p>
                </div>
            </div>
        </Link>
    )

    return (
        <div className="not-logged-user-dashboard-container">
            <h1 className="not-logged-user-dashboard-title">
                <FormattedMessage id="NotLoggedUserDashboard.title" defaultMessage="Welcome to the Reforestum Platform!"/>
            </h1>
            <p className="not-logged-user-dashboard-subtitle">
                <FormattedMessage id="NotLoggedUserDashboard.subtitle" defaultMessage="Whether you are interested in exploring the Voluntary Carbon Markets, an organization willing to build and manange your carbon portfolio or an individual willing to neutralize it's carbon emissions, Reforestum is your platform!"/>
                </p>
            <div className="not-logged-user-dashboard-cards-container">
                <div className="not-logged-user-dashboard-card">
                    <h2 className="not-logged-user-dashboard-card-title">
                        <FormattedMessage id="NotLoggedUserDashboard.cardVcm" defaultMessage="Explore the voluntary carbon markets"/>
                    </h2>
                    <div className="not-logged-user-dashboard-card-links">
                        {expandableLinks.vcmSection.dropdownContent.map(renderCardItem)}
                    </div>
                </div>
                <div className="not-logged-user-dashboard-card">
                    <h2 className="not-logged-user-dashboard-card-title">
                        <FormattedMessage id="NotLoggedUserDashboard.cardNeutralize" defaultMessage="Neutralize your carbon footprint"/>
                    </h2>
                    {expandableLinks.neutralizeFootprint.dropdownContent.map(renderCardItem)}
                </div>
            </div>
        </div>
    )
}

NotLoggedUserDashboardBody.propTypes  = {}

export default NotLoggedUserDashboardBody