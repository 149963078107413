import React from 'react';

const HeartIcon = (props) => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1208_21096)">
      <path d="M6.5 2.21755C5.5055 -0.481948 0.5 -0.0809472 0.5 4.00155C0.5 6.03555 2.03 8.74205 6.5 11.5001C10.97 8.74205 12.5 6.03555 12.5 4.00155C12.5 -0.0574472 7.5 -0.497948 6.5 2.21755Z" fill="#F08862"/>
    </g>
    <defs>
      <clipPath id="clip0_1208_21096">
        <rect width="12" height="12" fill="white" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>
);

export default HeartIcon;
