import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'react-layout-components';
import cx from "classnames"
import Text from "../UI/Text/Text"
import Small from "../UI/Text/Small"
import {FormattedNumber} from "react-intl"
import {getFormattedArea, getFormattedCredits} from "../../utils/units"
import FormattedUnit from "../Utils/FormattedUnit"
import AvatarPlaceholder from '@reforestum/shared-components/dist/assets/images/avatar-placeholder.png';

const CommunityPatronsPodium = ({profileLeaderboard}) => {
	return (
		<Box className="patrons-podium community-container-patrons-podium">
			{profileLeaderboard && profileLeaderboard.length > 0
				? profileLeaderboard.map((patron, index) => (
					<Box
						className="patrons-podium__patron"
						column
						key={index}
						alignItems="center"
						order={index + 1 === 2 ? -1 : index + 1}>
						<div
							className={cx(
								'patrons-podium__avatar',
								`patrons-podium__avatar--${index + 1}`)}
							style={{
								backgroundImage: `url(${(patron.avatar_url && CSS.escape(patron.avatar_url))
								|| AvatarPlaceholder})`
							}}>
							<Box center className="patrons-podium__rank">
								{index + 1}
							</Box>
						</div>
						<Text>
							{patron.display_name}
						</Text>

						{patron.sqm_amount
							?
							<Small color="green">
								<FormattedNumber value={getFormattedArea(patron.sqm_amount).value} />
								&nbsp;
								<FormattedUnit unit={getFormattedArea(patron.sqm_amount).unit} />
							</Small>
							:
							<Small color="green">
								<FormattedNumber value={getFormattedCredits({credits: patron.vcu_amount}).value} />
								&nbsp;
								<FormattedUnit unit={getFormattedCredits({credits: patron.vcu_amount}).unit} />
							</Small>}
					</Box>
				))
				: null}
		</Box>
	);
};

CommunityPatronsPodium.propTypes = {
	profileLeaderboard: PropTypes.array,
};

export default CommunityPatronsPodium;
