import React from 'react';
import PropTypes from 'prop-types';

import Previous from '../../UI/Icons/Previous';
import Next from '../../UI/Icons/Next';
import Close from '../../UI/Icons/Close';
import Heading from '../../UI/Text/HeadingSmall';
import Text from '../../UI/Text/Text';

import getFeatureFlags from '../../../constants/featureFlags';

import './Gallery.css';

const Gallery = ({
  images,
  closeGallery,
  index,
  next,
  previous,
}) => {

  const showPictureInfo = getFeatureFlags().titleAndDescriptionInGalleryImages;
  return (
    <div className="reforestum-gallery">

      <div className="reforestum-gallery__gallery">
        <div className="reforestum-gallery__inner" style={{
          transform: `translateX(${index % images.length * -100}%)`
        }}>
          {images.map((item, index) => (
            <div
            className="reforestum-gallery__slide"
            key={index}>
              <div className='reforestum-gallery__content-wrapper'>
              <img
                className="reforestum-gallery__image"
                src={item.URI}
                alt={item.title}
              />
              {showPictureInfo && item.title && <Heading
                className="reforestum-gallery__text"
                color="white">
                {item.title}
              </Heading>}
              {showPictureInfo && item.description && <Text
                className="reforestum-gallery__text"
                color="white">
                {item.description}
              </Text>}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="reforestum-gallery__controls">
        <div className="reforestum-gallery__previous" onClick={previous}>
          <Previous />
        </div>
        <div className="reforestum-gallery__next" onClick={next}>
          <Next />
        </div>
      </div>

      <div onClick={closeGallery} className="reforestum-gallery__overlay" />

      <div onClick={closeGallery} className="reforestum-gallery__close">
        <Close />
      </div>

    </div>
)};

Gallery.propTypes = {
  index: PropTypes.number.isRequired,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  closeGallery: PropTypes.func.isRequired,
};

export default Gallery;
