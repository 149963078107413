import React, {useEffect, useState} from 'react'
import './ProposalDetails.scss'
import {FormattedMessage} from 'react-intl'
import ProposalDetailsCard from './ProposalDetailsCard'
import ProposalDetailsOverview from './ProposalDetailsOverview'
import ProposalDetailsPriceAndAvailability from './ProposalDetailsPricesAndAvailability/ProposalDetailsPriceAndAvailability'
import ProposalDetailsAboutSupplier from './ProposalDetailsAboutSupplier'
import ProposalDetailsProposalDetails from './ProposalDetailsProposalDetails'
import ProposalDetailsProjectDetails from './ProposalDetailsProjectDetails'
import {queryRfpProposalDetails} from '../Utils/queryRfpProposalDetails'
import Loader from '../UI/Loader/Loader'
import getFeatureFlags from '../../constants/featureFlags'

const RFP_PROPOSAL_DETAILS_TIMEOUT = 10000

const SECTION_OVERVIEW = 'overview'
const SECTION_ABOUT_SUPPLIER = 'about supplier'
const SECTION_PRICE_AND_AVAILABILITY = 'prices and availability'
const SECTION_PROPOSAL_DETAILS = 'proposal details'
const SECTION_PROJECT_DETAILS = 'project details'

const ProposalDetails = (
  {
    proposalId,
    scrollRef,
    clickedContactSupplierModal=(o)=>o
  }
) => {

  const ff = getFeatureFlags()
  const [proposalDetails, setProposalDetails] = useState(null)
  
  useEffect(() => {
    const getProposalDetails = async () => {
      if(typeof(proposalId) === 'string') {
        const rfpResults = await queryRfpProposalDetails(proposalId)

        if(rfpResults.data) {
          setProposalDetails(rfpResults.data)
        }
      }
    }

    setProposalDetails(null)
    getProposalDetails()

    const intervalId = setInterval(getProposalDetails, RFP_PROPOSAL_DETAILS_TIMEOUT)

    return () => {
      clearInterval(intervalId)
    }
  }, [proposalId])

  const getExtrasToRender = ff.proposalDetailsExtras
    ? ({section}) => (proposalDetails.extra || []).filter(extrasItem => extrasItem.section_name === section)
    : []

  const renderProposalValidUntil = () => <>
    <FormattedMessage
      id={`ProposalDetails.OverviewValidUntilLabel`}
      defaultMessage={'Valid Until'}
    />
    &nbsp;
    {proposalDetails.proposal.valid_until}
  </>

const shouldRenderPriceAndAvailability = 
  proposalDetails && 
  proposalDetails.proposal && 
  proposalDetails.proposal.breakdown && (
    (proposalDetails.proposal.breakdown.ex_post && proposalDetails.proposal.breakdown.ex_post.length > 0) ||
    (proposalDetails.proposal.breakdown.ex_ante && proposalDetails.proposal.breakdown.ex_ante.length > 0)
  )

  const navigateToProjectDetails = () => {
    const scrollBarElement = scrollRef.current
    if (scrollBarElement) {
      const projectDetailsElement = scrollBarElement.querySelector('.proposal-details-project-details');
      const parentRect = scrollBarElement.getBoundingClientRect();
      const childRect = projectDetailsElement.getBoundingClientRect();
      const scrollTop = childRect.top - parentRect.top + scrollBarElement.scrollTop;
      scrollBarElement.scroll({top: scrollTop - 20});
    }
  };
  const sections = [
    {
      shouldRender: true,
      name: 'overview',
      label: <FormattedMessage
        id={`ProposalDetails.OverviewLabel`}
        defaultMessage={'Overview'}
      />,
      getContent: () => <ProposalDetailsOverview navigateToProjectDetails={navigateToProjectDetails} proposalDetails={proposalDetails} extrasToRender={getExtrasToRender({section: SECTION_OVERVIEW})} />,
      getHeaderRightSection: (proposalDetails && proposalDetails.proposal && proposalDetails.proposal.valid_until) ? () => renderProposalValidUntil() : () => null,
    },
    {
      shouldRender: true,
      name: 'about supplier',
      label: <FormattedMessage
        id={`ProposalDetails.AboutSupplierLabel`}
        defaultMessage={'About Supplier'}
      />,
      getContent: () => <ProposalDetailsAboutSupplier
        proposalDetails={proposalDetails}
        clickedContactSupplierModal={clickedContactSupplierModal}
        extrasToRender={getExtrasToRender({section: SECTION_ABOUT_SUPPLIER})}
      />,
      getHeaderRightSection: () => null,
    },
    {
      shouldRender: shouldRenderPriceAndAvailability,
      name: 'prices and availability',
      label: <FormattedMessage
        id={`ProposalDetails.PricesAndAvailabilityLabel`}
        defaultMessage={'Prices And Availability'}
      />,
      getContent: () => <ProposalDetailsPriceAndAvailability data={proposalDetails.proposal.breakdown} extrasToRender={getExtrasToRender({section: SECTION_PRICE_AND_AVAILABILITY})} />,
      getHeaderRightSection: () => null,
    },
    {
      shouldRender: true,
      name: 'proposal details',
      label: <FormattedMessage
        id={`ProposalDetails.ProposalDetailsLabel`}
        defaultMessage={'Proposal Details'}
      />,
      getContent: () => <ProposalDetailsProposalDetails proposalDetails={proposalDetails} extrasToRender={getExtrasToRender({section: SECTION_PROPOSAL_DETAILS})}/>,
      getHeaderRightSection: () => null,
    },
    {
      shouldRender: proposalDetails && proposalDetails.project && proposalDetails.project.id && proposalDetails.project.show_details,
      name: 'project details',
      label: <FormattedMessage
        id={`ProposalDetails.ProjectDetailsLabel`}
        defaultMessage={'Project Details'}
      />,
      getContent: () => <ProposalDetailsProjectDetails resetScrollCallback={navigateToProjectDetails} projectId={proposalDetails.project.id } extrasToRender={getExtrasToRender({section: SECTION_PROJECT_DETAILS})}/>,
      getHeaderRightSection: () => null,
    },
  ]

  const renderMain = () => <div className={'rfp-details'}>
    {
      sections.filter(s => s.shouldRender).map(
        (s, i) => <ProposalDetailsCard
          scrollRef={scrollRef}
          key={`rfp-details-card-${i}`}
          headerRightSection={s.getHeaderRightSection()}
          cardLabel={s.label}
          content={s.getContent()}
        />
      )
    }
  </div>

  if (!proposalDetails) {
    return <div style={{background: "white", width: "100%", height: "100%"}}><Loader /></div>
  }

  return renderMain()
}


export default ProposalDetails