import React, {useState, useEffect, useRef} from 'react'
import {queryRfpResults, queryUpdateRfpProposalStatus} from '../components/Utils/queryRfpResults'
import Loader from '../components/UI/Loader/Loader'
import './RequestForProposalsResults.scss'
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs'
import {Link, withRouter} from 'react-router-dom'
import {links} from '@reforestum/shared-utils'
import IconText from '../components/UI/Text/IconText'
import {FormattedMessage, injectIntl} from 'react-intl'
import RequestForProposalsResponseCard from '../components/RequestForProposalsResponseCard/RequestForProposalsResponseCard'
import ArrowLeftSimpleNoCircle from '../components/UI/Icons/ArrowLeftSimpleNoCircle'
import TinyInfoBlock from '../components/UI/TinyInfoBlock/TinyInfoBlock'
import {rfpCard} from '../constants/messages'
import {Button} from '@reforestum/shared-components'
import PencilSimple from '../components/UI/Icons/PencilSimple'
import formatDate from '../constants/formatDate'
import Modal from '../components/UI/Modal/Modal'
import {axiosV2Instance} from '../actions'
import api from '../constants/api'
import store from '../setup/store'
import {addAlert} from '../actions/alerts'
import {getUserOrImpersonatingOrg} from '../utils/usersAndOrganizations'
import Token from '../components/UI/Text/Token'
import ReadMore from '../components/UI/ReadMore'
import getCurrencySymbol from '../utils/getCurrencySymbol'
import ProposalDetails from '../components/ProposalDetails/ProposalDetails'
import getFeatureFlags from '../constants/featureFlags'
import SidebarPanel from '../components/UI/SidebarPanel/SidebarPanel'
import Trash from '../components/UI/Icons/Trash'
import Heart from '../components/UI/Icons/Heart'
import HeartFilled from '../components/UI/Icons/HeartFilled'
import {
  PROPOSAL_STATUS_ARCHIVE,
  PROPOSAL_STATUS_DEFAULT,
  PROPOSAL_STATUS_SHORTLIST
} from '../components/RequestForProposalsResponseCard/rfpConstants'
import Mail from '../components/UI/Icons/Mail'
import TrashCancel from '../components/UI/Icons/TrashCancel'
import renderTooltipForTrimmedText from '../utils/renderTooltipForTrimmedText'
import Tooltip from '../components/UI/Tooltip/Tooltip'
import ContactSupplierModal from './ContactSupplierModal'

const RFP_REFRESH_TIMEOUT = 15000

const MODAL_FORM_ACTION__RFP_EDIT_REQUEST = 'rfp-edit'
const MODAL_EDIT_REQUEST = 'MODAL_EDIT_REQUEST'
const MODAL_CONTACT_SUPPLIER = 'MODAL_CONTACT_SUPPLIER'
const ROUTE_NAME = 'rfp'

const RENDER_PROPOSALS_SETTINGS = {
  ALL: 'ALL',
  SHORTLISTED: 'SHORTLISTED',
  ARCHIVED: 'ARCHIVED',
}

const proposalTabs = [
  'inbox',
  'shortlist',
  'archive'
]

const PROJECT_DETAILS_SIDEBAR_MAX_TITLE_LENGTH = 25
const PROJECT_DETAILS_SIDEBAR_SUPPLIER_NAME_LENGTH = 20

const ff = getFeatureFlags()

const getStartTabIndexFromRoute = match => {
  const tabValue = match.params.tab

  const tabIndex = proposalTabs.indexOf(tabValue)

  let result = 0

  if (tabIndex >= 0) {
    result = tabIndex
  }

  return result
}

const getDetailsProposalIdFromRoute = match => {
  const detailsProposalId = match.params.proposalId
  return detailsProposalId || null
}

const getRfpIdFromRoute = match => {
  return match.params.rfpId || undefined
}

const RequestForProposalsResults = (
  {
    history,
    intl,
    match,
    user
  }
) => {
  const rfpId = getRfpIdFromRoute(match)

  const [forceRefreshCount, setForceRefreshCount] = useState(0)
  const [forceRefreshCount2, setForceRefreshCount2] = useState(0)
  const [selectedTab, setSelectedTab] = useState(getStartTabIndexFromRoute(match))
  const [proposals, setProposals] = useState()
  const [rfpDetails, setRfpDetails] = useState()
  const [isFetching, setIsFetching] = useState(null)
  const [modalToShow, setModalToShow] = useState(null)
  const [editRequestInput, setEditRequestInput] = useState('')
  const [contactSupplierModalEmail, setContactSupplierModalEmail] = useState(null)

  const proposalIdToInspect = getDetailsProposalIdFromRoute(match)
  const scrollRef = useRef(null)

  useEffect(() => {
    const getRfpResults = async () => {
      setIsFetching(true)

      const rfpResults = await queryRfpResults(rfpId)

      if(rfpResults && rfpResults.data && rfpResults.data.proposals) {
        setProposals(rfpResults.data.proposals)
      }

      if(rfpResults.data.request) {
        setRfpDetails(rfpResults.data.request)
      } else {
         //This means that there is no RFP for this org, so we reedirect to the dashboard
         history.push('/')
      }

      setIsFetching(false)
      setForceRefreshCount2(forceRefreshCount2 + 1)
    }

    getRfpResults()
    const intervalId = setInterval(getRfpResults, RFP_REFRESH_TIMEOUT)

    return () => {
      clearInterval(intervalId)
    }
  }, [rfpId, forceRefreshCount])

  useEffect(() => {
    const scrollSidebarToTop = () => {
      const element = scrollRef.current
      if (element) {
        element.scrollTo({ top: 0 })
      }
    }

    scrollSidebarToTop()
  }, [proposalIdToInspect])


  const clickedContactSupplierModal = ({supplierEmail}) => {
    setContactSupplierModalEmail(supplierEmail)
    setModalToShow(modalToShow ? null : MODAL_CONTACT_SUPPLIER)
  }

  const submitEditRequestForProposals = async () => {
    const userInput ={
      editRequestInput
    }
    await axiosV2Instance.post(api.RECORD_FORM_RESPONSE_v2, {
      action: MODAL_FORM_ACTION__RFP_EDIT_REQUEST,
      context: {
        userInput,
        userId: `${user.id}`,
        email: user.email,
        orgId: `${getUserOrImpersonatingOrg(user).organization_id}`
      }
    })
    store.dispatch(addAlert(
      {
        type: 'success',
        message: intl.formatMessage(rfpCard.EditRFPFormSubmitted),
        dismissAfter: 8000
      }
    ))
    setModalToShow(null)
  }

  const handleTabSelect = (index) => {
    setSelectedTab(index)

    const tabName = proposalTabs[index]
    const path = `/${ROUTE_NAME}/${tabName}`
    history.push(path)
  }

  const renderMain = () => {
    let result = null
    if(isFetching && forceRefreshCount === 0 && forceRefreshCount2 === 0) {
      result = renderLoading()
    }
    else if(proposals) {
      result = renderProposals()
    }
    return <div
      className={'rfp-results-container__outer'}
      onClick={(e) => {
        if(!ff.proposalDetailsSidebar) return
        //Close sidebar when clicking outside of it
        if(window.location.pathname.includes('rfp/0/proposal')) {
          //This timeout allow to click on other buttons with links with the sidebar open and only reedirect to rfp page if not other link is clicked
          setTimeout(() => {
            if(window.location.pathname.includes('rfp/0/proposal')) {
              history.push('/rfp/0')
            }
          }, 0)
        }
      }}
    >
      {result}
    </div>
  }

  const renderLoading = () => <div className={'rfp-results__loading__container'}>
    <Loader />
  </div>

  const renderFilteredProposals = ({setting}) => {
    const applyCorrectProposalsFilter = (proposal) => {
      let result = true
      if(setting === RENDER_PROPOSALS_SETTINGS.ALL) {
        result = !proposal.archived
      }
      else if(setting === RENDER_PROPOSALS_SETTINGS.SHORTLISTED) {
        result = proposal.shortlisted
      }
      else if(setting === RENDER_PROPOSALS_SETTINGS.ARCHIVED) {
        result = proposal.archived
      }
      return result
    }

    return (proposals || []).filter(applyCorrectProposalsFilter).map(
      p => <RequestForProposalsResponseCard
        activeTab={match.params.tab}
        proposalId={p.proposalId}
        projectId={p.projectId}
        projectRating={p.BeZeroRating}
        projectLocation={p.location}
        projectDetailedLocation={p.detailedLocation}
        projectName={p.name}
        projectImage={p.projectImage}
        projectTypology={p.typology}
        projectStandard={p.standard}
        supplierName={p.supplier.name}
        supplierEmail={p.supplier.email}
        receivedDate={p.receivedDate}
        validUntilDate={p.validUntilDate}
        vintagesTagArray={p.vintage}
        minVolume={p.minVolume}
        maxVolume={p.maxVolume}
        minPrice={p.minPrice}
        maxPrice={p.maxPrice}
        priceUnit={p.priceUnit}
        shortlisted={p.shortlisted}
        archived={p.archived}
        forceRefreshCount={forceRefreshCount}
        setForceRefreshCount={setForceRefreshCount}
        clickedContactSupplierModal={clickedContactSupplierModal}
        user={user}
      />
    )
  }

  const renderEmptyState = (setting) => {
    let message
    switch(setting) {
      case RENDER_PROPOSALS_SETTINGS.ALL:
        message = <FormattedMessage
          id={'RfpResults.EmptyStateAll'}
          defaultMessage={'You haven\'t received any proposals yet. Once you receive any proposal, it will appear here.'}
        />
        break;
      case RENDER_PROPOSALS_SETTINGS.SHORTLISTED:
        message = <FormattedMessage
          id={'RfpResults.EmptyStateShortlisted'}
          defaultMessage={'You have no shortlisted proposals yet. Once you shortlist any proposal, it will appear here.'}
        />
        break
      case RENDER_PROPOSALS_SETTINGS.ARCHIVED:
        message = <FormattedMessage
          id={'RfpResults.EmptyStateArchived'}
          defaultMessage={'You have no archived proposals yet. Once you archive any proposal, it will appear here.'}
        />
        break;
      default:
        message = ""
        break
    }

    return <div className={'rfp-results__empty-state__container'}>
      <p className={'rfp-results__empty-state__message'}>
        {message}
      </p>
    </div>

  }

  const proposalsAll = renderFilteredProposals({setting: RENDER_PROPOSALS_SETTINGS.ALL})
  const proposalsShortlisted = renderFilteredProposals({setting: RENDER_PROPOSALS_SETTINGS.SHORTLISTED})
  const proposalsArchived = renderFilteredProposals({setting: RENDER_PROPOSALS_SETTINGS.ARCHIVED})

  const handleShortlist = async (proposalId, shortlisted) => {
    let statusToSet = shortlisted ? PROPOSAL_STATUS_DEFAULT : PROPOSAL_STATUS_SHORTLIST
    await queryUpdateRfpProposalStatus(proposalId, statusToSet)
    store.dispatch(addAlert({
        type: 'success',
        message: shortlisted ?
          <FormattedMessage id={'RfpResults.UnshortlistSuccess'} defaultMessage={'Proposal unshortlisted'}/> :
          <FormattedMessage id={'RfpResults.ShortlistSuccess'} defaultMessage={'Proposal shortlisted'}/>,
        dismissAfter: 3000
      })
    )
    setForceRefreshCount(forceRefreshCount + 1)
  }

  const handleArchive = async (proposalId, archived) => {
    let statusToSet = archived ? PROPOSAL_STATUS_DEFAULT : PROPOSAL_STATUS_ARCHIVE
    await queryUpdateRfpProposalStatus(proposalId, statusToSet)
    store.dispatch(addAlert({
      type: 'success',
      message: archived ?
        <FormattedMessage id={'RfpResults.UnarchiveSuccess'} defaultMessage={'Proposal unarchived'}/> :
        <FormattedMessage id={'RfpResults.ArchiveSuccess'} defaultMessage={'Proposal archived'}/>,
      dismissAfter: 3000
    }))
    setForceRefreshCount(forceRefreshCount + 1)
  }

  const renderSidebarPanelDetails = () => {
    const openProposal = proposals.find(p => p.proposalId === proposalIdToInspect)
    if(proposalIdToInspect && !openProposal) history.push(`/rfp/0`)
    return <>
      {
        openProposal && <SidebarPanel
          scrollRef={scrollRef}
          clickClose={() => history.push(`/rfp/0/${match.params.tab || ''}`)}
          content={<ProposalDetails scrollRef={scrollRef} clickedContactSupplierModal={clickedContactSupplierModal} proposalId={openProposal.proposalId} />}
          toolbarContent={
            <div className={'proposal-details__sidebar_toolbar'}>
              <div className={'proposal-details__sidebar_toolbar__info'}>
                {
                  window.innerWidth > 700 && <div
                    className={'proposal-details__sidebar_toolbar__info__proposal-name design-system-text-title-xxs-regular'}>
                    {
                      openProposal.name.length > PROJECT_DETAILS_SIDEBAR_MAX_TITLE_LENGTH
                        ? renderTooltipForTrimmedText({text: openProposal.name, maxLength: PROJECT_DETAILS_SIDEBAR_MAX_TITLE_LENGTH})
                        : openProposal.name
                    }
                  </div>
                }
                <div className={'proposal-details__sidebar_toolbar__info__proposal-by'}>
                  <FormattedMessage
                    id={'RfpResults.OpenProposalProposalBy'}
                    defaultMessage={'Proposal by'}
                  />
                  <span className={'proposal-details__sidebar_toolbar__info__proposal-by-supplier'}>
                    {
                      openProposal.supplier.name.length > PROJECT_DETAILS_SIDEBAR_SUPPLIER_NAME_LENGTH
                        ? renderTooltipForTrimmedText({text: openProposal.supplier.name, maxLength: PROJECT_DETAILS_SIDEBAR_SUPPLIER_NAME_LENGTH})
                        : openProposal.supplier.name
                    }
                  </span>
                </div>
              </div>
              <div className={'proposal-details__sidebar_toolbar__actions'}>
                {
                  openProposal.archived
                    ? <Tooltip position={"bottom-right"} content={<FormattedMessage id={'RfpResults.Unarchive'} defaultMessage={'Unarchive'}/>}>
                        <TrashCancel width={20} height={19} onClick={() => handleArchive(openProposal.proposalId, openProposal.archived)} />
                     </Tooltip>
                    : <Tooltip position={"bottom-right"} content={<FormattedMessage id={'RfpResults.Archive'} defaultMessage={'Archive'}/>}>
                        <Trash onClick={() => handleArchive(openProposal.proposalId, openProposal.archived)} />
                      </Tooltip>
                }
                {
                  openProposal.shortlisted
                    ? <Tooltip  position={"bottom-right"} content={<FormattedMessage id={'RfpResults.Unshortlist'} defaultMessage={'Unshortlist'}/>}>
                        <HeartFilled fill={'#F08862'} onClick={() => handleShortlist(openProposal.proposalId, openProposal.shortlisted)} />
                      </Tooltip>
                    : <Tooltip  position={"bottom-right"} content={<FormattedMessage id={'RfpResults.Shortlist'} defaultMessage={'Shortlist'}/>}>
                        <Heart onClick={() => handleShortlist(openProposal.proposalId, openProposal.shortlisted)} fill={openProposal.shortlisted ? 'var(--ref-green-300)' : null} />
                      </Tooltip>
                }
                <Tooltip position={"bottom-right"} content={<FormattedMessage id={'RfpResults.ContactSupplier'} defaultMessage={'Contact Supplier'}/>}>
                  <Mail onClick={() => clickedContactSupplierModal({supplierEmail: openProposal.supplier.email})} />
                </Tooltip>
              </div>
            </div>
          }
        />
      }
    </>
  }

  const renderCorrectModals = () => {
    let result = null

    if(modalToShow === MODAL_EDIT_REQUEST) {
      result = <Modal
        large
        clickClose={() => setModalToShow(null)}
        content={<div>
          <h1>
            <FormattedMessage
              id={'RfpResults.EditRequest'}
              defaultMessage={'Edit Request For Proposals'}
            />
          </h1>
          <p>
            <FormattedMessage
              id={'RfpResults.EditRequestP1'}
              defaultMessage={'Do you see any misspecified requirements or want to modify the conditions of your open RFP?'}
            />
          </p>
          <p>
            <FormattedMessage
              id={'RfpResults.EditRequestP2'}
              defaultMessage={'Please provide further information about the desired criteria and or changes. '}
            />
          </p>
          <p className={'rfp-modal-instruction'}>
            <FormattedMessage
              id={'RfpResults.EditRequestFormInstruction'}
              defaultMessage={'Description of Desired Changes'}
            />
          </p>
          <textarea
            placeholder={intl.formatMessage(rfpCard.EditRequestFormPlaceholder)}
            onChange={e => setEditRequestInput(e.target.value)}
          >
            {editRequestInput}
          </textarea>
          <p>
            <FormattedMessage
              id={'RfpResults.EditRequestP3'}
              defaultMessage={'The Reforestum team will review this promptly and get in touch with you if there in case that clarifications are needed.'}
            />
          </p>
          <div className={'rfp-modal-button-container'}>
            <Button disabled={!editRequestInput} onClick={submitEditRequestForProposals}>
              <PencilSimple className={`rfp-modal-button-svg ${!editRequestInput && 'rfp-modal-button-svg--disabled'}`} />
              <FormattedMessage
                id={'RfpResults.EditRequestButtonText'}
                defaultMessage={'Edit Request for Proposals'}
              />
            </Button>
          </div>
        </div>}
      />
    }
    if(modalToShow === MODAL_CONTACT_SUPPLIER) {
      result = <ContactSupplierModal onClose={() => setModalToShow(null)} email={contactSupplierModalEmail} setModalToShow={setModalToShow} intl={intl} />
    }

    return result
  }

  const renderProposals = () => <div className={'rfp-results-container'}>
    {renderCorrectModals()}
    {renderBackToDashboard()}

    <h1>
      <FormattedMessage
        id={'RfpResults.RequestForProposals'}
        defaultMessage={'Request For Proposals'}
      />
    </h1>

    {renderRfpInformation()}

    <h2>
      <FormattedMessage
        id={'RfpResults.ReceivedProposals'}
        defaultMessage={'Received Proposals'}
      />
    </h2>

    {renderTabsForProposals()}
    {
      ff.proposalDetailsSidebar && renderSidebarPanelDetails()
    }
  </div>

  const tabs = [
    {
      formattedMessage: <FormattedMessage
        id={'RfpResults.InboxTab'}
        defaultMessage={'Inbox'}
      />,
      tokenProps: {content: `${proposalsAll.length}`, noMarginRight: true}
    },
    {
      formattedMessage: <FormattedMessage
        id={'RfpResults.ShortlistTab'}
        defaultMessage={'Shortlisted'}
      />,
      tokenProps: {content: `${proposalsShortlisted.length}`, noMarginRight: true}
    },
    {
      formattedMessage: <FormattedMessage
        id={'RfpResults.ArchiveTab'}
        defaultMessage={'Archived'}
      />,
      tokenProps: {content: `${proposalsArchived.length}`, noMarginRight: true}
    },
  ]

  const renderTabsForProposals = () => <Tabs selectedIndex={selectedTab} onSelect={handleTabSelect}>
    <TabList>
      {
        tabs.map(
          (t, i) => <Tab index={`tab-${i}`}>
            {t.formattedMessage}
            <Token
              className={selectedTab === i ? 'rfp-results__tabs-token rfp-results__tabs-token__active' : 'rfp-results__tabs-token rfp-results__tabs-token__inactive'}
              color={selectedTab === i ? 'green' : 'unselected'}
              {...t.tokenProps}
            />
          </Tab>
        )
      }
    </TabList>
    <TabPanel>
      {proposalsAll}
      {proposalsAll.length === 0 && renderEmptyState(RENDER_PROPOSALS_SETTINGS.ALL)}
    </TabPanel>
    <TabPanel>
      {proposalsShortlisted}
      {proposalsShortlisted.length === 0 && renderEmptyState(RENDER_PROPOSALS_SETTINGS.SHORTLISTED)}
    </TabPanel>
    <TabPanel>
      {proposalsArchived}
      {proposalsArchived.length === 0 && renderEmptyState(RENDER_PROPOSALS_SETTINGS.ARCHIVED)}
    </TabPanel>
  </Tabs>

  const formattedMessageForProjectInformationLabels = {
    'Project Type': <FormattedMessage
      id={`RfpResults.ProjectType`}
      defaultMessage={'Project Type'}
    />,
    'Certifications': <FormattedMessage
      id={`RfpResults.Certifications`}
      defaultMessage={'Certifications'}
    />,
    'Geography': <FormattedMessage
      id={`RfpResults.Geography`}
      defaultMessage={'Geography'}
    />
  }

  const renderProjectInformationLabels = () => <div className={'rfp-results__rfp-information__stats'}>
    {
      (rfpDetails && rfpDetails.properties) && rfpDetails.properties.map(
        (property, index) => property.items.length > 0 ?
        (<div key={`rfp-details-property-${index}`} className={'rfp-results__rfp-information__stats__group'}>
          <div className={'rfp-results__rfp-information__stats__group-label'}>
            {formattedMessageForProjectInformationLabels[property.propertyName]}
          </div>
          {
            property.items.map(
              (item, i) => <div key={`info__project-type-${i}`} className={'rfp-results__rfp-information__stats__group-value'}>
                {item}
              </div>
            )
          }
        </div>) : null
      )
    }

  </div>

  const renderProjectInformationTinyInfoBlocksAndActions = () => <div className={'rfp-results__rfp-information__tiny-info-blocks-and-actions'}>
    {renderProjectInformationTinyInfoBlocks()}
    <div className={'rfp-results__rfp-information__actions'}>
      <Button outlineBlack onClick={() => setModalToShow(MODAL_EDIT_REQUEST)}>
        <PencilSimple />
        <FormattedMessage
          id={'RfpResults.Edit Request'}
          defaultMessage={'Edit Request'}
        />
      </Button>
    </div>
  </div>

  const renderPriceString = () => {
    let numericString = `${intl.formatNumber(rfpDetails.targetPriceMin)}`

    if(rfpDetails.targetPriceMin && rfpDetails.targetPriceMax && rfpDetails.targetPriceMin !== rfpDetails.targetPriceMax) {
      numericString = `${intl.formatNumber(rfpDetails.targetPriceMin)} - ${intl.formatNumber(rfpDetails.targetPriceMax)}`
    }

    return <span>
      {getCurrencySymbol(rfpDetails.targetPriceUnit)}
      &nbsp;
      {numericString}
    </span>
  }

  const renderProjectInformationTinyInfoBlocks = () => {
    const blocks = [
      {
        shouldRender: rfpDetails.targetVolume,
        props: {
          className: 'rfp-card__tiny-info-block',
          title: `${intl.formatMessage(rfpCard.descriptionTargetVolume)}`,
          value: `${rfpDetails.targetVolume} ${intl.formatMessage(rfpCard.unitTons)}`
        }
      },
      {
        shouldRender: rfpDetails.targetPriceMin,
        props: {
          className: 'rfp-card__tiny-info-block',
          title: intl.formatMessage(rfpCard.descriptionTargetPrice),
          value: renderPriceString()
        }
      },
      {
        shouldRender: rfpDetails.closingDate,
        props: {
          className: 'rfp-card__tiny-info-block',
          title: intl.formatMessage(rfpCard.descriptionClosingDate),
          value: formatDate(rfpDetails.deadline)
        }
      }
    ]

    const blocksToRender = blocks.filter(b => b.shouldRender)

    return (
      <div className={'rfp-results__rfp-information__tiny-info-blocks'}>
        {blocksToRender.map((b, i) => (
          <TinyInfoBlock
            key={`tiny-info-block-${i}`}
            divider={blocksToRender.length > i + 1}
            {...b.props}
          />
        ))}
      </div>
    )
  }

  const renderRfpInformation = () => <div className={'rfp-results__rfp-information design-system-elevation-base'}>
    <div className={'rfp-results__rfp-information__title-tag'}>
      <FormattedMessage
        id={'RfpResults.RequestOverview'}
        defaultMessage={'Request Overview'}
      />
    </div>
    <h2>{rfpDetails.title}</h2>
    <div className={'rfp-results__rfp-information__read-more'}>
      <ReadMore text={rfpDetails.description} isHtml cutLength={280} minLength={230} />
    </div>
    {renderProjectInformationLabels()}
    {renderProjectInformationTinyInfoBlocksAndActions()}
  </div>

  const renderBackToDashboard = () => <div className={'rfp-results__back-to-dashboard'}>
      <Link to={links.getDashboardUrl()} className="rfp-results__back-to-dashboard">
        <IconText icon={() => <ArrowLeftSimpleNoCircle stroke={'var(--ref-green-300)'} />}>
        <FormattedMessage
          id={'RfpResults.BackToDashboard'}
          defaultMessage={'Back to dashboard'}
          />
        </IconText>
      </Link>
    </div>

  return renderMain()
}

export default withRouter(injectIntl(RequestForProposalsResults))