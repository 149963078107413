const CREATED = 0
const PROCESSING  = 1
const COMPLETED = 2
const FAILED = 99

export default {
    CREATED,
    PROCESSING,
    COMPLETED,
    FAILED
}