import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './ProfilePictureSetting.css';
import { Camera } from '../../UI/Icons/Camera';
import { imageIsSquare, processAvatarImage, validImageFormat } from '../../../utils/avatarFileupload';
import { uuidv4 } from '../../../utils/uuidv4';
import { VALID_FILE_TYPES } from '../../../constants/avatarFileUpload';
import { Button } from '@reforestum/shared-components';
import Text from '../../UI/Text/Text';
import { FormattedMessage, injectIntl } from 'react-intl';
import { AlertMessages } from '../../../constants/messages';


class ProfilePictureSetting extends React.Component {
    constructor(props) {
        super(props);
        this.setInputRef = element => {
            this.inputRef = element;
          };

        this.handleFile = this.handleFile.bind(this);
        this.handleInputClick = this.handleInputClick.bind(this);
        this.onUploadFileButtonClicked = this.onUploadFileButtonClicked.bind(this);
    }

    onUploadFileButtonClicked() {
        if(this.inputRef) {
            this.inputRef.click();
        }
    }
    
    async handleFile(event) {
        const { updateAvatar, refreshProfile , addAlert, id, intl } = this.props
        const userImage = event.target.files[0]
    
        if (!validImageFormat(userImage)) {
            const validFileTypesExceptLast = VALID_FILE_TYPES.slice(0, -1)
            const lastValidFileType = VALID_FILE_TYPES[VALID_FILE_TYPES.length - 1]
            addAlert(
                {
                message: intl.formatMessage(AlertMessages.uploadValidAvatarFile, {
                    validFileTypesExceptLast: validFileTypesExceptLast.join(', '),
                    lastValidFileType: lastValidFileType,
                }),
                type: 'error',
                dismissAfter: 6000,
                },
            )
            return false
        }
    
        if (!await imageIsSquare(userImage)) {
          addAlert(
            {
              message: intl.formatMessage(AlertMessages.imageNotSquareWarning),
              type: 'warning',
              dismissAfter: 6000,
            },
          )
        }
    
        const imageToUploadName = `${id}_${uuidv4()}`
        const {newImageFile, imageURL} = await processAvatarImage(userImage, imageToUploadName)
    
        const formData = new FormData()
        formData.append('image', newImageFile)
    
        updateAvatar({formData: formData, imageURL: imageURL, intl}).then(() => {
            refreshProfile()
        })
    }

    handleInputClick(event) {
        /*
         * To allow selecting the same file again value must be set to null.
         * https://stackoverflow.com/a/12102992/10459242
         */
        event.target.value = null
    }

    render() {
        const {
            profilePictureUrl,
            className,
        } = this.props;

        const avatar = profilePictureUrl ? 
            <img src={profilePictureUrl} alt='avatar' className='profile-picture-setting-image'/> : 
            <div className='profile-picture-setting-image-placeholder'>
                <Camera/>
            </div>;

        return (
            <div className={cx(className, 'profile-picture-setting-container')}>
                {avatar}
                <Button onClick={this.onUploadFileButtonClicked} outline small className='btn--blank profile-picture-setting-button'>
                    <Text className="profile-picture-setting-button-text">
                        <FormattedMessage id="Form.uploadProfilePicture" defaultMessage="Upload profile picture"/>
                    </Text>
                </Button>
                <input
                    ref={this.setInputRef}
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={this.handleFile}
                    onClick={this.handleInputClick}
                    className='profile-picture-setting-input'
                >
                </input>
                <p></p>
            </div>
        )
    }
}


ProfilePictureSetting.propTypes = {
    profilePictureUrl: PropTypes.string.isRequired,
    addAlert: PropTypes.func.isRequired,
    updateAvatar: PropTypes.func.isRequired,
    refreshProfile: PropTypes.func.isRequired,

}

export default injectIntl(ProfilePictureSetting);

