import React from 'react';

const Certification = props => (
	<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
	<g clipPath="url(#clip0)">
	<g clipPath="url(#clip1)">
		<path d="M8.74939 3.5L6.34314 5.90625L5.24939 4.8125" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
	</g>
	<path d="M4.78921 8.10245L4.08337 13.4166L7.00004 11.6666L9.91671 13.4166L9.21087 8.09662" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="bevel"/>
	<path d="M6.99996 8.74995C9.25512 8.74995 11.0833 6.92178 11.0833 4.66662C11.0833 2.41145 9.25512 0.583282 6.99996 0.583282C4.7448 0.583282 2.91663 2.41145 2.91663 4.66662C2.91663 6.92178 4.7448 8.74995 6.99996 8.74995Z" stroke="#79897A" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
	</g>
	<defs>
		<clipPath id="clip0">
			<rect width="14" height="14" fill="white"/>
		</clipPath>
		<clipPath id="clip1">
			<rect width="5.25" height="5.25" fill="white" transform="translate(4.375 2.1875)"/>
		</clipPath>
	</defs>
    </svg>
);

export default Certification;