import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Box } from 'react-layout-components';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Container from '../Layout/Container/Container';
import Content from '../Layout/Container/Content';

import Step from '../UI/Forms/Step';
import Footer from './Footer';
import StepLoading from './StepLoading';
import HeadingSmall from '../UI/Text/HeadingSmall';
import FeedbackMessage from '../UI/Forms/FeedbackMessage';
import Accordion from '../UI/Accordion/Accordion';

import { carbonMessages } from '../../constants/messages';
import CarbonSidebarContainer from '../../containers/CarbonSidebarContainer';
import { getUnit, keepToMetric, kilometersToMiles, convertStepIndexToMiles } from '../../utils/units';

import './Calculator.css';
import {moveCalculatorElementBackToLastPosition} from "./helpers/moveElementToPageTopOnMobile"

const startingState = {
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  isEditing: false,
  editSourceLoaded: false,
  sourceToEditPath: undefined,
  loadingFromParentAndStepList: false,
  premadePath: null,
  startedPremadeLoad: false,
  premadeIsLoaded: null
}

class Calculator extends Component {

  constructor (props) {
    super(props);

    this.state = {
      ...startingState
    }

    this.setStartingClassDeclarations()
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillReceiveProps(nextProps) {
    const {calculatedCarbon, shouldReinitializeCalculatorComponent, setReinitializeCalculatorComponent} = nextProps
    this.setState({renderingLastStep: calculatedCarbon != null})
    if(shouldReinitializeCalculatorComponent) {
      this.props.resetForm()
      this.setState({...startingState})
      this.setStartingClassDeclarations()
      setReinitializeCalculatorComponent(false)
    }
    this.calcOptions = {...this.calcOptions, ...nextProps.options}
    if(nextProps.steps.length === 0) return
    // A new step was added
    if (nextProps.steps.length > 1 && this.lastStepsPassedAsProps.length > nextProps.steps.length){
      if (this.props.stepsList.length > this.rememberStepsList.length) this.rememberStepsList = this.props.stepsList
      if(this.props.steps.length > 1 && this.stopSearching) this.stopSearching = false
      if (this.jumpingBackAStep) {return}
      if(this.rememberSteps === JSON.stringify(nextProps.steps)) return

      if(JSON.stringify(this.rememberStepsList) !== JSON.stringify(nextProps.steps)){
        if(nextProps.steps[nextProps.steps.length - 1].final === true) return
      }
      this.rememberSteps = JSON.stringify(nextProps.steps)
      return
    }
    // Incoming steps are different
    if (JSON.stringify(this.lastStepsPassedAsProps) !== JSON.stringify(nextProps.steps)){
      this.rememberStepsList = this.props.stepsList
      this.lastStepsPassedAsProps = nextProps.steps
      if(this.recallStepIterator > 0){
        let sourcesDict = {}
        nextProps.sources.forEach(e => sourcesDict[e.id] = e)

        let pathToEdit = this.state.startedPremadeLoad ? this.state.premadePath : JSON.parse(sourcesDict[nextProps.sourceToEdit].path)
        let sourceToEditPath = [[0, null], ...pathToEdit]

        this.setState({sourceToEditPath})
        this.callDoHandleStepUsingStepsDictAndList(sourceToEditPath, this.recallStepIterator, nextProps.steps)
      }
    }
  }

  componentDidUpdate() {
    if(this.props.sourceToEdit > 0 && (this.state.sourceToEditPath === undefined || (this.state.premadePath && this.state.premadePath.length > 0))){
      this.setState({premadePath: null, startedPremadeLoad: false})
      this.calculatedPathOfSourceToEdit = null
      this.nameOfSourceToEdit = null
      this.lastSourceToEdit = this.props.sourceToEdit
      let sourcesDict = {}
      this.props.sources.forEach(e => sourcesDict[e.id] = e)

      let sourceToEditPath = [[0, null], ...JSON.parse(sourcesDict[this.props.sourceToEdit].path)]
      this.sourceToEditPath = sourceToEditPath

      this.setState({sourceToEditPath, editSourceLoaded: false});

      this.callDoHandleStepUsingStepsDictAndList(sourceToEditPath, this.recallStepIterator, this.props.steps)

      this.props.requestStep()
    }
    else if(this.state.premadePath !== null && this.state.startedPremadeLoad !== true){
      this.calculatedPathOfSourceToEdit = null
      this.nameOfSourceToEdit = null
      this.lastSourceToEdit = this.props.sourceToEdit
      let sourcesDict = {}
      this.props.sources.forEach(e => sourcesDict[e.id] = e)

      let sourceToEditPath = [[0, null], ...this.state.premadePath]
      this.sourceToEditPath = sourceToEditPath

      this.setState({sourceToEditPath, editSourceLoaded: false, startedPremadeLoad: true})

      this.callDoHandleStepUsingStepsDictAndList(sourceToEditPath, this.recallStepIterator, this.props.steps)

      this.props.requestStep()
    }
    if(this.lastSourceToEdit !== undefined && this.lastSourceToEdit !== this.props.sourceToEdit){
      this.lastSourceToEdit = undefined
      this.setState({sourceToEditPath: undefined})
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  setStartingClassDeclarations() {
    this.getStepOptions = this.getStepOptions.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.renderLastStep = this.renderLastStep.bind(this);
    this.recallStepIterator = 0
    this.observedFormValuesFromSteps = {}
    this.lastStepsPassedAsProps = []
    this.rememberSteps = ''
    this.sourceToEditPath = []
    this.stopSearching = true
    this.jumpingBackAStep = false
    this.calcOptions = {}
    this.typedSourceName = ''
    this.calculatedPathOfSourceToEdit = null
  }

  stepWithValueIsFinal(step, value) {
    let optionsByValueIsFinal = {}
    Object.keys(this.calcOptions).forEach(op => {
      optionsByValueIsFinal[this.calcOptions[op].value] = this.calcOptions[op].final === true || false
    })
    return optionsByValueIsFinal[value]
  }

  // This helps to replay steps when selecting a source to edit
  callDoHandleStepUsingStepsDictAndList(sourceToEditPath, i, steps) {
    let stepsDict = {}
    steps.forEach(e => stepsDict[e.id] = e)
    let step = stepsDict[sourceToEditPath[i][0]]
    if (!step) { this.setState({editSourceLoaded: true}); return; }
    let nextStepID = sourceToEditPath[i + 1][0]
    let currentValue = sourceToEditPath[i + 1][1]
    if(step.unit_base === 'km' && getUnit(step.unit_base) !== 'km') {
      if(!this.props.distanceConverstionsForEditDone) {
        this.props.convertStepIndexToMiles(i + 1)
        currentValue = kilometersToMiles(currentValue)
        sourceToEditPath = convertStepIndexToMiles(sourceToEditPath, i + 1)
        this.props.manuallyChangeFormValues(sourceToEditPath)
      }
    }
    let isFinal = step.final || this.stepWithValueIsFinal(step, currentValue)

    this.doHandleNextStep(step, nextStepID, currentValue, isFinal, sourceToEditPath)

    this.recallStepIterator += 1
    if (this.recallStepIterator === sourceToEditPath.length - 1){
      this.setState({editSourceLoaded: true});
      this.recallStepIterator = 0
      if(this.props.manuallyChangedFormValues.formValuesProvidedByEdit) this.props.setFormValues(this.props.manuallyChangedFormValues.formValuesProvidedByEdit)
    }
  }

  deriveFormValuesFromSteps(stepId, nextStepId, currentValue) {
    if(stepId === 0) this.observedFormValuesFromSteps = {}
    if(!this.props.possibleNextStepValues) return
    let possibleNextStepValuesAndStrings = {}
    this.props.possibleNextStepValues.forEach(e => {if(e.name) possibleNextStepValuesAndStrings[e.value] = e.name})
    this.observedFormValuesFromSteps[`field_${stepId}`] = currentValue
    this.props.manuallyChangeFormValues(this.observedFormValuesFromSteps)
  }

  updateWindowDimensions() {
    if(window.innerHeight > this.state.windowHeight) moveCalculatorElementBackToLastPosition()
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    })
  }

  addStepOptionsToCalculator(arrOptions) {
    arrOptions.forEach(op => this.calcOptions[op.name] = op)
  }

  getStepOptions(step) {
    const { options } = this.props;
    return step.children ? step.children.map(option => options[option]) : [];
  }

  async setPremadePath(path, name){
    this.rememberTypedName(name)
    path = JSON.parse(path)
    await this.props.resetForm()
    this.props.doExitEditMode()
    this.setState({
      premadePath: path,
      startedPremadeLoad: false,
      premadeIsLoaded: null
    })
  }

  setPremadeLoaded(isLoaded){
    this.setState({premadeIsLoaded: isLoaded})
  }

  jumpBackToStepIndex(index){
    const {steps, stepsList, resetForm} = this.props
    this.jumpingBackAStep = true
    this.lastStepsPassedAsProps = []

    if(typeof index === 'string' && index.includes('_')) {
      index = index.split("_")[0]
    }

    if(index === 1) {
      resetForm()
    }
    else {
      this.handleNextStep(steps[index - 2], steps[index - 1].id, stepsList[index - 1][1], steps[index - 1].isFinal, false);
    }
  }

  handleNextStep(step, nextStepId, currentValue, isFinal = false, applyRememberedSteps=true) {
    if(!applyRememberedSteps){
      return this.doHandleNextStep(step, nextStepId, currentValue, isFinal, this.props.stepsList)
    }

    this.stopSearching = false
    return this.doHandleNextStep(step, nextStepId, currentValue, isFinal, this.props.stepsList)
  }

  doHandleNextStep(step, nextStepId, currentValue, isFinal, propsStepsList){
    const { requestStep, calculateCarbon } = this.props

    if (step.final || isFinal) {
      let stepsList = propsStepsList.slice();

      const expectedUnit = step.unit_base;
      const value = !isNaN(currentValue)
        ? expectedUnit ? Number(keepToMetric(getUnit(expectedUnit), expectedUnit, currentValue)) : Number(currentValue)
        : currentValue;

      this.deriveFormValuesFromSteps(
        step.id,
        nextStepId,
        currentValue,
      )

      // handling user going back from one last step to another last step.
      if (stepsList[stepsList.length - 1][0] !== step.id) {
        stepsList = [[0, null], ...stepsList.slice(1, stepsList.length - 1)];

        const stepsPath = [
          ...stepsList.slice().splice(1, (stepsList.length - 1)),
          [Number(nextStepId), value]
        ];

        return calculateCarbon(stepsPath, stepsList.slice());
      }

      let stepsPath = [
        ...stepsList.slice().splice(0, (stepsList.length)),
        [Number(nextStepId), value]
      ];

      if (stepsPath[0][0] === 0 && (stepsPath[0][1] === null || stepsPath[0][1] === undefined)) stepsPath = stepsPath.splice(1, stepsPath.length)

      return calculateCarbon(stepsPath, stepsList.slice());
    }

    this.deriveFormValuesFromSteps(
      step.id,
      nextStepId,
      currentValue,
    )

    return requestStep(
      step.id,
      nextStepId,
      currentValue,
    );
  }

  rememberTypedName(name) {
    this.typedSourceName = name
  }

  render() {
    const {
      addAlert,
      calcErrorMessages,
      calculatedCarbon,
      change,
      untouch,
      formSubmit,
      formValues,
      manuallyChangedFormValues,
      handleSubmit,
      handleUpdateSubmit,
      isAdding,
      isEditing,
      isCalculating,
      isFetching,
      stepErrorMessages,
      steps,
      intl,
      valid,
      sessionToken,
      advices,
      stepsList,
      sourceToEdit,
      resetForm,
      doExitEditMode,
      sources,
      calculatedPath,
    } = this.props;

    const { windowWidth, windowHeight } = this.state;
    const suggestions = advices.map((suggestion) => {

      return (
        <CSSTransition
          timeout={200}
          classNames="accordion"
          unmountOnExit
        >
          <Accordion
            title={intl.formatMessage(carbonMessages.calculatorHowToReduceYourCarbonFootprint)}
            content={suggestion.text}
            link={suggestion.link}
            isOpen={true}
          />
        </CSSTransition>
      )
    })

    let stepIndex = 0;

    return (
      <Box
        className={cx(
          'carbon-calculator',
          { 'pointer-disabled': isAdding || isFetching || this.state.loadingFromParentAndStepList === true }
        )}
        width="100%" style={{height: "100%"}}>

        <Container>
          <div className="carbon-calculator__suggestions-container carbon-calculator__suggestions-container--desktop">
            <TransitionGroup>
              {suggestions}
            </TransitionGroup>
          </div>
          <Content className="carbon-calculator__calculator">
            <div style={{}}>
              {
                this.props.sourceToEditName &&
                  <HeadingSmall className="carbon-calculator__title">
                    <FormattedMessage
                      id={'Calculator.editModeTitle'}
                      defaultMessage={'Editing CO₂ source'}
                    />
                    <FormattedMessage
                      id={'General.passValue'}
                      defaultMessage={' {value}'}
                      style={{color: 'green'}}
                      values={{
                        value: this.props.sourceToEditName
                      }}
                    />
                  </HeadingSmall>
              }


              {
                (
                  (isFetching && steps.length < 1)
                  || this.calculatorIsLoadingSteps()
                )
                &&
                <StepLoading sourceToEditPath={this.state.sourceToEditPath} />
              }

              <form
                id={'carbon-calculator__form'}
                className="carbon-calculator__form"
                onSubmit={handleSubmit(formSubmit)}
                autoComplete={"off"}
                style={{ opacity: isFetching || this.state.loadingFromParentAndStepList === true ? 0.5 : 1 }}>
                {
                  this.calculatorIsLoadingSteps()
                  ?
                  <span />
                  :
                  steps.map(step => {
                    stepIndex++;
                    if (step) {
                      return (
                        <Step
                          isMostRecentStep={stepIndex === steps.length && !this.state.renderingLastStep}
                          resetForm={resetForm}
                          doExitEditMode={doExitEditMode}
                          isFirstStep={step.id === 0 || (step.id !== 0 && stepIndex === 0)}
                          setPremadePath={this.setPremadePath.bind(this)}
                          setPremadeLoaded={this.setPremadeLoaded.bind(this)}
                          addStepOptionsToCalculator={this.addStepOptionsToCalculator.bind(this)}
                          addAlert={addAlert}
                          change={change}
                          untouch={untouch}
                          handleNextStep={this.handleNextStep}
                          key={step.id}
                          options={this.getStepOptions(step)}
                          step={step}
                          stepIndex={stepIndex}
                          intl={intl}
                          isEditing={isEditing}
                          formValues={formValues}
                          manuallyChangedFormValues={manuallyChangedFormValues['formValuesProvidedByEdit']}
                          sessionToken={sessionToken}
                          stepsList={stepsList}
                          rememberTypedName={this.rememberTypedName.bind(this)}
                          jumpBackToStepIndex={this.jumpBackToStepIndex.bind(this)}
                          sources={sources}
                          sourceToEdit={sourceToEdit}
                          jumpingBackAStep={this.jumpingBackAStep}
                        />
                      )
                    }
                    return undefined
                  })
                }

                {stepErrorMessages &&
                  <FeedbackMessage type="error">
                    {stepErrorMessages}
                  </FeedbackMessage>
                }

                {this.renderLastStep()}
              </form>
              {windowWidth <= 768 && calculatedCarbon > 0 &&
                <Footer
                  isSmallWidth={true}
                  formValues={formValues}
                  change={change}
                  calculatedCarbon={calculatedCarbon}
                  calculatedPath={calculatedPath}
                  calculatedPathOfSourceToEdit={this.calculatedPathOfSourceToEdit}
                  nameOfSourceToEdit={this.nameOfSourceToEdit}
                  calculatedCarbonOfSourceToEdit={this.calculatedCarbonOfSourceToEdit}
                  isAdding={isAdding}
                  isEditing={isEditing}
                  isCalculating={isCalculating}
                  valid={valid}
                  sourceToEdit={sourceToEdit}
                  stepErrorMessages={Boolean(stepErrorMessages)}
                  calcErrorMessages={calcErrorMessages}
                  handleSubmit={handleSubmit}
                  handleUpdateSubmit={handleUpdateSubmit}
                  formSubmit={formSubmit}
                  isFetching={isFetching}
                  resetForm={resetForm}
                  stepsList={stepsList}
                />
              }
            </div>
            <div className="carbon-calculator__suggestions-container carbon-calculator__suggestions-container--mobile">
              <TransitionGroup>
                {suggestions}
              </TransitionGroup>
            </div>
          </Content>
          {windowWidth > 768 &&
            <Footer
              isSmallWidth={false}
              formValues={formValues}
              change={change}
              calculatedCarbon={calculatedCarbon}
              calculatedPathOfSourceToEdit={this.calculatedPathOfSourceToEdit}
              nameOfSourceToEdit={this.nameOfSourceToEdit}
              calculatedCarbonOfSourceToEdit={this.calculatedCarbonOfSourceToEdit}
              calculatedPath={calculatedPath}
              isAdding={isAdding}
              isEditing={isEditing}
              isCalculating={isCalculating}
              valid={valid}
              sourceToEdit={sourceToEdit}
              stepErrorMessages={Boolean(stepErrorMessages)}
              calcErrorMessages={calcErrorMessages}
              handleSubmit={handleSubmit}
              handleUpdateSubmit={handleUpdateSubmit}
              formSubmit={formSubmit}
              isFetching={isFetching}
              resetForm={resetForm}
              stepsList={stepsList}
            />
          }
        </Container>

        <CarbonSidebarContainer isSmallWidth={windowWidth <= 768} isSmallHeight={windowHeight <= 300} resetForm={resetForm}/>

      </Box>
    );
  }

  renderLastStep() {
    const { calculatedCarbon, change, intl, sources, sourceToEditName, sourceToEdit, manuallyChangedFormValues, formValues, calculatedPath} = this.props;
    if (calculatedCarbon > 0) {
      const lastStep = {
        id: 'last',
        data_type: 'last',
        pretext: intl.formatMessage(carbonMessages.calculatorLastStepBefore),
      };

      if (this.calculatedPathOfSourceToEdit === null && formValues.field_last){
        this.calculatedPathOfSourceToEdit = calculatedPath
        this.nameOfSourceToEdit = formValues.field_last
        this.calculatedCarbonOfSourceToEdit = calculatedCarbon
      }

      return <Step
        isMostRecentStep={true}
        rememberTypedName={this.rememberTypedName.bind(this)}
        manuallyChangedFormValues={manuallyChangedFormValues['formValuesProvidedByEdit']}
        formValues={formValues}
        typedSourceName={this.typedSourceName}
        addStepOptionsToCalculator={this.addStepOptionsToCalculator.bind(this)}
        sourceToEditName={sourceToEditName}
        sourceToEdit={sourceToEdit}
        sources={sources}
        step={lastStep}
        change={change}
        intl={intl}
        jumpingBackAStep={this.jumpingBackAStep}
      />
    }
  }

  calculatorIsLoadingSteps(){
    const {sourceToEdit} = this.props
    const {premadePath, editSourceLoaded} = this.state
    return (
      (sourceToEdit > 0 && !editSourceLoaded)
      ||
      (premadePath !== null && !editSourceLoaded)
    )
  }
}

Calculator.propTypes = {
  addAlert: PropTypes.func.isRequired,
  calcErrorMessages: PropTypes.string,
  calculateCarbon: PropTypes.func.isRequired,
  calculatedCarbon: PropTypes.number,
  change: PropTypes.func.isRequired,
  formSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  manuallyChangedFormValues: PropTypes.object,
  distanceConverstionsForEditDone: PropTypes.bool.isRequired,
  convertStepIndexToMiles: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleUpdateSubmit: PropTypes.func.isRequired,
  sourceToEdit: PropTypes.number,
  sourceToEditName: PropTypes.string,
  isAdding: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isCalculating: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  options: PropTypes.object.isRequired,
  requestStep: PropTypes.func.isRequired,
  stepErrorMessages: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  steps: PropTypes.array.isRequired,
  possibleNextStepValues: PropTypes.array,
  stepsList: PropTypes.array.isRequired,
  valid: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  sessionToken: PropTypes.string.isRequired,
  advices: PropTypes.array.isRequired,
  sources: PropTypes.array,
  resetForm: PropTypes.func,
  setFormValues: PropTypes.func
};

export default injectIntl(Calculator);
