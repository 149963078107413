import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import Popover from '../UI/Popover/Popover';

import './UserDropdown.css';
import { localizedLinkMessages } from '../../constants/messages';
import PartySwitcher from "./PartySwitcher"
import AvatarPlaceholder from '@reforestum/shared-components/dist/assets/images/avatar-placeholder.png'
import PartySwitcherCard from "./PartySwitcherCard"
import {
  getUserOrImpersonatingOrg,
  getUserOrOrgAppropriateNameToShow,
  getOrganizationRoleNameFromRoleId
} from "../../utils/usersAndOrganizations"
import getFeatureFlags from "../../constants/featureFlags"
import {urlSpecificAfterSwitchActions} from "../../utils/partySwitcher"

const ff = getFeatureFlags()

const UserDropdown = ({ intl, className, logout, user }) => (
  <Popover
    className={cx(className, 'user-dropdown')}
    direction="down"
    useArrow={false}
  >
    {
      ff.partySwitcher && <span>
        <PartySwitcherCard
          partyName={getUserOrOrgAppropriateNameToShow(getUserOrImpersonatingOrg(user))}
          partyRoleString={getOrganizationRoleNameFromRoleId(getUserOrImpersonatingOrg(user).role, intl)}
          avatar={getUserOrImpersonatingOrg(user).avatar_url || AvatarPlaceholder}
        />
        <div className="user-dropdown__divider" />
      </span>
    }
    <a href="/account/" className="user-dropdown__item">
      <FormattedMessage
        id={'Header.accountSettings'}
        defaultMessage={'Account settings'}
      />
    </a>
    {
      ff.partySwitcher && <span>
        <div className="user-dropdown__divider" />
        <PartySwitcher
          user={user}
          actionAfterSwitch={urlSpecificAfterSwitchActions}
        />
      </span>
    }
    <div className="user-dropdown__divider" />
    <Link
      to={{ pathname: intl.formatMessage(localizedLinkMessages.blog) }}
      target="_blank"
      className="user-dropdown__item">
      <FormattedMessage
        id={'tosMessages.blog'}
        defaultMessage={"Blog"}
      />
    </Link>
    <Link
      to={{ pathname: intl.formatMessage(localizedLinkMessages.tos) }}
      target="_blank"
      className="user-dropdown__item">
      <FormattedMessage
        id={'tosMessages.tosShort'}
        defaultMessage={"Terms of service"}
      />
    </Link>
    <Link
      to={{ pathname: intl.formatMessage(localizedLinkMessages.contact) }}
      target="_blank"
      className="user-dropdown__item">
      <FormattedMessage
        id={'externalLinks.contacts'}
        defaultMessage={"Contact Us"}
      />
    </Link>
    <div className="user-dropdown__divider" />
    <span onClick={logout} className="user-dropdown__item user-dropdown__item--destructive">
      <FormattedMessage
        id={'Header.logout'}
        defaultMessage={'Logout'}
      />
    </span>
  </Popover>
);

UserDropdown.propTypes = {
  className: PropTypes.string,
  currentlyActingAsOrganizationId: PropTypes.string,
  logout: PropTypes.func.isRequired,
  intl: intlShape,
  user: PropTypes.object.isRequired,
};

export default injectIntl(UserDropdown);
