import React from 'react'

import './BetaTooltip.css'

const BetaTooltip = ({className}) => {
    return (
        <span className={`beta__tooltip ${className}`}>Beta</span>
    )
}

export default BetaTooltip