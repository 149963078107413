import React, { Component } from 'react';
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Area,
} from 'recharts';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { carbonAmountIn } from '../../../utils/carbon';
import { getFormattedWeight, formatUnit } from '../../../utils/units';
import FormattedUnit from '../../Utils/FormattedUnit';
import { injectIntl, intlShape, FormattedMessage, FormattedNumber } from 'react-intl';

import GraphHeader from './GraphHeader';
import GraphCircle from './GraphCircle';
import GraphFooter from './GraphFooter';
import CustomizedYAxis, { calculateValueAndUnitFormatted } from '../../UI/rechart/CustomizedYAxis';

import './Graph.css';

import Text from '../../UI/Text/Text';
import HeadingSmall from '../../UI/Text/HeadingSmall';

import Tooltip from '../../UI/Tooltip/Tooltip';
import GraphCircleCurrentMoment from './GraphCircleCurrentMoment';
import { FIRST_PART_OF_GRAPH_FILL_ID, FIRST_PART_OF_GRAPH_STROKE_ID, getGraphLineFinalPartStroke, getGraphLineInitialPartFill, getGraphLineInitialPartStroke, LAST_PART_OF_GRAPH_STROKE_ID, updateGraphStroke } from '../../../utils/graph/graphAnimationHelper';

const SHOW_GRAPH_GRADIENT = true
const WORKER_ROUTE = '/workers/carbonAmountPerYear.js'

class Graph extends Component {
  constructor() {
    super();
    this.state = {
      carbonByYear: [],
      selectedYearIndex: 0,
      carbonGraphDefsId: `carbonGraphDefsId-${new Date().getTime()}`
    };

    this.renderHeader = this.renderHeader.bind(this);
    this.renderTooltip = this.renderTooltip.bind(this);

    //This variables are not in the state because setting them will interfere with the graph render
    //There is no need to store them in the state anyways as there is no need to force a rerender when they change
    this.activeDotCoordinates = null
    this.currentMomentGraphCoords = null
    this.percentageToShow = 0
  }

  formatDatapoints(datapoints) {
    const {plantingDatePeriod: currentMomentYear, formula, surface} = this.props
    const currentMomentCarbonAmount = 
    currentMomentYear
      ? carbonAmountIn(currentMomentYear, formula, surface)
      : null;

    let currentMomentDatapoint = undefined;
    let projectStartingYear = undefined;
    let newDatapoints = datapoints

    //Insert year 0 equal to 0 Tons if year = is not provided
    if(datapoints[0].year !== 0) {
      newDatapoints = [{year: 0, carbon: 0}, ...newDatapoints]
    }

    if(currentMomentYear) {
      //Insert current moment in dataPoints in its corresponding place if current moment is present
      newDatapoints.splice(Math.trunc(currentMomentYear) + 1 , 0, {year: currentMomentYear, carbon: currentMomentCarbonAmount})
      
      //Insert date to datapoints if currentMomentYear is present
      const currentDate = new Date()
      const ML_SECONDS_IN_A_YEAR = 31536000000 
      const yearsPassedSinceTheStartOfTheProjectInMlSeconds = currentMomentYear * ML_SECONDS_IN_A_YEAR
      const yearCeroDate = new Date(currentDate.getTime() - yearsPassedSinceTheStartOfTheProjectInMlSeconds)
      projectStartingYear = yearCeroDate.getFullYear()

      currentMomentDatapoint = {
        carbon: currentMomentCarbonAmount,
        year: currentMomentYear,
        date: currentDate
      }

      newDatapoints = newDatapoints.map((datapoint) => {
        return {
          ...datapoint,
          date: new Date(yearCeroDate.getTime() + (ML_SECONDS_IN_A_YEAR * datapoint.year))
        }
      })
    }

    return [newDatapoints, currentMomentDatapoint, projectStartingYear];
  }

  componentDidMount() {
    const { years, formula=null, surface } = this.props;
    if(formula) {
      this.setGraphData(years, formula, surface);
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps === this.props) return;

    if(newProps.formula) {
      this.setGraphData(newProps.years, newProps.formula, newProps.surface);
    }
  }

  setGraphData(years, formula, surface) {
    if (window.Worker) {
      this.setGraphDataByWorker(years, formula, surface)
      return;
    }

    let carbonByYear = [];
    let currentMomentDatapoint;
    let projectStartingYear;
    for (let i = 0; i < years; i++) {
      carbonByYear[i] = {
        'carbon': carbonAmountIn(i+1, formula, surface),
        'year': i+1
      };
    }

    [carbonByYear, currentMomentDatapoint, projectStartingYear] = this.formatDatapoints(carbonByYear)

    this.setState({
      carbonByYear,
      currentMomentDatapoint,
      selectedYearIndex: carbonByYear.length - 1,
      projectStartingYear,
    });
  }

  setGraphDataByWorker(years, formula, surface) {
    const carbonPerYearWorker = new Worker(process.env.PUBLIC_URL + WORKER_ROUTE);
    carbonPerYearWorker.onmessage = (e) => {
      let carbonByYear= e.data;
      let currentMomentDatapoint
      let projectStartingYear
      [carbonByYear, currentMomentDatapoint, projectStartingYear] = this.formatDatapoints(carbonByYear)
      this.setState({
        carbonByYear,
        currentMomentDatapoint,
        selectedYearIndex: carbonByYear.length - 1,
        projectStartingYear
      });
      carbonPerYearWorker.terminate();
    }
    carbonPerYearWorker.postMessage({ years, formula, surface });
  }

  selectYear(year) {
    this.setState({
      selectedYearIndex: year - 1
    });
  }

  getDateIn(years) {
    return new Date(
      new Date().setFullYear(
        new Date().getFullYear() + years
      )
    );
  }

  tickFormatter(year, totalYears, windowWidth) {
      const {projectStartingYear} = this.state
      const maxTicks = windowWidth > 600 ? 10 : 5;
      const interval = Math.ceil(totalYears / maxTicks);

      if (projectStartingYear) {
        const NUMBER_OF_YEARS_SHOWN = 4
        const currentTickYear = (year + projectStartingYear)
        
        //Array containing equidistant years to show, taking into account the NUMBER_OF_YEARS_SHOWN constant
        const yearsShownArray = [...Array(NUMBER_OF_YEARS_SHOWN).keys()].map(index => (projectStartingYear + Math.trunc(index * (totalYears) / (NUMBER_OF_YEARS_SHOWN - 1))))
        return (yearsShownArray.includes(currentTickYear)) ? currentTickYear : '';
      }

      return (year % interval === 0) ? year : '';
  }

  renderHeader(carbonAmount, carbonUnit) {
    const { forestName } = this.props
    return (
      <div className="impact-container-info__heading">
        <HeadingSmall>
            <FormattedMessage
                id={'Impact.welcomeTitle'}
                defaultMessage={'Since {forest}\'s first tree was planted, {carbonTons} of carbon were captured.'}
                values={{
                    forest: forestName,
                    carbonTons: <Text color="green" tag="span">
                    <FormattedNumber
                        value={carbonAmount || 0}
                        /> <FormattedUnit unit={carbonUnit || "t"} />
                    </Text>
                }}
            />
        </HeadingSmall>
        <div className='impact-container-info__heading-subtitle'>
          <FormattedMessage
            id={'Impact.headingSubtitle'}
            defaultMessage={'The following graph shows the estimated carbon captured during the project duration. You can hover or click on the graph to see the estimated carbon captured over time.'}
          />
          
        </div>
      </div>
    )
  }

  dynamicallyCalculateYAxisWidth(datapoints, locale) {
    //The ticks shown on the graph are going to be the point with more amount of carbon stored, and 1/4, 2/4, 3/4 of it
    const biggestAmountOfCarbon = Math.max.apply(0, datapoints.map((datapoint) => datapoint.carbon))

    const longestTickLength = Math.max.apply(0, [
      calculateValueAndUnitFormatted(biggestAmountOfCarbon, locale).length,
      calculateValueAndUnitFormatted(biggestAmountOfCarbon * 1 / 4, locale).length,
      calculateValueAndUnitFormatted(biggestAmountOfCarbon * 2 / 4, locale).length,
      calculateValueAndUnitFormatted(biggestAmountOfCarbon * 3 / 4, locale).length
    ])
    
    return longestTickLength * 9
  } 

  renderTooltip(data) {
    return (tooltipProps) => {
    const { intl } = this.props;
    const { currentMomentDatapoint, carbonByYear, carbonGraphDefsId } = this.state

    let tooltipCarbon
    let totalCo2
    let year
    let yearsSinceProjectStarted
    let cx
    let cy
    let isCurrentDate
    let isFirstHalfOfPoints = false
    let percentageToHiglight

    //This if/else statement, allows to print the selectedPoint tooltip data when hovering over the graph
    //and when not, it shows the current date (if present).
    if (tooltipProps.label !== undefined && tooltipProps.active) {
      const activeDot =  data.find(graphPoint => graphPoint.year === tooltipProps.label)
      tooltipCarbon = activeDot.carbon
      totalCo2 = getFormattedWeight(tooltipCarbon)
      yearsSinceProjectStarted = activeDot.year
      year = activeDot.date && activeDot.date.getFullYear()
      cx = this.activeDotCoordinates && this.activeDotCoordinates.cx
      cy = this.activeDotCoordinates && this.activeDotCoordinates.cy
      isFirstHalfOfPoints = (this.activeDotCoordinates.index / carbonByYear.length) < 0.5
      percentageToHiglight = (yearsSinceProjectStarted / data[data.length - 1].year) * 100
      this.percentageToShow = updateGraphStroke(percentageToHiglight, this.percentageToShow, carbonGraphDefsId, SHOW_GRAPH_GRADIENT)

    } else if(currentMomentDatapoint && this.currentMomentGraphCoords) {
      tooltipCarbon = currentMomentDatapoint.carbon
      totalCo2 = getFormattedWeight(tooltipCarbon)
      yearsSinceProjectStarted = currentMomentDatapoint.year
      year = currentMomentDatapoint.date && currentMomentDatapoint.date.getFullYear()
      cx = this.currentMomentGraphCoords.cx
      cy = this.currentMomentGraphCoords.cy
      isFirstHalfOfPoints = (this.currentMomentGraphCoords.index / carbonByYear.length) < 0.5
      percentageToHiglight = (yearsSinceProjectStarted / data[data.length - 1].year) * 100
      this.percentageToShow = updateGraphStroke(percentageToHiglight, this.percentageToShow, carbonGraphDefsId, SHOW_GRAPH_GRADIENT)

    } else {
      this.percentageToShow = updateGraphStroke(0, this.percentageToShow, carbonGraphDefsId, SHOW_GRAPH_GRADIENT)
      return null
    }

    //Check if active dot is current date
    isCurrentDate = (this.currentMomentGraphCoords && this.currentMomentGraphCoords.cx === cx)

    return (
      <div 
        className="carbon-graph__tooltip-container"
        //This style change will position the tooltip in the active dot
        style={{transform: `translate(${cx}px, ${cy}px)`}}
      >
        <Tooltip
          className={'carbon-graph__tooltip-content'}
          alwaysOpen
          style={{marginLeft: '4px'}}
          position={isFirstHalfOfPoints ? 'top-right' : 'top-left'}
          content={
            <div>
              { year ?
                <div className="carbon-graph__tooltip-year">
                  {isCurrentDate ? 
                    <FormattedMessage  
                      id={'Impact.graphToday'}
                      defaultMessage={'Today'}
                    /> 
                    : 
                    <FormattedMessage  
                      id={'Impact.graphCurrentYear'}
                      defaultMessage={'in {year}'}
                      values={{year: year}}
                    />
                  }
                </div>
                :
                //This is to give support to personal dashboard, where current year is not present
                <span className="carbon-graph__tooltip-year">
                  <FormattedMessage  
                    id={'Impact.graphYears'}
                    defaultMessage={'in {years} years'}
                    values={{years: yearsSinceProjectStarted}}
                  />
                </span>
              }
              <div className="carbon-graph__tooltip-value">
                {`${intl.formatNumber(totalCo2.value)} ${formatUnit(intl, totalCo2.unit)}`}
              </div>
            </div>
          }
        >
          <div className={'carbon-graph__tooltip'}></div>
        </Tooltip>
    </div>
    )
  }
}

  render() {
    const { 
      className,
      years,
      surface,
      vcu,
      showHeader,
      captured,
      formula,
      dataPoints=[],
      myTreesGraph,
      hideHeader,
      plantingDatePeriod,
      locale
    } = this.props;
    
    const { 
      carbonByYear,
      currentMomentDatapoint,
      carbonGraphDefsId,
    } = this.state;

    if (!carbonByYear) return false;

    const plantingDateCarbonAmount = 
      plantingDatePeriod
        ? carbonAmountIn(plantingDatePeriod, formula, surface)
        : null;
    
    const firstTreeCO2AmountObj = (plantingDateCarbonAmount) ? getFormattedWeight(plantingDateCarbonAmount) : 0;
    const firstTreeCO2AmountUnit = firstTreeCO2AmountObj.unit;
    const firstTreeCO2AmountValue = firstTreeCO2AmountObj.value;
    
    const dataPointsCalculated = dataPoints.map((carbon, i) => ({
      carbon,
      year: i
    }))

    return (
      <div className={cx(className, 'carbon-graph')}>

        {showHeader && <GraphHeader
          totalCarbon={captured}
          hasVCUs={vcu && vcu > 0}
          hasTrees={surface && surface > 0}
          className="carbon-graph__header"
          me={null}
        />}

        {dataPointsCalculated.length > 0 &&
          <div>
          <div className="carbon-graph__graph">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={dataPointsCalculated}>
                <defs id={carbonGraphDefsId}>
                  {getGraphLineInitialPartFill(0)}
                  {getGraphLineInitialPartStroke(0)}
                  {getGraphLineFinalPartStroke(0)}
                </defs>
                <RechartsTooltip  content={this.renderTooltip(dataPointsCalculated)} offset={20}/>
                <XAxis
                    tickLine={{stroke: 'white'}}
                    minTickGap={0}
                    tickSize={24}
                    axisLine={false}
                    tick={{ fontSize: '11px', fill: '#79897A', fontWeight: 500 }}
                    interval={0}
                    tickCount={0}
                    domain={['dataMin', 'dataMax']}
                    dataKey={'year'}
                    type={'number'}
                    tickFormatter={year => this.tickFormatter(year, years, window.innerWidth)}
                />
                <YAxis
                    width={this.dynamicallyCalculateYAxisWidth(dataPointsCalculated, locale)}
                    tickLine={{stroke: 'white'}}
                    minTickGap={0}
                    tickSize={24}
                    type="number"
                    dataKey="carbon"
                    axisLine={false}
                    tick={<CustomizedYAxis intlLocale={locale}/>}
                />
                <CartesianGrid
                    strokeDasharray="4 4"
                    vertical={false}
                    stroke="#E8EAE7"
                />
                <Area
                    fill='none'
                    stroke={`url(#${LAST_PART_OF_GRAPH_STROKE_ID})`}
                    strokeWidth={1}
                    strokeDasharray="2 5"
                    type="monotone"
                    dataKey="carbon"
                    isAnimationActive={false}
                    dot={false}
                    activeDot={(props) => {
                        this.activeDotCoordinates = {cx: props.cx, cy: props.cy}
                        return <GraphCircle {...props}/>
                      }
                    }
                />
                <Area
                    stroke={`url(#${FIRST_PART_OF_GRAPH_STROKE_ID})`}
                    fill={`url(#${FIRST_PART_OF_GRAPH_FILL_ID})`}
                    type="monotone"
                    dataKey="carbon"
                    isAnimationActive={false}
                    strokeWidth={1}
                    activeDot={false}
                    dot={false}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          </div>
        }
        {/* @NOTE: KEEP WHILE THE DASHBOARD ENDPOINT IS NOT PROVIDING THE dataPoints */}
        { !!formula && !dataPointsCalculated.length > 0 &&
        <div>
          {/* @NOTE: the header is implemented in the Graph files for accessing carbon for year 1 */}
          <div>
          {carbonByYear && carbonByYear.length > 0 && !myTreesGraph && !hideHeader && this.renderHeader(firstTreeCO2AmountValue, firstTreeCO2AmountUnit)}
          </div>
          <div className="carbon-graph__graph">
          <text className='carbon-graph-general-label'>
            <FormattedMessage  
              id={'CarbonGraph.generalLabel'}
              defaultMessage={'Estimated CO₂ captured'}
            /> 
          </text>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={carbonByYear} >
              <defs id={carbonGraphDefsId}></defs>
                <RechartsTooltip  wrapperStyle={{visibility: 'visible'}} content={this.renderTooltip(carbonByYear)}/>
                <XAxis
                    tickLine={{stroke: 'white'}}
                    minTickGap={0}
                    tickSize={24}
                    axisLine={false}
                    tick={{ fontSize: '11px', fill: '#79897A', fontWeight: 500 }}
                    interval={0}
                    tickCount={0}
                    domain={['dataMin', 'dataMax']}
                    dataKey={'year'}
                    type={'number'}
                    tickFormatter={year => this.tickFormatter(year, years, window.innerWidth)}
                />
                <YAxis
                    width={this.dynamicallyCalculateYAxisWidth(carbonByYear, locale)}
                    tickLine={{stroke: 'white'}}
                    minTickGap={0}
                    tickSize={24}
                    type="number"
                    dataKey="carbon"
                    axisLine={false}
                    tick={<CustomizedYAxis intlLocale={locale}/>}
                />
                <CartesianGrid
                    strokeDasharray="1 1"
                    vertical={false}
                    stroke="#E8EAE7"
                />
                {/* We add two ovelapping lines to the graph to have the effect of a dotted line from the active dot to the end of the graph*/}
                <Area
                    stroke={`url(#${LAST_PART_OF_GRAPH_STROKE_ID})`}
                    fill='none'
                    type="monotone"
                    dataKey="carbon"
                    isAnimationActive={false}
                    dot={(props) => {
                      //This is used to find the coordinates of the currentDate dataPoint and store it to use it to
                      //render as default the current date datapoint tooltip at the correct location  
                      if(props.payload.year === currentMomentDatapoint.year) {
                        this.currentMomentGraphCoords = {cx: props.cx, cy: props.cy, index: props.index}
                        return <GraphCircleCurrentMoment {...props}/>
                      }
                    }}
                    strokeWidth={1}
                    strokeDasharray="2 5"
                    activeDot={(props) => {
                      this.activeDotCoordinates = {cx: props.cx, cy: props.cy, index: props.index}
                      return <GraphCircle {...props}/>
                    }}
                />
                <Area
                    stroke={`url(#${FIRST_PART_OF_GRAPH_STROKE_ID})`}
                    fill={`url(#${FIRST_PART_OF_GRAPH_FILL_ID})`}
                    type="monotone"
                    dataKey="carbon"
                    isAnimationActive={false}
                    strokeWidth={1}
                    activeDot={false}
                    dot={false}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
        }
        <GraphFooter
          getDateIn={this.getDateIn.bind(this)}
          carbonByYear={carbonByYear}
          dataPointsCalculated={dataPointsCalculated}
          surface={surface}
          vcu={vcu}
          years={years}
          myTreesGraph={myTreesGraph}
          hideHeader={hideHeader}
          firstTreeCO2AmountValue={firstTreeCO2AmountValue}
          firstTreeCO2AmountUnit={firstTreeCO2AmountUnit}
        />

      </div>
    );
  }
}

Graph.propTypes = {
  captured: PropTypes.number,
  className: PropTypes.string,
  formula: PropTypes.string,
  surface: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  datapoints: PropTypes.array,
  vcu: PropTypes.number,
  years: PropTypes.number.isRequired,
  me: PropTypes.bool,
  intl: intlShape.isRequired,
  showHeader: PropTypes.bool,
  isMySharesGraph: PropTypes.bool,
  renderHeader: PropTypes.func
};

export default injectIntl(Graph);
