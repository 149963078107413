import React from 'react'

const Share = props => (
	<svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M15 6.66667C16.3807 6.66667 17.5 5.54739 17.5 4.16667C17.5 2.78596 16.3807 1.66667 15 1.66667C13.6193 1.66667 12.5 2.78596 12.5 4.16667C12.5 5.54739 13.6193 6.66667 15 6.66667Z" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M5 12.5C6.38071 12.5 7.5 11.3807 7.5 10C7.5 8.6193 6.38071 7.50001 5 7.50001C3.61929 7.50001 2.5 8.6193 2.5 10C2.5 11.3807 3.61929 12.5 5 12.5Z" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M15 18.3333C16.3807 18.3333 17.5 17.2141 17.5 15.8333C17.5 14.4526 16.3807 13.3333 15 13.3333C13.6193 13.3333 12.5 14.4526 12.5 15.8333C12.5 17.2141 13.6193 18.3333 15 18.3333Z" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M7.15833 11.2583L12.85 14.575" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M12.8417 5.42501L7.15833 8.74167" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
	</svg>
)

export default Share;
