import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import GraphLegendLine from '../../UI/Icons/GraphLegendLine';
import GraphLegendDot from '../../UI/Icons/GraphLegendDot';
import Tooltip from '../../UI/Tooltip/Tooltip';
import Information from '../../UI/Icons/Information';
import { getGraphLegendMessageText, getGraphLegendMessageTooltip } from '../../../utils/getGraphLegend';
import PropTypes from 'prop-types';

const MRVGraphFooter = ({mrvGraph: {mrvGraph}, intl}) => {
  const carbonLegendId = mrvGraph && mrvGraph["legend_id"];
  const graphLengendText = carbonLegendId && getGraphLegendMessageText(intl, carbonLegendId);
  const graphLengendTooltip = carbonLegendId && getGraphLegendMessageTooltip(intl, carbonLegendId);
  return (
    <div className="mrv-graph-footer">
      {graphLengendText && <div className="mrv-graph-footer-item">
        <GraphLegendLine />
        <span className="mrv-graph-footer-item-text">
          {graphLengendText}
        </span>
        {graphLengendTooltip && <Tooltip
            style={{marginLeft: '4px'}}
            size='medium'
            position='top'
            content={graphLengendTooltip}
        >
            <Information/>
        </Tooltip>}
      </div>}
      <div className="mrv-graph-footer-item">
        <GraphLegendDot/>
        <span className="mrv-graph-footer-item-text">
          <FormattedMessage
              id={'MRVGraph.vcsIssuance'}
              defaultMessage={'VCS Issuance'}
          />
        </span>
        <Tooltip
            style={{marginLeft: '4px'}}
            size='medium'
            position='top'
            content={
                <FormattedMessage 
                    id="MRVGraph.vcsIssuanceTooltip"
                    defaultMessages={'Dates when Verra audits the project according to VCS standards and issues carbon credits.'}
                /> 
            }
        >
            <Information/>
        </Tooltip>
      </div>
    </div>
  );
};

MRVGraphFooter.propTypes = {
  mrvGraph: PropTypes.object.isRequired,
};

export default injectIntl(MRVGraphFooter);
