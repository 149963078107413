// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table'

//Import columns and styles
import { getTransactionsColumns } from '../../../utils/transactionsColumns/transactionsColumns';
import '../../../utils/transactionsColumns/transactionsColumns.css'

// Styles
import './TransactionsInProjectSection.css';
import './MyForestSummary.css'
import Heading from '../../UI/Text/Heading';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import HorizontalScrollWithArrowsContainer from '../../UI/HorizontalScrollWIthArrowsContainer/HorizontalScrollWIthArrowsContainer';
import { Button } from '@reforestum/shared-components';
import RoundedWhitePlus from '../../UI/Icons/RoundedWhitePlus';
import {getUserOrImpersonatingOrg} from "../../../utils/usersAndOrganizations"
import {getUserDetails} from "../../../selectors/userSession"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"
import {transactionsInProjectSection} from "../../../constants/messages"
import StandardFormModal from "../../UI/Modal/StandardFormModal"
import {REGISTRY_GOLD_STANDARD, REGISTRY_MITERD} from '../../../constants/registries';

const TRANSACTIONS_PREVIEW_LENGTH = 1


class TransactionsInProjectSection extends Component {

  constructor(props) {
    const {organizationPrivateDashboardMode, transactions} = props
    super(props)

    const shouldShowCollapsableButton = !organizationPrivateDashboardMode && transactions.length > TRANSACTIONS_PREVIEW_LENGTH
    this.state = {
      collapsed: shouldShowCollapsableButton,
      shouldShowCollapsableButton: shouldShowCollapsableButton,
      modalToDisplay: null
    }
  }

  getModals() {
    const {intl} = this.props
    const modalsCommonProperties = {
      heading: intl.formatMessage(transactionsInProjectSection.genericContactFormHeading),
      description: intl.formatMessage(transactionsInProjectSection.genericContactFormInstructions),
      handleClose: () => this.setState({modalToDisplay: null}),
      submitCallback: () => this.setState({modalToDisplay: null}),
      additionalContext: {partyId: getUserOrImpersonatingOrg(this.props.user.id)},
      successAlertSettings: {
        type: 'success',
          message: this.props.intl.formatMessage(transactionsInProjectSection.genericSuccessMessage),
          dismissAfter: 8000
      }
    }
    const modals = {
      common: {
        ...modalsCommonProperties
      },
      addTransaction: {
        id: 'addTransaction',
        hubspotFormActionName: 'add-organization-transaction',
        ...modalsCommonProperties
      },
      askForVerificationOfTransaction: {
        hubspotFormActionName: 'request-verification-organization-transaction',
        id: 'askForVerificationOfTransaction',
        ...modalsCommonProperties
      },
      recoverTransaction: {
        hubspotFormActionName: 'modify-organization-transaction',
        id: 'recoverTransaction',
        ...modalsCommonProperties
      },
      rejectTransaction: {
        hubspotFormActionName: 'remove-organization-transaction',
        id: 'rejectTransaction',
        ...modalsCommonProperties
      }
    }
    return modals
  }

  newTransactionButtonPressHandler() {
    this.setState({modalToDisplay: this.getModals().addTransaction.id})
  }

  verificationButtonPressHandler() {
    this.setState({modalToDisplay: this.getModals().askForVerificationOfTransaction.id})
  }

  rejectButtonPressHandler() {
    this.setState({modalToDisplay: this.getModals().rejectTransaction.id})
  }

  getPrivateModeTransactionsColumns() {
    const {registry, intl} = this.props
    const transactionsColumns =  getTransactionsColumns(intl, () => this.verificationButtonPressHandler(), () => this.rejectButtonPressHandler())
    if(registry === REGISTRY_MITERD) {
      return transactionsColumns.orgsDashboardMiterdProjectTransactionsColumns
    } else if (registry === REGISTRY_GOLD_STANDARD) {
      return transactionsColumns.orgsDashboardGoldStandardProjectTransactionsColumns
    } else {
      return transactionsColumns.orgsDashboardVerraProjectTransactionsColumns
    }
  }

  getPublicModeTransactionsColumns() {
    const {registry, intl} = this.props
    const transactionsColumns =  getTransactionsColumns(intl, () => this.verificationButtonPressHandler(), () => this.rejectButtonPressHandler())
    if(registry === REGISTRY_MITERD) {
      return transactionsColumns.publicDashboardMiterdProjectTransactionsColumns
    } else if (registry === REGISTRY_GOLD_STANDARD) {
      return transactionsColumns.orgsDashboardGoldStandardProjectTransactionsColumns
    } else {
      return transactionsColumns.publicDashboardVerraProjectTransactionsColumns
    }
  }

  render() {
    const {transactions, organizationPrivateDashboardMode, registry} = this.props
    const {collapsed, shouldShowCollapsableButton, modalToDisplay} = this.state

    const specificTransactionsColumns = organizationPrivateDashboardMode ? this.getPrivateModeTransactionsColumns() : this.getPublicModeTransactionsColumns()

    return (
        <section className="my-forest-summary__section transactions-in-project-section-wrapper">
          {
            modalToDisplay && <StandardFormModal
              hideFirstName
              hideLastName
              intl={this.props.intl}
              action={this.getModals()[modalToDisplay].hubspotFormActionName}
              heading={
                <span>{this.getModals()[modalToDisplay].heading}</span>
              }
              description={
                <span>{this.getModals()[modalToDisplay].description}</span>
              }
              handleClose={this.getModals()[modalToDisplay].handleClose}
              submitCallback={this.getModals()[modalToDisplay].submitCallback}
              additionalContext={this.getModals()[modalToDisplay].additionalContext}
              successAlertSettings={this.getModals()[modalToDisplay].successAlertSettings}
            />
          }
          <div className='transactions-in-project-section-title-and-button-wrapper'>
            <Heading className={'my-forest-summary-project-description-title transactions-in-project-section-title'}>
              {
                registry === REGISTRY_GOLD_STANDARD
                  ? <FormattedMessage
                    id={'OrgTransactionsInProject.titleGoldStandard'}
                    defaultMessage={'Transactions in Gold Standard registry'}
                  />
                  : (
                    registry === REGISTRY_MITERD
                      ? <FormattedMessage
                        id={'OrgTransactionsInProject.titleMiterd'}
                        defaultMessage={'Transactions in MITERD registry'}
                      />
                      : <FormattedMessage
                        id={'OrgTransactionsInProject.title'}
                        defaultMessage={'Transactions in Verra registry'}
                      />
                  )
              }
            </Heading>
            {organizationPrivateDashboardMode && <Button onClick={() => this.newTransactionButtonPressHandler()}>
              <RoundedWhitePlus className="public-dashboard-new-add-project-button-icon"/>
              <FormattedMessage
                id={'OrgTransactionsInProject.addNewTransactionButton'}
                defaultMessage={'Add new transaction'}
              />
            </Button>}
          </div>
            <HorizontalScrollWithArrowsContainer overflowYVisible className='transactions-table-container transactions-in-project-section-table-container'>
            <div style={{overflow: "visible"}}>
              <ReactTable
                data={collapsed ? transactions.slice(0, TRANSACTIONS_PREVIEW_LENGTH + 1) : transactions}
                columns={specificTransactionsColumns}
                showPagination={false}
                sortable={false}
                minRows={0}
                loadingText={null}
              />
              </div>
            </HorizontalScrollWithArrowsContainer>
            {collapsed && 
              <div className='transactions-in-project-section-show-all-container'>
                <Button onClick={() => this.setState({collapsed: false})} className="btn--blank transactions-in-project-section-show-all-less-button" big>
                  <FormattedMessage
                    id={'OrgTransactionsInProject.seeAllTransactionsButton'}
                    defaultMessage={'See all transactions'}
                  />
                </Button>
              </div>
            }
            {shouldShowCollapsableButton && !collapsed &&  
              <div className='transactions-in-project-section-show-less-container'>
                <Button onClick={() => this.setState({collapsed: true})} className="btn--blank transactions-in-project-section-show-all-less-button" big>
                  <FormattedMessage
                    id={'OrgTransactionsInProject.seeLessTransactionsButton'}
                    defaultMessage={'See less transactions'}
                  />
                </Button>
              </div>
            }
        </section> 
    )
  }
}

const mapStateToProps = state => ({
  user: getUserDetails(state)
})

TransactionsInProjectSection.propTypes = {
    organizationPrivateDashboardMode: PropTypes.bool,
    transactions: PropTypes.array,
    registry: PropTypes.string,
    intl: intlShape,
};

export default connect(
  mapStateToProps,
)(
  injectIntl(withRouter(TransactionsInProjectSection))
)
