import React, { Component } from 'react';
import { connect } from 'react-redux';
import IframeResizable from '../components/UI/IframeResizable/IframeResizable';
import IframeResizableFullPageContainer from '../components/UI/IframeResizable/IframeResizableFullPageContainer';
import { MARKET_INSIGHTS_METABASE_DASHBOARD } from '../constants/metabaseDashboards';

class MarketInsightsContainer extends Component {

  render() {
    return (
      <IframeResizableFullPageContainer>
        <IframeResizable
          id="merketInsights"
          title='VCM insights'
          src={MARKET_INSIGHTS_METABASE_DASHBOARD}
        />
      </IframeResizableFullPageContainer>
    )
  }
}

const mapStateToProps = (state) => ({
});

MarketInsightsContainer.propTypes = {
};

export default connect(mapStateToProps, {
})(MarketInsightsContainer);
