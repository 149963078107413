import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'react-layout-components';
import { injectIntl, intlShape } from 'react-intl';

import Container from '../Layout/Container/Container';
import Content from '../Layout/Container/Content';
import Greeting from './Greeting'

import Huge from '../UI/Text/Huge';
import Carbon from '../Widgets/CarbonBalance/Carbon';
import Graph from '../Widgets/CarbonGraph/Graph';
import CarbonSidebarContainer from '../../containers/CarbonSidebarContainer';

import './Dashboard.css';
import { links } from '@reforestum/shared-utils';
import PartyInUseInformation from "../Checkout/PartyInUseInformation"
import NotLoggedUserDashboardBody from './NotLoggedUserDashboardBody/NotLoggedUserDashboardBody';
import getFeatureFlags from '../../constants/featureFlags';

const Dashboard = ({
  emitted,
  captured,
  oxygen,
  formula=null,
  isAuthed,
  user,
  surface,
  vcu,
  dataPoints,
  intl
}) => {
  const footprint = captured - emitted;
  const { locale } = intl;

  const renderLoggedUserDashboard = () => (
    <Content className="dashboard">
          <div className="dashboard__message">
            {
              (user.organizations && user.organizations.length > 0) &&
              <PartyInUseInformation user={user}/>
            }
            <Huge>
              {
                captured === 0 && emitted === 0
                  ? (<Greeting
                        isAuthed={isAuthed}
                        user={user}
                        status={'neutral'}
                        link={links.getCalculatorUrl()}
                    />)
                  : footprint >= 0
                    ? (<Greeting
                          isAuthed={isAuthed}
                          user={user}
                          status={'positive'}
                          link={links.getCalculatorUrl()}
                      />)
                    : (<Greeting
                          isAuthed={isAuthed}
                          user={user}
                          status={'negative'}
                          link={links.getCreateForestUrl()}
                      />)
              }
            </Huge>
          </div>
          <Box className="dashboard__data">
            <Carbon
              emitted={emitted}
              captured={captured}
              oxygen={oxygen}
              className="dashboard__carbon"
            />
            {captured > 0 && (
                <Graph
                  captured={captured}
                  years={25}
                  formula={formula}
                  surface={surface}
                  vcu={vcu}
                  className="dashboard__graph"
                  showHeader
                  dataPoints={dataPoints}
                  locale={locale}
                />
              )}
          </Box>
        </Content>
  )

  const featureFlags = getFeatureFlags()
  return (
    <Box className="carbon-calculator" style={{overflow: "auto"}} width="100%">
      <Container>
        {isAuthed ? renderLoggedUserDashboard() : (featureFlags.alternativeDashboardNotLoggedUsers ? <NotLoggedUserDashboardBody /> : renderLoggedUserDashboard())}
      </Container>

      <CarbonSidebarContainer isAuthed={featureFlags.alternativeDashboardNotLoggedUsers? isAuthed : true} dashboard={true} />
    </Box>
  );
};

Dashboard.propTypes = {
  captured: PropTypes.number.isRequired,
  emitted: PropTypes.number.isRequired,
  dataPoints: PropTypes.array,
  formula: PropTypes.string,
  isAuthed: PropTypes.bool.isRequired,
  oxygen: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  surface: PropTypes.number.isRequired,
  vcu: PropTypes.number.isRequired,
  intl: intlShape
};

export default injectIntl(Dashboard);
