import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Box } from 'react-layout-components';
import { TabList, Tab, TabPanel } from 'react-tabs';
import { FormattedNumber, FormattedMessage } from 'react-intl';

import Sidebar from '../Layout/Sidebar/Sidebar';
import Content from '../Layout/Container/Content';
import Bottom from '../Layout/Container/Bottom';
import CarbonSearch from './CarbonSearch';
import EmptyCarbon from './EmptyCarbon';
import CarbonList from './CarbonList';
import ForestList from './ForestList';
import ErrorFetching from '../UI/Interface/ErrorFetching';
import CarbonItemLoading from './CarbonItemLoading';
import HeadingSmall from '../UI/Text/HeadingSmall';
import Uppercase from '../UI/Text/Uppercase';
import Text from '../UI/Text/Text';
import { ButtonLink } from '@reforestum/shared-components';
import { DisabledButton } from '@reforestum/shared-components';
import Tabbed from '../UI/Tabs/Tabbed';
import SearchIcon from '../UI/Icons/Search';
import Token from '../UI/Text/Token';
import IconText from '../UI/Text/IconText';
import OrangePlus from '../UI/Icons/OrangePlus';
import GreenPlus from '../UI/Icons/GreenPlus';
import SidebarToggle from '../UI/SidebarToggle/SidebarToggle';

import { getFormattedWeight } from '../../utils/units';

import './CarbonSidebar.css';
import FormattedUnit from '../Utils/FormattedUnit';
import { links } from '@reforestum/shared-utils';

const CarbonSidebar = ({
  className,
  currentlySelected,
  deleteItem,
  editItem,
  errorMessages,
  fetchSources,
  filter,
  isAdding,
  isFetching,
  isRemoving,
  isEditing,
  isSearchOpen,
  toggleSearch,
  isUpdating,
  selectItem,
  setFilter,
  sources,
  totalCarbon,
  updateFrequency,
  isFetchingForests,
  forests,
  dashboard,
  isSmallWidth,
  isSmallHeight,
  resetForm
}) => {

  const renderTitleCloseAndSearch = () => <Box
    className="carbon-sidebar__title">

    <HeadingSmall>
      {dashboard ?
        <Token
          content={sources.length.toString()}
          color="green"
        >
          <FormattedMessage
            id={'CarbonSidebar.title'}
            defaultMessage={'Your CO₂ emissions'}
          />
        </Token>
        :
        <FormattedMessage
          id={'CarbonSidebar.title'}
          defaultMessage={'Your CO₂ emissions'}
        />
      }
    </HeadingSmall>

    <div className={'carbon-sidebar-search-and-close'}>
      {
        !dashboard && sources.length > 0 &&
        <Box
          className={cx(
            'carbon-sidebar__search',
            { 'carbon-sidebar__search--active': isSearchOpen }
          )}
          onClick={toggleSearch}
        >
          <SearchIcon />
        </Box>
      }

      {
        (!dashboard && isSmallWidth) && <SidebarToggle className={'carbon-sidebar__close-sidebar'}/>
      }
    </div>

    {!dashboard && isSmallWidth &&
    <Box column className="carbon-sidebar__total">
      <Uppercase
        color="light">
        <FormattedMessage
          id={'CarbonSidebar.total'}
          defaultMessage={'Total CO₂ Emitted'}
        />
      </Uppercase>
      <Text>
        <FormattedNumber
          value={getFormattedWeight(totalCarbon).value}
        /> <FormattedUnit unit={getFormattedWeight(totalCarbon).unit} />
      </Text>
    </Box>
    }
  </Box>

  if(isSmallHeight) return null
  return (
    <Sidebar
      isSearchOpen={isSearchOpen}
      toggleSearch={toggleSearch}
      className={cx(
        className,
        {
          'pointer-disabled':
            isAdding || isFetching || isRemoving || isUpdating || isEditing
        }
      )}
      style={{ opacity: isRemoving || isEditing || isUpdating ? 0.5 : 1 }}
    >

      {dashboard &&
      <Box
        className="carbon-sidebar__dashboard-header">
        <HeadingSmall>
          <FormattedMessage
            id={'Dashboard.overview'}
            defaultMessage={'Overview'}
          />
        </HeadingSmall>
        <SidebarToggle />
      </Box>
      }

      <Content className="carbon-sidebar__main">

        {dashboard && forests &&
        <Box column>
          <Box
            className="carbon-sidebar__title">

            <HeadingSmall>
              <Token
                content={forests.length.toString()}
                color="green"
              >
                <FormattedMessage
                  id={'ForestSidebar.title'}
                  defaultMessage={'My Forests'}
                />
              </Token>
            </HeadingSmall>
          </Box>

          <div className="carbon-sidebar__content">
            {isFetchingForests && forests.length <= 0 ?
              <Box column>
                <CarbonItemLoading />
                <CarbonItemLoading />
              </Box>
              :
              <ForestList
                forests={forests}
              />
            }
            <Link to={links.getCreateForestUrl()} className="carbon-sidebar__add">
              <IconText icon={GreenPlus}>
                <Text color="green">
                  {forests.length > 0 ?
                    <FormattedMessage
                      id={'ForestSources.extend'}
                      defaultMessage={'Extend forest'}
                    />
                    :
                    <FormattedMessage
                      id={'ForestSources.create'}
                      defaultMessage={'Create forest'}
                    />
                  }
                </Text>
              </IconText>
            </Link>
          </div>

        </Box>

        }

        {dashboard &&
        <hr className="carbon-sidebar__divider" />
        }

        {renderTitleCloseAndSearch()}

        {
          isSearchOpen &&
          <CarbonSearch
            setFilter={setFilter}
            placeholder="Search sources"
            filter={filter}
          />
        }

        <div className="carbon-sidebar__content">
          {isFetching && sources.length <= 0 ?
            <Box column>
              <CarbonItemLoading />
              <CarbonItemLoading />
              <CarbonItemLoading />
            </Box>
            :
            sources.length <= 0 ? <EmptyCarbon /> :
              <Tabbed theme="carbon">
                <TabList>
                  <Tab>
                    <Token
                      content={sources.filter((el) => !el.offset && el.periodicity < 1).length.toString()}
                      color="green"
                    >
                      <FormattedMessage
                        id={'Carbon.notOffset'}
                        defaultMessage={'To offset'}
                      />
                    </Token>
                  </Tab>
                  <Tab>
                    <Token
                      content={sources.filter((el) => el.offset && el.periodicity < 1).length.toString()}
                      color="green"
                    >
                      <FormattedMessage
                        id={'Carbon.offset'}
                        defaultMessage={'Offset'}
                      />
                    </Token>
                  </Tab>
                  <Tab>
                    <Token
                      content={sources.filter((el) => el.periodicity > 0).length.toString()}
                      color="green"
                    >
                      <FormattedMessage
                        id={'Carbon.recurring'}
                        defaultMessage={'Recurring'}
                      />
                    </Token>
                  </Tab>
                </TabList>

                <TabPanel>
                  <CarbonList
                    isAdding={isAdding}
                    isFetching={isFetching}
                    sources={sources.filter((el) => !el.offset && el.periodicity < 1)}
                    currentlySelected={currentlySelected}
                    selectItem={selectItem}
                    deleteItem={deleteItem}
                    editItem={editItem}
                    updateFrequency={updateFrequency}
                    setFilter={setFilter}
                    isSearchOpen={isSearchOpen}
                    type="toOffset"
                    dashboard={dashboard}
                    resetForm={resetForm}
                  />
                </TabPanel>

                <TabPanel>
                  <CarbonList
                    isAdding={isAdding}
                    isFetching={isFetching}
                    sources={sources.filter((el) => el.offset && el.periodicity < 1)}
                    currentlySelected={currentlySelected}
                    selectItem={selectItem}
                    deleteItem={deleteItem}
                    editItem={editItem}
                    updateFrequency={updateFrequency}
                    setFilter={setFilter}
                    isSearchOpen={isSearchOpen}
                    type="offset"
                    dashboard={dashboard}
                    resetForm={resetForm}
                  />
                </TabPanel>

                <TabPanel>
                  <CarbonList
                    isAdding={isAdding}
                    isFetching={isFetching}
                    sources={sources.filter((el) => el.periodicity > 0)}
                    currentlySelected={currentlySelected}
                    selectItem={selectItem}
                    deleteItem={deleteItem}
                    editItem={editItem}
                    updateFrequency={updateFrequency}
                    setFilter={setFilter}
                    isSearchOpen={isSearchOpen}
                    type="recurring"
                    dashboard={dashboard}
                    resetForm={resetForm}
                  />
                </TabPanel>

              </Tabbed>
          }

          {dashboard &&
          <Link to={links.getCalculatorUrl()} className="carbon-sidebar__add">
            <IconText icon={OrangePlus}>
              <Text color="orange">
                <FormattedMessage
                  id={'CarbonSources.addMore'}
                  defaultMessage={'Add CO₂ source'}
                />
              </Text>
            </IconText>
          </Link>
          }

          {errorMessages &&
          <ErrorFetching retry={fetchSources} />
          }
        </div>
      </Content>

      {
        !dashboard &&
        <Bottom>
          <Box
            flexBasis="100%"
            className={"carbon-sidebar__bottom"}>
            <Box column>
              <Uppercase
                className={'t-uppercase-with-padding'}
                color="light">
                <FormattedMessage
                  id={'CarbonSidebar.total'}
                  defaultMessage={'Total CO₂ Emitted'}
                />
              </Uppercase>
              <Text>
                <FormattedNumber
                  value={getFormattedWeight(totalCarbon).value}
                /> <FormattedUnit unit={getFormattedWeight(totalCarbon).unit} />
              </Text>
            </Box>

            {
              totalCarbon > 0 ?
                <ButtonLink
                  to={{
                    pathname: links.getCreateForestUrl(),
                    state: { selectAll: true }
                  }} >
                  <FormattedMessage
                    id={'CarbonSidebar.offset'}
                    defaultMessage={'Offset sources'}
                  />
                </ButtonLink>
                :
                <DisabledButton>
                  <FormattedMessage
                    id={'CarbonSidebar.offset'}
                    defaultMessage={'Offset sources'}
                  />
                </DisabledButton>
            }
          </Box>
        </Bottom>
      }

    </Sidebar>
  );
};

CarbonSidebar.propTypes = {
  className: PropTypes.string,
  currentlySelected: PropTypes.number,
  deleteItem: PropTypes.func.isRequired,
  editItem: PropTypes.func.isRequired,
  errorMessages: PropTypes.string,
  fetchSources: PropTypes.func.isRequired,
  isAdding: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isRemoving: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  selectItem: PropTypes.func.isRequired,
  sources: PropTypes.array.isRequired,
  totalCarbon: PropTypes.number.isRequired,
  updateFrequency: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  isSearchOpen: PropTypes.bool.isRequired,
  toggleSearch: PropTypes.func.isRequired,
  filter: PropTypes.string,
  dashboard: PropTypes.bool,
  isFetchingForests: PropTypes.bool.isRequired,
  forests: PropTypes.array,
  resetForm: PropTypes.func
};

export default CarbonSidebar;
