import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { FormattedMessage, intlShape } from 'react-intl';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en';
import fr from 'date-fns/locale/fr';

import Text from '../Text/Text';
import Certification from '../Icons/Certification';
import Planted from '../Icons/Planted';

import getFeatureFlags from '../../../constants/featureFlags';

import './Tags.css';
import Tooltip from "../Tooltip/Tooltip"
import Information from "../Icons/Information"
import { getProjectTypeDetails, isAfforestationLikeProject } from '../../../utils/getProjectTypeDetails';
import { REGISTRY_MITERD, REGISTRY_VERRA } from '../../../constants/registries';
import { OFFSET_TYPE_CREDIT } from '../../../constants/offsetTypes';
const ff = getFeatureFlags()

const Tags = (props) => {
    const {
        planting_date, 
        certifications,
        reforestumProjectType,
        projectType,
        offsetType,
        registry,
        projectTypeTooltipPosition,
        tagBackground,
        intl,
    } = props

    const locales = { en, es, fr };

    const plantingDateParsed = planting_date ? format(planting_date, 'MMMM YYYY', { locale: locales[intl.locale] }) : null

    const projectTypeDetails = getProjectTypeDetails(projectType, intl)

    const showFirstVerifiedDate = planting_date && (registry === REGISTRY_VERRA)
    const showEstablishedIn = planting_date && (offsetType === OFFSET_TYPE_CREDIT) && (registry === REGISTRY_MITERD)
    const showPlantedIn =  (!showFirstVerifiedDate && !showEstablishedIn) && (planting_date && isAfforestationLikeProject(projectType))

    return (
        <div className={'forest-profile-tags__wrapper'} key={`${JSON.stringify(certifications)}-${JSON.stringify(planting_date)}`}>
            {reforestumProjectType && reforestumProjectType.title && reforestumProjectType.description &&    
                <div className={cx("tag-projectType__wrapper", tagBackground && `tag-projectType__wrapper--${tagBackground}`)}>
                    <Text className="tag-projectType__text">{reforestumProjectType.title}</Text>
                    <Tooltip
                        content={reforestumProjectType.description}
                        position={'bottom'}
                    >
                    <Information className="tag-information__icon"/>
                    </Tooltip>
                </div>
            }
            <div className={cx("tag-forestType__wrapper", tagBackground && `tag-forestType__wrapper--${tagBackground}`)}>
                <Text className="tag-forestType__text">
                 {projectTypeDetails.tagText}
                </Text>
                {projectTypeDetails.tagTooltip && <Tooltip
                        content={projectTypeDetails.tagTooltip}
                        position={projectTypeTooltipPosition ? projectTypeTooltipPosition : 'bottom-left'}
                    >
                    <Information className="tag-information__icon"/>
                </Tooltip>}
            </div>
            {ff.forestCertificationsTags && Array.isArray(certifications) && certifications.map(
                item => (
                    <div className={cx("tag-certification__wrapper", tagBackground && `tag-certification__wrapper--${tagBackground}`)} key={JSON.stringify(item)}>
                        <Certification className="tag-certification__icon"/>
                        <Text className="tag-certification__text">{item.name}</Text>
                        <Tooltip
                            content={item.tooltip}
                            position={'bottom'}
                            key={item.name}
                        >
                        <Information className="tag-information__icon"/>
                        </Tooltip>
                    </div>
                )
            )}
            {(showEstablishedIn || showPlantedIn || showFirstVerifiedDate) && <div className="tag-plantedDate__wrapper">
                <Planted className="tag-plantedDate__wrapper"/>
                <Text className="tag-plantingDate__text">
                    {showEstablishedIn &&
                        <FormattedMessage
                            id={'Tags.establishedInDate'}
                            defaultMessage={'Established {plantingDateParsed}'}
                            values={{ plantingDateParsed }}
                        />
                    }
                    {showFirstVerifiedDate &&
                        <FormattedMessage
                            id={'Tags.firstVerifiedInDate'}
                            defaultMessage={'First verified in {plantingDateParsed}'}
                            values={{ plantingDateParsed }}
                        />
                    }
                    {showPlantedIn &&
                        <FormattedMessage
                            id={'Tags.plantedInDate'}
                            defaultMessage={'Planted in {plantingDateParsed}'}
                            values={{ plantingDateParsed }}
                        />
                    }
                </Text>
            </div>}
        </div>
    )
}

Tags.propTypes = {
    className: PropTypes.string,
    planting_date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    certifications: PropTypes.array,
    reforestumProjectType: PropTypes.object,
    projectType: PropTypes.string,
    tagBackground: PropTypes.oneOf(['light']),
    forceEstablishedDatePhrasing: PropTypes.bool,
    intl: intlShape
};

export default Tags;
