import api from "../../constants/api"
import {getUserRequestHeaders} from "./getUserRequestHeaders"

export const queryDiscover = async (
	{
		perPage=20,
		currentPage=0,
		searchString,
		filters,
		filtersValues
	}
) => {

	let urlQueryParams = ''
	let urlQueryParamsSets = []

	const constructURLQueryParamsSet = ({key, values}) => {
		const valuesWithoutLabels = values.map(v => encodeURIComponent(v.value))
		return `${key}=${valuesWithoutLabels.join('%2C')}`
	}

	filters.map(
		f => ({key: f.param, values: filtersValues[f.param]})
	).forEach(
		({key, values}) => {
			if(values && Array.isArray(values) && values.length > 0) {
				urlQueryParamsSets.push(constructURLQueryParamsSet({key, values}))
			}
		}
	)

	urlQueryParamsSets.forEach(
		(paramSetString, i) => {
			urlQueryParams += `${i === 0 ? '?' : '&'}${paramSetString}`
		}
	)

	const searchStringSet = () => searchString && typeof searchString === 'string' && searchString.length > 0

	if(searchStringSet()) {
		urlQueryParams += `${urlQueryParamsSets.length === 0 ? '?' : '&'}search=${encodeURIComponent(searchString)}`
	}

	urlQueryParams += `${(urlQueryParamsSets.length === 0 && !searchStringSet()) ? '?' : '&'}perPage=${perPage}&currentPage=${currentPage}`

	let result = {data: {totalProjects: [], totalMatches: [], matches: []}}
	try {
		result = await fetch(
			`${api.DISCOVER}${urlQueryParams}`,
			{headers: getUserRequestHeaders()}
		)
		result = await result.json()
	}
	catch(e) {
		console.warn('Error performing discover search', e)
	}

	if(result.data) {
		result = {
			totalProjects: result.data.totalProjects || 0,
			totalMatches: result.data.totalMatches || 0,
			matches: result.data.matches || []
		}
	}

	return result
}


export const queryDiscoverFilters = async () => {
	let result = []
	try {
		result = await fetch(
			`${api.DISCOVER_FILTERS}`,
			{headers: getUserRequestHeaders()}
		)
		result = await result.json()
	}
	catch(e) {
		console.warn('Error performing discover search', e)
	}

	return result.data ? result.data : []
}
