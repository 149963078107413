import React from 'react';
import { Helmet } from 'react-helmet';

import Wrapper from '../components/Layout/Wrapper/Wrapper';
import MobileTopbar from "../components/Header/MobileTopbar/MobileTopbar"
import ProjectsListContainer from '../containers/ProjectsListContainer';

const ProjectsList = () => {
  return (
    <Wrapper>
        <Helmet>
            <title>
            Project list | Reforestum
            </title>
            <link rel="canonical" href={`https://app.reforestum.com/projects`} />
            <meta name="description" content="Explore the Projects across the Voluntary Carbon Markets"/>
        </Helmet>
        <MobileTopbar>
        </MobileTopbar>
        <ProjectsListContainer renderBeZeroReferencesInList />
    </Wrapper>
  );
};

export default ProjectsList;
