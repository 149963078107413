const DEFAULT_PROJECT_IMAGE = 'https://statics.reforestum.com/rfp/proposal_fallback.png'
const PROPOSAL_STATUS_DEFAULT = 'default'
const PROPOSAL_STATUS_SHORTLIST = 'favorite'
const PROPOSAL_STATUS_ARCHIVE = 'archived'

export {
  DEFAULT_PROJECT_IMAGE,
  PROPOSAL_STATUS_DEFAULT,
  PROPOSAL_STATUS_SHORTLIST,
  PROPOSAL_STATUS_ARCHIVE,
}