import React from 'react';
import cx from 'classnames';

import Text from '../Text/Text';
import Bulb from '../Icons/Bulb';

import './Accordion.css';

export default class Accordion extends React.PureComponent {
  state = {
    isOpen: this.props.isOpen || false,
  }

  toggleContentVisibility = () => {
    this.setState((previousState) => ({
      isOpen: !previousState.isOpen,
    }));
  }

  render() {
    const { title, content, link } = this.props;
    const { isOpen } = this.state;

    return (
      <div className={`accordion ${isOpen ? 'accordion__is-open' : 'accordion__is-closed'}`}>
        <div className="accordion__header" onClick={this.toggleContentVisibility}>
          <Bulb className="accordion__icon accordion__icon--bulb" />
          <Text tag="h3" color="dark">{title}</Text>
          <div className={cx('accordion__arrow', isOpen && 'accordion__arrow--down')}></div>
        </div>

        <div className={cx('accordion__content', isOpen && 'accordion__content--visible')}>
          <Text tag="p" color="light">{content}</Text>
          {link && (
            <Text
              className="accordion__link"
              tag="a"
              href={link.hyperlink}
              color="green"
              rel="noopener noreferrer"
              target="_blank"
            >
              {link.text}
            </Text>
          )}
        </div>
      </div>
    );
  }
}
