import React from 'react';

const DocumentWithTextIcon = props => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.3918 14.5H3.64128C3.49762 14.5 3.35984 14.4429 3.25826 14.3414C3.15668 14.2398 3.09961 14.102 3.09961 13.9583V2.04167C3.09961 1.89801 3.15668 1.76023 3.25826 1.65865C3.35984 1.55707 3.49762 1.5 3.64128 1.5H10.1418L13.9335 5.29167V13.9583C13.9335 14.0295 13.9195 14.0999 13.8922 14.1656C13.865 14.2313 13.8251 14.2911 13.7748 14.3413C13.7245 14.3916 13.6648 14.4315 13.5991 14.4588C13.5334 14.486 13.4629 14.5 13.3918 14.5Z" stroke="#4C624D" stroke-width="1.2" stroke-linecap="round"/>
    <path d="M10.1416 1.5V5.29167H13.9338" stroke="#4C624D" stroke-width="1.2" stroke-linecap="round"/>
    <path d="M6.35059 8.54175H10.6839" stroke="#4C624D" stroke-width="1.2" stroke-linecap="square"/>
    <path d="M6.35059 10.7083H10.6839" stroke="#4C624D" stroke-width="1.2" stroke-linecap="square"/>
  </svg>

);

export default DocumentWithTextIcon;
