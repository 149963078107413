import { MRVGraphMessages } from "../constants/messages";

const getGraphLegendMessages = (intl) => {
  return {
    'id-total-carbon-stock': {
      text: intl.formatMessage(MRVGraphMessages.carbonCaptured),
      tooltip: intl.formatMessage(MRVGraphMessages.carbonCapturedTooltip)
    },
    'id-legend-planet-5m': {
      text: intl.formatMessage(MRVGraphMessages.planetCarbonLengend),
      tooltip: intl.formatMessage(MRVGraphMessages.planetCarbonLengendTooltip)
    }
  }
}

export function getGraphLegendMessageText(intl, key) {
    const graphLegendMessage = getGraphLegendMessages(intl)[key];
    return graphLegendMessage && graphLegendMessage.text;
}

export function getGraphLegendMessageTooltip(intl, key) {
  const graphLegendMessage = getGraphLegendMessages(intl)[key];
  return graphLegendMessage && graphLegendMessage.tooltip;
}