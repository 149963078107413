import React from 'react'

const PlusCircle = props => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<circle cx="10" cy="10" r="9.5" stroke="#A5B1A6"/>
		<path d="M6.5 10H13.5" stroke="#1F3B21" stroke-width="1.2" stroke-linecap="square"/>
		<path d="M10 6.5V13.5" stroke="#1F3B21" stroke-width="1.2" stroke-linecap="square"/>
	</svg>

)

export default PlusCircle
