import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchTransactions, resetTransactions, fetchProjectVintages, resetProjectVintages } from '../actions/forests';
import * as selector from '../selectors/forests';

import Loader from '../components/UI/Loader/Loader';
import FullErrorFetching from '../components/UI/Interface/FullErrorFetching';
import ReactTable from 'react-table'

import NoForestEmptyStateImg from '@reforestum/shared-components/dist/assets/images/no-forests-empty-state.png';
import './TransactionsContainer.css';
import Pagination from '../components/UI/Pagination/Pagination';
import { FormattedMessage, injectIntl } from 'react-intl';
import HorizontalScrollWithArrowsContainer from '../components/UI/HorizontalScrollWIthArrowsContainer/HorizontalScrollWIthArrowsContainer';
import ForestSection from '../components/Forests/UI/ForestSection';
import ForestTitle from '../components/Forests/UI/ForestTitle';
import Text from '../components/UI/Text/Text';
import Tag from '../components/UI/Tags/Tag';
import featureFlags from '../constants/featureFlags';

//Import columns and styles
import { getTransactionsColumns } from '../utils/transactionsColumns/transactionsColumns';
import '../utils/transactionsColumns/transactionsColumns.css'
import WidgetsContainer from '../components/Widgets/ForestWidgets/WidgetsContainer';
import VintagesWidget from '../components/Widgets/ForestWidgets/VintagesWidget';
import { REGISTRY_MITERD, REGISTRY_VERRA, REGISTRY_GOLD_STANDARD } from '../constants/registries';
class TransactionsContainer extends Component {
  constructor() {
    super();
    this.pageLength = 10;
    this.showVintagesWidget = this.showVintagesWidget.bind(this)
  }

  componentWillMount() {
    const { fetchTransactions, resetTransactions, forest, transactionsForestId, vintagesProjectId, fetchProjectVintages  } = this.props;
    if(transactionsForestId !== forest.id) {
      resetTransactions();
      fetchTransactions({ forestId: forest.id }, 1, this.pageLength);
    }

    if(vintagesProjectId !== forest.id)  {
      resetProjectVintages()
      if (this.showVintagesWidget()) fetchProjectVintages({projectId: forest.id})
    }
  }

  showVintagesWidget() {
    const {forest} = this.props;
    return featureFlags().vintagesWidgetInTransactionsTab && forest.registry === REGISTRY_VERRA
  }

  render() {
    const {
      errorMessages,
      page,
      forest,
      transactions,
      transactionsForestId,
      vintagesProjectId,
      vintages,
      isFetchingTransactions,
      total,
      fetchTransactions,
      intl
    } = this.props;

    const transactionColumns = getTransactionsColumns(intl);
    const transactionsColumnsByRegistry = forest.registry === REGISTRY_GOLD_STANDARD
        ? transactionColumns.projectDetailsTransactionsTabColumnsGoldStandard
        : forest.registry === REGISTRY_MITERD
          ? transactionColumns.projectDetailsTransactionsTabColumnsMiterd
          : transactionColumns.projectDetailsTransactionsTabColumnsVerra

    if (errorMessages) {
      return (
        <FullErrorFetching
          errorMessage={errorMessages}
          retry={fetchTransactions.bind(this, {forestId: forest.id}, page, this.pageLength)}
        />
      );
    }

    const isPreviousForestData = (Number(transactionsForestId) !== Number(forest.id)) || (this.showVintagesWidget() && (Number(vintagesProjectId) !== Number(forest.id)));
    if ((isFetchingTransactions && (Object.keys(transactions).length <= 0)) || isPreviousForestData) return <Loader />;

    if(!isFetchingTransactions && (Object.keys(transactions).length <= 0)) {
      return (
        <div>
          <img src={NoForestEmptyStateImg} className="transactions-container-no-transactions-image" alt="Person watering plants"/>
          <Text className="transactions-container-no-transactions-text">
            <FormattedMessage id="transactionsContainer.noTransactions" defaultMessage="No transactions imported for this project"/>
          </Text>
        </div>
      )
    }

    return (
      <div>
        <ForestSection>
          <ForestTitle className="transactions-container-title">
            <FormattedMessage id="registryTransactions.title" defaultMessage="Registry transactions"/>
            <Tag 
              className='transactions-container-title-tag' 
              text={
                <FormattedMessage 
                  id="registryTransactions.tag" 
                  defaultMessage="Registry: {registryName}"
                  values={{
                    registryName: (forest.registry === REGISTRY_GOLD_STANDARD) ? 'Gold Standard' : (forest.registry === REGISTRY_MITERD) ? 'MITERD' : 'Verra VCS'
                  }}
                />
              }
            />
          </ForestTitle>
          <div style={{margin: '32px 0px'}}>
            <WidgetsContainer>
              {this.showVintagesWidget() && vintages && forest.total_vcus && <VintagesWidget totalVcusVerified={forest.total_vcus}  vintages={vintages} projectId={forest.id}/>}
            </WidgetsContainer>
          </div>
          <Text tag="p" color='light' weight="regular">
            {<FormattedMessage 
              id="registryTransactions.description" 
              defaultMessage="The table below shows the latest transaction from the {registryName} registry for the {projectName} project. It tracks the retirement by different entities of the verified carbon credits generated by this project, which represents the reduction or removal of greenhouse gas emissions."
              values={{
                registryName: 'Verra',
                projectName: forest.name
              }}  
            />}
          </Text>
        </ForestSection>
        <HorizontalScrollWithArrowsContainer className='transactions-table-container'>
          <ReactTable
            data={transactions}
            columns={transactionsColumnsByRegistry}
            showPagination={false}
            sortable={false}
            minRows={0}
            loadingText={null}
          />
        </HorizontalScrollWithArrowsContainer >
        {(total > this.pageLength) && <div className='transactions-container-pagination'>
          <Pagination
            current={page}
            getMore={fetchTransactions.bind(this, {forestId: forest.id})}
            pageLength={this.pageLength}
            total={total}
            maxItems={5}
          />
        </div>}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  errorMessages: selector.getTransactionsErrorMessages(state),
  isFetchingTransactions: selector.getIsFetchingTransactions(state),
  page: selector.getTransactionsPage(state),
  total: selector.getTransactionsTotal(state),
  transactions: selector.getTransactions(state),
  transactionsForestId: selector.getTransactionsForestId(state),
  vintages: selector.getVintages(state),
  vintagesProjectId: selector.getVintagesProjectId(state),
  isFetchingVintages: selector.getIsFetchingVintages(state),
});

TransactionsContainer.propTypes = {
  fetchTransactions: PropTypes.func.isRequired,
  resetTransactions: PropTypes.func.isRequired,
  fetchProjectVintages: PropTypes.func.isRequired,
  resetProjectVintages: PropTypes.func.isRequired,
  forest: PropTypes.object.isRequired,
  transactionsForestId: PropTypes.number,
  vintagesProjectId: PropTypes.number,
  errorMessages: PropTypes.string,
  isFetchingTransactions: PropTypes.bool.isRequired,
  isFetchingVintages: PropTypes.bool.isRequired,
  page: PropTypes.number,
  transactions: PropTypes.array,
  intl: PropTypes.object,
};

export default  connect(mapStateToProps, {
  fetchTransactions,
  resetTransactions,
  fetchProjectVintages,
  resetProjectVintages,
})(injectIntl(TransactionsContainer));