import { googleClientId } from "../../constants/config";

let instance

class GoogleAuth {
  constructor(callback) {
    if (instance) {
      callback(instance)
      return instance
    }

    this.injectClientLibrary(callback)
    instance = this
  }

  loadAuth2AndCallCallback(callback) {
    window.gapi.load('auth2', () => {
      window.gapi.auth2.init({
        client_id: googleClientId,
      }).then(auth2 => {
        this.auth2 = auth2
        this.auth2.isSignedIn.listen(this.handleStatusChange)
        // shouldReload = false
        callback(this)
      })
    })
  }

  injectClientLibrary = callback => {    
    // @NOTE: This prevent google auth block due race condictions 
    // let shouldReload = true;
    // setTimeout (() => {
    //   if(shouldReload) {
    //     window.location.reload()
    //   }
    // }, 500)

    if(!window.gapi) {
      window.gapiLoaded = () => {
        this.loadAuth2AndCallCallback(callback)
      }
      if(window.tarteaucitron.added.gplus) {
        window.tarteaucitron.services.gplus.js()
      }
    }
    else {
      this.loadAuth2AndCallCallback(callback)
    }
}

  login = (onSuccess, onFailure) => {
    if (this.auth2.isSignedIn.get()) {
      onSuccess(this.auth2.currentUser.get())
    } else {
      this.auth2.signIn({
        prompt: 'consent'
      }).then(onSuccess).catch(onFailure)
    }
  }

  logout = onLogout => {
    this.auth2.signOut().then(onLogout)
  }

  handleStatusChange = (isSignedIn = this.auth2.isSignedIn.get()) => {
    if (this.onExternalLogout && !isSignedIn) {
      this.onExternalLogout()
    }
  }

  setOnExternalLogout = callback => {
    this.onExternalLogout = callback
  }
}

export default GoogleAuth
