import actionTypes from '../constants/actionTypes';

export const toggleUserDropDown = () => ({
  type: actionTypes.USER_POPUP_TOGGLE
});

export const togglePreferencesSwitcherPopup = () => ({
  type: actionTypes.CURRENCY_AND_MEASUREMENTS_POPUP_TOGGLE
});

export const openUserDropDown = () => ({
  type: actionTypes.USER_POPUP_OPEN
});

export const closeUserDropDown = () => ({
  type: actionTypes.USER_POPUP_CLOSE
});

export const openGallery = (images, index) => ({
  type: actionTypes.GALLERY_OPEN,
  payload: {
    images,
    index,
  },
});

export const closeGallery = () => ({
  type: actionTypes.GALLERY_CLOSE
});

export const updateLoadStatus = (status) => ({
  type: actionTypes.LOAD_STATUS,
  payload: status,
});

export const setIsMobileResolution = (isMobileResolution) => ({
  type: actionTypes.SET_IS_MOBILE_RESOLUTION,
  payload: isMobileResolution,
});


