import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import Seed from '../UI/Icons/Seed';
import Compass from '../UI/Icons/Compass';
import Monitor from '../UI/Icons/Monitor';
import Text from '../UI/Text/Text';
import ReadMore from '../UI/ReadMore';

import AvatarPlaceholder from '@reforestum/shared-components/dist/assets/images/avatar-placeholder.png';

import './CompanyInfoSection.css';
import {Button} from "@reforestum/shared-components"
import { withRouter } from 'react-router-dom';
import Share from "../UI/Icons/Share"

const CompanyInfoSection = ({
  avatar_url,
  bio,
  year_foundation,
  website,
  environmental_statement,
  className,
  companyNickname,
  unOnboardedCompanyMode,
  organizationPrivateDashboardMode,
  handleShowOnboardYourCompanyModal,
  history,
  toggleSharingModal,
}) => {
  return (
    <div className={className}>
      <div className="public-dashboard-new-company-info-container">
        {
          unOnboardedCompanyMode &&
          <Button onClick={handleShowOnboardYourCompanyModal} className="btn--blank public-dashboard-company-update-cta">
            <Text className={'public-dashboard-company-update-cta__text'}>
              <FormattedMessage
                id={'PublicDashboard.unOnboardedCompany.updateButtonCTA'}
                defaultMessage={'Update organization offsets'}
              />
            </Text>
          </Button>
        }
        {
          organizationPrivateDashboardMode &&
          <Button onClick={() => history.push('account/organization-information')} className="btn--blank public-dashboard-company-update-cta">
            <Text className={'public-dashboard-company-update-cta__text'}>
              <FormattedMessage
                id={'OrganizationPrivateDashboard.updateButtonCTA'}
                defaultMessage={'Edit information'}
              />
            </Text>
          </Button>
        }
        <div className="public-dashboard__company-info--share-button__container">
          <Share className={'public-dashboard__company-info--share-button'} onClick={toggleSharingModal} />
        </div>
        <div className="public-dashboard-new-company-info-image-cropper">
          <img className="public-dashboard-new-company-info-image" src={avatar_url || AvatarPlaceholder} alt="company logo" />
        </div>
        <p className="public-dashboard-new-company-info-name">{companyNickname}</p>
        {bio && <p className="public-dashboard-new-company-info-bio">{bio}</p>}
        <div className="public-dashboard-new-company-info-separator" />
        { year_foundation &&
          <div className="public-dashboard-new-company-info-iconwithtext-container">
            <Seed />
            <span className="public-dashboard-new-company-info-iconwithtext-text">
              <FormattedMessage
                  id={'Dashboard.founded'}
                  defaultMessage={'Founded in {value}'}
                  values={{ value: year_foundation }}
                />
            </span>
          </div>
        }
        {website &&
          <div className="public-dashboard-new-company-info-iconwithtext-container">
            <Monitor />
            <a
              className="public-dashboard-new-company-info-iconwithtext-text public-dashboard-new-company-info-link"
              target="_blank"
              rel="noopener noreferrer"
              href={website}
            >
              {website}
            </a>
          </div>
        }
        {(environmental_statement || unOnboardedCompanyMode) &&
        <div className="public-dashboard-new-company-info-iconwithtext-container__environmental-statement-wrapper">
          <div className="public-dashboard-new-company-info-iconwithtext-container">
            <Compass />
            <span className="public-dashboard-new-company-info-iconwithtext-text">
              <FormattedMessage
                id={'Dashboard.mission'}
                defaultMessage={'Environmental statement'}
              />
              </span>
          </div>

          {
            unOnboardedCompanyMode
              ? <div onClick={handleShowOnboardYourCompanyModal} className={'public-dashboard__unonboarded-company__add-environmental-statement__wrapper'}>
                <Text tag="span" weight="regular" className={'public-dashboard-new-company-info-environmental_statement public-dashboard__unonboarded-company__add-environmental-statement'}>
                  <FormattedMessage
                    id={'Dashboard.unonboardedCompany.addEnvironmentalStatement'}
                    defaultMessage={'If you work with this organization, Reforestum invites you to'}
                  />
                </Text>
                &nbsp;
                <Text tag="span" weight="regular" className={'public-dashboard-new-company-info-environmental_statement public-dashboard__unonboarded-company__add-environmental-statement__cta'}>
                  <FormattedMessage
                    id={'Dashboard.unonboardedCompany.addEnvironmentalStatementCTA'}
                    defaultMessage={'add an environmental statement'}
                  />
                </Text>
              </div>
              : <Text tag="p" weight="regular" className="public-dashboard-new-company-info-environmental_statement">
                <ReadMore
                  text={environmental_statement}
                  isHtml
                  cutLength={300}
                  minLength={300}
                />
              </Text>
          }
        </div>

        }
      </div>
    </div>
  );
};

CompanyInfoSection.propTypes = {
  avatar_url: PropTypes.string,
  name: PropTypes.string,
  bio: PropTypes.string,
  year_foundation: PropTypes.number,
  website: PropTypes.string,
  environmental_statement: PropTypes.string,
  className: PropTypes.string,
  companyNickname: PropTypes.string,
  unOnboardedCompanyMode: PropTypes.bool,
  organizationPrivateDashboardMode: PropTypes.bool,
  handleShowOnboardYourCompanyModal: PropTypes.func,
};

export default withRouter(CompanyInfoSection);
