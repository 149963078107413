import React from 'react';
import { Box } from 'react-layout-components';

import './Loader.css';

class Loader extends React.Component {
  componentDidMount(){
    window.Intercom('update', {
      "hide_default_launcher": true
    });
  }

  componentWillUnmount(){
    window.Intercom('update', {
      "hide_default_launcher": false
    });
  }
  
  render() {
    const { backgroundTransparent } = this.props;
    return (
      this.props.useNormalDiv ?
        <div className={`loader ${backgroundTransparent && 'loader--background-transparent'}`}>
          <div className="loader__holder">
            <div className="loader__state" />
          </div>
        </div>
        :
        <Box fit center>
          <Box column center className={`loader ${backgroundTransparent && 'loader--background-transparent'}`}>
            <div className="loader__holder">
              <div className="loader__state" />
            </div>
          </Box>
        </Box>
    )
  }
}

export default Loader;
