import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import AutosizeInput from "react-input-autosize"
import { intlShape } from 'react-intl';
import { carbonMessages } from '../../constants/messages';
import {GENERAL_INPUT_DEFAULT_SIZE} from "./helpers/constants";
import './stepCommon.css';
import {
  moveCalculatorElementToPageTopOnMobile
} from "./helpers/moveElementToPageTopOnMobile"
import {Element} from "react-scroll/modules"

class StepLast extends Component {
  constructor() {
    super();

    this.state = {
      nameChanged: false,
      updatedReduxFromWithEditName: false
    }
    this.inputComponent = this.inputComponent.bind(this);
    this.textInput = null;
    this.scrollToOnMountTimeout = null
  }

  componentDidMount() {
    this.textInput.focus()
    this.scrollToOnMountTimeout = setTimeout(() => {
      this.textInput.focus()
      moveCalculatorElementToPageTopOnMobile('step-last', 100)
    }, 2000)
  }

  componentWillUnmount() {
    clearTimeout(this.scrollToOnMountTimeout)
  }

  getValueToDisplay(input) {
    return this.state.nameChanged ? input.value : this.props.sourceToEditName
  }

  inputComponent(props) {
    const { input } = props;

    if(!this.updatedReduxFromWithEditName) {
      input.onChange(this.getValueToDisplay(input))
      this.setState({updatedReduxFromWithEditName: true})
    }

    return (
      <AutosizeInput
        {...input}
        id={this.props.isMostRecentStep ? 'most-recent-step' : ''}
        value={this.getValueToDisplay(input)}
        ref={elem => (this.textInput = elem)}
        onFocus={e => {
          this.moveCaretToEnd(e)
        }}
        onClick={() => {
          moveCalculatorElementToPageTopOnMobile('step-last')
        }}
        onKeyDown={() => {
          this.setState({nameChanged: true})
        }}
        inputClassName="AutosizeInput-class step-last"
        minWidth={GENERAL_INPUT_DEFAULT_SIZE}
      />
    )
  }

  moveCaretToEnd(e) {
    var temp_value = e.target.value
    e.target.value = ''
    e.target.value = temp_value
  }

  render() {
    const { step, intl } = this.props;
    return (
      <Element className={'react-scroll-element'} name={'step-last'}>
        <div className="line-input__input-holder">
          <Field
            name={`field_${step.id}`}
            component={this.inputComponent}
            placeholder={intl.formatMessage(carbonMessages.calculatorLastStepPlaceholder)}
          />
        </div>
      </Element>
    )
  }
}

StepLast.propTypes = {
  step: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  sourceToEditName: PropTypes.string,
}

export default StepLast;
