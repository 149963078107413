import React from 'react';
import PropTypes from 'prop-types';
import Masonry from 'react-masonry-css';

const SMALL_IMAGE_SIZE = 135;
const BIG_IMAGE_SIZE = 220;

let sizeStore = [];

const sumSizeForColumn = columnIndex => columnIndex >= 0
  && sizeStore[columnIndex].reduce((sum, size) => {
    return sum + size;
  }, 0);

const getNLastSizeFromColumn = (columnIndex, n) => {
  const columnSizes = sizeStore[columnIndex];
  return columnSizes[columnSizes.length - n];
}

const isRepeatLimitExceeded = columnIndex => {
  if (sizeStore[columnIndex].length > 1) {
    return getNLastSizeFromColumn(columnIndex, 1) === getNLastSizeFromColumn(columnIndex, 2);
  } else {
    return false;
  }
};

function determineImageSize(index, numberOfColumns, isLastInColumn) {
  if (index === 0) {
    sizeStore = [[BIG_IMAGE_SIZE], ...Array.apply(null, {length: numberOfColumns - 1}).map(() => [])];
  }

  const currentColumnSizeIndex = index % numberOfColumns;

  if (isRepeatLimitExceeded(currentColumnSizeIndex)) {
    const finalImageSize = getNLastSizeFromColumn(currentColumnSizeIndex, 1) === BIG_IMAGE_SIZE ? SMALL_IMAGE_SIZE : BIG_IMAGE_SIZE;
    sizeStore[currentColumnSizeIndex].push(finalImageSize);
    return finalImageSize;
  }

  const previousColumnSize = sumSizeForColumn(currentColumnSizeIndex - 1);
  const columnSize = sumSizeForColumn(currentColumnSizeIndex);

  const forceBigSize = index === 0
    || (currentColumnSizeIndex !== 0 && columnSize + SMALL_IMAGE_SIZE === previousColumnSize)
    || (isLastInColumn && previousColumnSize > columnSize + SMALL_IMAGE_SIZE);

  const forceSmallSize = columnSize + BIG_IMAGE_SIZE === previousColumnSize
    || (isLastInColumn && previousColumnSize < columnSize + SMALL_IMAGE_SIZE);

  const finalImageSize = forceBigSize
    ? BIG_IMAGE_SIZE
    : forceSmallSize
      ? SMALL_IMAGE_SIZE
      : Math.random() < 0.5 ? SMALL_IMAGE_SIZE : BIG_IMAGE_SIZE;

  if (index !== 0) {
    sizeStore[currentColumnSizeIndex].push(finalImageSize);
  }
  return finalImageSize;
}

const ForestInfoGallery = ({ images, openGallery }) => {
  const numberOfColumns = window.innerWidth <= 768 ? 2 : 4;
  return (
    <Masonry
      breakpointCols={{ default: numberOfColumns }}
      className="forest-info__gallery"
      columnClassName="forest-info__gallery-column"
    >
      {images && images.map(({URI}, index) => {
        const isLastInColumn = index > images.length - numberOfColumns;
        return (
          <img
            loading="lazy"
            alt=""
            data-index={index}
            className="forest-info__gallery-image"
            key={index}
            onClick={openGallery(index)}
            src={URI}
            style={{height: `${determineImageSize(index, numberOfColumns, isLastInColumn)}px`}}
          />
        );
      })}
    </Masonry>
  );
}

ForestInfoGallery.propTypes = {
  images: PropTypes.array,
  openGallery: PropTypes.func.isRequired,
};

export default ForestInfoGallery;