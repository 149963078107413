import React from 'react'
import {FormattedMessage, FormattedNumber, injectIntl} from "react-intl"
import history from '../../../setup/history'
import {Link} from "react-router-dom"
import {links} from '@reforestum/shared-utils'

import { sponsorsCard } from "../../../constants/messages"

import Heading from "../Text/Heading"
import Text from "../Text/Text"
import { Button } from '@reforestum/shared-components'
import TinyInfoBlock from "../TinyInfoBlock/TinyInfoBlock"
import Information from "../Icons/Information"
import Tooltip from "../Tooltip/Tooltip"
import ReadMore from "../ReadMore"

import './SponsorsCard.css'
import { getFormattedArea } from '../../../utils/units'
import FormattedUnit from '../../Utils/FormattedUnit'
import CommunityLogo from '../Icons/CommunityLogo'

const MAX_DESCRIPTION_PREVIEW_LENGTH = 300

const SponsorsCard = (
	{
		intl,
		forestId,
		id,
		logo,
		profileDomain,
		companyName,
		reforestersAmount,
		totalCredits,
		totalSurface,
		description,
		isSqmSharesProject,
		isCommunity,
	}
) => {
	const tinyInfoItems = [
		isSqmSharesProject ? {
			title: "Surface",
			value: <div>
				<FormattedNumber
              		value={getFormattedArea(totalSurface).value}
          		/>
				&nbsp;
				<FormattedUnit unit={getFormattedArea(totalSurface).unit} />
				<Tooltip
					style={{marginLeft: '4px'}}
					position="bottom-right"
					content={
						<FormattedMessage
							id={'SponsorsCard.totalSurfaceTooltip'}
							defaultMessage={'Total surface this community has reforested'}
						/>
					}
				>
					<Information/>
				</Tooltip>
			</div>,
			icon: 'surface',
			divider: (reforestersAmount > 1),
		} : totalCredits && {
			title: intl.formatMessage(sponsorsCard.creditsOwned),
			value: <div>
				<FormattedNumber value={totalCredits} />
				&nbsp;
				<span>VCUs</span>
				<Tooltip
					style={{marginLeft: '4px'}}
					position="bottom-right"
					content={
						<FormattedMessage
							id={'ImpactMRV.totalVCUsTooltip'}
							defaultMessage={'Total amount of Carbon Credits issued in this project by the certifying body to date.'}
						/>
					}
				>
					<Information/>
				</Tooltip>
			</div>,
			icon: 'credits',
			divider: (reforestersAmount > 1),
		},
		(reforestersAmount > 1) && {
			title: intl.formatMessage(sponsorsCard.reforesters),
			value: `${reforestersAmount}`,
			icon: 'users',
			divider: false,
		},
	]

	const renderHeader = () => <div className={'sponsors-card-header'}>
		<div className="sponsors-card__sponsor-info">
			{
				isCommunity ?
					<CommunityLogo className="sponsors-card-header-company__logo sponsors-card-header-company__logo--community"/> :
				 	logo &&
						<img
							src={logo}
							alt={`company logo for ${companyName}`}
							className="sponsors-card-header-company__logo"
						/>
			}
			<div className="sponsors-card__sponsor-name-and-founded-by">
				<Heading className="sponsors-card-header__name">
					{companyName}
				</Heading>
				{isCommunity && <p className="sponsors-card-header__community">
					<FormattedMessage
						defaultMessage="Collaborations made through reforestum's platform"
						id="SponsorsCard.communityDescription"
					/>
					</p>}
				<Text className="sponsors-card-header__founded-by">
					{profileDomain &&
						<div>
							<Link
								to={links.getPublicProfileUrl(profileDomain)}
								className="sponsors-card-header-publicProfile__link"
							>
								<FormattedMessage
									defaultMessage="View profile"
									id="ForestDetails.viewPatronProfile"
								/>
							</Link>
						</div>
					}
				</Text>
			</div>
		</div>
		<div className={'sponsors-card__view-sector-button__container'}>
			<Button
				className="btn--blank sponsors-card__view-sector-button"
				onClick={() => history.push(links.getProjectUrl(forestId, id))}
			>
				<FormattedMessage
					id={'SponsorsCard.exploreCommunity'}
					defaultMessage={'Explore Community'}
				/>
			</Button>
		</div>
	</div>

	const renderTinyInfo = () => <div className="sponsors-card__tiny-info-wrapper">
		{tinyInfoItems.map((item, index) => (
			<TinyInfoBlock
				tooltip={item.tooltip}
				className={'forest-header-details__item'}
				key={index}
				{...item}
			/>
		))}
	</div>

	const renderDescription = () =>
		<Text color={'light'} weight={'regular'} tag={'div'} className={'sponsors-card__description t-main t-main--light t-main--regular'}>
			<ReadMore text={description} isHtml cutLength={MAX_DESCRIPTION_PREVIEW_LENGTH} minLength={MAX_DESCRIPTION_PREVIEW_LENGTH}/>
		</Text>

	const renderMain = () => <div className={'sponsors-card'}>
		{renderHeader()}
		{renderTinyInfo()}
		{renderDescription()}
	</div>

	return renderMain();
}

export default injectIntl(SponsorsCard)
