import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import Text from '../Text/Text';

import './BreadCrumb.css';
import arrowRightLight from '@reforestum/shared-components/dist/assets/images/arrow-right--light.png'

const BreadCrumb = ({className, ...props}) => (
    <div className={cx(className, 'breadcrumb-wrapper')}>
        {props.sector && 
        <div className='breadcrumb-wrapper-originItem'>
            <Link to={props.linkToOrigin}>
                <Text className='my-forests' color='light'>{props.textOrigin}</Text>  
            </Link>
                <img 
                    src={arrowRightLight}
                    alt='arrow-right'
                    className={cx(className, 'arrow-right')}
                />
        </div>
        }
        <Link to={props.linkTo}>
            <Text className='forest-id' color='light'>{props.textFrom}</Text>  
        </Link>
        <img 
            src={arrowRightLight}
            alt='arrow-right'
            className={cx(className, 'arrow-right')}
        />
        <Text className='sector-id'>{props.textTo}</Text>
    </div>
);

BreadCrumb.propTypes = {
  className: PropTypes.string,
  textOrigin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  textFrom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  textTo: PropTypes.string.isRequired,
  linkToOrigin: PropTypes.string,
  linkTo: PropTypes.string
};

export default BreadCrumb;