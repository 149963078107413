import actionTypes from '../constants/actionTypes'
import {axiosV2Instance} from './'
import api from '../constants/api'
import {getIsFetching} from '../selectors/publicProfile'


export const getPublicProfile = (userName) => ({
	shouldCallAPI: state => !getIsFetching(state),
	callAPI: () => axiosV2Instance.get(`${api.GET_ORGANIZATION_PROFILE}${userName}/public-profile`),
	actions: [
		actionTypes.PUBLIC_PROFILE_START,
		{
			type: actionTypes.PUBLIC_PROFILE_COMPLETE,
			payload: response => response.data.data
		},
		{
			type: actionTypes.PUBLIC_PROFILE_ERROR,
			payload: response => response
		}
	],
})
