import { defineMessages } from "react-intl";

//Including a tagTooltip is optional
export const PROJECT_TYPES = defineMessages({
    'REDD': { id: "ProjectTypes.REDD.tag", defaultMessage: "REDD - Reducing emissions from deforestation and forest degradation" },
    'ARR': { id: "ProjectTypes.ARR.tag", defaultMessage: "ARR - Afforestation, reforestation and revegetation" },
    'IFM': { id: "ProjectTypes.IFM.tag", defaultMessage: "IFM - Improved forest managemenent" },
    'ALM': { id: "ProjectTypes.ALM.tag", defaultMessage: "ALM - Agricultural Land Management" },
    'ACoGS': { id: "ProjectTypes.ACoGS.tag", defaultMessage: "ACoGS - Avoiding Conversion of Grasslands and Shrublands" },
    'WRC': { id: "ProjectTypes.WRC.tag", defaultMessage: "WRC - Wetlands Restoration and Conservation" },
    'Energy (renewable/non-renewable)': { id: "ProjectTypes.EnergyRenewableNonRenewable.tag", defaultMessage: "Energy - Energy (renewable/non-renewable)" },
    'Energy distribution': { id: "ProjectTypes.EnergyDistribution.tag", defaultMessage: "Energy - Energy distribution" },
    'Energy demand': { id: "ProjectTypes.EnergyDemand.tag", defaultMessage: "Energy - Energy demand" },
    'Manufacturing industries': { id: "ProjectTypes.ManufacturingIndustries.tag", defaultMessage: "Manufacturing - Manufacturing industries" },
    'Chemical industry': { id: "ProjectTypes.ChemicalIndustry.tag", defaultMessage: "Chemical industry" },
    'Construction': { id: "ProjectTypes.Construction.tag", defaultMessage: "Construction" },
    'Transport': { id: "ProjectTypes.Transport.tag", defaultMessage: "Transport" },
    'Mining/Mineral production': { id: "ProjectTypes.MiningMineralProduction.tag", defaultMessage: "Mining - Mining/Mineral production" },
    'Metal production': { id: "ProjectTypes.MetalProduction.tag", defaultMessage: "Metal production" },
    'Fugitive emissions – from fuels (solid, oil, and gas)': { id: "ProjectTypes.FugitiveEmissionsFromFuels.tag", defaultMessage: "Fugitive emissions – Fugitive emissions from fuels (solid, oil, and gas)" },
    'Fugitive emissions – from Industrial gases (halocarbons and sulphur hexafluoride)': { id: "ProjectTypes.FugitiveEmissionsFromIndustrialGases.tag", defaultMessage: "Fugitive emissions – Fugitive emissions from Industrial gases (halocarbons and sulphur hexafluoride)" },
    'Solvents use': { id: "ProjectTypes.Solvents.tag", defaultMessage: "Solvents - Solvent use" },
    'Waste handling and disposal': { id: "ProjectTypes.WasteHandlingAndDisposal.tag", defaultMessage: "Waste - Waste handling and disposal" },
    'Livestock and manure management': { id: "ProjectTypes.LivestockAndManureManagement.tag", defaultMessage: "Livestock - Livestock and manure management" },
    'Carbon capture and storage': { id: "ProjectTypes.CarbonCaptureAndStorage.tag", defaultMessage: "Carbon Capture - Carbon capture and storage" },
    'Other': { id: "ProjectTypes.Other.tag", defaultMessage: "Other" }
})