import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { links } from '@reforestum/shared-utils'
import {
  fetchForestDetails,
  fetchForests,
  fetchMyForests,
  fetchForestAboutGallery,
  resetMrv,
  forestSummary,
  fetchForestLatestActivity
} from '../actions/forests';
import { openGallery } from '../actions/UI';
import * as forestSelector from '../selectors/forests';

import ForestDetails from '../components/Forests/Details/ForestDetails';
import Loader from '../components/UI/Loader/Loader';

import mixpanel from 'mixpanel-browser';
import getIsAutheticatedFromStore from '../utils/getIsAutheticatedFromStore';
import { fetchForestContributors, resetForestContributors } from '../actions/forests';
import { CONTRIBUTORS_PER_PAGE } from '../constants/contributors';

class ForestContainer extends Component {

  constructor(props) {
    super(props)
    this.updateContributorsIfNeeded = this.updateContributorsIfNeeded.bind(this)
  }

  updateContributorsIfNeeded() {
    const { fetchForestContributors, resetForestContributors, selectedForestId, contributorsForestId  } = this.props;
    if(contributorsForestId !== selectedForestId) {
      resetForestContributors();
        fetchForestContributors({ forestId: selectedForestId }, 1, CONTRIBUTORS_PER_PAGE);
    }
  }


  componentWillMount() {
    // This action is needed in order to reset previous MRV raster sources from redux
    // every time we open a different project
    this.props.resetMrv();

    const { 
      fetchMyForests,
      fetchForestDetails,
      selectedForestId, 
      fetchForestAboutGallery,
      forestSummary,
      fetchForestLatestActivity
    } = this.props;

    fetchMyForests();
    fetchForestDetails(selectedForestId);
    fetchForestAboutGallery(selectedForestId);
    forestSummary();
    fetchForestLatestActivity(selectedForestId);
    this.updateContributorsIfNeeded();
  }

  componentDidUpdate(prevProps) {
    const {
      selectedForestId,
      fetchForestDetails,
      fetchForestAboutGallery,
      fetchForestLatestActivity
    } = this.props
    //Refresh forest page when new forest is selected
    if(prevProps.selectedForestId !== selectedForestId) {
      fetchForestDetails(selectedForestId);
      fetchForestAboutGallery(selectedForestId);
      fetchForestLatestActivity(selectedForestId);
      this.updateContributorsIfNeeded();
    }
  }

  componentDidMount() {
    mixpanel.track("Page view", {
      "Authenticated": getIsAutheticatedFromStore(),
      "Action": "Forest page",
      "Forest": this.props.selectedForestId,
      "Domain": "App"
    });
  }

  render() {
    const {
      forest,
      isFetching,
      openGallery,
      species,
      team,
      gallery,
      myOwnedForests,
      latestActivity,
      topContributors,
      renderedStandalone,
      tabsWithoutRouting,
      totalContributors,
      resetScrollCallback,
      hideCommunity,
      hideEmptyTabs
    } = this.props;

    if (isFetching) {
      return <Loader />;
    }

    if(renderedStandalone && !forest) {
      return <Loader />;
    }

    if (!forest) {
      return <Redirect replace to={links.get404Url()} />;
    }


    return (
      <ForestDetails
        forest={forest}
        openGallery={openGallery}
        species={species}
        team={team}
        gallery={gallery}
        myOwnedForests={myOwnedForests}
        latestActivity={latestActivity}
        topContributors={topContributors}
        totalContributors={totalContributors}
        tabsWithoutRouting={tabsWithoutRouting}
        renderedStandalone={renderedStandalone}
        resetScrollCallback={resetScrollCallback}
        hideCommunity={hideCommunity}
        hideEmptyTabs={hideEmptyTabs}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  forest: forestSelector.getForestById(state, ownProps.selectedForestId),
  isFetching: forestSelector.getIsFetchingDetails(state),
  species: forestSelector.getForestSpecies(state, ownProps.selectedForestId),
  isFetchingMyForests: forestSelector.getIsFetchingMyForests(state),
  team: forestSelector.getForestTeam(state, ownProps.selectedForestId),
  gallery: forestSelector.getGallery(state, ownProps.selectedForestId),
  myOwnedForests: forestSelector.getMyOwnedForests(state),
  latestActivity: forestSelector.getLatestActivity(state),
  contributorsForestId: forestSelector.getContributorsForestId(state),
  topContributors: forestSelector.getTopContributors(state),
  totalContributors: forestSelector.getContributorsTotal(state),
});

ForestContainer.propTypes = {
  fetchForestDetails: PropTypes.func.isRequired,
  fetchMyForests: PropTypes.func.isRequired,
  fetchForestAboutGallery: PropTypes.func.isRequired,
  forestSummary: PropTypes.func.isRequired,
  fetchForestLatestActivity: PropTypes.func.isRequired,
  forest: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  openGallery: PropTypes.func.isRequired,
  isFetchingMyForests: PropTypes.bool.isRequired,
  selectedForestId: PropTypes.number.isRequired,
  species: PropTypes.array.isRequired,
  team: PropTypes.array.isRequired,
  gallery: PropTypes.object,
  myOwnedForests: PropTypes.array,
  resetMrv: PropTypes.func,
  latestActivity: PropTypes.array.isRequired,
  renderedStandalone: PropTypes.bool,
  tabsWithoutRouting: PropTypes.bool,
  hideCommunity: PropTypes.bool,
  hideEmptyTabs: PropTypes.bool,
};

export default withRouter(connect(mapStateToProps, {
  fetchForestDetails,
  fetchMyForests,
  fetchForests,
  openGallery,
  fetchForestAboutGallery,
  resetMrv,
  forestSummary,
  fetchForestLatestActivity,
  fetchForestContributors,
  resetForestContributors,
})(ForestContainer));
