import { privacyLevels } from '../constants/messages';
import { PRIVACYLEVEL } from '../constants/privacyLevels';

export const getPrivacyOptionsForIndividuals = (intl) => {
    return (
        [
            {
              label: intl.formatMessage(privacyLevels.public),
              value: PRIVACYLEVEL.PUBLIC,
              customDescription: intl.formatMessage(privacyLevels.publicDescription),
            },
            {
              label: intl.formatMessage(privacyLevels.nickname),
              value: PRIVACYLEVEL.NICKNAME,
              customDescription: intl.formatMessage(privacyLevels.nicknameDescription)
            },
            {
              label: intl.formatMessage(privacyLevels.private),
              value: PRIVACYLEVEL.PRIVATE,
              customDescription: intl.formatMessage(privacyLevels.privateDescription)
            }
          ]
        
    )
}

export const getPrivacyOptionsForCompany = (intl) => {
  return (
    [
        {
          label: intl.formatMessage(privacyLevels.public),
          value: PRIVACYLEVEL.PUBLIC,
          customDescription: intl.formatMessage(privacyLevels.publicDescriptionForCompany),
        },
        {
          label: intl.formatMessage(privacyLevels.private),
          value: PRIVACYLEVEL.PRIVATE,
          customDescription: intl.formatMessage(privacyLevels.privateDescription)
        }
      ]
    
)
}
