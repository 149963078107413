
import React from 'react';
import PropTypes from 'prop-types';
import mixpanel from 'mixpanel-browser';
import { FormattedMessage } from 'react-intl';

import { ACTIVATION_STATUS } from '../../constants/enterpriseLanding'
import { OFFSET_ACTIVATION_TYPES } from '../../constants/enterpriseLanding';

import Logo from '../UI/Logo/Logo';
import Heading from '../UI/Text/Heading';
import Text from '../UI/Text/Text';
import { Button } from '@reforestum/shared-components';
import { ButtonLink } from '@reforestum/shared-components';

import './EnterpriseParamsLanding.css';
import { links } from '@reforestum/shared-utils';
import getFeatureFlags from '../../constants/featureFlags';

const ff = getFeatureFlags();

const EnterpriseParamsLanding = ({
    companyData,
    setShowActivationForm,
    isAuthenticated,
    defaultLandingImage,
    activationStatus,
    handleActivatedOffsetRedirectToLogin,
    handleActivatedOffsetRedirectToMyShares,
    handleActivateMySharesLoggedUser,
    offsetTransactionType,
    isMiraklCase,
    handleActivatedOffsetSpecialCaseRedirectionToReforesters
}) => {

    const {
        landingImage,
        companyLogo,
        companyName,
        landingTitle,
        principalLandingText,
        secondaryLandingText,
        tertiaryLandingText,
        corporateProfileSlug
    } = companyData;

    const addDefaultSrc = (ev) => ev.target.src = defaultLandingImage

    // special redirection case for Mirakl
    const isB2B2B = offsetTransactionType && offsetTransactionType === OFFSET_ACTIVATION_TYPES.B2B2B;
    const isMiraklSpecialCase = isMiraklCase && isB2B2B;
    
    // case for user authenticated and activation activated
    const handleNormalConditionForOnClick = () => {
        mixpanel.track_links('#visit_shares_btn', 'Visit my shares no-auth click');
        mixpanel.track('Visit my shares no-auth click');

        return isAuthenticated ? handleActivatedOffsetRedirectToMyShares() : handleActivatedOffsetRedirectToLogin();
    }

    // activate my shares flow with mixpanel track link
    const handleActivationAndTracking = () => {
        mixpanel.track_links('#activate_profile_btn', 'Activate my shares in landing page click');
        mixpanel.track('Activate my shares in landing page click');

        return isAuthenticated ? handleActivateMySharesLoggedUser() : setShowActivationForm();
    }

    const handleVisitCompanyProfileTracking = () => {
        mixpanel.track_links('#visit_corporate_profile_btn', 'Visit company profile click');
        mixpanel.track('Visit company profile click');
    }

    return (
        <div className="landing-main__wrapper">
          <div className="left-side-image__wrapper">
              <img
                  onError={addDefaultSrc}
                  className="main_company__image"
                  src={landingImage}
                  alt="forest"
              />
          </div>
          <div className="right-side-description__wrapper">
                <div className="reforestum-logo__wrapper">
                    <Logo className="reforestum__logo" />
                </div>
                <div>
                    <img
                        className={'company__logo'}
                        src={companyLogo}
                        alt={`${corporateProfileSlug} logo`}
                    />
                </div>
              <Heading>{landingTitle}</Heading>
              <div className={'right-side-description-text-block__wrapper'}>
                  <div className={'description-text-principal'}>
                      <Text weight="regular">{principalLandingText}</Text>
                  </div>
                  <div className={'description-text-secondary'}>
                      <Text weight="regular">{secondaryLandingText}</Text>
                  </div>
                  <div className={'description-text-tertiary'}>
                      <Text weight="regular">{tertiaryLandingText}</Text>
                  </div>
                  <div className="ctas-buttons_wrapper">
                    {activationStatus === ACTIVATION_STATUS.ACTIVATED
                    ?
                      <Button
                        className="activate_profile_btn"
                        id="visit_shares_btn"
                        onClick={() => isMiraklSpecialCase
                            ? handleActivatedOffsetSpecialCaseRedirectionToReforesters()
                            : handleNormalConditionForOnClick()
                        }
                      >
                        <FormattedMessage
                            id={'EnterpriseParamsLanding.mySharesButton'}
                            defaultMessage={'Visit my shares'}
                        />
                      </Button>
                    : <Button
                        className="activate_profile_btn"
                        id="activate_profile_btn"
                        onClick={() => handleActivationAndTracking()}
                      >
                        <FormattedMessage
                            id={'EnterpriseParamsLanding.activateButton'}
                            defaultMessage={'Activate my shares'}
                        />
                    </Button>
                    }
                    {ff.enterpriseActivationProfileLink &&
                    <ButtonLink
                        className="visit_corporate_profile_btn"
                        id="visit_corporate_profile_btn"
                        onClick={() => handleVisitCompanyProfileTracking()}
                        to={links.getPublicProfileUrl(corporateProfileSlug)}
                        outline={true}
                    >
                            <FormattedMessage 
                                id={'EnterpriseParamsLanding.visitBtn'}
                                defaultMessage={"Visit {companyName}'s profile"}
                                values={{ companyName }}
                            />
                    </ButtonLink>
                    }
                  </div>
              </div>
          </div>
        </div>
      )
};

EnterpriseParamsLanding.propTypes = {
  setShowActivationForm: PropTypes.func.isRequired,
  companyData: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  defaultLandingImage: PropTypes.string,
  activationStatus: PropTypes.string.isRequired,
  handleActivatedOffsetRedirectToLogin: PropTypes.func.isRequired,
  handleActivatedOffsetRedirectToMyShares: PropTypes.func.isRequired,
  handleActivateMySharesLoggedUser: PropTypes.func.isRequired,
  offsetTransactionType: PropTypes.string,
  isMiraklCase: PropTypes.bool.isRequired,
  handleActivatedOffsetSpecialCaseRedirectionToReforesters: PropTypes.func.isRequired
};

export default EnterpriseParamsLanding
