import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'react-layout-components';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import history from '../setup/history';
import { fetchReforesterShares } from '../actions/forests'
import * as selector from '../selectors/forests';
import SimpleMap from '../components/Forests/Map/SimpleMap';
import SectorContainer from './SectorContainer';
import VirtualSectorContainer from './VirtualSectorContainer';
import Loader from '../components/UI/Loader/Loader';
import FullErrorFetching from '../components/UI/Interface/FullErrorFetching';
import Container from '../components/Layout/Container/Container';
import Content from '../components/Layout/Container/Content';
import DynamicWidth from '../components/Layout/DynamicWidth/DynamicWidth';
import Sidebar from '../components/Layout/Sidebar/Sidebar';
import HeadingSmall from '../components/UI/Text/HeadingSmall';
import SidebarToggle from '../components/UI/SidebarToggle/SidebarToggle';
import mixpanel from 'mixpanel-browser';
import getIsAutheticatedFromStore from '../utils/getIsAutheticatedFromStore';
import { MONITORING_LEVEL } from "../constants/monitoringLevel";
import { links } from '@reforestum/shared-utils';
import { OFFSET_TYPE_SQM_SHARES, OFFSET_TYPE_CREDIT } from '../constants/offsetTypes';

class MySectorContainer extends Component {
  constructor(props){
    super(props);

    this.state = {
      windowWidth: window.innerWidth,
      reforesterShares: null
    };

    this.updateWindowWidth = this.updateWindowWidth.bind(this);
    this.getUserToZoom = this.getUserToZoom.bind(this);
    this.calculateFitboundsForFlyToMyShares = this.calculateFitboundsForFlyToMyShares.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowWidth);

    const {match: {params: {id: selectedForestId}}, sectorId, fetchReforesterShares} = this.props;
    fetchReforesterShares(selectedForestId, sectorId);

    mixpanel.track("Page view", {
      "Authenticated": getIsAutheticatedFromStore(),
      "Action": "Sectors view",
      "Forest": selectedForestId,
      "Sector": sectorId,
      "Domain": "App"
    });
  }

  componentDidUpdate(prevProps) {
    const {selectedForestId, sectorId, fetchReforesterShares} = this.props
    if(prevProps && sectorId && (sectorId !== prevProps.sectorId)) {
      fetchReforesterShares(selectedForestId, sectorId)
    }
  }

  componentWillReceiveProps(newProps) {
    if(newProps.reforesterShares){
      this.setState({reforesterShares: newProps.reforesterShares})
    }
  }
  
  // feat: find my shares in the map
  // Filter the exact share by the params sharedId and use the share's fitbounds for zoom
  getUserToZoom(id, shares){
    return id ? shares && Array.isArray(shares) && shares.filter(e => e["shareId"] === Number(id)) : null;
  }

  // feat: find my shares in the map
  // Get the share's fitbounds
  calculateFitboundsForFlyToMyShares(shares){
    return shares.length > 0 ? shares[0].fitbounds : null;
  }

  updateWindowWidth() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  render() {
    const {
      detailErrorMessages,
      errorMessages,
      fetchForestDetails,
      fetchMyForests,
      isFetchingReforesterShares,
      selectedForestId,
      sectorId,
      shareIdToZoom,
      selectedForest,
      isForestLevel,
      offsetType,
    } = this.props;

    const { reforesterShares } = this.state;

    if(!selectedForest || !selectedForest.geo_json || !reforesterShares){
      return null
    }

    if (isFetchingReforesterShares && shareIdToZoom) return <Loader />;

    if (errorMessages) {
      return (
        <FullErrorFetching
          errorMessage={errorMessages}
          retry={() => { fetchMyForests(); }}
        />
      );
    }

    if (detailErrorMessages) {
      return (
        <FullErrorFetching
          errorMessage={detailErrorMessages}
          retry={fetchForestDetails.bind(this, selectedForestId)}
        />
      );
    }

    const getMapVcusOffsetsProps = () => {
      const forestGeoJSON = selectedForest && selectedForest.geo_json
      const mapData = selectedForest
			? {
				fitbounds: selectedForest.fitbounds,
				centerMap: selectedForest.coordinates
			}
			: {}

      return {
        geojson: forestGeoJSON,
        center: mapData.centerMap,
        markers: [],
        fitbounds: mapData.fitbounds,
        forestFitbounds: mapData.fitbounds,
        mapType:'forest',
        selectedForestId: Number(selectedForestId),
        isMonitoredProject: selectedForestId && selectedForest.monitoring_level !== MONITORING_LEVEL.NOT_MONITORED,
        forestName: selectedForestId && selectedForest.name,
        isFetchingReforesterShares: this.props.isFetchingReforesterShares
      }
    }

    const getMapSqmSharesOffsetsProps = () => {
      let shareToZoom;
      let fitboundsToZoom;

      if(shareIdToZoom && reforesterShares.length > 0){
        shareToZoom = this.getUserToZoom(shareIdToZoom, reforesterShares);
    
        fitboundsToZoom = shareToZoom && this.calculateFitboundsForFlyToMyShares(shareToZoom);
    
        if(!shareToZoom || !fitboundsToZoom){
          const redirectionUrl = links.getProjectUrl(selectedForestId, sectorId)
          history.push(redirectionUrl);
        }
      }
  
      // GEOJSON for sectors
      const selectedSectorGeoData = selectedForest.sectors && selectedForest.sectors.filter(sector => Number(sector.sector_id) === Number(sectorId))[0];
      const forest_sectors = selectedForest && selectedForest.geo_json;

      const mapData = selectedSectorGeoData
        ? {
          fitbounds: shareToZoom ? fitboundsToZoom : JSON.parse(selectedSectorGeoData.fitbounds),
          forestFitBounds: JSON.parse(selectedSectorGeoData.fitbounds),
          centerMap: JSON.parse(selectedSectorGeoData.label_coords),
          surface: Number(selectedSectorGeoData.sector_surface),
          highlightedGeojsonMap: selectedSectorGeoData.geo_json
        }
        : {
          //If sector still not present, use project fitbounds to start navigating to project 
          fitbounds: selectedForest.fitbounds
        };

      const markersData = selectedSectorGeoData
        ? [{
          coords: mapData.centerMap,
          name: selectedSectorGeoData.name,
          surface: mapData.surface,
          logo: selectedSectorGeoData.logo_url
        }]
        : [];

      return {
        center: mapData.centerMap,
        markers: markersData,
        geojson: forest_sectors,
        fitbounds: mapData.fitbounds,
        forestFitBounds: mapData.forestFitbounds,
        highlightedGeojson: mapData.highlightedGeojsonMap,
        mapType: 'sector',
        reforesterShares: reforesterShares,
        shareIdToZoom: shareIdToZoom,
        sectorId,
        selectedForest,
        selectedForestId : selectedForest.id,
        isFetchingReforesterShares: this.props.isFetchingReforesterShares
      }
    }

    const renderMap = () => {
      let simpleMapProps = getMapSqmSharesOffsetsProps()

      if(offsetType === OFFSET_TYPE_CREDIT) {
        simpleMapProps = getMapVcusOffsetsProps()
      }

      if (simpleMapProps) {
        return <SimpleMap {...simpleMapProps} />
      }

      return null
    }

    const renderSectorContainer = () => {
      if(!selectedForestId) return null;

      if(offsetType === OFFSET_TYPE_CREDIT) {
        return (<VirtualSectorContainer
          selectedForestId={Number(selectedForestId) || null}
          selectedVirtualSectorId={Number(sectorId) || null}
          isForestLevel={isForestLevel}
        />)
      }

      return (<SectorContainer
        reforesterShares={this.state.reforesterShares}
        selectedForestId={Number(selectedForestId) || null}
        selectedSectorId={Number(sectorId) || null}
        isForestLevel={isForestLevel}
        shareIdToZoom={shareIdToZoom}
        history={history}
      />)
    }


    return (
      <Container>
        {this.state.windowWidth > 768
          ?
          <Content coverage="full">
            <DynamicWidth
              id="forest-map-container"
              style={{
                'width': selectedForestId ? '50%' : '100%',
              }}>
                {renderMap()}
            </DynamicWidth>
            <DynamicWidth
              style={{
                'width': selectedForestId ? '50%' : '0%',
              }}>
                {renderSectorContainer()}
            </DynamicWidth>
          </Content>
          :
          <Content coverage="full">
            <DynamicWidth
              id="forest-map-container"
              style={{
                'width': '100%',
              }}>
              {renderMap()}
            </DynamicWidth>
            <Sidebar
              className="forest-sidebar__main"
              locked={selectedForestId ? false : true}
              >
              <Box
                center
                justifyContent="space-between"
                className="forest-sidebar__title"
              >
                <HeadingSmall>
                  <FormattedMessage
                    id={'Forests.forestdetails'}
                    defaultMessage={'Forest details'}
                  />
                </HeadingSmall>
                <SidebarToggle/>
              </Box>
                {renderSectorContainer()}
            </Sidebar>
          </Content>
        }
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  reforesterShares: selector.getReforesterShares(state),
  isFetchingReforesterShares: selector.getIsFetchingReforesterShares(state)
});

MySectorContainer.propTypes = {
  detailErrorMessages: PropTypes.string,
  errorMessages: PropTypes.string,
  fetchForestDetails: PropTypes.func,
  fetchMyForests: PropTypes.func.isRequired,
  fetchReforesterShares: PropTypes.func.isRequired,
  isFetchingReforesterShares: PropTypes.bool.isRequired,
  isFetchingTrees: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  selectedForest: PropTypes.object,
  trees: PropTypes.array.isRequired,
  isForestLevel: PropTypes.bool,
  selectedForestId: PropTypes.string,
  sectorId: PropTypes.string,
  offsetType: PropTypes.oneOf([ OFFSET_TYPE_SQM_SHARES, OFFSET_TYPE_CREDIT ]).isRequired
};

export default connect(mapStateToProps, {
  fetchReforesterShares
})(MySectorContainer);
