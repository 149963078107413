import React from 'react';

const FileText = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12.8918 14.5H3.14128C2.99762 14.5 2.85984 14.4429 2.75826 14.3414C2.65668 14.2398 2.59961 14.102 2.59961 13.9583V2.04167C2.59961 1.89801 2.65668 1.76023 2.75826 1.65865C2.85984 1.55707 2.99762 1.5 3.14128 1.5H9.6418L13.4335 5.29167V13.9583C13.4335 14.0295 13.4195 14.0999 13.3922 14.1656C13.365 14.2313 13.3251 14.2911 13.2748 14.3413C13.2245 14.3916 13.1648 14.4315 13.0991 14.4588C13.0334 14.486 12.9629 14.5 12.8918 14.5Z" stroke="#1F3B21" stroke-width="1.2" stroke-linecap="round"/>
    <path d="M9.6416 1.5V5.29167H13.4338" stroke="#1F3B21" stroke-width="1.2" stroke-linecap="round"/>
    <path d="M5.85059 8.54175H10.1839" stroke="#79897A" stroke-width="1.2" stroke-linecap="square"/>
    <path d="M5.85059 10.7083H10.1839" stroke="#79897A" stroke-width="1.2" stroke-linecap="square"/>
  </svg>
);

export default FileText;
