import { axiosV1Instance, axiosV2Instance } from './';
import api from '../constants/api';
import { normalize } from 'normalizr';
import { openGallery } from './UI';
import actionTypes from '../constants/actionTypes';
import {
  forests,
  forestsArray,
  patronsResponse,
} from '../schemas/forests';
import {
  getIsFetching,
  getIsFetchingMyForests,
  getIsFetchingDetails,
  getIsFetchingReforesterShares,
  getIsFetchingPatrons,
  getIsFetchingGallery,
  getIsFetchingContributors,
  getIsFetchingTransactions,
  getIsFetchingVintages,
} from '../selectors/forests';


export const forestSummary = () => ({
  shouldCallAPI: state => !getIsFetching(state),
  callAPI: () => axiosV1Instance.get(api.FOREST_SUMMARY),
  actions: [
    actionTypes.FORESTS_SUMMARY_START,
    {
      type: actionTypes.FORESTS_SUMMARY_COMPLETE,
      payload: response => {
        const { forests } = response.data.data;
        // INTERCOM: Update forests sqm attribute ==========
        if (forests.length > 0) {
          const totalSqms = forests.reduce((totalSqms, forest) => {
            return forest.user_total_surface > 0
              ? totalSqms += forest.user_total_surface
              : totalSqms;
            }, 0);
            window.Intercom('update', {
              "forests_sqms": totalSqms,
            });
        }
        // ==========

        return normalize(forests, forestsArray);
      }
    },
    {
      type: actionTypes.FORESTS_SUMMARY_ERROR,
      payload: response => response.data
    }
  ],
});

export const fetchMyForests = () => ({
  shouldCallAPI: state => !getIsFetchingMyForests(state),
  callAPI: () => axiosV1Instance.get(api.MY_FORESTS),
  actions: [
    actionTypes.MY_FORESTS_START,
    {
      type: actionTypes.MY_FORESTS_COMPLETE,
      payload: response => {
        return normalize(response.data.data.forests, forestsArray);
      }
    },
    {
      type: actionTypes.MY_FORESTS_ERROR,
      payload: response => response,
    }
  ],
});

export const fetchForests = () => ({
  shouldCallAPI: state => !getIsFetching(state),
  callAPI: (state) =>  axiosV1Instance.get(api.FORESTS, { withCurrencyHeader: true }),
  actions: [
    actionTypes.FORESTS_FETCH_START,
    {
      type: actionTypes.FORESTS_FETCH_COMPLETE,
      payload: response => normalize(response.data.data.forests, forestsArray)
    },
    {
      type: actionTypes.FORESTS_FETCH_ERROR,
      payload: response => response,
    },
  ],
});

export const fetchForestDetails = (id) => ({
  shouldCallAPI: state => !getIsFetchingDetails(state),
  callAPI: () =>  axiosV1Instance.get(api.FORESTS + id),
  actions: [
    actionTypes.FORESTS_DETAILS_START,
    {
      type: actionTypes.FORESTS_DETAILS_COMPLETE,
      payload: response => normalize(response.data.data, forests),
    },
    actionTypes.FORESTS_DETAILS_ERROR,
  ]
});

export const fetchReforesterShares = (selectedForestId, sectorId) => ({
  shouldCallAPI: state => !getIsFetchingReforesterShares(state),
  callAPI: () => axiosV2Instance.get(`${api.REFORESTER_SHARES}${selectedForestId}/sector/${sectorId}/forest-shares`),
  actions: [
    actionTypes.REFORESTER_SHARES_START,
    {
      type: actionTypes.REFORESTER_SHARES_COMPLETE,
      payload: response => ({
        reforesterShares: response.data.data
      }),
    },
    actionTypes.REFORESTER_SHARES_ERROR,
  ]
});

export const resetForestPatrons = () => ({
  type: actionTypes.FOREST_PATRONS_RESET,
});

export const fetchForestPatrons = ({forestId, sectorId}, pageIndex, pageLength, ) => ({
  shouldCallAPI: state => !getIsFetchingPatrons(state),
  callAPI: () => {
    const url = sectorId ? `${api.FORESTS}${forestId}/${sectorId}${api.PATRONS}` : `${api.FORESTS}${forestId}${api.PATRONS}`
    return axiosV1Instance.post(url, {
        "size": pageLength,
        "page": pageIndex
    });
  },
  actions: [
    actionTypes.FOREST_PATRONS_START,
    {
      type: actionTypes.FOREST_PATRONS_COMPLETE,
      payload: response => normalize(response.data.data, patronsResponse)
    },
    actionTypes.FOREST_PATRONS_ERROR,
  ],
  payload: {
    page: pageIndex,
    forestId: forestId,
    sectorId: sectorId,
  }
});

export const resetForestContributors = () => ({
  type: actionTypes.FOREST_CONTRIBUTORS_RESET,
});

export const fetchForestContributors = ({forestId}, pageIndex, pageLength, ) => ({
  shouldCallAPI: state => !getIsFetchingContributors(state),
  callAPI: () => {
    const url = `${api.CONTRIBUTORS}?project=${forestId}&perPage=${pageLength || 10}&currentPage=${pageIndex || 1}`
    return axiosV2Instance.get(url);
  },
  actions: [
    actionTypes.FOREST_CONTRIBUTORS_START,
    {
      type: actionTypes.FOREST_CONTRIBUTORS_COMPLETE,
      payload: response => response.data
    },
    actionTypes.FOREST_CONTRIBUTORS_ERROR,
  ],
  payload: {
    page: pageIndex,
    forestId: forestId,
  }
});

export const resetProjectVintages = () => ({
  type: actionTypes.PROJECT_VINTAGES_RESET,
});

export const fetchProjectVintages = ({projectId}) => ({
  shouldCallAPI: state => !getIsFetchingVintages(state),
  callAPI: () => {
    const url = `${api.PROJECT_VINTAGES}?project=${projectId}`
    return axiosV2Instance.get(url);
  },
  actions: [
    actionTypes.PROJECT_VINTAGES_START,
    {
      type: actionTypes.PROJECT_VINTAGES_COMPLETE,
      payload: response => response.data
    },
    actionTypes.PROJECT_VINTAGES_ERROR,
  ],
  payload: {
    projectId: projectId
  }
})

export const resetTransactions = () => ({
  type: actionTypes.FOREST_TRANSACTIONS_RESET,
});

export const fetchTransactions = ({forestId}, pageIndex, pageLength, ) => ({
  shouldCallAPI: state => !getIsFetchingTransactions(state),
  callAPI: () => {
    const url = `${api.TRANSACTIONS}?project=${forestId}&perPage=${pageLength || 10}&currentPage=${pageIndex || 1}`
    return axiosV2Instance.get(url);
  },
  actions: [
    actionTypes.FOREST_TRANSACTIONS_START,
    {
      type: actionTypes.FOREST_TRANSACTIONS_COMPLETE,
      payload: response => {
        return {
          ...response.data,
          forestId,
        }
      }
    },
    actionTypes.FOREST_TRANSACTIONS_ERROR,
  ],
  payload: {
    page: pageIndex,
    forestId: forestId,
  }
});

export const fetchForestGallery = (id) => ({
  shouldCallAPI: state => !getIsFetchingGallery(state),
  callAPI: () => axiosV1Instance.get(`${api.FORESTS}${id}${api.GALLERY}`),
  actions: [
    actionTypes.FOREST_GALLERY_START,
    {
      type: actionTypes.FOREST_GALLERY_COMPLETE,
      payload: (response, dispatch) => {
        dispatch(openGallery(response.data.data.items));
      }
    },
    actionTypes.FOREST_GALLERY_ERROR,
  ],
  payload: { id }
});

export const fetchForestAboutGallery = (id) => ({
  shouldCallAPI: state => true,
  callAPI: () => axiosV1Instance.get(`${api.FORESTS}${id}${api.GALLERY}`),
  actions: [
    actionTypes.FOREST_GALLERIES_START,
    {
      type: actionTypes.FOREST_GALLERIES_COMPLETE,
      payload: response => ({
        images: response.data.data.items,
        id,
      })
    },
    actionTypes.FOREST_GALLERIES_ERROR,
  ],
  payload: { id },
});

export const fetchForestLatestActivity = (forestId, sectorId=null) => ({
  shouldCallAPI: state => true,
  callAPI: () => axiosV1Instance.get(sectorId ? `${api.FORESTS}events/${forestId}/${sectorId}` : `${api.FORESTS}events/${forestId}`),
  actions: [
    actionTypes.FOREST_LATEST_ACTIVITY_START,
    {
      type: actionTypes.FOREST_LATEST_ACTIVITY_COMPLETE,
      payload: response => ({
        latestActivity: response.data.data.events,
      }),
    },
    actionTypes.FOREST_LATEST_ACTIVITY_ERROR,
  ]
});

export const fetchForestMrvGraph = (id) => ({
  shouldCallAPI: () => true,
  callAPI: () => axiosV2Instance.get(`${api.FORESTS}${id}/mrv`),
  actions: [
    actionTypes.FOREST_MRV_GRAPH_START,
    {
      type: actionTypes.FOREST_MRV_GRAPH_COMPLETE,
      payload: response => ({
        mrvGraph: response.data.data,
      }),
    },
    actionTypes.FOREST_MRV_GRAPH_ERROR,
  ]
});

export const setIsImpactTabOpened = (isOpened) => ({
  type: actionTypes.FOREST_IMPACT_TAB_OPENED,
  payload: isOpened
  });

export const showOnlyGraphControlledRasterSources = (showOnly) => ({
  type: actionTypes.FOREST_UPDATE_SHOW_ONLY_GRAPH_CONTROLLED_LAYERS,
  payload: showOnly,
})


export const setGraphControlledRasterSources = (graphControlledRasterSources) => ({
  type: actionTypes.FOREST_UPDATE_MRV_RASTER_SOURCES,
  payload: { ...graphControlledRasterSources }
});

export const resetMrv = () => ({
  type: actionTypes.FOREST_RESET_MRV,
  payload: {}
});

export const setHideMrvSnack = () => ({
  type: actionTypes.FORESTS_HIDE_MRV_SNACK,
});
