import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';


import './PreferencesForm.css';
import Row from '../../../UI/Forms/Row';
import {Button} from '@reforestum/shared-components';
import { getCurrencyOptions, getDistanceOptions, getLanguageOptions, getTempUnitsOptions, getTonTypeOptions, getWeightOptions } from '../../../../utils/getPreferencesOptions';
import { isEqual } from 'lodash';
import CustomSelect from '../../../UI/Forms/CustomSelectNew';


class PreferencesForm extends Component {
    constructor(props) {
        const { intl } = props;
        super(props);

        const { initialValues } = props;
        this.state = {
            languageOptions: getLanguageOptions(intl),
            currenyOptions: getCurrencyOptions(intl),
            tempUnitsOptions: getTempUnitsOptions(intl),
            distanceOptions: getDistanceOptions(intl),
            weightOptions: getWeightOptions(intl),
            tonTypeOptions: getTonTypeOptions(intl),
            initialized: Boolean(initialValues),
            updatingUser: false,
            fields: initialValues ? {...initialValues, lang: initialValues.lang.toLowerCase()} : null,
        }
        this.submitForm = this.submitForm.bind(this);
        this.optionSelected = this.optionSelected.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const { initialValues } = nextProps;
        const { addAlert } = this.props;
        const { initialized } = this.state;
        
        //Sets the initial values of the form
        if(initialValues && !initialized) {
            this.setState({
                fields: {
                    ...initialValues,
                   lang: initialValues.lang.toLowerCase()
                },
                initialized: false,
            })
        }

        //Shows update user error message if the user update returns an error
        if(nextProps.updateErrorMessages && (nextProps.updateErrorMessages !== this.props.updateErrorMessages)) {
            addAlert({
                type: 'error',
                message: nextProps.updateErrorMessages,
                dismissAfter: 5000,
            })
        }
    }

    optionSelected(value, options) {
        return (value !== null) ? options.find(option => option.value === value) : null
    }

    handleFieldChange(fieldName) { 
        return (field) => {
            const { fields } = this.state;
            this.setState({
                fields: {
                    ...fields,
                    [fieldName]: field.value,
                }
            })
        }
    }

    submitForm(event) {
        // prevent page refresh
        event.preventDefault();

        const { fields } = this.state;
        const { updateUser, refreshProfile, intl } = this.props;
        this.setState({
            updatingUser: true,
        })

        updateUser({
            form: {
                preferences: fields,
            },
            intl
        }).then(() => {
            refreshProfile().then(() => {
                this.setState({
                    updatingUser: false,
                })
            })
        })
    };

    render() {
        const {
            loadingInitialValues,
            initialValues,
        } = this.props;

        const {
            languageOptions,
            currenyOptions,
            tempUnitsOptions,
            distanceOptions,
            weightOptions,
            tonTypeOptions,
            updatingUser,
            fields } = this.state;

        const {
            lang,
            currency,
            temp_celsius,
            metric_distance,
            metric_weight,
            big_weight,
        } = fields || {};

        const dirty = !isEqual(fields, {...initialValues, lang: initialValues.lang.toLowerCase()});

        return (
                <form onSubmit={this.submitForm} className="personal-information-form-container">         
                    <Row>
                            <CustomSelect
                                disabled={loadingInitialValues || updatingUser}
                                label={<FormattedMessage id="Form.preferenceLanguage" defaultMessage="Language"/>}
                                value={this.optionSelected(lang, languageOptions)}
                                options={languageOptions}
                                onChange={this.handleFieldChange('lang')}
                            />
                            <CustomSelect
                                disabled={loadingInitialValues || updatingUser}
                                label={<FormattedMessage id="Form.preferenceCurrency" defaultMessage="Currency"/>}
                                value={this.optionSelected(currency, currenyOptions)}
                                options={currenyOptions}
                                onChange={this.handleFieldChange('currency')}
                                isSearchable={false}
                            />
                    </Row>
                    <Row>
                            <CustomSelect
                                disabled={loadingInitialValues || updatingUser}
                                label={<FormattedMessage id="Form.preferenceTemperatureUnits" defaultMessage="Temperature units"/>}
                                value={this.optionSelected(temp_celsius, tempUnitsOptions)}
                                options={tempUnitsOptions}
                                onChange={this.handleFieldChange('temp_celsius')}
                            />
                            <CustomSelect
                                disabled={loadingInitialValues || updatingUser}
                                label={<FormattedMessage id="Form.preferenceAreaUnits" defaultMessage="Area units"/>}
                                value={this.optionSelected(metric_distance, distanceOptions)}
                                options={distanceOptions}
                                onChange={this.handleFieldChange('metric_distance')}
                            />
                    </Row>
                    <Row>
                            <CustomSelect
                                disabled={loadingInitialValues || updatingUser}
                                label={<FormattedMessage id="Form.preferenceWeightUnits" defaultMessage="Weight units"/>}
                                value={this.optionSelected(metric_weight, weightOptions)}
                                options={weightOptions}
                                onChange={this.handleFieldChange('metric_weight')}
                            />
                            <CustomSelect
                                disabled={loadingInitialValues || updatingUser}
                                label={<FormattedMessage id="Form.preferenceTonType" defaultMessage="Ton type"/>}
                                value={this.optionSelected(big_weight, tonTypeOptions)}
                                options={tonTypeOptions}
                                onChange={this.handleFieldChange('big_weight')}
                            />
                    </Row>
                    <Row>
                        <Button
                            disabled={loadingInitialValues || updatingUser || (!(dirty))}
                            type="submit"
                            className='personal-information-form-submit-button'
                            loading={loadingInitialValues || updatingUser}
                        >
                            <FormattedMessage id="Form.updateInforamtionButton" defaultMessage="Update Information"/>
                        </Button>
                    </Row>
                </form>
        )
    }
}

PreferencesForm.propTypes = {
    initialValues: PropTypes.object,
    intl: PropTypes.object,
    countries: PropTypes.array,
    loadingInitialValues: PropTypes.bool,
    updateUser: PropTypes.func,
    updateErrorMessages: PropTypes.string,
    /* redux form */
    handleSubmit: PropTypes.func,
};

export default injectIntl(PreferencesForm);
