import React from 'react'

export default ({ className }) =>
<svg
  className={className} 
  width="10"
  height="18"
  viewBox="0 0 10 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path d="M9.4998 0.666656H6.9998C5.89473 0.666656 4.83492 1.10564 4.05352 1.88705C3.27212 2.66845 2.83313 3.72826 2.83313 4.83333V7.33333H0.33313V10.6667H2.83313V17.3333H6.16646V10.6667H8.66646L9.4998 7.33333H6.16646V4.83333C6.16646 4.61231 6.25426 4.40035 6.41054 4.24407C6.56682 4.08779 6.77878 3.99999 6.9998 3.99999H9.4998V0.666656Z" fill="white"/>
</svg>
