// Default image for errors on rendering images
export const defaultLandingImage = "https://statics.reforestum.com/enterprise/offset_landing_default_image.png";

export const ACTIVATION_STATUS = {
    ACTIVATED: 'activated',
    PENDING: 'pending',
    CANCELLED: 'cancelled'
}

export const ENTERPRISE_ACTIVATION_VIEWS = {
    REGISTER: 'register',
    LOGIN: 'login'
}

export const OFFSET_ACTIVATION_TYPES = {
    B2B2B: 'B2B2B',
    B2B2C: 'B2B2C'
}

// User activation landing page - Grosvenor case (hardcoded)
export const GROSVENOR_LOGO = "https://reforestum-static-images.s3.eu-central-1.amazonaws.com/logos/grosvernor.jpeg"