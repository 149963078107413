import React from 'react';

const Seed = props => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M8 2.40039C8 4.64772 8 8.24202 8 9.40039" stroke="#79897A" strokeWidth="1.2" strokeLinecap="square"/>
    <path d="M3.68465 9.17027C4.19115 6.63958 5.81916 3.5161 7.97735 1.77092C8.88959 2.59466 9.79491 3.72389 10.5532 4.96367C11.4104 6.36508 12.0544 7.86648 12.3154 9.17027C12.4851 10.0186 12.3983 10.8979 12.0657 11.6973C11.7332 12.4966 11.1696 13.1804 10.4459 13.6616C9.72222 14.1429 8.87101 14.4 8 14.4C7.12899 14.4 6.27778 14.1429 5.55407 13.6616C4.83038 13.1804 4.26684 12.4966 3.93426 11.6973C3.60169 10.8979 3.51487 10.0186 3.68465 9.17027Z" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"/>
  </svg>
);

export default Seed;
