import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
  FormattedMessage,
  FormattedNumber,
  intlShape,
  injectIntl 
} from 'react-intl';
import FormattedUnit from '../Utils/FormattedUnit';
import Box from 'react-layout-components';

import { getFormattedArea, getFormattedCredits } from '../../utils/units';
import { carbonAmountIn } from '../../utils/carbon';
import getFeatureFlags from "../../constants/featureFlags";

import Container from '../Layout/Container/Container';
import Content from '../Layout/Container/Content';

import Heading from '../UI/Text/Heading';
import Text from '../UI/Text/Text';
import HeadingSmall from '../UI/Text/HeadingSmall';
import { ButtonLink } from '@reforestum/shared-components';
import Forest from '../Forests/Create/Forest';

import Cluster from '@reforestum/shared-components/dist/assets/images/cluster.png';

import { OFFSET_TYPE_SQM_SHARES } from '../../constants/offsetTypes';

import { links } from '@reforestum/shared-utils';

import './ThankYou.css';

class ThankYou extends Component {

  constructor(props) {
    super(props)
    this.renderAppropiateButton = this.renderAppropiateButton.bind(this)
  }

  renderAppropiateButton() {
    const ff = getFeatureFlags();
    const {lastPurchaseInformation, forest} = this.props;
    if(lastPurchaseInformation && lastPurchaseInformation.sector_share_id && ff.findMyShares) {
      return (
        <ButtonLink
          big
          to={links.getProjectUrl(lastPurchaseInformation.forest_id, lastPurchaseInformation.sector_id, lastPurchaseInformation.sector_share_id)}
        >
          <FormattedMessage
            id={'checkoutMessages.goToMyShares'}
            defaultMessage={'Go to my shares'}
          />
        </ButtonLink>
      )
    } else if(lastPurchaseInformation && lastPurchaseInformation.forest_virutal_sector_id) {
      return (
        <ButtonLink
          big
          to={`${links.getProjectUrl(lastPurchaseInformation.forest_id, lastPurchaseInformation.forest_virutal_sector_id)}/my-shares`}
        >
          <FormattedMessage
            id={'checkoutMessages.goToForest'}
            defaultMessage={'Go to {forestName}'}
            values={{ forestName: forest.name }}
          />
        </ButtonLink>
      )
    } else {
      return (
        <ButtonLink
          big
          to={`${links.getProjectUrl(forest.id)}/my-shares`}
        >
          <FormattedMessage
            id={'checkoutMessages.goToForest'}
            defaultMessage={'Go to {forestName}'}
            values={{ forestName: forest.name }}
          />
        </ButtonLink>
      )
    }

  }


  render(){
    const {
      forest,
      quantity,
      offsetType,
      price,
      currency,
    } = this.props;

    if(!forest){
      return null;
    }

    const isOffsetTypeBySqm = (forest.offset_type === OFFSET_TYPE_SQM_SHARES);
    const formattedQuantity = isOffsetTypeBySqm? getFormattedArea(quantity) : getFormattedCredits({credits: quantity});

    return (
      <Container>
        <Content>
          <Box column className="thanks">
            <div className="thanks__tree-holder">
              <img className="thanks__tree" alt="cluster" src={Cluster} />
              <img className="thanks__tree" alt="cluster" src={Cluster} />
              <img className="thanks__tree" alt="cluster" src={Cluster} />
            </div>
            <div className="thanks__text">
              <Heading>
                <FormattedMessage
                  id={'checkoutMessages.youNowOwn'}
                  defaultMessage={'You are now a forest owner'}
                />
              </Heading>
              <br/>
              <HeadingSmall color="light">
                <FormattedMessage
                  id={'checkoutMessages.congratulations'}
                  defaultMessage={'Congratulations, you have successfully bought {value} {unit} at {forestName}'}
                  values={{
                    value: (<Text color="green" tag="span"><FormattedNumber value={formattedQuantity.value}/></Text>),
                    unit: (<Text color="green" tag="span"><FormattedUnit unit={formattedQuantity.unit} /></Text>),
                    forestName: forest.name,
                  }}
                />
              </HeadingSmall>
            </div>

            <div className="thanks__forest">
              <Forest
                available={forest.available}
                key={forest.id}
                forest={forest}
                purchasable={true}
                getSelectedOffsetData={()=> {
                  return {
                    price: price,
                    offsetType: offsetType,
                    quantity: quantity,
                    carbon: isOffsetTypeBySqm ? carbonAmountIn(forest.co2_years, forest.timeline_formula, quantity) : quantity * 1000,
                  };
                }}
                thankYou={true}
                currency={currency}
              />
            </div>
            {this.renderAppropiateButton()}
          </Box>
        </Content>
      </Container>
    );
  }
}

ThankYou.propTypes = {
  forest: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  offsetType: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  intl: intlShape
};

export default injectIntl(ThankYou);
