import api from "../../constants/api"
import {getUserRequestHeaders} from "./getUserRequestHeaders"

export const querySearch = async searchTerm => {
	searchTerm = encodeURIComponent(searchTerm)
	let result = {data: {companies: [], projects: []}}
	try {
		result = await fetch(
			`${api.SEARCH}${searchTerm ? `?term=${searchTerm}` : ''}`,
			{headers: getUserRequestHeaders()}
		)
		result = await result.json()
	}
	catch(e) {
		console.warn('Error performing search', e)
	}
	return result
}
