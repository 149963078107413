import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { requiredIf } from '../../../utils/requiredIf';

import Small from '../Text/Small';

import './Textarea.css';

class Textarea extends Component {

  render() {
    const {
      action,
      className,
      disabled = false,
      input,
      label,
      meta,
      placeholder,
      type = 'text'
    } = this.props;

    return (
      <div className={
        cx(
          className,
          { 'textarea-holder--disabled': disabled }
        )}
        onClick={() => { this[input.name].focus(); }}>
        <div className="main-textarea">
          { label &&
            <div className="main-textarea__header">
              <Small
                tag="label"
                className="main-textarea__label">
                {label}
              </Small>

              { action &&
                <Small
                  tag="span"
                  className="main-textarea__action"
                  onClick={action.action}>
                  {action.label}
                </Small>
              }

            </div>
          }

          {
            <textarea
              {...input}
              className="main-textarea__textarea"
              placeholder={placeholder}
              type={type}
              disabled={disabled}
              ref={node => this[input.name] = node}
            />
          }
        </div>
          { meta.touched && meta.error &&
            <div className="main-textarea__error">
              <Small color="red">{meta.error}</Small>
            </div>
          }
      </div>
    );
  }
}

Textarea.defaultProps = {
  disabled: false,
  type: 'text'
};

Textarea.propTypes = {
  /** Label required to use */
  action: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired
  }),
  className: PropTypes.string,
  /** If this is a select, we need the children props */
  disabled: PropTypes.bool,
  /** Will be provided by redux-form */
  input: PropTypes.object.isRequired,
  /** Label is required if there's an action */
  label: requiredIf( PropTypes.string, props => props.action),
  /** Will be provided by redux-form */
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

export default Textarea;
