import React, { Component } from 'react';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip
} from 'recharts';
import PropTypes from 'prop-types';

import Text from '../../UI/Text/Text';
import GraphCircle from '../CarbonGraph/GraphCircle';
import CustomizedYAxis from '../../UI/rechart/CustomizedYAxis';
import CustomizedDot from './CustomizedDot';

import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en';
import fr from 'date-fns/locale/fr';

import { intlShape, injectIntl, FormattedMessage, FormattedNumber } from 'react-intl';
import { MRVGraphMessages, MRVLinkToWebsite } from '../../../constants/messages';

import { getFormattedWeight, formatUnit } from '../../../utils/units';

import '../CarbonGraph/Graph.css';
import './MRVGraph.css';

import { fetchForestMrvGraph, setGraphControlledRasterSources } from '../../../actions/forests';

import { connect } from 'react-redux';
import Loader from '../../UI/Loader/Loader';
import FormattedUnit from '../../Utils/FormattedUnit';
import HeadingSmall from '../../UI/Text/HeadingSmall';
import { getGraphLegendMessageText } from '../../../utils/getGraphLegend';
import Tooltip from '../../UI/Tooltip/Tooltip';
import { getGraphLineFinalPartStroke, getGraphLineInitialPartFill, getGraphLineInitialPartStroke, updateGraphStroke } from '../../../utils/graph/graphAnimationHelper';

const MOUSE_STAY_TIME_TO_LOAD_MRV = 500;
const TIME_MOVING_MOUSE_TO_SHOW_MAP_LOADED_DATAPOINT = 1000;
const FIRST_PART_OF_GRAPH_STROKE_ID = 'firstPartGraphStroke'
const LAST_PART_OF_GRAPH_STROKE_ID = 'lastPartGraphStroke'
const FIRST_PART_OF_GRAPH_FILL_ID = 'firstPartGraphFill'
const GRAPH_FILL_COLOR = '#60B47D'

class MRVGraph extends Component {
  constructor() {
    super();
    this.state = {
      selectedYearIndex: 0,
      mrvDataPoints: undefined,
      carbonGraphDefsId: `carbonGraphDefsId-${new Date().getTime()}`
    };
    this.mrvLayerTimer = undefined;
    this.renderTooltip = this.renderTooltip.bind(this);
    this.onHoverGraph = this.onHoverGraph.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);

    //This variable is not in the state because setting them will interfere with the graph render
    //There is no need to store it in the state anyways as there is no need to force a rerender when it changes
    this.activeDotCoordinates = null
    this.percentageToShow = 0
    this.firstPointGraphCoords = null
    this.lastPointGraphCoords = null
    this.mapLayerDatapoint = null
  }

  componentDidMount() {
    const { fetchForestMrvGraph, forestId } = this.props;
    fetchForestMrvGraph(forestId);
  }

  componentDidUpdate() {
    const { isFetching, mrvGraph } = this.props.mrvGraph;
    const mrvDataPoints = this.state.mrvDataPoints;

    //Skip update if mrvGraph is not received yet or if it is already loaded
    if (isFetching || Object.keys(mrvGraph).length === 0 || mrvDataPoints !== undefined) {
      return
    }

    const newMrvDataPoints = mrvGraph.timestamp.map((dataPoint) => {
      const locales = { en, es, fr};
      const tiles = this.extractTilesFromDatapoint(dataPoint, mrvGraph);
      const date = new Date(dataPoint.id_timestamp);
      const formattedDate = format(date, 'DD/MM/YYYY', { locale: locales[this.props.intl.locale] })
      const dateTooltip = format(date, 'D MMMM YYYY', { locale: locales[this.props.intl.locale] }).toUpperCase()
      return {
        ...dataPoint, 
        dateTooltip: dateTooltip, 
        timestamp: formattedDate, 
        tiles: tiles, 
        date: date,
        // We multiply by 1000 because carbon_stock_total received from backend is in tons
        carbon: parseInt(dataPoint.value * 1000),
        vcusIssued: dataPoint.vcu_issued,
      }
    });

    // I can use setState here becouse there is a condition on top to avoid the infinite loop
    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({mrvDataPoints: newMrvDataPoints})
      
    const lastDataPointIndex = newMrvDataPoints.length - 1;
    this.props.setGraphControlledRasterSources({
        timestamp: newMrvDataPoints[lastDataPointIndex].timestamp,
        urls: [
          ...newMrvDataPoints[lastDataPointIndex].tiles
        ]
      })
  }

  componentWillUnmount() {
    clearInterval(this.mrvLayerTimer);
  }
  
  extractTilesFromDatapoint(dataPoint, mrvGraph) {
      return Object.keys(dataPoint.tiles).map((tileKey) => {
        const tile = dataPoint.tiles[tileKey];
        return {
          url: tile + (mrvGraph["tile_params"][tileKey]["url_params"] || ''),
          legend: mrvGraph["tile_params"][tileKey]["legend_img"],
          id: tileKey,
          defaultChecked: mrvGraph["tile_params"][tileKey]["selected"],
          titleId: mrvGraph["tile_params"][tileKey]["title_id"],
        }
      });
  }

  onHoverGraph(event) {
      /* This logic has been moved to the renderTooltip method, that is called every time the user move the mouse over the graph
         The intention of it is to be consistent between the datapoint and tooltip shown, and the layer shown on the map so it is 
         more clear to the user wich layer corresponds to wich page
      */

      /*
            const mrvData = event.activePayload && event.activePayload[0] && event.activePayload[0].payload;
            if (mrvData === undefined) {
              return;
            }
          
            clearInterval(this.mrvLayerTimer);
            this.mrvLayerTimer = setTimeout(() => this.props.setGraphControlledRasterSources({
              timestamp: mrvData.timestamp,
              urls: mrvData.graphControlledRasterSources
            }), MOUSE_STAY_TIME_TO_LOAD_MRV);
      */
  }

  handleMouseLeave() {
    clearInterval(this.mrvLayerTimer);
  }


  renderTooltip() {
    const { 
      intl, 
      mrvGraph : {mrvGraph} 
      } = this.props;
    return (tooltipProps) => {
      const mrvData = tooltipProps.payload[0] ? tooltipProps.payload[0].payload : {};

      const {
        mrvDataPoints,
        carbonGraphDefsId
      } = this.state

      let isFirstHalfOfPoints
      let cx
      let cy
      let percentageToHiglight
      let formattedCarbon
      let vcuIssuedToRender
      let dateTooltipToRender

      if(tooltipProps.active) {
        const {
          dateTooltip,
          carbon,
          vcusIssued,
        } = mrvData;

        //Set tooltip data
        vcuIssuedToRender = vcusIssued
        dateTooltipToRender = dateTooltip
        isFirstHalfOfPoints = (this.activeDotCoordinates.index / mrvDataPoints.length) < 0.5
        cx = this.activeDotCoordinates && this.activeDotCoordinates.cx
        cy = this.activeDotCoordinates && this.activeDotCoordinates.cy
        formattedCarbon = getFormattedWeight(carbon);


        //Update graph percentage highlighted
        percentageToHiglight = (this.firstPointGraphCoords && this.lastPointGraphCoords) ?
          (
            100 *
            (this.activeDotCoordinates.cx - this.firstPointGraphCoords.cx) /
            (this.lastPointGraphCoords.cx - this.firstPointGraphCoords.cx)
          ) :
          0
        this.percentageToShow = updateGraphStroke(percentageToHiglight, this.percentageToShow, carbonGraphDefsId)

        //Update map layers with the new datapoint
        clearInterval(this.mrvLayerTimer)
        this.mrvLayerTimer = setTimeout(() => {
          //When there are not tiles for the specific datapoint, we keep the map layers of the last datapoint with tiles
          if (mrvData.tiles && mrvData.tiles.length > 0) {
          this.props.setGraphControlledRasterSources({
            timestamp: mrvData.timestamp,
            urls: mrvData.graphControlledRasterSources
          })
        this.mapLayerDatapoint = {
          cx: cx, 
          cy: cy, 
          index: this.activeDotCoordinates.index,
          pointLoadedTimestamp: new Date().getTime(),
          isFirstHalfOfPoints: (this.activeDotCoordinates.index / mrvDataPoints.length) < 0.5
        }
        //This will hide the last Point Rendered tooltip after the current selected poin is rendered
        this.forceUpdate()
        }
        }, MOUSE_STAY_TIME_TO_LOAD_MRV);
    } else {
      /*
        This condition shows the latest hovered point of the graph that has update the map layers (has been active for more than MOUSE_STAY_TIME_TO_LOAD_MRV)
        or the latest active point if the mouse is out of the graph but the graph has been already touched
      */
     const pointToRender = this.activeDotCoordinates ?  this.mapLayerDatapoint : this.lastPointGraphCoords
     if(!pointToRender) return
     vcuIssuedToRender = mrvDataPoints[pointToRender.index].vcusIssued
     dateTooltipToRender = mrvDataPoints[pointToRender.index].dateTooltip
     isFirstHalfOfPoints = (pointToRender.index / mrvDataPoints.length) < 0.5
      cx = pointToRender && pointToRender.cx
      cy = pointToRender && pointToRender.cy
      percentageToHiglight = (this.firstPointGraphCoords && this.lastPointGraphCoords) ?
        (
          100 *
          (pointToRender.cx - this.firstPointGraphCoords.cx) /
          (this.lastPointGraphCoords.cx - this.firstPointGraphCoords.cx)
        ) :
        0
      this.percentageToShow = updateGraphStroke(percentageToHiglight, this.percentageToShow, carbonGraphDefsId)
      formattedCarbon = getFormattedWeight(mrvDataPoints[pointToRender.index].carbon);

    }


      return (
        <div>
        <div 
          className="carbon-graph__tooltip-container"
          //This style change will position the tooltip in the active dot
          style={{transform: `translate(${cx}px, ${cy}px)`}}
        >
        <Tooltip
          className={'carbon-graph__tooltip-content'}
          alwaysOpen
          style={{marginLeft: '4px'}}
          position={isFirstHalfOfPoints ? 'top-right' : 'top-left'}
          content={
            <div>
              <div className="carbon-graph__tooltip-year">{dateTooltipToRender}</div>
              <div className="carbon-graph__tooltip-value">
                {`${intl.formatNumber(formattedCarbon.value)} ${formatUnit(intl, formattedCarbon.unit)}`}
              </div>
              <div className="carbon-graph__tooltip-label">
                {getGraphLegendMessageText(intl, mrvGraph["legend_id"])}
              </div>
              {vcuIssuedToRender && 
                <div className="carbon-graph__tooltip-value">
                  {`${intl.formatNumber(vcuIssuedToRender)} VCUs`}
                </div>
              }
              {vcuIssuedToRender && 
                <div className="carbon-graph__tooltip-label">
                  {intl.formatMessage(MRVGraphMessages.creditsIssuedTooltip)}
                </div>
              }
              {cx === this.mapLayerDatapoint.cx && !this.props.renderedWithoutMap &&
                <div className="carbon-graph__tooltip-snack">
                  {intl.formatMessage(MRVGraphMessages.currentlyLoadedDatapointTooltip)}
                </div>
              }
            </div>
          }
        >
          <div className={'carbon-graph__tooltip'}></div>
        </Tooltip>
        {/*The following tooltip is render when the actual hovered datapoint in snot the one shown on the map
            Letting the user known that to render a new point it needs to stop the cursor in a certain point
        */}
        </div>
          {this.mapLayerDatapoint && (this.mapLayerDatapoint.cx !== cx) && !this.props.renderedWithoutMap &&
            ((new Date().getTime() - this.mapLayerDatapoint.pointLoadedTimestamp) > TIME_MOVING_MOUSE_TO_SHOW_MAP_LOADED_DATAPOINT) &&
            <div  className="carbon-graph__tooltip-container"
              //This style change will position the tooltip in the active dot
              style={{transform: `translate(${this.mapLayerDatapoint.cx}px, ${this.mapLayerDatapoint.cy}px)`}}>
                    
              <Tooltip
                className={'mrv-graph__last-point-rendered-tooltip-content'}
                position={this.mapLayerDatapoint.isFirstHalfOfPoints ? 'bottom-right' : 'bottom-left'}
                alwaysOpen
                color="#FDC684"
                style={{marginRight: '4px'}}
                content={
                  <div>
                    <div className="mrv-graph__last-point-rendered-tooltip-year">{
                      mrvDataPoints[this.mapLayerDatapoint.index].dateTooltip
                    }</div>
                    <div className="mrv-graph__last-point-rendered-tooltip-text">
                      {intl.formatMessage(MRVGraphMessages.currentlyLoadedDatapointTooltip)}
                    </div>
                  </div>
                }
              >
                <div className={'carbon-graph__tooltip'}></div>
              </Tooltip>
            </div>
          }
        </div>
      );
    }
  }
    
    selectYear(year) {
        this.setState({
          selectedYearIndex: year - 1
        });
    }

  render() {
    const { mrvDataPoints } = this.state;
      const dataPointsCalculated = mrvDataPoints ? mrvDataPoints.map((el) => {
        return {
          ...el,
          year: new Date(el.id_timestamp).getFullYear(),
          timestamp: el.timestamp,
          dateTooltip: el.dateTooltip,
          graphControlledRasterSources: el.tiles,
          date: el.date,
        }
      }) : []
        const { intl, forestName, totalVcus, mrvGraph : {mrvGraph}  } = this.props;
        const { locale } = intl;
        const { selectedYearIndex, carbonGraphDefsId } = this.state;

        const totalCarbonAvoided = getFormattedWeight(totalVcus * 1000);

        return (
          <div>
            <div>
              {totalVcus && totalVcus !== 0 && <HeadingSmall className="impact-container-info__heading">
                <FormattedMessage 
                    id={'Impact.welcomeTitleConservation'}
                    defaultMessage={'Since the {forest} project started, over {carbon} of CO2 emissions have been avoided'}
                    values={{
                        forest: forestName,
                        carbon: <Text color="green" tag="span">
                        <FormattedNumber
                            value={totalCarbonAvoided.value || 0}
                            /> <FormattedUnit unit={totalCarbonAvoided.unit || "t"} />
                        </Text>
                    }}
                />
              </HeadingSmall>
              }
              {mrvGraph.legend_id === "id-legend-planet-5m" ?
                <Text className="impact-container-description">
                  <FormattedMessage
                    id={'Impact.welcomePlanetLayerDescription'}
                    defaultMessage={'We aim to improve trust in the carbon market by integrating Monitoring, Reporting and Verification (MRV) systems in our platform. For nature-based projects within the tropics, you can check processed satellite imagery to watch the evolution of the project. Select a date below to display the closest available imagery.'}
                  />
                </Text>
                : <Text className="impact-container-description">
                  <FormattedMessage
                    id={'Impact.welcomeDescription'}
                    defaultMessage={'We aim to improve trust in the forest carbon market with our'}
                  />
                  <a
                    className="mrv-extenal-link"
                    href={intl.formatMessage(MRVLinkToWebsite.mrvLink)}
                    rel="noopener noreferrer"
                    target="_blank">
                    &nbsp;
                    <FormattedMessage
                      id={'ImpactMRV.blogEntryLink'}
                      defaultMessage={'Monitoring, Reporting and Verification (MRV) system'}
                    />
                  </a>
                  <FormattedMessage
                    id={'Impact.welcomeDescription2'}
                    defaultMessage={', that processes satellite images through AI to measure how carbon stocks of projects evolve over time'}
                  />
                </Text>}
            </div>
            <div className="carbon-graph__graph mrv-graph__graph">
                {!mrvDataPoints && <Loader/>}
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={dataPointsCalculated} onMouseMove={this.onHoverGraph} onMouseLeave={this.handleMouseLeave}>
                  <defs id={carbonGraphDefsId}>
                    {getGraphLineInitialPartFill(0, FIRST_PART_OF_GRAPH_FILL_ID, GRAPH_FILL_COLOR)}
                    {getGraphLineInitialPartStroke(0, FIRST_PART_OF_GRAPH_STROKE_ID)}
                    {getGraphLineFinalPartStroke(0, LAST_PART_OF_GRAPH_STROKE_ID)}
                  </defs>
                    <RechartsTooltip 
                        content={this.renderTooltip(dataPointsCalculated)} 
                        labelFormatter={(index) => dataPointsCalculated[index].carbon}               
                        offset={20}
                    />
                    <XAxis
                        tickLine={{stroke: 'white'}}
                        minTickGap={0}
                        tickSize={24}
                        axisLine={false}
                        dataKey={'year'}
                        tick={{fontSize: '11px', fill: '#79897A', fontWeight: 500}}
                        interval="preserveStartEnd"
                        ticks={[...new Set(dataPointsCalculated.map(el => el.year))]}
                    />
                    <YAxis
                        domain={[0, 'auto']}
                        tickLine={{stroke: 'white'}}
                        minTickGap={0}
                        tickSize={24}
                        type="number"
                        dataKey="carbon"
                        axisLine={false}
                        tick={<CustomizedYAxis intlLocale={locale}/>}
                    />
                    <CartesianGrid
                      strokeDasharray="1 1"
                      vertical={false}
                      stroke="#E8EAE7"
                    />
                      {/* We add two ovelapping lines to the graph to have the effect of a dotted line from the active dot to the end of the graph*/}
                    <Area
                        stroke={`url(#${LAST_PART_OF_GRAPH_STROKE_ID})`}
                        fill='none'
                        type="monotone"
                        dataKey="carbon"
                        isAnimationActive={false}
                        strokeWidth={1}
                        strokeDasharray="2 5"
                        dot={(props) => {
                          //This is used to save the first and last datapoints to show the last datapoint as default
                          //and calcuate the percentage that needs to be highlighted
                          //render as default the current date datapoint tooltip at the correct location  
                          if(props.payload.date.getTime() === mrvDataPoints[0].date.getTime()) {
                            this.firstPointGraphCoords = {cx: props.cx, cy: props.cy, index: props.index}
                          }

                          if(props.payload.date.getTime() === mrvDataPoints[mrvDataPoints.length - 1].date.getTime()) {
                            this.lastPointGraphCoords = {cx: props.cx, cy: props.cy, index: props.index}
                            //initialize the currently rendered datapoint with the last point of the graph
                            if(this.mapLayerDatapoint === null) {
                              this.mapLayerDatapoint = {
                                cx: props.cx,
                                cy: props.cy,
                                index: props.index,
                                pointLoadedTimestamp: new Date().getTime(),
                                isFirstHalfOfPoints: (props.index / mrvDataPoints.length) < 0.5
                              }
                            }
                          }

                          return <CustomizedDot {...props}></CustomizedDot>
                        }}
                        activeDot={(props) => {
                          this.activeDotCoordinates = {cx: props.cx, cy: props.cy, index: props.index}
                          return (
                            <GraphCircle
                              {...props}
                              selectYear={this.selectYear.bind(this)}
                              selectedYearIndex={selectedYearIndex}
                              maxYearsIndex={5}
                            />
                          )
                          }
                        }
                    />
                    <Area
                        stroke={`url(#${FIRST_PART_OF_GRAPH_STROKE_ID})`}
                        fill={`url(#${FIRST_PART_OF_GRAPH_FILL_ID})`}
                        type="monotone"
                        dataKey="carbon"
                        isAnimationActive={false}
                        strokeWidth={1}
                        activeDot={false}
                        dot={false}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
          </div>
        )
    }
}

MRVGraph.propTypes = {
  intl: intlShape.isRequired,
  setGraphControlledRasterSources: PropTypes.func.isRequired,
  fetchForestMrvGraph: PropTypes.func.isRequired,
  mrvGraph: PropTypes.object.isRequired,
  forestId: PropTypes.number.isRequired,
  forestName: PropTypes.string,
  totalVcus: PropTypes.number,
  renderedWithoutMap: PropTypes.bool
};

export default connect(null, {setGraphControlledRasterSources, fetchForestMrvGraph})(injectIntl(MRVGraph));