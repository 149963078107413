import { Button } from '@reforestum/shared-components';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MapTrifold from '../components/UI/Icons/MapTrifold';
import IframeResizable from '../components/UI/IframeResizable/IframeResizable';
import IframeResizableFullPageContainer from '../components/UI/IframeResizable/IframeResizableFullPageContainer';
import { PROJECTS_LIST_DASHBOARD } from '../constants/metabaseDashboards';
import {
  fetchMyForests
} from '../actions/forests';
import * as selector from '../selectors/forests';
import MyForestsContainer from './MyForestsContainer';

import './ProjectsListContainer.css';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import ListIcon from '../components/UI/Icons/ListIcon';
import { links } from '@reforestum/shared-utils';
import {FormattedMessage, injectIntl} from 'react-intl';
import {BeZero as BeZeroTexts} from '../constants/messages'

const PROJECTS_LIST_SUBROUTE = 'list';
const PROJECTS_MAP_SUBROUTE = 'map';

class ProjectsListContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.updateIframeSrc = this.updateIframeSrc.bind(this)
  }

  componentDidMount() {
    const {
      fetchMyForests,
    } = this.props;

    this.updateIframeSrc();
    fetchMyForests();
  }

  componentDidUpdate() {
      this.updateIframeSrc();
  }

  getIframeSrc() {
    const searchParams = new URLSearchParams(window.location.search);
    const iframeParsedQueryParams = {}
    if(searchParams.get('name')) {
      //This correspond to the name of the filter in the metabase dashboard
      iframeParsedQueryParams.project_name = searchParams.get('name');
    }

    //iframeSrcWithQueryParams using URL utils in case there are already query params in the url
    const iframeSrcWithQueryParams = new URL(PROJECTS_LIST_DASHBOARD);
    iframeSrcWithQueryParams.search = new URLSearchParams(iframeParsedQueryParams).toString();
    return iframeSrcWithQueryParams.toString();
  }

  updateIframeSrc() {
    const currentIframeSrc = this.getIframeSrc()
    if(currentIframeSrc !== this.state.iframeSrcWithQueryParams)
    this.setState({ iframeSrcWithQueryParams: currentIframeSrc });

  }

  renderBeZeroReferences() {
    let result = null
    const {intl} = this.props
    if(this.props.renderBeZeroReferencesInList) {
      result = <div className={'projects-list__be-zero-references'}>
        {intl.formatMessage(BeZeroTexts.dataReferencesTitle)}:&nbsp;
          <a target="_blank" rel="noopener noreferrer" href={'https://bezerocarbon.com/methodology/'}>{intl.formatMessage(BeZeroTexts.dataReferencesMethodology)}</a>,&nbsp;
          <a target="_blank" rel="noopener noreferrer" href={'https://bezerocarbon.com/end-user-disclaimer-usage-guidelines/'}>{intl.formatMessage(BeZeroTexts.endUserDisclaimerAndUsageGuidelines)}</a>
      </div>
    }
    return result
  }

  renderListView() {
    const { match, history } = this.props;
    const { iframeSrcWithQueryParams } = this.state;
    return (
      <IframeResizableFullPageContainer noMarginTop noScroll>
        <div className='project-list-toggle-button-container'>
          <Button outline onClick={() => history.push(`${match.url}/${PROJECTS_MAP_SUBROUTE}`)} className={'projects-list-toggle-button'}>
            <MapTrifold/>
              <FormattedMessage id='projectsList.listView.switchToMapView' defaultMessage='Switch to map view'/>
          </Button>
        </div>
        <IframeResizable
          id="projectsList"
          title='Projects List'
          src={iframeSrcWithQueryParams}
        />
        {this.renderBeZeroReferences()}
      </IframeResizableFullPageContainer>
    )
  }

  renderMapView() {
    const { match, history } = this.props;

    return (
      <div className='projects-list-map-container'>
        <div className='project-list-map-toggle-button-container'>
          <Button outline onClick={() => history.push(`${match.url}/${PROJECTS_LIST_SUBROUTE}`)} className={'projects-list-map-toggle-button'}>
            <ListIcon/>
            <span className={'projects-list-map-toggle-button-text'}>
              <FormattedMessage id='projectsList.mapView.switchToListView' defaultMessage='Switch to list view'/>
            </span>
          </Button>
        </div>
        <MyForestsContainer {...this.props} withSecondaryNavigation/>
      </div>
    )
  }

  render() {
    const { location, match } = this.props;
    const listPath = `${links.getProjectUrl()}/${PROJECTS_LIST_SUBROUTE}`;


    return (
      <div className={`${'projects-list-container'} ${(location.pathname === listPath) && 'projects-list-container--is-list'}`}>
        <Switch>
          <Redirect from={`${match.path}`} to={`${match.path}/${PROJECTS_LIST_SUBROUTE}`} exact />
        </Switch>
        <Switch>
          <Route path={`${match.path}/${PROJECTS_LIST_SUBROUTE}`}>
            {this.renderListView()}
          </Route>
          <Route path={`${match.path}/${PROJECTS_MAP_SUBROUTE}`}>
            {this.renderMapView()}
          </Route>
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  errorMessages: selector.getErrorMessages(state),
  isFetching: selector.getIsFetching(state),
  myForests: selector.getMyForests(state),
});

ProjectsListContainer.propTypes = {
};

export default withRouter(connect(mapStateToProps, {
  fetchMyForests,
})(injectIntl(ProjectsListContainer)));
