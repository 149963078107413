import store from "../../setup/store"
import {getToken, getUserLang} from "../../selectors/userSession"
import {loadFromStorage} from "../../utils/localStorage"

const getOrganizationImpersonationHeaders = () => {
	const currentlyActingAsOrganizationId = loadFromStorage('organizationId')
	return (currentlyActingAsOrganizationId
		? {'x-organization': currentlyActingAsOrganizationId}
		: {})
}

export const getUserRequestHeaders = () => {
	const state = store.getState()
	const generalUserHeaders = {
		"Content-Type": "application/json",
		Authorization: `Bearer ${getToken(state)}`,
		'Accept-Language': getUserLang(state) || navigator.language
	}

	return {
		...generalUserHeaders,
		...getOrganizationImpersonationHeaders()
	}
}
