import React from 'react'
import './ProposalDetailsCard.css'

const ProposalDetailsCard = (
  {
    headerRightSection,
    cardLabel,
    content
  }
) => <div className={'rfp-details-card'}>
  <div className={'rfp-details-card__header'}>
    {
      cardLabel &&
      <div className={'rfp-details-card__header__label design-system-text-label-m'}>
        {cardLabel}
      </div>
    }
    {
      headerRightSection && <div className={'rfp-details-card__header__right-section design-system-text-label-m'}>
        {headerRightSection}
      </div>
    }
  </div>

  <div className={'rfp-details-card__content'}>
    {content}
  </div>
</div>


export default ProposalDetailsCard