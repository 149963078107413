
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames'
import { FormattedNumber, FormattedMessage, injectIntl, intlShape } from 'react-intl';

import Small from '../UI/Text/Small';
import IconText from '../UI/Text/IconText';
import OrangeRain from '../UI/Icons/OrangeRain';
import Frequency from '../UI/Icons/Frequency';
import { getFormattedWeight } from '../../utils/units';
import { carbonMessages } from '../../constants/messages';
import WhiteRain from '../UI/Icons/WhiteRain';
import Next from '../UI/Icons/NavigationNext';
import './COSources.css';
import FormattedUnit from '../Utils/FormattedUnit';

const renderFrequency = (frequency, intl) => {
  switch (frequency) {
    case 1:
      return intl.formatMessage(carbonMessages.freqFormDaily)
    case 2:
      return intl.formatMessage(carbonMessages.freqFormWeekly)
    case 3:
      return intl.formatMessage(carbonMessages.freqFormMonthly)
    default:
      return null
  }
}

const renderWeight = (value) =>  (
  <span>
    <FormattedNumber value={getFormattedWeight(value).value} />
    <span>{' '}</span>
    <FormattedUnit unit={getFormattedWeight(value).unit} />
  </span>
)

const getSourcesTotalAmout = sources => sources.reduce((sum, { amount }) => {
  return sum + amount
}, 0)

class COSources extends Component {
  constructor() {
    super()

    this.state = {
      sourcesVisible: false
    }
  }

  toggleSourceVisible = () => {
    this.setState((state) => ({
      sourcesVisible: !state.sourcesVisible
    }))
  }

  renderSource = ({ id, name, periodicity, amount }) => {
    const { intl } = this.props

    return (
      <div key={id} className="checkout-cosources__sources__row">
        <div className="checkout-cosources__sources__name">
          {name}
        </div>
        <div className="checkout-cosources__sources__frequency">
          {!!periodicity && (
            <IconText icon={Frequency}>
              <span>
                {renderFrequency(periodicity, intl)}
              </span>
            </IconText>
          )}
        </div>
        <div className="checkout-cosources__sources__emit">
          <IconText icon={OrangeRain}>
            <Small color="orange">
              {renderWeight(amount)}
            </Small>
          </IconText>
        </div>
      </div>
    )
  }


  renderShowSources = () => {
    const { sources } = this.props;
    const { sourcesVisible } = this.state

    if (!sources.length) return null

    const arrowState =  sourcesVisible ? 'up' : 'down'
    const arrowClass = cn(
      'checkout-cosources__showmore-arrow',
      `checkout-cosources__showmore-arrow--${arrowState}`
    )
    const sourcesTotalAmount = getSourcesTotalAmout(sources)

    return (
      <div className="checkout-cosources__showmore">
        <div
          onClick={this.toggleSourceVisible}
          className="checkout-cosources__showmore-btn"
        >
          <WhiteRain
            className="checkout-cosources__showmore-icon"
          />
          See my sources
          <Next className={arrowClass} />
        </div>
        <div className="checkout-cosources__showmore__sources">
          {sources.length} sources / {renderWeight(sourcesTotalAmount)}
        </div>
      </div>
    )
  }

  renderSources = () => {
    const { sourcesVisible } = this.state
    const { sources } = this.props;

    if (!sourcesVisible) return null

    return (
      <div className="checkout-cosources__sources">
        {sources.map(this.renderSource)}
      </div>
    )
  }

  render() {
    const { sourcesVisible } = this.state;
    const { carbonCaptured } = this.props

    const headClass = cn(
      'checkout-cosources__head',
      {'checkout-cosources__head--opened': sourcesVisible }
    )

    return (
      <div className="checkout-cosources">
        <div className={headClass}>
          <div className="checkout-cosources__head__intro">
            <FormattedMessage 
              id="COSources.offsetMessage" 
              defaultMessage={'Congratulations, you’re now neutralising {value} of carbon!'}
              values={{
                value: renderWeight(carbonCaptured),
              }}
            />
            <br />
            <FormattedMessage 
              id="COSources.offsetMessageUnder" 
              defaultMessage="Keep up the good work! 🙌"
            />
          </div>
          {this.renderShowSources()}
        </div>
        {this.renderSources()}
      </div>
    );
  }
}

COSources.propTypes = {
  sources: PropTypes.array.isRequired,
  carbonCaptured: PropTypes.number.isRequired,
  intl: intlShape,
};

export default injectIntl(COSources);
