import React from 'react';
import PropTypes from 'prop-types';

import './GraphCircle.css';

const GraphCircleCurrentMoment = props => {
  const {
    cx,
    cy,
    key,
  } = props;

  return (
    <g>
      <circle
        className="carbon-graph-area"
        key={key}
        r={3}
        cx={cx}
        cy={cy}
      />
    </g>
  );
};

GraphCircleCurrentMoment.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  key: PropTypes.number,
};

export default GraphCircleCurrentMoment;
