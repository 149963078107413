import actionTypes from '../constants/actionTypes';

const initialState = {
  isFetching: false,
  lastPurchaseInformation: undefined,
  lastPurchaseInfoErrorMessages: null
};

export default (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.LAST_PURCHASE_START:
        return {
          ...state,
          isFetching: true,
          lastPurchaseInfoErrorMessages: null
        }
      case actionTypes.LAST_PURCHASE_COMPLETE:
        return {
          isFetching: false,
          lastPurchaseInformation: action.payload,
          lastPurchaseInfoErrorMessages: null
        };
      case actionTypes.LAST_PURCHASE_ERROR:
        return {
          isFetching: false,
          lastPurchaseInfoErrorMessages: action.payload
        }
      default:
        return state;
    }
};

export const getLastPurchaseInfo = state => state.lastPurchaseInformation;
export const getIsFetching = state => state.isFetching;
export const getLastPurchaseInfoErrorMessages = state => state.lastPurchaseInfoErrorMessages;