import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchForestContributors } from '../actions/forests';
import * as selector from '../selectors/forests';

import Patrons from '../components/Forests/Patrons/Patrons';
import Loader from '../components/UI/Loader/Loader';
import FullErrorFetching from '../components/UI/Interface/FullErrorFetching';

import NoForestEmptyStateImg from '@reforestum/shared-components/dist/assets/images/no-forests-empty-state.png';
import './ContributorsContainer.css';
import Text from '../components/UI/Text/Text';
import ForestSection from '../components/Forests/UI/ForestSection';
import ForestTitle from '../components/Forests/UI/ForestTitle';
import Tooltip from '../components/UI/Tooltip/Tooltip';
import { FormattedMessage } from 'react-intl';
import Information from '../components/UI/Icons/Information';
import { CONTRIBUTORS_PER_PAGE } from '../constants/contributors';
import {REGISTRY_GOLD_STANDARD, REGISTRY_MITERD, REGISTRY_VERRA} from '../constants/registries';

class ContributorsContainer extends Component {

  renderContributorsTitle() {

    const {registry} = this.props

    return (
      <ForestTitle>
        {(registry === REGISTRY_GOLD_STANDARD) ? <FormattedMessage
          id={'OrgTransactionsInProject.titleGoldStandard'}
          defaultMessage={'Transactions in Gold Standard registry'}
        /> : (registry === REGISTRY_MITERD) ?
          <FormattedMessage id='contributorsContainer.titleMiterd' defaultMessage='Contributors'/> : 
          <FormattedMessage id='contributorsContainer.title' defaultMessage='Detected contributors'/>
        }
        {(registry === REGISTRY_VERRA) && 
          <Tooltip
            position={'bottom-right'}
            className='contributors-container-tooltip'
            content={<FormattedMessage id='contributorsContainer.howDoWeGetThisListContent' defaultMessage='This list is obtained from the Project Registry in Verra and shows the detected contributors that have obteined VCUs in this project.'/>}
          >
            <Information style={{marginLeft: '6px'}}/>
          </Tooltip>
        }
      </ForestTitle>
    )
 }

  render() {
    const {
      errorMessages,
      fetchForestContributors,
      forestId,
      isFetching,
      page,
      total,
      contributors,
      contributorsForestId,
      registry,
    } = this.props;

    if (errorMessages) {
      return (
        <FullErrorFetching
          errorMessage={errorMessages}
          retry={fetchForestContributors.bind(this, {forestId}, page, CONTRIBUTORS_PER_PAGE)}
        />
      );
    }
    const isPreviousForestData = Number(contributorsForestId) !== Number(forestId);
    if ((isFetching && (Object.keys(contributors).length <= 0)) || isPreviousForestData) return <Loader />;
    if(!isFetching && (Object.keys(contributors).length <= 0)) {
      return (
        <div>
          <img src={NoForestEmptyStateImg} className="contributors-container-no-contributors-image" alt="Person watering plants"/>
          <Text className="contributors-container-no-contributors-text">
            <FormattedMessage id="contributorsContainer.noContributorsDetected" defaultMessage="No contributors detected for this project"/>
        </Text>
        </div>
      )
    }
    return (
      <div>
        <ForestSection>
          {this.renderContributorsTitle()}
        </ForestSection>
        <Patrons
          getMore={fetchForestContributors.bind(this, {forestId})}
          page={page}
          data={{total}}
          pageLength={CONTRIBUTORS_PER_PAGE}
          patrons={contributors}
          isFetching={isFetching}
          registry={registry}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errorMessages: selector.getContributorsErrorMessages(state),
  isFetching: selector.getIsFetchingContributors(state),
  page: selector.getContributorsPage(state),
  total: selector.getContributorsTotal(state),
  contributors: selector.getContributors(state),
  contributorsForestId: selector.getContributorsForestId(state),
});

ContributorsContainer.propTypes = {
  fetchForestContributors: PropTypes.func.isRequired,
  forestId: PropTypes.number.isRequired,
  sectorId: PropTypes.number,
  patronsForestId: PropTypes.number,
  patronsSectorId: PropTypes.number,
  registry: PropTypes.string,
  errorMessages: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  page: PropTypes.number,
  patrons: PropTypes.array,
  podium: PropTypes.array,
  user: PropTypes.object,
};

export default connect(mapStateToProps, {
  fetchForestContributors,
})(ContributorsContainer);
