export const SPECIAL_CASE_DOUBLE_PARAGRAPH = {
    gl: 'Galeries Lafayette'
};

export const GALLERY_TYPE = {
    GAL: 'GAL',
    TXT: 'TXT'
}

export const PUBLIC_DASHBOARD_SECTION_IDS = {
    PROJECTS_AND_COMMUNITY: 'projects_and_community',
    SIMILAR_COMPANIES: 'similar_companies',
}
