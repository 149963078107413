const {
    REACT_APP_STRIPE_TOKEN,
    REACT_APP_MAPBOX_STYLES_URL,
    REACT_APP_MAPBOX_KEY,
    REACT_APP_MIXPANEL_KEY,
    REACT_APP_FACEBOOK_APP_ID,
    REACT_APP_GOOGLE_CLIENT_ID,
    REACT_APP_GOOGLE_API_KEY,
    REACT_APP_INTERCOM_APP_ID,
    REACT_APP_API_URL,
    REACT_APP_ENV,
    REACT_APP_API_V2_URL,
    REACT_APP_FEATURE_FLAG_ENV,
    REACT_APP_ENTERPRISE_ACTIVATION_URL,
    REACT_APP_SENTRY_DSN_URL,
    REACT_APP_POSTHOG_API_HOST,
    REACT_APP_POSTHOG_PROJECT_API_KEY
  } = process.env


export const stripeToken = REACT_APP_STRIPE_TOKEN;
export const mapboxKey = REACT_APP_MAPBOX_KEY;
export const mapboxStylesUrl = REACT_APP_MAPBOX_STYLES_URL;
export const mixpanelKey = REACT_APP_MIXPANEL_KEY;
export const intercomAppId = REACT_APP_INTERCOM_APP_ID;
export const googleClientId = REACT_APP_GOOGLE_CLIENT_ID;
export const googleApiKey = REACT_APP_GOOGLE_API_KEY;
export const facebookAppId = REACT_APP_FACEBOOK_APP_ID;
export const reforestumApiUrl = REACT_APP_API_URL;
export const enviroment = REACT_APP_ENV;
export const reforestumApiV2Url =  REACT_APP_API_V2_URL;
export const featureFlagEnv = REACT_APP_FEATURE_FLAG_ENV;
export const enterpriseActivation = REACT_APP_ENTERPRISE_ACTIVATION_URL;
export const sentryDsnUrl = REACT_APP_SENTRY_DSN_URL;
export const posthogApiHost = REACT_APP_POSTHOG_API_HOST;
export const posthogProjectApiKey = REACT_APP_POSTHOG_PROJECT_API_KEY;
