import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Field } from 'redux-form';
import { intlShape } from 'react-intl';
import get from 'lodash/get';
import { formMessages } from '../../constants/messages';
import { required } from '../../utils/formHelpers';
import { withIntl } from '../../utils/intl';
import {Element} from "react-scroll";
import ClearStep from "./ClearStep";

class StepSelect extends Component {
  constructor(props){
    super(props);

    this.state = {
      showDropdown: false,
      selectedOptionText: props.intl.formatMessage(formMessages.selectDefaultOption)
    };

    this.selectComponent = this.selectComponent.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.fetchedUsingManuallySavedValues = 0;
  }

  getSelectedFromOptions(props, selectedOption) {
    if (!props.options || !selectedOption) {
      return props.intl.formatMessage(formMessages.selectDefaultOption)
    }
    for (let i = 0; i < props.options.length; i++) {
      if (props.options[i]['value'] === selectedOption) return props.options[i]['name']
    }
    return selectedOption
  }

  getSelectedItem() {
    const {intl, formValues, step, options, manuallySavedValue} = this.props
    let result = <span className={'select-input__default-item'}>{intl.formatMessage(formMessages.selectDefaultOption)}</span>

    if(get(formValues, `field_${step.id}`) !== undefined) {
      result = get(
        options.find(o => o.value === get(formValues, `field_${step.id}`)),
        'name'
      )
    }
    else if(manuallySavedValue) {
      result = get(
        options.find(o => o.value === parseInt(manuallySavedValue)),
        'name'
      )
    }

    return result
  }

  selectComponent(props) {
    const {showDropdown} = this.state
    const { options, step, jumpingBackAStep, isMostRecentStep, stepIndex, jumpBackToStepIndex, disabled } = this.props;
    const { input, meta } = props;
    const selectOptions = options.map((option, index) => (
      <div
        className={'select-input__option'}
        onClick={
          disabled
          ? (() => {})()
          : e => {
            this.setState({showDropdown: false})
            this.handleChange(e, {
              index,
              dataFinal: option.final,
              dataNextStepId: option.next_step_id || step.next_step_id,
              name: option.name,
              value: option.value
            })
          }
        }
        key={index}
      >
        {option.name}
      </div>
    ));

    if(input.name.substr(6, input.name.length) === '0' && this.props.stepsList.length > 1){
      input.value = this.props.stepsList[1][1].toString()
    }

    for(let i = 0; i < this.props.stepsList.length - 1; i++) {
      if(
        this.props.stepsList[i][1] !== undefined && this.props.stepsList[i][0] === parseInt( input.name.substr(6, input.name.length) )
      ){
        input.value = this.props.stepsList[i + 1][1].toString()
        break
      }
    }

    if(this.props.manuallySavedValue && input.value !== this.props.manuallySavedValue && !jumpingBackAStep){
      input.value = this.props.manuallySavedValue
      this.handleChange(null)
    }

    this.valueFromOptions = input.value

    return <div>
        <div className={`input-and-jump-back-a-step-wrapper ${disabled ? 'input-and-jump-back-a-step-wrapper__disabled' : ''}`} id={`${isMostRecentStep ? 'most-recent-step' : ''}`}>
          <div
            {...input}
            className={cx(
              'select-input',
              {'select-input__dropdown-open': !!showDropdown},
              {'hide-select-dropdown-arrow': input.value !== ''},
            )}
            onClick={
              disabled
                ? (() => {})()
                : () => {this.setState({showDropdown: !showDropdown})}
            }
          >
            {this.getSelectedItem()}
          </div>
          {
            (input.value !== '') && <ClearStep
              step={step}
              stepIndex={stepIndex}
              jumpBackToStepIndex={jumpBackToStepIndex}
            />
          }
          {
            showDropdown &&
              <div>
                <div
                  onClick={() => this.setState({showDropdown: !showDropdown})}
                  className={'step-select-dropdown-handler'}
                />
                <div className={'step-select-dropdown-container'}>
                  {selectOptions}
                </div>
              </div>
          }
        </div>
        { meta.touched && meta.error &&
        <div className="line-input__error">
          <span color="red">{meta.error}</span>
        </div>
        }
      </div>
  }

  handleChange(event, selectedStep) {
    const { step, handleNextStep, rememberTypedName, doExitEditMode, change } = this.props;

    if (step && step.id === 0) {
      rememberTypedName('')
      doExitEditMode()
    }
    if(event === null && this.props.manuallySavedValue !== undefined && this.fetchedUsingManuallySavedValues < 4) {
      this.fetchedUsingManuallySavedValues += 1
      let optionsDict = {}
      this.props.options.forEach(op => optionsDict[op.value] = op)
      const selectedOption = optionsDict[this.props.manuallySavedValue]
      handleNextStep(step, step.next_step_id || selectedOption.next_step_id, selectedOption.value, step.final === true || step.final === 'true' || selectedOption.final);
    }
    else if(event && event.target && event.target.selectedIndex) {
      const selectedOption = event.target.options[event.target.selectedIndex]
      this.setState({ selectedOptionText: selectedOption.text });
      handleNextStep(step, selectedOption.dataset.nextid, selectedOption.value, selectedOption.dataset.final === 'true');
    }
    else if(event && event.target && selectedStep) {
      const {index, dataFinal, dataNextStepId, value} = selectedStep
      const selectedOption = step.children[index]
      change(`field_${step.id}`, value)
      this.setState({ selectedOptionText: selectedOption.text })
      handleNextStep(step, dataNextStepId, value, dataFinal === 'true')
    }
  }

  render() {
    const { disabled, step, intl } = this.props;
    return (
      <Element className={'react-scroll-element'} name={`step-${this.props.stepIndex}`}>
        <div className="line-input__input-holder">
          <Field
            name={`field_${step.id}`}
            component={this.selectComponent}
            validate={withIntl(intl, required)}
            onChange={disabled ? (() => {})() : this.handleChange()}
          />
          <div ref={element => this.dummyDiv = element} className="line-input__width-helper">
            {this.getSelectedFromOptions(this.props, parseInt(this.valueFromOptions))}
          </div>
        </div>
      </Element>
    );
  }
}

StepSelect.propTypes = {
  handleNextStep: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  step: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  stepsList: PropTypes.array,
  jumpingBackAStep: PropTypes.bool,
  jumpBackToStepIndex: PropTypes.func,
  stepIndex: PropTypes.number
};

export default StepSelect;
