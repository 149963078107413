import React from 'react';
import PropTypes from 'prop-types';
import Box from 'react-layout-components';

import { injectIntl, intlShape } from 'react-intl';
import { getPrivacyOptionsForIndividuals } from '../../utils/getPrivacyOptions';

import CoverImage from '../Layout/CoverImage/CoverImage';
import VerticalCenter from '../Layout/VerticalCenter/VerticalCenter';
import AuthForm from './AuthForm';
import UserActivationForm from './UserActivationForm';
import Logo from '../UI/Logo/Logo';
import Heading from '../UI/Text/Heading';
import Text from '../UI/Text/Text';

import { SIGNUP_IMAGE } from '../../constants/signupImage';
import { GROSVENOR_LOGO } from '../../constants/enterpriseLanding';

import './UserActivation.css';

const UserActivation = ({
  intl,
  user,
  isUpdating,
  errorMessages,
  handleUserActivation,
}) => {

  const privacyOptions = getPrivacyOptionsForIndividuals(intl);

  return (
    <Box width="100%">
      <CoverImage img={SIGNUP_IMAGE} />
      <VerticalCenter className="user-activation__sidebar">
        <div className="reforestum-logo-activation__wrapper">
            <Logo className="reforestum__logo" />
        </div>
        <img
            className={'activation-landing-company__logo'}
            src={GROSVENOR_LOGO}
            alt={'Company logo'}
        />
        <Heading className='activation-user__heading'>
              {"Register at Reforestum and track Grosvenor's forest"}
        </Heading>
        <div className='activation-form-welcome-text__wrapper'>
            <Text tag='p'>
              {"As a sign of our strong commitment to the planet, Grosvenor's UK property business has chosen to offset all staff emissions for business travel and working from home in 2022 through verified carbon offsets from the Sumatra Peatland restoration project. By registering below, you can locate and view these offsets and monitor how we are restoring natural ecosystems to help Grosvenor reach net-zero by 2030."}
            </Text>
            <Text tag='p'>
              {"We have also worked with Reforestum to enable colleagues to offset their own personal emissions. With your own account, you will be able to estimate your own carbon footprint and offset as much, or as little as you like."}
            </Text>
            <Text tag='p'>
              {"We want to break down the barriers and make it simple for all of us to play our part in tackling climate change. So, what are you waiting for? Complete your registration below and let's make this world a better place to live together."}
            </Text>
        </div>

        <AuthForm>
          <UserActivationForm
            initialValues={user}
            isUpdating={isUpdating}
            errorMessages={errorMessages}
            handleUserActivation={handleUserActivation}
            privacyOptions={privacyOptions}
          />
        </AuthForm>
      </VerticalCenter>
    </Box>
  );
};

UserActivation.propTypes = {
  intl: intlShape,
  user: PropTypes.object.isRequired,
  privacy: PropTypes.oneOf([ 0, 1, 2, '0', '1', '2' ]),
  isUpdating: PropTypes.bool.isRequired,
  errorMessages: PropTypes.string,
  handleUserActivation: PropTypes.func.isRequired,
};

export default injectIntl(UserActivation);
