import actionTypes from '../constants/actionTypes';

const initialState = {
  isFetching: false,
  companyDetails: undefined,
  companyDetailsErrorMessages: null
};

export default (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.ENTERPRISE_ACTIVATION_COMPANY_DETAILS_START:
        return {
          ...state,
          isFetching: true
        }
      case actionTypes.ENTERPRISE_ACTIVATION__COMPANY_DETAILS_COMPLETE:
        return {
          isFetching: false,
          companyDetails: action.payload.companyDetails
        };
      case actionTypes.ENTERPRISE_ACTIVATION__COMPANY_DETAILS_ERROR:
        return {
          isFetching: false,
          companyDetailsErrorMessages: action.payload
        }
      default:
        return state;
    }
};

export const getEnterpriseActivationCompanyDetails = state => state.companyDetails;
export const getEnterpriseActivationCompanyDetailsErrors = state => state.companyDetailsErrorMessages;