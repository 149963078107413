import React from 'react';

const SquaresFour = props => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M3.75 3.725H8.75C8.76381 3.725 8.775 3.73619 8.775 3.75V8.75C8.775 8.76381 8.76381 8.775 8.75 8.775H3.75C3.73619 8.775 3.725 8.76381 3.725 8.75V3.75C3.725 3.73619 3.73619 3.725 3.75 3.725Z" stroke="#1F3B21" strokeWidth="1.2"/>
		<path d="M11.25 3.725H16.25C16.2638 3.725 16.275 3.73619 16.275 3.75V8.75C16.275 8.76381 16.2638 8.775 16.25 8.775H11.25C11.2362 8.775 11.225 8.76381 11.225 8.75V3.75C11.225 3.73619 11.2362 3.725 11.25 3.725Z" stroke="#A5B1A6" strokeWidth="1.2"/>
		<path d="M3.75 11.225H8.75C8.76381 11.225 8.775 11.2362 8.775 11.25V16.25C8.775 16.2638 8.76381 16.275 8.75 16.275H3.75C3.73619 16.275 3.725 16.2638 3.725 16.25V11.25C3.725 11.2362 3.73619 11.225 3.75 11.225Z" stroke="#A5B1A6" strokeWidth="1.2"/>
		<path d="M11.25 11.225H16.25C16.2638 11.225 16.275 11.2362 16.275 11.25V16.25C16.275 16.2638 16.2638 16.275 16.25 16.275H11.25C11.2362 16.275 11.225 16.2638 11.225 16.25V11.25C11.225 11.2362 11.2362 11.225 11.25 11.225Z" stroke="#1F3B21" strokeWidth="1.2"/>
	</svg>
);

export default SquaresFour;
