import { axiosV1Instance } from './';

// constants
import actionTypes from '../constants/actionTypes';
import api from '../constants/api';

// Retrieve my contributions to that public profile
export const fetchMyContributionsInPublicProfile = (slug) => ({
    shouldCallAPI: () => true,
    callAPI: () => axiosV1Instance.get(`${api.GET_PUBLIC_PROFILE}/${slug}/offsets`),
    actions: [
      actionTypes.MY_CONTRIBUTIONS_START,
      {
        type: actionTypes.MY_CONTRIBUTIONS_COMPLETE,
        payload: response => response.data.data
      },
      {
        type: actionTypes.MY_CONTRIBUTIONS_ERROR,
        payload: response => response
      }
    ]
});