import React from 'react';
import PropTypes from 'prop-types';

import './SpeciesItem.css';

const SpeciesItem = ({name, latin, image}) => (
  <div className="specie">
    <img className="specie__icon" src={image} alt={name} />
    <div className="specie__text">
      <h3 className="specie__line-1">{latin}</h3>
      <h4 className="specie__line-2">{name}</h4>
    </div>
  </div>
);

SpeciesItem.propTypes = {
  image: PropTypes.string,
  latin: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default SpeciesItem;
