import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import {addressMessages, authMessages, formMessages} from '../../constants/messages'

import Row from '../UI/Forms/Row';
import RowAligner from '../UI/Forms/RowAligner';
import { Button } from '@reforestum/shared-components';
import FeedbackMessage from '../UI/Forms/FeedbackMessage';
import BillingHeader from './BillingHeader';
import PersonalInfo from './PersonalInfo';
import PasswordForm from './PasswordForm';

import './BillingForm.css';
import {getUserOrImpersonatingOrg, isImpersonatingOrg} from "../../utils/usersAndOrganizations"
import actionTypes from "../../constants/actionTypes"
import Modal from '../UI/Modal/Modal';
import UpdateInformationInModalContainer from '../AccountSettings/OrganizationSettings/OrganizationInformation/UpdateInformationInModalContainer';

class BillingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formHeight: 0,
      showUpdateOrganizationInformationModal: false,
    
    };

    this.handleChangeShowUpdateOrganizationInformationModal = this.handleChangeShowUpdateOrganizationInformationModal.bind(this)
    this.renderUpdateOrganizationInformationModal = this.renderUpdateOrganizationInformationModal.bind(this)
  }

  componentDidMount() {
    this.updateFormHeight();
  }

  handleChangeShowUpdateOrganizationInformationModal() {
    this.setState({showUpdateOrganizationInformationModal: !this.state.showUpdateOrganizationInformationModal})
  }

  componentDidUpdate() {
    this.updateFormHeight();
  }

  updateFormHeight() {
    if (this.form && (this.state.formHeight !== this.form.clientHeight)) {
      this.setState({
        formHeight: this.form.clientHeight
      });
    }
    else if(!this.form && (this.state.formHeight !== 500)) {
      this.setState({
        formHeight: 500
      });
    }
  }

  submitForm(form) {
    const {
      dirty,
      isAuthenticated,
      toggleBillingPanel,
      valid,
    } = this.props;

    const country = this.props.countries.find(
      (element) => form.country === element.country_name
    );
    form = {
      ...form,
      country: country.country_code,
    };

    if (!valid) { return; }

    if (!isAuthenticated) {
      return this.handleSignup(form);
    }

    return dirty ?
      this.handleUpdate(form) :
      toggleBillingPanel();
  }

  handleSignup(form) {
    this.props.signup(
      form,
      this.props.refreshProfile,
      this.props.toggleBillingPanel
    );
  }

  handleUpdate(form) {
    const {intl} = this.props;
    this.props.updateUser(
      {form, intl},
      this.props.refreshProfile,
      this.props.toggleBillingPanel
    );
  }

  renderPersonalInfoBillingForm() {
    const {
      billingComplete,
      handleSubmit,
      isAuthenticated,
      isWorkingAuth,
      signupErrorMessages,
      updateErrorMessages,
      countries,
      isCompany,
    } = this.props;

    return <div
      className="checkout-billing-form__form-wrapper"
      style={{
        maxHeight: billingComplete ? 0 : this.state.formHeight
      }}>
      <form
        className="checkout-billing-form__form"
        ref={form => this.form = form}
        onSubmit={handleSubmit(this.submitForm.bind(this))}>

        {
          (signupErrorMessages || updateErrorMessages) &&
          <FeedbackMessage type="error">
            {signupErrorMessages || updateErrorMessages}
          </FeedbackMessage>
        }

        <PersonalInfo
          isAuthenticated={isAuthenticated}
          isWorkingAuth={isWorkingAuth}
          billingComplete={billingComplete}
          countries={countries}
          isCompany={isCompany}
        />

        { !isAuthenticated &&
          <PasswordForm
            billingComplete={billingComplete}
            isWorkingAuth={isWorkingAuth}
          />
        }

        { !billingComplete &&
          <Row>
            <RowAligner>
              <Button big loading={isWorkingAuth}>
                <FormattedMessage
                  id={'BillingForm.continue'}
                  defaultMessage={'Save and Continue'}
                />
              </Button>
            </RowAligner>
          </Row>
        }
      </form>
    </div>
  }

  renderImpersonatingOrgBillingInformation() {
    const {
      company_name,
      vat_number,
      address_line,
      city,
      state,
      zip,
      country
    } = getUserOrImpersonatingOrg(this.props.user)

    const labelsAndValues = [
      {label: this.props.intl.formatMessage(authMessages.companyNameLabel), value: company_name},
      {label: this.props.intl.formatMessage(authMessages.vatNumberLabel), value: vat_number},
      {label: this.props.intl.formatMessage(addressMessages.addressLabel), value: address_line},
      {label: this.props.intl.formatMessage(addressMessages.cityLabel), value: city},
      {label: this.props.intl.formatMessage(addressMessages.stateLabel), value: state},
      {label: this.props.intl.formatMessage(addressMessages.zipLabel), value: zip},
      {label: this.props.intl.formatMessage(addressMessages.countryLabel), value: country},
    ]

    const requiresAdditionalInformationForOrganization = () => labelsAndValues.some(
      labelAndValue => !labelAndValue.value || !labelAndValue.value.length
    )

    return <div className={'billing-form__organization-information'}>
      <div className={'billing-form__organization-information__form-like'}>
        {
          labelsAndValues.map(
            labelAndValue => <div className={'billing-form__organization-information--item'}>
              <div className={'billing-form__organization-information--item-label main-input__label t-small t-main t-main--dark t-main--medium'}>
                { labelAndValue.label }
              </div>
              <div className={'billing-form__organization-information--item-value main-input__input'}>
                { labelAndValue.value || <span>&nbsp;</span> }
              </div>
            </div>
          )
        }

      </div>

      {
        requiresAdditionalInformationForOrganization() &&
        <div>
          <div className={'billing-form__organization-information--more-billing-details-required'}>
            <span onClick={this.handleChangeShowUpdateOrganizationInformationModal} className={'billing-form__organization-information--more-billing-details-required__clickable-action'}>
              <FormattedMessage
                id={'BillingForm.pleaseUpdate'}
                defaultMessage={"Please update"}
              />
            </span>
            &nbsp;
            <FormattedMessage
              id={'BillingForm.yourOrganizationsDetails'}
              defaultMessage={"your organization's details"}
            />
          </div>
        </div>
      }
      {
        !requiresAdditionalInformationForOrganization() &&
        <div>
          <div className={'billing-form__organization-information-update-option'}>
            <span onClick={this.handleChangeShowUpdateOrganizationInformationModal} className={'billing-form__organization-information-update-option__clickable-action'}>
              <FormattedMessage
                id={'BillingForm.update'}
                defaultMessage={"Update"}
              />
              &nbsp;
              <FormattedMessage
                id={'BillingForm.yourOrganizationsDetails'}
                defaultMessage={"your organization's details"}
              />
            </span>
          </div>
          <Button
            onClick={() => {
              this.props.dispatch({ type: actionTypes.CHECKOUT_TOGGLE_BILLING })
            }}
            big
          >
            <FormattedMessage
              id={'BillingForm.justContinue'}
              defaultMessage={'Continue'}
            />
          </Button>
        </div>
      }
    </div>
  }

  renderUpdateOrganizationInformationModal() {
    return <Modal large clickClose={this.handleChangeShowUpdateOrganizationInformationModal} content={<UpdateInformationInModalContainer/>} />
  }

  render() {
    const {
      billingComplete,
      isAuthenticated,
      toggleBillingPanel
    } = this.props;

    const { showUpdateOrganizationInformationModal } = this.state;

    return (
      <div className="checkout-billing-form">
        {showUpdateOrganizationInformationModal && this.renderUpdateOrganizationInformationModal()}
        <BillingHeader
          isAuthenticated={isAuthenticated}
          billingComplete={billingComplete}
          toggleBillingPanel={toggleBillingPanel}
        />

        {
          !isImpersonatingOrg() && this.renderPersonalInfoBillingForm()
        }

        {
          (isImpersonatingOrg() && !billingComplete) && this.renderImpersonatingOrgBillingInformation()
        }
      </div>
    );
  }
}

const validate = (formProps, props) => {
  const errors = {};
  const { country, password, passwordConfirm } = formProps;
  const { intl } = props; 

  if ( passwordConfirm && (password !== passwordConfirm) ) {
    errors.passwordConfirm = intl.formatMessage(formMessages.passwordNotMatch);
  }

  if (country) {
    const isCountryValid = props.countries.some(countryObject => countryObject.country_name === country);
    if (!isCountryValid) {
      errors.country = intl.formatMessage(formMessages.countryMatch);
    }
  }

  return errors;
};

BillingForm.propTypes = {
  billingComplete: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: intlShape,
  isAuthenticated: PropTypes.bool.isRequired,
  isWorkingAuth: PropTypes.bool.isRequired,
  signup: PropTypes.func.isRequired,
  signupErrorMessages: PropTypes.string,
  toggleBillingPanel: PropTypes.func.isRequired,
  updateErrorMessages: PropTypes.string,
  updateUser: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  countries: PropTypes.array.isRequired,
  refreshProfile: PropTypes.func.isRequired,
  isCompany: PropTypes.bool.isRequired,
};

export default injectIntl(reduxForm({
  form: 'billing-form',
  validate,
})(BillingForm));
