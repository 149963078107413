import React from 'react';
import get from 'lodash/get';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { updateUser } from '../../actions/userActions';
import {refreshProfile} from "../../actions/userSession";
import './PreferencesSwitcherSelects.css';
import {getUserDetails} from "../../selectors/userSession";
import {EUR, GBP, USD} from "../../constants/currencies";
import {IMPERIAL, METRIC} from "../../constants/measurements";
import {languageIs} from "./PreferencesSwitcherHelper"
import {EN, ES, FR} from "../../constants/languages"

const PreferencesSwitcherSelects = props => {
  const {updateUser, refreshProfile, userDetails, intl} = props

  const switchMeasurementType = async () => {
    await updateUser({
      form: {
        preferences: {
          metric_distance: !get(userDetails, 'preferences.metric_distance', false),
          metric_weight: !get(userDetails, 'preferences.metric_distance', false),
        }
      },
      intl
    })
    refreshProfile()
  }

  const handleCurrencyChange = async e => {
    const {intl} = this.props
    await updateUser(
      {
        form: {
          preferences: {
            currency: e.target.value,
          }
        },
        intl
      }
    )
    refreshProfile()
  }

  const handleMeasurementChange = e => {
    if(e.target.value === IMPERIAL) {
      get(userDetails, 'preferences.metric_distance', false) && switchMeasurementType()
    }
    else if(e.target.value === METRIC) {
      !get(userDetails, 'preferences.metric_distance', false) && switchMeasurementType()
    }
  }

  const handleLanguageChange = async e => {
    const {intl} = this.props
    await updateUser({
      form: {
        preferences: {
          lang: e.target.value,
        },
        intl
      }
    })
    refreshProfile()
  }

  const isCurrencySelected = currencyValue => get(userDetails, 'preferences.currency') === currencyValue

  const isMeasurementSelected = measurementValue => {
    if(measurementValue === METRIC && get(userDetails, 'preferences.metric_distance', false)) return true
    else if(measurementValue === IMPERIAL && !get(userDetails, 'preferences.metric_distance', false)) return true
  }

  return (
    <div>
      <div className={"preferences-switcher-selects__section-title"}>
        <FormattedMessage
          id={'Header.preferences-switcher-dropdown.currency'}
          defaultMessage={'Currency'}
        />
      </div>

      <select onChange={handleCurrencyChange} className={'preferences-switcher-select__select'}>
        <option value={EUR} selected={isCurrencySelected(EUR)}>
          <FormattedMessage id={'currency.euro-eur'} defaultMessage={"Euro (EUR)"}/>
        </option>
        <option value={GBP} selected={isCurrencySelected(GBP)}>
          <FormattedMessage id={'currency.pound-sterling-gbp'} defaultMessage={"Pound Sterling (GBP)"}/>
        </option>
        <option value={USD} selected={isCurrencySelected(USD)}>
          <FormattedMessage id={'currency.us-dollar-usd'} defaultMessage={"US Dollar (USD)"}/>
        </option>
      </select>

      <div className={"preferences-switcher-selects__section-title"}>
        <FormattedMessage
          id={'Header.preferences-switcher-dropdown.measurement-system'}
          defaultMessage={'Measurement System'}
        />
      </div>

      <select onChange={handleMeasurementChange} className={'preferences-switcher-select__select'}>
        <option value={METRIC} selected={isMeasurementSelected(METRIC)}>
          <FormattedMessage id={'Units.metric'} defaultMessage={"Metric"}/>
        </option>
        <option value={IMPERIAL} selected={isMeasurementSelected(IMPERIAL)}>
          <FormattedMessage id={'Units.imperial'} defaultMessage={"Imperial"}/>
        </option>
      </select>

      <div className={"preferences-switcher-selects__section-title"}>
        <FormattedMessage
          id={'Header.preferences-switcher-dropdown.language'}
          defaultMessage={'Language'}
        />
      </div>

      <select onChange={handleLanguageChange} className={'preferences-switcher-select__select'}>
        <option value={EN} selected={languageIs(EN, userDetails)}>
          English
        </option>
        <option value={ES} selected={languageIs(ES, userDetails)}>
          Español
        </option>
        <option value={FR} selected={languageIs(FR, userDetails)}>
          Français
        </option>
      </select>
    </div>
  )
}

const mapStateToProps = state => ({
  userDetails: getUserDetails(state)
})

PreferencesSwitcherSelects.propTypes = {
  className: PropTypes.string
};

export default connect(
  mapStateToProps,
  { updateUser, refreshProfile }
)(injectIntl(PreferencesSwitcherSelects));
