import React from 'react'
import './ProposalDetails.scss'
import ForestContainer from '../../containers/ForestContainer'
import ProposalDetailsExtras from './ProposalDetailsExtras'

const ProposalDetailsProjectDetails = ({ projectId, resetScrollCallback, extrasToRender=null }={}) => {

  return (
    <div className="proposal-details-project-details">
      <ForestContainer
        selectedForestId={projectId}
        renderedStandalone
        hideCommunity
        hideEmptyTabs
        resetScrollCallback={resetScrollCallback}
      />
      {
        extrasToRender &&
        <ProposalDetailsExtras
          extrasToRender={extrasToRender}
        />
      }
    </div>
  );
};

export default ProposalDetailsProjectDetails