import React from "react";
import PropTypes from "prop-types";
import CloseCircleCalculator from "../UI/Icons/CloseCircleCalculator";
import './ClearStep.css';

const ClearStep = ({stepIndex, jumpBackToStepIndex}) => <CloseCircleCalculator
	onClick={() => jumpBackToStepIndex(stepIndex)}
	className='jump-back-a-step-icon'
/>

ClearStep.propTypes = {
	step: PropTypes.object,
	stepIndex: PropTypes.number,
	jumpBackToStepIndex: PropTypes.func
};

export default ClearStep
